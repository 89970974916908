.container {
  width: 100%;
}

@media (min-width: 480px) {
  .container {
    max-width: 480px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

/* purgecss start ignore */

.space-y-0 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--space-y-reverse)) !important;
}

.space-x-0 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
}

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
}

.space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
}

.space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
}

.space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(4rem * var(--space-x-reverse)) !important;
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
}

.space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
}

.space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(6rem * var(--space-x-reverse)) !important;
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
}

.space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
}

.space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(10rem * var(--space-x-reverse)) !important;
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
}

.space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(12rem * var(--space-x-reverse)) !important;
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
}

.space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(14rem * var(--space-x-reverse)) !important;
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
}

.space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(16rem * var(--space-x-reverse)) !important;
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1px * var(--space-y-reverse)) !important;
}

.space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1px * var(--space-x-reverse)) !important;
  margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
}

.-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
}

.-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
}

.-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
}

.-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
}

.-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
}

.-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
}

.-space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
}

.-space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
}

.-space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
}

.-space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
}

.-space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
}

.-space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
}

.-space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
}

.-space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
}

.-space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
}

.-space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
}

.-space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
}

.-space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1 !important;
}

.space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1 !important;
}

.divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
}

.divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
}

.divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(2px * var(--divide-x-reverse)) !important;
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
}

.divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(4px * var(--divide-x-reverse)) !important;
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
}

.divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(8px * var(--divide-x-reverse)) !important;
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
}

.divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1 !important;
}

.divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1 !important;
}

.divide-transparent > :not(template) ~ :not(template) {
  border-color: transparent !important;
}

.divide-current > :not(template) ~ :not(template) {
  border-color: currentColor !important;
}

.divide-black > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #000 !important;
  border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
}

.divide-white > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fff !important;
  border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
}

.divide-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
}

.divide-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
}

.divide-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #e2e8f0 !important;
  border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
}

.divide-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
}

.divide-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
}

.divide-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #718096 !important;
  border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
}

.divide-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #4a5568 !important;
  border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
}

.divide-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #2d3748 !important;
  border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
}

.divide-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #1a202c !important;
  border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
}

.divide-red-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fff5f5 !important;
  border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
}

.divide-red-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fed7d7 !important;
  border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
}

.divide-red-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #feb2b2 !important;
  border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
}

.divide-red-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fc8181 !important;
  border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
}

.divide-red-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
}

.divide-red-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #e53e3e !important;
  border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
}

.divide-red-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #c53030 !important;
  border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
}

.divide-red-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9b2c2c !important;
  border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
}

.divide-red-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #742a2a !important;
  border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
}

.divide-orange-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fffaf0 !important;
  border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
}

.divide-orange-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #feebc8 !important;
  border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
}

.divide-orange-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fbd38d !important;
  border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
}

.divide-orange-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #f6ad55 !important;
  border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
}

.divide-orange-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #ed8936 !important;
  border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
}

.divide-orange-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #dd6b20 !important;
  border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
}

.divide-orange-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #c05621 !important;
  border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
}

.divide-orange-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9c4221 !important;
  border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
}

.divide-orange-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #7b341e !important;
  border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
}

.divide-yellow-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fffff0 !important;
  border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
}

.divide-yellow-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fefcbf !important;
  border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
}

.divide-yellow-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #faf089 !important;
  border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
}

.divide-yellow-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #f6e05e !important;
  border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
}

.divide-yellow-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #ecc94b !important;
  border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
}

.divide-yellow-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #d69e2e !important;
  border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
}

.divide-yellow-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #b7791f !important;
  border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
}

.divide-yellow-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #975a16 !important;
  border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
}

.divide-yellow-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #744210 !important;
  border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
}

.divide-green-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #f0fff4 !important;
  border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
}

.divide-green-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #c6f6d5 !important;
  border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
}

.divide-green-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9ae6b4 !important;
  border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
}

.divide-green-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #68d391 !important;
  border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
}

.divide-green-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #48bb78 !important;
  border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
}

.divide-green-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #38a169 !important;
  border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
}

.divide-green-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #2f855a !important;
  border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
}

.divide-green-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #276749 !important;
  border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
}

.divide-green-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #22543d !important;
  border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
}

.divide-teal-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #e6fffa !important;
  border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
}

.divide-teal-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #b2f5ea !important;
  border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
}

.divide-teal-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #81e6d9 !important;
  border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
}

.divide-teal-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #4fd1c5 !important;
  border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
}

.divide-teal-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #38b2ac !important;
  border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
}

.divide-teal-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #319795 !important;
  border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
}

.divide-teal-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #2c7a7b !important;
  border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
}

.divide-teal-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #285e61 !important;
  border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
}

.divide-teal-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #234e52 !important;
  border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
}

.divide-blue-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #ebf8ff !important;
  border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
}

.divide-blue-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #bee3f8 !important;
  border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
}

.divide-blue-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #90cdf4 !important;
  border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
}

.divide-blue-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #63b3ed !important;
  border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
}

.divide-blue-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #4299e1 !important;
  border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
}

.divide-blue-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #3182ce !important;
  border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
}

.divide-blue-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #2b6cb0 !important;
  border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
}

.divide-blue-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #2c5282 !important;
  border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
}

.divide-blue-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #2a4365 !important;
  border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
}

.divide-indigo-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #ebf4ff !important;
  border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
}

.divide-indigo-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #c3dafe !important;
  border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
}

.divide-indigo-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #a3bffa !important;
  border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
}

.divide-indigo-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #7f9cf5 !important;
  border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
}

.divide-indigo-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #667eea !important;
  border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
}

.divide-indigo-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #5a67d8 !important;
  border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
}

.divide-indigo-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #4c51bf !important;
  border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
}

.divide-indigo-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #434190 !important;
  border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
}

.divide-indigo-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #3c366b !important;
  border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
}

.divide-purple-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #faf5ff !important;
  border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
}

.divide-purple-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #e9d8fd !important;
  border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
}

.divide-purple-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #d6bcfa !important;
  border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
}

.divide-purple-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #b794f4 !important;
  border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
}

.divide-purple-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9f7aea !important;
  border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
}

.divide-purple-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #805ad5 !important;
  border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
}

.divide-purple-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #6b46c1 !important;
  border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
}

.divide-purple-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #553c9a !important;
  border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
}

.divide-purple-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #44337a !important;
  border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
}

.divide-pink-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fff5f7 !important;
  border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
}

.divide-pink-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fed7e2 !important;
  border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
}

.divide-pink-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fbb6ce !important;
  border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
}

.divide-pink-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #f687b3 !important;
  border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
}

.divide-pink-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #ed64a6 !important;
  border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
}

.divide-pink-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #d53f8c !important;
  border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
}

.divide-pink-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #b83280 !important;
  border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
}

.divide-pink-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #97266d !important;
  border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
}

.divide-pink-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #702459 !important;
  border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
}

.divide-primary > :not(template) ~ :not(template) {
  border-color: var(--primary) !important;
}

.divide-accent-1 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #fafafa !important;
  border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
}

.divide-accent-2 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #eaeaea !important;
  border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
}

.divide-solid > :not(template) ~ :not(template) {
  border-style: solid !important;
}

.divide-dashed > :not(template) ~ :not(template) {
  border-style: dashed !important;
}

.divide-dotted > :not(template) ~ :not(template) {
  border-style: dotted !important;
}

.divide-double > :not(template) ~ :not(template) {
  border-style: double !important;
}

.divide-none > :not(template) ~ :not(template) {
  border-style: none !important;
}

.divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0 !important;
}

.divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: 0.25 !important;
}

.divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: 0.5 !important;
}

.divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: 0.75 !important;
}

.divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.not-sr-only {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.focus\:sr-only:focus {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.focus\:not-sr-only:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.appearance-none {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
}

.bg-fixed {
  background-attachment: fixed !important;
}

.bg-local {
  background-attachment: local !important;
}

.bg-scroll {
  background-attachment: scroll !important;
}

.bg-clip-border {
  background-clip: border-box !important;
}

.bg-clip-padding {
  background-clip: padding-box !important;
}

.bg-clip-content {
  background-clip: content-box !important;
}

.bg-clip-text {
  -webkit-background-clip: text !important;
          background-clip: text !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-current {
  background-color: currentColor !important;
}

.bg-black {
  --bg-opacity: 1 !important;
  background-color: #000 !important;
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.bg-white {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.bg-gray-100 {
  --bg-opacity: 1 !important;
  background-color: #f7fafc !important;
  background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
}

.bg-gray-200 {
  --bg-opacity: 1 !important;
  background-color: #edf2f7 !important;
  background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
}

.bg-gray-300 {
  --bg-opacity: 1 !important;
  background-color: #e2e8f0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}

.bg-gray-400 {
  --bg-opacity: 1 !important;
  background-color: #cbd5e0 !important;
  background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
}

.bg-gray-500 {
  --bg-opacity: 1 !important;
  background-color: #a0aec0 !important;
  background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
}

.bg-gray-600 {
  --bg-opacity: 1 !important;
  background-color: #718096 !important;
  background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
}

.bg-gray-700 {
  --bg-opacity: 1 !important;
  background-color: #4a5568 !important;
  background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
}

.bg-gray-800 {
  --bg-opacity: 1 !important;
  background-color: #2d3748 !important;
  background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
}

.bg-gray-900 {
  --bg-opacity: 1 !important;
  background-color: #1a202c !important;
  background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
}

.bg-red-100 {
  --bg-opacity: 1 !important;
  background-color: #fff5f5 !important;
  background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
}

.bg-red-200 {
  --bg-opacity: 1 !important;
  background-color: #fed7d7 !important;
  background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
}

.bg-red-300 {
  --bg-opacity: 1 !important;
  background-color: #feb2b2 !important;
  background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
}

.bg-red-400 {
  --bg-opacity: 1 !important;
  background-color: #fc8181 !important;
  background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
}

.bg-red-500 {
  --bg-opacity: 1 !important;
  background-color: #f56565 !important;
  background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
}

.bg-red-600 {
  --bg-opacity: 1 !important;
  background-color: #e53e3e !important;
  background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
}

.bg-red-700 {
  --bg-opacity: 1 !important;
  background-color: #c53030 !important;
  background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
}

.bg-red-800 {
  --bg-opacity: 1 !important;
  background-color: #9b2c2c !important;
  background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
}

.bg-red-900 {
  --bg-opacity: 1 !important;
  background-color: #742a2a !important;
  background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
}

.bg-orange-100 {
  --bg-opacity: 1 !important;
  background-color: #fffaf0 !important;
  background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
}

.bg-orange-200 {
  --bg-opacity: 1 !important;
  background-color: #feebc8 !important;
  background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
}

.bg-orange-300 {
  --bg-opacity: 1 !important;
  background-color: #fbd38d !important;
  background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
}

.bg-orange-400 {
  --bg-opacity: 1 !important;
  background-color: #f6ad55 !important;
  background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
}

.bg-orange-500 {
  --bg-opacity: 1 !important;
  background-color: #ed8936 !important;
  background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
}

.bg-orange-600 {
  --bg-opacity: 1 !important;
  background-color: #dd6b20 !important;
  background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
}

.bg-orange-700 {
  --bg-opacity: 1 !important;
  background-color: #c05621 !important;
  background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
}

.bg-orange-800 {
  --bg-opacity: 1 !important;
  background-color: #9c4221 !important;
  background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
}

.bg-orange-900 {
  --bg-opacity: 1 !important;
  background-color: #7b341e !important;
  background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
}

.bg-yellow-100 {
  --bg-opacity: 1 !important;
  background-color: #fffff0 !important;
  background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
}

.bg-yellow-200 {
  --bg-opacity: 1 !important;
  background-color: #fefcbf !important;
  background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
}

.bg-yellow-300 {
  --bg-opacity: 1 !important;
  background-color: #faf089 !important;
  background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
}

.bg-yellow-400 {
  --bg-opacity: 1 !important;
  background-color: #f6e05e !important;
  background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
}

.bg-yellow-500 {
  --bg-opacity: 1 !important;
  background-color: #ecc94b !important;
  background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
}

.bg-yellow-600 {
  --bg-opacity: 1 !important;
  background-color: #d69e2e !important;
  background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
}

.bg-yellow-700 {
  --bg-opacity: 1 !important;
  background-color: #b7791f !important;
  background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
}

.bg-yellow-800 {
  --bg-opacity: 1 !important;
  background-color: #975a16 !important;
  background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
}

.bg-yellow-900 {
  --bg-opacity: 1 !important;
  background-color: #744210 !important;
  background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
}

.bg-green-100 {
  --bg-opacity: 1 !important;
  background-color: #f0fff4 !important;
  background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
}

.bg-green-200 {
  --bg-opacity: 1 !important;
  background-color: #c6f6d5 !important;
  background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
}

.bg-green-300 {
  --bg-opacity: 1 !important;
  background-color: #9ae6b4 !important;
  background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
}

.bg-green-400 {
  --bg-opacity: 1 !important;
  background-color: #68d391 !important;
  background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
}

.bg-green-500 {
  --bg-opacity: 1 !important;
  background-color: #48bb78 !important;
  background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
}

.bg-green-600 {
  --bg-opacity: 1 !important;
  background-color: #38a169 !important;
  background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
}

.bg-green-700 {
  --bg-opacity: 1 !important;
  background-color: #2f855a !important;
  background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
}

.bg-green-800 {
  --bg-opacity: 1 !important;
  background-color: #276749 !important;
  background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
}

.bg-green-900 {
  --bg-opacity: 1 !important;
  background-color: #22543d !important;
  background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
}

.bg-teal-100 {
  --bg-opacity: 1 !important;
  background-color: #e6fffa !important;
  background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
}

.bg-teal-200 {
  --bg-opacity: 1 !important;
  background-color: #b2f5ea !important;
  background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
}

.bg-teal-300 {
  --bg-opacity: 1 !important;
  background-color: #81e6d9 !important;
  background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
}

.bg-teal-400 {
  --bg-opacity: 1 !important;
  background-color: #4fd1c5 !important;
  background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
}

.bg-teal-500 {
  --bg-opacity: 1 !important;
  background-color: #38b2ac !important;
  background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
}

.bg-teal-600 {
  --bg-opacity: 1 !important;
  background-color: #319795 !important;
  background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
}

.bg-teal-700 {
  --bg-opacity: 1 !important;
  background-color: #2c7a7b !important;
  background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
}

.bg-teal-800 {
  --bg-opacity: 1 !important;
  background-color: #285e61 !important;
  background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
}

.bg-teal-900 {
  --bg-opacity: 1 !important;
  background-color: #234e52 !important;
  background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
}

.bg-blue-100 {
  --bg-opacity: 1 !important;
  background-color: #ebf8ff !important;
  background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
}

.bg-blue-200 {
  --bg-opacity: 1 !important;
  background-color: #bee3f8 !important;
  background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
}

.bg-blue-300 {
  --bg-opacity: 1 !important;
  background-color: #90cdf4 !important;
  background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
}

.bg-blue-400 {
  --bg-opacity: 1 !important;
  background-color: #63b3ed !important;
  background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
}

.bg-blue-500 {
  --bg-opacity: 1 !important;
  background-color: #4299e1 !important;
  background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
}

.bg-blue-600 {
  --bg-opacity: 1 !important;
  background-color: #3182ce !important;
  background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
}

.bg-blue-700 {
  --bg-opacity: 1 !important;
  background-color: #2b6cb0 !important;
  background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
}

.bg-blue-800 {
  --bg-opacity: 1 !important;
  background-color: #2c5282 !important;
  background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
}

.bg-blue-900 {
  --bg-opacity: 1 !important;
  background-color: #2a4365 !important;
  background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
}

.bg-indigo-100 {
  --bg-opacity: 1 !important;
  background-color: #ebf4ff !important;
  background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
}

.bg-indigo-200 {
  --bg-opacity: 1 !important;
  background-color: #c3dafe !important;
  background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
}

.bg-indigo-300 {
  --bg-opacity: 1 !important;
  background-color: #a3bffa !important;
  background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
}

.bg-indigo-400 {
  --bg-opacity: 1 !important;
  background-color: #7f9cf5 !important;
  background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
}

.bg-indigo-500 {
  --bg-opacity: 1 !important;
  background-color: #667eea !important;
  background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
}

.bg-indigo-600 {
  --bg-opacity: 1 !important;
  background-color: #5a67d8 !important;
  background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
}

.bg-indigo-700 {
  --bg-opacity: 1 !important;
  background-color: #4c51bf !important;
  background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
}

.bg-indigo-800 {
  --bg-opacity: 1 !important;
  background-color: #434190 !important;
  background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
}

.bg-indigo-900 {
  --bg-opacity: 1 !important;
  background-color: #3c366b !important;
  background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
}

.bg-purple-100 {
  --bg-opacity: 1 !important;
  background-color: #faf5ff !important;
  background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
}

.bg-purple-200 {
  --bg-opacity: 1 !important;
  background-color: #e9d8fd !important;
  background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
}

.bg-purple-300 {
  --bg-opacity: 1 !important;
  background-color: #d6bcfa !important;
  background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
}

.bg-purple-400 {
  --bg-opacity: 1 !important;
  background-color: #b794f4 !important;
  background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
}

.bg-purple-500 {
  --bg-opacity: 1 !important;
  background-color: #9f7aea !important;
  background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
}

.bg-purple-600 {
  --bg-opacity: 1 !important;
  background-color: #805ad5 !important;
  background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
}

.bg-purple-700 {
  --bg-opacity: 1 !important;
  background-color: #6b46c1 !important;
  background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
}

.bg-purple-800 {
  --bg-opacity: 1 !important;
  background-color: #553c9a !important;
  background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
}

.bg-purple-900 {
  --bg-opacity: 1 !important;
  background-color: #44337a !important;
  background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
}

.bg-pink-100 {
  --bg-opacity: 1 !important;
  background-color: #fff5f7 !important;
  background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
}

.bg-pink-200 {
  --bg-opacity: 1 !important;
  background-color: #fed7e2 !important;
  background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
}

.bg-pink-300 {
  --bg-opacity: 1 !important;
  background-color: #fbb6ce !important;
  background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
}

.bg-pink-400 {
  --bg-opacity: 1 !important;
  background-color: #f687b3 !important;
  background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
}

.bg-pink-500 {
  --bg-opacity: 1 !important;
  background-color: #ed64a6 !important;
  background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
}

.bg-pink-600 {
  --bg-opacity: 1 !important;
  background-color: #d53f8c !important;
  background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
}

.bg-pink-700 {
  --bg-opacity: 1 !important;
  background-color: #b83280 !important;
  background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
}

.bg-pink-800 {
  --bg-opacity: 1 !important;
  background-color: #97266d !important;
  background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
}

.bg-pink-900 {
  --bg-opacity: 1 !important;
  background-color: #702459 !important;
  background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-accent-1 {
  --bg-opacity: 1 !important;
  background-color: #fafafa !important;
  background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
}

.bg-accent-2 {
  --bg-opacity: 1 !important;
  background-color: #eaeaea !important;
  background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
}

.hover\:bg-transparent:hover {
  background-color: transparent !important;
}

.hover\:bg-current:hover {
  background-color: currentColor !important;
}

.hover\:bg-black:hover {
  --bg-opacity: 1 !important;
  background-color: #000 !important;
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.hover\:bg-white:hover {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.hover\:bg-gray-100:hover {
  --bg-opacity: 1 !important;
  background-color: #f7fafc !important;
  background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1 !important;
  background-color: #edf2f7 !important;
  background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
}

.hover\:bg-gray-300:hover {
  --bg-opacity: 1 !important;
  background-color: #e2e8f0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}

.hover\:bg-gray-400:hover {
  --bg-opacity: 1 !important;
  background-color: #cbd5e0 !important;
  background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
}

.hover\:bg-gray-500:hover {
  --bg-opacity: 1 !important;
  background-color: #a0aec0 !important;
  background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
}

.hover\:bg-gray-600:hover {
  --bg-opacity: 1 !important;
  background-color: #718096 !important;
  background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
}

.hover\:bg-gray-700:hover {
  --bg-opacity: 1 !important;
  background-color: #4a5568 !important;
  background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
}

.hover\:bg-gray-800:hover {
  --bg-opacity: 1 !important;
  background-color: #2d3748 !important;
  background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
}

.hover\:bg-gray-900:hover {
  --bg-opacity: 1 !important;
  background-color: #1a202c !important;
  background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
}

.hover\:bg-red-100:hover {
  --bg-opacity: 1 !important;
  background-color: #fff5f5 !important;
  background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
}

.hover\:bg-red-200:hover {
  --bg-opacity: 1 !important;
  background-color: #fed7d7 !important;
  background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
}

.hover\:bg-red-300:hover {
  --bg-opacity: 1 !important;
  background-color: #feb2b2 !important;
  background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
}

.hover\:bg-red-400:hover {
  --bg-opacity: 1 !important;
  background-color: #fc8181 !important;
  background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
}

.hover\:bg-red-500:hover {
  --bg-opacity: 1 !important;
  background-color: #f56565 !important;
  background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
}

.hover\:bg-red-600:hover {
  --bg-opacity: 1 !important;
  background-color: #e53e3e !important;
  background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
}

.hover\:bg-red-700:hover {
  --bg-opacity: 1 !important;
  background-color: #c53030 !important;
  background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
}

.hover\:bg-red-800:hover {
  --bg-opacity: 1 !important;
  background-color: #9b2c2c !important;
  background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
}

.hover\:bg-red-900:hover {
  --bg-opacity: 1 !important;
  background-color: #742a2a !important;
  background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
}

.hover\:bg-orange-100:hover {
  --bg-opacity: 1 !important;
  background-color: #fffaf0 !important;
  background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
}

.hover\:bg-orange-200:hover {
  --bg-opacity: 1 !important;
  background-color: #feebc8 !important;
  background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
}

.hover\:bg-orange-300:hover {
  --bg-opacity: 1 !important;
  background-color: #fbd38d !important;
  background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
}

.hover\:bg-orange-400:hover {
  --bg-opacity: 1 !important;
  background-color: #f6ad55 !important;
  background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
}

.hover\:bg-orange-500:hover {
  --bg-opacity: 1 !important;
  background-color: #ed8936 !important;
  background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
}

.hover\:bg-orange-600:hover {
  --bg-opacity: 1 !important;
  background-color: #dd6b20 !important;
  background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
}

.hover\:bg-orange-700:hover {
  --bg-opacity: 1 !important;
  background-color: #c05621 !important;
  background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
}

.hover\:bg-orange-800:hover {
  --bg-opacity: 1 !important;
  background-color: #9c4221 !important;
  background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
}

.hover\:bg-orange-900:hover {
  --bg-opacity: 1 !important;
  background-color: #7b341e !important;
  background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-100:hover {
  --bg-opacity: 1 !important;
  background-color: #fffff0 !important;
  background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-200:hover {
  --bg-opacity: 1 !important;
  background-color: #fefcbf !important;
  background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-300:hover {
  --bg-opacity: 1 !important;
  background-color: #faf089 !important;
  background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-400:hover {
  --bg-opacity: 1 !important;
  background-color: #f6e05e !important;
  background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-500:hover {
  --bg-opacity: 1 !important;
  background-color: #ecc94b !important;
  background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-600:hover {
  --bg-opacity: 1 !important;
  background-color: #d69e2e !important;
  background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-700:hover {
  --bg-opacity: 1 !important;
  background-color: #b7791f !important;
  background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-800:hover {
  --bg-opacity: 1 !important;
  background-color: #975a16 !important;
  background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
}

.hover\:bg-yellow-900:hover {
  --bg-opacity: 1 !important;
  background-color: #744210 !important;
  background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
}

.hover\:bg-green-100:hover {
  --bg-opacity: 1 !important;
  background-color: #f0fff4 !important;
  background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
}

.hover\:bg-green-200:hover {
  --bg-opacity: 1 !important;
  background-color: #c6f6d5 !important;
  background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
}

.hover\:bg-green-300:hover {
  --bg-opacity: 1 !important;
  background-color: #9ae6b4 !important;
  background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
}

.hover\:bg-green-400:hover {
  --bg-opacity: 1 !important;
  background-color: #68d391 !important;
  background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
}

.hover\:bg-green-500:hover {
  --bg-opacity: 1 !important;
  background-color: #48bb78 !important;
  background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
}

.hover\:bg-green-600:hover {
  --bg-opacity: 1 !important;
  background-color: #38a169 !important;
  background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
}

.hover\:bg-green-700:hover {
  --bg-opacity: 1 !important;
  background-color: #2f855a !important;
  background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
}

.hover\:bg-green-800:hover {
  --bg-opacity: 1 !important;
  background-color: #276749 !important;
  background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
}

.hover\:bg-green-900:hover {
  --bg-opacity: 1 !important;
  background-color: #22543d !important;
  background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
}

.hover\:bg-teal-100:hover {
  --bg-opacity: 1 !important;
  background-color: #e6fffa !important;
  background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
}

.hover\:bg-teal-200:hover {
  --bg-opacity: 1 !important;
  background-color: #b2f5ea !important;
  background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
}

.hover\:bg-teal-300:hover {
  --bg-opacity: 1 !important;
  background-color: #81e6d9 !important;
  background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
}

.hover\:bg-teal-400:hover {
  --bg-opacity: 1 !important;
  background-color: #4fd1c5 !important;
  background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
}

.hover\:bg-teal-500:hover {
  --bg-opacity: 1 !important;
  background-color: #38b2ac !important;
  background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
}

.hover\:bg-teal-600:hover {
  --bg-opacity: 1 !important;
  background-color: #319795 !important;
  background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
}

.hover\:bg-teal-700:hover {
  --bg-opacity: 1 !important;
  background-color: #2c7a7b !important;
  background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
}

.hover\:bg-teal-800:hover {
  --bg-opacity: 1 !important;
  background-color: #285e61 !important;
  background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
}

.hover\:bg-teal-900:hover {
  --bg-opacity: 1 !important;
  background-color: #234e52 !important;
  background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
}

.hover\:bg-blue-100:hover {
  --bg-opacity: 1 !important;
  background-color: #ebf8ff !important;
  background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
}

.hover\:bg-blue-200:hover {
  --bg-opacity: 1 !important;
  background-color: #bee3f8 !important;
  background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
}

.hover\:bg-blue-300:hover {
  --bg-opacity: 1 !important;
  background-color: #90cdf4 !important;
  background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
}

.hover\:bg-blue-400:hover {
  --bg-opacity: 1 !important;
  background-color: #63b3ed !important;
  background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
}

.hover\:bg-blue-500:hover {
  --bg-opacity: 1 !important;
  background-color: #4299e1 !important;
  background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
}

.hover\:bg-blue-600:hover {
  --bg-opacity: 1 !important;
  background-color: #3182ce !important;
  background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
}

.hover\:bg-blue-700:hover {
  --bg-opacity: 1 !important;
  background-color: #2b6cb0 !important;
  background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
}

.hover\:bg-blue-800:hover {
  --bg-opacity: 1 !important;
  background-color: #2c5282 !important;
  background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
}

.hover\:bg-blue-900:hover {
  --bg-opacity: 1 !important;
  background-color: #2a4365 !important;
  background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-100:hover {
  --bg-opacity: 1 !important;
  background-color: #ebf4ff !important;
  background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-200:hover {
  --bg-opacity: 1 !important;
  background-color: #c3dafe !important;
  background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-300:hover {
  --bg-opacity: 1 !important;
  background-color: #a3bffa !important;
  background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-400:hover {
  --bg-opacity: 1 !important;
  background-color: #7f9cf5 !important;
  background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-500:hover {
  --bg-opacity: 1 !important;
  background-color: #667eea !important;
  background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-600:hover {
  --bg-opacity: 1 !important;
  background-color: #5a67d8 !important;
  background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-700:hover {
  --bg-opacity: 1 !important;
  background-color: #4c51bf !important;
  background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-800:hover {
  --bg-opacity: 1 !important;
  background-color: #434190 !important;
  background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
}

.hover\:bg-indigo-900:hover {
  --bg-opacity: 1 !important;
  background-color: #3c366b !important;
  background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
}

.hover\:bg-purple-100:hover {
  --bg-opacity: 1 !important;
  background-color: #faf5ff !important;
  background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
}

.hover\:bg-purple-200:hover {
  --bg-opacity: 1 !important;
  background-color: #e9d8fd !important;
  background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
}

.hover\:bg-purple-300:hover {
  --bg-opacity: 1 !important;
  background-color: #d6bcfa !important;
  background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
}

.hover\:bg-purple-400:hover {
  --bg-opacity: 1 !important;
  background-color: #b794f4 !important;
  background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
}

.hover\:bg-purple-500:hover {
  --bg-opacity: 1 !important;
  background-color: #9f7aea !important;
  background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
}

.hover\:bg-purple-600:hover {
  --bg-opacity: 1 !important;
  background-color: #805ad5 !important;
  background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
}

.hover\:bg-purple-700:hover {
  --bg-opacity: 1 !important;
  background-color: #6b46c1 !important;
  background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
}

.hover\:bg-purple-800:hover {
  --bg-opacity: 1 !important;
  background-color: #553c9a !important;
  background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
}

.hover\:bg-purple-900:hover {
  --bg-opacity: 1 !important;
  background-color: #44337a !important;
  background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
}

.hover\:bg-pink-100:hover {
  --bg-opacity: 1 !important;
  background-color: #fff5f7 !important;
  background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
}

.hover\:bg-pink-200:hover {
  --bg-opacity: 1 !important;
  background-color: #fed7e2 !important;
  background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
}

.hover\:bg-pink-300:hover {
  --bg-opacity: 1 !important;
  background-color: #fbb6ce !important;
  background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
}

.hover\:bg-pink-400:hover {
  --bg-opacity: 1 !important;
  background-color: #f687b3 !important;
  background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
}

.hover\:bg-pink-500:hover {
  --bg-opacity: 1 !important;
  background-color: #ed64a6 !important;
  background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
}

.hover\:bg-pink-600:hover {
  --bg-opacity: 1 !important;
  background-color: #d53f8c !important;
  background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
}

.hover\:bg-pink-700:hover {
  --bg-opacity: 1 !important;
  background-color: #b83280 !important;
  background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
}

.hover\:bg-pink-800:hover {
  --bg-opacity: 1 !important;
  background-color: #97266d !important;
  background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
}

.hover\:bg-pink-900:hover {
  --bg-opacity: 1 !important;
  background-color: #702459 !important;
  background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
}

.hover\:bg-primary:hover {
  background-color: var(--primary) !important;
}

.hover\:bg-accent-1:hover {
  --bg-opacity: 1 !important;
  background-color: #fafafa !important;
  background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
}

.hover\:bg-accent-2:hover {
  --bg-opacity: 1 !important;
  background-color: #eaeaea !important;
  background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
}

.focus\:bg-transparent:focus {
  background-color: transparent !important;
}

.focus\:bg-current:focus {
  background-color: currentColor !important;
}

.focus\:bg-black:focus {
  --bg-opacity: 1 !important;
  background-color: #000 !important;
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.focus\:bg-white:focus {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.focus\:bg-gray-100:focus {
  --bg-opacity: 1 !important;
  background-color: #f7fafc !important;
  background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
}

.focus\:bg-gray-200:focus {
  --bg-opacity: 1 !important;
  background-color: #edf2f7 !important;
  background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
}

.focus\:bg-gray-300:focus {
  --bg-opacity: 1 !important;
  background-color: #e2e8f0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}

.focus\:bg-gray-400:focus {
  --bg-opacity: 1 !important;
  background-color: #cbd5e0 !important;
  background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
}

.focus\:bg-gray-500:focus {
  --bg-opacity: 1 !important;
  background-color: #a0aec0 !important;
  background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
}

.focus\:bg-gray-600:focus {
  --bg-opacity: 1 !important;
  background-color: #718096 !important;
  background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
}

.focus\:bg-gray-700:focus {
  --bg-opacity: 1 !important;
  background-color: #4a5568 !important;
  background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
}

.focus\:bg-gray-800:focus {
  --bg-opacity: 1 !important;
  background-color: #2d3748 !important;
  background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
}

.focus\:bg-gray-900:focus {
  --bg-opacity: 1 !important;
  background-color: #1a202c !important;
  background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
}

.focus\:bg-red-100:focus {
  --bg-opacity: 1 !important;
  background-color: #fff5f5 !important;
  background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
}

.focus\:bg-red-200:focus {
  --bg-opacity: 1 !important;
  background-color: #fed7d7 !important;
  background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
}

.focus\:bg-red-300:focus {
  --bg-opacity: 1 !important;
  background-color: #feb2b2 !important;
  background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
}

.focus\:bg-red-400:focus {
  --bg-opacity: 1 !important;
  background-color: #fc8181 !important;
  background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
}

.focus\:bg-red-500:focus {
  --bg-opacity: 1 !important;
  background-color: #f56565 !important;
  background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
}

.focus\:bg-red-600:focus {
  --bg-opacity: 1 !important;
  background-color: #e53e3e !important;
  background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
}

.focus\:bg-red-700:focus {
  --bg-opacity: 1 !important;
  background-color: #c53030 !important;
  background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
}

.focus\:bg-red-800:focus {
  --bg-opacity: 1 !important;
  background-color: #9b2c2c !important;
  background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
}

.focus\:bg-red-900:focus {
  --bg-opacity: 1 !important;
  background-color: #742a2a !important;
  background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
}

.focus\:bg-orange-100:focus {
  --bg-opacity: 1 !important;
  background-color: #fffaf0 !important;
  background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
}

.focus\:bg-orange-200:focus {
  --bg-opacity: 1 !important;
  background-color: #feebc8 !important;
  background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
}

.focus\:bg-orange-300:focus {
  --bg-opacity: 1 !important;
  background-color: #fbd38d !important;
  background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
}

.focus\:bg-orange-400:focus {
  --bg-opacity: 1 !important;
  background-color: #f6ad55 !important;
  background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
}

.focus\:bg-orange-500:focus {
  --bg-opacity: 1 !important;
  background-color: #ed8936 !important;
  background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
}

.focus\:bg-orange-600:focus {
  --bg-opacity: 1 !important;
  background-color: #dd6b20 !important;
  background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
}

.focus\:bg-orange-700:focus {
  --bg-opacity: 1 !important;
  background-color: #c05621 !important;
  background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
}

.focus\:bg-orange-800:focus {
  --bg-opacity: 1 !important;
  background-color: #9c4221 !important;
  background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
}

.focus\:bg-orange-900:focus {
  --bg-opacity: 1 !important;
  background-color: #7b341e !important;
  background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-100:focus {
  --bg-opacity: 1 !important;
  background-color: #fffff0 !important;
  background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-200:focus {
  --bg-opacity: 1 !important;
  background-color: #fefcbf !important;
  background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-300:focus {
  --bg-opacity: 1 !important;
  background-color: #faf089 !important;
  background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-400:focus {
  --bg-opacity: 1 !important;
  background-color: #f6e05e !important;
  background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-500:focus {
  --bg-opacity: 1 !important;
  background-color: #ecc94b !important;
  background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-600:focus {
  --bg-opacity: 1 !important;
  background-color: #d69e2e !important;
  background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-700:focus {
  --bg-opacity: 1 !important;
  background-color: #b7791f !important;
  background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-800:focus {
  --bg-opacity: 1 !important;
  background-color: #975a16 !important;
  background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
}

.focus\:bg-yellow-900:focus {
  --bg-opacity: 1 !important;
  background-color: #744210 !important;
  background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
}

.focus\:bg-green-100:focus {
  --bg-opacity: 1 !important;
  background-color: #f0fff4 !important;
  background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
}

.focus\:bg-green-200:focus {
  --bg-opacity: 1 !important;
  background-color: #c6f6d5 !important;
  background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
}

.focus\:bg-green-300:focus {
  --bg-opacity: 1 !important;
  background-color: #9ae6b4 !important;
  background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
}

.focus\:bg-green-400:focus {
  --bg-opacity: 1 !important;
  background-color: #68d391 !important;
  background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
}

.focus\:bg-green-500:focus {
  --bg-opacity: 1 !important;
  background-color: #48bb78 !important;
  background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
}

.focus\:bg-green-600:focus {
  --bg-opacity: 1 !important;
  background-color: #38a169 !important;
  background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
}

.focus\:bg-green-700:focus {
  --bg-opacity: 1 !important;
  background-color: #2f855a !important;
  background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
}

.focus\:bg-green-800:focus {
  --bg-opacity: 1 !important;
  background-color: #276749 !important;
  background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
}

.focus\:bg-green-900:focus {
  --bg-opacity: 1 !important;
  background-color: #22543d !important;
  background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
}

.focus\:bg-teal-100:focus {
  --bg-opacity: 1 !important;
  background-color: #e6fffa !important;
  background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
}

.focus\:bg-teal-200:focus {
  --bg-opacity: 1 !important;
  background-color: #b2f5ea !important;
  background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
}

.focus\:bg-teal-300:focus {
  --bg-opacity: 1 !important;
  background-color: #81e6d9 !important;
  background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
}

.focus\:bg-teal-400:focus {
  --bg-opacity: 1 !important;
  background-color: #4fd1c5 !important;
  background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
}

.focus\:bg-teal-500:focus {
  --bg-opacity: 1 !important;
  background-color: #38b2ac !important;
  background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
}

.focus\:bg-teal-600:focus {
  --bg-opacity: 1 !important;
  background-color: #319795 !important;
  background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
}

.focus\:bg-teal-700:focus {
  --bg-opacity: 1 !important;
  background-color: #2c7a7b !important;
  background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
}

.focus\:bg-teal-800:focus {
  --bg-opacity: 1 !important;
  background-color: #285e61 !important;
  background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
}

.focus\:bg-teal-900:focus {
  --bg-opacity: 1 !important;
  background-color: #234e52 !important;
  background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
}

.focus\:bg-blue-100:focus {
  --bg-opacity: 1 !important;
  background-color: #ebf8ff !important;
  background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
}

.focus\:bg-blue-200:focus {
  --bg-opacity: 1 !important;
  background-color: #bee3f8 !important;
  background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
}

.focus\:bg-blue-300:focus {
  --bg-opacity: 1 !important;
  background-color: #90cdf4 !important;
  background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
}

.focus\:bg-blue-400:focus {
  --bg-opacity: 1 !important;
  background-color: #63b3ed !important;
  background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
}

.focus\:bg-blue-500:focus {
  --bg-opacity: 1 !important;
  background-color: #4299e1 !important;
  background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
}

.focus\:bg-blue-600:focus {
  --bg-opacity: 1 !important;
  background-color: #3182ce !important;
  background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
}

.focus\:bg-blue-700:focus {
  --bg-opacity: 1 !important;
  background-color: #2b6cb0 !important;
  background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
}

.focus\:bg-blue-800:focus {
  --bg-opacity: 1 !important;
  background-color: #2c5282 !important;
  background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
}

.focus\:bg-blue-900:focus {
  --bg-opacity: 1 !important;
  background-color: #2a4365 !important;
  background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-100:focus {
  --bg-opacity: 1 !important;
  background-color: #ebf4ff !important;
  background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-200:focus {
  --bg-opacity: 1 !important;
  background-color: #c3dafe !important;
  background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-300:focus {
  --bg-opacity: 1 !important;
  background-color: #a3bffa !important;
  background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-400:focus {
  --bg-opacity: 1 !important;
  background-color: #7f9cf5 !important;
  background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-500:focus {
  --bg-opacity: 1 !important;
  background-color: #667eea !important;
  background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-600:focus {
  --bg-opacity: 1 !important;
  background-color: #5a67d8 !important;
  background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-700:focus {
  --bg-opacity: 1 !important;
  background-color: #4c51bf !important;
  background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-800:focus {
  --bg-opacity: 1 !important;
  background-color: #434190 !important;
  background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
}

.focus\:bg-indigo-900:focus {
  --bg-opacity: 1 !important;
  background-color: #3c366b !important;
  background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
}

.focus\:bg-purple-100:focus {
  --bg-opacity: 1 !important;
  background-color: #faf5ff !important;
  background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
}

.focus\:bg-purple-200:focus {
  --bg-opacity: 1 !important;
  background-color: #e9d8fd !important;
  background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
}

.focus\:bg-purple-300:focus {
  --bg-opacity: 1 !important;
  background-color: #d6bcfa !important;
  background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
}

.focus\:bg-purple-400:focus {
  --bg-opacity: 1 !important;
  background-color: #b794f4 !important;
  background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
}

.focus\:bg-purple-500:focus {
  --bg-opacity: 1 !important;
  background-color: #9f7aea !important;
  background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
}

.focus\:bg-purple-600:focus {
  --bg-opacity: 1 !important;
  background-color: #805ad5 !important;
  background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
}

.focus\:bg-purple-700:focus {
  --bg-opacity: 1 !important;
  background-color: #6b46c1 !important;
  background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
}

.focus\:bg-purple-800:focus {
  --bg-opacity: 1 !important;
  background-color: #553c9a !important;
  background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
}

.focus\:bg-purple-900:focus {
  --bg-opacity: 1 !important;
  background-color: #44337a !important;
  background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
}

.focus\:bg-pink-100:focus {
  --bg-opacity: 1 !important;
  background-color: #fff5f7 !important;
  background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
}

.focus\:bg-pink-200:focus {
  --bg-opacity: 1 !important;
  background-color: #fed7e2 !important;
  background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
}

.focus\:bg-pink-300:focus {
  --bg-opacity: 1 !important;
  background-color: #fbb6ce !important;
  background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
}

.focus\:bg-pink-400:focus {
  --bg-opacity: 1 !important;
  background-color: #f687b3 !important;
  background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
}

.focus\:bg-pink-500:focus {
  --bg-opacity: 1 !important;
  background-color: #ed64a6 !important;
  background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
}

.focus\:bg-pink-600:focus {
  --bg-opacity: 1 !important;
  background-color: #d53f8c !important;
  background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
}

.focus\:bg-pink-700:focus {
  --bg-opacity: 1 !important;
  background-color: #b83280 !important;
  background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
}

.focus\:bg-pink-800:focus {
  --bg-opacity: 1 !important;
  background-color: #97266d !important;
  background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
}

.focus\:bg-pink-900:focus {
  --bg-opacity: 1 !important;
  background-color: #702459 !important;
  background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
}

.focus\:bg-primary:focus {
  background-color: var(--primary) !important;
}

.focus\:bg-accent-1:focus {
  --bg-opacity: 1 !important;
  background-color: #fafafa !important;
  background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
}

.focus\:bg-accent-2:focus {
  --bg-opacity: 1 !important;
  background-color: #eaeaea !important;
  background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
}

.bg-none {
  background-image: none !important;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
}

.from-transparent {
  --gradient-from-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.from-current {
  --gradient-from-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.from-black {
  --gradient-from-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.from-white {
  --gradient-from-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.from-gray-100 {
  --gradient-from-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.from-gray-200 {
  --gradient-from-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.from-gray-300 {
  --gradient-from-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.from-gray-400 {
  --gradient-from-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.from-gray-500 {
  --gradient-from-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.from-gray-600 {
  --gradient-from-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.from-gray-700 {
  --gradient-from-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.from-gray-800 {
  --gradient-from-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.from-gray-900 {
  --gradient-from-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.from-red-100 {
  --gradient-from-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.from-red-200 {
  --gradient-from-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.from-red-300 {
  --gradient-from-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.from-red-400 {
  --gradient-from-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.from-red-500 {
  --gradient-from-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.from-red-600 {
  --gradient-from-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.from-red-700 {
  --gradient-from-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.from-red-800 {
  --gradient-from-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.from-red-900 {
  --gradient-from-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.from-orange-100 {
  --gradient-from-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.from-orange-200 {
  --gradient-from-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.from-orange-300 {
  --gradient-from-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.from-orange-400 {
  --gradient-from-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.from-orange-500 {
  --gradient-from-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.from-orange-600 {
  --gradient-from-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.from-orange-700 {
  --gradient-from-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.from-orange-800 {
  --gradient-from-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.from-orange-900 {
  --gradient-from-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.from-yellow-100 {
  --gradient-from-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.from-yellow-200 {
  --gradient-from-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.from-yellow-300 {
  --gradient-from-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.from-yellow-400 {
  --gradient-from-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.from-yellow-500 {
  --gradient-from-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.from-yellow-600 {
  --gradient-from-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.from-yellow-700 {
  --gradient-from-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.from-yellow-800 {
  --gradient-from-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.from-yellow-900 {
  --gradient-from-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.from-green-100 {
  --gradient-from-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.from-green-200 {
  --gradient-from-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.from-green-300 {
  --gradient-from-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.from-green-400 {
  --gradient-from-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.from-green-500 {
  --gradient-from-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.from-green-600 {
  --gradient-from-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.from-green-700 {
  --gradient-from-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.from-green-800 {
  --gradient-from-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.from-green-900 {
  --gradient-from-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.from-teal-100 {
  --gradient-from-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.from-teal-200 {
  --gradient-from-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.from-teal-300 {
  --gradient-from-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.from-teal-400 {
  --gradient-from-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.from-teal-500 {
  --gradient-from-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.from-teal-600 {
  --gradient-from-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.from-teal-700 {
  --gradient-from-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.from-teal-800 {
  --gradient-from-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.from-teal-900 {
  --gradient-from-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.from-blue-100 {
  --gradient-from-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.from-blue-200 {
  --gradient-from-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.from-blue-300 {
  --gradient-from-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.from-blue-400 {
  --gradient-from-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.from-blue-500 {
  --gradient-from-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.from-blue-600 {
  --gradient-from-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.from-blue-700 {
  --gradient-from-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.from-blue-800 {
  --gradient-from-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.from-blue-900 {
  --gradient-from-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.from-indigo-100 {
  --gradient-from-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.from-indigo-200 {
  --gradient-from-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.from-indigo-300 {
  --gradient-from-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.from-indigo-400 {
  --gradient-from-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.from-indigo-500 {
  --gradient-from-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.from-indigo-600 {
  --gradient-from-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.from-indigo-700 {
  --gradient-from-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.from-indigo-800 {
  --gradient-from-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.from-indigo-900 {
  --gradient-from-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.from-purple-100 {
  --gradient-from-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.from-purple-200 {
  --gradient-from-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.from-purple-300 {
  --gradient-from-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.from-purple-400 {
  --gradient-from-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.from-purple-500 {
  --gradient-from-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.from-purple-600 {
  --gradient-from-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.from-purple-700 {
  --gradient-from-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.from-purple-800 {
  --gradient-from-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.from-purple-900 {
  --gradient-from-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.from-pink-100 {
  --gradient-from-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.from-pink-200 {
  --gradient-from-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.from-pink-300 {
  --gradient-from-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.from-pink-400 {
  --gradient-from-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.from-pink-500 {
  --gradient-from-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.from-pink-600 {
  --gradient-from-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.from-pink-700 {
  --gradient-from-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.from-pink-800 {
  --gradient-from-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.from-pink-900 {
  --gradient-from-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.from-primary {
  --gradient-from-color: var(--primary) !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.from-accent-1 {
  --gradient-from-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.from-accent-2 {
  --gradient-from-color: #eaeaea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
}

.via-transparent {
  --gradient-via-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.via-current {
  --gradient-via-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.via-black {
  --gradient-via-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.via-white {
  --gradient-via-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.via-gray-100 {
  --gradient-via-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.via-gray-200 {
  --gradient-via-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.via-gray-300 {
  --gradient-via-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.via-gray-400 {
  --gradient-via-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.via-gray-500 {
  --gradient-via-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.via-gray-600 {
  --gradient-via-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.via-gray-700 {
  --gradient-via-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.via-gray-800 {
  --gradient-via-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.via-gray-900 {
  --gradient-via-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.via-red-100 {
  --gradient-via-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.via-red-200 {
  --gradient-via-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.via-red-300 {
  --gradient-via-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.via-red-400 {
  --gradient-via-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.via-red-500 {
  --gradient-via-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.via-red-600 {
  --gradient-via-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.via-red-700 {
  --gradient-via-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.via-red-800 {
  --gradient-via-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.via-red-900 {
  --gradient-via-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.via-orange-100 {
  --gradient-via-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.via-orange-200 {
  --gradient-via-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.via-orange-300 {
  --gradient-via-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.via-orange-400 {
  --gradient-via-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.via-orange-500 {
  --gradient-via-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.via-orange-600 {
  --gradient-via-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.via-orange-700 {
  --gradient-via-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.via-orange-800 {
  --gradient-via-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.via-orange-900 {
  --gradient-via-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.via-yellow-100 {
  --gradient-via-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.via-yellow-200 {
  --gradient-via-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.via-yellow-300 {
  --gradient-via-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.via-yellow-400 {
  --gradient-via-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.via-yellow-500 {
  --gradient-via-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.via-yellow-600 {
  --gradient-via-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.via-yellow-700 {
  --gradient-via-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.via-yellow-800 {
  --gradient-via-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.via-yellow-900 {
  --gradient-via-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.via-green-100 {
  --gradient-via-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.via-green-200 {
  --gradient-via-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.via-green-300 {
  --gradient-via-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.via-green-400 {
  --gradient-via-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.via-green-500 {
  --gradient-via-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.via-green-600 {
  --gradient-via-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.via-green-700 {
  --gradient-via-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.via-green-800 {
  --gradient-via-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.via-green-900 {
  --gradient-via-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.via-teal-100 {
  --gradient-via-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.via-teal-200 {
  --gradient-via-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.via-teal-300 {
  --gradient-via-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.via-teal-400 {
  --gradient-via-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.via-teal-500 {
  --gradient-via-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.via-teal-600 {
  --gradient-via-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.via-teal-700 {
  --gradient-via-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.via-teal-800 {
  --gradient-via-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.via-teal-900 {
  --gradient-via-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.via-blue-100 {
  --gradient-via-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.via-blue-200 {
  --gradient-via-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.via-blue-300 {
  --gradient-via-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.via-blue-400 {
  --gradient-via-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.via-blue-500 {
  --gradient-via-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.via-blue-600 {
  --gradient-via-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.via-blue-700 {
  --gradient-via-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.via-blue-800 {
  --gradient-via-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.via-blue-900 {
  --gradient-via-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.via-indigo-100 {
  --gradient-via-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.via-indigo-200 {
  --gradient-via-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.via-indigo-300 {
  --gradient-via-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.via-indigo-400 {
  --gradient-via-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.via-indigo-500 {
  --gradient-via-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.via-indigo-600 {
  --gradient-via-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.via-indigo-700 {
  --gradient-via-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.via-indigo-800 {
  --gradient-via-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.via-indigo-900 {
  --gradient-via-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.via-purple-100 {
  --gradient-via-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.via-purple-200 {
  --gradient-via-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.via-purple-300 {
  --gradient-via-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.via-purple-400 {
  --gradient-via-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.via-purple-500 {
  --gradient-via-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.via-purple-600 {
  --gradient-via-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.via-purple-700 {
  --gradient-via-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.via-purple-800 {
  --gradient-via-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.via-purple-900 {
  --gradient-via-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.via-pink-100 {
  --gradient-via-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.via-pink-200 {
  --gradient-via-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.via-pink-300 {
  --gradient-via-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.via-pink-400 {
  --gradient-via-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.via-pink-500 {
  --gradient-via-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.via-pink-600 {
  --gradient-via-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.via-pink-700 {
  --gradient-via-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.via-pink-800 {
  --gradient-via-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.via-pink-900 {
  --gradient-via-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.via-primary {
  --gradient-via-color: var(--primary) !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.via-accent-1 {
  --gradient-via-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.via-accent-2 {
  --gradient-via-color: #eaeaea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
}

.to-transparent {
  --gradient-to-color: transparent !important;
}

.to-current {
  --gradient-to-color: currentColor !important;
}

.to-black {
  --gradient-to-color: #000 !important;
}

.to-white {
  --gradient-to-color: #fff !important;
}

.to-gray-100 {
  --gradient-to-color: #f7fafc !important;
}

.to-gray-200 {
  --gradient-to-color: #edf2f7 !important;
}

.to-gray-300 {
  --gradient-to-color: #e2e8f0 !important;
}

.to-gray-400 {
  --gradient-to-color: #cbd5e0 !important;
}

.to-gray-500 {
  --gradient-to-color: #a0aec0 !important;
}

.to-gray-600 {
  --gradient-to-color: #718096 !important;
}

.to-gray-700 {
  --gradient-to-color: #4a5568 !important;
}

.to-gray-800 {
  --gradient-to-color: #2d3748 !important;
}

.to-gray-900 {
  --gradient-to-color: #1a202c !important;
}

.to-red-100 {
  --gradient-to-color: #fff5f5 !important;
}

.to-red-200 {
  --gradient-to-color: #fed7d7 !important;
}

.to-red-300 {
  --gradient-to-color: #feb2b2 !important;
}

.to-red-400 {
  --gradient-to-color: #fc8181 !important;
}

.to-red-500 {
  --gradient-to-color: #f56565 !important;
}

.to-red-600 {
  --gradient-to-color: #e53e3e !important;
}

.to-red-700 {
  --gradient-to-color: #c53030 !important;
}

.to-red-800 {
  --gradient-to-color: #9b2c2c !important;
}

.to-red-900 {
  --gradient-to-color: #742a2a !important;
}

.to-orange-100 {
  --gradient-to-color: #fffaf0 !important;
}

.to-orange-200 {
  --gradient-to-color: #feebc8 !important;
}

.to-orange-300 {
  --gradient-to-color: #fbd38d !important;
}

.to-orange-400 {
  --gradient-to-color: #f6ad55 !important;
}

.to-orange-500 {
  --gradient-to-color: #ed8936 !important;
}

.to-orange-600 {
  --gradient-to-color: #dd6b20 !important;
}

.to-orange-700 {
  --gradient-to-color: #c05621 !important;
}

.to-orange-800 {
  --gradient-to-color: #9c4221 !important;
}

.to-orange-900 {
  --gradient-to-color: #7b341e !important;
}

.to-yellow-100 {
  --gradient-to-color: #fffff0 !important;
}

.to-yellow-200 {
  --gradient-to-color: #fefcbf !important;
}

.to-yellow-300 {
  --gradient-to-color: #faf089 !important;
}

.to-yellow-400 {
  --gradient-to-color: #f6e05e !important;
}

.to-yellow-500 {
  --gradient-to-color: #ecc94b !important;
}

.to-yellow-600 {
  --gradient-to-color: #d69e2e !important;
}

.to-yellow-700 {
  --gradient-to-color: #b7791f !important;
}

.to-yellow-800 {
  --gradient-to-color: #975a16 !important;
}

.to-yellow-900 {
  --gradient-to-color: #744210 !important;
}

.to-green-100 {
  --gradient-to-color: #f0fff4 !important;
}

.to-green-200 {
  --gradient-to-color: #c6f6d5 !important;
}

.to-green-300 {
  --gradient-to-color: #9ae6b4 !important;
}

.to-green-400 {
  --gradient-to-color: #68d391 !important;
}

.to-green-500 {
  --gradient-to-color: #48bb78 !important;
}

.to-green-600 {
  --gradient-to-color: #38a169 !important;
}

.to-green-700 {
  --gradient-to-color: #2f855a !important;
}

.to-green-800 {
  --gradient-to-color: #276749 !important;
}

.to-green-900 {
  --gradient-to-color: #22543d !important;
}

.to-teal-100 {
  --gradient-to-color: #e6fffa !important;
}

.to-teal-200 {
  --gradient-to-color: #b2f5ea !important;
}

.to-teal-300 {
  --gradient-to-color: #81e6d9 !important;
}

.to-teal-400 {
  --gradient-to-color: #4fd1c5 !important;
}

.to-teal-500 {
  --gradient-to-color: #38b2ac !important;
}

.to-teal-600 {
  --gradient-to-color: #319795 !important;
}

.to-teal-700 {
  --gradient-to-color: #2c7a7b !important;
}

.to-teal-800 {
  --gradient-to-color: #285e61 !important;
}

.to-teal-900 {
  --gradient-to-color: #234e52 !important;
}

.to-blue-100 {
  --gradient-to-color: #ebf8ff !important;
}

.to-blue-200 {
  --gradient-to-color: #bee3f8 !important;
}

.to-blue-300 {
  --gradient-to-color: #90cdf4 !important;
}

.to-blue-400 {
  --gradient-to-color: #63b3ed !important;
}

.to-blue-500 {
  --gradient-to-color: #4299e1 !important;
}

.to-blue-600 {
  --gradient-to-color: #3182ce !important;
}

.to-blue-700 {
  --gradient-to-color: #2b6cb0 !important;
}

.to-blue-800 {
  --gradient-to-color: #2c5282 !important;
}

.to-blue-900 {
  --gradient-to-color: #2a4365 !important;
}

.to-indigo-100 {
  --gradient-to-color: #ebf4ff !important;
}

.to-indigo-200 {
  --gradient-to-color: #c3dafe !important;
}

.to-indigo-300 {
  --gradient-to-color: #a3bffa !important;
}

.to-indigo-400 {
  --gradient-to-color: #7f9cf5 !important;
}

.to-indigo-500 {
  --gradient-to-color: #667eea !important;
}

.to-indigo-600 {
  --gradient-to-color: #5a67d8 !important;
}

.to-indigo-700 {
  --gradient-to-color: #4c51bf !important;
}

.to-indigo-800 {
  --gradient-to-color: #434190 !important;
}

.to-indigo-900 {
  --gradient-to-color: #3c366b !important;
}

.to-purple-100 {
  --gradient-to-color: #faf5ff !important;
}

.to-purple-200 {
  --gradient-to-color: #e9d8fd !important;
}

.to-purple-300 {
  --gradient-to-color: #d6bcfa !important;
}

.to-purple-400 {
  --gradient-to-color: #b794f4 !important;
}

.to-purple-500 {
  --gradient-to-color: #9f7aea !important;
}

.to-purple-600 {
  --gradient-to-color: #805ad5 !important;
}

.to-purple-700 {
  --gradient-to-color: #6b46c1 !important;
}

.to-purple-800 {
  --gradient-to-color: #553c9a !important;
}

.to-purple-900 {
  --gradient-to-color: #44337a !important;
}

.to-pink-100 {
  --gradient-to-color: #fff5f7 !important;
}

.to-pink-200 {
  --gradient-to-color: #fed7e2 !important;
}

.to-pink-300 {
  --gradient-to-color: #fbb6ce !important;
}

.to-pink-400 {
  --gradient-to-color: #f687b3 !important;
}

.to-pink-500 {
  --gradient-to-color: #ed64a6 !important;
}

.to-pink-600 {
  --gradient-to-color: #d53f8c !important;
}

.to-pink-700 {
  --gradient-to-color: #b83280 !important;
}

.to-pink-800 {
  --gradient-to-color: #97266d !important;
}

.to-pink-900 {
  --gradient-to-color: #702459 !important;
}

.to-primary {
  --gradient-to-color: var(--primary) !important;
}

.to-accent-1 {
  --gradient-to-color: #fafafa !important;
}

.to-accent-2 {
  --gradient-to-color: #eaeaea !important;
}

.hover\:from-transparent:hover {
  --gradient-from-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.hover\:from-current:hover {
  --gradient-from-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.hover\:from-black:hover {
  --gradient-from-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.hover\:from-white:hover {
  --gradient-from-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.hover\:from-gray-100:hover {
  --gradient-from-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.hover\:from-gray-200:hover {
  --gradient-from-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.hover\:from-gray-300:hover {
  --gradient-from-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.hover\:from-gray-400:hover {
  --gradient-from-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.hover\:from-gray-500:hover {
  --gradient-from-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.hover\:from-gray-600:hover {
  --gradient-from-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.hover\:from-gray-700:hover {
  --gradient-from-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.hover\:from-gray-800:hover {
  --gradient-from-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.hover\:from-gray-900:hover {
  --gradient-from-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.hover\:from-red-100:hover {
  --gradient-from-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.hover\:from-red-200:hover {
  --gradient-from-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.hover\:from-red-300:hover {
  --gradient-from-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.hover\:from-red-400:hover {
  --gradient-from-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.hover\:from-red-500:hover {
  --gradient-from-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.hover\:from-red-600:hover {
  --gradient-from-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.hover\:from-red-700:hover {
  --gradient-from-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.hover\:from-red-800:hover {
  --gradient-from-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.hover\:from-red-900:hover {
  --gradient-from-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.hover\:from-orange-100:hover {
  --gradient-from-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.hover\:from-orange-200:hover {
  --gradient-from-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.hover\:from-orange-300:hover {
  --gradient-from-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.hover\:from-orange-400:hover {
  --gradient-from-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.hover\:from-orange-500:hover {
  --gradient-from-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.hover\:from-orange-600:hover {
  --gradient-from-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.hover\:from-orange-700:hover {
  --gradient-from-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.hover\:from-orange-800:hover {
  --gradient-from-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.hover\:from-orange-900:hover {
  --gradient-from-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.hover\:from-yellow-100:hover {
  --gradient-from-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.hover\:from-yellow-200:hover {
  --gradient-from-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.hover\:from-yellow-300:hover {
  --gradient-from-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.hover\:from-yellow-400:hover {
  --gradient-from-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.hover\:from-yellow-500:hover {
  --gradient-from-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.hover\:from-yellow-600:hover {
  --gradient-from-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.hover\:from-yellow-700:hover {
  --gradient-from-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.hover\:from-yellow-800:hover {
  --gradient-from-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.hover\:from-yellow-900:hover {
  --gradient-from-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.hover\:from-green-100:hover {
  --gradient-from-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.hover\:from-green-200:hover {
  --gradient-from-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.hover\:from-green-300:hover {
  --gradient-from-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.hover\:from-green-400:hover {
  --gradient-from-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.hover\:from-green-500:hover {
  --gradient-from-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.hover\:from-green-600:hover {
  --gradient-from-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.hover\:from-green-700:hover {
  --gradient-from-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.hover\:from-green-800:hover {
  --gradient-from-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.hover\:from-green-900:hover {
  --gradient-from-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.hover\:from-teal-100:hover {
  --gradient-from-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.hover\:from-teal-200:hover {
  --gradient-from-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.hover\:from-teal-300:hover {
  --gradient-from-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.hover\:from-teal-400:hover {
  --gradient-from-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.hover\:from-teal-500:hover {
  --gradient-from-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.hover\:from-teal-600:hover {
  --gradient-from-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.hover\:from-teal-700:hover {
  --gradient-from-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.hover\:from-teal-800:hover {
  --gradient-from-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.hover\:from-teal-900:hover {
  --gradient-from-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.hover\:from-blue-100:hover {
  --gradient-from-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.hover\:from-blue-200:hover {
  --gradient-from-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.hover\:from-blue-300:hover {
  --gradient-from-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.hover\:from-blue-400:hover {
  --gradient-from-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.hover\:from-blue-500:hover {
  --gradient-from-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.hover\:from-blue-600:hover {
  --gradient-from-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.hover\:from-blue-700:hover {
  --gradient-from-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.hover\:from-blue-800:hover {
  --gradient-from-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.hover\:from-blue-900:hover {
  --gradient-from-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.hover\:from-indigo-100:hover {
  --gradient-from-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.hover\:from-indigo-200:hover {
  --gradient-from-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.hover\:from-indigo-300:hover {
  --gradient-from-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.hover\:from-indigo-400:hover {
  --gradient-from-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.hover\:from-indigo-500:hover {
  --gradient-from-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.hover\:from-indigo-600:hover {
  --gradient-from-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.hover\:from-indigo-700:hover {
  --gradient-from-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.hover\:from-indigo-800:hover {
  --gradient-from-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.hover\:from-indigo-900:hover {
  --gradient-from-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.hover\:from-purple-100:hover {
  --gradient-from-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.hover\:from-purple-200:hover {
  --gradient-from-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.hover\:from-purple-300:hover {
  --gradient-from-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.hover\:from-purple-400:hover {
  --gradient-from-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.hover\:from-purple-500:hover {
  --gradient-from-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.hover\:from-purple-600:hover {
  --gradient-from-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.hover\:from-purple-700:hover {
  --gradient-from-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.hover\:from-purple-800:hover {
  --gradient-from-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.hover\:from-purple-900:hover {
  --gradient-from-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.hover\:from-pink-100:hover {
  --gradient-from-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.hover\:from-pink-200:hover {
  --gradient-from-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.hover\:from-pink-300:hover {
  --gradient-from-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.hover\:from-pink-400:hover {
  --gradient-from-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.hover\:from-pink-500:hover {
  --gradient-from-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.hover\:from-pink-600:hover {
  --gradient-from-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.hover\:from-pink-700:hover {
  --gradient-from-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.hover\:from-pink-800:hover {
  --gradient-from-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.hover\:from-pink-900:hover {
  --gradient-from-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.hover\:from-primary:hover {
  --gradient-from-color: var(--primary) !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.hover\:from-accent-1:hover {
  --gradient-from-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.hover\:from-accent-2:hover {
  --gradient-from-color: #eaeaea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
}

.hover\:via-transparent:hover {
  --gradient-via-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.hover\:via-current:hover {
  --gradient-via-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.hover\:via-black:hover {
  --gradient-via-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.hover\:via-white:hover {
  --gradient-via-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.hover\:via-gray-100:hover {
  --gradient-via-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.hover\:via-gray-200:hover {
  --gradient-via-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.hover\:via-gray-300:hover {
  --gradient-via-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.hover\:via-gray-400:hover {
  --gradient-via-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.hover\:via-gray-500:hover {
  --gradient-via-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.hover\:via-gray-600:hover {
  --gradient-via-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.hover\:via-gray-700:hover {
  --gradient-via-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.hover\:via-gray-800:hover {
  --gradient-via-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.hover\:via-gray-900:hover {
  --gradient-via-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.hover\:via-red-100:hover {
  --gradient-via-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.hover\:via-red-200:hover {
  --gradient-via-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.hover\:via-red-300:hover {
  --gradient-via-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.hover\:via-red-400:hover {
  --gradient-via-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.hover\:via-red-500:hover {
  --gradient-via-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.hover\:via-red-600:hover {
  --gradient-via-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.hover\:via-red-700:hover {
  --gradient-via-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.hover\:via-red-800:hover {
  --gradient-via-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.hover\:via-red-900:hover {
  --gradient-via-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.hover\:via-orange-100:hover {
  --gradient-via-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.hover\:via-orange-200:hover {
  --gradient-via-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.hover\:via-orange-300:hover {
  --gradient-via-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.hover\:via-orange-400:hover {
  --gradient-via-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.hover\:via-orange-500:hover {
  --gradient-via-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.hover\:via-orange-600:hover {
  --gradient-via-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.hover\:via-orange-700:hover {
  --gradient-via-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.hover\:via-orange-800:hover {
  --gradient-via-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.hover\:via-orange-900:hover {
  --gradient-via-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.hover\:via-yellow-100:hover {
  --gradient-via-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.hover\:via-yellow-200:hover {
  --gradient-via-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.hover\:via-yellow-300:hover {
  --gradient-via-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.hover\:via-yellow-400:hover {
  --gradient-via-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.hover\:via-yellow-500:hover {
  --gradient-via-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.hover\:via-yellow-600:hover {
  --gradient-via-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.hover\:via-yellow-700:hover {
  --gradient-via-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.hover\:via-yellow-800:hover {
  --gradient-via-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.hover\:via-yellow-900:hover {
  --gradient-via-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.hover\:via-green-100:hover {
  --gradient-via-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.hover\:via-green-200:hover {
  --gradient-via-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.hover\:via-green-300:hover {
  --gradient-via-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.hover\:via-green-400:hover {
  --gradient-via-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.hover\:via-green-500:hover {
  --gradient-via-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.hover\:via-green-600:hover {
  --gradient-via-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.hover\:via-green-700:hover {
  --gradient-via-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.hover\:via-green-800:hover {
  --gradient-via-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.hover\:via-green-900:hover {
  --gradient-via-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.hover\:via-teal-100:hover {
  --gradient-via-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.hover\:via-teal-200:hover {
  --gradient-via-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.hover\:via-teal-300:hover {
  --gradient-via-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.hover\:via-teal-400:hover {
  --gradient-via-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.hover\:via-teal-500:hover {
  --gradient-via-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.hover\:via-teal-600:hover {
  --gradient-via-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.hover\:via-teal-700:hover {
  --gradient-via-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.hover\:via-teal-800:hover {
  --gradient-via-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.hover\:via-teal-900:hover {
  --gradient-via-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.hover\:via-blue-100:hover {
  --gradient-via-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.hover\:via-blue-200:hover {
  --gradient-via-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.hover\:via-blue-300:hover {
  --gradient-via-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.hover\:via-blue-400:hover {
  --gradient-via-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.hover\:via-blue-500:hover {
  --gradient-via-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.hover\:via-blue-600:hover {
  --gradient-via-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.hover\:via-blue-700:hover {
  --gradient-via-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.hover\:via-blue-800:hover {
  --gradient-via-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.hover\:via-blue-900:hover {
  --gradient-via-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.hover\:via-indigo-100:hover {
  --gradient-via-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.hover\:via-indigo-200:hover {
  --gradient-via-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.hover\:via-indigo-300:hover {
  --gradient-via-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.hover\:via-indigo-400:hover {
  --gradient-via-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.hover\:via-indigo-500:hover {
  --gradient-via-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.hover\:via-indigo-600:hover {
  --gradient-via-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.hover\:via-indigo-700:hover {
  --gradient-via-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.hover\:via-indigo-800:hover {
  --gradient-via-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.hover\:via-indigo-900:hover {
  --gradient-via-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.hover\:via-purple-100:hover {
  --gradient-via-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.hover\:via-purple-200:hover {
  --gradient-via-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.hover\:via-purple-300:hover {
  --gradient-via-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.hover\:via-purple-400:hover {
  --gradient-via-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.hover\:via-purple-500:hover {
  --gradient-via-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.hover\:via-purple-600:hover {
  --gradient-via-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.hover\:via-purple-700:hover {
  --gradient-via-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.hover\:via-purple-800:hover {
  --gradient-via-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.hover\:via-purple-900:hover {
  --gradient-via-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.hover\:via-pink-100:hover {
  --gradient-via-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.hover\:via-pink-200:hover {
  --gradient-via-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.hover\:via-pink-300:hover {
  --gradient-via-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.hover\:via-pink-400:hover {
  --gradient-via-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.hover\:via-pink-500:hover {
  --gradient-via-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.hover\:via-pink-600:hover {
  --gradient-via-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.hover\:via-pink-700:hover {
  --gradient-via-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.hover\:via-pink-800:hover {
  --gradient-via-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.hover\:via-pink-900:hover {
  --gradient-via-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.hover\:via-primary:hover {
  --gradient-via-color: var(--primary) !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.hover\:via-accent-1:hover {
  --gradient-via-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.hover\:via-accent-2:hover {
  --gradient-via-color: #eaeaea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
}

.hover\:to-transparent:hover {
  --gradient-to-color: transparent !important;
}

.hover\:to-current:hover {
  --gradient-to-color: currentColor !important;
}

.hover\:to-black:hover {
  --gradient-to-color: #000 !important;
}

.hover\:to-white:hover {
  --gradient-to-color: #fff !important;
}

.hover\:to-gray-100:hover {
  --gradient-to-color: #f7fafc !important;
}

.hover\:to-gray-200:hover {
  --gradient-to-color: #edf2f7 !important;
}

.hover\:to-gray-300:hover {
  --gradient-to-color: #e2e8f0 !important;
}

.hover\:to-gray-400:hover {
  --gradient-to-color: #cbd5e0 !important;
}

.hover\:to-gray-500:hover {
  --gradient-to-color: #a0aec0 !important;
}

.hover\:to-gray-600:hover {
  --gradient-to-color: #718096 !important;
}

.hover\:to-gray-700:hover {
  --gradient-to-color: #4a5568 !important;
}

.hover\:to-gray-800:hover {
  --gradient-to-color: #2d3748 !important;
}

.hover\:to-gray-900:hover {
  --gradient-to-color: #1a202c !important;
}

.hover\:to-red-100:hover {
  --gradient-to-color: #fff5f5 !important;
}

.hover\:to-red-200:hover {
  --gradient-to-color: #fed7d7 !important;
}

.hover\:to-red-300:hover {
  --gradient-to-color: #feb2b2 !important;
}

.hover\:to-red-400:hover {
  --gradient-to-color: #fc8181 !important;
}

.hover\:to-red-500:hover {
  --gradient-to-color: #f56565 !important;
}

.hover\:to-red-600:hover {
  --gradient-to-color: #e53e3e !important;
}

.hover\:to-red-700:hover {
  --gradient-to-color: #c53030 !important;
}

.hover\:to-red-800:hover {
  --gradient-to-color: #9b2c2c !important;
}

.hover\:to-red-900:hover {
  --gradient-to-color: #742a2a !important;
}

.hover\:to-orange-100:hover {
  --gradient-to-color: #fffaf0 !important;
}

.hover\:to-orange-200:hover {
  --gradient-to-color: #feebc8 !important;
}

.hover\:to-orange-300:hover {
  --gradient-to-color: #fbd38d !important;
}

.hover\:to-orange-400:hover {
  --gradient-to-color: #f6ad55 !important;
}

.hover\:to-orange-500:hover {
  --gradient-to-color: #ed8936 !important;
}

.hover\:to-orange-600:hover {
  --gradient-to-color: #dd6b20 !important;
}

.hover\:to-orange-700:hover {
  --gradient-to-color: #c05621 !important;
}

.hover\:to-orange-800:hover {
  --gradient-to-color: #9c4221 !important;
}

.hover\:to-orange-900:hover {
  --gradient-to-color: #7b341e !important;
}

.hover\:to-yellow-100:hover {
  --gradient-to-color: #fffff0 !important;
}

.hover\:to-yellow-200:hover {
  --gradient-to-color: #fefcbf !important;
}

.hover\:to-yellow-300:hover {
  --gradient-to-color: #faf089 !important;
}

.hover\:to-yellow-400:hover {
  --gradient-to-color: #f6e05e !important;
}

.hover\:to-yellow-500:hover {
  --gradient-to-color: #ecc94b !important;
}

.hover\:to-yellow-600:hover {
  --gradient-to-color: #d69e2e !important;
}

.hover\:to-yellow-700:hover {
  --gradient-to-color: #b7791f !important;
}

.hover\:to-yellow-800:hover {
  --gradient-to-color: #975a16 !important;
}

.hover\:to-yellow-900:hover {
  --gradient-to-color: #744210 !important;
}

.hover\:to-green-100:hover {
  --gradient-to-color: #f0fff4 !important;
}

.hover\:to-green-200:hover {
  --gradient-to-color: #c6f6d5 !important;
}

.hover\:to-green-300:hover {
  --gradient-to-color: #9ae6b4 !important;
}

.hover\:to-green-400:hover {
  --gradient-to-color: #68d391 !important;
}

.hover\:to-green-500:hover {
  --gradient-to-color: #48bb78 !important;
}

.hover\:to-green-600:hover {
  --gradient-to-color: #38a169 !important;
}

.hover\:to-green-700:hover {
  --gradient-to-color: #2f855a !important;
}

.hover\:to-green-800:hover {
  --gradient-to-color: #276749 !important;
}

.hover\:to-green-900:hover {
  --gradient-to-color: #22543d !important;
}

.hover\:to-teal-100:hover {
  --gradient-to-color: #e6fffa !important;
}

.hover\:to-teal-200:hover {
  --gradient-to-color: #b2f5ea !important;
}

.hover\:to-teal-300:hover {
  --gradient-to-color: #81e6d9 !important;
}

.hover\:to-teal-400:hover {
  --gradient-to-color: #4fd1c5 !important;
}

.hover\:to-teal-500:hover {
  --gradient-to-color: #38b2ac !important;
}

.hover\:to-teal-600:hover {
  --gradient-to-color: #319795 !important;
}

.hover\:to-teal-700:hover {
  --gradient-to-color: #2c7a7b !important;
}

.hover\:to-teal-800:hover {
  --gradient-to-color: #285e61 !important;
}

.hover\:to-teal-900:hover {
  --gradient-to-color: #234e52 !important;
}

.hover\:to-blue-100:hover {
  --gradient-to-color: #ebf8ff !important;
}

.hover\:to-blue-200:hover {
  --gradient-to-color: #bee3f8 !important;
}

.hover\:to-blue-300:hover {
  --gradient-to-color: #90cdf4 !important;
}

.hover\:to-blue-400:hover {
  --gradient-to-color: #63b3ed !important;
}

.hover\:to-blue-500:hover {
  --gradient-to-color: #4299e1 !important;
}

.hover\:to-blue-600:hover {
  --gradient-to-color: #3182ce !important;
}

.hover\:to-blue-700:hover {
  --gradient-to-color: #2b6cb0 !important;
}

.hover\:to-blue-800:hover {
  --gradient-to-color: #2c5282 !important;
}

.hover\:to-blue-900:hover {
  --gradient-to-color: #2a4365 !important;
}

.hover\:to-indigo-100:hover {
  --gradient-to-color: #ebf4ff !important;
}

.hover\:to-indigo-200:hover {
  --gradient-to-color: #c3dafe !important;
}

.hover\:to-indigo-300:hover {
  --gradient-to-color: #a3bffa !important;
}

.hover\:to-indigo-400:hover {
  --gradient-to-color: #7f9cf5 !important;
}

.hover\:to-indigo-500:hover {
  --gradient-to-color: #667eea !important;
}

.hover\:to-indigo-600:hover {
  --gradient-to-color: #5a67d8 !important;
}

.hover\:to-indigo-700:hover {
  --gradient-to-color: #4c51bf !important;
}

.hover\:to-indigo-800:hover {
  --gradient-to-color: #434190 !important;
}

.hover\:to-indigo-900:hover {
  --gradient-to-color: #3c366b !important;
}

.hover\:to-purple-100:hover {
  --gradient-to-color: #faf5ff !important;
}

.hover\:to-purple-200:hover {
  --gradient-to-color: #e9d8fd !important;
}

.hover\:to-purple-300:hover {
  --gradient-to-color: #d6bcfa !important;
}

.hover\:to-purple-400:hover {
  --gradient-to-color: #b794f4 !important;
}

.hover\:to-purple-500:hover {
  --gradient-to-color: #9f7aea !important;
}

.hover\:to-purple-600:hover {
  --gradient-to-color: #805ad5 !important;
}

.hover\:to-purple-700:hover {
  --gradient-to-color: #6b46c1 !important;
}

.hover\:to-purple-800:hover {
  --gradient-to-color: #553c9a !important;
}

.hover\:to-purple-900:hover {
  --gradient-to-color: #44337a !important;
}

.hover\:to-pink-100:hover {
  --gradient-to-color: #fff5f7 !important;
}

.hover\:to-pink-200:hover {
  --gradient-to-color: #fed7e2 !important;
}

.hover\:to-pink-300:hover {
  --gradient-to-color: #fbb6ce !important;
}

.hover\:to-pink-400:hover {
  --gradient-to-color: #f687b3 !important;
}

.hover\:to-pink-500:hover {
  --gradient-to-color: #ed64a6 !important;
}

.hover\:to-pink-600:hover {
  --gradient-to-color: #d53f8c !important;
}

.hover\:to-pink-700:hover {
  --gradient-to-color: #b83280 !important;
}

.hover\:to-pink-800:hover {
  --gradient-to-color: #97266d !important;
}

.hover\:to-pink-900:hover {
  --gradient-to-color: #702459 !important;
}

.hover\:to-primary:hover {
  --gradient-to-color: var(--primary) !important;
}

.hover\:to-accent-1:hover {
  --gradient-to-color: #fafafa !important;
}

.hover\:to-accent-2:hover {
  --gradient-to-color: #eaeaea !important;
}

.focus\:from-transparent:focus {
  --gradient-from-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.focus\:from-current:focus {
  --gradient-from-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.focus\:from-black:focus {
  --gradient-from-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.focus\:from-white:focus {
  --gradient-from-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.focus\:from-gray-100:focus {
  --gradient-from-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.focus\:from-gray-200:focus {
  --gradient-from-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.focus\:from-gray-300:focus {
  --gradient-from-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.focus\:from-gray-400:focus {
  --gradient-from-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.focus\:from-gray-500:focus {
  --gradient-from-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.focus\:from-gray-600:focus {
  --gradient-from-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.focus\:from-gray-700:focus {
  --gradient-from-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.focus\:from-gray-800:focus {
  --gradient-from-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.focus\:from-gray-900:focus {
  --gradient-from-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.focus\:from-red-100:focus {
  --gradient-from-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.focus\:from-red-200:focus {
  --gradient-from-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.focus\:from-red-300:focus {
  --gradient-from-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.focus\:from-red-400:focus {
  --gradient-from-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.focus\:from-red-500:focus {
  --gradient-from-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.focus\:from-red-600:focus {
  --gradient-from-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.focus\:from-red-700:focus {
  --gradient-from-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.focus\:from-red-800:focus {
  --gradient-from-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.focus\:from-red-900:focus {
  --gradient-from-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.focus\:from-orange-100:focus {
  --gradient-from-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.focus\:from-orange-200:focus {
  --gradient-from-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.focus\:from-orange-300:focus {
  --gradient-from-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.focus\:from-orange-400:focus {
  --gradient-from-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.focus\:from-orange-500:focus {
  --gradient-from-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.focus\:from-orange-600:focus {
  --gradient-from-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.focus\:from-orange-700:focus {
  --gradient-from-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.focus\:from-orange-800:focus {
  --gradient-from-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.focus\:from-orange-900:focus {
  --gradient-from-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.focus\:from-yellow-100:focus {
  --gradient-from-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.focus\:from-yellow-200:focus {
  --gradient-from-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.focus\:from-yellow-300:focus {
  --gradient-from-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.focus\:from-yellow-400:focus {
  --gradient-from-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.focus\:from-yellow-500:focus {
  --gradient-from-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.focus\:from-yellow-600:focus {
  --gradient-from-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.focus\:from-yellow-700:focus {
  --gradient-from-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.focus\:from-yellow-800:focus {
  --gradient-from-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.focus\:from-yellow-900:focus {
  --gradient-from-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.focus\:from-green-100:focus {
  --gradient-from-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.focus\:from-green-200:focus {
  --gradient-from-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.focus\:from-green-300:focus {
  --gradient-from-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.focus\:from-green-400:focus {
  --gradient-from-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.focus\:from-green-500:focus {
  --gradient-from-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.focus\:from-green-600:focus {
  --gradient-from-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.focus\:from-green-700:focus {
  --gradient-from-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.focus\:from-green-800:focus {
  --gradient-from-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.focus\:from-green-900:focus {
  --gradient-from-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.focus\:from-teal-100:focus {
  --gradient-from-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.focus\:from-teal-200:focus {
  --gradient-from-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.focus\:from-teal-300:focus {
  --gradient-from-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.focus\:from-teal-400:focus {
  --gradient-from-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.focus\:from-teal-500:focus {
  --gradient-from-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.focus\:from-teal-600:focus {
  --gradient-from-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.focus\:from-teal-700:focus {
  --gradient-from-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.focus\:from-teal-800:focus {
  --gradient-from-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.focus\:from-teal-900:focus {
  --gradient-from-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.focus\:from-blue-100:focus {
  --gradient-from-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.focus\:from-blue-200:focus {
  --gradient-from-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.focus\:from-blue-300:focus {
  --gradient-from-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.focus\:from-blue-400:focus {
  --gradient-from-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.focus\:from-blue-500:focus {
  --gradient-from-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.focus\:from-blue-600:focus {
  --gradient-from-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.focus\:from-blue-700:focus {
  --gradient-from-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.focus\:from-blue-800:focus {
  --gradient-from-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.focus\:from-blue-900:focus {
  --gradient-from-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.focus\:from-indigo-100:focus {
  --gradient-from-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.focus\:from-indigo-200:focus {
  --gradient-from-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.focus\:from-indigo-300:focus {
  --gradient-from-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.focus\:from-indigo-400:focus {
  --gradient-from-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.focus\:from-indigo-500:focus {
  --gradient-from-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.focus\:from-indigo-600:focus {
  --gradient-from-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.focus\:from-indigo-700:focus {
  --gradient-from-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.focus\:from-indigo-800:focus {
  --gradient-from-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.focus\:from-indigo-900:focus {
  --gradient-from-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.focus\:from-purple-100:focus {
  --gradient-from-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.focus\:from-purple-200:focus {
  --gradient-from-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.focus\:from-purple-300:focus {
  --gradient-from-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.focus\:from-purple-400:focus {
  --gradient-from-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.focus\:from-purple-500:focus {
  --gradient-from-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.focus\:from-purple-600:focus {
  --gradient-from-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.focus\:from-purple-700:focus {
  --gradient-from-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.focus\:from-purple-800:focus {
  --gradient-from-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.focus\:from-purple-900:focus {
  --gradient-from-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.focus\:from-pink-100:focus {
  --gradient-from-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.focus\:from-pink-200:focus {
  --gradient-from-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.focus\:from-pink-300:focus {
  --gradient-from-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.focus\:from-pink-400:focus {
  --gradient-from-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.focus\:from-pink-500:focus {
  --gradient-from-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.focus\:from-pink-600:focus {
  --gradient-from-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.focus\:from-pink-700:focus {
  --gradient-from-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.focus\:from-pink-800:focus {
  --gradient-from-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.focus\:from-pink-900:focus {
  --gradient-from-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.focus\:from-primary:focus {
  --gradient-from-color: var(--primary) !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.focus\:from-accent-1:focus {
  --gradient-from-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.focus\:from-accent-2:focus {
  --gradient-from-color: #eaeaea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
}

.focus\:via-transparent:focus {
  --gradient-via-color: transparent !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.focus\:via-current:focus {
  --gradient-via-color: currentColor !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.focus\:via-black:focus {
  --gradient-via-color: #000 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
}

.focus\:via-white:focus {
  --gradient-via-color: #fff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.focus\:via-gray-100:focus {
  --gradient-via-color: #f7fafc !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
}

.focus\:via-gray-200:focus {
  --gradient-via-color: #edf2f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
}

.focus\:via-gray-300:focus {
  --gradient-via-color: #e2e8f0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
}

.focus\:via-gray-400:focus {
  --gradient-via-color: #cbd5e0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
}

.focus\:via-gray-500:focus {
  --gradient-via-color: #a0aec0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
}

.focus\:via-gray-600:focus {
  --gradient-via-color: #718096 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
}

.focus\:via-gray-700:focus {
  --gradient-via-color: #4a5568 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
}

.focus\:via-gray-800:focus {
  --gradient-via-color: #2d3748 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
}

.focus\:via-gray-900:focus {
  --gradient-via-color: #1a202c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
}

.focus\:via-red-100:focus {
  --gradient-via-color: #fff5f5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
}

.focus\:via-red-200:focus {
  --gradient-via-color: #fed7d7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
}

.focus\:via-red-300:focus {
  --gradient-via-color: #feb2b2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
}

.focus\:via-red-400:focus {
  --gradient-via-color: #fc8181 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
}

.focus\:via-red-500:focus {
  --gradient-via-color: #f56565 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
}

.focus\:via-red-600:focus {
  --gradient-via-color: #e53e3e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
}

.focus\:via-red-700:focus {
  --gradient-via-color: #c53030 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
}

.focus\:via-red-800:focus {
  --gradient-via-color: #9b2c2c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
}

.focus\:via-red-900:focus {
  --gradient-via-color: #742a2a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
}

.focus\:via-orange-100:focus {
  --gradient-via-color: #fffaf0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
}

.focus\:via-orange-200:focus {
  --gradient-via-color: #feebc8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
}

.focus\:via-orange-300:focus {
  --gradient-via-color: #fbd38d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
}

.focus\:via-orange-400:focus {
  --gradient-via-color: #f6ad55 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
}

.focus\:via-orange-500:focus {
  --gradient-via-color: #ed8936 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
}

.focus\:via-orange-600:focus {
  --gradient-via-color: #dd6b20 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
}

.focus\:via-orange-700:focus {
  --gradient-via-color: #c05621 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
}

.focus\:via-orange-800:focus {
  --gradient-via-color: #9c4221 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
}

.focus\:via-orange-900:focus {
  --gradient-via-color: #7b341e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
}

.focus\:via-yellow-100:focus {
  --gradient-via-color: #fffff0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
}

.focus\:via-yellow-200:focus {
  --gradient-via-color: #fefcbf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
}

.focus\:via-yellow-300:focus {
  --gradient-via-color: #faf089 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
}

.focus\:via-yellow-400:focus {
  --gradient-via-color: #f6e05e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
}

.focus\:via-yellow-500:focus {
  --gradient-via-color: #ecc94b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
}

.focus\:via-yellow-600:focus {
  --gradient-via-color: #d69e2e !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
}

.focus\:via-yellow-700:focus {
  --gradient-via-color: #b7791f !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
}

.focus\:via-yellow-800:focus {
  --gradient-via-color: #975a16 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
}

.focus\:via-yellow-900:focus {
  --gradient-via-color: #744210 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
}

.focus\:via-green-100:focus {
  --gradient-via-color: #f0fff4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
}

.focus\:via-green-200:focus {
  --gradient-via-color: #c6f6d5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
}

.focus\:via-green-300:focus {
  --gradient-via-color: #9ae6b4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
}

.focus\:via-green-400:focus {
  --gradient-via-color: #68d391 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
}

.focus\:via-green-500:focus {
  --gradient-via-color: #48bb78 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
}

.focus\:via-green-600:focus {
  --gradient-via-color: #38a169 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
}

.focus\:via-green-700:focus {
  --gradient-via-color: #2f855a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
}

.focus\:via-green-800:focus {
  --gradient-via-color: #276749 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
}

.focus\:via-green-900:focus {
  --gradient-via-color: #22543d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
}

.focus\:via-teal-100:focus {
  --gradient-via-color: #e6fffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
}

.focus\:via-teal-200:focus {
  --gradient-via-color: #b2f5ea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
}

.focus\:via-teal-300:focus {
  --gradient-via-color: #81e6d9 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
}

.focus\:via-teal-400:focus {
  --gradient-via-color: #4fd1c5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
}

.focus\:via-teal-500:focus {
  --gradient-via-color: #38b2ac !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
}

.focus\:via-teal-600:focus {
  --gradient-via-color: #319795 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
}

.focus\:via-teal-700:focus {
  --gradient-via-color: #2c7a7b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
}

.focus\:via-teal-800:focus {
  --gradient-via-color: #285e61 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
}

.focus\:via-teal-900:focus {
  --gradient-via-color: #234e52 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
}

.focus\:via-blue-100:focus {
  --gradient-via-color: #ebf8ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
}

.focus\:via-blue-200:focus {
  --gradient-via-color: #bee3f8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
}

.focus\:via-blue-300:focus {
  --gradient-via-color: #90cdf4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
}

.focus\:via-blue-400:focus {
  --gradient-via-color: #63b3ed !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
}

.focus\:via-blue-500:focus {
  --gradient-via-color: #4299e1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
}

.focus\:via-blue-600:focus {
  --gradient-via-color: #3182ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
}

.focus\:via-blue-700:focus {
  --gradient-via-color: #2b6cb0 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
}

.focus\:via-blue-800:focus {
  --gradient-via-color: #2c5282 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
}

.focus\:via-blue-900:focus {
  --gradient-via-color: #2a4365 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
}

.focus\:via-indigo-100:focus {
  --gradient-via-color: #ebf4ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
}

.focus\:via-indigo-200:focus {
  --gradient-via-color: #c3dafe !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
}

.focus\:via-indigo-300:focus {
  --gradient-via-color: #a3bffa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
}

.focus\:via-indigo-400:focus {
  --gradient-via-color: #7f9cf5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
}

.focus\:via-indigo-500:focus {
  --gradient-via-color: #667eea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
}

.focus\:via-indigo-600:focus {
  --gradient-via-color: #5a67d8 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
}

.focus\:via-indigo-700:focus {
  --gradient-via-color: #4c51bf !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
}

.focus\:via-indigo-800:focus {
  --gradient-via-color: #434190 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
}

.focus\:via-indigo-900:focus {
  --gradient-via-color: #3c366b !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
}

.focus\:via-purple-100:focus {
  --gradient-via-color: #faf5ff !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
}

.focus\:via-purple-200:focus {
  --gradient-via-color: #e9d8fd !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
}

.focus\:via-purple-300:focus {
  --gradient-via-color: #d6bcfa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
}

.focus\:via-purple-400:focus {
  --gradient-via-color: #b794f4 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
}

.focus\:via-purple-500:focus {
  --gradient-via-color: #9f7aea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
}

.focus\:via-purple-600:focus {
  --gradient-via-color: #805ad5 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
}

.focus\:via-purple-700:focus {
  --gradient-via-color: #6b46c1 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
}

.focus\:via-purple-800:focus {
  --gradient-via-color: #553c9a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
}

.focus\:via-purple-900:focus {
  --gradient-via-color: #44337a !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
}

.focus\:via-pink-100:focus {
  --gradient-via-color: #fff5f7 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
}

.focus\:via-pink-200:focus {
  --gradient-via-color: #fed7e2 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
}

.focus\:via-pink-300:focus {
  --gradient-via-color: #fbb6ce !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
}

.focus\:via-pink-400:focus {
  --gradient-via-color: #f687b3 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
}

.focus\:via-pink-500:focus {
  --gradient-via-color: #ed64a6 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
}

.focus\:via-pink-600:focus {
  --gradient-via-color: #d53f8c !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
}

.focus\:via-pink-700:focus {
  --gradient-via-color: #b83280 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
}

.focus\:via-pink-800:focus {
  --gradient-via-color: #97266d !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
}

.focus\:via-pink-900:focus {
  --gradient-via-color: #702459 !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
}

.focus\:via-primary:focus {
  --gradient-via-color: var(--primary) !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
}

.focus\:via-accent-1:focus {
  --gradient-via-color: #fafafa !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
}

.focus\:via-accent-2:focus {
  --gradient-via-color: #eaeaea !important;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
}

.focus\:to-transparent:focus {
  --gradient-to-color: transparent !important;
}

.focus\:to-current:focus {
  --gradient-to-color: currentColor !important;
}

.focus\:to-black:focus {
  --gradient-to-color: #000 !important;
}

.focus\:to-white:focus {
  --gradient-to-color: #fff !important;
}

.focus\:to-gray-100:focus {
  --gradient-to-color: #f7fafc !important;
}

.focus\:to-gray-200:focus {
  --gradient-to-color: #edf2f7 !important;
}

.focus\:to-gray-300:focus {
  --gradient-to-color: #e2e8f0 !important;
}

.focus\:to-gray-400:focus {
  --gradient-to-color: #cbd5e0 !important;
}

.focus\:to-gray-500:focus {
  --gradient-to-color: #a0aec0 !important;
}

.focus\:to-gray-600:focus {
  --gradient-to-color: #718096 !important;
}

.focus\:to-gray-700:focus {
  --gradient-to-color: #4a5568 !important;
}

.focus\:to-gray-800:focus {
  --gradient-to-color: #2d3748 !important;
}

.focus\:to-gray-900:focus {
  --gradient-to-color: #1a202c !important;
}

.focus\:to-red-100:focus {
  --gradient-to-color: #fff5f5 !important;
}

.focus\:to-red-200:focus {
  --gradient-to-color: #fed7d7 !important;
}

.focus\:to-red-300:focus {
  --gradient-to-color: #feb2b2 !important;
}

.focus\:to-red-400:focus {
  --gradient-to-color: #fc8181 !important;
}

.focus\:to-red-500:focus {
  --gradient-to-color: #f56565 !important;
}

.focus\:to-red-600:focus {
  --gradient-to-color: #e53e3e !important;
}

.focus\:to-red-700:focus {
  --gradient-to-color: #c53030 !important;
}

.focus\:to-red-800:focus {
  --gradient-to-color: #9b2c2c !important;
}

.focus\:to-red-900:focus {
  --gradient-to-color: #742a2a !important;
}

.focus\:to-orange-100:focus {
  --gradient-to-color: #fffaf0 !important;
}

.focus\:to-orange-200:focus {
  --gradient-to-color: #feebc8 !important;
}

.focus\:to-orange-300:focus {
  --gradient-to-color: #fbd38d !important;
}

.focus\:to-orange-400:focus {
  --gradient-to-color: #f6ad55 !important;
}

.focus\:to-orange-500:focus {
  --gradient-to-color: #ed8936 !important;
}

.focus\:to-orange-600:focus {
  --gradient-to-color: #dd6b20 !important;
}

.focus\:to-orange-700:focus {
  --gradient-to-color: #c05621 !important;
}

.focus\:to-orange-800:focus {
  --gradient-to-color: #9c4221 !important;
}

.focus\:to-orange-900:focus {
  --gradient-to-color: #7b341e !important;
}

.focus\:to-yellow-100:focus {
  --gradient-to-color: #fffff0 !important;
}

.focus\:to-yellow-200:focus {
  --gradient-to-color: #fefcbf !important;
}

.focus\:to-yellow-300:focus {
  --gradient-to-color: #faf089 !important;
}

.focus\:to-yellow-400:focus {
  --gradient-to-color: #f6e05e !important;
}

.focus\:to-yellow-500:focus {
  --gradient-to-color: #ecc94b !important;
}

.focus\:to-yellow-600:focus {
  --gradient-to-color: #d69e2e !important;
}

.focus\:to-yellow-700:focus {
  --gradient-to-color: #b7791f !important;
}

.focus\:to-yellow-800:focus {
  --gradient-to-color: #975a16 !important;
}

.focus\:to-yellow-900:focus {
  --gradient-to-color: #744210 !important;
}

.focus\:to-green-100:focus {
  --gradient-to-color: #f0fff4 !important;
}

.focus\:to-green-200:focus {
  --gradient-to-color: #c6f6d5 !important;
}

.focus\:to-green-300:focus {
  --gradient-to-color: #9ae6b4 !important;
}

.focus\:to-green-400:focus {
  --gradient-to-color: #68d391 !important;
}

.focus\:to-green-500:focus {
  --gradient-to-color: #48bb78 !important;
}

.focus\:to-green-600:focus {
  --gradient-to-color: #38a169 !important;
}

.focus\:to-green-700:focus {
  --gradient-to-color: #2f855a !important;
}

.focus\:to-green-800:focus {
  --gradient-to-color: #276749 !important;
}

.focus\:to-green-900:focus {
  --gradient-to-color: #22543d !important;
}

.focus\:to-teal-100:focus {
  --gradient-to-color: #e6fffa !important;
}

.focus\:to-teal-200:focus {
  --gradient-to-color: #b2f5ea !important;
}

.focus\:to-teal-300:focus {
  --gradient-to-color: #81e6d9 !important;
}

.focus\:to-teal-400:focus {
  --gradient-to-color: #4fd1c5 !important;
}

.focus\:to-teal-500:focus {
  --gradient-to-color: #38b2ac !important;
}

.focus\:to-teal-600:focus {
  --gradient-to-color: #319795 !important;
}

.focus\:to-teal-700:focus {
  --gradient-to-color: #2c7a7b !important;
}

.focus\:to-teal-800:focus {
  --gradient-to-color: #285e61 !important;
}

.focus\:to-teal-900:focus {
  --gradient-to-color: #234e52 !important;
}

.focus\:to-blue-100:focus {
  --gradient-to-color: #ebf8ff !important;
}

.focus\:to-blue-200:focus {
  --gradient-to-color: #bee3f8 !important;
}

.focus\:to-blue-300:focus {
  --gradient-to-color: #90cdf4 !important;
}

.focus\:to-blue-400:focus {
  --gradient-to-color: #63b3ed !important;
}

.focus\:to-blue-500:focus {
  --gradient-to-color: #4299e1 !important;
}

.focus\:to-blue-600:focus {
  --gradient-to-color: #3182ce !important;
}

.focus\:to-blue-700:focus {
  --gradient-to-color: #2b6cb0 !important;
}

.focus\:to-blue-800:focus {
  --gradient-to-color: #2c5282 !important;
}

.focus\:to-blue-900:focus {
  --gradient-to-color: #2a4365 !important;
}

.focus\:to-indigo-100:focus {
  --gradient-to-color: #ebf4ff !important;
}

.focus\:to-indigo-200:focus {
  --gradient-to-color: #c3dafe !important;
}

.focus\:to-indigo-300:focus {
  --gradient-to-color: #a3bffa !important;
}

.focus\:to-indigo-400:focus {
  --gradient-to-color: #7f9cf5 !important;
}

.focus\:to-indigo-500:focus {
  --gradient-to-color: #667eea !important;
}

.focus\:to-indigo-600:focus {
  --gradient-to-color: #5a67d8 !important;
}

.focus\:to-indigo-700:focus {
  --gradient-to-color: #4c51bf !important;
}

.focus\:to-indigo-800:focus {
  --gradient-to-color: #434190 !important;
}

.focus\:to-indigo-900:focus {
  --gradient-to-color: #3c366b !important;
}

.focus\:to-purple-100:focus {
  --gradient-to-color: #faf5ff !important;
}

.focus\:to-purple-200:focus {
  --gradient-to-color: #e9d8fd !important;
}

.focus\:to-purple-300:focus {
  --gradient-to-color: #d6bcfa !important;
}

.focus\:to-purple-400:focus {
  --gradient-to-color: #b794f4 !important;
}

.focus\:to-purple-500:focus {
  --gradient-to-color: #9f7aea !important;
}

.focus\:to-purple-600:focus {
  --gradient-to-color: #805ad5 !important;
}

.focus\:to-purple-700:focus {
  --gradient-to-color: #6b46c1 !important;
}

.focus\:to-purple-800:focus {
  --gradient-to-color: #553c9a !important;
}

.focus\:to-purple-900:focus {
  --gradient-to-color: #44337a !important;
}

.focus\:to-pink-100:focus {
  --gradient-to-color: #fff5f7 !important;
}

.focus\:to-pink-200:focus {
  --gradient-to-color: #fed7e2 !important;
}

.focus\:to-pink-300:focus {
  --gradient-to-color: #fbb6ce !important;
}

.focus\:to-pink-400:focus {
  --gradient-to-color: #f687b3 !important;
}

.focus\:to-pink-500:focus {
  --gradient-to-color: #ed64a6 !important;
}

.focus\:to-pink-600:focus {
  --gradient-to-color: #d53f8c !important;
}

.focus\:to-pink-700:focus {
  --gradient-to-color: #b83280 !important;
}

.focus\:to-pink-800:focus {
  --gradient-to-color: #97266d !important;
}

.focus\:to-pink-900:focus {
  --gradient-to-color: #702459 !important;
}

.focus\:to-primary:focus {
  --gradient-to-color: var(--primary) !important;
}

.focus\:to-accent-1:focus {
  --gradient-to-color: #fafafa !important;
}

.focus\:to-accent-2:focus {
  --gradient-to-color: #eaeaea !important;
}

.bg-opacity-0 {
  --bg-opacity: 0 !important;
}

.bg-opacity-25 {
  --bg-opacity: 0.25 !important;
}

.bg-opacity-50 {
  --bg-opacity: 0.5 !important;
}

.bg-opacity-75 {
  --bg-opacity: 0.75 !important;
}

.bg-opacity-100 {
  --bg-opacity: 1 !important;
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0 !important;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25 !important;
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5 !important;
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75 !important;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1 !important;
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0 !important;
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: 0.25 !important;
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: 0.5 !important;
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: 0.75 !important;
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1 !important;
}

.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.border-collapse {
  border-collapse: collapse !important;
}

.border-separate {
  border-collapse: separate !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-current {
  border-color: currentColor !important;
}

.border-black {
  --border-opacity: 1 !important;
  border-color: #000 !important;
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.border-white {
  --border-opacity: 1 !important;
  border-color: #fff !important;
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.border-gray-100 {
  --border-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
}

.border-gray-200 {
  --border-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
}

.border-gray-300 {
  --border-opacity: 1 !important;
  border-color: #e2e8f0 !important;
  border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
}

.border-gray-400 {
  --border-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
}

.border-gray-500 {
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
}

.border-gray-600 {
  --border-opacity: 1 !important;
  border-color: #718096 !important;
  border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
}

.border-gray-700 {
  --border-opacity: 1 !important;
  border-color: #4a5568 !important;
  border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
}

.border-gray-800 {
  --border-opacity: 1 !important;
  border-color: #2d3748 !important;
  border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
}

.border-gray-900 {
  --border-opacity: 1 !important;
  border-color: #1a202c !important;
  border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
}

.border-red-100 {
  --border-opacity: 1 !important;
  border-color: #fff5f5 !important;
  border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
}

.border-red-200 {
  --border-opacity: 1 !important;
  border-color: #fed7d7 !important;
  border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
}

.border-red-300 {
  --border-opacity: 1 !important;
  border-color: #feb2b2 !important;
  border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
}

.border-red-400 {
  --border-opacity: 1 !important;
  border-color: #fc8181 !important;
  border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
}

.border-red-500 {
  --border-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
}

.border-red-600 {
  --border-opacity: 1 !important;
  border-color: #e53e3e !important;
  border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
}

.border-red-700 {
  --border-opacity: 1 !important;
  border-color: #c53030 !important;
  border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
}

.border-red-800 {
  --border-opacity: 1 !important;
  border-color: #9b2c2c !important;
  border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
}

.border-red-900 {
  --border-opacity: 1 !important;
  border-color: #742a2a !important;
  border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
}

.border-orange-100 {
  --border-opacity: 1 !important;
  border-color: #fffaf0 !important;
  border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
}

.border-orange-200 {
  --border-opacity: 1 !important;
  border-color: #feebc8 !important;
  border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
}

.border-orange-300 {
  --border-opacity: 1 !important;
  border-color: #fbd38d !important;
  border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
}

.border-orange-400 {
  --border-opacity: 1 !important;
  border-color: #f6ad55 !important;
  border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
}

.border-orange-500 {
  --border-opacity: 1 !important;
  border-color: #ed8936 !important;
  border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
}

.border-orange-600 {
  --border-opacity: 1 !important;
  border-color: #dd6b20 !important;
  border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
}

.border-orange-700 {
  --border-opacity: 1 !important;
  border-color: #c05621 !important;
  border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
}

.border-orange-800 {
  --border-opacity: 1 !important;
  border-color: #9c4221 !important;
  border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
}

.border-orange-900 {
  --border-opacity: 1 !important;
  border-color: #7b341e !important;
  border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
}

.border-yellow-100 {
  --border-opacity: 1 !important;
  border-color: #fffff0 !important;
  border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
}

.border-yellow-200 {
  --border-opacity: 1 !important;
  border-color: #fefcbf !important;
  border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
}

.border-yellow-300 {
  --border-opacity: 1 !important;
  border-color: #faf089 !important;
  border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
}

.border-yellow-400 {
  --border-opacity: 1 !important;
  border-color: #f6e05e !important;
  border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
}

.border-yellow-500 {
  --border-opacity: 1 !important;
  border-color: #ecc94b !important;
  border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
}

.border-yellow-600 {
  --border-opacity: 1 !important;
  border-color: #d69e2e !important;
  border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
}

.border-yellow-700 {
  --border-opacity: 1 !important;
  border-color: #b7791f !important;
  border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
}

.border-yellow-800 {
  --border-opacity: 1 !important;
  border-color: #975a16 !important;
  border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
}

.border-yellow-900 {
  --border-opacity: 1 !important;
  border-color: #744210 !important;
  border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
}

.border-green-100 {
  --border-opacity: 1 !important;
  border-color: #f0fff4 !important;
  border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
}

.border-green-200 {
  --border-opacity: 1 !important;
  border-color: #c6f6d5 !important;
  border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
}

.border-green-300 {
  --border-opacity: 1 !important;
  border-color: #9ae6b4 !important;
  border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
}

.border-green-400 {
  --border-opacity: 1 !important;
  border-color: #68d391 !important;
  border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
}

.border-green-500 {
  --border-opacity: 1 !important;
  border-color: #48bb78 !important;
  border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
}

.border-green-600 {
  --border-opacity: 1 !important;
  border-color: #38a169 !important;
  border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
}

.border-green-700 {
  --border-opacity: 1 !important;
  border-color: #2f855a !important;
  border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
}

.border-green-800 {
  --border-opacity: 1 !important;
  border-color: #276749 !important;
  border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
}

.border-green-900 {
  --border-opacity: 1 !important;
  border-color: #22543d !important;
  border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
}

.border-teal-100 {
  --border-opacity: 1 !important;
  border-color: #e6fffa !important;
  border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
}

.border-teal-200 {
  --border-opacity: 1 !important;
  border-color: #b2f5ea !important;
  border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
}

.border-teal-300 {
  --border-opacity: 1 !important;
  border-color: #81e6d9 !important;
  border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
}

.border-teal-400 {
  --border-opacity: 1 !important;
  border-color: #4fd1c5 !important;
  border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
}

.border-teal-500 {
  --border-opacity: 1 !important;
  border-color: #38b2ac !important;
  border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
}

.border-teal-600 {
  --border-opacity: 1 !important;
  border-color: #319795 !important;
  border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
}

.border-teal-700 {
  --border-opacity: 1 !important;
  border-color: #2c7a7b !important;
  border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
}

.border-teal-800 {
  --border-opacity: 1 !important;
  border-color: #285e61 !important;
  border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
}

.border-teal-900 {
  --border-opacity: 1 !important;
  border-color: #234e52 !important;
  border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
}

.border-blue-100 {
  --border-opacity: 1 !important;
  border-color: #ebf8ff !important;
  border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
}

.border-blue-200 {
  --border-opacity: 1 !important;
  border-color: #bee3f8 !important;
  border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
}

.border-blue-300 {
  --border-opacity: 1 !important;
  border-color: #90cdf4 !important;
  border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
}

.border-blue-400 {
  --border-opacity: 1 !important;
  border-color: #63b3ed !important;
  border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
}

.border-blue-500 {
  --border-opacity: 1 !important;
  border-color: #4299e1 !important;
  border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
}

.border-blue-600 {
  --border-opacity: 1 !important;
  border-color: #3182ce !important;
  border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
}

.border-blue-700 {
  --border-opacity: 1 !important;
  border-color: #2b6cb0 !important;
  border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
}

.border-blue-800 {
  --border-opacity: 1 !important;
  border-color: #2c5282 !important;
  border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
}

.border-blue-900 {
  --border-opacity: 1 !important;
  border-color: #2a4365 !important;
  border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
}

.border-indigo-100 {
  --border-opacity: 1 !important;
  border-color: #ebf4ff !important;
  border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
}

.border-indigo-200 {
  --border-opacity: 1 !important;
  border-color: #c3dafe !important;
  border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
}

.border-indigo-300 {
  --border-opacity: 1 !important;
  border-color: #a3bffa !important;
  border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
}

.border-indigo-400 {
  --border-opacity: 1 !important;
  border-color: #7f9cf5 !important;
  border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
}

.border-indigo-500 {
  --border-opacity: 1 !important;
  border-color: #667eea !important;
  border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
}

.border-indigo-600 {
  --border-opacity: 1 !important;
  border-color: #5a67d8 !important;
  border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
}

.border-indigo-700 {
  --border-opacity: 1 !important;
  border-color: #4c51bf !important;
  border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
}

.border-indigo-800 {
  --border-opacity: 1 !important;
  border-color: #434190 !important;
  border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
}

.border-indigo-900 {
  --border-opacity: 1 !important;
  border-color: #3c366b !important;
  border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
}

.border-purple-100 {
  --border-opacity: 1 !important;
  border-color: #faf5ff !important;
  border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
}

.border-purple-200 {
  --border-opacity: 1 !important;
  border-color: #e9d8fd !important;
  border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
}

.border-purple-300 {
  --border-opacity: 1 !important;
  border-color: #d6bcfa !important;
  border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
}

.border-purple-400 {
  --border-opacity: 1 !important;
  border-color: #b794f4 !important;
  border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
}

.border-purple-500 {
  --border-opacity: 1 !important;
  border-color: #9f7aea !important;
  border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
}

.border-purple-600 {
  --border-opacity: 1 !important;
  border-color: #805ad5 !important;
  border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
}

.border-purple-700 {
  --border-opacity: 1 !important;
  border-color: #6b46c1 !important;
  border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
}

.border-purple-800 {
  --border-opacity: 1 !important;
  border-color: #553c9a !important;
  border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
}

.border-purple-900 {
  --border-opacity: 1 !important;
  border-color: #44337a !important;
  border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
}

.border-pink-100 {
  --border-opacity: 1 !important;
  border-color: #fff5f7 !important;
  border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
}

.border-pink-200 {
  --border-opacity: 1 !important;
  border-color: #fed7e2 !important;
  border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
}

.border-pink-300 {
  --border-opacity: 1 !important;
  border-color: #fbb6ce !important;
  border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
}

.border-pink-400 {
  --border-opacity: 1 !important;
  border-color: #f687b3 !important;
  border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
}

.border-pink-500 {
  --border-opacity: 1 !important;
  border-color: #ed64a6 !important;
  border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
}

.border-pink-600 {
  --border-opacity: 1 !important;
  border-color: #d53f8c !important;
  border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
}

.border-pink-700 {
  --border-opacity: 1 !important;
  border-color: #b83280 !important;
  border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
}

.border-pink-800 {
  --border-opacity: 1 !important;
  border-color: #97266d !important;
  border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
}

.border-pink-900 {
  --border-opacity: 1 !important;
  border-color: #702459 !important;
  border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.border-accent-1 {
  --border-opacity: 1 !important;
  border-color: #fafafa !important;
  border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
}

.border-accent-2 {
  --border-opacity: 1 !important;
  border-color: #eaeaea !important;
  border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
}

.hover\:border-transparent:hover {
  border-color: transparent !important;
}

.hover\:border-current:hover {
  border-color: currentColor !important;
}

.hover\:border-black:hover {
  --border-opacity: 1 !important;
  border-color: #000 !important;
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.hover\:border-white:hover {
  --border-opacity: 1 !important;
  border-color: #fff !important;
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.hover\:border-gray-100:hover {
  --border-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
}

.hover\:border-gray-200:hover {
  --border-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
}

.hover\:border-gray-300:hover {
  --border-opacity: 1 !important;
  border-color: #e2e8f0 !important;
  border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
}

.hover\:border-gray-400:hover {
  --border-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
}

.hover\:border-gray-500:hover {
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
}

.hover\:border-gray-600:hover {
  --border-opacity: 1 !important;
  border-color: #718096 !important;
  border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
}

.hover\:border-gray-700:hover {
  --border-opacity: 1 !important;
  border-color: #4a5568 !important;
  border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
}

.hover\:border-gray-800:hover {
  --border-opacity: 1 !important;
  border-color: #2d3748 !important;
  border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
}

.hover\:border-gray-900:hover {
  --border-opacity: 1 !important;
  border-color: #1a202c !important;
  border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
}

.hover\:border-red-100:hover {
  --border-opacity: 1 !important;
  border-color: #fff5f5 !important;
  border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
}

.hover\:border-red-200:hover {
  --border-opacity: 1 !important;
  border-color: #fed7d7 !important;
  border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
}

.hover\:border-red-300:hover {
  --border-opacity: 1 !important;
  border-color: #feb2b2 !important;
  border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
}

.hover\:border-red-400:hover {
  --border-opacity: 1 !important;
  border-color: #fc8181 !important;
  border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
}

.hover\:border-red-500:hover {
  --border-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
}

.hover\:border-red-600:hover {
  --border-opacity: 1 !important;
  border-color: #e53e3e !important;
  border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
}

.hover\:border-red-700:hover {
  --border-opacity: 1 !important;
  border-color: #c53030 !important;
  border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
}

.hover\:border-red-800:hover {
  --border-opacity: 1 !important;
  border-color: #9b2c2c !important;
  border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
}

.hover\:border-red-900:hover {
  --border-opacity: 1 !important;
  border-color: #742a2a !important;
  border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
}

.hover\:border-orange-100:hover {
  --border-opacity: 1 !important;
  border-color: #fffaf0 !important;
  border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
}

.hover\:border-orange-200:hover {
  --border-opacity: 1 !important;
  border-color: #feebc8 !important;
  border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
}

.hover\:border-orange-300:hover {
  --border-opacity: 1 !important;
  border-color: #fbd38d !important;
  border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
}

.hover\:border-orange-400:hover {
  --border-opacity: 1 !important;
  border-color: #f6ad55 !important;
  border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
}

.hover\:border-orange-500:hover {
  --border-opacity: 1 !important;
  border-color: #ed8936 !important;
  border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
}

.hover\:border-orange-600:hover {
  --border-opacity: 1 !important;
  border-color: #dd6b20 !important;
  border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
}

.hover\:border-orange-700:hover {
  --border-opacity: 1 !important;
  border-color: #c05621 !important;
  border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
}

.hover\:border-orange-800:hover {
  --border-opacity: 1 !important;
  border-color: #9c4221 !important;
  border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
}

.hover\:border-orange-900:hover {
  --border-opacity: 1 !important;
  border-color: #7b341e !important;
  border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
}

.hover\:border-yellow-100:hover {
  --border-opacity: 1 !important;
  border-color: #fffff0 !important;
  border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
}

.hover\:border-yellow-200:hover {
  --border-opacity: 1 !important;
  border-color: #fefcbf !important;
  border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
}

.hover\:border-yellow-300:hover {
  --border-opacity: 1 !important;
  border-color: #faf089 !important;
  border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
}

.hover\:border-yellow-400:hover {
  --border-opacity: 1 !important;
  border-color: #f6e05e !important;
  border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
}

.hover\:border-yellow-500:hover {
  --border-opacity: 1 !important;
  border-color: #ecc94b !important;
  border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
}

.hover\:border-yellow-600:hover {
  --border-opacity: 1 !important;
  border-color: #d69e2e !important;
  border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
}

.hover\:border-yellow-700:hover {
  --border-opacity: 1 !important;
  border-color: #b7791f !important;
  border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
}

.hover\:border-yellow-800:hover {
  --border-opacity: 1 !important;
  border-color: #975a16 !important;
  border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
}

.hover\:border-yellow-900:hover {
  --border-opacity: 1 !important;
  border-color: #744210 !important;
  border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
}

.hover\:border-green-100:hover {
  --border-opacity: 1 !important;
  border-color: #f0fff4 !important;
  border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
}

.hover\:border-green-200:hover {
  --border-opacity: 1 !important;
  border-color: #c6f6d5 !important;
  border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
}

.hover\:border-green-300:hover {
  --border-opacity: 1 !important;
  border-color: #9ae6b4 !important;
  border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
}

.hover\:border-green-400:hover {
  --border-opacity: 1 !important;
  border-color: #68d391 !important;
  border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
}

.hover\:border-green-500:hover {
  --border-opacity: 1 !important;
  border-color: #48bb78 !important;
  border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
}

.hover\:border-green-600:hover {
  --border-opacity: 1 !important;
  border-color: #38a169 !important;
  border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
}

.hover\:border-green-700:hover {
  --border-opacity: 1 !important;
  border-color: #2f855a !important;
  border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
}

.hover\:border-green-800:hover {
  --border-opacity: 1 !important;
  border-color: #276749 !important;
  border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
}

.hover\:border-green-900:hover {
  --border-opacity: 1 !important;
  border-color: #22543d !important;
  border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
}

.hover\:border-teal-100:hover {
  --border-opacity: 1 !important;
  border-color: #e6fffa !important;
  border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
}

.hover\:border-teal-200:hover {
  --border-opacity: 1 !important;
  border-color: #b2f5ea !important;
  border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
}

.hover\:border-teal-300:hover {
  --border-opacity: 1 !important;
  border-color: #81e6d9 !important;
  border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
}

.hover\:border-teal-400:hover {
  --border-opacity: 1 !important;
  border-color: #4fd1c5 !important;
  border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
}

.hover\:border-teal-500:hover {
  --border-opacity: 1 !important;
  border-color: #38b2ac !important;
  border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
}

.hover\:border-teal-600:hover {
  --border-opacity: 1 !important;
  border-color: #319795 !important;
  border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
}

.hover\:border-teal-700:hover {
  --border-opacity: 1 !important;
  border-color: #2c7a7b !important;
  border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
}

.hover\:border-teal-800:hover {
  --border-opacity: 1 !important;
  border-color: #285e61 !important;
  border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
}

.hover\:border-teal-900:hover {
  --border-opacity: 1 !important;
  border-color: #234e52 !important;
  border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
}

.hover\:border-blue-100:hover {
  --border-opacity: 1 !important;
  border-color: #ebf8ff !important;
  border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
}

.hover\:border-blue-200:hover {
  --border-opacity: 1 !important;
  border-color: #bee3f8 !important;
  border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
}

.hover\:border-blue-300:hover {
  --border-opacity: 1 !important;
  border-color: #90cdf4 !important;
  border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
}

.hover\:border-blue-400:hover {
  --border-opacity: 1 !important;
  border-color: #63b3ed !important;
  border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
}

.hover\:border-blue-500:hover {
  --border-opacity: 1 !important;
  border-color: #4299e1 !important;
  border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
}

.hover\:border-blue-600:hover {
  --border-opacity: 1 !important;
  border-color: #3182ce !important;
  border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
}

.hover\:border-blue-700:hover {
  --border-opacity: 1 !important;
  border-color: #2b6cb0 !important;
  border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
}

.hover\:border-blue-800:hover {
  --border-opacity: 1 !important;
  border-color: #2c5282 !important;
  border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
}

.hover\:border-blue-900:hover {
  --border-opacity: 1 !important;
  border-color: #2a4365 !important;
  border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
}

.hover\:border-indigo-100:hover {
  --border-opacity: 1 !important;
  border-color: #ebf4ff !important;
  border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
}

.hover\:border-indigo-200:hover {
  --border-opacity: 1 !important;
  border-color: #c3dafe !important;
  border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
}

.hover\:border-indigo-300:hover {
  --border-opacity: 1 !important;
  border-color: #a3bffa !important;
  border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
}

.hover\:border-indigo-400:hover {
  --border-opacity: 1 !important;
  border-color: #7f9cf5 !important;
  border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
}

.hover\:border-indigo-500:hover {
  --border-opacity: 1 !important;
  border-color: #667eea !important;
  border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
}

.hover\:border-indigo-600:hover {
  --border-opacity: 1 !important;
  border-color: #5a67d8 !important;
  border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
}

.hover\:border-indigo-700:hover {
  --border-opacity: 1 !important;
  border-color: #4c51bf !important;
  border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
}

.hover\:border-indigo-800:hover {
  --border-opacity: 1 !important;
  border-color: #434190 !important;
  border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
}

.hover\:border-indigo-900:hover {
  --border-opacity: 1 !important;
  border-color: #3c366b !important;
  border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
}

.hover\:border-purple-100:hover {
  --border-opacity: 1 !important;
  border-color: #faf5ff !important;
  border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
}

.hover\:border-purple-200:hover {
  --border-opacity: 1 !important;
  border-color: #e9d8fd !important;
  border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
}

.hover\:border-purple-300:hover {
  --border-opacity: 1 !important;
  border-color: #d6bcfa !important;
  border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
}

.hover\:border-purple-400:hover {
  --border-opacity: 1 !important;
  border-color: #b794f4 !important;
  border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
}

.hover\:border-purple-500:hover {
  --border-opacity: 1 !important;
  border-color: #9f7aea !important;
  border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
}

.hover\:border-purple-600:hover {
  --border-opacity: 1 !important;
  border-color: #805ad5 !important;
  border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
}

.hover\:border-purple-700:hover {
  --border-opacity: 1 !important;
  border-color: #6b46c1 !important;
  border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
}

.hover\:border-purple-800:hover {
  --border-opacity: 1 !important;
  border-color: #553c9a !important;
  border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
}

.hover\:border-purple-900:hover {
  --border-opacity: 1 !important;
  border-color: #44337a !important;
  border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
}

.hover\:border-pink-100:hover {
  --border-opacity: 1 !important;
  border-color: #fff5f7 !important;
  border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
}

.hover\:border-pink-200:hover {
  --border-opacity: 1 !important;
  border-color: #fed7e2 !important;
  border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
}

.hover\:border-pink-300:hover {
  --border-opacity: 1 !important;
  border-color: #fbb6ce !important;
  border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
}

.hover\:border-pink-400:hover {
  --border-opacity: 1 !important;
  border-color: #f687b3 !important;
  border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
}

.hover\:border-pink-500:hover {
  --border-opacity: 1 !important;
  border-color: #ed64a6 !important;
  border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
}

.hover\:border-pink-600:hover {
  --border-opacity: 1 !important;
  border-color: #d53f8c !important;
  border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
}

.hover\:border-pink-700:hover {
  --border-opacity: 1 !important;
  border-color: #b83280 !important;
  border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
}

.hover\:border-pink-800:hover {
  --border-opacity: 1 !important;
  border-color: #97266d !important;
  border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
}

.hover\:border-pink-900:hover {
  --border-opacity: 1 !important;
  border-color: #702459 !important;
  border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
}

.hover\:border-primary:hover {
  border-color: var(--primary) !important;
}

.hover\:border-accent-1:hover {
  --border-opacity: 1 !important;
  border-color: #fafafa !important;
  border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
}

.hover\:border-accent-2:hover {
  --border-opacity: 1 !important;
  border-color: #eaeaea !important;
  border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
}

.focus\:border-transparent:focus {
  border-color: transparent !important;
}

.focus\:border-current:focus {
  border-color: currentColor !important;
}

.focus\:border-black:focus {
  --border-opacity: 1 !important;
  border-color: #000 !important;
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.focus\:border-white:focus {
  --border-opacity: 1 !important;
  border-color: #fff !important;
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.focus\:border-gray-100:focus {
  --border-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
}

.focus\:border-gray-200:focus {
  --border-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
}

.focus\:border-gray-300:focus {
  --border-opacity: 1 !important;
  border-color: #e2e8f0 !important;
  border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
}

.focus\:border-gray-400:focus {
  --border-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
}

.focus\:border-gray-500:focus {
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
}

.focus\:border-gray-600:focus {
  --border-opacity: 1 !important;
  border-color: #718096 !important;
  border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
}

.focus\:border-gray-700:focus {
  --border-opacity: 1 !important;
  border-color: #4a5568 !important;
  border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
}

.focus\:border-gray-800:focus {
  --border-opacity: 1 !important;
  border-color: #2d3748 !important;
  border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
}

.focus\:border-gray-900:focus {
  --border-opacity: 1 !important;
  border-color: #1a202c !important;
  border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
}

.focus\:border-red-100:focus {
  --border-opacity: 1 !important;
  border-color: #fff5f5 !important;
  border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
}

.focus\:border-red-200:focus {
  --border-opacity: 1 !important;
  border-color: #fed7d7 !important;
  border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
}

.focus\:border-red-300:focus {
  --border-opacity: 1 !important;
  border-color: #feb2b2 !important;
  border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
}

.focus\:border-red-400:focus {
  --border-opacity: 1 !important;
  border-color: #fc8181 !important;
  border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
}

.focus\:border-red-500:focus {
  --border-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
}

.focus\:border-red-600:focus {
  --border-opacity: 1 !important;
  border-color: #e53e3e !important;
  border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
}

.focus\:border-red-700:focus {
  --border-opacity: 1 !important;
  border-color: #c53030 !important;
  border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
}

.focus\:border-red-800:focus {
  --border-opacity: 1 !important;
  border-color: #9b2c2c !important;
  border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
}

.focus\:border-red-900:focus {
  --border-opacity: 1 !important;
  border-color: #742a2a !important;
  border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
}

.focus\:border-orange-100:focus {
  --border-opacity: 1 !important;
  border-color: #fffaf0 !important;
  border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
}

.focus\:border-orange-200:focus {
  --border-opacity: 1 !important;
  border-color: #feebc8 !important;
  border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
}

.focus\:border-orange-300:focus {
  --border-opacity: 1 !important;
  border-color: #fbd38d !important;
  border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
}

.focus\:border-orange-400:focus {
  --border-opacity: 1 !important;
  border-color: #f6ad55 !important;
  border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
}

.focus\:border-orange-500:focus {
  --border-opacity: 1 !important;
  border-color: #ed8936 !important;
  border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
}

.focus\:border-orange-600:focus {
  --border-opacity: 1 !important;
  border-color: #dd6b20 !important;
  border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
}

.focus\:border-orange-700:focus {
  --border-opacity: 1 !important;
  border-color: #c05621 !important;
  border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
}

.focus\:border-orange-800:focus {
  --border-opacity: 1 !important;
  border-color: #9c4221 !important;
  border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
}

.focus\:border-orange-900:focus {
  --border-opacity: 1 !important;
  border-color: #7b341e !important;
  border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
}

.focus\:border-yellow-100:focus {
  --border-opacity: 1 !important;
  border-color: #fffff0 !important;
  border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
}

.focus\:border-yellow-200:focus {
  --border-opacity: 1 !important;
  border-color: #fefcbf !important;
  border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
}

.focus\:border-yellow-300:focus {
  --border-opacity: 1 !important;
  border-color: #faf089 !important;
  border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
}

.focus\:border-yellow-400:focus {
  --border-opacity: 1 !important;
  border-color: #f6e05e !important;
  border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
}

.focus\:border-yellow-500:focus {
  --border-opacity: 1 !important;
  border-color: #ecc94b !important;
  border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
}

.focus\:border-yellow-600:focus {
  --border-opacity: 1 !important;
  border-color: #d69e2e !important;
  border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
}

.focus\:border-yellow-700:focus {
  --border-opacity: 1 !important;
  border-color: #b7791f !important;
  border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
}

.focus\:border-yellow-800:focus {
  --border-opacity: 1 !important;
  border-color: #975a16 !important;
  border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
}

.focus\:border-yellow-900:focus {
  --border-opacity: 1 !important;
  border-color: #744210 !important;
  border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
}

.focus\:border-green-100:focus {
  --border-opacity: 1 !important;
  border-color: #f0fff4 !important;
  border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
}

.focus\:border-green-200:focus {
  --border-opacity: 1 !important;
  border-color: #c6f6d5 !important;
  border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
}

.focus\:border-green-300:focus {
  --border-opacity: 1 !important;
  border-color: #9ae6b4 !important;
  border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
}

.focus\:border-green-400:focus {
  --border-opacity: 1 !important;
  border-color: #68d391 !important;
  border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
}

.focus\:border-green-500:focus {
  --border-opacity: 1 !important;
  border-color: #48bb78 !important;
  border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
}

.focus\:border-green-600:focus {
  --border-opacity: 1 !important;
  border-color: #38a169 !important;
  border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
}

.focus\:border-green-700:focus {
  --border-opacity: 1 !important;
  border-color: #2f855a !important;
  border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
}

.focus\:border-green-800:focus {
  --border-opacity: 1 !important;
  border-color: #276749 !important;
  border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
}

.focus\:border-green-900:focus {
  --border-opacity: 1 !important;
  border-color: #22543d !important;
  border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
}

.focus\:border-teal-100:focus {
  --border-opacity: 1 !important;
  border-color: #e6fffa !important;
  border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
}

.focus\:border-teal-200:focus {
  --border-opacity: 1 !important;
  border-color: #b2f5ea !important;
  border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
}

.focus\:border-teal-300:focus {
  --border-opacity: 1 !important;
  border-color: #81e6d9 !important;
  border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
}

.focus\:border-teal-400:focus {
  --border-opacity: 1 !important;
  border-color: #4fd1c5 !important;
  border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
}

.focus\:border-teal-500:focus {
  --border-opacity: 1 !important;
  border-color: #38b2ac !important;
  border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
}

.focus\:border-teal-600:focus {
  --border-opacity: 1 !important;
  border-color: #319795 !important;
  border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
}

.focus\:border-teal-700:focus {
  --border-opacity: 1 !important;
  border-color: #2c7a7b !important;
  border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
}

.focus\:border-teal-800:focus {
  --border-opacity: 1 !important;
  border-color: #285e61 !important;
  border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
}

.focus\:border-teal-900:focus {
  --border-opacity: 1 !important;
  border-color: #234e52 !important;
  border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
}

.focus\:border-blue-100:focus {
  --border-opacity: 1 !important;
  border-color: #ebf8ff !important;
  border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
}

.focus\:border-blue-200:focus {
  --border-opacity: 1 !important;
  border-color: #bee3f8 !important;
  border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
}

.focus\:border-blue-300:focus {
  --border-opacity: 1 !important;
  border-color: #90cdf4 !important;
  border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
}

.focus\:border-blue-400:focus {
  --border-opacity: 1 !important;
  border-color: #63b3ed !important;
  border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
}

.focus\:border-blue-500:focus {
  --border-opacity: 1 !important;
  border-color: #4299e1 !important;
  border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
}

.focus\:border-blue-600:focus {
  --border-opacity: 1 !important;
  border-color: #3182ce !important;
  border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
}

.focus\:border-blue-700:focus {
  --border-opacity: 1 !important;
  border-color: #2b6cb0 !important;
  border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
}

.focus\:border-blue-800:focus {
  --border-opacity: 1 !important;
  border-color: #2c5282 !important;
  border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
}

.focus\:border-blue-900:focus {
  --border-opacity: 1 !important;
  border-color: #2a4365 !important;
  border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
}

.focus\:border-indigo-100:focus {
  --border-opacity: 1 !important;
  border-color: #ebf4ff !important;
  border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
}

.focus\:border-indigo-200:focus {
  --border-opacity: 1 !important;
  border-color: #c3dafe !important;
  border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
}

.focus\:border-indigo-300:focus {
  --border-opacity: 1 !important;
  border-color: #a3bffa !important;
  border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
}

.focus\:border-indigo-400:focus {
  --border-opacity: 1 !important;
  border-color: #7f9cf5 !important;
  border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
}

.focus\:border-indigo-500:focus {
  --border-opacity: 1 !important;
  border-color: #667eea !important;
  border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
}

.focus\:border-indigo-600:focus {
  --border-opacity: 1 !important;
  border-color: #5a67d8 !important;
  border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
}

.focus\:border-indigo-700:focus {
  --border-opacity: 1 !important;
  border-color: #4c51bf !important;
  border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
}

.focus\:border-indigo-800:focus {
  --border-opacity: 1 !important;
  border-color: #434190 !important;
  border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
}

.focus\:border-indigo-900:focus {
  --border-opacity: 1 !important;
  border-color: #3c366b !important;
  border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
}

.focus\:border-purple-100:focus {
  --border-opacity: 1 !important;
  border-color: #faf5ff !important;
  border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
}

.focus\:border-purple-200:focus {
  --border-opacity: 1 !important;
  border-color: #e9d8fd !important;
  border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
}

.focus\:border-purple-300:focus {
  --border-opacity: 1 !important;
  border-color: #d6bcfa !important;
  border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
}

.focus\:border-purple-400:focus {
  --border-opacity: 1 !important;
  border-color: #b794f4 !important;
  border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
}

.focus\:border-purple-500:focus {
  --border-opacity: 1 !important;
  border-color: #9f7aea !important;
  border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
}

.focus\:border-purple-600:focus {
  --border-opacity: 1 !important;
  border-color: #805ad5 !important;
  border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
}

.focus\:border-purple-700:focus {
  --border-opacity: 1 !important;
  border-color: #6b46c1 !important;
  border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
}

.focus\:border-purple-800:focus {
  --border-opacity: 1 !important;
  border-color: #553c9a !important;
  border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
}

.focus\:border-purple-900:focus {
  --border-opacity: 1 !important;
  border-color: #44337a !important;
  border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
}

.focus\:border-pink-100:focus {
  --border-opacity: 1 !important;
  border-color: #fff5f7 !important;
  border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
}

.focus\:border-pink-200:focus {
  --border-opacity: 1 !important;
  border-color: #fed7e2 !important;
  border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
}

.focus\:border-pink-300:focus {
  --border-opacity: 1 !important;
  border-color: #fbb6ce !important;
  border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
}

.focus\:border-pink-400:focus {
  --border-opacity: 1 !important;
  border-color: #f687b3 !important;
  border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
}

.focus\:border-pink-500:focus {
  --border-opacity: 1 !important;
  border-color: #ed64a6 !important;
  border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
}

.focus\:border-pink-600:focus {
  --border-opacity: 1 !important;
  border-color: #d53f8c !important;
  border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
}

.focus\:border-pink-700:focus {
  --border-opacity: 1 !important;
  border-color: #b83280 !important;
  border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
}

.focus\:border-pink-800:focus {
  --border-opacity: 1 !important;
  border-color: #97266d !important;
  border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
}

.focus\:border-pink-900:focus {
  --border-opacity: 1 !important;
  border-color: #702459 !important;
  border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
}

.focus\:border-primary:focus {
  border-color: var(--primary) !important;
}

.focus\:border-accent-1:focus {
  --border-opacity: 1 !important;
  border-color: #fafafa !important;
  border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
}

.focus\:border-accent-2:focus {
  --border-opacity: 1 !important;
  border-color: #eaeaea !important;
  border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
}

.border-opacity-0 {
  --border-opacity: 0 !important;
}

.border-opacity-25 {
  --border-opacity: 0.25 !important;
}

.border-opacity-50 {
  --border-opacity: 0.5 !important;
}

.border-opacity-75 {
  --border-opacity: 0.75 !important;
}

.border-opacity-100 {
  --border-opacity: 1 !important;
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0 !important;
}

.hover\:border-opacity-25:hover {
  --border-opacity: 0.25 !important;
}

.hover\:border-opacity-50:hover {
  --border-opacity: 0.5 !important;
}

.hover\:border-opacity-75:hover {
  --border-opacity: 0.75 !important;
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1 !important;
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0 !important;
}

.focus\:border-opacity-25:focus {
  --border-opacity: 0.25 !important;
}

.focus\:border-opacity-50:focus {
  --border-opacity: 0.5 !important;
}

.focus\:border-opacity-75:focus {
  --border-opacity: 0.75 !important;
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1 !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-sm {
  border-radius: 0.125rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-2xl {
  border-radius: 1rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-t-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b-none {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-t {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-r {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-l {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-t-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-b-xl {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-b-2xl {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-tl-none {
  border-top-left-radius: 0 !important;
}

.rounded-tr-none {
  border-top-right-radius: 0 !important;
}

.rounded-br-none {
  border-bottom-right-radius: 0 !important;
}

.rounded-bl-none {
  border-bottom-left-radius: 0 !important;
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem !important;
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem !important;
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-tl {
  border-top-left-radius: 0.25rem !important;
}

.rounded-tr {
  border-top-right-radius: 0.25rem !important;
}

.rounded-br {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem !important;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem !important;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem !important;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem !important;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem !important;
}

.rounded-tr-xl {
  border-top-right-radius: 0.75rem !important;
}

.rounded-br-xl {
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem !important;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem !important;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem !important;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem !important;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem !important;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem !important;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-tl-full {
  border-top-left-radius: 9999px !important;
}

.rounded-tr-full {
  border-top-right-radius: 9999px !important;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px !important;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-none {
  border-style: none !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border {
  border-width: 1px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-r-0 {
  border-right-width: 0 !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-l-0 {
  border-left-width: 0 !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-r-2 {
  border-right-width: 2px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-t-4 {
  border-top-width: 4px !important;
}

.border-r-4 {
  border-right-width: 4px !important;
}

.border-b-4 {
  border-bottom-width: 4px !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.border-t-8 {
  border-top-width: 8px !important;
}

.border-r-8 {
  border-right-width: 8px !important;
}

.border-b-8 {
  border-bottom-width: 8px !important;
}

.border-l-8 {
  border-left-width: 8px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-l {
  border-left-width: 1px !important;
}

.box-border {
  box-sizing: border-box !important;
}

.box-content {
  box-sizing: content-box !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.table-caption {
  display: table-caption !important;
}

.table-cell {
  display: table-cell !important;
}

.table-column {
  display: table-column !important;
}

.table-column-group {
  display: table-column-group !important;
}

.table-footer-group {
  display: table-footer-group !important;
}

.table-header-group {
  display: table-header-group !important;
}

.table-row-group {
  display: table-row-group !important;
}

.table-row {
  display: table-row !important;
}

.flow-root {
  display: flow-root !important;
}

.grid {
  display: grid !important;
}

.inline-grid {
  display: inline-grid !important;
}

.contents {
  display: contents !important;
}

.hidden {
  display: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.place-items-auto {
  place-items: auto !important;
}

.place-items-start {
  place-items: start !important;
}

.place-items-end {
  place-items: end !important;
}

.place-items-center {
  place-items: center !important;
}

.place-items-stretch {
  place-items: stretch !important;
}

.place-content-center {
  place-content: center !important;
}

.place-content-start {
  place-content: start !important;
}

.place-content-end {
  place-content: end !important;
}

.place-content-between {
  place-content: space-between !important;
}

.place-content-around {
  place-content: space-around !important;
}

.place-content-evenly {
  place-content: space-evenly !important;
}

.place-content-stretch {
  place-content: stretch !important;
}

.place-self-auto {
  place-self: auto !important;
}

.place-self-start {
  place-self: start !important;
}

.place-self-end {
  place-self: end !important;
}

.place-self-center {
  place-self: center !important;
}

.place-self-stretch {
  place-self: stretch !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.items-stretch {
  align-items: stretch !important;
}

.content-center {
  align-content: center !important;
}

.content-start {
  align-content: flex-start !important;
}

.content-end {
  align-content: flex-end !important;
}

.content-between {
  align-content: space-between !important;
}

.content-around {
  align-content: space-around !important;
}

.content-evenly {
  align-content: space-evenly !important;
}

.self-auto {
  align-self: auto !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-end {
  align-self: flex-end !important;
}

.self-center {
  align-self: center !important;
}

.self-stretch {
  align-self: stretch !important;
}

.justify-items-auto {
  justify-items: auto !important;
}

.justify-items-start {
  justify-items: start !important;
}

.justify-items-end {
  justify-items: end !important;
}

.justify-items-center {
  justify-items: center !important;
}

.justify-items-stretch {
  justify-items: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-self-auto {
  justify-self: auto !important;
}

.justify-self-start {
  justify-self: start !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-self-center {
  justify-self: center !important;
}

.justify-self-stretch {
  justify-self: stretch !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-6 {
  order: 6 !important;
}

.order-7 {
  order: 7 !important;
}

.order-8 {
  order: 8 !important;
}

.order-9 {
  order: 9 !important;
}

.order-10 {
  order: 10 !important;
}

.order-11 {
  order: 11 !important;
}

.order-12 {
  order: 12 !important;
}

.order-first {
  order: -9999 !important;
}

.order-last {
  order: 9999 !important;
}

.order-none {
  order: 0 !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.clearfix:after {
  content: "" !important;
  display: table !important;
  clear: both !important;
}

.clear-left {
  clear: left !important;
}

.clear-right {
  clear: right !important;
}

.clear-both {
  clear: both !important;
}

.clear-none {
  clear: none !important;
}

.font-sans {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.font-hairline {
  font-weight: 100 !important;
}

.font-thin {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

.hover\:font-hairline:hover {
  font-weight: 100 !important;
}

.hover\:font-thin:hover {
  font-weight: 200 !important;
}

.hover\:font-light:hover {
  font-weight: 300 !important;
}

.hover\:font-normal:hover {
  font-weight: 400 !important;
}

.hover\:font-medium:hover {
  font-weight: 500 !important;
}

.hover\:font-semibold:hover {
  font-weight: 600 !important;
}

.hover\:font-bold:hover {
  font-weight: 700 !important;
}

.hover\:font-extrabold:hover {
  font-weight: 800 !important;
}

.hover\:font-black:hover {
  font-weight: 900 !important;
}

.focus\:font-hairline:focus {
  font-weight: 100 !important;
}

.focus\:font-thin:focus {
  font-weight: 200 !important;
}

.focus\:font-light:focus {
  font-weight: 300 !important;
}

.focus\:font-normal:focus {
  font-weight: 400 !important;
}

.focus\:font-medium:focus {
  font-weight: 500 !important;
}

.focus\:font-semibold:focus {
  font-weight: 600 !important;
}

.focus\:font-bold:focus {
  font-weight: 700 !important;
}

.focus\:font-extrabold:focus {
  font-weight: 800 !important;
}

.focus\:font-black:focus {
  font-weight: 900 !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-20 {
  height: 5rem !important;
}

.h-24 {
  height: 6rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-48 {
  height: 12rem !important;
}

.h-56 {
  height: 14rem !important;
}

.h-64 {
  height: 16rem !important;
}

.h-auto {
  height: auto !important;
}

.h-px {
  height: 1px !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.text-xs {
  font-size: 0.80em !important;
}

.text-sm {
  font-size: 0.975em !important;
}

.text-base {
  font-size: 1em !important;
}

.text-lg {
  font-size: 1.125em !important;
}

.text-xl {
  font-size: 1.25em !important;
}

.text-2xl {
  font-size: 1.5em !important;
}

.text-3xl {
  font-size: 1.875em !important;
}

.text-4xl {
  font-size: 2.25em !important;
}

.text-5xl {
  font-size: 3em !important;
}

.text-6xl {
  font-size: 4em !important;
}

.text-md {
  font-size: 1em !important;
}

.text-sm-title {
  font-size:  18px !important;
}

.text-md-title {
  font-size: 22px !important;
}

.text-lg-title {
  font-size: 36px !important;
}

.text-size-body {
  font-size: 12px !important;
}

.text-size-label {
  font-size: 10px !important;
}

.leading-3 {
  line-height: .75rem !important;
}

.leading-4 {
  line-height: 1rem !important;
}

.leading-5 {
  line-height: 1.25rem !important;
}

.leading-6 {
  line-height: 1.5rem !important;
}

.leading-7 {
  line-height: 1.75rem !important;
}

.leading-8 {
  line-height: 2rem !important;
}

.leading-9 {
  line-height: 2.25rem !important;
}

.leading-10 {
  line-height: 2.5rem !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.leading-snug {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-loose {
  line-height: 2 !important;
}

.list-inside {
  list-style-position: inside !important;
}

.list-outside {
  list-style-position: outside !important;
}

.list-none {
  list-style-type: none !important;
}

.list-disc {
  list-style-type: disc !important;
}

.list-decimal {
  list-style-type: decimal !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.m-auto {
  margin: auto !important;
}

.m-px {
  margin: 1px !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -0.75rem !important;
}

.-m-4 {
  margin: -1rem !important;
}

.-m-5 {
  margin: -1.25rem !important;
}

.-m-6 {
  margin: -1.5rem !important;
}

.-m-8 {
  margin: -2rem !important;
}

.-m-10 {
  margin: -2.5rem !important;
}

.-m-12 {
  margin: -3rem !important;
}

.-m-16 {
  margin: -4rem !important;
}

.-m-20 {
  margin: -5rem !important;
}

.-m-24 {
  margin: -6rem !important;
}

.-m-32 {
  margin: -8rem !important;
}

.-m-40 {
  margin: -10rem !important;
}

.-m-48 {
  margin: -12rem !important;
}

.-m-56 {
  margin: -14rem !important;
}

.-m-64 {
  margin: -16rem !important;
}

.-m-px {
  margin: -1px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-my-3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.-mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-my-4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-mx-4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-my-5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-mx-5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-my-6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-my-8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-my-10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-mx-10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-mx-12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-my-16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-mx-16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-my-20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-mx-20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-my-24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-mx-24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-my-32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-mx-32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-my-40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-mx-40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-my-48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-mx-48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-my-56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-mx-56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-my-64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-mx-64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-my-px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.-mx-px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mr-12 {
  margin-right: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.ml-20 {
  margin-left: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mr-24 {
  margin-right: 6rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.ml-24 {
  margin-left: 6rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mr-32 {
  margin-right: 8rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.ml-32 {
  margin-left: 8rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mr-40 {
  margin-right: 10rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.ml-40 {
  margin-left: 10rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mr-48 {
  margin-right: 12rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.ml-48 {
  margin-left: 12rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mr-56 {
  margin-right: 14rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.ml-56 {
  margin-left: 14rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mr-64 {
  margin-right: 16rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.ml-64 {
  margin-left: 16rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-px {
  margin-top: 1px !important;
}

.mr-px {
  margin-right: 1px !important;
}

.mb-px {
  margin-bottom: 1px !important;
}

.ml-px {
  margin-left: 1px !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-mt-3 {
  margin-top: -0.75rem !important;
}

.-mr-3 {
  margin-right: -0.75rem !important;
}

.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-ml-3 {
  margin-left: -0.75rem !important;
}

.-mt-4 {
  margin-top: -1rem !important;
}

.-mr-4 {
  margin-right: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1rem !important;
}

.-ml-4 {
  margin-left: -1rem !important;
}

.-mt-5 {
  margin-top: -1.25rem !important;
}

.-mr-5 {
  margin-right: -1.25rem !important;
}

.-mb-5 {
  margin-bottom: -1.25rem !important;
}

.-ml-5 {
  margin-left: -1.25rem !important;
}

.-mt-6 {
  margin-top: -1.5rem !important;
}

.-mr-6 {
  margin-right: -1.5rem !important;
}

.-mb-6 {
  margin-bottom: -1.5rem !important;
}

.-ml-6 {
  margin-left: -1.5rem !important;
}

.-mt-8 {
  margin-top: -2rem !important;
}

.-mr-8 {
  margin-right: -2rem !important;
}

.-mb-8 {
  margin-bottom: -2rem !important;
}

.-ml-8 {
  margin-left: -2rem !important;
}

.-mt-10 {
  margin-top: -2.5rem !important;
}

.-mr-10 {
  margin-right: -2.5rem !important;
}

.-mb-10 {
  margin-bottom: -2.5rem !important;
}

.-ml-10 {
  margin-left: -2.5rem !important;
}

.-mt-12 {
  margin-top: -3rem !important;
}

.-mr-12 {
  margin-right: -3rem !important;
}

.-mb-12 {
  margin-bottom: -3rem !important;
}

.-ml-12 {
  margin-left: -3rem !important;
}

.-mt-16 {
  margin-top: -4rem !important;
}

.-mr-16 {
  margin-right: -4rem !important;
}

.-mb-16 {
  margin-bottom: -4rem !important;
}

.-ml-16 {
  margin-left: -4rem !important;
}

.-mt-20 {
  margin-top: -5rem !important;
}

.-mr-20 {
  margin-right: -5rem !important;
}

.-mb-20 {
  margin-bottom: -5rem !important;
}

.-ml-20 {
  margin-left: -5rem !important;
}

.-mt-24 {
  margin-top: -6rem !important;
}

.-mr-24 {
  margin-right: -6rem !important;
}

.-mb-24 {
  margin-bottom: -6rem !important;
}

.-ml-24 {
  margin-left: -6rem !important;
}

.-mt-32 {
  margin-top: -8rem !important;
}

.-mr-32 {
  margin-right: -8rem !important;
}

.-mb-32 {
  margin-bottom: -8rem !important;
}

.-ml-32 {
  margin-left: -8rem !important;
}

.-mt-40 {
  margin-top: -10rem !important;
}

.-mr-40 {
  margin-right: -10rem !important;
}

.-mb-40 {
  margin-bottom: -10rem !important;
}

.-ml-40 {
  margin-left: -10rem !important;
}

.-mt-48 {
  margin-top: -12rem !important;
}

.-mr-48 {
  margin-right: -12rem !important;
}

.-mb-48 {
  margin-bottom: -12rem !important;
}

.-ml-48 {
  margin-left: -12rem !important;
}

.-mt-56 {
  margin-top: -14rem !important;
}

.-mr-56 {
  margin-right: -14rem !important;
}

.-mb-56 {
  margin-bottom: -14rem !important;
}

.-ml-56 {
  margin-left: -14rem !important;
}

.-mt-64 {
  margin-top: -16rem !important;
}

.-mr-64 {
  margin-right: -16rem !important;
}

.-mb-64 {
  margin-bottom: -16rem !important;
}

.-ml-64 {
  margin-left: -16rem !important;
}

.-mt-px {
  margin-top: -1px !important;
}

.-mr-px {
  margin-right: -1px !important;
}

.-mb-px {
  margin-bottom: -1px !important;
}

.-ml-px {
  margin-left: -1px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-1 {
  max-width: 0.25rem !important;
}

.max-w-2 {
  max-width: 0.5rem !important;
}

.max-w-3 {
  max-width: 0.75rem !important;
}

.max-w-4 {
  max-width: 1rem !important;
}

.max-w-5 {
  max-width: 1.25rem !important;
}

.max-w-6 {
  max-width: 1.5rem !important;
}

.max-w-8 {
  max-width: 2rem !important;
}

.max-w-10 {
  max-width: 2.5rem !important;
}

.max-w-12 {
  max-width: 3rem !important;
}

.max-w-16 {
  max-width: 4rem !important;
}

.max-w-20 {
  max-width: 5rem !important;
}

.max-w-24 {
  max-width: 6rem !important;
}

.max-w-32 {
  max-width: 8rem !important;
}

.max-w-40 {
  max-width: 10rem !important;
}

.max-w-48 {
  max-width: 12rem !important;
}

.max-w-56 {
  max-width: 14rem !important;
}

.max-w-64 {
  max-width: 16rem !important;
}

.max-w-none {
  max-width: none !important;
}

.max-w-xs {
  max-width: 20rem !important;
}

.max-w-sm {
  max-width: 24rem !important;
}

.max-w-md {
  max-width: 28rem !important;
}

.max-w-lg {
  max-width: 32rem !important;
}

.max-w-xl {
  max-width: 36rem !important;
}

.max-w-2xl {
  max-width: 42rem !important;
}

.max-w-3xl {
  max-width: 48rem !important;
}

.max-w-4xl {
  max-width: 56rem !important;
}

.max-w-5xl {
  max-width: 64rem !important;
}

.max-w-6xl {
  max-width: 72rem !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen-xs {
  max-width: 480px !important;
}

.max-w-screen-sm {
  max-width: 576px !important;
}

.max-w-screen-md {
  max-width: 768px !important;
}

.max-w-screen-lg {
  max-width: 992px !important;
}

.max-w-screen-xl {
  max-width: 1200px !important;
}

.max-w-screen-xxl {
  max-width: 1600px !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-px {
  max-width: 1px !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-1 {
  min-width: 0.25rem !important;
}

.min-w-2 {
  min-width: 0.5rem !important;
}

.min-w-3 {
  min-width: 0.75rem !important;
}

.min-w-4 {
  min-width: 1rem !important;
}

.min-w-5 {
  min-width: 1.25rem !important;
}

.min-w-6 {
  min-width: 1.5rem !important;
}

.min-w-8 {
  min-width: 2rem !important;
}

.min-w-10 {
  min-width: 2.5rem !important;
}

.min-w-12 {
  min-width: 3rem !important;
}

.min-w-16 {
  min-width: 4rem !important;
}

.min-w-20 {
  min-width: 5rem !important;
}

.min-w-24 {
  min-width: 6rem !important;
}

.min-w-32 {
  min-width: 8rem !important;
}

.min-w-40 {
  min-width: 10rem !important;
}

.min-w-48 {
  min-width: 12rem !important;
}

.min-w-56 {
  min-width: 14rem !important;
}

.min-w-64 {
  min-width: 16rem !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-px {
  min-width: 1px !important;
}

.min-w-screen-xs {
  min-width: 480px !important;
}

.min-w-screen-sm {
  min-width: 576px !important;
}

.min-w-screen-md {
  min-width: 768px !important;
}

.min-w-screen-lg {
  min-width: 992px !important;
}

.min-w-screen-xl {
  min-width: 1200px !important;
}

.min-w-screen-xxl {
  min-width: 1600px !important;
}

.object-contain {
  object-fit: contain !important;
}

.object-cover {
  object-fit: cover !important;
}

.object-fill {
  object-fit: fill !important;
}

.object-none {
  object-fit: none !important;
}

.object-scale-down {
  object-fit: scale-down !important;
}

.object-bottom {
  object-position: bottom !important;
}

.object-center {
  object-position: center !important;
}

.object-left {
  object-position: left !important;
}

.object-left-bottom {
  object-position: left bottom !important;
}

.object-left-top {
  object-position: left top !important;
}

.object-right {
  object-position: right !important;
}

.object-right-bottom {
  object-position: right bottom !important;
}

.object-right-top {
  object-position: right top !important;
}

.object-top {
  object-position: top !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.hover\:opacity-0:hover {
  opacity: 0 !important;
}

.hover\:opacity-25:hover {
  opacity: 0.25 !important;
}

.hover\:opacity-50:hover {
  opacity: 0.5 !important;
}

.hover\:opacity-75:hover {
  opacity: 0.75 !important;
}

.hover\:opacity-100:hover {
  opacity: 1 !important;
}

.focus\:opacity-0:focus {
  opacity: 0 !important;
}

.focus\:opacity-25:focus {
  opacity: 0.25 !important;
}

.focus\:opacity-50:focus {
  opacity: 0.5 !important;
}

.focus\:opacity-75:focus {
  opacity: 0.75 !important;
}

.focus\:opacity-100:focus {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch !important;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto !important;
}

.overscroll-auto {
  overscroll-behavior: auto !important;
}

.overscroll-contain {
  overscroll-behavior: contain !important;
}

.overscroll-none {
  overscroll-behavior: none !important;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto !important;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain !important;
}

.overscroll-y-none {
  overscroll-behavior-y: none !important;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto !important;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain !important;
}

.overscroll-x-none {
  overscroll-behavior-x: none !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.p-px {
  padding: 1px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pl-12 {
  padding-left: 3rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pr-20 {
  padding-right: 5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pl-20 {
  padding-left: 5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pr-24 {
  padding-right: 6rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pl-24 {
  padding-left: 6rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pr-32 {
  padding-right: 8rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pl-32 {
  padding-left: 8rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pr-40 {
  padding-right: 10rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pl-40 {
  padding-left: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pr-48 {
  padding-right: 12rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pl-48 {
  padding-left: 12rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pr-56 {
  padding-right: 14rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pl-56 {
  padding-left: 14rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pr-64 {
  padding-right: 16rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pl-64 {
  padding-left: 16rem !important;
}

.pt-px {
  padding-top: 1px !important;
}

.pr-px {
  padding-right: 1px !important;
}

.pb-px {
  padding-bottom: 1px !important;
}

.pl-px {
  padding-left: 1px !important;
}

.placeholder-transparent::-webkit-input-placeholder {
  color: transparent !important;
}

.placeholder-transparent::-ms-input-placeholder {
  color: transparent !important;
}

.placeholder-transparent::placeholder {
  color: transparent !important;
}

.placeholder-current::-webkit-input-placeholder {
  color: currentColor !important;
}

.placeholder-current::-ms-input-placeholder {
  color: currentColor !important;
}

.placeholder-current::placeholder {
  color: currentColor !important;
}

.placeholder-black::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
}

.placeholder-black::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
}

.placeholder-black::placeholder {
  --placeholder-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
}

.placeholder-white::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
}

.placeholder-white::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
}

.placeholder-white::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
}

.placeholder-gray-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
}

.placeholder-gray-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
}

.placeholder-gray-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
}

.placeholder-gray-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
}

.placeholder-gray-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
}

.placeholder-gray-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
}

.placeholder-gray-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
}

.placeholder-gray-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
}

.placeholder-gray-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
}

.placeholder-gray-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
}

.placeholder-gray-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
}

.placeholder-gray-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
}

.placeholder-gray-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
}

.placeholder-gray-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
}

.placeholder-gray-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
}

.placeholder-gray-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
}

.placeholder-gray-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
}

.placeholder-gray-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
}

.placeholder-gray-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
}

.placeholder-gray-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
}

.placeholder-gray-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
}

.placeholder-gray-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
}

.placeholder-gray-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
}

.placeholder-gray-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
}

.placeholder-gray-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
}

.placeholder-gray-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
}

.placeholder-gray-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
}

.placeholder-red-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
}

.placeholder-red-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
}

.placeholder-red-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
}

.placeholder-red-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
}

.placeholder-red-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
}

.placeholder-red-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
}

.placeholder-red-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
}

.placeholder-red-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
}

.placeholder-red-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
}

.placeholder-red-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
}

.placeholder-red-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
}

.placeholder-red-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
}

.placeholder-red-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
}

.placeholder-red-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
}

.placeholder-red-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
}

.placeholder-red-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
}

.placeholder-red-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
}

.placeholder-red-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
}

.placeholder-red-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
}

.placeholder-red-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
}

.placeholder-red-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
}

.placeholder-red-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
}

.placeholder-red-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
}

.placeholder-red-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
}

.placeholder-red-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
}

.placeholder-red-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
}

.placeholder-red-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
}

.placeholder-orange-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
}

.placeholder-orange-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
}

.placeholder-orange-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
}

.placeholder-orange-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
}

.placeholder-orange-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
}

.placeholder-orange-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
}

.placeholder-orange-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
}

.placeholder-orange-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
}

.placeholder-orange-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
}

.placeholder-orange-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
}

.placeholder-orange-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
}

.placeholder-orange-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
}

.placeholder-orange-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
}

.placeholder-orange-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
}

.placeholder-orange-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
}

.placeholder-orange-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
}

.placeholder-orange-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
}

.placeholder-orange-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
}

.placeholder-orange-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
}

.placeholder-orange-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
}

.placeholder-orange-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
}

.placeholder-orange-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
}

.placeholder-orange-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
}

.placeholder-orange-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
}

.placeholder-orange-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
}

.placeholder-orange-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
}

.placeholder-orange-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
}

.placeholder-yellow-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
}

.placeholder-green-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
}

.placeholder-green-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
}

.placeholder-green-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
}

.placeholder-green-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
}

.placeholder-green-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
}

.placeholder-green-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
}

.placeholder-green-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
}

.placeholder-green-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
}

.placeholder-green-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
}

.placeholder-green-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
}

.placeholder-green-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
}

.placeholder-green-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
}

.placeholder-green-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
}

.placeholder-green-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
}

.placeholder-green-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
}

.placeholder-green-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
}

.placeholder-green-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
}

.placeholder-green-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
}

.placeholder-green-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
}

.placeholder-green-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
}

.placeholder-green-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
}

.placeholder-green-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
}

.placeholder-green-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
}

.placeholder-green-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
}

.placeholder-green-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
}

.placeholder-green-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
}

.placeholder-green-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
}

.placeholder-teal-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
}

.placeholder-teal-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
}

.placeholder-teal-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
}

.placeholder-teal-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
}

.placeholder-teal-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
}

.placeholder-teal-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
}

.placeholder-teal-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
}

.placeholder-teal-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
}

.placeholder-teal-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
}

.placeholder-teal-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
}

.placeholder-teal-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
}

.placeholder-teal-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
}

.placeholder-teal-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
}

.placeholder-teal-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
}

.placeholder-teal-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
}

.placeholder-teal-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
}

.placeholder-teal-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
}

.placeholder-teal-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
}

.placeholder-teal-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
}

.placeholder-teal-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
}

.placeholder-teal-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
}

.placeholder-teal-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
}

.placeholder-teal-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
}

.placeholder-teal-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
}

.placeholder-teal-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
}

.placeholder-teal-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
}

.placeholder-teal-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
}

.placeholder-blue-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
}

.placeholder-blue-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
}

.placeholder-blue-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
}

.placeholder-blue-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
}

.placeholder-blue-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
}

.placeholder-blue-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
}

.placeholder-blue-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
}

.placeholder-blue-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
}

.placeholder-blue-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
}

.placeholder-blue-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
}

.placeholder-blue-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
}

.placeholder-blue-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
}

.placeholder-blue-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
}

.placeholder-blue-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
}

.placeholder-blue-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
}

.placeholder-blue-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
}

.placeholder-blue-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
}

.placeholder-blue-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
}

.placeholder-blue-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
}

.placeholder-blue-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
}

.placeholder-blue-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
}

.placeholder-blue-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
}

.placeholder-blue-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
}

.placeholder-blue-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
}

.placeholder-blue-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
}

.placeholder-blue-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
}

.placeholder-blue-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
}

.placeholder-indigo-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
}

.placeholder-purple-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
}

.placeholder-purple-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
}

.placeholder-purple-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
}

.placeholder-purple-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
}

.placeholder-purple-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
}

.placeholder-purple-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
}

.placeholder-purple-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
}

.placeholder-purple-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
}

.placeholder-purple-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
}

.placeholder-purple-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
}

.placeholder-purple-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
}

.placeholder-purple-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
}

.placeholder-purple-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
}

.placeholder-purple-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
}

.placeholder-purple-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
}

.placeholder-purple-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
}

.placeholder-purple-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
}

.placeholder-purple-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
}

.placeholder-purple-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
}

.placeholder-purple-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
}

.placeholder-purple-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
}

.placeholder-purple-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
}

.placeholder-purple-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
}

.placeholder-purple-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
}

.placeholder-purple-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
}

.placeholder-purple-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
}

.placeholder-purple-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
}

.placeholder-pink-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
}

.placeholder-pink-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
}

.placeholder-pink-100::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
}

.placeholder-pink-200::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
}

.placeholder-pink-200::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
}

.placeholder-pink-200::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
}

.placeholder-pink-300::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
}

.placeholder-pink-300::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
}

.placeholder-pink-300::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
}

.placeholder-pink-400::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
}

.placeholder-pink-400::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
}

.placeholder-pink-400::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
}

.placeholder-pink-500::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
}

.placeholder-pink-500::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
}

.placeholder-pink-500::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
}

.placeholder-pink-600::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
}

.placeholder-pink-600::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
}

.placeholder-pink-600::placeholder {
  --placeholder-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
}

.placeholder-pink-700::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
}

.placeholder-pink-700::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
}

.placeholder-pink-700::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
}

.placeholder-pink-800::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
}

.placeholder-pink-800::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
}

.placeholder-pink-800::placeholder {
  --placeholder-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
}

.placeholder-pink-900::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
}

.placeholder-pink-900::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
}

.placeholder-pink-900::placeholder {
  --placeholder-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
}

.placeholder-primary::-webkit-input-placeholder {
  color: var(--primary) !important;
}

.placeholder-primary::-ms-input-placeholder {
  color: var(--primary) !important;
}

.placeholder-primary::placeholder {
  color: var(--primary) !important;
}

.placeholder-accent-1::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
}

.placeholder-accent-1::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
}

.placeholder-accent-1::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
}

.placeholder-accent-2::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
}

.placeholder-accent-2::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
}

.placeholder-accent-2::placeholder {
  --placeholder-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-transparent:focus::-webkit-input-placeholder {
  color: transparent !important;
}

.focus\:placeholder-transparent:focus::-ms-input-placeholder {
  color: transparent !important;
}

.focus\:placeholder-transparent:focus::placeholder {
  color: transparent !important;
}

.focus\:placeholder-current:focus::-webkit-input-placeholder {
  color: currentColor !important;
}

.focus\:placeholder-current:focus::-ms-input-placeholder {
  color: currentColor !important;
}

.focus\:placeholder-current:focus::placeholder {
  color: currentColor !important;
}

.focus\:placeholder-black:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-black:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-black:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-white:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-white:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-white:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-gray-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-red-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-orange-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-green-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-teal-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-blue-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-purple-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-200:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-200:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-300:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-300:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-400:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-400:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-500:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-500:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-600:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-600:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-700:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-700:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-800:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-800:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-900:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-pink-900:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-primary:focus::-webkit-input-placeholder {
  color: var(--primary) !important;
}

.focus\:placeholder-primary:focus::-ms-input-placeholder {
  color: var(--primary) !important;
}

.focus\:placeholder-primary:focus::placeholder {
  color: var(--primary) !important;
}

.focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-accent-1:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-accent-1:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-accent-2:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
}

.focus\:placeholder-accent-2:focus::placeholder {
  --placeholder-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
}

.placeholder-opacity-0::-webkit-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-0::-ms-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-25::-webkit-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25::-ms-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-50::-webkit-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50::-ms-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-75::-webkit-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75::-ms-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.placeholder-opacity-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.inset-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.inset-auto {
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important;
}

.inset-x-0 {
  right: 0 !important;
  left: 0 !important;
}

.inset-y-auto {
  top: auto !important;
  bottom: auto !important;
}

.inset-x-auto {
  right: auto !important;
  left: auto !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.top-auto {
  top: auto !important;
}

.right-auto {
  right: auto !important;
}

.bottom-auto {
  bottom: auto !important;
}

.left-auto {
  left: auto !important;
}

.resize-none {
  resize: none !important;
}

.resize-y {
  resize: vertical !important;
}

.resize-x {
  resize: horizontal !important;
}

.resize {
  resize: both !important;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.hover\:shadow-none:hover {
  box-shadow: none !important;
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.focus\:shadow-none:focus {
  box-shadow: none !important;
}

.fill-current {
  fill: currentColor !important;
}

.stroke-current {
  stroke: currentColor !important;
}

.stroke-0 {
  stroke-width: 0 !important;
}

.stroke-1 {
  stroke-width: 1 !important;
}

.stroke-2 {
  stroke-width: 2 !important;
}

.table-auto {
  table-layout: auto !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-transparent {
  color: transparent !important;
}

.text-current {
  color: currentColor !important;
}

.text-black {
  --text-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

.text-white {
  --text-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

.text-gray-100 {
  --text-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--text-opacity)) !important;
}

.text-gray-200 {
  --text-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--text-opacity)) !important;
}

.text-gray-300 {
  --text-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--text-opacity)) !important;
}

.text-gray-400 {
  --text-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--text-opacity)) !important;
}

.text-gray-500 {
  --text-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--text-opacity)) !important;
}

.text-gray-600 {
  --text-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--text-opacity)) !important;
}

.text-gray-700 {
  --text-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--text-opacity)) !important;
}

.text-gray-800 {
  --text-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--text-opacity)) !important;
}

.text-gray-900 {
  --text-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--text-opacity)) !important;
}

.text-red-100 {
  --text-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--text-opacity)) !important;
}

.text-red-200 {
  --text-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--text-opacity)) !important;
}

.text-red-300 {
  --text-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--text-opacity)) !important;
}

.text-red-400 {
  --text-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--text-opacity)) !important;
}

.text-red-500 {
  --text-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--text-opacity)) !important;
}

.text-red-600 {
  --text-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--text-opacity)) !important;
}

.text-red-700 {
  --text-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--text-opacity)) !important;
}

.text-red-800 {
  --text-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--text-opacity)) !important;
}

.text-red-900 {
  --text-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--text-opacity)) !important;
}

.text-orange-100 {
  --text-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--text-opacity)) !important;
}

.text-orange-200 {
  --text-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--text-opacity)) !important;
}

.text-orange-300 {
  --text-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--text-opacity)) !important;
}

.text-orange-400 {
  --text-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--text-opacity)) !important;
}

.text-orange-500 {
  --text-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--text-opacity)) !important;
}

.text-orange-600 {
  --text-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--text-opacity)) !important;
}

.text-orange-700 {
  --text-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--text-opacity)) !important;
}

.text-orange-800 {
  --text-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--text-opacity)) !important;
}

.text-orange-900 {
  --text-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--text-opacity)) !important;
}

.text-yellow-100 {
  --text-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--text-opacity)) !important;
}

.text-yellow-200 {
  --text-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--text-opacity)) !important;
}

.text-yellow-300 {
  --text-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--text-opacity)) !important;
}

.text-yellow-400 {
  --text-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--text-opacity)) !important;
}

.text-yellow-500 {
  --text-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--text-opacity)) !important;
}

.text-yellow-600 {
  --text-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--text-opacity)) !important;
}

.text-yellow-700 {
  --text-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--text-opacity)) !important;
}

.text-yellow-800 {
  --text-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--text-opacity)) !important;
}

.text-yellow-900 {
  --text-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--text-opacity)) !important;
}

.text-green-100 {
  --text-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--text-opacity)) !important;
}

.text-green-200 {
  --text-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--text-opacity)) !important;
}

.text-green-300 {
  --text-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--text-opacity)) !important;
}

.text-green-400 {
  --text-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--text-opacity)) !important;
}

.text-green-500 {
  --text-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--text-opacity)) !important;
}

.text-green-600 {
  --text-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--text-opacity)) !important;
}

.text-green-700 {
  --text-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--text-opacity)) !important;
}

.text-green-800 {
  --text-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--text-opacity)) !important;
}

.text-green-900 {
  --text-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--text-opacity)) !important;
}

.text-teal-100 {
  --text-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--text-opacity)) !important;
}

.text-teal-200 {
  --text-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--text-opacity)) !important;
}

.text-teal-300 {
  --text-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--text-opacity)) !important;
}

.text-teal-400 {
  --text-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--text-opacity)) !important;
}

.text-teal-500 {
  --text-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--text-opacity)) !important;
}

.text-teal-600 {
  --text-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--text-opacity)) !important;
}

.text-teal-700 {
  --text-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--text-opacity)) !important;
}

.text-teal-800 {
  --text-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--text-opacity)) !important;
}

.text-teal-900 {
  --text-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--text-opacity)) !important;
}

.text-blue-100 {
  --text-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--text-opacity)) !important;
}

.text-blue-200 {
  --text-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--text-opacity)) !important;
}

.text-blue-300 {
  --text-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--text-opacity)) !important;
}

.text-blue-400 {
  --text-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--text-opacity)) !important;
}

.text-blue-500 {
  --text-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--text-opacity)) !important;
}

.text-blue-600 {
  --text-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--text-opacity)) !important;
}

.text-blue-700 {
  --text-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--text-opacity)) !important;
}

.text-blue-800 {
  --text-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--text-opacity)) !important;
}

.text-blue-900 {
  --text-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--text-opacity)) !important;
}

.text-indigo-100 {
  --text-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--text-opacity)) !important;
}

.text-indigo-200 {
  --text-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--text-opacity)) !important;
}

.text-indigo-300 {
  --text-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--text-opacity)) !important;
}

.text-indigo-400 {
  --text-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--text-opacity)) !important;
}

.text-indigo-500 {
  --text-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--text-opacity)) !important;
}

.text-indigo-600 {
  --text-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--text-opacity)) !important;
}

.text-indigo-700 {
  --text-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--text-opacity)) !important;
}

.text-indigo-800 {
  --text-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--text-opacity)) !important;
}

.text-indigo-900 {
  --text-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--text-opacity)) !important;
}

.text-purple-100 {
  --text-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--text-opacity)) !important;
}

.text-purple-200 {
  --text-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--text-opacity)) !important;
}

.text-purple-300 {
  --text-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--text-opacity)) !important;
}

.text-purple-400 {
  --text-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--text-opacity)) !important;
}

.text-purple-500 {
  --text-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--text-opacity)) !important;
}

.text-purple-600 {
  --text-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--text-opacity)) !important;
}

.text-purple-700 {
  --text-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--text-opacity)) !important;
}

.text-purple-800 {
  --text-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--text-opacity)) !important;
}

.text-purple-900 {
  --text-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--text-opacity)) !important;
}

.text-pink-100 {
  --text-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--text-opacity)) !important;
}

.text-pink-200 {
  --text-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--text-opacity)) !important;
}

.text-pink-300 {
  --text-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--text-opacity)) !important;
}

.text-pink-400 {
  --text-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--text-opacity)) !important;
}

.text-pink-500 {
  --text-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--text-opacity)) !important;
}

.text-pink-600 {
  --text-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--text-opacity)) !important;
}

.text-pink-700 {
  --text-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--text-opacity)) !important;
}

.text-pink-800 {
  --text-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--text-opacity)) !important;
}

.text-pink-900 {
  --text-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--text-opacity)) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-accent-1 {
  --text-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--text-opacity)) !important;
}

.text-accent-2 {
  --text-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--text-opacity)) !important;
}

.hover\:text-transparent:hover {
  color: transparent !important;
}

.hover\:text-current:hover {
  color: currentColor !important;
}

.hover\:text-black:hover {
  --text-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

.hover\:text-white:hover {
  --text-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

.hover\:text-gray-100:hover {
  --text-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--text-opacity)) !important;
}

.hover\:text-gray-200:hover {
  --text-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--text-opacity)) !important;
}

.hover\:text-gray-300:hover {
  --text-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--text-opacity)) !important;
}

.hover\:text-gray-400:hover {
  --text-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--text-opacity)) !important;
}

.hover\:text-gray-500:hover {
  --text-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--text-opacity)) !important;
}

.hover\:text-gray-600:hover {
  --text-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--text-opacity)) !important;
}

.hover\:text-gray-700:hover {
  --text-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--text-opacity)) !important;
}

.hover\:text-gray-800:hover {
  --text-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--text-opacity)) !important;
}

.hover\:text-gray-900:hover {
  --text-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--text-opacity)) !important;
}

.hover\:text-red-100:hover {
  --text-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--text-opacity)) !important;
}

.hover\:text-red-200:hover {
  --text-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--text-opacity)) !important;
}

.hover\:text-red-300:hover {
  --text-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--text-opacity)) !important;
}

.hover\:text-red-400:hover {
  --text-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--text-opacity)) !important;
}

.hover\:text-red-500:hover {
  --text-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--text-opacity)) !important;
}

.hover\:text-red-600:hover {
  --text-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--text-opacity)) !important;
}

.hover\:text-red-700:hover {
  --text-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--text-opacity)) !important;
}

.hover\:text-red-800:hover {
  --text-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--text-opacity)) !important;
}

.hover\:text-red-900:hover {
  --text-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--text-opacity)) !important;
}

.hover\:text-orange-100:hover {
  --text-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--text-opacity)) !important;
}

.hover\:text-orange-200:hover {
  --text-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--text-opacity)) !important;
}

.hover\:text-orange-300:hover {
  --text-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--text-opacity)) !important;
}

.hover\:text-orange-400:hover {
  --text-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--text-opacity)) !important;
}

.hover\:text-orange-500:hover {
  --text-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--text-opacity)) !important;
}

.hover\:text-orange-600:hover {
  --text-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--text-opacity)) !important;
}

.hover\:text-orange-700:hover {
  --text-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--text-opacity)) !important;
}

.hover\:text-orange-800:hover {
  --text-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--text-opacity)) !important;
}

.hover\:text-orange-900:hover {
  --text-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--text-opacity)) !important;
}

.hover\:text-yellow-100:hover {
  --text-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--text-opacity)) !important;
}

.hover\:text-yellow-200:hover {
  --text-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--text-opacity)) !important;
}

.hover\:text-yellow-300:hover {
  --text-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--text-opacity)) !important;
}

.hover\:text-yellow-400:hover {
  --text-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--text-opacity)) !important;
}

.hover\:text-yellow-500:hover {
  --text-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--text-opacity)) !important;
}

.hover\:text-yellow-600:hover {
  --text-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--text-opacity)) !important;
}

.hover\:text-yellow-700:hover {
  --text-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--text-opacity)) !important;
}

.hover\:text-yellow-800:hover {
  --text-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--text-opacity)) !important;
}

.hover\:text-yellow-900:hover {
  --text-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--text-opacity)) !important;
}

.hover\:text-green-100:hover {
  --text-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--text-opacity)) !important;
}

.hover\:text-green-200:hover {
  --text-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--text-opacity)) !important;
}

.hover\:text-green-300:hover {
  --text-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--text-opacity)) !important;
}

.hover\:text-green-400:hover {
  --text-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--text-opacity)) !important;
}

.hover\:text-green-500:hover {
  --text-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--text-opacity)) !important;
}

.hover\:text-green-600:hover {
  --text-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--text-opacity)) !important;
}

.hover\:text-green-700:hover {
  --text-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--text-opacity)) !important;
}

.hover\:text-green-800:hover {
  --text-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--text-opacity)) !important;
}

.hover\:text-green-900:hover {
  --text-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--text-opacity)) !important;
}

.hover\:text-teal-100:hover {
  --text-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--text-opacity)) !important;
}

.hover\:text-teal-200:hover {
  --text-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--text-opacity)) !important;
}

.hover\:text-teal-300:hover {
  --text-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--text-opacity)) !important;
}

.hover\:text-teal-400:hover {
  --text-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--text-opacity)) !important;
}

.hover\:text-teal-500:hover {
  --text-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--text-opacity)) !important;
}

.hover\:text-teal-600:hover {
  --text-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--text-opacity)) !important;
}

.hover\:text-teal-700:hover {
  --text-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--text-opacity)) !important;
}

.hover\:text-teal-800:hover {
  --text-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--text-opacity)) !important;
}

.hover\:text-teal-900:hover {
  --text-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--text-opacity)) !important;
}

.hover\:text-blue-100:hover {
  --text-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--text-opacity)) !important;
}

.hover\:text-blue-200:hover {
  --text-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--text-opacity)) !important;
}

.hover\:text-blue-300:hover {
  --text-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--text-opacity)) !important;
}

.hover\:text-blue-400:hover {
  --text-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--text-opacity)) !important;
}

.hover\:text-blue-500:hover {
  --text-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--text-opacity)) !important;
}

.hover\:text-blue-600:hover {
  --text-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--text-opacity)) !important;
}

.hover\:text-blue-700:hover {
  --text-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--text-opacity)) !important;
}

.hover\:text-blue-800:hover {
  --text-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--text-opacity)) !important;
}

.hover\:text-blue-900:hover {
  --text-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--text-opacity)) !important;
}

.hover\:text-indigo-100:hover {
  --text-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--text-opacity)) !important;
}

.hover\:text-indigo-200:hover {
  --text-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--text-opacity)) !important;
}

.hover\:text-indigo-300:hover {
  --text-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--text-opacity)) !important;
}

.hover\:text-indigo-400:hover {
  --text-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--text-opacity)) !important;
}

.hover\:text-indigo-500:hover {
  --text-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--text-opacity)) !important;
}

.hover\:text-indigo-600:hover {
  --text-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--text-opacity)) !important;
}

.hover\:text-indigo-700:hover {
  --text-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--text-opacity)) !important;
}

.hover\:text-indigo-800:hover {
  --text-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--text-opacity)) !important;
}

.hover\:text-indigo-900:hover {
  --text-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--text-opacity)) !important;
}

.hover\:text-purple-100:hover {
  --text-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--text-opacity)) !important;
}

.hover\:text-purple-200:hover {
  --text-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--text-opacity)) !important;
}

.hover\:text-purple-300:hover {
  --text-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--text-opacity)) !important;
}

.hover\:text-purple-400:hover {
  --text-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--text-opacity)) !important;
}

.hover\:text-purple-500:hover {
  --text-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--text-opacity)) !important;
}

.hover\:text-purple-600:hover {
  --text-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--text-opacity)) !important;
}

.hover\:text-purple-700:hover {
  --text-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--text-opacity)) !important;
}

.hover\:text-purple-800:hover {
  --text-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--text-opacity)) !important;
}

.hover\:text-purple-900:hover {
  --text-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--text-opacity)) !important;
}

.hover\:text-pink-100:hover {
  --text-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--text-opacity)) !important;
}

.hover\:text-pink-200:hover {
  --text-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--text-opacity)) !important;
}

.hover\:text-pink-300:hover {
  --text-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--text-opacity)) !important;
}

.hover\:text-pink-400:hover {
  --text-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--text-opacity)) !important;
}

.hover\:text-pink-500:hover {
  --text-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--text-opacity)) !important;
}

.hover\:text-pink-600:hover {
  --text-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--text-opacity)) !important;
}

.hover\:text-pink-700:hover {
  --text-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--text-opacity)) !important;
}

.hover\:text-pink-800:hover {
  --text-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--text-opacity)) !important;
}

.hover\:text-pink-900:hover {
  --text-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--text-opacity)) !important;
}

.hover\:text-primary:hover {
  color: var(--primary) !important;
}

.hover\:text-accent-1:hover {
  --text-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--text-opacity)) !important;
}

.hover\:text-accent-2:hover {
  --text-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--text-opacity)) !important;
}

.focus\:text-transparent:focus {
  color: transparent !important;
}

.focus\:text-current:focus {
  color: currentColor !important;
}

.focus\:text-black:focus {
  --text-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

.focus\:text-white:focus {
  --text-opacity: 1 !important;
  color: #fff !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

.focus\:text-gray-100:focus {
  --text-opacity: 1 !important;
  color: #f7fafc !important;
  color: rgba(247, 250, 252, var(--text-opacity)) !important;
}

.focus\:text-gray-200:focus {
  --text-opacity: 1 !important;
  color: #edf2f7 !important;
  color: rgba(237, 242, 247, var(--text-opacity)) !important;
}

.focus\:text-gray-300:focus {
  --text-opacity: 1 !important;
  color: #e2e8f0 !important;
  color: rgba(226, 232, 240, var(--text-opacity)) !important;
}

.focus\:text-gray-400:focus {
  --text-opacity: 1 !important;
  color: #cbd5e0 !important;
  color: rgba(203, 213, 224, var(--text-opacity)) !important;
}

.focus\:text-gray-500:focus {
  --text-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--text-opacity)) !important;
}

.focus\:text-gray-600:focus {
  --text-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--text-opacity)) !important;
}

.focus\:text-gray-700:focus {
  --text-opacity: 1 !important;
  color: #4a5568 !important;
  color: rgba(74, 85, 104, var(--text-opacity)) !important;
}

.focus\:text-gray-800:focus {
  --text-opacity: 1 !important;
  color: #2d3748 !important;
  color: rgba(45, 55, 72, var(--text-opacity)) !important;
}

.focus\:text-gray-900:focus {
  --text-opacity: 1 !important;
  color: #1a202c !important;
  color: rgba(26, 32, 44, var(--text-opacity)) !important;
}

.focus\:text-red-100:focus {
  --text-opacity: 1 !important;
  color: #fff5f5 !important;
  color: rgba(255, 245, 245, var(--text-opacity)) !important;
}

.focus\:text-red-200:focus {
  --text-opacity: 1 !important;
  color: #fed7d7 !important;
  color: rgba(254, 215, 215, var(--text-opacity)) !important;
}

.focus\:text-red-300:focus {
  --text-opacity: 1 !important;
  color: #feb2b2 !important;
  color: rgba(254, 178, 178, var(--text-opacity)) !important;
}

.focus\:text-red-400:focus {
  --text-opacity: 1 !important;
  color: #fc8181 !important;
  color: rgba(252, 129, 129, var(--text-opacity)) !important;
}

.focus\:text-red-500:focus {
  --text-opacity: 1 !important;
  color: #f56565 !important;
  color: rgba(245, 101, 101, var(--text-opacity)) !important;
}

.focus\:text-red-600:focus {
  --text-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--text-opacity)) !important;
}

.focus\:text-red-700:focus {
  --text-opacity: 1 !important;
  color: #c53030 !important;
  color: rgba(197, 48, 48, var(--text-opacity)) !important;
}

.focus\:text-red-800:focus {
  --text-opacity: 1 !important;
  color: #9b2c2c !important;
  color: rgba(155, 44, 44, var(--text-opacity)) !important;
}

.focus\:text-red-900:focus {
  --text-opacity: 1 !important;
  color: #742a2a !important;
  color: rgba(116, 42, 42, var(--text-opacity)) !important;
}

.focus\:text-orange-100:focus {
  --text-opacity: 1 !important;
  color: #fffaf0 !important;
  color: rgba(255, 250, 240, var(--text-opacity)) !important;
}

.focus\:text-orange-200:focus {
  --text-opacity: 1 !important;
  color: #feebc8 !important;
  color: rgba(254, 235, 200, var(--text-opacity)) !important;
}

.focus\:text-orange-300:focus {
  --text-opacity: 1 !important;
  color: #fbd38d !important;
  color: rgba(251, 211, 141, var(--text-opacity)) !important;
}

.focus\:text-orange-400:focus {
  --text-opacity: 1 !important;
  color: #f6ad55 !important;
  color: rgba(246, 173, 85, var(--text-opacity)) !important;
}

.focus\:text-orange-500:focus {
  --text-opacity: 1 !important;
  color: #ed8936 !important;
  color: rgba(237, 137, 54, var(--text-opacity)) !important;
}

.focus\:text-orange-600:focus {
  --text-opacity: 1 !important;
  color: #dd6b20 !important;
  color: rgba(221, 107, 32, var(--text-opacity)) !important;
}

.focus\:text-orange-700:focus {
  --text-opacity: 1 !important;
  color: #c05621 !important;
  color: rgba(192, 86, 33, var(--text-opacity)) !important;
}

.focus\:text-orange-800:focus {
  --text-opacity: 1 !important;
  color: #9c4221 !important;
  color: rgba(156, 66, 33, var(--text-opacity)) !important;
}

.focus\:text-orange-900:focus {
  --text-opacity: 1 !important;
  color: #7b341e !important;
  color: rgba(123, 52, 30, var(--text-opacity)) !important;
}

.focus\:text-yellow-100:focus {
  --text-opacity: 1 !important;
  color: #fffff0 !important;
  color: rgba(255, 255, 240, var(--text-opacity)) !important;
}

.focus\:text-yellow-200:focus {
  --text-opacity: 1 !important;
  color: #fefcbf !important;
  color: rgba(254, 252, 191, var(--text-opacity)) !important;
}

.focus\:text-yellow-300:focus {
  --text-opacity: 1 !important;
  color: #faf089 !important;
  color: rgba(250, 240, 137, var(--text-opacity)) !important;
}

.focus\:text-yellow-400:focus {
  --text-opacity: 1 !important;
  color: #f6e05e !important;
  color: rgba(246, 224, 94, var(--text-opacity)) !important;
}

.focus\:text-yellow-500:focus {
  --text-opacity: 1 !important;
  color: #ecc94b !important;
  color: rgba(236, 201, 75, var(--text-opacity)) !important;
}

.focus\:text-yellow-600:focus {
  --text-opacity: 1 !important;
  color: #d69e2e !important;
  color: rgba(214, 158, 46, var(--text-opacity)) !important;
}

.focus\:text-yellow-700:focus {
  --text-opacity: 1 !important;
  color: #b7791f !important;
  color: rgba(183, 121, 31, var(--text-opacity)) !important;
}

.focus\:text-yellow-800:focus {
  --text-opacity: 1 !important;
  color: #975a16 !important;
  color: rgba(151, 90, 22, var(--text-opacity)) !important;
}

.focus\:text-yellow-900:focus {
  --text-opacity: 1 !important;
  color: #744210 !important;
  color: rgba(116, 66, 16, var(--text-opacity)) !important;
}

.focus\:text-green-100:focus {
  --text-opacity: 1 !important;
  color: #f0fff4 !important;
  color: rgba(240, 255, 244, var(--text-opacity)) !important;
}

.focus\:text-green-200:focus {
  --text-opacity: 1 !important;
  color: #c6f6d5 !important;
  color: rgba(198, 246, 213, var(--text-opacity)) !important;
}

.focus\:text-green-300:focus {
  --text-opacity: 1 !important;
  color: #9ae6b4 !important;
  color: rgba(154, 230, 180, var(--text-opacity)) !important;
}

.focus\:text-green-400:focus {
  --text-opacity: 1 !important;
  color: #68d391 !important;
  color: rgba(104, 211, 145, var(--text-opacity)) !important;
}

.focus\:text-green-500:focus {
  --text-opacity: 1 !important;
  color: #48bb78 !important;
  color: rgba(72, 187, 120, var(--text-opacity)) !important;
}

.focus\:text-green-600:focus {
  --text-opacity: 1 !important;
  color: #38a169 !important;
  color: rgba(56, 161, 105, var(--text-opacity)) !important;
}

.focus\:text-green-700:focus {
  --text-opacity: 1 !important;
  color: #2f855a !important;
  color: rgba(47, 133, 90, var(--text-opacity)) !important;
}

.focus\:text-green-800:focus {
  --text-opacity: 1 !important;
  color: #276749 !important;
  color: rgba(39, 103, 73, var(--text-opacity)) !important;
}

.focus\:text-green-900:focus {
  --text-opacity: 1 !important;
  color: #22543d !important;
  color: rgba(34, 84, 61, var(--text-opacity)) !important;
}

.focus\:text-teal-100:focus {
  --text-opacity: 1 !important;
  color: #e6fffa !important;
  color: rgba(230, 255, 250, var(--text-opacity)) !important;
}

.focus\:text-teal-200:focus {
  --text-opacity: 1 !important;
  color: #b2f5ea !important;
  color: rgba(178, 245, 234, var(--text-opacity)) !important;
}

.focus\:text-teal-300:focus {
  --text-opacity: 1 !important;
  color: #81e6d9 !important;
  color: rgba(129, 230, 217, var(--text-opacity)) !important;
}

.focus\:text-teal-400:focus {
  --text-opacity: 1 !important;
  color: #4fd1c5 !important;
  color: rgba(79, 209, 197, var(--text-opacity)) !important;
}

.focus\:text-teal-500:focus {
  --text-opacity: 1 !important;
  color: #38b2ac !important;
  color: rgba(56, 178, 172, var(--text-opacity)) !important;
}

.focus\:text-teal-600:focus {
  --text-opacity: 1 !important;
  color: #319795 !important;
  color: rgba(49, 151, 149, var(--text-opacity)) !important;
}

.focus\:text-teal-700:focus {
  --text-opacity: 1 !important;
  color: #2c7a7b !important;
  color: rgba(44, 122, 123, var(--text-opacity)) !important;
}

.focus\:text-teal-800:focus {
  --text-opacity: 1 !important;
  color: #285e61 !important;
  color: rgba(40, 94, 97, var(--text-opacity)) !important;
}

.focus\:text-teal-900:focus {
  --text-opacity: 1 !important;
  color: #234e52 !important;
  color: rgba(35, 78, 82, var(--text-opacity)) !important;
}

.focus\:text-blue-100:focus {
  --text-opacity: 1 !important;
  color: #ebf8ff !important;
  color: rgba(235, 248, 255, var(--text-opacity)) !important;
}

.focus\:text-blue-200:focus {
  --text-opacity: 1 !important;
  color: #bee3f8 !important;
  color: rgba(190, 227, 248, var(--text-opacity)) !important;
}

.focus\:text-blue-300:focus {
  --text-opacity: 1 !important;
  color: #90cdf4 !important;
  color: rgba(144, 205, 244, var(--text-opacity)) !important;
}

.focus\:text-blue-400:focus {
  --text-opacity: 1 !important;
  color: #63b3ed !important;
  color: rgba(99, 179, 237, var(--text-opacity)) !important;
}

.focus\:text-blue-500:focus {
  --text-opacity: 1 !important;
  color: #4299e1 !important;
  color: rgba(66, 153, 225, var(--text-opacity)) !important;
}

.focus\:text-blue-600:focus {
  --text-opacity: 1 !important;
  color: #3182ce !important;
  color: rgba(49, 130, 206, var(--text-opacity)) !important;
}

.focus\:text-blue-700:focus {
  --text-opacity: 1 !important;
  color: #2b6cb0 !important;
  color: rgba(43, 108, 176, var(--text-opacity)) !important;
}

.focus\:text-blue-800:focus {
  --text-opacity: 1 !important;
  color: #2c5282 !important;
  color: rgba(44, 82, 130, var(--text-opacity)) !important;
}

.focus\:text-blue-900:focus {
  --text-opacity: 1 !important;
  color: #2a4365 !important;
  color: rgba(42, 67, 101, var(--text-opacity)) !important;
}

.focus\:text-indigo-100:focus {
  --text-opacity: 1 !important;
  color: #ebf4ff !important;
  color: rgba(235, 244, 255, var(--text-opacity)) !important;
}

.focus\:text-indigo-200:focus {
  --text-opacity: 1 !important;
  color: #c3dafe !important;
  color: rgba(195, 218, 254, var(--text-opacity)) !important;
}

.focus\:text-indigo-300:focus {
  --text-opacity: 1 !important;
  color: #a3bffa !important;
  color: rgba(163, 191, 250, var(--text-opacity)) !important;
}

.focus\:text-indigo-400:focus {
  --text-opacity: 1 !important;
  color: #7f9cf5 !important;
  color: rgba(127, 156, 245, var(--text-opacity)) !important;
}

.focus\:text-indigo-500:focus {
  --text-opacity: 1 !important;
  color: #667eea !important;
  color: rgba(102, 126, 234, var(--text-opacity)) !important;
}

.focus\:text-indigo-600:focus {
  --text-opacity: 1 !important;
  color: #5a67d8 !important;
  color: rgba(90, 103, 216, var(--text-opacity)) !important;
}

.focus\:text-indigo-700:focus {
  --text-opacity: 1 !important;
  color: #4c51bf !important;
  color: rgba(76, 81, 191, var(--text-opacity)) !important;
}

.focus\:text-indigo-800:focus {
  --text-opacity: 1 !important;
  color: #434190 !important;
  color: rgba(67, 65, 144, var(--text-opacity)) !important;
}

.focus\:text-indigo-900:focus {
  --text-opacity: 1 !important;
  color: #3c366b !important;
  color: rgba(60, 54, 107, var(--text-opacity)) !important;
}

.focus\:text-purple-100:focus {
  --text-opacity: 1 !important;
  color: #faf5ff !important;
  color: rgba(250, 245, 255, var(--text-opacity)) !important;
}

.focus\:text-purple-200:focus {
  --text-opacity: 1 !important;
  color: #e9d8fd !important;
  color: rgba(233, 216, 253, var(--text-opacity)) !important;
}

.focus\:text-purple-300:focus {
  --text-opacity: 1 !important;
  color: #d6bcfa !important;
  color: rgba(214, 188, 250, var(--text-opacity)) !important;
}

.focus\:text-purple-400:focus {
  --text-opacity: 1 !important;
  color: #b794f4 !important;
  color: rgba(183, 148, 244, var(--text-opacity)) !important;
}

.focus\:text-purple-500:focus {
  --text-opacity: 1 !important;
  color: #9f7aea !important;
  color: rgba(159, 122, 234, var(--text-opacity)) !important;
}

.focus\:text-purple-600:focus {
  --text-opacity: 1 !important;
  color: #805ad5 !important;
  color: rgba(128, 90, 213, var(--text-opacity)) !important;
}

.focus\:text-purple-700:focus {
  --text-opacity: 1 !important;
  color: #6b46c1 !important;
  color: rgba(107, 70, 193, var(--text-opacity)) !important;
}

.focus\:text-purple-800:focus {
  --text-opacity: 1 !important;
  color: #553c9a !important;
  color: rgba(85, 60, 154, var(--text-opacity)) !important;
}

.focus\:text-purple-900:focus {
  --text-opacity: 1 !important;
  color: #44337a !important;
  color: rgba(68, 51, 122, var(--text-opacity)) !important;
}

.focus\:text-pink-100:focus {
  --text-opacity: 1 !important;
  color: #fff5f7 !important;
  color: rgba(255, 245, 247, var(--text-opacity)) !important;
}

.focus\:text-pink-200:focus {
  --text-opacity: 1 !important;
  color: #fed7e2 !important;
  color: rgba(254, 215, 226, var(--text-opacity)) !important;
}

.focus\:text-pink-300:focus {
  --text-opacity: 1 !important;
  color: #fbb6ce !important;
  color: rgba(251, 182, 206, var(--text-opacity)) !important;
}

.focus\:text-pink-400:focus {
  --text-opacity: 1 !important;
  color: #f687b3 !important;
  color: rgba(246, 135, 179, var(--text-opacity)) !important;
}

.focus\:text-pink-500:focus {
  --text-opacity: 1 !important;
  color: #ed64a6 !important;
  color: rgba(237, 100, 166, var(--text-opacity)) !important;
}

.focus\:text-pink-600:focus {
  --text-opacity: 1 !important;
  color: #d53f8c !important;
  color: rgba(213, 63, 140, var(--text-opacity)) !important;
}

.focus\:text-pink-700:focus {
  --text-opacity: 1 !important;
  color: #b83280 !important;
  color: rgba(184, 50, 128, var(--text-opacity)) !important;
}

.focus\:text-pink-800:focus {
  --text-opacity: 1 !important;
  color: #97266d !important;
  color: rgba(151, 38, 109, var(--text-opacity)) !important;
}

.focus\:text-pink-900:focus {
  --text-opacity: 1 !important;
  color: #702459 !important;
  color: rgba(112, 36, 89, var(--text-opacity)) !important;
}

.focus\:text-primary:focus {
  color: var(--primary) !important;
}

.focus\:text-accent-1:focus {
  --text-opacity: 1 !important;
  color: #fafafa !important;
  color: rgba(250, 250, 250, var(--text-opacity)) !important;
}

.focus\:text-accent-2:focus {
  --text-opacity: 1 !important;
  color: #eaeaea !important;
  color: rgba(234, 234, 234, var(--text-opacity)) !important;
}

.text-opacity-0 {
  --text-opacity: 0 !important;
}

.text-opacity-25 {
  --text-opacity: 0.25 !important;
}

.text-opacity-50 {
  --text-opacity: 0.5 !important;
}

.text-opacity-75 {
  --text-opacity: 0.75 !important;
}

.text-opacity-100 {
  --text-opacity: 1 !important;
}

.hover\:text-opacity-0:hover {
  --text-opacity: 0 !important;
}

.hover\:text-opacity-25:hover {
  --text-opacity: 0.25 !important;
}

.hover\:text-opacity-50:hover {
  --text-opacity: 0.5 !important;
}

.hover\:text-opacity-75:hover {
  --text-opacity: 0.75 !important;
}

.hover\:text-opacity-100:hover {
  --text-opacity: 1 !important;
}

.focus\:text-opacity-0:focus {
  --text-opacity: 0 !important;
}

.focus\:text-opacity-25:focus {
  --text-opacity: 0.25 !important;
}

.focus\:text-opacity-50:focus {
  --text-opacity: 0.5 !important;
}

.focus\:text-opacity-75:focus {
  --text-opacity: 0.75 !important;
}

.focus\:text-opacity-100:focus {
  --text-opacity: 1 !important;
}

.italic {
  font-style: italic !important;
}

.not-italic {
  font-style: normal !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.hover\:line-through:hover {
  text-decoration: line-through !important;
}

.hover\:no-underline:hover {
  text-decoration: none !important;
}

.focus\:underline:focus {
  text-decoration: underline !important;
}

.focus\:line-through:focus {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus {
  text-decoration: none !important;
}

.antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
  --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
}

.normal-nums {
  font-variant-numeric: normal !important;
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal !important;
}

.slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero !important;
}

.lining-nums {
  --font-variant-numeric-figure: lining-nums !important;
}

.oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums !important;
}

.proportional-nums {
  --font-variant-numeric-spacing: proportional-nums !important;
}

.tabular-nums {
  --font-variant-numeric-spacing: tabular-nums !important;
}

.diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions !important;
}

.stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-wider {
  letter-spacing: 0.05em !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

.select-none {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-no-wrap {
  white-space: nowrap !important;
}

.whitespace-pre {
  white-space: pre !important;
}

.whitespace-pre-line {
  white-space: pre-line !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.break-normal {
  word-wrap: normal !important;
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-words {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.break-all {
  word-break: break-all !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-56 {
  width: 14rem !important;
}

.w-64 {
  width: 16rem !important;
}

.w-auto {
  width: auto !important;
}

.w-px {
  width: 1px !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-2\/4 {
  width: 50% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.w-1\/6 {
  width: 16.666667% !important;
}

.w-2\/6 {
  width: 33.333333% !important;
}

.w-3\/6 {
  width: 50% !important;
}

.w-4\/6 {
  width: 66.666667% !important;
}

.w-5\/6 {
  width: 83.333333% !important;
}

.w-1\/12 {
  width: 8.333333% !important;
}

.w-2\/12 {
  width: 16.666667% !important;
}

.w-3\/12 {
  width: 25% !important;
}

.w-4\/12 {
  width: 33.333333% !important;
}

.w-5\/12 {
  width: 41.666667% !important;
}

.w-6\/12 {
  width: 50% !important;
}

.w-7\/12 {
  width: 58.333333% !important;
}

.w-8\/12 {
  width: 66.666667% !important;
}

.w-9\/12 {
  width: 75% !important;
}

.w-10\/12 {
  width: 83.333333% !important;
}

.w-11\/12 {
  width: 91.666667% !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-none {
  width: none !important;
}

.w-xs {
  width: 20rem !important;
}

.w-sm {
  width: 24rem !important;
}

.w-md {
  width: 28rem !important;
}

.w-lg {
  width: 32rem !important;
}

.w-xl {
  width: 36rem !important;
}

.w-2xl {
  width: 42rem !important;
}

.w-3xl {
  width: 48rem !important;
}

.w-4xl {
  width: 56rem !important;
}

.w-5xl {
  width: 64rem !important;
}

.w-6xl {
  width: 72rem !important;
}

.w-screen-xs {
  width: 480px !important;
}

.w-screen-sm {
  width: 576px !important;
}

.w-screen-md {
  width: 768px !important;
}

.w-screen-lg {
  width: 992px !important;
}

.w-screen-xl {
  width: 1200px !important;
}

.w-screen-xxl {
  width: 1600px !important;
}

.w-sidebar {
  width: 15rem !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-30 {
  z-index: 30 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-auto {
  z-index: auto !important;
}

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-3 {
  grid-gap: 0.75rem !important;
  gap: 0.75rem !important;
}

.gap-4 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.gap-5 {
  grid-gap: 1.25rem !important;
  gap: 1.25rem !important;
}

.gap-6 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-8 {
  grid-gap: 2rem !important;
  gap: 2rem !important;
}

.gap-10 {
  grid-gap: 2.5rem !important;
  gap: 2.5rem !important;
}

.gap-12 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.gap-16 {
  grid-gap: 4rem !important;
  gap: 4rem !important;
}

.gap-20 {
  grid-gap: 5rem !important;
  gap: 5rem !important;
}

.gap-24 {
  grid-gap: 6rem !important;
  gap: 6rem !important;
}

.gap-32 {
  grid-gap: 8rem !important;
  gap: 8rem !important;
}

.gap-40 {
  grid-gap: 10rem !important;
  gap: 10rem !important;
}

.gap-48 {
  grid-gap: 12rem !important;
  gap: 12rem !important;
}

.gap-56 {
  grid-gap: 14rem !important;
  gap: 14rem !important;
}

.gap-64 {
  grid-gap: 16rem !important;
  gap: 16rem !important;
}

.gap-px {
  grid-gap: 1px !important;
  gap: 1px !important;
}

.gap-x-0 {
  grid-column-gap: 0 !important;
  -webkit-column-gap: 0 !important;
          column-gap: 0 !important;
}

.gap-x-1 {
  grid-column-gap: 0.25rem !important;
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}

.gap-x-2 {
  grid-column-gap: 0.5rem !important;
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}

.gap-x-3 {
  grid-column-gap: 0.75rem !important;
  -webkit-column-gap: 0.75rem !important;
          column-gap: 0.75rem !important;
}

.gap-x-4 {
  grid-column-gap: 1rem !important;
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}

.gap-x-5 {
  grid-column-gap: 1.25rem !important;
  -webkit-column-gap: 1.25rem !important;
          column-gap: 1.25rem !important;
}

.gap-x-6 {
  grid-column-gap: 1.5rem !important;
  -webkit-column-gap: 1.5rem !important;
          column-gap: 1.5rem !important;
}

.gap-x-8 {
  grid-column-gap: 2rem !important;
  -webkit-column-gap: 2rem !important;
          column-gap: 2rem !important;
}

.gap-x-10 {
  grid-column-gap: 2.5rem !important;
  -webkit-column-gap: 2.5rem !important;
          column-gap: 2.5rem !important;
}

.gap-x-12 {
  grid-column-gap: 3rem !important;
  -webkit-column-gap: 3rem !important;
          column-gap: 3rem !important;
}

.gap-x-16 {
  grid-column-gap: 4rem !important;
  -webkit-column-gap: 4rem !important;
          column-gap: 4rem !important;
}

.gap-x-20 {
  grid-column-gap: 5rem !important;
  -webkit-column-gap: 5rem !important;
          column-gap: 5rem !important;
}

.gap-x-24 {
  grid-column-gap: 6rem !important;
  -webkit-column-gap: 6rem !important;
          column-gap: 6rem !important;
}

.gap-x-32 {
  grid-column-gap: 8rem !important;
  -webkit-column-gap: 8rem !important;
          column-gap: 8rem !important;
}

.gap-x-40 {
  grid-column-gap: 10rem !important;
  -webkit-column-gap: 10rem !important;
          column-gap: 10rem !important;
}

.gap-x-48 {
  grid-column-gap: 12rem !important;
  -webkit-column-gap: 12rem !important;
          column-gap: 12rem !important;
}

.gap-x-56 {
  grid-column-gap: 14rem !important;
  -webkit-column-gap: 14rem !important;
          column-gap: 14rem !important;
}

.gap-x-64 {
  grid-column-gap: 16rem !important;
  -webkit-column-gap: 16rem !important;
          column-gap: 16rem !important;
}

.gap-x-px {
  grid-column-gap: 1px !important;
  -webkit-column-gap: 1px !important;
          column-gap: 1px !important;
}

.gap-y-0 {
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}

.gap-y-1 {
  grid-row-gap: 0.25rem !important;
  row-gap: 0.25rem !important;
}

.gap-y-2 {
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important;
}

.gap-y-3 {
  grid-row-gap: 0.75rem !important;
  row-gap: 0.75rem !important;
}

.gap-y-4 {
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important;
}

.gap-y-5 {
  grid-row-gap: 1.25rem !important;
  row-gap: 1.25rem !important;
}

.gap-y-6 {
  grid-row-gap: 1.5rem !important;
  row-gap: 1.5rem !important;
}

.gap-y-8 {
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important;
}

.gap-y-10 {
  grid-row-gap: 2.5rem !important;
  row-gap: 2.5rem !important;
}

.gap-y-12 {
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important;
}

.gap-y-16 {
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important;
}

.gap-y-20 {
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important;
}

.gap-y-24 {
  grid-row-gap: 6rem !important;
  row-gap: 6rem !important;
}

.gap-y-32 {
  grid-row-gap: 8rem !important;
  row-gap: 8rem !important;
}

.gap-y-40 {
  grid-row-gap: 10rem !important;
  row-gap: 10rem !important;
}

.gap-y-48 {
  grid-row-gap: 12rem !important;
  row-gap: 12rem !important;
}

.gap-y-56 {
  grid-row-gap: 14rem !important;
  row-gap: 14rem !important;
}

.gap-y-64 {
  grid-row-gap: 16rem !important;
  row-gap: 16rem !important;
}

.gap-y-px {
  grid-row-gap: 1px !important;
  row-gap: 1px !important;
}

.grid-flow-row {
  grid-auto-flow: row !important;
}

.grid-flow-col {
  grid-auto-flow: column !important;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense !important;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.grid-cols-none {
  grid-template-columns: none !important;
}

.auto-cols-auto {
  grid-auto-columns: auto !important;
}

.auto-cols-min {
  grid-auto-columns: -webkit-min-content !important;
  grid-auto-columns: min-content !important;
}

.auto-cols-max {
  grid-auto-columns: -webkit-max-content !important;
  grid-auto-columns: max-content !important;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr) !important;
}

.col-auto {
  grid-column: auto !important;
}

.col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.col-span-5 {
  grid-column: span 5 / span 5 !important;
}

.col-span-6 {
  grid-column: span 6 / span 6 !important;
}

.col-span-7 {
  grid-column: span 7 / span 7 !important;
}

.col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.col-span-10 {
  grid-column: span 10 / span 10 !important;
}

.col-span-11 {
  grid-column: span 11 / span 11 !important;
}

.col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.col-span-full {
  grid-column: 1 / -1 !important;
}

.col-start-1 {
  grid-column-start: 1 !important;
}

.col-start-2 {
  grid-column-start: 2 !important;
}

.col-start-3 {
  grid-column-start: 3 !important;
}

.col-start-4 {
  grid-column-start: 4 !important;
}

.col-start-5 {
  grid-column-start: 5 !important;
}

.col-start-6 {
  grid-column-start: 6 !important;
}

.col-start-7 {
  grid-column-start: 7 !important;
}

.col-start-8 {
  grid-column-start: 8 !important;
}

.col-start-9 {
  grid-column-start: 9 !important;
}

.col-start-10 {
  grid-column-start: 10 !important;
}

.col-start-11 {
  grid-column-start: 11 !important;
}

.col-start-12 {
  grid-column-start: 12 !important;
}

.col-start-13 {
  grid-column-start: 13 !important;
}

.col-start-auto {
  grid-column-start: auto !important;
}

.col-end-1 {
  grid-column-end: 1 !important;
}

.col-end-2 {
  grid-column-end: 2 !important;
}

.col-end-3 {
  grid-column-end: 3 !important;
}

.col-end-4 {
  grid-column-end: 4 !important;
}

.col-end-5 {
  grid-column-end: 5 !important;
}

.col-end-6 {
  grid-column-end: 6 !important;
}

.col-end-7 {
  grid-column-end: 7 !important;
}

.col-end-8 {
  grid-column-end: 8 !important;
}

.col-end-9 {
  grid-column-end: 9 !important;
}

.col-end-10 {
  grid-column-end: 10 !important;
}

.col-end-11 {
  grid-column-end: 11 !important;
}

.col-end-12 {
  grid-column-end: 12 !important;
}

.col-end-13 {
  grid-column-end: 13 !important;
}

.col-end-auto {
  grid-column-end: auto !important;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.grid-rows-none {
  grid-template-rows: none !important;
}

.auto-rows-auto {
  grid-auto-rows: auto !important;
}

.auto-rows-min {
  grid-auto-rows: -webkit-min-content !important;
  grid-auto-rows: min-content !important;
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content !important;
  grid-auto-rows: max-content !important;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr) !important;
}

.row-auto {
  grid-row: auto !important;
}

.row-span-1 {
  grid-row: span 1 / span 1 !important;
}

.row-span-2 {
  grid-row: span 2 / span 2 !important;
}

.row-span-3 {
  grid-row: span 3 / span 3 !important;
}

.row-span-4 {
  grid-row: span 4 / span 4 !important;
}

.row-span-5 {
  grid-row: span 5 / span 5 !important;
}

.row-span-6 {
  grid-row: span 6 / span 6 !important;
}

.row-span-full {
  grid-row: 1 / -1 !important;
}

.row-start-1 {
  grid-row-start: 1 !important;
}

.row-start-2 {
  grid-row-start: 2 !important;
}

.row-start-3 {
  grid-row-start: 3 !important;
}

.row-start-4 {
  grid-row-start: 4 !important;
}

.row-start-5 {
  grid-row-start: 5 !important;
}

.row-start-6 {
  grid-row-start: 6 !important;
}

.row-start-7 {
  grid-row-start: 7 !important;
}

.row-start-auto {
  grid-row-start: auto !important;
}

.row-end-1 {
  grid-row-end: 1 !important;
}

.row-end-2 {
  grid-row-end: 2 !important;
}

.row-end-3 {
  grid-row-end: 3 !important;
}

.row-end-4 {
  grid-row-end: 4 !important;
}

.row-end-5 {
  grid-row-end: 5 !important;
}

.row-end-6 {
  grid-row-end: 6 !important;
}

.row-end-7 {
  grid-row-end: 7 !important;
}

.row-end-auto {
  grid-row-end: auto !important;
}

.transform {
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
}

.transform-none {
  transform: none !important;
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top left !important;
}

.scale-0 {
  --transform-scale-x: 0 !important;
  --transform-scale-y: 0 !important;
}

.scale-50 {
  --transform-scale-x: .5 !important;
  --transform-scale-y: .5 !important;
}

.scale-75 {
  --transform-scale-x: .75 !important;
  --transform-scale-y: .75 !important;
}

.scale-90 {
  --transform-scale-x: .9 !important;
  --transform-scale-y: .9 !important;
}

.scale-95 {
  --transform-scale-x: .95 !important;
  --transform-scale-y: .95 !important;
}

.scale-100 {
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
}

.scale-105 {
  --transform-scale-x: 1.05 !important;
  --transform-scale-y: 1.05 !important;
}

.scale-110 {
  --transform-scale-x: 1.1 !important;
  --transform-scale-y: 1.1 !important;
}

.scale-125 {
  --transform-scale-x: 1.25 !important;
  --transform-scale-y: 1.25 !important;
}

.scale-150 {
  --transform-scale-x: 1.5 !important;
  --transform-scale-y: 1.5 !important;
}

.scale-x-0 {
  --transform-scale-x: 0 !important;
}

.scale-x-50 {
  --transform-scale-x: .5 !important;
}

.scale-x-75 {
  --transform-scale-x: .75 !important;
}

.scale-x-90 {
  --transform-scale-x: .9 !important;
}

.scale-x-95 {
  --transform-scale-x: .95 !important;
}

.scale-x-100 {
  --transform-scale-x: 1 !important;
}

.scale-x-105 {
  --transform-scale-x: 1.05 !important;
}

.scale-x-110 {
  --transform-scale-x: 1.1 !important;
}

.scale-x-125 {
  --transform-scale-x: 1.25 !important;
}

.scale-x-150 {
  --transform-scale-x: 1.5 !important;
}

.scale-y-0 {
  --transform-scale-y: 0 !important;
}

.scale-y-50 {
  --transform-scale-y: .5 !important;
}

.scale-y-75 {
  --transform-scale-y: .75 !important;
}

.scale-y-90 {
  --transform-scale-y: .9 !important;
}

.scale-y-95 {
  --transform-scale-y: .95 !important;
}

.scale-y-100 {
  --transform-scale-y: 1 !important;
}

.scale-y-105 {
  --transform-scale-y: 1.05 !important;
}

.scale-y-110 {
  --transform-scale-y: 1.1 !important;
}

.scale-y-125 {
  --transform-scale-y: 1.25 !important;
}

.scale-y-150 {
  --transform-scale-y: 1.5 !important;
}

.hover\:scale-0:hover {
  --transform-scale-x: 0 !important;
  --transform-scale-y: 0 !important;
}

.hover\:scale-50:hover {
  --transform-scale-x: .5 !important;
  --transform-scale-y: .5 !important;
}

.hover\:scale-75:hover {
  --transform-scale-x: .75 !important;
  --transform-scale-y: .75 !important;
}

.hover\:scale-90:hover {
  --transform-scale-x: .9 !important;
  --transform-scale-y: .9 !important;
}

.hover\:scale-95:hover {
  --transform-scale-x: .95 !important;
  --transform-scale-y: .95 !important;
}

.hover\:scale-100:hover {
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
}

.hover\:scale-105:hover {
  --transform-scale-x: 1.05 !important;
  --transform-scale-y: 1.05 !important;
}

.hover\:scale-110:hover {
  --transform-scale-x: 1.1 !important;
  --transform-scale-y: 1.1 !important;
}

.hover\:scale-125:hover {
  --transform-scale-x: 1.25 !important;
  --transform-scale-y: 1.25 !important;
}

.hover\:scale-150:hover {
  --transform-scale-x: 1.5 !important;
  --transform-scale-y: 1.5 !important;
}

.hover\:scale-x-0:hover {
  --transform-scale-x: 0 !important;
}

.hover\:scale-x-50:hover {
  --transform-scale-x: .5 !important;
}

.hover\:scale-x-75:hover {
  --transform-scale-x: .75 !important;
}

.hover\:scale-x-90:hover {
  --transform-scale-x: .9 !important;
}

.hover\:scale-x-95:hover {
  --transform-scale-x: .95 !important;
}

.hover\:scale-x-100:hover {
  --transform-scale-x: 1 !important;
}

.hover\:scale-x-105:hover {
  --transform-scale-x: 1.05 !important;
}

.hover\:scale-x-110:hover {
  --transform-scale-x: 1.1 !important;
}

.hover\:scale-x-125:hover {
  --transform-scale-x: 1.25 !important;
}

.hover\:scale-x-150:hover {
  --transform-scale-x: 1.5 !important;
}

.hover\:scale-y-0:hover {
  --transform-scale-y: 0 !important;
}

.hover\:scale-y-50:hover {
  --transform-scale-y: .5 !important;
}

.hover\:scale-y-75:hover {
  --transform-scale-y: .75 !important;
}

.hover\:scale-y-90:hover {
  --transform-scale-y: .9 !important;
}

.hover\:scale-y-95:hover {
  --transform-scale-y: .95 !important;
}

.hover\:scale-y-100:hover {
  --transform-scale-y: 1 !important;
}

.hover\:scale-y-105:hover {
  --transform-scale-y: 1.05 !important;
}

.hover\:scale-y-110:hover {
  --transform-scale-y: 1.1 !important;
}

.hover\:scale-y-125:hover {
  --transform-scale-y: 1.25 !important;
}

.hover\:scale-y-150:hover {
  --transform-scale-y: 1.5 !important;
}

.focus\:scale-0:focus {
  --transform-scale-x: 0 !important;
  --transform-scale-y: 0 !important;
}

.focus\:scale-50:focus {
  --transform-scale-x: .5 !important;
  --transform-scale-y: .5 !important;
}

.focus\:scale-75:focus {
  --transform-scale-x: .75 !important;
  --transform-scale-y: .75 !important;
}

.focus\:scale-90:focus {
  --transform-scale-x: .9 !important;
  --transform-scale-y: .9 !important;
}

.focus\:scale-95:focus {
  --transform-scale-x: .95 !important;
  --transform-scale-y: .95 !important;
}

.focus\:scale-100:focus {
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
}

.focus\:scale-105:focus {
  --transform-scale-x: 1.05 !important;
  --transform-scale-y: 1.05 !important;
}

.focus\:scale-110:focus {
  --transform-scale-x: 1.1 !important;
  --transform-scale-y: 1.1 !important;
}

.focus\:scale-125:focus {
  --transform-scale-x: 1.25 !important;
  --transform-scale-y: 1.25 !important;
}

.focus\:scale-150:focus {
  --transform-scale-x: 1.5 !important;
  --transform-scale-y: 1.5 !important;
}

.focus\:scale-x-0:focus {
  --transform-scale-x: 0 !important;
}

.focus\:scale-x-50:focus {
  --transform-scale-x: .5 !important;
}

.focus\:scale-x-75:focus {
  --transform-scale-x: .75 !important;
}

.focus\:scale-x-90:focus {
  --transform-scale-x: .9 !important;
}

.focus\:scale-x-95:focus {
  --transform-scale-x: .95 !important;
}

.focus\:scale-x-100:focus {
  --transform-scale-x: 1 !important;
}

.focus\:scale-x-105:focus {
  --transform-scale-x: 1.05 !important;
}

.focus\:scale-x-110:focus {
  --transform-scale-x: 1.1 !important;
}

.focus\:scale-x-125:focus {
  --transform-scale-x: 1.25 !important;
}

.focus\:scale-x-150:focus {
  --transform-scale-x: 1.5 !important;
}

.focus\:scale-y-0:focus {
  --transform-scale-y: 0 !important;
}

.focus\:scale-y-50:focus {
  --transform-scale-y: .5 !important;
}

.focus\:scale-y-75:focus {
  --transform-scale-y: .75 !important;
}

.focus\:scale-y-90:focus {
  --transform-scale-y: .9 !important;
}

.focus\:scale-y-95:focus {
  --transform-scale-y: .95 !important;
}

.focus\:scale-y-100:focus {
  --transform-scale-y: 1 !important;
}

.focus\:scale-y-105:focus {
  --transform-scale-y: 1.05 !important;
}

.focus\:scale-y-110:focus {
  --transform-scale-y: 1.1 !important;
}

.focus\:scale-y-125:focus {
  --transform-scale-y: 1.25 !important;
}

.focus\:scale-y-150:focus {
  --transform-scale-y: 1.5 !important;
}

.rotate-0 {
  --transform-rotate: 0 !important;
}

.rotate-1 {
  --transform-rotate: 1deg !important;
}

.rotate-2 {
  --transform-rotate: 2deg !important;
}

.rotate-3 {
  --transform-rotate: 3deg !important;
}

.rotate-6 {
  --transform-rotate: 6deg !important;
}

.rotate-12 {
  --transform-rotate: 12deg !important;
}

.rotate-45 {
  --transform-rotate: 45deg !important;
}

.rotate-90 {
  --transform-rotate: 90deg !important;
}

.rotate-180 {
  --transform-rotate: 180deg !important;
}

.-rotate-180 {
  --transform-rotate: -180deg !important;
}

.-rotate-90 {
  --transform-rotate: -90deg !important;
}

.-rotate-45 {
  --transform-rotate: -45deg !important;
}

.-rotate-12 {
  --transform-rotate: -12deg !important;
}

.-rotate-6 {
  --transform-rotate: -6deg !important;
}

.-rotate-3 {
  --transform-rotate: -3deg !important;
}

.-rotate-2 {
  --transform-rotate: -2deg !important;
}

.-rotate-1 {
  --transform-rotate: -1deg !important;
}

.hover\:rotate-0:hover {
  --transform-rotate: 0 !important;
}

.hover\:rotate-1:hover {
  --transform-rotate: 1deg !important;
}

.hover\:rotate-2:hover {
  --transform-rotate: 2deg !important;
}

.hover\:rotate-3:hover {
  --transform-rotate: 3deg !important;
}

.hover\:rotate-6:hover {
  --transform-rotate: 6deg !important;
}

.hover\:rotate-12:hover {
  --transform-rotate: 12deg !important;
}

.hover\:rotate-45:hover {
  --transform-rotate: 45deg !important;
}

.hover\:rotate-90:hover {
  --transform-rotate: 90deg !important;
}

.hover\:rotate-180:hover {
  --transform-rotate: 180deg !important;
}

.hover\:-rotate-180:hover {
  --transform-rotate: -180deg !important;
}

.hover\:-rotate-90:hover {
  --transform-rotate: -90deg !important;
}

.hover\:-rotate-45:hover {
  --transform-rotate: -45deg !important;
}

.hover\:-rotate-12:hover {
  --transform-rotate: -12deg !important;
}

.hover\:-rotate-6:hover {
  --transform-rotate: -6deg !important;
}

.hover\:-rotate-3:hover {
  --transform-rotate: -3deg !important;
}

.hover\:-rotate-2:hover {
  --transform-rotate: -2deg !important;
}

.hover\:-rotate-1:hover {
  --transform-rotate: -1deg !important;
}

.focus\:rotate-0:focus {
  --transform-rotate: 0 !important;
}

.focus\:rotate-1:focus {
  --transform-rotate: 1deg !important;
}

.focus\:rotate-2:focus {
  --transform-rotate: 2deg !important;
}

.focus\:rotate-3:focus {
  --transform-rotate: 3deg !important;
}

.focus\:rotate-6:focus {
  --transform-rotate: 6deg !important;
}

.focus\:rotate-12:focus {
  --transform-rotate: 12deg !important;
}

.focus\:rotate-45:focus {
  --transform-rotate: 45deg !important;
}

.focus\:rotate-90:focus {
  --transform-rotate: 90deg !important;
}

.focus\:rotate-180:focus {
  --transform-rotate: 180deg !important;
}

.focus\:-rotate-180:focus {
  --transform-rotate: -180deg !important;
}

.focus\:-rotate-90:focus {
  --transform-rotate: -90deg !important;
}

.focus\:-rotate-45:focus {
  --transform-rotate: -45deg !important;
}

.focus\:-rotate-12:focus {
  --transform-rotate: -12deg !important;
}

.focus\:-rotate-6:focus {
  --transform-rotate: -6deg !important;
}

.focus\:-rotate-3:focus {
  --transform-rotate: -3deg !important;
}

.focus\:-rotate-2:focus {
  --transform-rotate: -2deg !important;
}

.focus\:-rotate-1:focus {
  --transform-rotate: -1deg !important;
}

.translate-x-0 {
  --transform-translate-x: 0 !important;
}

.translate-x-1 {
  --transform-translate-x: 0.25rem !important;
}

.translate-x-2 {
  --transform-translate-x: 0.5rem !important;
}

.translate-x-3 {
  --transform-translate-x: 0.75rem !important;
}

.translate-x-4 {
  --transform-translate-x: 1rem !important;
}

.translate-x-5 {
  --transform-translate-x: 1.25rem !important;
}

.translate-x-6 {
  --transform-translate-x: 1.5rem !important;
}

.translate-x-8 {
  --transform-translate-x: 2rem !important;
}

.translate-x-10 {
  --transform-translate-x: 2.5rem !important;
}

.translate-x-12 {
  --transform-translate-x: 3rem !important;
}

.translate-x-16 {
  --transform-translate-x: 4rem !important;
}

.translate-x-20 {
  --transform-translate-x: 5rem !important;
}

.translate-x-24 {
  --transform-translate-x: 6rem !important;
}

.translate-x-32 {
  --transform-translate-x: 8rem !important;
}

.translate-x-40 {
  --transform-translate-x: 10rem !important;
}

.translate-x-48 {
  --transform-translate-x: 12rem !important;
}

.translate-x-56 {
  --transform-translate-x: 14rem !important;
}

.translate-x-64 {
  --transform-translate-x: 16rem !important;
}

.translate-x-px {
  --transform-translate-x: 1px !important;
}

.-translate-x-1 {
  --transform-translate-x: -0.25rem !important;
}

.-translate-x-2 {
  --transform-translate-x: -0.5rem !important;
}

.-translate-x-3 {
  --transform-translate-x: -0.75rem !important;
}

.-translate-x-4 {
  --transform-translate-x: -1rem !important;
}

.-translate-x-5 {
  --transform-translate-x: -1.25rem !important;
}

.-translate-x-6 {
  --transform-translate-x: -1.5rem !important;
}

.-translate-x-8 {
  --transform-translate-x: -2rem !important;
}

.-translate-x-10 {
  --transform-translate-x: -2.5rem !important;
}

.-translate-x-12 {
  --transform-translate-x: -3rem !important;
}

.-translate-x-16 {
  --transform-translate-x: -4rem !important;
}

.-translate-x-20 {
  --transform-translate-x: -5rem !important;
}

.-translate-x-24 {
  --transform-translate-x: -6rem !important;
}

.-translate-x-32 {
  --transform-translate-x: -8rem !important;
}

.-translate-x-40 {
  --transform-translate-x: -10rem !important;
}

.-translate-x-48 {
  --transform-translate-x: -12rem !important;
}

.-translate-x-56 {
  --transform-translate-x: -14rem !important;
}

.-translate-x-64 {
  --transform-translate-x: -16rem !important;
}

.-translate-x-px {
  --transform-translate-x: -1px !important;
}

.-translate-x-full {
  --transform-translate-x: -100% !important;
}

.-translate-x-1\/2 {
  --transform-translate-x: -50% !important;
}

.translate-x-1\/2 {
  --transform-translate-x: 50% !important;
}

.translate-x-full {
  --transform-translate-x: 100% !important;
}

.translate-y-0 {
  --transform-translate-y: 0 !important;
}

.translate-y-1 {
  --transform-translate-y: 0.25rem !important;
}

.translate-y-2 {
  --transform-translate-y: 0.5rem !important;
}

.translate-y-3 {
  --transform-translate-y: 0.75rem !important;
}

.translate-y-4 {
  --transform-translate-y: 1rem !important;
}

.translate-y-5 {
  --transform-translate-y: 1.25rem !important;
}

.translate-y-6 {
  --transform-translate-y: 1.5rem !important;
}

.translate-y-8 {
  --transform-translate-y: 2rem !important;
}

.translate-y-10 {
  --transform-translate-y: 2.5rem !important;
}

.translate-y-12 {
  --transform-translate-y: 3rem !important;
}

.translate-y-16 {
  --transform-translate-y: 4rem !important;
}

.translate-y-20 {
  --transform-translate-y: 5rem !important;
}

.translate-y-24 {
  --transform-translate-y: 6rem !important;
}

.translate-y-32 {
  --transform-translate-y: 8rem !important;
}

.translate-y-40 {
  --transform-translate-y: 10rem !important;
}

.translate-y-48 {
  --transform-translate-y: 12rem !important;
}

.translate-y-56 {
  --transform-translate-y: 14rem !important;
}

.translate-y-64 {
  --transform-translate-y: 16rem !important;
}

.translate-y-px {
  --transform-translate-y: 1px !important;
}

.-translate-y-1 {
  --transform-translate-y: -0.25rem !important;
}

.-translate-y-2 {
  --transform-translate-y: -0.5rem !important;
}

.-translate-y-3 {
  --transform-translate-y: -0.75rem !important;
}

.-translate-y-4 {
  --transform-translate-y: -1rem !important;
}

.-translate-y-5 {
  --transform-translate-y: -1.25rem !important;
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem !important;
}

.-translate-y-8 {
  --transform-translate-y: -2rem !important;
}

.-translate-y-10 {
  --transform-translate-y: -2.5rem !important;
}

.-translate-y-12 {
  --transform-translate-y: -3rem !important;
}

.-translate-y-16 {
  --transform-translate-y: -4rem !important;
}

.-translate-y-20 {
  --transform-translate-y: -5rem !important;
}

.-translate-y-24 {
  --transform-translate-y: -6rem !important;
}

.-translate-y-32 {
  --transform-translate-y: -8rem !important;
}

.-translate-y-40 {
  --transform-translate-y: -10rem !important;
}

.-translate-y-48 {
  --transform-translate-y: -12rem !important;
}

.-translate-y-56 {
  --transform-translate-y: -14rem !important;
}

.-translate-y-64 {
  --transform-translate-y: -16rem !important;
}

.-translate-y-px {
  --transform-translate-y: -1px !important;
}

.-translate-y-full {
  --transform-translate-y: -100% !important;
}

.-translate-y-1\/2 {
  --transform-translate-y: -50% !important;
}

.translate-y-1\/2 {
  --transform-translate-y: 50% !important;
}

.translate-y-full {
  --transform-translate-y: 100% !important;
}

.hover\:translate-x-0:hover {
  --transform-translate-x: 0 !important;
}

.hover\:translate-x-1:hover {
  --transform-translate-x: 0.25rem !important;
}

.hover\:translate-x-2:hover {
  --transform-translate-x: 0.5rem !important;
}

.hover\:translate-x-3:hover {
  --transform-translate-x: 0.75rem !important;
}

.hover\:translate-x-4:hover {
  --transform-translate-x: 1rem !important;
}

.hover\:translate-x-5:hover {
  --transform-translate-x: 1.25rem !important;
}

.hover\:translate-x-6:hover {
  --transform-translate-x: 1.5rem !important;
}

.hover\:translate-x-8:hover {
  --transform-translate-x: 2rem !important;
}

.hover\:translate-x-10:hover {
  --transform-translate-x: 2.5rem !important;
}

.hover\:translate-x-12:hover {
  --transform-translate-x: 3rem !important;
}

.hover\:translate-x-16:hover {
  --transform-translate-x: 4rem !important;
}

.hover\:translate-x-20:hover {
  --transform-translate-x: 5rem !important;
}

.hover\:translate-x-24:hover {
  --transform-translate-x: 6rem !important;
}

.hover\:translate-x-32:hover {
  --transform-translate-x: 8rem !important;
}

.hover\:translate-x-40:hover {
  --transform-translate-x: 10rem !important;
}

.hover\:translate-x-48:hover {
  --transform-translate-x: 12rem !important;
}

.hover\:translate-x-56:hover {
  --transform-translate-x: 14rem !important;
}

.hover\:translate-x-64:hover {
  --transform-translate-x: 16rem !important;
}

.hover\:translate-x-px:hover {
  --transform-translate-x: 1px !important;
}

.hover\:-translate-x-1:hover {
  --transform-translate-x: -0.25rem !important;
}

.hover\:-translate-x-2:hover {
  --transform-translate-x: -0.5rem !important;
}

.hover\:-translate-x-3:hover {
  --transform-translate-x: -0.75rem !important;
}

.hover\:-translate-x-4:hover {
  --transform-translate-x: -1rem !important;
}

.hover\:-translate-x-5:hover {
  --transform-translate-x: -1.25rem !important;
}

.hover\:-translate-x-6:hover {
  --transform-translate-x: -1.5rem !important;
}

.hover\:-translate-x-8:hover {
  --transform-translate-x: -2rem !important;
}

.hover\:-translate-x-10:hover {
  --transform-translate-x: -2.5rem !important;
}

.hover\:-translate-x-12:hover {
  --transform-translate-x: -3rem !important;
}

.hover\:-translate-x-16:hover {
  --transform-translate-x: -4rem !important;
}

.hover\:-translate-x-20:hover {
  --transform-translate-x: -5rem !important;
}

.hover\:-translate-x-24:hover {
  --transform-translate-x: -6rem !important;
}

.hover\:-translate-x-32:hover {
  --transform-translate-x: -8rem !important;
}

.hover\:-translate-x-40:hover {
  --transform-translate-x: -10rem !important;
}

.hover\:-translate-x-48:hover {
  --transform-translate-x: -12rem !important;
}

.hover\:-translate-x-56:hover {
  --transform-translate-x: -14rem !important;
}

.hover\:-translate-x-64:hover {
  --transform-translate-x: -16rem !important;
}

.hover\:-translate-x-px:hover {
  --transform-translate-x: -1px !important;
}

.hover\:-translate-x-full:hover {
  --transform-translate-x: -100% !important;
}

.hover\:-translate-x-1\/2:hover {
  --transform-translate-x: -50% !important;
}

.hover\:translate-x-1\/2:hover {
  --transform-translate-x: 50% !important;
}

.hover\:translate-x-full:hover {
  --transform-translate-x: 100% !important;
}

.hover\:translate-y-0:hover {
  --transform-translate-y: 0 !important;
}

.hover\:translate-y-1:hover {
  --transform-translate-y: 0.25rem !important;
}

.hover\:translate-y-2:hover {
  --transform-translate-y: 0.5rem !important;
}

.hover\:translate-y-3:hover {
  --transform-translate-y: 0.75rem !important;
}

.hover\:translate-y-4:hover {
  --transform-translate-y: 1rem !important;
}

.hover\:translate-y-5:hover {
  --transform-translate-y: 1.25rem !important;
}

.hover\:translate-y-6:hover {
  --transform-translate-y: 1.5rem !important;
}

.hover\:translate-y-8:hover {
  --transform-translate-y: 2rem !important;
}

.hover\:translate-y-10:hover {
  --transform-translate-y: 2.5rem !important;
}

.hover\:translate-y-12:hover {
  --transform-translate-y: 3rem !important;
}

.hover\:translate-y-16:hover {
  --transform-translate-y: 4rem !important;
}

.hover\:translate-y-20:hover {
  --transform-translate-y: 5rem !important;
}

.hover\:translate-y-24:hover {
  --transform-translate-y: 6rem !important;
}

.hover\:translate-y-32:hover {
  --transform-translate-y: 8rem !important;
}

.hover\:translate-y-40:hover {
  --transform-translate-y: 10rem !important;
}

.hover\:translate-y-48:hover {
  --transform-translate-y: 12rem !important;
}

.hover\:translate-y-56:hover {
  --transform-translate-y: 14rem !important;
}

.hover\:translate-y-64:hover {
  --transform-translate-y: 16rem !important;
}

.hover\:translate-y-px:hover {
  --transform-translate-y: 1px !important;
}

.hover\:-translate-y-1:hover {
  --transform-translate-y: -0.25rem !important;
}

.hover\:-translate-y-2:hover {
  --transform-translate-y: -0.5rem !important;
}

.hover\:-translate-y-3:hover {
  --transform-translate-y: -0.75rem !important;
}

.hover\:-translate-y-4:hover {
  --transform-translate-y: -1rem !important;
}

.hover\:-translate-y-5:hover {
  --transform-translate-y: -1.25rem !important;
}

.hover\:-translate-y-6:hover {
  --transform-translate-y: -1.5rem !important;
}

.hover\:-translate-y-8:hover {
  --transform-translate-y: -2rem !important;
}

.hover\:-translate-y-10:hover {
  --transform-translate-y: -2.5rem !important;
}

.hover\:-translate-y-12:hover {
  --transform-translate-y: -3rem !important;
}

.hover\:-translate-y-16:hover {
  --transform-translate-y: -4rem !important;
}

.hover\:-translate-y-20:hover {
  --transform-translate-y: -5rem !important;
}

.hover\:-translate-y-24:hover {
  --transform-translate-y: -6rem !important;
}

.hover\:-translate-y-32:hover {
  --transform-translate-y: -8rem !important;
}

.hover\:-translate-y-40:hover {
  --transform-translate-y: -10rem !important;
}

.hover\:-translate-y-48:hover {
  --transform-translate-y: -12rem !important;
}

.hover\:-translate-y-56:hover {
  --transform-translate-y: -14rem !important;
}

.hover\:-translate-y-64:hover {
  --transform-translate-y: -16rem !important;
}

.hover\:-translate-y-px:hover {
  --transform-translate-y: -1px !important;
}

.hover\:-translate-y-full:hover {
  --transform-translate-y: -100% !important;
}

.hover\:-translate-y-1\/2:hover {
  --transform-translate-y: -50% !important;
}

.hover\:translate-y-1\/2:hover {
  --transform-translate-y: 50% !important;
}

.hover\:translate-y-full:hover {
  --transform-translate-y: 100% !important;
}

.focus\:translate-x-0:focus {
  --transform-translate-x: 0 !important;
}

.focus\:translate-x-1:focus {
  --transform-translate-x: 0.25rem !important;
}

.focus\:translate-x-2:focus {
  --transform-translate-x: 0.5rem !important;
}

.focus\:translate-x-3:focus {
  --transform-translate-x: 0.75rem !important;
}

.focus\:translate-x-4:focus {
  --transform-translate-x: 1rem !important;
}

.focus\:translate-x-5:focus {
  --transform-translate-x: 1.25rem !important;
}

.focus\:translate-x-6:focus {
  --transform-translate-x: 1.5rem !important;
}

.focus\:translate-x-8:focus {
  --transform-translate-x: 2rem !important;
}

.focus\:translate-x-10:focus {
  --transform-translate-x: 2.5rem !important;
}

.focus\:translate-x-12:focus {
  --transform-translate-x: 3rem !important;
}

.focus\:translate-x-16:focus {
  --transform-translate-x: 4rem !important;
}

.focus\:translate-x-20:focus {
  --transform-translate-x: 5rem !important;
}

.focus\:translate-x-24:focus {
  --transform-translate-x: 6rem !important;
}

.focus\:translate-x-32:focus {
  --transform-translate-x: 8rem !important;
}

.focus\:translate-x-40:focus {
  --transform-translate-x: 10rem !important;
}

.focus\:translate-x-48:focus {
  --transform-translate-x: 12rem !important;
}

.focus\:translate-x-56:focus {
  --transform-translate-x: 14rem !important;
}

.focus\:translate-x-64:focus {
  --transform-translate-x: 16rem !important;
}

.focus\:translate-x-px:focus {
  --transform-translate-x: 1px !important;
}

.focus\:-translate-x-1:focus {
  --transform-translate-x: -0.25rem !important;
}

.focus\:-translate-x-2:focus {
  --transform-translate-x: -0.5rem !important;
}

.focus\:-translate-x-3:focus {
  --transform-translate-x: -0.75rem !important;
}

.focus\:-translate-x-4:focus {
  --transform-translate-x: -1rem !important;
}

.focus\:-translate-x-5:focus {
  --transform-translate-x: -1.25rem !important;
}

.focus\:-translate-x-6:focus {
  --transform-translate-x: -1.5rem !important;
}

.focus\:-translate-x-8:focus {
  --transform-translate-x: -2rem !important;
}

.focus\:-translate-x-10:focus {
  --transform-translate-x: -2.5rem !important;
}

.focus\:-translate-x-12:focus {
  --transform-translate-x: -3rem !important;
}

.focus\:-translate-x-16:focus {
  --transform-translate-x: -4rem !important;
}

.focus\:-translate-x-20:focus {
  --transform-translate-x: -5rem !important;
}

.focus\:-translate-x-24:focus {
  --transform-translate-x: -6rem !important;
}

.focus\:-translate-x-32:focus {
  --transform-translate-x: -8rem !important;
}

.focus\:-translate-x-40:focus {
  --transform-translate-x: -10rem !important;
}

.focus\:-translate-x-48:focus {
  --transform-translate-x: -12rem !important;
}

.focus\:-translate-x-56:focus {
  --transform-translate-x: -14rem !important;
}

.focus\:-translate-x-64:focus {
  --transform-translate-x: -16rem !important;
}

.focus\:-translate-x-px:focus {
  --transform-translate-x: -1px !important;
}

.focus\:-translate-x-full:focus {
  --transform-translate-x: -100% !important;
}

.focus\:-translate-x-1\/2:focus {
  --transform-translate-x: -50% !important;
}

.focus\:translate-x-1\/2:focus {
  --transform-translate-x: 50% !important;
}

.focus\:translate-x-full:focus {
  --transform-translate-x: 100% !important;
}

.focus\:translate-y-0:focus {
  --transform-translate-y: 0 !important;
}

.focus\:translate-y-1:focus {
  --transform-translate-y: 0.25rem !important;
}

.focus\:translate-y-2:focus {
  --transform-translate-y: 0.5rem !important;
}

.focus\:translate-y-3:focus {
  --transform-translate-y: 0.75rem !important;
}

.focus\:translate-y-4:focus {
  --transform-translate-y: 1rem !important;
}

.focus\:translate-y-5:focus {
  --transform-translate-y: 1.25rem !important;
}

.focus\:translate-y-6:focus {
  --transform-translate-y: 1.5rem !important;
}

.focus\:translate-y-8:focus {
  --transform-translate-y: 2rem !important;
}

.focus\:translate-y-10:focus {
  --transform-translate-y: 2.5rem !important;
}

.focus\:translate-y-12:focus {
  --transform-translate-y: 3rem !important;
}

.focus\:translate-y-16:focus {
  --transform-translate-y: 4rem !important;
}

.focus\:translate-y-20:focus {
  --transform-translate-y: 5rem !important;
}

.focus\:translate-y-24:focus {
  --transform-translate-y: 6rem !important;
}

.focus\:translate-y-32:focus {
  --transform-translate-y: 8rem !important;
}

.focus\:translate-y-40:focus {
  --transform-translate-y: 10rem !important;
}

.focus\:translate-y-48:focus {
  --transform-translate-y: 12rem !important;
}

.focus\:translate-y-56:focus {
  --transform-translate-y: 14rem !important;
}

.focus\:translate-y-64:focus {
  --transform-translate-y: 16rem !important;
}

.focus\:translate-y-px:focus {
  --transform-translate-y: 1px !important;
}

.focus\:-translate-y-1:focus {
  --transform-translate-y: -0.25rem !important;
}

.focus\:-translate-y-2:focus {
  --transform-translate-y: -0.5rem !important;
}

.focus\:-translate-y-3:focus {
  --transform-translate-y: -0.75rem !important;
}

.focus\:-translate-y-4:focus {
  --transform-translate-y: -1rem !important;
}

.focus\:-translate-y-5:focus {
  --transform-translate-y: -1.25rem !important;
}

.focus\:-translate-y-6:focus {
  --transform-translate-y: -1.5rem !important;
}

.focus\:-translate-y-8:focus {
  --transform-translate-y: -2rem !important;
}

.focus\:-translate-y-10:focus {
  --transform-translate-y: -2.5rem !important;
}

.focus\:-translate-y-12:focus {
  --transform-translate-y: -3rem !important;
}

.focus\:-translate-y-16:focus {
  --transform-translate-y: -4rem !important;
}

.focus\:-translate-y-20:focus {
  --transform-translate-y: -5rem !important;
}

.focus\:-translate-y-24:focus {
  --transform-translate-y: -6rem !important;
}

.focus\:-translate-y-32:focus {
  --transform-translate-y: -8rem !important;
}

.focus\:-translate-y-40:focus {
  --transform-translate-y: -10rem !important;
}

.focus\:-translate-y-48:focus {
  --transform-translate-y: -12rem !important;
}

.focus\:-translate-y-56:focus {
  --transform-translate-y: -14rem !important;
}

.focus\:-translate-y-64:focus {
  --transform-translate-y: -16rem !important;
}

.focus\:-translate-y-px:focus {
  --transform-translate-y: -1px !important;
}

.focus\:-translate-y-full:focus {
  --transform-translate-y: -100% !important;
}

.focus\:-translate-y-1\/2:focus {
  --transform-translate-y: -50% !important;
}

.focus\:translate-y-1\/2:focus {
  --transform-translate-y: 50% !important;
}

.focus\:translate-y-full:focus {
  --transform-translate-y: 100% !important;
}

.skew-x-0 {
  --transform-skew-x: 0 !important;
}

.skew-x-1 {
  --transform-skew-x: 1deg !important;
}

.skew-x-2 {
  --transform-skew-x: 2deg !important;
}

.skew-x-3 {
  --transform-skew-x: 3deg !important;
}

.skew-x-6 {
  --transform-skew-x: 6deg !important;
}

.skew-x-12 {
  --transform-skew-x: 12deg !important;
}

.-skew-x-12 {
  --transform-skew-x: -12deg !important;
}

.-skew-x-6 {
  --transform-skew-x: -6deg !important;
}

.-skew-x-3 {
  --transform-skew-x: -3deg !important;
}

.-skew-x-2 {
  --transform-skew-x: -2deg !important;
}

.-skew-x-1 {
  --transform-skew-x: -1deg !important;
}

.skew-y-0 {
  --transform-skew-y: 0 !important;
}

.skew-y-1 {
  --transform-skew-y: 1deg !important;
}

.skew-y-2 {
  --transform-skew-y: 2deg !important;
}

.skew-y-3 {
  --transform-skew-y: 3deg !important;
}

.skew-y-6 {
  --transform-skew-y: 6deg !important;
}

.skew-y-12 {
  --transform-skew-y: 12deg !important;
}

.-skew-y-12 {
  --transform-skew-y: -12deg !important;
}

.-skew-y-6 {
  --transform-skew-y: -6deg !important;
}

.-skew-y-3 {
  --transform-skew-y: -3deg !important;
}

.-skew-y-2 {
  --transform-skew-y: -2deg !important;
}

.-skew-y-1 {
  --transform-skew-y: -1deg !important;
}

.hover\:skew-x-0:hover {
  --transform-skew-x: 0 !important;
}

.hover\:skew-x-1:hover {
  --transform-skew-x: 1deg !important;
}

.hover\:skew-x-2:hover {
  --transform-skew-x: 2deg !important;
}

.hover\:skew-x-3:hover {
  --transform-skew-x: 3deg !important;
}

.hover\:skew-x-6:hover {
  --transform-skew-x: 6deg !important;
}

.hover\:skew-x-12:hover {
  --transform-skew-x: 12deg !important;
}

.hover\:-skew-x-12:hover {
  --transform-skew-x: -12deg !important;
}

.hover\:-skew-x-6:hover {
  --transform-skew-x: -6deg !important;
}

.hover\:-skew-x-3:hover {
  --transform-skew-x: -3deg !important;
}

.hover\:-skew-x-2:hover {
  --transform-skew-x: -2deg !important;
}

.hover\:-skew-x-1:hover {
  --transform-skew-x: -1deg !important;
}

.hover\:skew-y-0:hover {
  --transform-skew-y: 0 !important;
}

.hover\:skew-y-1:hover {
  --transform-skew-y: 1deg !important;
}

.hover\:skew-y-2:hover {
  --transform-skew-y: 2deg !important;
}

.hover\:skew-y-3:hover {
  --transform-skew-y: 3deg !important;
}

.hover\:skew-y-6:hover {
  --transform-skew-y: 6deg !important;
}

.hover\:skew-y-12:hover {
  --transform-skew-y: 12deg !important;
}

.hover\:-skew-y-12:hover {
  --transform-skew-y: -12deg !important;
}

.hover\:-skew-y-6:hover {
  --transform-skew-y: -6deg !important;
}

.hover\:-skew-y-3:hover {
  --transform-skew-y: -3deg !important;
}

.hover\:-skew-y-2:hover {
  --transform-skew-y: -2deg !important;
}

.hover\:-skew-y-1:hover {
  --transform-skew-y: -1deg !important;
}

.focus\:skew-x-0:focus {
  --transform-skew-x: 0 !important;
}

.focus\:skew-x-1:focus {
  --transform-skew-x: 1deg !important;
}

.focus\:skew-x-2:focus {
  --transform-skew-x: 2deg !important;
}

.focus\:skew-x-3:focus {
  --transform-skew-x: 3deg !important;
}

.focus\:skew-x-6:focus {
  --transform-skew-x: 6deg !important;
}

.focus\:skew-x-12:focus {
  --transform-skew-x: 12deg !important;
}

.focus\:-skew-x-12:focus {
  --transform-skew-x: -12deg !important;
}

.focus\:-skew-x-6:focus {
  --transform-skew-x: -6deg !important;
}

.focus\:-skew-x-3:focus {
  --transform-skew-x: -3deg !important;
}

.focus\:-skew-x-2:focus {
  --transform-skew-x: -2deg !important;
}

.focus\:-skew-x-1:focus {
  --transform-skew-x: -1deg !important;
}

.focus\:skew-y-0:focus {
  --transform-skew-y: 0 !important;
}

.focus\:skew-y-1:focus {
  --transform-skew-y: 1deg !important;
}

.focus\:skew-y-2:focus {
  --transform-skew-y: 2deg !important;
}

.focus\:skew-y-3:focus {
  --transform-skew-y: 3deg !important;
}

.focus\:skew-y-6:focus {
  --transform-skew-y: 6deg !important;
}

.focus\:skew-y-12:focus {
  --transform-skew-y: 12deg !important;
}

.focus\:-skew-y-12:focus {
  --transform-skew-y: -12deg !important;
}

.focus\:-skew-y-6:focus {
  --transform-skew-y: -6deg !important;
}

.focus\:-skew-y-3:focus {
  --transform-skew-y: -3deg !important;
}

.focus\:-skew-y-2:focus {
  --transform-skew-y: -2deg !important;
}

.focus\:-skew-y-1:focus {
  --transform-skew-y: -1deg !important;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke !important;
}

.transition-opacity {
  transition-property: opacity !important;
}

.transition-shadow {
  transition-property: box-shadow !important;
}

.transition-transform {
  transition-property: transform !important;
}

.ease-linear {
  transition-timing-function: linear !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.duration-75 {
  transition-duration: 75ms !important;
}

.duration-100 {
  transition-duration: 100ms !important;
}

.duration-150 {
  transition-duration: 150ms !important;
}

.duration-200 {
  transition-duration: 200ms !important;
}

.duration-300 {
  transition-duration: 300ms !important;
}

.duration-500 {
  transition-duration: 500ms !important;
}

.duration-700 {
  transition-duration: 700ms !important;
}

.duration-1000 {
  transition-duration: 1000ms !important;
}

.delay-75 {
  transition-delay: 75ms !important;
}

.delay-100 {
  transition-delay: 100ms !important;
}

.delay-150 {
  transition-delay: 150ms !important;
}

.delay-200 {
  transition-delay: 200ms !important;
}

.delay-300 {
  transition-delay: 300ms !important;
}

.delay-500 {
  transition-delay: 500ms !important;
}

.delay-700 {
  transition-delay: 700ms !important;
}

.delay-1000 {
  transition-delay: 1000ms !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-none {
  animation: none !important;
}

.animate-spin {
  animation: spin 1s linear infinite !important;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

.animate-bounce {
  animation: bounce 1s infinite !important;
}

.link {
  color: var(--primary);
  cursor: pointer;
  transition: color 0.3s ease;
}

/* purgecss end ignore */

.transition-all {
  transition: all 0.3s ease;
}

.center-x {
  left: 50%;
  transform: translateX(-50);
}

.center-y {
  left: 50%;
  transform: translateX(-50);
}

.center-y {
  top: 50%;
  transform: translateY(-50);
}

.center-xy,
.center-yx {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50);
}

:root {
  --primary: #4cb75b;
}

.link:hover {
  opacity: 0.75;
}

@media (min-width: 480px) {
  .xs\:container {
    width: 100%;
  }

  @media (min-width: 480px) {
    .xs\:container {
      max-width: 480px;
    }
  }

  @media (min-width: 576px) {
    .xs\:container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .xs\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .xs\:container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .xs\:container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .xs\:container {
      max-width: 1600px;
    }
  }

  .xs\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .xs\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .xs\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .xs\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .xs\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .xs\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .xs\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .xs\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
  }

  .xs\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--primary) !important;
  }

  .xs\:divide-accent-1 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-accent-2 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
  }

  .xs\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid !important;
  }

  .xs\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed !important;
  }

  .xs\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted !important;
  }

  .xs\:divide-double > :not(template) ~ :not(template) {
    border-style: double !important;
  }

  .xs\:divide-none > :not(template) ~ :not(template) {
    border-style: none !important;
  }

  .xs\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .xs\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .xs\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .xs\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .xs\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .xs\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xs\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xs\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xs\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xs\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .xs\:bg-fixed {
    background-attachment: fixed !important;
  }

  .xs\:bg-local {
    background-attachment: local !important;
  }

  .xs\:bg-scroll {
    background-attachment: scroll !important;
  }

  .xs\:bg-clip-border {
    background-clip: border-box !important;
  }

  .xs\:bg-clip-padding {
    background-clip: padding-box !important;
  }

  .xs\:bg-clip-content {
    background-clip: content-box !important;
  }

  .xs\:bg-clip-text {
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .xs\:bg-transparent {
    background-color: transparent !important;
  }

  .xs\:bg-current {
    background-color: currentColor !important;
  }

  .xs\:bg-black {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xs\:bg-white {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-100 {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-200 {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-300 {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-400 {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-500 {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-600 {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-700 {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-800 {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xs\:bg-gray-900 {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-300 {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-400 {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-500 {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-600 {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-700 {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-800 {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xs\:bg-red-900 {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-100 {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-200 {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-300 {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-400 {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-500 {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-600 {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-700 {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-800 {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xs\:bg-orange-900 {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-100 {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-200 {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-300 {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-400 {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-500 {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-600 {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-700 {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-800 {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xs\:bg-yellow-900 {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-100 {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-200 {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-300 {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-400 {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-500 {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-600 {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-700 {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-800 {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xs\:bg-green-900 {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-100 {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-200 {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-300 {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-400 {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-500 {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-600 {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-700 {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-800 {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xs\:bg-teal-900 {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-200 {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-300 {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-400 {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-500 {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-600 {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-700 {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-800 {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xs\:bg-blue-900 {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-200 {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-300 {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-400 {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-500 {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-600 {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-700 {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-800 {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xs\:bg-indigo-900 {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-100 {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-200 {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-300 {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-400 {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-500 {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-600 {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-700 {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-800 {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xs\:bg-purple-900 {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-300 {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-400 {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-500 {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-600 {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-700 {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-800 {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xs\:bg-pink-900 {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xs\:bg-primary {
    background-color: var(--primary) !important;
  }

  .xs\:bg-accent-1 {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xs\:bg-accent-2 {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-transparent:hover {
    background-color: transparent !important;
  }

  .xs\:hover\:bg-current:hover {
    background-color: currentColor !important;
  }

  .xs\:hover\:bg-black:hover {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-white:hover {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-200:hover {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-300:hover {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-400:hover {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-500:hover {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-600:hover {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-gray-900:hover {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-300:hover {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-400:hover {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-500:hover {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-600:hover {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-red-900:hover {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-200:hover {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-600:hover {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-orange-900:hover {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-300:hover {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-400:hover {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-500:hover {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-600:hover {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-800:hover {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-green-900:hover {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-100:hover {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-200:hover {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-300:hover {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-400:hover {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-500:hover {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-600:hover {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-800:hover {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-teal-900:hover {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-200:hover {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-300:hover {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-400:hover {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-500:hover {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-600:hover {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-blue-900:hover {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-100:hover {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-200:hover {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-300:hover {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-400:hover {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-500:hover {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-600:hover {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-700:hover {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-800:hover {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-purple-900:hover {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-800:hover {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-pink-900:hover {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-primary:hover {
    background-color: var(--primary) !important;
  }

  .xs\:hover\:bg-accent-1:hover {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xs\:hover\:bg-accent-2:hover {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-transparent:focus {
    background-color: transparent !important;
  }

  .xs\:focus\:bg-current:focus {
    background-color: currentColor !important;
  }

  .xs\:focus\:bg-black:focus {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-white:focus {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-200:focus {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-300:focus {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-400:focus {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-500:focus {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-600:focus {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-gray-900:focus {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-300:focus {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-400:focus {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-500:focus {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-600:focus {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-red-900:focus {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-200:focus {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-600:focus {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-orange-900:focus {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-300:focus {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-400:focus {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-500:focus {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-600:focus {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-800:focus {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-green-900:focus {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-100:focus {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-200:focus {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-300:focus {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-400:focus {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-500:focus {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-600:focus {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-800:focus {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-teal-900:focus {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-200:focus {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-300:focus {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-400:focus {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-500:focus {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-600:focus {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-blue-900:focus {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-100:focus {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-200:focus {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-300:focus {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-400:focus {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-500:focus {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-600:focus {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-700:focus {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-800:focus {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-purple-900:focus {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-800:focus {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-pink-900:focus {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-primary:focus {
    background-color: var(--primary) !important;
  }

  .xs\:focus\:bg-accent-1:focus {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xs\:focus\:bg-accent-2:focus {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xs\:bg-none {
    background-image: none !important;
  }

  .xs\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
  }

  .xs\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
  }

  .xs\:from-transparent {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:from-current {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:from-black {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:from-white {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:from-gray-100 {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xs\:from-gray-200 {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xs\:from-gray-300 {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xs\:from-gray-400 {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xs\:from-gray-500 {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xs\:from-gray-600 {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xs\:from-gray-700 {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xs\:from-gray-800 {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xs\:from-gray-900 {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xs\:from-red-100 {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xs\:from-red-200 {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xs\:from-red-300 {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xs\:from-red-400 {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xs\:from-red-500 {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xs\:from-red-600 {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xs\:from-red-700 {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xs\:from-red-800 {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xs\:from-red-900 {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xs\:from-orange-100 {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xs\:from-orange-200 {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xs\:from-orange-300 {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xs\:from-orange-400 {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xs\:from-orange-500 {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xs\:from-orange-600 {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xs\:from-orange-700 {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xs\:from-orange-800 {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xs\:from-orange-900 {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xs\:from-yellow-100 {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xs\:from-yellow-200 {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xs\:from-yellow-300 {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xs\:from-yellow-400 {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xs\:from-yellow-500 {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xs\:from-yellow-600 {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xs\:from-yellow-700 {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xs\:from-yellow-800 {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xs\:from-yellow-900 {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xs\:from-green-100 {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xs\:from-green-200 {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xs\:from-green-300 {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xs\:from-green-400 {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xs\:from-green-500 {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xs\:from-green-600 {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xs\:from-green-700 {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xs\:from-green-800 {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xs\:from-green-900 {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xs\:from-teal-100 {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xs\:from-teal-200 {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xs\:from-teal-300 {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xs\:from-teal-400 {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xs\:from-teal-500 {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xs\:from-teal-600 {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xs\:from-teal-700 {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xs\:from-teal-800 {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xs\:from-teal-900 {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xs\:from-blue-100 {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xs\:from-blue-200 {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xs\:from-blue-300 {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xs\:from-blue-400 {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xs\:from-blue-500 {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xs\:from-blue-600 {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xs\:from-blue-700 {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xs\:from-blue-800 {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xs\:from-blue-900 {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xs\:from-indigo-100 {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xs\:from-indigo-200 {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xs\:from-indigo-300 {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xs\:from-indigo-400 {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xs\:from-indigo-500 {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xs\:from-indigo-600 {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xs\:from-indigo-700 {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xs\:from-indigo-800 {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xs\:from-indigo-900 {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xs\:from-purple-100 {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xs\:from-purple-200 {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xs\:from-purple-300 {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xs\:from-purple-400 {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xs\:from-purple-500 {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xs\:from-purple-600 {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xs\:from-purple-700 {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xs\:from-purple-800 {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xs\:from-purple-900 {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xs\:from-pink-100 {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xs\:from-pink-200 {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xs\:from-pink-300 {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xs\:from-pink-400 {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xs\:from-pink-500 {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xs\:from-pink-600 {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xs\:from-pink-700 {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xs\:from-pink-800 {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xs\:from-pink-900 {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xs\:from-primary {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:from-accent-1 {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xs\:from-accent-2 {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xs\:via-transparent {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:via-current {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:via-black {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:via-white {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:via-gray-100 {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xs\:via-gray-200 {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xs\:via-gray-300 {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xs\:via-gray-400 {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xs\:via-gray-500 {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xs\:via-gray-600 {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xs\:via-gray-700 {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xs\:via-gray-800 {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xs\:via-gray-900 {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xs\:via-red-100 {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xs\:via-red-200 {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xs\:via-red-300 {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xs\:via-red-400 {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xs\:via-red-500 {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xs\:via-red-600 {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xs\:via-red-700 {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xs\:via-red-800 {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xs\:via-red-900 {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xs\:via-orange-100 {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xs\:via-orange-200 {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xs\:via-orange-300 {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xs\:via-orange-400 {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xs\:via-orange-500 {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xs\:via-orange-600 {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xs\:via-orange-700 {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xs\:via-orange-800 {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xs\:via-orange-900 {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xs\:via-yellow-100 {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xs\:via-yellow-200 {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xs\:via-yellow-300 {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xs\:via-yellow-400 {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xs\:via-yellow-500 {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xs\:via-yellow-600 {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xs\:via-yellow-700 {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xs\:via-yellow-800 {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xs\:via-yellow-900 {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xs\:via-green-100 {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xs\:via-green-200 {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xs\:via-green-300 {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xs\:via-green-400 {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xs\:via-green-500 {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xs\:via-green-600 {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xs\:via-green-700 {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xs\:via-green-800 {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xs\:via-green-900 {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xs\:via-teal-100 {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xs\:via-teal-200 {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xs\:via-teal-300 {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xs\:via-teal-400 {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xs\:via-teal-500 {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xs\:via-teal-600 {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xs\:via-teal-700 {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xs\:via-teal-800 {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xs\:via-teal-900 {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xs\:via-blue-100 {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xs\:via-blue-200 {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xs\:via-blue-300 {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xs\:via-blue-400 {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xs\:via-blue-500 {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xs\:via-blue-600 {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xs\:via-blue-700 {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xs\:via-blue-800 {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xs\:via-blue-900 {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xs\:via-indigo-100 {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xs\:via-indigo-200 {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xs\:via-indigo-300 {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xs\:via-indigo-400 {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xs\:via-indigo-500 {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xs\:via-indigo-600 {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xs\:via-indigo-700 {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xs\:via-indigo-800 {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xs\:via-indigo-900 {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xs\:via-purple-100 {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xs\:via-purple-200 {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xs\:via-purple-300 {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xs\:via-purple-400 {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xs\:via-purple-500 {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xs\:via-purple-600 {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xs\:via-purple-700 {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xs\:via-purple-800 {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xs\:via-purple-900 {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xs\:via-pink-100 {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xs\:via-pink-200 {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xs\:via-pink-300 {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xs\:via-pink-400 {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xs\:via-pink-500 {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xs\:via-pink-600 {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xs\:via-pink-700 {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xs\:via-pink-800 {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xs\:via-pink-900 {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xs\:via-primary {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:via-accent-1 {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xs\:via-accent-2 {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xs\:to-transparent {
    --gradient-to-color: transparent !important;
  }

  .xs\:to-current {
    --gradient-to-color: currentColor !important;
  }

  .xs\:to-black {
    --gradient-to-color: #000 !important;
  }

  .xs\:to-white {
    --gradient-to-color: #fff !important;
  }

  .xs\:to-gray-100 {
    --gradient-to-color: #f7fafc !important;
  }

  .xs\:to-gray-200 {
    --gradient-to-color: #edf2f7 !important;
  }

  .xs\:to-gray-300 {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xs\:to-gray-400 {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xs\:to-gray-500 {
    --gradient-to-color: #a0aec0 !important;
  }

  .xs\:to-gray-600 {
    --gradient-to-color: #718096 !important;
  }

  .xs\:to-gray-700 {
    --gradient-to-color: #4a5568 !important;
  }

  .xs\:to-gray-800 {
    --gradient-to-color: #2d3748 !important;
  }

  .xs\:to-gray-900 {
    --gradient-to-color: #1a202c !important;
  }

  .xs\:to-red-100 {
    --gradient-to-color: #fff5f5 !important;
  }

  .xs\:to-red-200 {
    --gradient-to-color: #fed7d7 !important;
  }

  .xs\:to-red-300 {
    --gradient-to-color: #feb2b2 !important;
  }

  .xs\:to-red-400 {
    --gradient-to-color: #fc8181 !important;
  }

  .xs\:to-red-500 {
    --gradient-to-color: #f56565 !important;
  }

  .xs\:to-red-600 {
    --gradient-to-color: #e53e3e !important;
  }

  .xs\:to-red-700 {
    --gradient-to-color: #c53030 !important;
  }

  .xs\:to-red-800 {
    --gradient-to-color: #9b2c2c !important;
  }

  .xs\:to-red-900 {
    --gradient-to-color: #742a2a !important;
  }

  .xs\:to-orange-100 {
    --gradient-to-color: #fffaf0 !important;
  }

  .xs\:to-orange-200 {
    --gradient-to-color: #feebc8 !important;
  }

  .xs\:to-orange-300 {
    --gradient-to-color: #fbd38d !important;
  }

  .xs\:to-orange-400 {
    --gradient-to-color: #f6ad55 !important;
  }

  .xs\:to-orange-500 {
    --gradient-to-color: #ed8936 !important;
  }

  .xs\:to-orange-600 {
    --gradient-to-color: #dd6b20 !important;
  }

  .xs\:to-orange-700 {
    --gradient-to-color: #c05621 !important;
  }

  .xs\:to-orange-800 {
    --gradient-to-color: #9c4221 !important;
  }

  .xs\:to-orange-900 {
    --gradient-to-color: #7b341e !important;
  }

  .xs\:to-yellow-100 {
    --gradient-to-color: #fffff0 !important;
  }

  .xs\:to-yellow-200 {
    --gradient-to-color: #fefcbf !important;
  }

  .xs\:to-yellow-300 {
    --gradient-to-color: #faf089 !important;
  }

  .xs\:to-yellow-400 {
    --gradient-to-color: #f6e05e !important;
  }

  .xs\:to-yellow-500 {
    --gradient-to-color: #ecc94b !important;
  }

  .xs\:to-yellow-600 {
    --gradient-to-color: #d69e2e !important;
  }

  .xs\:to-yellow-700 {
    --gradient-to-color: #b7791f !important;
  }

  .xs\:to-yellow-800 {
    --gradient-to-color: #975a16 !important;
  }

  .xs\:to-yellow-900 {
    --gradient-to-color: #744210 !important;
  }

  .xs\:to-green-100 {
    --gradient-to-color: #f0fff4 !important;
  }

  .xs\:to-green-200 {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xs\:to-green-300 {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xs\:to-green-400 {
    --gradient-to-color: #68d391 !important;
  }

  .xs\:to-green-500 {
    --gradient-to-color: #48bb78 !important;
  }

  .xs\:to-green-600 {
    --gradient-to-color: #38a169 !important;
  }

  .xs\:to-green-700 {
    --gradient-to-color: #2f855a !important;
  }

  .xs\:to-green-800 {
    --gradient-to-color: #276749 !important;
  }

  .xs\:to-green-900 {
    --gradient-to-color: #22543d !important;
  }

  .xs\:to-teal-100 {
    --gradient-to-color: #e6fffa !important;
  }

  .xs\:to-teal-200 {
    --gradient-to-color: #b2f5ea !important;
  }

  .xs\:to-teal-300 {
    --gradient-to-color: #81e6d9 !important;
  }

  .xs\:to-teal-400 {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xs\:to-teal-500 {
    --gradient-to-color: #38b2ac !important;
  }

  .xs\:to-teal-600 {
    --gradient-to-color: #319795 !important;
  }

  .xs\:to-teal-700 {
    --gradient-to-color: #2c7a7b !important;
  }

  .xs\:to-teal-800 {
    --gradient-to-color: #285e61 !important;
  }

  .xs\:to-teal-900 {
    --gradient-to-color: #234e52 !important;
  }

  .xs\:to-blue-100 {
    --gradient-to-color: #ebf8ff !important;
  }

  .xs\:to-blue-200 {
    --gradient-to-color: #bee3f8 !important;
  }

  .xs\:to-blue-300 {
    --gradient-to-color: #90cdf4 !important;
  }

  .xs\:to-blue-400 {
    --gradient-to-color: #63b3ed !important;
  }

  .xs\:to-blue-500 {
    --gradient-to-color: #4299e1 !important;
  }

  .xs\:to-blue-600 {
    --gradient-to-color: #3182ce !important;
  }

  .xs\:to-blue-700 {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xs\:to-blue-800 {
    --gradient-to-color: #2c5282 !important;
  }

  .xs\:to-blue-900 {
    --gradient-to-color: #2a4365 !important;
  }

  .xs\:to-indigo-100 {
    --gradient-to-color: #ebf4ff !important;
  }

  .xs\:to-indigo-200 {
    --gradient-to-color: #c3dafe !important;
  }

  .xs\:to-indigo-300 {
    --gradient-to-color: #a3bffa !important;
  }

  .xs\:to-indigo-400 {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xs\:to-indigo-500 {
    --gradient-to-color: #667eea !important;
  }

  .xs\:to-indigo-600 {
    --gradient-to-color: #5a67d8 !important;
  }

  .xs\:to-indigo-700 {
    --gradient-to-color: #4c51bf !important;
  }

  .xs\:to-indigo-800 {
    --gradient-to-color: #434190 !important;
  }

  .xs\:to-indigo-900 {
    --gradient-to-color: #3c366b !important;
  }

  .xs\:to-purple-100 {
    --gradient-to-color: #faf5ff !important;
  }

  .xs\:to-purple-200 {
    --gradient-to-color: #e9d8fd !important;
  }

  .xs\:to-purple-300 {
    --gradient-to-color: #d6bcfa !important;
  }

  .xs\:to-purple-400 {
    --gradient-to-color: #b794f4 !important;
  }

  .xs\:to-purple-500 {
    --gradient-to-color: #9f7aea !important;
  }

  .xs\:to-purple-600 {
    --gradient-to-color: #805ad5 !important;
  }

  .xs\:to-purple-700 {
    --gradient-to-color: #6b46c1 !important;
  }

  .xs\:to-purple-800 {
    --gradient-to-color: #553c9a !important;
  }

  .xs\:to-purple-900 {
    --gradient-to-color: #44337a !important;
  }

  .xs\:to-pink-100 {
    --gradient-to-color: #fff5f7 !important;
  }

  .xs\:to-pink-200 {
    --gradient-to-color: #fed7e2 !important;
  }

  .xs\:to-pink-300 {
    --gradient-to-color: #fbb6ce !important;
  }

  .xs\:to-pink-400 {
    --gradient-to-color: #f687b3 !important;
  }

  .xs\:to-pink-500 {
    --gradient-to-color: #ed64a6 !important;
  }

  .xs\:to-pink-600 {
    --gradient-to-color: #d53f8c !important;
  }

  .xs\:to-pink-700 {
    --gradient-to-color: #b83280 !important;
  }

  .xs\:to-pink-800 {
    --gradient-to-color: #97266d !important;
  }

  .xs\:to-pink-900 {
    --gradient-to-color: #702459 !important;
  }

  .xs\:to-primary {
    --gradient-to-color: var(--primary) !important;
  }

  .xs\:to-accent-1 {
    --gradient-to-color: #fafafa !important;
  }

  .xs\:to-accent-2 {
    --gradient-to-color: #eaeaea !important;
  }

  .xs\:hover\:from-transparent:hover {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:hover\:from-current:hover {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:hover\:from-black:hover {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:hover\:from-white:hover {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xs\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xs\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xs\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xs\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xs\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xs\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xs\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xs\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xs\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xs\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xs\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xs\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xs\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xs\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xs\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xs\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xs\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xs\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xs\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xs\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xs\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xs\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xs\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xs\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xs\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xs\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xs\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xs\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xs\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xs\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xs\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xs\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xs\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xs\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xs\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xs\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xs\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xs\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xs\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xs\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xs\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xs\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xs\:hover\:from-green-800:hover {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xs\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xs\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xs\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xs\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xs\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xs\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xs\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xs\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xs\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xs\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xs\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xs\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xs\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xs\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xs\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xs\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xs\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xs\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xs\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xs\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xs\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xs\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xs\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xs\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xs\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xs\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xs\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xs\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xs\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xs\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xs\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xs\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xs\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xs\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xs\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xs\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xs\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xs\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xs\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xs\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xs\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xs\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xs\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xs\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xs\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xs\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xs\:hover\:from-primary:hover {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:hover\:from-accent-1:hover {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xs\:hover\:from-accent-2:hover {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xs\:hover\:via-transparent:hover {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:hover\:via-current:hover {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:hover\:via-black:hover {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:hover\:via-white:hover {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xs\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xs\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xs\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xs\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xs\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xs\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xs\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xs\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xs\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xs\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xs\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xs\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xs\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xs\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xs\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xs\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xs\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xs\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xs\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xs\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xs\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xs\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xs\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xs\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xs\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xs\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xs\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xs\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xs\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xs\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xs\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xs\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xs\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xs\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xs\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xs\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xs\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xs\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xs\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xs\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xs\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xs\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xs\:hover\:via-green-800:hover {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xs\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xs\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xs\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xs\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xs\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xs\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xs\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xs\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xs\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xs\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xs\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xs\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xs\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xs\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xs\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xs\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xs\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xs\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xs\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xs\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xs\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xs\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xs\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xs\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xs\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xs\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xs\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xs\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xs\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xs\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xs\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xs\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xs\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xs\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xs\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xs\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xs\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xs\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xs\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xs\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xs\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xs\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xs\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xs\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xs\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xs\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xs\:hover\:via-primary:hover {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:hover\:via-accent-1:hover {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xs\:hover\:via-accent-2:hover {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xs\:hover\:to-transparent:hover {
    --gradient-to-color: transparent !important;
  }

  .xs\:hover\:to-current:hover {
    --gradient-to-color: currentColor !important;
  }

  .xs\:hover\:to-black:hover {
    --gradient-to-color: #000 !important;
  }

  .xs\:hover\:to-white:hover {
    --gradient-to-color: #fff !important;
  }

  .xs\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc !important;
  }

  .xs\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7 !important;
  }

  .xs\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xs\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xs\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0 !important;
  }

  .xs\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096 !important;
  }

  .xs\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568 !important;
  }

  .xs\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748 !important;
  }

  .xs\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c !important;
  }

  .xs\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5 !important;
  }

  .xs\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7 !important;
  }

  .xs\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2 !important;
  }

  .xs\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181 !important;
  }

  .xs\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565 !important;
  }

  .xs\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e !important;
  }

  .xs\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030 !important;
  }

  .xs\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c !important;
  }

  .xs\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a !important;
  }

  .xs\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0 !important;
  }

  .xs\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8 !important;
  }

  .xs\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d !important;
  }

  .xs\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55 !important;
  }

  .xs\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936 !important;
  }

  .xs\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20 !important;
  }

  .xs\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621 !important;
  }

  .xs\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221 !important;
  }

  .xs\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e !important;
  }

  .xs\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0 !important;
  }

  .xs\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf !important;
  }

  .xs\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089 !important;
  }

  .xs\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e !important;
  }

  .xs\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b !important;
  }

  .xs\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e !important;
  }

  .xs\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f !important;
  }

  .xs\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16 !important;
  }

  .xs\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210 !important;
  }

  .xs\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4 !important;
  }

  .xs\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xs\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xs\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391 !important;
  }

  .xs\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78 !important;
  }

  .xs\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169 !important;
  }

  .xs\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a !important;
  }

  .xs\:hover\:to-green-800:hover {
    --gradient-to-color: #276749 !important;
  }

  .xs\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d !important;
  }

  .xs\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa !important;
  }

  .xs\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea !important;
  }

  .xs\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9 !important;
  }

  .xs\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xs\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac !important;
  }

  .xs\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795 !important;
  }

  .xs\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b !important;
  }

  .xs\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61 !important;
  }

  .xs\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52 !important;
  }

  .xs\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff !important;
  }

  .xs\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8 !important;
  }

  .xs\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4 !important;
  }

  .xs\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed !important;
  }

  .xs\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1 !important;
  }

  .xs\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce !important;
  }

  .xs\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xs\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282 !important;
  }

  .xs\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365 !important;
  }

  .xs\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff !important;
  }

  .xs\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe !important;
  }

  .xs\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa !important;
  }

  .xs\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xs\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea !important;
  }

  .xs\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8 !important;
  }

  .xs\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf !important;
  }

  .xs\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190 !important;
  }

  .xs\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b !important;
  }

  .xs\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff !important;
  }

  .xs\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd !important;
  }

  .xs\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa !important;
  }

  .xs\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4 !important;
  }

  .xs\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea !important;
  }

  .xs\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5 !important;
  }

  .xs\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1 !important;
  }

  .xs\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a !important;
  }

  .xs\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a !important;
  }

  .xs\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7 !important;
  }

  .xs\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2 !important;
  }

  .xs\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce !important;
  }

  .xs\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3 !important;
  }

  .xs\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6 !important;
  }

  .xs\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c !important;
  }

  .xs\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280 !important;
  }

  .xs\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d !important;
  }

  .xs\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459 !important;
  }

  .xs\:hover\:to-primary:hover {
    --gradient-to-color: var(--primary) !important;
  }

  .xs\:hover\:to-accent-1:hover {
    --gradient-to-color: #fafafa !important;
  }

  .xs\:hover\:to-accent-2:hover {
    --gradient-to-color: #eaeaea !important;
  }

  .xs\:focus\:from-transparent:focus {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:focus\:from-current:focus {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:focus\:from-black:focus {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:focus\:from-white:focus {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xs\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xs\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xs\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xs\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xs\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xs\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xs\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xs\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xs\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xs\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xs\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xs\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xs\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xs\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xs\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xs\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xs\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xs\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xs\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xs\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xs\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xs\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xs\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xs\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xs\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xs\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xs\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xs\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xs\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xs\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xs\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xs\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xs\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xs\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xs\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xs\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xs\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xs\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xs\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xs\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xs\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xs\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xs\:focus\:from-green-800:focus {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xs\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xs\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xs\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xs\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xs\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xs\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xs\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xs\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xs\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xs\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xs\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xs\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xs\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xs\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xs\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xs\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xs\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xs\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xs\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xs\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xs\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xs\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xs\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xs\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xs\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xs\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xs\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xs\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xs\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xs\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xs\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xs\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xs\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xs\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xs\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xs\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xs\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xs\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xs\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xs\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xs\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xs\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xs\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xs\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xs\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xs\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xs\:focus\:from-primary:focus {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:focus\:from-accent-1:focus {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xs\:focus\:from-accent-2:focus {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xs\:focus\:via-transparent:focus {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:focus\:via-current:focus {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:focus\:via-black:focus {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xs\:focus\:via-white:focus {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xs\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xs\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xs\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xs\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xs\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xs\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xs\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xs\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xs\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xs\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xs\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xs\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xs\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xs\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xs\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xs\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xs\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xs\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xs\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xs\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xs\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xs\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xs\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xs\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xs\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xs\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xs\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xs\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xs\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xs\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xs\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xs\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xs\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xs\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xs\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xs\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xs\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xs\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xs\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xs\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xs\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xs\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xs\:focus\:via-green-800:focus {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xs\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xs\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xs\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xs\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xs\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xs\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xs\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xs\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xs\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xs\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xs\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xs\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xs\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xs\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xs\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xs\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xs\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xs\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xs\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xs\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xs\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xs\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xs\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xs\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xs\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xs\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xs\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xs\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xs\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xs\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xs\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xs\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xs\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xs\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xs\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xs\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xs\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xs\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xs\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xs\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xs\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xs\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xs\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xs\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xs\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xs\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xs\:focus\:via-primary:focus {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xs\:focus\:via-accent-1:focus {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xs\:focus\:via-accent-2:focus {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xs\:focus\:to-transparent:focus {
    --gradient-to-color: transparent !important;
  }

  .xs\:focus\:to-current:focus {
    --gradient-to-color: currentColor !important;
  }

  .xs\:focus\:to-black:focus {
    --gradient-to-color: #000 !important;
  }

  .xs\:focus\:to-white:focus {
    --gradient-to-color: #fff !important;
  }

  .xs\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc !important;
  }

  .xs\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7 !important;
  }

  .xs\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xs\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xs\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0 !important;
  }

  .xs\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096 !important;
  }

  .xs\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568 !important;
  }

  .xs\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748 !important;
  }

  .xs\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c !important;
  }

  .xs\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5 !important;
  }

  .xs\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7 !important;
  }

  .xs\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2 !important;
  }

  .xs\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181 !important;
  }

  .xs\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565 !important;
  }

  .xs\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e !important;
  }

  .xs\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030 !important;
  }

  .xs\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c !important;
  }

  .xs\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a !important;
  }

  .xs\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0 !important;
  }

  .xs\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8 !important;
  }

  .xs\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d !important;
  }

  .xs\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55 !important;
  }

  .xs\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936 !important;
  }

  .xs\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20 !important;
  }

  .xs\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621 !important;
  }

  .xs\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221 !important;
  }

  .xs\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e !important;
  }

  .xs\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0 !important;
  }

  .xs\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf !important;
  }

  .xs\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089 !important;
  }

  .xs\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e !important;
  }

  .xs\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b !important;
  }

  .xs\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e !important;
  }

  .xs\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f !important;
  }

  .xs\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16 !important;
  }

  .xs\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210 !important;
  }

  .xs\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4 !important;
  }

  .xs\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xs\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xs\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391 !important;
  }

  .xs\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78 !important;
  }

  .xs\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169 !important;
  }

  .xs\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a !important;
  }

  .xs\:focus\:to-green-800:focus {
    --gradient-to-color: #276749 !important;
  }

  .xs\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d !important;
  }

  .xs\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa !important;
  }

  .xs\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea !important;
  }

  .xs\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9 !important;
  }

  .xs\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xs\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac !important;
  }

  .xs\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795 !important;
  }

  .xs\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b !important;
  }

  .xs\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61 !important;
  }

  .xs\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52 !important;
  }

  .xs\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff !important;
  }

  .xs\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8 !important;
  }

  .xs\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4 !important;
  }

  .xs\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed !important;
  }

  .xs\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1 !important;
  }

  .xs\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce !important;
  }

  .xs\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xs\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282 !important;
  }

  .xs\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365 !important;
  }

  .xs\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff !important;
  }

  .xs\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe !important;
  }

  .xs\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa !important;
  }

  .xs\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xs\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea !important;
  }

  .xs\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8 !important;
  }

  .xs\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf !important;
  }

  .xs\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190 !important;
  }

  .xs\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b !important;
  }

  .xs\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff !important;
  }

  .xs\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd !important;
  }

  .xs\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa !important;
  }

  .xs\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4 !important;
  }

  .xs\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea !important;
  }

  .xs\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5 !important;
  }

  .xs\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1 !important;
  }

  .xs\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a !important;
  }

  .xs\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a !important;
  }

  .xs\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7 !important;
  }

  .xs\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2 !important;
  }

  .xs\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce !important;
  }

  .xs\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3 !important;
  }

  .xs\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6 !important;
  }

  .xs\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c !important;
  }

  .xs\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280 !important;
  }

  .xs\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d !important;
  }

  .xs\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459 !important;
  }

  .xs\:focus\:to-primary:focus {
    --gradient-to-color: var(--primary) !important;
  }

  .xs\:focus\:to-accent-1:focus {
    --gradient-to-color: #fafafa !important;
  }

  .xs\:focus\:to-accent-2:focus {
    --gradient-to-color: #eaeaea !important;
  }

  .xs\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .xs\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .xs\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .xs\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .xs\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .xs\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .xs\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .xs\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .xs\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .xs\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .xs\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .xs\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .xs\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .xs\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .xs\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .xs\:bg-bottom {
    background-position: bottom !important;
  }

  .xs\:bg-center {
    background-position: center !important;
  }

  .xs\:bg-left {
    background-position: left !important;
  }

  .xs\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .xs\:bg-left-top {
    background-position: left top !important;
  }

  .xs\:bg-right {
    background-position: right !important;
  }

  .xs\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .xs\:bg-right-top {
    background-position: right top !important;
  }

  .xs\:bg-top {
    background-position: top !important;
  }

  .xs\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xs\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xs\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xs\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xs\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xs\:bg-repeat-space {
    background-repeat: space !important;
  }

  .xs\:bg-auto {
    background-size: auto !important;
  }

  .xs\:bg-cover {
    background-size: cover !important;
  }

  .xs\:bg-contain {
    background-size: contain !important;
  }

  .xs\:border-collapse {
    border-collapse: collapse !important;
  }

  .xs\:border-separate {
    border-collapse: separate !important;
  }

  .xs\:border-transparent {
    border-color: transparent !important;
  }

  .xs\:border-current {
    border-color: currentColor !important;
  }

  .xs\:border-black {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xs\:border-white {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xs\:border-gray-100 {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xs\:border-gray-200 {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xs\:border-gray-300 {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xs\:border-gray-400 {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xs\:border-gray-500 {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xs\:border-gray-600 {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xs\:border-gray-700 {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xs\:border-gray-800 {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xs\:border-gray-900 {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xs\:border-red-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xs\:border-red-200 {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xs\:border-red-300 {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xs\:border-red-400 {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xs\:border-red-500 {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xs\:border-red-600 {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xs\:border-red-700 {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xs\:border-red-800 {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xs\:border-red-900 {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xs\:border-orange-100 {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xs\:border-orange-200 {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xs\:border-orange-300 {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xs\:border-orange-400 {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xs\:border-orange-500 {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xs\:border-orange-600 {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xs\:border-orange-700 {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xs\:border-orange-800 {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xs\:border-orange-900 {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-100 {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-200 {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-300 {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-400 {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-500 {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-600 {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-700 {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-800 {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xs\:border-yellow-900 {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xs\:border-green-100 {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xs\:border-green-200 {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xs\:border-green-300 {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xs\:border-green-400 {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xs\:border-green-500 {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xs\:border-green-600 {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xs\:border-green-700 {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xs\:border-green-800 {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xs\:border-green-900 {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xs\:border-teal-100 {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xs\:border-teal-200 {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xs\:border-teal-300 {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xs\:border-teal-400 {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xs\:border-teal-500 {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xs\:border-teal-600 {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xs\:border-teal-700 {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xs\:border-teal-800 {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xs\:border-teal-900 {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xs\:border-blue-100 {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xs\:border-blue-200 {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xs\:border-blue-300 {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xs\:border-blue-400 {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xs\:border-blue-500 {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xs\:border-blue-600 {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xs\:border-blue-700 {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xs\:border-blue-800 {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xs\:border-blue-900 {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-100 {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-200 {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-300 {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-400 {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-500 {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-600 {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-700 {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-800 {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xs\:border-indigo-900 {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xs\:border-purple-100 {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xs\:border-purple-200 {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xs\:border-purple-300 {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xs\:border-purple-400 {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xs\:border-purple-500 {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xs\:border-purple-600 {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xs\:border-purple-700 {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xs\:border-purple-800 {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xs\:border-purple-900 {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xs\:border-pink-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xs\:border-pink-200 {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xs\:border-pink-300 {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xs\:border-pink-400 {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xs\:border-pink-500 {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xs\:border-pink-600 {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xs\:border-pink-700 {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xs\:border-pink-800 {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xs\:border-pink-900 {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xs\:border-primary {
    border-color: var(--primary) !important;
  }

  .xs\:border-accent-1 {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xs\:border-accent-2 {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  .xs\:hover\:border-current:hover {
    border-color: currentColor !important;
  }

  .xs\:hover\:border-black:hover {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-white:hover {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-100:hover {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-200:hover {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-300:hover {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-400:hover {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-500:hover {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-600:hover {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-700:hover {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-800:hover {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-gray-900:hover {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-300:hover {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-400:hover {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-500:hover {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-600:hover {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-700:hover {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-800:hover {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-red-900:hover {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-200:hover {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-600:hover {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-700:hover {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-800:hover {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-orange-900:hover {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-200:hover {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-300:hover {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-500:hover {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-600:hover {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-700:hover {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-800:hover {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-yellow-900:hover {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-100:hover {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-200:hover {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-300:hover {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-400:hover {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-500:hover {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-600:hover {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-700:hover {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-800:hover {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-green-900:hover {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-100:hover {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-200:hover {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-300:hover {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-400:hover {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-500:hover {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-600:hover {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-700:hover {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-800:hover {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-teal-900:hover {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-200:hover {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-300:hover {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-400:hover {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-500:hover {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-600:hover {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-700:hover {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-800:hover {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-blue-900:hover {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-200:hover {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-300:hover {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-400:hover {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-500:hover {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-600:hover {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-700:hover {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-800:hover {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-indigo-900:hover {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-100:hover {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-200:hover {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-300:hover {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-400:hover {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-500:hover {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-600:hover {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-700:hover {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-800:hover {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-purple-900:hover {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-400:hover {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-600:hover {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-700:hover {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-800:hover {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-pink-900:hover {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-primary:hover {
    border-color: var(--primary) !important;
  }

  .xs\:hover\:border-accent-1:hover {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xs\:hover\:border-accent-2:hover {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-transparent:focus {
    border-color: transparent !important;
  }

  .xs\:focus\:border-current:focus {
    border-color: currentColor !important;
  }

  .xs\:focus\:border-black:focus {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-white:focus {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-100:focus {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-200:focus {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-300:focus {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-400:focus {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-500:focus {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-600:focus {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-700:focus {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-800:focus {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-gray-900:focus {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-300:focus {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-400:focus {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-500:focus {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-600:focus {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-700:focus {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-800:focus {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-red-900:focus {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-200:focus {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-600:focus {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-700:focus {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-800:focus {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-orange-900:focus {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-200:focus {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-300:focus {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-500:focus {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-600:focus {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-700:focus {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-800:focus {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-yellow-900:focus {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-100:focus {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-200:focus {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-300:focus {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-400:focus {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-500:focus {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-600:focus {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-700:focus {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-800:focus {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-green-900:focus {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-100:focus {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-200:focus {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-300:focus {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-400:focus {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-500:focus {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-600:focus {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-700:focus {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-800:focus {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-teal-900:focus {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-200:focus {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-300:focus {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-400:focus {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-500:focus {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-600:focus {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-700:focus {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-800:focus {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-blue-900:focus {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-200:focus {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-300:focus {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-400:focus {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-500:focus {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-600:focus {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-700:focus {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-800:focus {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-indigo-900:focus {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-100:focus {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-200:focus {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-300:focus {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-400:focus {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-500:focus {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-600:focus {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-700:focus {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-800:focus {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-purple-900:focus {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-400:focus {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-600:focus {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-700:focus {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-800:focus {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-pink-900:focus {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-primary:focus {
    border-color: var(--primary) !important;
  }

  .xs\:focus\:border-accent-1:focus {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xs\:focus\:border-accent-2:focus {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xs\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .xs\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .xs\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .xs\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .xs\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .xs\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .xs\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .xs\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .xs\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .xs\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .xs\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .xs\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .xs\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .xs\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .xs\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .xs\:rounded-none {
    border-radius: 0 !important;
  }

  .xs\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .xs\:rounded {
    border-radius: 0.25rem !important;
  }

  .xs\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .xs\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .xs\:rounded-xl {
    border-radius: 0.75rem !important;
  }

  .xs\:rounded-2xl {
    border-radius: 1rem !important;
  }

  .xs\:rounded-3xl {
    border-radius: 1.5rem !important;
  }

  .xs\:rounded-full {
    border-radius: 9999px !important;
  }

  .xs\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xs\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xs\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xs\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xs\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xs\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xs\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xs\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xs\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xs\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xs\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xs\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xs\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xs\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xs\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xs\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xs\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xs\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xs\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xs\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xs\:rounded-t-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .xs\:rounded-r-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .xs\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xs\:rounded-l-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xs\:rounded-t-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xs\:rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xs\:rounded-b-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xs\:rounded-l-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xs\:rounded-t-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xs\:rounded-r-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xs\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xs\:rounded-l-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xs\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xs\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xs\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xs\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xs\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .xs\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .xs\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .xs\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .xs\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .xs\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .xs\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .xs\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .xs\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .xs\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .xs\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .xs\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .xs\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .xs\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .xs\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .xs\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .xs\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .xs\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .xs\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .xs\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .xs\:rounded-tl-xl {
    border-top-left-radius: 0.75rem !important;
  }

  .xs\:rounded-tr-xl {
    border-top-right-radius: 0.75rem !important;
  }

  .xs\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem !important;
  }

  .xs\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem !important;
  }

  .xs\:rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
  }

  .xs\:rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
  }

  .xs\:rounded-br-2xl {
    border-bottom-right-radius: 1rem !important;
  }

  .xs\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem !important;
  }

  .xs\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
  }

  .xs\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem !important;
  }

  .xs\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .xs\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .xs\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .xs\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .xs\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .xs\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .xs\:border-solid {
    border-style: solid !important;
  }

  .xs\:border-dashed {
    border-style: dashed !important;
  }

  .xs\:border-dotted {
    border-style: dotted !important;
  }

  .xs\:border-double {
    border-style: double !important;
  }

  .xs\:border-none {
    border-style: none !important;
  }

  .xs\:border-0 {
    border-width: 0 !important;
  }

  .xs\:border-2 {
    border-width: 2px !important;
  }

  .xs\:border-4 {
    border-width: 4px !important;
  }

  .xs\:border-8 {
    border-width: 8px !important;
  }

  .xs\:border {
    border-width: 1px !important;
  }

  .xs\:border-t-0 {
    border-top-width: 0 !important;
  }

  .xs\:border-r-0 {
    border-right-width: 0 !important;
  }

  .xs\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .xs\:border-l-0 {
    border-left-width: 0 !important;
  }

  .xs\:border-t-2 {
    border-top-width: 2px !important;
  }

  .xs\:border-r-2 {
    border-right-width: 2px !important;
  }

  .xs\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .xs\:border-l-2 {
    border-left-width: 2px !important;
  }

  .xs\:border-t-4 {
    border-top-width: 4px !important;
  }

  .xs\:border-r-4 {
    border-right-width: 4px !important;
  }

  .xs\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .xs\:border-l-4 {
    border-left-width: 4px !important;
  }

  .xs\:border-t-8 {
    border-top-width: 8px !important;
  }

  .xs\:border-r-8 {
    border-right-width: 8px !important;
  }

  .xs\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .xs\:border-l-8 {
    border-left-width: 8px !important;
  }

  .xs\:border-t {
    border-top-width: 1px !important;
  }

  .xs\:border-r {
    border-right-width: 1px !important;
  }

  .xs\:border-b {
    border-bottom-width: 1px !important;
  }

  .xs\:border-l {
    border-left-width: 1px !important;
  }

  .xs\:box-border {
    box-sizing: border-box !important;
  }

  .xs\:box-content {
    box-sizing: content-box !important;
  }

  .xs\:cursor-auto {
    cursor: auto !important;
  }

  .xs\:cursor-default {
    cursor: default !important;
  }

  .xs\:cursor-pointer {
    cursor: pointer !important;
  }

  .xs\:cursor-wait {
    cursor: wait !important;
  }

  .xs\:cursor-text {
    cursor: text !important;
  }

  .xs\:cursor-move {
    cursor: move !important;
  }

  .xs\:cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .xs\:block {
    display: block !important;
  }

  .xs\:inline-block {
    display: inline-block !important;
  }

  .xs\:inline {
    display: inline !important;
  }

  .xs\:flex {
    display: flex !important;
  }

  .xs\:inline-flex {
    display: inline-flex !important;
  }

  .xs\:table {
    display: table !important;
  }

  .xs\:table-caption {
    display: table-caption !important;
  }

  .xs\:table-cell {
    display: table-cell !important;
  }

  .xs\:table-column {
    display: table-column !important;
  }

  .xs\:table-column-group {
    display: table-column-group !important;
  }

  .xs\:table-footer-group {
    display: table-footer-group !important;
  }

  .xs\:table-header-group {
    display: table-header-group !important;
  }

  .xs\:table-row-group {
    display: table-row-group !important;
  }

  .xs\:table-row {
    display: table-row !important;
  }

  .xs\:flow-root {
    display: flow-root !important;
  }

  .xs\:grid {
    display: grid !important;
  }

  .xs\:inline-grid {
    display: inline-grid !important;
  }

  .xs\:contents {
    display: contents !important;
  }

  .xs\:hidden {
    display: none !important;
  }

  .xs\:flex-row {
    flex-direction: row !important;
  }

  .xs\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xs\:flex-col {
    flex-direction: column !important;
  }

  .xs\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .xs\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xs\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xs\:flex-no-wrap {
    flex-wrap: nowrap !important;
  }

  .xs\:place-items-auto {
    place-items: auto !important;
  }

  .xs\:place-items-start {
    place-items: start !important;
  }

  .xs\:place-items-end {
    place-items: end !important;
  }

  .xs\:place-items-center {
    place-items: center !important;
  }

  .xs\:place-items-stretch {
    place-items: stretch !important;
  }

  .xs\:place-content-center {
    place-content: center !important;
  }

  .xs\:place-content-start {
    place-content: start !important;
  }

  .xs\:place-content-end {
    place-content: end !important;
  }

  .xs\:place-content-between {
    place-content: space-between !important;
  }

  .xs\:place-content-around {
    place-content: space-around !important;
  }

  .xs\:place-content-evenly {
    place-content: space-evenly !important;
  }

  .xs\:place-content-stretch {
    place-content: stretch !important;
  }

  .xs\:place-self-auto {
    place-self: auto !important;
  }

  .xs\:place-self-start {
    place-self: start !important;
  }

  .xs\:place-self-end {
    place-self: end !important;
  }

  .xs\:place-self-center {
    place-self: center !important;
  }

  .xs\:place-self-stretch {
    place-self: stretch !important;
  }

  .xs\:items-start {
    align-items: flex-start !important;
  }

  .xs\:items-end {
    align-items: flex-end !important;
  }

  .xs\:items-center {
    align-items: center !important;
  }

  .xs\:items-baseline {
    align-items: baseline !important;
  }

  .xs\:items-stretch {
    align-items: stretch !important;
  }

  .xs\:content-center {
    align-content: center !important;
  }

  .xs\:content-start {
    align-content: flex-start !important;
  }

  .xs\:content-end {
    align-content: flex-end !important;
  }

  .xs\:content-between {
    align-content: space-between !important;
  }

  .xs\:content-around {
    align-content: space-around !important;
  }

  .xs\:content-evenly {
    align-content: space-evenly !important;
  }

  .xs\:self-auto {
    align-self: auto !important;
  }

  .xs\:self-start {
    align-self: flex-start !important;
  }

  .xs\:self-end {
    align-self: flex-end !important;
  }

  .xs\:self-center {
    align-self: center !important;
  }

  .xs\:self-stretch {
    align-self: stretch !important;
  }

  .xs\:justify-items-auto {
    justify-items: auto !important;
  }

  .xs\:justify-items-start {
    justify-items: start !important;
  }

  .xs\:justify-items-end {
    justify-items: end !important;
  }

  .xs\:justify-items-center {
    justify-items: center !important;
  }

  .xs\:justify-items-stretch {
    justify-items: stretch !important;
  }

  .xs\:justify-start {
    justify-content: flex-start !important;
  }

  .xs\:justify-end {
    justify-content: flex-end !important;
  }

  .xs\:justify-center {
    justify-content: center !important;
  }

  .xs\:justify-between {
    justify-content: space-between !important;
  }

  .xs\:justify-around {
    justify-content: space-around !important;
  }

  .xs\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .xs\:justify-self-auto {
    justify-self: auto !important;
  }

  .xs\:justify-self-start {
    justify-self: start !important;
  }

  .xs\:justify-self-end {
    justify-self: end !important;
  }

  .xs\:justify-self-center {
    justify-self: center !important;
  }

  .xs\:justify-self-stretch {
    justify-self: stretch !important;
  }

  .xs\:flex-1 {
    flex: 1 1 0% !important;
  }

  .xs\:flex-auto {
    flex: 1 1 auto !important;
  }

  .xs\:flex-initial {
    flex: 0 1 auto !important;
  }

  .xs\:flex-none {
    flex: none !important;
  }

  .xs\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xs\:flex-grow {
    flex-grow: 1 !important;
  }

  .xs\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xs\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .xs\:order-1 {
    order: 1 !important;
  }

  .xs\:order-2 {
    order: 2 !important;
  }

  .xs\:order-3 {
    order: 3 !important;
  }

  .xs\:order-4 {
    order: 4 !important;
  }

  .xs\:order-5 {
    order: 5 !important;
  }

  .xs\:order-6 {
    order: 6 !important;
  }

  .xs\:order-7 {
    order: 7 !important;
  }

  .xs\:order-8 {
    order: 8 !important;
  }

  .xs\:order-9 {
    order: 9 !important;
  }

  .xs\:order-10 {
    order: 10 !important;
  }

  .xs\:order-11 {
    order: 11 !important;
  }

  .xs\:order-12 {
    order: 12 !important;
  }

  .xs\:order-first {
    order: -9999 !important;
  }

  .xs\:order-last {
    order: 9999 !important;
  }

  .xs\:order-none {
    order: 0 !important;
  }

  .xs\:float-right {
    float: right !important;
  }

  .xs\:float-left {
    float: left !important;
  }

  .xs\:float-none {
    float: none !important;
  }

  .xs\:clearfix:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }

  .xs\:clear-left {
    clear: left !important;
  }

  .xs\:clear-right {
    clear: right !important;
  }

  .xs\:clear-both {
    clear: both !important;
  }

  .xs\:clear-none {
    clear: none !important;
  }

  .xs\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .xs\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .xs\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .xs\:font-hairline {
    font-weight: 100 !important;
  }

  .xs\:font-thin {
    font-weight: 200 !important;
  }

  .xs\:font-light {
    font-weight: 300 !important;
  }

  .xs\:font-normal {
    font-weight: 400 !important;
  }

  .xs\:font-medium {
    font-weight: 500 !important;
  }

  .xs\:font-semibold {
    font-weight: 600 !important;
  }

  .xs\:font-bold {
    font-weight: 700 !important;
  }

  .xs\:font-extrabold {
    font-weight: 800 !important;
  }

  .xs\:font-black {
    font-weight: 900 !important;
  }

  .xs\:hover\:font-hairline:hover {
    font-weight: 100 !important;
  }

  .xs\:hover\:font-thin:hover {
    font-weight: 200 !important;
  }

  .xs\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .xs\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .xs\:hover\:font-medium:hover {
    font-weight: 500 !important;
  }

  .xs\:hover\:font-semibold:hover {
    font-weight: 600 !important;
  }

  .xs\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .xs\:hover\:font-extrabold:hover {
    font-weight: 800 !important;
  }

  .xs\:hover\:font-black:hover {
    font-weight: 900 !important;
  }

  .xs\:focus\:font-hairline:focus {
    font-weight: 100 !important;
  }

  .xs\:focus\:font-thin:focus {
    font-weight: 200 !important;
  }

  .xs\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .xs\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .xs\:focus\:font-medium:focus {
    font-weight: 500 !important;
  }

  .xs\:focus\:font-semibold:focus {
    font-weight: 600 !important;
  }

  .xs\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .xs\:focus\:font-extrabold:focus {
    font-weight: 800 !important;
  }

  .xs\:focus\:font-black:focus {
    font-weight: 900 !important;
  }

  .xs\:h-0 {
    height: 0 !important;
  }

  .xs\:h-1 {
    height: 0.25rem !important;
  }

  .xs\:h-2 {
    height: 0.5rem !important;
  }

  .xs\:h-3 {
    height: 0.75rem !important;
  }

  .xs\:h-4 {
    height: 1rem !important;
  }

  .xs\:h-5 {
    height: 1.25rem !important;
  }

  .xs\:h-6 {
    height: 1.5rem !important;
  }

  .xs\:h-8 {
    height: 2rem !important;
  }

  .xs\:h-10 {
    height: 2.5rem !important;
  }

  .xs\:h-12 {
    height: 3rem !important;
  }

  .xs\:h-16 {
    height: 4rem !important;
  }

  .xs\:h-20 {
    height: 5rem !important;
  }

  .xs\:h-24 {
    height: 6rem !important;
  }

  .xs\:h-32 {
    height: 8rem !important;
  }

  .xs\:h-40 {
    height: 10rem !important;
  }

  .xs\:h-48 {
    height: 12rem !important;
  }

  .xs\:h-56 {
    height: 14rem !important;
  }

  .xs\:h-64 {
    height: 16rem !important;
  }

  .xs\:h-auto {
    height: auto !important;
  }

  .xs\:h-px {
    height: 1px !important;
  }

  .xs\:h-full {
    height: 100% !important;
  }

  .xs\:h-screen {
    height: 100vh !important;
  }

  .xs\:text-xs {
    font-size: 0.80em !important;
  }

  .xs\:text-sm {
    font-size: 0.975em !important;
  }

  .xs\:text-base {
    font-size: 1em !important;
  }

  .xs\:text-lg {
    font-size: 1.125em !important;
  }

  .xs\:text-xl {
    font-size: 1.25em !important;
  }

  .xs\:text-2xl {
    font-size: 1.5em !important;
  }

  .xs\:text-3xl {
    font-size: 1.875em !important;
  }

  .xs\:text-4xl {
    font-size: 2.25em !important;
  }

  .xs\:text-5xl {
    font-size: 3em !important;
  }

  .xs\:text-6xl {
    font-size: 4em !important;
  }

  .xs\:text-md {
    font-size: 1em !important;
  }

  .xs\:text-sm-title {
    font-size:  18px !important;
  }

  .xs\:text-md-title {
    font-size: 22px !important;
  }

  .xs\:text-lg-title {
    font-size: 36px !important;
  }

  .xs\:text-size-body {
    font-size: 12px !important;
  }

  .xs\:text-size-label {
    font-size: 10px !important;
  }

  .xs\:leading-3 {
    line-height: .75rem !important;
  }

  .xs\:leading-4 {
    line-height: 1rem !important;
  }

  .xs\:leading-5 {
    line-height: 1.25rem !important;
  }

  .xs\:leading-6 {
    line-height: 1.5rem !important;
  }

  .xs\:leading-7 {
    line-height: 1.75rem !important;
  }

  .xs\:leading-8 {
    line-height: 2rem !important;
  }

  .xs\:leading-9 {
    line-height: 2.25rem !important;
  }

  .xs\:leading-10 {
    line-height: 2.5rem !important;
  }

  .xs\:leading-none {
    line-height: 1 !important;
  }

  .xs\:leading-tight {
    line-height: 1.25 !important;
  }

  .xs\:leading-snug {
    line-height: 1.375 !important;
  }

  .xs\:leading-normal {
    line-height: 1.5 !important;
  }

  .xs\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .xs\:leading-loose {
    line-height: 2 !important;
  }

  .xs\:list-inside {
    list-style-position: inside !important;
  }

  .xs\:list-outside {
    list-style-position: outside !important;
  }

  .xs\:list-none {
    list-style-type: none !important;
  }

  .xs\:list-disc {
    list-style-type: disc !important;
  }

  .xs\:list-decimal {
    list-style-type: decimal !important;
  }

  .xs\:m-0 {
    margin: 0 !important;
  }

  .xs\:m-1 {
    margin: 0.25rem !important;
  }

  .xs\:m-2 {
    margin: 0.5rem !important;
  }

  .xs\:m-3 {
    margin: 0.75rem !important;
  }

  .xs\:m-4 {
    margin: 1rem !important;
  }

  .xs\:m-5 {
    margin: 1.25rem !important;
  }

  .xs\:m-6 {
    margin: 1.5rem !important;
  }

  .xs\:m-8 {
    margin: 2rem !important;
  }

  .xs\:m-10 {
    margin: 2.5rem !important;
  }

  .xs\:m-12 {
    margin: 3rem !important;
  }

  .xs\:m-16 {
    margin: 4rem !important;
  }

  .xs\:m-20 {
    margin: 5rem !important;
  }

  .xs\:m-24 {
    margin: 6rem !important;
  }

  .xs\:m-32 {
    margin: 8rem !important;
  }

  .xs\:m-40 {
    margin: 10rem !important;
  }

  .xs\:m-48 {
    margin: 12rem !important;
  }

  .xs\:m-56 {
    margin: 14rem !important;
  }

  .xs\:m-64 {
    margin: 16rem !important;
  }

  .xs\:m-auto {
    margin: auto !important;
  }

  .xs\:m-px {
    margin: 1px !important;
  }

  .xs\:-m-1 {
    margin: -0.25rem !important;
  }

  .xs\:-m-2 {
    margin: -0.5rem !important;
  }

  .xs\:-m-3 {
    margin: -0.75rem !important;
  }

  .xs\:-m-4 {
    margin: -1rem !important;
  }

  .xs\:-m-5 {
    margin: -1.25rem !important;
  }

  .xs\:-m-6 {
    margin: -1.5rem !important;
  }

  .xs\:-m-8 {
    margin: -2rem !important;
  }

  .xs\:-m-10 {
    margin: -2.5rem !important;
  }

  .xs\:-m-12 {
    margin: -3rem !important;
  }

  .xs\:-m-16 {
    margin: -4rem !important;
  }

  .xs\:-m-20 {
    margin: -5rem !important;
  }

  .xs\:-m-24 {
    margin: -6rem !important;
  }

  .xs\:-m-32 {
    margin: -8rem !important;
  }

  .xs\:-m-40 {
    margin: -10rem !important;
  }

  .xs\:-m-48 {
    margin: -12rem !important;
  }

  .xs\:-m-56 {
    margin: -14rem !important;
  }

  .xs\:-m-64 {
    margin: -16rem !important;
  }

  .xs\:-m-px {
    margin: -1px !important;
  }

  .xs\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xs\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xs\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xs\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xs\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xs\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xs\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xs\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xs\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xs\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xs\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xs\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xs\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xs\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xs\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xs\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xs\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xs\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xs\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xs\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xs\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xs\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xs\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xs\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xs\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xs\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xs\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xs\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xs\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xs\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xs\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xs\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xs\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xs\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xs\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xs\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xs\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xs\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xs\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xs\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xs\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xs\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xs\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xs\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xs\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xs\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xs\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xs\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xs\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xs\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xs\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xs\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xs\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xs\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xs\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xs\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xs\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xs\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xs\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xs\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xs\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xs\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xs\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xs\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xs\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xs\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xs\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xs\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xs\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xs\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xs\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xs\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xs\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xs\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xs\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xs\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xs\:mt-0 {
    margin-top: 0 !important;
  }

  .xs\:mr-0 {
    margin-right: 0 !important;
  }

  .xs\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xs\:ml-0 {
    margin-left: 0 !important;
  }

  .xs\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xs\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xs\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xs\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xs\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xs\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xs\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xs\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xs\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .xs\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .xs\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .xs\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .xs\:mt-4 {
    margin-top: 1rem !important;
  }

  .xs\:mr-4 {
    margin-right: 1rem !important;
  }

  .xs\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .xs\:ml-4 {
    margin-left: 1rem !important;
  }

  .xs\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .xs\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .xs\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .xs\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .xs\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .xs\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .xs\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .xs\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .xs\:mt-8 {
    margin-top: 2rem !important;
  }

  .xs\:mr-8 {
    margin-right: 2rem !important;
  }

  .xs\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .xs\:ml-8 {
    margin-left: 2rem !important;
  }

  .xs\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .xs\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .xs\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .xs\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .xs\:mt-12 {
    margin-top: 3rem !important;
  }

  .xs\:mr-12 {
    margin-right: 3rem !important;
  }

  .xs\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .xs\:ml-12 {
    margin-left: 3rem !important;
  }

  .xs\:mt-16 {
    margin-top: 4rem !important;
  }

  .xs\:mr-16 {
    margin-right: 4rem !important;
  }

  .xs\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .xs\:ml-16 {
    margin-left: 4rem !important;
  }

  .xs\:mt-20 {
    margin-top: 5rem !important;
  }

  .xs\:mr-20 {
    margin-right: 5rem !important;
  }

  .xs\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .xs\:ml-20 {
    margin-left: 5rem !important;
  }

  .xs\:mt-24 {
    margin-top: 6rem !important;
  }

  .xs\:mr-24 {
    margin-right: 6rem !important;
  }

  .xs\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .xs\:ml-24 {
    margin-left: 6rem !important;
  }

  .xs\:mt-32 {
    margin-top: 8rem !important;
  }

  .xs\:mr-32 {
    margin-right: 8rem !important;
  }

  .xs\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .xs\:ml-32 {
    margin-left: 8rem !important;
  }

  .xs\:mt-40 {
    margin-top: 10rem !important;
  }

  .xs\:mr-40 {
    margin-right: 10rem !important;
  }

  .xs\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .xs\:ml-40 {
    margin-left: 10rem !important;
  }

  .xs\:mt-48 {
    margin-top: 12rem !important;
  }

  .xs\:mr-48 {
    margin-right: 12rem !important;
  }

  .xs\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .xs\:ml-48 {
    margin-left: 12rem !important;
  }

  .xs\:mt-56 {
    margin-top: 14rem !important;
  }

  .xs\:mr-56 {
    margin-right: 14rem !important;
  }

  .xs\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .xs\:ml-56 {
    margin-left: 14rem !important;
  }

  .xs\:mt-64 {
    margin-top: 16rem !important;
  }

  .xs\:mr-64 {
    margin-right: 16rem !important;
  }

  .xs\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .xs\:ml-64 {
    margin-left: 16rem !important;
  }

  .xs\:mt-auto {
    margin-top: auto !important;
  }

  .xs\:mr-auto {
    margin-right: auto !important;
  }

  .xs\:mb-auto {
    margin-bottom: auto !important;
  }

  .xs\:ml-auto {
    margin-left: auto !important;
  }

  .xs\:mt-px {
    margin-top: 1px !important;
  }

  .xs\:mr-px {
    margin-right: 1px !important;
  }

  .xs\:mb-px {
    margin-bottom: 1px !important;
  }

  .xs\:ml-px {
    margin-left: 1px !important;
  }

  .xs\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .xs\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .xs\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .xs\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .xs\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .xs\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .xs\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .xs\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .xs\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .xs\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .xs\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .xs\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .xs\:-mt-4 {
    margin-top: -1rem !important;
  }

  .xs\:-mr-4 {
    margin-right: -1rem !important;
  }

  .xs\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .xs\:-ml-4 {
    margin-left: -1rem !important;
  }

  .xs\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .xs\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .xs\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .xs\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .xs\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .xs\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .xs\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .xs\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .xs\:-mt-8 {
    margin-top: -2rem !important;
  }

  .xs\:-mr-8 {
    margin-right: -2rem !important;
  }

  .xs\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .xs\:-ml-8 {
    margin-left: -2rem !important;
  }

  .xs\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .xs\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .xs\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .xs\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .xs\:-mt-12 {
    margin-top: -3rem !important;
  }

  .xs\:-mr-12 {
    margin-right: -3rem !important;
  }

  .xs\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .xs\:-ml-12 {
    margin-left: -3rem !important;
  }

  .xs\:-mt-16 {
    margin-top: -4rem !important;
  }

  .xs\:-mr-16 {
    margin-right: -4rem !important;
  }

  .xs\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .xs\:-ml-16 {
    margin-left: -4rem !important;
  }

  .xs\:-mt-20 {
    margin-top: -5rem !important;
  }

  .xs\:-mr-20 {
    margin-right: -5rem !important;
  }

  .xs\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .xs\:-ml-20 {
    margin-left: -5rem !important;
  }

  .xs\:-mt-24 {
    margin-top: -6rem !important;
  }

  .xs\:-mr-24 {
    margin-right: -6rem !important;
  }

  .xs\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .xs\:-ml-24 {
    margin-left: -6rem !important;
  }

  .xs\:-mt-32 {
    margin-top: -8rem !important;
  }

  .xs\:-mr-32 {
    margin-right: -8rem !important;
  }

  .xs\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .xs\:-ml-32 {
    margin-left: -8rem !important;
  }

  .xs\:-mt-40 {
    margin-top: -10rem !important;
  }

  .xs\:-mr-40 {
    margin-right: -10rem !important;
  }

  .xs\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .xs\:-ml-40 {
    margin-left: -10rem !important;
  }

  .xs\:-mt-48 {
    margin-top: -12rem !important;
  }

  .xs\:-mr-48 {
    margin-right: -12rem !important;
  }

  .xs\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .xs\:-ml-48 {
    margin-left: -12rem !important;
  }

  .xs\:-mt-56 {
    margin-top: -14rem !important;
  }

  .xs\:-mr-56 {
    margin-right: -14rem !important;
  }

  .xs\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .xs\:-ml-56 {
    margin-left: -14rem !important;
  }

  .xs\:-mt-64 {
    margin-top: -16rem !important;
  }

  .xs\:-mr-64 {
    margin-right: -16rem !important;
  }

  .xs\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .xs\:-ml-64 {
    margin-left: -16rem !important;
  }

  .xs\:-mt-px {
    margin-top: -1px !important;
  }

  .xs\:-mr-px {
    margin-right: -1px !important;
  }

  .xs\:-mb-px {
    margin-bottom: -1px !important;
  }

  .xs\:-ml-px {
    margin-left: -1px !important;
  }

  .xs\:max-h-full {
    max-height: 100% !important;
  }

  .xs\:max-h-screen {
    max-height: 100vh !important;
  }

  .xs\:max-w-0 {
    max-width: 0 !important;
  }

  .xs\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .xs\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .xs\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .xs\:max-w-4 {
    max-width: 1rem !important;
  }

  .xs\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .xs\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .xs\:max-w-8 {
    max-width: 2rem !important;
  }

  .xs\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .xs\:max-w-12 {
    max-width: 3rem !important;
  }

  .xs\:max-w-16 {
    max-width: 4rem !important;
  }

  .xs\:max-w-20 {
    max-width: 5rem !important;
  }

  .xs\:max-w-24 {
    max-width: 6rem !important;
  }

  .xs\:max-w-32 {
    max-width: 8rem !important;
  }

  .xs\:max-w-40 {
    max-width: 10rem !important;
  }

  .xs\:max-w-48 {
    max-width: 12rem !important;
  }

  .xs\:max-w-56 {
    max-width: 14rem !important;
  }

  .xs\:max-w-64 {
    max-width: 16rem !important;
  }

  .xs\:max-w-none {
    max-width: none !important;
  }

  .xs\:max-w-xs {
    max-width: 20rem !important;
  }

  .xs\:max-w-sm {
    max-width: 24rem !important;
  }

  .xs\:max-w-md {
    max-width: 28rem !important;
  }

  .xs\:max-w-lg {
    max-width: 32rem !important;
  }

  .xs\:max-w-xl {
    max-width: 36rem !important;
  }

  .xs\:max-w-2xl {
    max-width: 42rem !important;
  }

  .xs\:max-w-3xl {
    max-width: 48rem !important;
  }

  .xs\:max-w-4xl {
    max-width: 56rem !important;
  }

  .xs\:max-w-5xl {
    max-width: 64rem !important;
  }

  .xs\:max-w-6xl {
    max-width: 72rem !important;
  }

  .xs\:max-w-full {
    max-width: 100% !important;
  }

  .xs\:max-w-screen-xs {
    max-width: 480px !important;
  }

  .xs\:max-w-screen-sm {
    max-width: 576px !important;
  }

  .xs\:max-w-screen-md {
    max-width: 768px !important;
  }

  .xs\:max-w-screen-lg {
    max-width: 992px !important;
  }

  .xs\:max-w-screen-xl {
    max-width: 1200px !important;
  }

  .xs\:max-w-screen-xxl {
    max-width: 1600px !important;
  }

  .xs\:max-w-screen {
    max-width: 100vw !important;
  }

  .xs\:max-w-px {
    max-width: 1px !important;
  }

  .xs\:min-h-0 {
    min-height: 0 !important;
  }

  .xs\:min-h-full {
    min-height: 100% !important;
  }

  .xs\:min-h-screen {
    min-height: 100vh !important;
  }

  .xs\:min-w-0 {
    min-width: 0 !important;
  }

  .xs\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .xs\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .xs\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .xs\:min-w-4 {
    min-width: 1rem !important;
  }

  .xs\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .xs\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .xs\:min-w-8 {
    min-width: 2rem !important;
  }

  .xs\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .xs\:min-w-12 {
    min-width: 3rem !important;
  }

  .xs\:min-w-16 {
    min-width: 4rem !important;
  }

  .xs\:min-w-20 {
    min-width: 5rem !important;
  }

  .xs\:min-w-24 {
    min-width: 6rem !important;
  }

  .xs\:min-w-32 {
    min-width: 8rem !important;
  }

  .xs\:min-w-40 {
    min-width: 10rem !important;
  }

  .xs\:min-w-48 {
    min-width: 12rem !important;
  }

  .xs\:min-w-56 {
    min-width: 14rem !important;
  }

  .xs\:min-w-64 {
    min-width: 16rem !important;
  }

  .xs\:min-w-full {
    min-width: 100% !important;
  }

  .xs\:min-w-screen {
    min-width: 100vw !important;
  }

  .xs\:min-w-px {
    min-width: 1px !important;
  }

  .xs\:min-w-screen-xs {
    min-width: 480px !important;
  }

  .xs\:min-w-screen-sm {
    min-width: 576px !important;
  }

  .xs\:min-w-screen-md {
    min-width: 768px !important;
  }

  .xs\:min-w-screen-lg {
    min-width: 992px !important;
  }

  .xs\:min-w-screen-xl {
    min-width: 1200px !important;
  }

  .xs\:min-w-screen-xxl {
    min-width: 1600px !important;
  }

  .xs\:object-contain {
    object-fit: contain !important;
  }

  .xs\:object-cover {
    object-fit: cover !important;
  }

  .xs\:object-fill {
    object-fit: fill !important;
  }

  .xs\:object-none {
    object-fit: none !important;
  }

  .xs\:object-scale-down {
    object-fit: scale-down !important;
  }

  .xs\:object-bottom {
    object-position: bottom !important;
  }

  .xs\:object-center {
    object-position: center !important;
  }

  .xs\:object-left {
    object-position: left !important;
  }

  .xs\:object-left-bottom {
    object-position: left bottom !important;
  }

  .xs\:object-left-top {
    object-position: left top !important;
  }

  .xs\:object-right {
    object-position: right !important;
  }

  .xs\:object-right-bottom {
    object-position: right bottom !important;
  }

  .xs\:object-right-top {
    object-position: right top !important;
  }

  .xs\:object-top {
    object-position: top !important;
  }

  .xs\:opacity-0 {
    opacity: 0 !important;
  }

  .xs\:opacity-25 {
    opacity: 0.25 !important;
  }

  .xs\:opacity-50 {
    opacity: 0.5 !important;
  }

  .xs\:opacity-75 {
    opacity: 0.75 !important;
  }

  .xs\:opacity-100 {
    opacity: 1 !important;
  }

  .xs\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .xs\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .xs\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .xs\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .xs\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .xs\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .xs\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .xs\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .xs\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .xs\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .xs\:overflow-auto {
    overflow: auto !important;
  }

  .xs\:overflow-hidden {
    overflow: hidden !important;
  }

  .xs\:overflow-visible {
    overflow: visible !important;
  }

  .xs\:overflow-scroll {
    overflow: scroll !important;
  }

  .xs\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xs\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xs\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xs\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xs\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xs\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xs\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .xs\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .xs\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .xs\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .xs\:overscroll-auto {
    overscroll-behavior: auto !important;
  }

  .xs\:overscroll-contain {
    overscroll-behavior: contain !important;
  }

  .xs\:overscroll-none {
    overscroll-behavior: none !important;
  }

  .xs\:overscroll-y-auto {
    overscroll-behavior-y: auto !important;
  }

  .xs\:overscroll-y-contain {
    overscroll-behavior-y: contain !important;
  }

  .xs\:overscroll-y-none {
    overscroll-behavior-y: none !important;
  }

  .xs\:overscroll-x-auto {
    overscroll-behavior-x: auto !important;
  }

  .xs\:overscroll-x-contain {
    overscroll-behavior-x: contain !important;
  }

  .xs\:overscroll-x-none {
    overscroll-behavior-x: none !important;
  }

  .xs\:p-0 {
    padding: 0 !important;
  }

  .xs\:p-1 {
    padding: 0.25rem !important;
  }

  .xs\:p-2 {
    padding: 0.5rem !important;
  }

  .xs\:p-3 {
    padding: 0.75rem !important;
  }

  .xs\:p-4 {
    padding: 1rem !important;
  }

  .xs\:p-5 {
    padding: 1.25rem !important;
  }

  .xs\:p-6 {
    padding: 1.5rem !important;
  }

  .xs\:p-8 {
    padding: 2rem !important;
  }

  .xs\:p-10 {
    padding: 2.5rem !important;
  }

  .xs\:p-12 {
    padding: 3rem !important;
  }

  .xs\:p-16 {
    padding: 4rem !important;
  }

  .xs\:p-20 {
    padding: 5rem !important;
  }

  .xs\:p-24 {
    padding: 6rem !important;
  }

  .xs\:p-32 {
    padding: 8rem !important;
  }

  .xs\:p-40 {
    padding: 10rem !important;
  }

  .xs\:p-48 {
    padding: 12rem !important;
  }

  .xs\:p-56 {
    padding: 14rem !important;
  }

  .xs\:p-64 {
    padding: 16rem !important;
  }

  .xs\:p-px {
    padding: 1px !important;
  }

  .xs\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xs\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xs\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xs\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xs\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xs\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xs\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xs\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xs\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xs\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xs\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xs\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xs\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xs\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xs\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xs\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xs\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xs\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xs\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xs\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xs\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xs\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xs\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xs\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xs\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xs\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xs\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xs\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xs\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xs\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xs\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xs\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xs\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xs\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xs\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xs\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xs\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xs\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xs\:pt-0 {
    padding-top: 0 !important;
  }

  .xs\:pr-0 {
    padding-right: 0 !important;
  }

  .xs\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xs\:pl-0 {
    padding-left: 0 !important;
  }

  .xs\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xs\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xs\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xs\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xs\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xs\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xs\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xs\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xs\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .xs\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .xs\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .xs\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .xs\:pt-4 {
    padding-top: 1rem !important;
  }

  .xs\:pr-4 {
    padding-right: 1rem !important;
  }

  .xs\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .xs\:pl-4 {
    padding-left: 1rem !important;
  }

  .xs\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .xs\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .xs\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .xs\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .xs\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .xs\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .xs\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .xs\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .xs\:pt-8 {
    padding-top: 2rem !important;
  }

  .xs\:pr-8 {
    padding-right: 2rem !important;
  }

  .xs\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .xs\:pl-8 {
    padding-left: 2rem !important;
  }

  .xs\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .xs\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .xs\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .xs\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .xs\:pt-12 {
    padding-top: 3rem !important;
  }

  .xs\:pr-12 {
    padding-right: 3rem !important;
  }

  .xs\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .xs\:pl-12 {
    padding-left: 3rem !important;
  }

  .xs\:pt-16 {
    padding-top: 4rem !important;
  }

  .xs\:pr-16 {
    padding-right: 4rem !important;
  }

  .xs\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .xs\:pl-16 {
    padding-left: 4rem !important;
  }

  .xs\:pt-20 {
    padding-top: 5rem !important;
  }

  .xs\:pr-20 {
    padding-right: 5rem !important;
  }

  .xs\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .xs\:pl-20 {
    padding-left: 5rem !important;
  }

  .xs\:pt-24 {
    padding-top: 6rem !important;
  }

  .xs\:pr-24 {
    padding-right: 6rem !important;
  }

  .xs\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .xs\:pl-24 {
    padding-left: 6rem !important;
  }

  .xs\:pt-32 {
    padding-top: 8rem !important;
  }

  .xs\:pr-32 {
    padding-right: 8rem !important;
  }

  .xs\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .xs\:pl-32 {
    padding-left: 8rem !important;
  }

  .xs\:pt-40 {
    padding-top: 10rem !important;
  }

  .xs\:pr-40 {
    padding-right: 10rem !important;
  }

  .xs\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .xs\:pl-40 {
    padding-left: 10rem !important;
  }

  .xs\:pt-48 {
    padding-top: 12rem !important;
  }

  .xs\:pr-48 {
    padding-right: 12rem !important;
  }

  .xs\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .xs\:pl-48 {
    padding-left: 12rem !important;
  }

  .xs\:pt-56 {
    padding-top: 14rem !important;
  }

  .xs\:pr-56 {
    padding-right: 14rem !important;
  }

  .xs\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .xs\:pl-56 {
    padding-left: 14rem !important;
  }

  .xs\:pt-64 {
    padding-top: 16rem !important;
  }

  .xs\:pr-64 {
    padding-right: 16rem !important;
  }

  .xs\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .xs\:pl-64 {
    padding-left: 16rem !important;
  }

  .xs\:pt-px {
    padding-top: 1px !important;
  }

  .xs\:pr-px {
    padding-right: 1px !important;
  }

  .xs\:pb-px {
    padding-bottom: 1px !important;
  }

  .xs\:pl-px {
    padding-left: 1px !important;
  }

  .xs\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent !important;
  }

  .xs\:placeholder-transparent::-ms-input-placeholder {
    color: transparent !important;
  }

  .xs\:placeholder-transparent::placeholder {
    color: transparent !important;
  }

  .xs\:placeholder-current::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .xs\:placeholder-current::-ms-input-placeholder {
    color: currentColor !important;
  }

  .xs\:placeholder-current::placeholder {
    color: currentColor !important;
  }

  .xs\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-black::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-white::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .xs\:placeholder-primary::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .xs\:placeholder-primary::placeholder {
    color: var(--primary) !important;
  }

  .xs\:placeholder-accent-1::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-accent-1::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-accent-1::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-accent-2::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-accent-2::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-accent-2::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  .xs\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent !important;
  }

  .xs\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent !important;
  }

  .xs\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .xs\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor !important;
  }

  .xs\:focus\:placeholder-current:focus::placeholder {
    color: currentColor !important;
  }

  .xs\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .xs\:focus\:placeholder-primary:focus::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .xs\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary) !important;
  }

  .xs\:focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-accent-1:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-accent-1:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-accent-2:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:focus\:placeholder-accent-2:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xs\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:pointer-events-none {
    pointer-events: none !important;
  }

  .xs\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .xs\:static {
    position: static !important;
  }

  .xs\:fixed {
    position: fixed !important;
  }

  .xs\:absolute {
    position: absolute !important;
  }

  .xs\:relative {
    position: relative !important;
  }

  .xs\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .xs\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xs\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xs\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .xs\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .xs\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .xs\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .xs\:top-0 {
    top: 0 !important;
  }

  .xs\:right-0 {
    right: 0 !important;
  }

  .xs\:bottom-0 {
    bottom: 0 !important;
  }

  .xs\:left-0 {
    left: 0 !important;
  }

  .xs\:top-auto {
    top: auto !important;
  }

  .xs\:right-auto {
    right: auto !important;
  }

  .xs\:bottom-auto {
    bottom: auto !important;
  }

  .xs\:left-auto {
    left: auto !important;
  }

  .xs\:resize-none {
    resize: none !important;
  }

  .xs\:resize-y {
    resize: vertical !important;
  }

  .xs\:resize-x {
    resize: horizontal !important;
  }

  .xs\:resize {
    resize: both !important;
  }

  .xs\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xs\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xs\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xs\:shadow-none {
    box-shadow: none !important;
  }

  .xs\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xs\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xs\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xs\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .xs\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xs\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xs\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xs\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .xs\:fill-current {
    fill: currentColor !important;
  }

  .xs\:stroke-current {
    stroke: currentColor !important;
  }

  .xs\:stroke-0 {
    stroke-width: 0 !important;
  }

  .xs\:stroke-1 {
    stroke-width: 1 !important;
  }

  .xs\:stroke-2 {
    stroke-width: 2 !important;
  }

  .xs\:table-auto {
    table-layout: auto !important;
  }

  .xs\:table-fixed {
    table-layout: fixed !important;
  }

  .xs\:text-left {
    text-align: left !important;
  }

  .xs\:text-center {
    text-align: center !important;
  }

  .xs\:text-right {
    text-align: right !important;
  }

  .xs\:text-justify {
    text-align: justify !important;
  }

  .xs\:text-transparent {
    color: transparent !important;
  }

  .xs\:text-current {
    color: currentColor !important;
  }

  .xs\:text-black {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xs\:text-white {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xs\:text-gray-100 {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xs\:text-gray-200 {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xs\:text-gray-300 {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xs\:text-gray-400 {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xs\:text-gray-500 {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xs\:text-gray-600 {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xs\:text-gray-700 {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xs\:text-gray-800 {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xs\:text-gray-900 {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xs\:text-red-100 {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xs\:text-red-200 {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xs\:text-red-300 {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xs\:text-red-400 {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xs\:text-red-500 {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xs\:text-red-600 {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xs\:text-red-700 {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xs\:text-red-800 {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xs\:text-red-900 {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xs\:text-orange-100 {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xs\:text-orange-200 {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xs\:text-orange-300 {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xs\:text-orange-400 {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xs\:text-orange-500 {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xs\:text-orange-600 {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xs\:text-orange-700 {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xs\:text-orange-800 {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xs\:text-orange-900 {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-100 {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-200 {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-300 {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-400 {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-500 {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-600 {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-700 {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-800 {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xs\:text-yellow-900 {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xs\:text-green-100 {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xs\:text-green-200 {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xs\:text-green-300 {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xs\:text-green-400 {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xs\:text-green-500 {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xs\:text-green-600 {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xs\:text-green-700 {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xs\:text-green-800 {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xs\:text-green-900 {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xs\:text-teal-100 {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xs\:text-teal-200 {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xs\:text-teal-300 {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xs\:text-teal-400 {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xs\:text-teal-500 {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xs\:text-teal-600 {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xs\:text-teal-700 {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xs\:text-teal-800 {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xs\:text-teal-900 {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xs\:text-blue-100 {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xs\:text-blue-200 {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xs\:text-blue-300 {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xs\:text-blue-400 {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xs\:text-blue-500 {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xs\:text-blue-600 {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xs\:text-blue-700 {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xs\:text-blue-800 {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xs\:text-blue-900 {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-100 {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-200 {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-300 {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-400 {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-500 {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-600 {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-700 {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-800 {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xs\:text-indigo-900 {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xs\:text-purple-100 {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xs\:text-purple-200 {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xs\:text-purple-300 {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xs\:text-purple-400 {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xs\:text-purple-500 {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xs\:text-purple-600 {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xs\:text-purple-700 {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xs\:text-purple-800 {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xs\:text-purple-900 {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xs\:text-pink-100 {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xs\:text-pink-200 {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xs\:text-pink-300 {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xs\:text-pink-400 {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xs\:text-pink-500 {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xs\:text-pink-600 {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xs\:text-pink-700 {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xs\:text-pink-800 {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xs\:text-pink-900 {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xs\:text-primary {
    color: var(--primary) !important;
  }

  .xs\:text-accent-1 {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xs\:text-accent-2 {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-transparent:hover {
    color: transparent !important;
  }

  .xs\:hover\:text-current:hover {
    color: currentColor !important;
  }

  .xs\:hover\:text-black:hover {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-white:hover {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-100:hover {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-200:hover {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-300:hover {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-400:hover {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-500:hover {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-600:hover {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-700:hover {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-800:hover {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-gray-900:hover {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-200:hover {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-300:hover {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-400:hover {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-500:hover {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-600:hover {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-700:hover {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-800:hover {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-red-900:hover {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-100:hover {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-200:hover {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-300:hover {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-400:hover {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-500:hover {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-600:hover {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-700:hover {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-800:hover {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-orange-900:hover {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-100:hover {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-200:hover {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-300:hover {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-400:hover {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-500:hover {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-600:hover {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-700:hover {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-800:hover {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-yellow-900:hover {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-100:hover {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-200:hover {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-300:hover {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-400:hover {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-500:hover {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-600:hover {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-700:hover {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-800:hover {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-green-900:hover {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-100:hover {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-200:hover {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-300:hover {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-400:hover {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-500:hover {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-600:hover {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-700:hover {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-800:hover {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-teal-900:hover {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-100:hover {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-200:hover {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-300:hover {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-400:hover {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-500:hover {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-600:hover {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-700:hover {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-800:hover {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-blue-900:hover {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-100:hover {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-200:hover {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-300:hover {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-400:hover {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-500:hover {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-600:hover {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-700:hover {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-800:hover {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-indigo-900:hover {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-100:hover {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-200:hover {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-300:hover {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-400:hover {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-500:hover {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-600:hover {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-700:hover {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-800:hover {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-purple-900:hover {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-200:hover {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-300:hover {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-400:hover {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-500:hover {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-600:hover {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-700:hover {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-800:hover {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-pink-900:hover {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-primary:hover {
    color: var(--primary) !important;
  }

  .xs\:hover\:text-accent-1:hover {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xs\:hover\:text-accent-2:hover {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-transparent:focus {
    color: transparent !important;
  }

  .xs\:focus\:text-current:focus {
    color: currentColor !important;
  }

  .xs\:focus\:text-black:focus {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-white:focus {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-100:focus {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-200:focus {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-300:focus {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-400:focus {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-500:focus {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-600:focus {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-700:focus {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-800:focus {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-gray-900:focus {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-200:focus {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-300:focus {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-400:focus {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-500:focus {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-600:focus {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-700:focus {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-800:focus {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-red-900:focus {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-100:focus {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-200:focus {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-300:focus {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-400:focus {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-500:focus {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-600:focus {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-700:focus {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-800:focus {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-orange-900:focus {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-100:focus {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-200:focus {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-300:focus {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-400:focus {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-500:focus {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-600:focus {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-700:focus {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-800:focus {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-yellow-900:focus {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-100:focus {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-200:focus {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-300:focus {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-400:focus {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-500:focus {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-600:focus {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-700:focus {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-800:focus {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-green-900:focus {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-100:focus {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-200:focus {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-300:focus {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-400:focus {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-500:focus {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-600:focus {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-700:focus {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-800:focus {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-teal-900:focus {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-100:focus {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-200:focus {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-300:focus {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-400:focus {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-500:focus {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-600:focus {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-700:focus {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-800:focus {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-blue-900:focus {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-100:focus {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-200:focus {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-300:focus {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-400:focus {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-500:focus {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-600:focus {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-700:focus {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-800:focus {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-indigo-900:focus {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-100:focus {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-200:focus {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-300:focus {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-400:focus {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-500:focus {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-600:focus {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-700:focus {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-800:focus {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-purple-900:focus {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-200:focus {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-300:focus {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-400:focus {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-500:focus {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-600:focus {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-700:focus {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-800:focus {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-pink-900:focus {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-primary:focus {
    color: var(--primary) !important;
  }

  .xs\:focus\:text-accent-1:focus {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xs\:focus\:text-accent-2:focus {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xs\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .xs\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .xs\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .xs\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .xs\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .xs\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .xs\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .xs\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .xs\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .xs\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .xs\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .xs\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .xs\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .xs\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .xs\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .xs\:italic {
    font-style: italic !important;
  }

  .xs\:not-italic {
    font-style: normal !important;
  }

  .xs\:uppercase {
    text-transform: uppercase !important;
  }

  .xs\:lowercase {
    text-transform: lowercase !important;
  }

  .xs\:capitalize {
    text-transform: capitalize !important;
  }

  .xs\:normal-case {
    text-transform: none !important;
  }

  .xs\:underline {
    text-decoration: underline !important;
  }

  .xs\:line-through {
    text-decoration: line-through !important;
  }

  .xs\:no-underline {
    text-decoration: none !important;
  }

  .xs\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .xs\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .xs\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .xs\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .xs\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .xs\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .xs\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .xs\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .xs\:ordinal, .xs\:slashed-zero, .xs\:lining-nums, .xs\:oldstyle-nums, .xs\:proportional-nums, .xs\:tabular-nums, .xs\:diagonal-fractions, .xs\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
  }

  .xs\:normal-nums {
    font-variant-numeric: normal !important;
  }

  .xs\:ordinal {
    --font-variant-numeric-ordinal: ordinal !important;
  }

  .xs\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero !important;
  }

  .xs\:lining-nums {
    --font-variant-numeric-figure: lining-nums !important;
  }

  .xs\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums !important;
  }

  .xs\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums !important;
  }

  .xs\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums !important;
  }

  .xs\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions !important;
  }

  .xs\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions !important;
  }

  .xs\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .xs\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .xs\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xs\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .xs\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .xs\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .xs\:select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .xs\:select-text {
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .xs\:select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .xs\:select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .xs\:align-baseline {
    vertical-align: baseline !important;
  }

  .xs\:align-top {
    vertical-align: top !important;
  }

  .xs\:align-middle {
    vertical-align: middle !important;
  }

  .xs\:align-bottom {
    vertical-align: bottom !important;
  }

  .xs\:align-text-top {
    vertical-align: text-top !important;
  }

  .xs\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xs\:visible {
    visibility: visible !important;
  }

  .xs\:invisible {
    visibility: hidden !important;
  }

  .xs\:whitespace-normal {
    white-space: normal !important;
  }

  .xs\:whitespace-no-wrap {
    white-space: nowrap !important;
  }

  .xs\:whitespace-pre {
    white-space: pre !important;
  }

  .xs\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .xs\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .xs\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xs\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  .xs\:break-all {
    word-break: break-all !important;
  }

  .xs\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xs\:w-0 {
    width: 0 !important;
  }

  .xs\:w-1 {
    width: 0.25rem !important;
  }

  .xs\:w-2 {
    width: 0.5rem !important;
  }

  .xs\:w-3 {
    width: 0.75rem !important;
  }

  .xs\:w-4 {
    width: 1rem !important;
  }

  .xs\:w-5 {
    width: 1.25rem !important;
  }

  .xs\:w-6 {
    width: 1.5rem !important;
  }

  .xs\:w-8 {
    width: 2rem !important;
  }

  .xs\:w-10 {
    width: 2.5rem !important;
  }

  .xs\:w-12 {
    width: 3rem !important;
  }

  .xs\:w-16 {
    width: 4rem !important;
  }

  .xs\:w-20 {
    width: 5rem !important;
  }

  .xs\:w-24 {
    width: 6rem !important;
  }

  .xs\:w-32 {
    width: 8rem !important;
  }

  .xs\:w-40 {
    width: 10rem !important;
  }

  .xs\:w-48 {
    width: 12rem !important;
  }

  .xs\:w-56 {
    width: 14rem !important;
  }

  .xs\:w-64 {
    width: 16rem !important;
  }

  .xs\:w-auto {
    width: auto !important;
  }

  .xs\:w-px {
    width: 1px !important;
  }

  .xs\:w-1\/2 {
    width: 50% !important;
  }

  .xs\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xs\:w-2\/3 {
    width: 66.666667% !important;
  }

  .xs\:w-1\/4 {
    width: 25% !important;
  }

  .xs\:w-2\/4 {
    width: 50% !important;
  }

  .xs\:w-3\/4 {
    width: 75% !important;
  }

  .xs\:w-1\/5 {
    width: 20% !important;
  }

  .xs\:w-2\/5 {
    width: 40% !important;
  }

  .xs\:w-3\/5 {
    width: 60% !important;
  }

  .xs\:w-4\/5 {
    width: 80% !important;
  }

  .xs\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xs\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xs\:w-3\/6 {
    width: 50% !important;
  }

  .xs\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xs\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xs\:w-1\/12 {
    width: 8.333333% !important;
  }

  .xs\:w-2\/12 {
    width: 16.666667% !important;
  }

  .xs\:w-3\/12 {
    width: 25% !important;
  }

  .xs\:w-4\/12 {
    width: 33.333333% !important;
  }

  .xs\:w-5\/12 {
    width: 41.666667% !important;
  }

  .xs\:w-6\/12 {
    width: 50% !important;
  }

  .xs\:w-7\/12 {
    width: 58.333333% !important;
  }

  .xs\:w-8\/12 {
    width: 66.666667% !important;
  }

  .xs\:w-9\/12 {
    width: 75% !important;
  }

  .xs\:w-10\/12 {
    width: 83.333333% !important;
  }

  .xs\:w-11\/12 {
    width: 91.666667% !important;
  }

  .xs\:w-full {
    width: 100% !important;
  }

  .xs\:w-screen {
    width: 100vw !important;
  }

  .xs\:w-none {
    width: none !important;
  }

  .xs\:w-xs {
    width: 20rem !important;
  }

  .xs\:w-sm {
    width: 24rem !important;
  }

  .xs\:w-md {
    width: 28rem !important;
  }

  .xs\:w-lg {
    width: 32rem !important;
  }

  .xs\:w-xl {
    width: 36rem !important;
  }

  .xs\:w-2xl {
    width: 42rem !important;
  }

  .xs\:w-3xl {
    width: 48rem !important;
  }

  .xs\:w-4xl {
    width: 56rem !important;
  }

  .xs\:w-5xl {
    width: 64rem !important;
  }

  .xs\:w-6xl {
    width: 72rem !important;
  }

  .xs\:w-screen-xs {
    width: 480px !important;
  }

  .xs\:w-screen-sm {
    width: 576px !important;
  }

  .xs\:w-screen-md {
    width: 768px !important;
  }

  .xs\:w-screen-lg {
    width: 992px !important;
  }

  .xs\:w-screen-xl {
    width: 1200px !important;
  }

  .xs\:w-screen-xxl {
    width: 1600px !important;
  }

  .xs\:w-sidebar {
    width: 15rem !important;
  }

  .xs\:z-0 {
    z-index: 0 !important;
  }

  .xs\:z-10 {
    z-index: 10 !important;
  }

  .xs\:z-20 {
    z-index: 20 !important;
  }

  .xs\:z-30 {
    z-index: 30 !important;
  }

  .xs\:z-40 {
    z-index: 40 !important;
  }

  .xs\:z-50 {
    z-index: 50 !important;
  }

  .xs\:z-auto {
    z-index: auto !important;
  }

  .xs\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .xs\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .xs\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .xs\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .xs\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .xs\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .xs\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .xs\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .xs\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .xs\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .xs\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .xs\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .xs\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .xs\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .xs\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .xs\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .xs\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .xs\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .xs\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .xs\:gap-x-0 {
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }

  .xs\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .xs\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .xs\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .xs\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .xs\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .xs\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .xs\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .xs\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .xs\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .xs\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .xs\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .xs\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .xs\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .xs\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .xs\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .xs\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .xs\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .xs\:gap-x-px {
    grid-column-gap: 1px !important;
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .xs\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .xs\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .xs\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .xs\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .xs\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .xs\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .xs\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .xs\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .xs\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .xs\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .xs\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .xs\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .xs\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .xs\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .xs\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .xs\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .xs\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .xs\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .xs\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .xs\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .xs\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .xs\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .xs\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .xs\:auto-cols-auto {
    grid-auto-columns: auto !important;
  }

  .xs\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .xs\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .xs\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .xs\:col-auto {
    grid-column: auto !important;
  }

  .xs\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .xs\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .xs\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .xs\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .xs\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .xs\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .xs\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .xs\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .xs\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .xs\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .xs\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .xs\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .xs\:col-span-full {
    grid-column: 1 / -1 !important;
  }

  .xs\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .xs\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .xs\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .xs\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .xs\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .xs\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .xs\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .xs\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .xs\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .xs\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .xs\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .xs\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .xs\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .xs\:col-start-auto {
    grid-column-start: auto !important;
  }

  .xs\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .xs\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .xs\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .xs\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .xs\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .xs\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .xs\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .xs\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .xs\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .xs\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .xs\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .xs\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .xs\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .xs\:col-end-auto {
    grid-column-end: auto !important;
  }

  .xs\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .xs\:auto-rows-auto {
    grid-auto-rows: auto !important;
  }

  .xs\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .xs\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .xs\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .xs\:row-auto {
    grid-row: auto !important;
  }

  .xs\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .xs\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .xs\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .xs\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .xs\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .xs\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .xs\:row-span-full {
    grid-row: 1 / -1 !important;
  }

  .xs\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .xs\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .xs\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .xs\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .xs\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .xs\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .xs\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .xs\:row-start-auto {
    grid-row-start: auto !important;
  }

  .xs\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .xs\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .xs\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .xs\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .xs\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .xs\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .xs\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .xs\:row-end-auto {
    grid-row-end: auto !important;
  }

  .xs\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .xs\:transform-none {
    transform: none !important;
  }

  .xs\:origin-center {
    transform-origin: center !important;
  }

  .xs\:origin-top {
    transform-origin: top !important;
  }

  .xs\:origin-top-right {
    transform-origin: top right !important;
  }

  .xs\:origin-right {
    transform-origin: right !important;
  }

  .xs\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .xs\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xs\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .xs\:origin-left {
    transform-origin: left !important;
  }

  .xs\:origin-top-left {
    transform-origin: top left !important;
  }

  .xs\:scale-0 {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xs\:scale-50 {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xs\:scale-75 {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xs\:scale-90 {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xs\:scale-95 {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xs\:scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xs\:scale-105 {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xs\:scale-110 {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xs\:scale-125 {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xs\:scale-150 {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xs\:scale-x-0 {
    --transform-scale-x: 0 !important;
  }

  .xs\:scale-x-50 {
    --transform-scale-x: .5 !important;
  }

  .xs\:scale-x-75 {
    --transform-scale-x: .75 !important;
  }

  .xs\:scale-x-90 {
    --transform-scale-x: .9 !important;
  }

  .xs\:scale-x-95 {
    --transform-scale-x: .95 !important;
  }

  .xs\:scale-x-100 {
    --transform-scale-x: 1 !important;
  }

  .xs\:scale-x-105 {
    --transform-scale-x: 1.05 !important;
  }

  .xs\:scale-x-110 {
    --transform-scale-x: 1.1 !important;
  }

  .xs\:scale-x-125 {
    --transform-scale-x: 1.25 !important;
  }

  .xs\:scale-x-150 {
    --transform-scale-x: 1.5 !important;
  }

  .xs\:scale-y-0 {
    --transform-scale-y: 0 !important;
  }

  .xs\:scale-y-50 {
    --transform-scale-y: .5 !important;
  }

  .xs\:scale-y-75 {
    --transform-scale-y: .75 !important;
  }

  .xs\:scale-y-90 {
    --transform-scale-y: .9 !important;
  }

  .xs\:scale-y-95 {
    --transform-scale-y: .95 !important;
  }

  .xs\:scale-y-100 {
    --transform-scale-y: 1 !important;
  }

  .xs\:scale-y-105 {
    --transform-scale-y: 1.05 !important;
  }

  .xs\:scale-y-110 {
    --transform-scale-y: 1.1 !important;
  }

  .xs\:scale-y-125 {
    --transform-scale-y: 1.25 !important;
  }

  .xs\:scale-y-150 {
    --transform-scale-y: 1.5 !important;
  }

  .xs\:hover\:scale-0:hover {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xs\:hover\:scale-50:hover {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xs\:hover\:scale-75:hover {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xs\:hover\:scale-90:hover {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xs\:hover\:scale-95:hover {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xs\:hover\:scale-100:hover {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xs\:hover\:scale-105:hover {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xs\:hover\:scale-110:hover {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xs\:hover\:scale-125:hover {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xs\:hover\:scale-150:hover {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xs\:hover\:scale-x-0:hover {
    --transform-scale-x: 0 !important;
  }

  .xs\:hover\:scale-x-50:hover {
    --transform-scale-x: .5 !important;
  }

  .xs\:hover\:scale-x-75:hover {
    --transform-scale-x: .75 !important;
  }

  .xs\:hover\:scale-x-90:hover {
    --transform-scale-x: .9 !important;
  }

  .xs\:hover\:scale-x-95:hover {
    --transform-scale-x: .95 !important;
  }

  .xs\:hover\:scale-x-100:hover {
    --transform-scale-x: 1 !important;
  }

  .xs\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05 !important;
  }

  .xs\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1 !important;
  }

  .xs\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25 !important;
  }

  .xs\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5 !important;
  }

  .xs\:hover\:scale-y-0:hover {
    --transform-scale-y: 0 !important;
  }

  .xs\:hover\:scale-y-50:hover {
    --transform-scale-y: .5 !important;
  }

  .xs\:hover\:scale-y-75:hover {
    --transform-scale-y: .75 !important;
  }

  .xs\:hover\:scale-y-90:hover {
    --transform-scale-y: .9 !important;
  }

  .xs\:hover\:scale-y-95:hover {
    --transform-scale-y: .95 !important;
  }

  .xs\:hover\:scale-y-100:hover {
    --transform-scale-y: 1 !important;
  }

  .xs\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05 !important;
  }

  .xs\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1 !important;
  }

  .xs\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25 !important;
  }

  .xs\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5 !important;
  }

  .xs\:focus\:scale-0:focus {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xs\:focus\:scale-50:focus {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xs\:focus\:scale-75:focus {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xs\:focus\:scale-90:focus {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xs\:focus\:scale-95:focus {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xs\:focus\:scale-100:focus {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xs\:focus\:scale-105:focus {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xs\:focus\:scale-110:focus {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xs\:focus\:scale-125:focus {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xs\:focus\:scale-150:focus {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xs\:focus\:scale-x-0:focus {
    --transform-scale-x: 0 !important;
  }

  .xs\:focus\:scale-x-50:focus {
    --transform-scale-x: .5 !important;
  }

  .xs\:focus\:scale-x-75:focus {
    --transform-scale-x: .75 !important;
  }

  .xs\:focus\:scale-x-90:focus {
    --transform-scale-x: .9 !important;
  }

  .xs\:focus\:scale-x-95:focus {
    --transform-scale-x: .95 !important;
  }

  .xs\:focus\:scale-x-100:focus {
    --transform-scale-x: 1 !important;
  }

  .xs\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05 !important;
  }

  .xs\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1 !important;
  }

  .xs\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25 !important;
  }

  .xs\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5 !important;
  }

  .xs\:focus\:scale-y-0:focus {
    --transform-scale-y: 0 !important;
  }

  .xs\:focus\:scale-y-50:focus {
    --transform-scale-y: .5 !important;
  }

  .xs\:focus\:scale-y-75:focus {
    --transform-scale-y: .75 !important;
  }

  .xs\:focus\:scale-y-90:focus {
    --transform-scale-y: .9 !important;
  }

  .xs\:focus\:scale-y-95:focus {
    --transform-scale-y: .95 !important;
  }

  .xs\:focus\:scale-y-100:focus {
    --transform-scale-y: 1 !important;
  }

  .xs\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05 !important;
  }

  .xs\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1 !important;
  }

  .xs\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25 !important;
  }

  .xs\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5 !important;
  }

  .xs\:rotate-0 {
    --transform-rotate: 0 !important;
  }

  .xs\:rotate-1 {
    --transform-rotate: 1deg !important;
  }

  .xs\:rotate-2 {
    --transform-rotate: 2deg !important;
  }

  .xs\:rotate-3 {
    --transform-rotate: 3deg !important;
  }

  .xs\:rotate-6 {
    --transform-rotate: 6deg !important;
  }

  .xs\:rotate-12 {
    --transform-rotate: 12deg !important;
  }

  .xs\:rotate-45 {
    --transform-rotate: 45deg !important;
  }

  .xs\:rotate-90 {
    --transform-rotate: 90deg !important;
  }

  .xs\:rotate-180 {
    --transform-rotate: 180deg !important;
  }

  .xs\:-rotate-180 {
    --transform-rotate: -180deg !important;
  }

  .xs\:-rotate-90 {
    --transform-rotate: -90deg !important;
  }

  .xs\:-rotate-45 {
    --transform-rotate: -45deg !important;
  }

  .xs\:-rotate-12 {
    --transform-rotate: -12deg !important;
  }

  .xs\:-rotate-6 {
    --transform-rotate: -6deg !important;
  }

  .xs\:-rotate-3 {
    --transform-rotate: -3deg !important;
  }

  .xs\:-rotate-2 {
    --transform-rotate: -2deg !important;
  }

  .xs\:-rotate-1 {
    --transform-rotate: -1deg !important;
  }

  .xs\:hover\:rotate-0:hover {
    --transform-rotate: 0 !important;
  }

  .xs\:hover\:rotate-1:hover {
    --transform-rotate: 1deg !important;
  }

  .xs\:hover\:rotate-2:hover {
    --transform-rotate: 2deg !important;
  }

  .xs\:hover\:rotate-3:hover {
    --transform-rotate: 3deg !important;
  }

  .xs\:hover\:rotate-6:hover {
    --transform-rotate: 6deg !important;
  }

  .xs\:hover\:rotate-12:hover {
    --transform-rotate: 12deg !important;
  }

  .xs\:hover\:rotate-45:hover {
    --transform-rotate: 45deg !important;
  }

  .xs\:hover\:rotate-90:hover {
    --transform-rotate: 90deg !important;
  }

  .xs\:hover\:rotate-180:hover {
    --transform-rotate: 180deg !important;
  }

  .xs\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg !important;
  }

  .xs\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg !important;
  }

  .xs\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg !important;
  }

  .xs\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg !important;
  }

  .xs\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg !important;
  }

  .xs\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg !important;
  }

  .xs\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg !important;
  }

  .xs\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg !important;
  }

  .xs\:focus\:rotate-0:focus {
    --transform-rotate: 0 !important;
  }

  .xs\:focus\:rotate-1:focus {
    --transform-rotate: 1deg !important;
  }

  .xs\:focus\:rotate-2:focus {
    --transform-rotate: 2deg !important;
  }

  .xs\:focus\:rotate-3:focus {
    --transform-rotate: 3deg !important;
  }

  .xs\:focus\:rotate-6:focus {
    --transform-rotate: 6deg !important;
  }

  .xs\:focus\:rotate-12:focus {
    --transform-rotate: 12deg !important;
  }

  .xs\:focus\:rotate-45:focus {
    --transform-rotate: 45deg !important;
  }

  .xs\:focus\:rotate-90:focus {
    --transform-rotate: 90deg !important;
  }

  .xs\:focus\:rotate-180:focus {
    --transform-rotate: 180deg !important;
  }

  .xs\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg !important;
  }

  .xs\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg !important;
  }

  .xs\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg !important;
  }

  .xs\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg !important;
  }

  .xs\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg !important;
  }

  .xs\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg !important;
  }

  .xs\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg !important;
  }

  .xs\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg !important;
  }

  .xs\:translate-x-0 {
    --transform-translate-x: 0 !important;
  }

  .xs\:translate-x-1 {
    --transform-translate-x: 0.25rem !important;
  }

  .xs\:translate-x-2 {
    --transform-translate-x: 0.5rem !important;
  }

  .xs\:translate-x-3 {
    --transform-translate-x: 0.75rem !important;
  }

  .xs\:translate-x-4 {
    --transform-translate-x: 1rem !important;
  }

  .xs\:translate-x-5 {
    --transform-translate-x: 1.25rem !important;
  }

  .xs\:translate-x-6 {
    --transform-translate-x: 1.5rem !important;
  }

  .xs\:translate-x-8 {
    --transform-translate-x: 2rem !important;
  }

  .xs\:translate-x-10 {
    --transform-translate-x: 2.5rem !important;
  }

  .xs\:translate-x-12 {
    --transform-translate-x: 3rem !important;
  }

  .xs\:translate-x-16 {
    --transform-translate-x: 4rem !important;
  }

  .xs\:translate-x-20 {
    --transform-translate-x: 5rem !important;
  }

  .xs\:translate-x-24 {
    --transform-translate-x: 6rem !important;
  }

  .xs\:translate-x-32 {
    --transform-translate-x: 8rem !important;
  }

  .xs\:translate-x-40 {
    --transform-translate-x: 10rem !important;
  }

  .xs\:translate-x-48 {
    --transform-translate-x: 12rem !important;
  }

  .xs\:translate-x-56 {
    --transform-translate-x: 14rem !important;
  }

  .xs\:translate-x-64 {
    --transform-translate-x: 16rem !important;
  }

  .xs\:translate-x-px {
    --transform-translate-x: 1px !important;
  }

  .xs\:-translate-x-1 {
    --transform-translate-x: -0.25rem !important;
  }

  .xs\:-translate-x-2 {
    --transform-translate-x: -0.5rem !important;
  }

  .xs\:-translate-x-3 {
    --transform-translate-x: -0.75rem !important;
  }

  .xs\:-translate-x-4 {
    --transform-translate-x: -1rem !important;
  }

  .xs\:-translate-x-5 {
    --transform-translate-x: -1.25rem !important;
  }

  .xs\:-translate-x-6 {
    --transform-translate-x: -1.5rem !important;
  }

  .xs\:-translate-x-8 {
    --transform-translate-x: -2rem !important;
  }

  .xs\:-translate-x-10 {
    --transform-translate-x: -2.5rem !important;
  }

  .xs\:-translate-x-12 {
    --transform-translate-x: -3rem !important;
  }

  .xs\:-translate-x-16 {
    --transform-translate-x: -4rem !important;
  }

  .xs\:-translate-x-20 {
    --transform-translate-x: -5rem !important;
  }

  .xs\:-translate-x-24 {
    --transform-translate-x: -6rem !important;
  }

  .xs\:-translate-x-32 {
    --transform-translate-x: -8rem !important;
  }

  .xs\:-translate-x-40 {
    --transform-translate-x: -10rem !important;
  }

  .xs\:-translate-x-48 {
    --transform-translate-x: -12rem !important;
  }

  .xs\:-translate-x-56 {
    --transform-translate-x: -14rem !important;
  }

  .xs\:-translate-x-64 {
    --transform-translate-x: -16rem !important;
  }

  .xs\:-translate-x-px {
    --transform-translate-x: -1px !important;
  }

  .xs\:-translate-x-full {
    --transform-translate-x: -100% !important;
  }

  .xs\:-translate-x-1\/2 {
    --transform-translate-x: -50% !important;
  }

  .xs\:translate-x-1\/2 {
    --transform-translate-x: 50% !important;
  }

  .xs\:translate-x-full {
    --transform-translate-x: 100% !important;
  }

  .xs\:translate-y-0 {
    --transform-translate-y: 0 !important;
  }

  .xs\:translate-y-1 {
    --transform-translate-y: 0.25rem !important;
  }

  .xs\:translate-y-2 {
    --transform-translate-y: 0.5rem !important;
  }

  .xs\:translate-y-3 {
    --transform-translate-y: 0.75rem !important;
  }

  .xs\:translate-y-4 {
    --transform-translate-y: 1rem !important;
  }

  .xs\:translate-y-5 {
    --transform-translate-y: 1.25rem !important;
  }

  .xs\:translate-y-6 {
    --transform-translate-y: 1.5rem !important;
  }

  .xs\:translate-y-8 {
    --transform-translate-y: 2rem !important;
  }

  .xs\:translate-y-10 {
    --transform-translate-y: 2.5rem !important;
  }

  .xs\:translate-y-12 {
    --transform-translate-y: 3rem !important;
  }

  .xs\:translate-y-16 {
    --transform-translate-y: 4rem !important;
  }

  .xs\:translate-y-20 {
    --transform-translate-y: 5rem !important;
  }

  .xs\:translate-y-24 {
    --transform-translate-y: 6rem !important;
  }

  .xs\:translate-y-32 {
    --transform-translate-y: 8rem !important;
  }

  .xs\:translate-y-40 {
    --transform-translate-y: 10rem !important;
  }

  .xs\:translate-y-48 {
    --transform-translate-y: 12rem !important;
  }

  .xs\:translate-y-56 {
    --transform-translate-y: 14rem !important;
  }

  .xs\:translate-y-64 {
    --transform-translate-y: 16rem !important;
  }

  .xs\:translate-y-px {
    --transform-translate-y: 1px !important;
  }

  .xs\:-translate-y-1 {
    --transform-translate-y: -0.25rem !important;
  }

  .xs\:-translate-y-2 {
    --transform-translate-y: -0.5rem !important;
  }

  .xs\:-translate-y-3 {
    --transform-translate-y: -0.75rem !important;
  }

  .xs\:-translate-y-4 {
    --transform-translate-y: -1rem !important;
  }

  .xs\:-translate-y-5 {
    --transform-translate-y: -1.25rem !important;
  }

  .xs\:-translate-y-6 {
    --transform-translate-y: -1.5rem !important;
  }

  .xs\:-translate-y-8 {
    --transform-translate-y: -2rem !important;
  }

  .xs\:-translate-y-10 {
    --transform-translate-y: -2.5rem !important;
  }

  .xs\:-translate-y-12 {
    --transform-translate-y: -3rem !important;
  }

  .xs\:-translate-y-16 {
    --transform-translate-y: -4rem !important;
  }

  .xs\:-translate-y-20 {
    --transform-translate-y: -5rem !important;
  }

  .xs\:-translate-y-24 {
    --transform-translate-y: -6rem !important;
  }

  .xs\:-translate-y-32 {
    --transform-translate-y: -8rem !important;
  }

  .xs\:-translate-y-40 {
    --transform-translate-y: -10rem !important;
  }

  .xs\:-translate-y-48 {
    --transform-translate-y: -12rem !important;
  }

  .xs\:-translate-y-56 {
    --transform-translate-y: -14rem !important;
  }

  .xs\:-translate-y-64 {
    --transform-translate-y: -16rem !important;
  }

  .xs\:-translate-y-px {
    --transform-translate-y: -1px !important;
  }

  .xs\:-translate-y-full {
    --transform-translate-y: -100% !important;
  }

  .xs\:-translate-y-1\/2 {
    --transform-translate-y: -50% !important;
  }

  .xs\:translate-y-1\/2 {
    --transform-translate-y: 50% !important;
  }

  .xs\:translate-y-full {
    --transform-translate-y: 100% !important;
  }

  .xs\:hover\:translate-x-0:hover {
    --transform-translate-x: 0 !important;
  }

  .xs\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem !important;
  }

  .xs\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem !important;
  }

  .xs\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem !important;
  }

  .xs\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem !important;
  }

  .xs\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem !important;
  }

  .xs\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem !important;
  }

  .xs\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem !important;
  }

  .xs\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem !important;
  }

  .xs\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem !important;
  }

  .xs\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem !important;
  }

  .xs\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem !important;
  }

  .xs\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem !important;
  }

  .xs\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem !important;
  }

  .xs\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem !important;
  }

  .xs\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem !important;
  }

  .xs\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem !important;
  }

  .xs\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem !important;
  }

  .xs\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px !important;
  }

  .xs\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem !important;
  }

  .xs\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem !important;
  }

  .xs\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem !important;
  }

  .xs\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem !important;
  }

  .xs\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem !important;
  }

  .xs\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem !important;
  }

  .xs\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem !important;
  }

  .xs\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem !important;
  }

  .xs\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem !important;
  }

  .xs\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem !important;
  }

  .xs\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem !important;
  }

  .xs\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem !important;
  }

  .xs\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem !important;
  }

  .xs\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem !important;
  }

  .xs\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem !important;
  }

  .xs\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem !important;
  }

  .xs\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem !important;
  }

  .xs\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px !important;
  }

  .xs\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100% !important;
  }

  .xs\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50% !important;
  }

  .xs\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50% !important;
  }

  .xs\:hover\:translate-x-full:hover {
    --transform-translate-x: 100% !important;
  }

  .xs\:hover\:translate-y-0:hover {
    --transform-translate-y: 0 !important;
  }

  .xs\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem !important;
  }

  .xs\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem !important;
  }

  .xs\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem !important;
  }

  .xs\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem !important;
  }

  .xs\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem !important;
  }

  .xs\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem !important;
  }

  .xs\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem !important;
  }

  .xs\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem !important;
  }

  .xs\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem !important;
  }

  .xs\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem !important;
  }

  .xs\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem !important;
  }

  .xs\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem !important;
  }

  .xs\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem !important;
  }

  .xs\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem !important;
  }

  .xs\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem !important;
  }

  .xs\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem !important;
  }

  .xs\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem !important;
  }

  .xs\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px !important;
  }

  .xs\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem !important;
  }

  .xs\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem !important;
  }

  .xs\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem !important;
  }

  .xs\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem !important;
  }

  .xs\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem !important;
  }

  .xs\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem !important;
  }

  .xs\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem !important;
  }

  .xs\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem !important;
  }

  .xs\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem !important;
  }

  .xs\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem !important;
  }

  .xs\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem !important;
  }

  .xs\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem !important;
  }

  .xs\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem !important;
  }

  .xs\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem !important;
  }

  .xs\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem !important;
  }

  .xs\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem !important;
  }

  .xs\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem !important;
  }

  .xs\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px !important;
  }

  .xs\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100% !important;
  }

  .xs\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50% !important;
  }

  .xs\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50% !important;
  }

  .xs\:hover\:translate-y-full:hover {
    --transform-translate-y: 100% !important;
  }

  .xs\:focus\:translate-x-0:focus {
    --transform-translate-x: 0 !important;
  }

  .xs\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem !important;
  }

  .xs\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem !important;
  }

  .xs\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem !important;
  }

  .xs\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem !important;
  }

  .xs\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem !important;
  }

  .xs\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem !important;
  }

  .xs\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem !important;
  }

  .xs\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem !important;
  }

  .xs\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem !important;
  }

  .xs\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem !important;
  }

  .xs\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem !important;
  }

  .xs\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem !important;
  }

  .xs\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem !important;
  }

  .xs\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem !important;
  }

  .xs\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem !important;
  }

  .xs\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem !important;
  }

  .xs\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem !important;
  }

  .xs\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px !important;
  }

  .xs\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem !important;
  }

  .xs\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem !important;
  }

  .xs\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem !important;
  }

  .xs\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem !important;
  }

  .xs\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem !important;
  }

  .xs\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem !important;
  }

  .xs\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem !important;
  }

  .xs\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem !important;
  }

  .xs\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem !important;
  }

  .xs\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem !important;
  }

  .xs\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem !important;
  }

  .xs\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem !important;
  }

  .xs\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem !important;
  }

  .xs\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem !important;
  }

  .xs\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem !important;
  }

  .xs\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem !important;
  }

  .xs\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem !important;
  }

  .xs\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px !important;
  }

  .xs\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100% !important;
  }

  .xs\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50% !important;
  }

  .xs\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50% !important;
  }

  .xs\:focus\:translate-x-full:focus {
    --transform-translate-x: 100% !important;
  }

  .xs\:focus\:translate-y-0:focus {
    --transform-translate-y: 0 !important;
  }

  .xs\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem !important;
  }

  .xs\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem !important;
  }

  .xs\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem !important;
  }

  .xs\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem !important;
  }

  .xs\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem !important;
  }

  .xs\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem !important;
  }

  .xs\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem !important;
  }

  .xs\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem !important;
  }

  .xs\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem !important;
  }

  .xs\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem !important;
  }

  .xs\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem !important;
  }

  .xs\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem !important;
  }

  .xs\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem !important;
  }

  .xs\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem !important;
  }

  .xs\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem !important;
  }

  .xs\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem !important;
  }

  .xs\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem !important;
  }

  .xs\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px !important;
  }

  .xs\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem !important;
  }

  .xs\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem !important;
  }

  .xs\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem !important;
  }

  .xs\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem !important;
  }

  .xs\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem !important;
  }

  .xs\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem !important;
  }

  .xs\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem !important;
  }

  .xs\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem !important;
  }

  .xs\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem !important;
  }

  .xs\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem !important;
  }

  .xs\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem !important;
  }

  .xs\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem !important;
  }

  .xs\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem !important;
  }

  .xs\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem !important;
  }

  .xs\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem !important;
  }

  .xs\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem !important;
  }

  .xs\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem !important;
  }

  .xs\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px !important;
  }

  .xs\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100% !important;
  }

  .xs\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50% !important;
  }

  .xs\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50% !important;
  }

  .xs\:focus\:translate-y-full:focus {
    --transform-translate-y: 100% !important;
  }

  .xs\:skew-x-0 {
    --transform-skew-x: 0 !important;
  }

  .xs\:skew-x-1 {
    --transform-skew-x: 1deg !important;
  }

  .xs\:skew-x-2 {
    --transform-skew-x: 2deg !important;
  }

  .xs\:skew-x-3 {
    --transform-skew-x: 3deg !important;
  }

  .xs\:skew-x-6 {
    --transform-skew-x: 6deg !important;
  }

  .xs\:skew-x-12 {
    --transform-skew-x: 12deg !important;
  }

  .xs\:-skew-x-12 {
    --transform-skew-x: -12deg !important;
  }

  .xs\:-skew-x-6 {
    --transform-skew-x: -6deg !important;
  }

  .xs\:-skew-x-3 {
    --transform-skew-x: -3deg !important;
  }

  .xs\:-skew-x-2 {
    --transform-skew-x: -2deg !important;
  }

  .xs\:-skew-x-1 {
    --transform-skew-x: -1deg !important;
  }

  .xs\:skew-y-0 {
    --transform-skew-y: 0 !important;
  }

  .xs\:skew-y-1 {
    --transform-skew-y: 1deg !important;
  }

  .xs\:skew-y-2 {
    --transform-skew-y: 2deg !important;
  }

  .xs\:skew-y-3 {
    --transform-skew-y: 3deg !important;
  }

  .xs\:skew-y-6 {
    --transform-skew-y: 6deg !important;
  }

  .xs\:skew-y-12 {
    --transform-skew-y: 12deg !important;
  }

  .xs\:-skew-y-12 {
    --transform-skew-y: -12deg !important;
  }

  .xs\:-skew-y-6 {
    --transform-skew-y: -6deg !important;
  }

  .xs\:-skew-y-3 {
    --transform-skew-y: -3deg !important;
  }

  .xs\:-skew-y-2 {
    --transform-skew-y: -2deg !important;
  }

  .xs\:-skew-y-1 {
    --transform-skew-y: -1deg !important;
  }

  .xs\:hover\:skew-x-0:hover {
    --transform-skew-x: 0 !important;
  }

  .xs\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg !important;
  }

  .xs\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg !important;
  }

  .xs\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg !important;
  }

  .xs\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg !important;
  }

  .xs\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg !important;
  }

  .xs\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg !important;
  }

  .xs\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg !important;
  }

  .xs\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg !important;
  }

  .xs\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg !important;
  }

  .xs\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg !important;
  }

  .xs\:hover\:skew-y-0:hover {
    --transform-skew-y: 0 !important;
  }

  .xs\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg !important;
  }

  .xs\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg !important;
  }

  .xs\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg !important;
  }

  .xs\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg !important;
  }

  .xs\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg !important;
  }

  .xs\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg !important;
  }

  .xs\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg !important;
  }

  .xs\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg !important;
  }

  .xs\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg !important;
  }

  .xs\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg !important;
  }

  .xs\:focus\:skew-x-0:focus {
    --transform-skew-x: 0 !important;
  }

  .xs\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg !important;
  }

  .xs\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg !important;
  }

  .xs\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg !important;
  }

  .xs\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg !important;
  }

  .xs\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg !important;
  }

  .xs\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg !important;
  }

  .xs\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg !important;
  }

  .xs\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg !important;
  }

  .xs\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg !important;
  }

  .xs\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg !important;
  }

  .xs\:focus\:skew-y-0:focus {
    --transform-skew-y: 0 !important;
  }

  .xs\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg !important;
  }

  .xs\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg !important;
  }

  .xs\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg !important;
  }

  .xs\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg !important;
  }

  .xs\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg !important;
  }

  .xs\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg !important;
  }

  .xs\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg !important;
  }

  .xs\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg !important;
  }

  .xs\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg !important;
  }

  .xs\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg !important;
  }

  .xs\:transition-none {
    transition-property: none !important;
  }

  .xs\:transition-all {
    transition-property: all !important;
  }

  .xs\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .xs\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .xs\:transition-opacity {
    transition-property: opacity !important;
  }

  .xs\:transition-shadow {
    transition-property: box-shadow !important;
  }

  .xs\:transition-transform {
    transition-property: transform !important;
  }

  .xs\:ease-linear {
    transition-timing-function: linear !important;
  }

  .xs\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .xs\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .xs\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .xs\:duration-75 {
    transition-duration: 75ms !important;
  }

  .xs\:duration-100 {
    transition-duration: 100ms !important;
  }

  .xs\:duration-150 {
    transition-duration: 150ms !important;
  }

  .xs\:duration-200 {
    transition-duration: 200ms !important;
  }

  .xs\:duration-300 {
    transition-duration: 300ms !important;
  }

  .xs\:duration-500 {
    transition-duration: 500ms !important;
  }

  .xs\:duration-700 {
    transition-duration: 700ms !important;
  }

  .xs\:duration-1000 {
    transition-duration: 1000ms !important;
  }

  .xs\:delay-75 {
    transition-delay: 75ms !important;
  }

  .xs\:delay-100 {
    transition-delay: 100ms !important;
  }

  .xs\:delay-150 {
    transition-delay: 150ms !important;
  }

  .xs\:delay-200 {
    transition-delay: 200ms !important;
  }

  .xs\:delay-300 {
    transition-delay: 300ms !important;
  }

  .xs\:delay-500 {
    transition-delay: 500ms !important;
  }

  .xs\:delay-700 {
    transition-delay: 700ms !important;
  }

  .xs\:delay-1000 {
    transition-delay: 1000ms !important;
  }

  .xs\:animate-none {
    animation: none !important;
  }

  .xs\:animate-spin {
    animation: spin 1s linear infinite !important;
  }

  .xs\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .xs\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .xs\:animate-bounce {
    animation: bounce 1s infinite !important;
  }

  .xs\:link {
    color: var(--primary);
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

@media (min-width: 576px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 480px) {
    .sm\:container {
      max-width: 480px;
    }
  }

  @media (min-width: 576px) {
    .sm\:container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .sm\:container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .sm\:container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .sm\:container {
      max-width: 1600px;
    }
  }

  .sm\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .sm\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .sm\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .sm\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .sm\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .sm\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .sm\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .sm\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
  }

  .sm\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--primary) !important;
  }

  .sm\:divide-accent-1 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-accent-2 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
  }

  .sm\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid !important;
  }

  .sm\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed !important;
  }

  .sm\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted !important;
  }

  .sm\:divide-double > :not(template) ~ :not(template) {
    border-style: double !important;
  }

  .sm\:divide-none > :not(template) ~ :not(template) {
    border-style: none !important;
  }

  .sm\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .sm\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .sm\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .sm\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .sm\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .sm\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .sm\:bg-fixed {
    background-attachment: fixed !important;
  }

  .sm\:bg-local {
    background-attachment: local !important;
  }

  .sm\:bg-scroll {
    background-attachment: scroll !important;
  }

  .sm\:bg-clip-border {
    background-clip: border-box !important;
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box !important;
  }

  .sm\:bg-clip-content {
    background-clip: content-box !important;
  }

  .sm\:bg-clip-text {
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .sm\:bg-transparent {
    background-color: transparent !important;
  }

  .sm\:bg-current {
    background-color: currentColor !important;
  }

  .sm\:bg-black {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .sm\:bg-white {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-100 {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-200 {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-300 {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-400 {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-500 {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-600 {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-700 {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-800 {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .sm\:bg-gray-900 {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-300 {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-400 {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-500 {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-600 {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-700 {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-800 {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .sm\:bg-red-900 {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-100 {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-200 {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-300 {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-400 {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-500 {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-600 {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-700 {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-800 {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .sm\:bg-orange-900 {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-100 {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-200 {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-300 {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-400 {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-500 {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-600 {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-700 {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-800 {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .sm\:bg-yellow-900 {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-100 {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-200 {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-300 {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-400 {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-500 {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-600 {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-700 {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-800 {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .sm\:bg-green-900 {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-100 {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-200 {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-300 {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-400 {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-500 {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-600 {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-700 {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-800 {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .sm\:bg-teal-900 {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-200 {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-300 {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-400 {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-500 {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-600 {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-700 {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-800 {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .sm\:bg-blue-900 {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-200 {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-300 {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-400 {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-500 {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-600 {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-700 {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-800 {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .sm\:bg-indigo-900 {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-100 {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-200 {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-300 {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-400 {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-500 {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-600 {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-700 {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-800 {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .sm\:bg-purple-900 {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-300 {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-400 {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-500 {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-600 {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-700 {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-800 {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .sm\:bg-pink-900 {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .sm\:bg-primary {
    background-color: var(--primary) !important;
  }

  .sm\:bg-accent-1 {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .sm\:bg-accent-2 {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: transparent !important;
  }

  .sm\:hover\:bg-current:hover {
    background-color: currentColor !important;
  }

  .sm\:hover\:bg-black:hover {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-white:hover {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-200:hover {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-300:hover {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-400:hover {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-500:hover {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-600:hover {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-900:hover {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-300:hover {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-400:hover {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-500:hover {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-600:hover {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-900:hover {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-200:hover {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-600:hover {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-orange-900:hover {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-300:hover {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-400:hover {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-500:hover {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-600:hover {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-800:hover {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-900:hover {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-100:hover {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-200:hover {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-300:hover {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-400:hover {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-500:hover {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-600:hover {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-800:hover {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-teal-900:hover {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-200:hover {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-300:hover {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-400:hover {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-500:hover {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-600:hover {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-900:hover {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-100:hover {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-200:hover {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-300:hover {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-400:hover {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-500:hover {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-600:hover {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-700:hover {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-800:hover {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-900:hover {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-800:hover {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-900:hover {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-primary:hover {
    background-color: var(--primary) !important;
  }

  .sm\:hover\:bg-accent-1:hover {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .sm\:hover\:bg-accent-2:hover {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: transparent !important;
  }

  .sm\:focus\:bg-current:focus {
    background-color: currentColor !important;
  }

  .sm\:focus\:bg-black:focus {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-white:focus {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-200:focus {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-300:focus {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-400:focus {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-500:focus {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-600:focus {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-900:focus {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-300:focus {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-400:focus {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-500:focus {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-600:focus {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-900:focus {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-200:focus {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-600:focus {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-orange-900:focus {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-300:focus {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-400:focus {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-500:focus {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-600:focus {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-800:focus {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-900:focus {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-100:focus {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-200:focus {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-300:focus {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-400:focus {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-500:focus {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-600:focus {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-800:focus {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-teal-900:focus {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-200:focus {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-300:focus {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-400:focus {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-500:focus {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-600:focus {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-900:focus {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-100:focus {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-200:focus {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-300:focus {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-400:focus {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-500:focus {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-600:focus {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-700:focus {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-800:focus {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-900:focus {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-800:focus {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-900:focus {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-primary:focus {
    background-color: var(--primary) !important;
  }

  .sm\:focus\:bg-accent-1:focus {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .sm\:focus\:bg-accent-2:focus {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .sm\:bg-none {
    background-image: none !important;
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
  }

  .sm\:from-transparent {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:from-current {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:from-black {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:from-white {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:from-gray-100 {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .sm\:from-gray-200 {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .sm\:from-gray-300 {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .sm\:from-gray-400 {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .sm\:from-gray-500 {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .sm\:from-gray-600 {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .sm\:from-gray-700 {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .sm\:from-gray-800 {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .sm\:from-gray-900 {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .sm\:from-red-100 {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .sm\:from-red-200 {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .sm\:from-red-300 {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .sm\:from-red-400 {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .sm\:from-red-500 {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .sm\:from-red-600 {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .sm\:from-red-700 {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .sm\:from-red-800 {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .sm\:from-red-900 {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .sm\:from-orange-100 {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .sm\:from-orange-200 {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .sm\:from-orange-300 {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .sm\:from-orange-400 {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .sm\:from-orange-500 {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .sm\:from-orange-600 {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .sm\:from-orange-700 {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .sm\:from-orange-800 {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .sm\:from-orange-900 {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .sm\:from-yellow-100 {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .sm\:from-yellow-200 {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .sm\:from-yellow-300 {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .sm\:from-yellow-400 {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .sm\:from-yellow-500 {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .sm\:from-yellow-600 {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .sm\:from-yellow-700 {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .sm\:from-yellow-800 {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .sm\:from-yellow-900 {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .sm\:from-green-100 {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .sm\:from-green-200 {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .sm\:from-green-300 {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .sm\:from-green-400 {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .sm\:from-green-500 {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .sm\:from-green-600 {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .sm\:from-green-700 {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .sm\:from-green-800 {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .sm\:from-green-900 {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .sm\:from-teal-100 {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .sm\:from-teal-200 {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .sm\:from-teal-300 {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .sm\:from-teal-400 {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .sm\:from-teal-500 {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .sm\:from-teal-600 {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .sm\:from-teal-700 {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .sm\:from-teal-800 {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .sm\:from-teal-900 {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .sm\:from-blue-100 {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .sm\:from-blue-200 {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .sm\:from-blue-300 {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .sm\:from-blue-400 {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .sm\:from-blue-500 {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .sm\:from-blue-600 {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .sm\:from-blue-700 {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .sm\:from-blue-800 {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .sm\:from-blue-900 {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .sm\:from-indigo-100 {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .sm\:from-indigo-200 {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .sm\:from-indigo-300 {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .sm\:from-indigo-400 {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .sm\:from-indigo-500 {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .sm\:from-indigo-600 {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .sm\:from-indigo-700 {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .sm\:from-indigo-800 {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .sm\:from-indigo-900 {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .sm\:from-purple-100 {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .sm\:from-purple-200 {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .sm\:from-purple-300 {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .sm\:from-purple-400 {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .sm\:from-purple-500 {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .sm\:from-purple-600 {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .sm\:from-purple-700 {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .sm\:from-purple-800 {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .sm\:from-purple-900 {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .sm\:from-pink-100 {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .sm\:from-pink-200 {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .sm\:from-pink-300 {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .sm\:from-pink-400 {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .sm\:from-pink-500 {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .sm\:from-pink-600 {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .sm\:from-pink-700 {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .sm\:from-pink-800 {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .sm\:from-pink-900 {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .sm\:from-primary {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:from-accent-1 {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .sm\:from-accent-2 {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .sm\:via-transparent {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:via-current {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:via-black {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:via-white {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:via-gray-100 {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .sm\:via-gray-200 {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .sm\:via-gray-300 {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .sm\:via-gray-400 {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .sm\:via-gray-500 {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .sm\:via-gray-600 {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .sm\:via-gray-700 {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .sm\:via-gray-800 {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .sm\:via-gray-900 {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .sm\:via-red-100 {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .sm\:via-red-200 {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .sm\:via-red-300 {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .sm\:via-red-400 {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .sm\:via-red-500 {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .sm\:via-red-600 {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .sm\:via-red-700 {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .sm\:via-red-800 {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .sm\:via-red-900 {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .sm\:via-orange-100 {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .sm\:via-orange-200 {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .sm\:via-orange-300 {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .sm\:via-orange-400 {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .sm\:via-orange-500 {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .sm\:via-orange-600 {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .sm\:via-orange-700 {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .sm\:via-orange-800 {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .sm\:via-orange-900 {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .sm\:via-yellow-100 {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .sm\:via-yellow-200 {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .sm\:via-yellow-300 {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .sm\:via-yellow-400 {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .sm\:via-yellow-500 {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .sm\:via-yellow-600 {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .sm\:via-yellow-700 {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .sm\:via-yellow-800 {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .sm\:via-yellow-900 {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .sm\:via-green-100 {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .sm\:via-green-200 {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .sm\:via-green-300 {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .sm\:via-green-400 {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .sm\:via-green-500 {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .sm\:via-green-600 {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .sm\:via-green-700 {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .sm\:via-green-800 {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .sm\:via-green-900 {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .sm\:via-teal-100 {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .sm\:via-teal-200 {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .sm\:via-teal-300 {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .sm\:via-teal-400 {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .sm\:via-teal-500 {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .sm\:via-teal-600 {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .sm\:via-teal-700 {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .sm\:via-teal-800 {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .sm\:via-teal-900 {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .sm\:via-blue-100 {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .sm\:via-blue-200 {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .sm\:via-blue-300 {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .sm\:via-blue-400 {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .sm\:via-blue-500 {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .sm\:via-blue-600 {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .sm\:via-blue-700 {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .sm\:via-blue-800 {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .sm\:via-blue-900 {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .sm\:via-indigo-100 {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .sm\:via-indigo-200 {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .sm\:via-indigo-300 {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .sm\:via-indigo-400 {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .sm\:via-indigo-500 {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .sm\:via-indigo-600 {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .sm\:via-indigo-700 {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .sm\:via-indigo-800 {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .sm\:via-indigo-900 {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .sm\:via-purple-100 {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .sm\:via-purple-200 {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .sm\:via-purple-300 {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .sm\:via-purple-400 {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .sm\:via-purple-500 {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .sm\:via-purple-600 {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .sm\:via-purple-700 {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .sm\:via-purple-800 {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .sm\:via-purple-900 {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .sm\:via-pink-100 {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .sm\:via-pink-200 {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .sm\:via-pink-300 {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .sm\:via-pink-400 {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .sm\:via-pink-500 {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .sm\:via-pink-600 {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .sm\:via-pink-700 {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .sm\:via-pink-800 {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .sm\:via-pink-900 {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .sm\:via-primary {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:via-accent-1 {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .sm\:via-accent-2 {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .sm\:to-transparent {
    --gradient-to-color: transparent !important;
  }

  .sm\:to-current {
    --gradient-to-color: currentColor !important;
  }

  .sm\:to-black {
    --gradient-to-color: #000 !important;
  }

  .sm\:to-white {
    --gradient-to-color: #fff !important;
  }

  .sm\:to-gray-100 {
    --gradient-to-color: #f7fafc !important;
  }

  .sm\:to-gray-200 {
    --gradient-to-color: #edf2f7 !important;
  }

  .sm\:to-gray-300 {
    --gradient-to-color: #e2e8f0 !important;
  }

  .sm\:to-gray-400 {
    --gradient-to-color: #cbd5e0 !important;
  }

  .sm\:to-gray-500 {
    --gradient-to-color: #a0aec0 !important;
  }

  .sm\:to-gray-600 {
    --gradient-to-color: #718096 !important;
  }

  .sm\:to-gray-700 {
    --gradient-to-color: #4a5568 !important;
  }

  .sm\:to-gray-800 {
    --gradient-to-color: #2d3748 !important;
  }

  .sm\:to-gray-900 {
    --gradient-to-color: #1a202c !important;
  }

  .sm\:to-red-100 {
    --gradient-to-color: #fff5f5 !important;
  }

  .sm\:to-red-200 {
    --gradient-to-color: #fed7d7 !important;
  }

  .sm\:to-red-300 {
    --gradient-to-color: #feb2b2 !important;
  }

  .sm\:to-red-400 {
    --gradient-to-color: #fc8181 !important;
  }

  .sm\:to-red-500 {
    --gradient-to-color: #f56565 !important;
  }

  .sm\:to-red-600 {
    --gradient-to-color: #e53e3e !important;
  }

  .sm\:to-red-700 {
    --gradient-to-color: #c53030 !important;
  }

  .sm\:to-red-800 {
    --gradient-to-color: #9b2c2c !important;
  }

  .sm\:to-red-900 {
    --gradient-to-color: #742a2a !important;
  }

  .sm\:to-orange-100 {
    --gradient-to-color: #fffaf0 !important;
  }

  .sm\:to-orange-200 {
    --gradient-to-color: #feebc8 !important;
  }

  .sm\:to-orange-300 {
    --gradient-to-color: #fbd38d !important;
  }

  .sm\:to-orange-400 {
    --gradient-to-color: #f6ad55 !important;
  }

  .sm\:to-orange-500 {
    --gradient-to-color: #ed8936 !important;
  }

  .sm\:to-orange-600 {
    --gradient-to-color: #dd6b20 !important;
  }

  .sm\:to-orange-700 {
    --gradient-to-color: #c05621 !important;
  }

  .sm\:to-orange-800 {
    --gradient-to-color: #9c4221 !important;
  }

  .sm\:to-orange-900 {
    --gradient-to-color: #7b341e !important;
  }

  .sm\:to-yellow-100 {
    --gradient-to-color: #fffff0 !important;
  }

  .sm\:to-yellow-200 {
    --gradient-to-color: #fefcbf !important;
  }

  .sm\:to-yellow-300 {
    --gradient-to-color: #faf089 !important;
  }

  .sm\:to-yellow-400 {
    --gradient-to-color: #f6e05e !important;
  }

  .sm\:to-yellow-500 {
    --gradient-to-color: #ecc94b !important;
  }

  .sm\:to-yellow-600 {
    --gradient-to-color: #d69e2e !important;
  }

  .sm\:to-yellow-700 {
    --gradient-to-color: #b7791f !important;
  }

  .sm\:to-yellow-800 {
    --gradient-to-color: #975a16 !important;
  }

  .sm\:to-yellow-900 {
    --gradient-to-color: #744210 !important;
  }

  .sm\:to-green-100 {
    --gradient-to-color: #f0fff4 !important;
  }

  .sm\:to-green-200 {
    --gradient-to-color: #c6f6d5 !important;
  }

  .sm\:to-green-300 {
    --gradient-to-color: #9ae6b4 !important;
  }

  .sm\:to-green-400 {
    --gradient-to-color: #68d391 !important;
  }

  .sm\:to-green-500 {
    --gradient-to-color: #48bb78 !important;
  }

  .sm\:to-green-600 {
    --gradient-to-color: #38a169 !important;
  }

  .sm\:to-green-700 {
    --gradient-to-color: #2f855a !important;
  }

  .sm\:to-green-800 {
    --gradient-to-color: #276749 !important;
  }

  .sm\:to-green-900 {
    --gradient-to-color: #22543d !important;
  }

  .sm\:to-teal-100 {
    --gradient-to-color: #e6fffa !important;
  }

  .sm\:to-teal-200 {
    --gradient-to-color: #b2f5ea !important;
  }

  .sm\:to-teal-300 {
    --gradient-to-color: #81e6d9 !important;
  }

  .sm\:to-teal-400 {
    --gradient-to-color: #4fd1c5 !important;
  }

  .sm\:to-teal-500 {
    --gradient-to-color: #38b2ac !important;
  }

  .sm\:to-teal-600 {
    --gradient-to-color: #319795 !important;
  }

  .sm\:to-teal-700 {
    --gradient-to-color: #2c7a7b !important;
  }

  .sm\:to-teal-800 {
    --gradient-to-color: #285e61 !important;
  }

  .sm\:to-teal-900 {
    --gradient-to-color: #234e52 !important;
  }

  .sm\:to-blue-100 {
    --gradient-to-color: #ebf8ff !important;
  }

  .sm\:to-blue-200 {
    --gradient-to-color: #bee3f8 !important;
  }

  .sm\:to-blue-300 {
    --gradient-to-color: #90cdf4 !important;
  }

  .sm\:to-blue-400 {
    --gradient-to-color: #63b3ed !important;
  }

  .sm\:to-blue-500 {
    --gradient-to-color: #4299e1 !important;
  }

  .sm\:to-blue-600 {
    --gradient-to-color: #3182ce !important;
  }

  .sm\:to-blue-700 {
    --gradient-to-color: #2b6cb0 !important;
  }

  .sm\:to-blue-800 {
    --gradient-to-color: #2c5282 !important;
  }

  .sm\:to-blue-900 {
    --gradient-to-color: #2a4365 !important;
  }

  .sm\:to-indigo-100 {
    --gradient-to-color: #ebf4ff !important;
  }

  .sm\:to-indigo-200 {
    --gradient-to-color: #c3dafe !important;
  }

  .sm\:to-indigo-300 {
    --gradient-to-color: #a3bffa !important;
  }

  .sm\:to-indigo-400 {
    --gradient-to-color: #7f9cf5 !important;
  }

  .sm\:to-indigo-500 {
    --gradient-to-color: #667eea !important;
  }

  .sm\:to-indigo-600 {
    --gradient-to-color: #5a67d8 !important;
  }

  .sm\:to-indigo-700 {
    --gradient-to-color: #4c51bf !important;
  }

  .sm\:to-indigo-800 {
    --gradient-to-color: #434190 !important;
  }

  .sm\:to-indigo-900 {
    --gradient-to-color: #3c366b !important;
  }

  .sm\:to-purple-100 {
    --gradient-to-color: #faf5ff !important;
  }

  .sm\:to-purple-200 {
    --gradient-to-color: #e9d8fd !important;
  }

  .sm\:to-purple-300 {
    --gradient-to-color: #d6bcfa !important;
  }

  .sm\:to-purple-400 {
    --gradient-to-color: #b794f4 !important;
  }

  .sm\:to-purple-500 {
    --gradient-to-color: #9f7aea !important;
  }

  .sm\:to-purple-600 {
    --gradient-to-color: #805ad5 !important;
  }

  .sm\:to-purple-700 {
    --gradient-to-color: #6b46c1 !important;
  }

  .sm\:to-purple-800 {
    --gradient-to-color: #553c9a !important;
  }

  .sm\:to-purple-900 {
    --gradient-to-color: #44337a !important;
  }

  .sm\:to-pink-100 {
    --gradient-to-color: #fff5f7 !important;
  }

  .sm\:to-pink-200 {
    --gradient-to-color: #fed7e2 !important;
  }

  .sm\:to-pink-300 {
    --gradient-to-color: #fbb6ce !important;
  }

  .sm\:to-pink-400 {
    --gradient-to-color: #f687b3 !important;
  }

  .sm\:to-pink-500 {
    --gradient-to-color: #ed64a6 !important;
  }

  .sm\:to-pink-600 {
    --gradient-to-color: #d53f8c !important;
  }

  .sm\:to-pink-700 {
    --gradient-to-color: #b83280 !important;
  }

  .sm\:to-pink-800 {
    --gradient-to-color: #97266d !important;
  }

  .sm\:to-pink-900 {
    --gradient-to-color: #702459 !important;
  }

  .sm\:to-primary {
    --gradient-to-color: var(--primary) !important;
  }

  .sm\:to-accent-1 {
    --gradient-to-color: #fafafa !important;
  }

  .sm\:to-accent-2 {
    --gradient-to-color: #eaeaea !important;
  }

  .sm\:hover\:from-transparent:hover {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:from-current:hover {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:from-black:hover {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:from-white:hover {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .sm\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .sm\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .sm\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .sm\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .sm\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .sm\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .sm\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .sm\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .sm\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .sm\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .sm\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .sm\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .sm\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .sm\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .sm\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .sm\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .sm\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .sm\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .sm\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .sm\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .sm\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .sm\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .sm\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .sm\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .sm\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .sm\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .sm\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .sm\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .sm\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .sm\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .sm\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .sm\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .sm\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .sm\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .sm\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .sm\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .sm\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .sm\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .sm\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .sm\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .sm\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .sm\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .sm\:hover\:from-green-800:hover {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .sm\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .sm\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .sm\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .sm\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .sm\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .sm\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .sm\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .sm\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .sm\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .sm\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .sm\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .sm\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .sm\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .sm\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .sm\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .sm\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .sm\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .sm\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .sm\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .sm\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .sm\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .sm\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .sm\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .sm\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .sm\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .sm\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .sm\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .sm\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .sm\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .sm\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .sm\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .sm\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .sm\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .sm\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .sm\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .sm\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .sm\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .sm\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .sm\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .sm\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .sm\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .sm\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .sm\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .sm\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .sm\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .sm\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .sm\:hover\:from-primary:hover {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:from-accent-1:hover {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .sm\:hover\:from-accent-2:hover {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .sm\:hover\:via-transparent:hover {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:via-current:hover {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:via-black:hover {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:via-white:hover {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .sm\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .sm\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .sm\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .sm\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .sm\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .sm\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .sm\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .sm\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .sm\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .sm\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .sm\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .sm\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .sm\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .sm\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .sm\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .sm\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .sm\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .sm\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .sm\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .sm\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .sm\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .sm\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .sm\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .sm\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .sm\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .sm\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .sm\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .sm\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .sm\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .sm\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .sm\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .sm\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .sm\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .sm\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .sm\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .sm\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .sm\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .sm\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .sm\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .sm\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .sm\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .sm\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .sm\:hover\:via-green-800:hover {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .sm\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .sm\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .sm\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .sm\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .sm\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .sm\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .sm\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .sm\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .sm\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .sm\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .sm\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .sm\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .sm\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .sm\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .sm\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .sm\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .sm\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .sm\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .sm\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .sm\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .sm\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .sm\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .sm\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .sm\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .sm\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .sm\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .sm\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .sm\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .sm\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .sm\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .sm\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .sm\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .sm\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .sm\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .sm\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .sm\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .sm\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .sm\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .sm\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .sm\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .sm\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .sm\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .sm\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .sm\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .sm\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .sm\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .sm\:hover\:via-primary:hover {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:via-accent-1:hover {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .sm\:hover\:via-accent-2:hover {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .sm\:hover\:to-transparent:hover {
    --gradient-to-color: transparent !important;
  }

  .sm\:hover\:to-current:hover {
    --gradient-to-color: currentColor !important;
  }

  .sm\:hover\:to-black:hover {
    --gradient-to-color: #000 !important;
  }

  .sm\:hover\:to-white:hover {
    --gradient-to-color: #fff !important;
  }

  .sm\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc !important;
  }

  .sm\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7 !important;
  }

  .sm\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0 !important;
  }

  .sm\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0 !important;
  }

  .sm\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0 !important;
  }

  .sm\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096 !important;
  }

  .sm\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568 !important;
  }

  .sm\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748 !important;
  }

  .sm\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c !important;
  }

  .sm\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5 !important;
  }

  .sm\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7 !important;
  }

  .sm\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2 !important;
  }

  .sm\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181 !important;
  }

  .sm\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565 !important;
  }

  .sm\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e !important;
  }

  .sm\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030 !important;
  }

  .sm\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c !important;
  }

  .sm\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a !important;
  }

  .sm\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0 !important;
  }

  .sm\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8 !important;
  }

  .sm\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d !important;
  }

  .sm\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55 !important;
  }

  .sm\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936 !important;
  }

  .sm\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20 !important;
  }

  .sm\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621 !important;
  }

  .sm\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221 !important;
  }

  .sm\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e !important;
  }

  .sm\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0 !important;
  }

  .sm\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf !important;
  }

  .sm\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089 !important;
  }

  .sm\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e !important;
  }

  .sm\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b !important;
  }

  .sm\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e !important;
  }

  .sm\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f !important;
  }

  .sm\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16 !important;
  }

  .sm\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210 !important;
  }

  .sm\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4 !important;
  }

  .sm\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5 !important;
  }

  .sm\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4 !important;
  }

  .sm\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391 !important;
  }

  .sm\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78 !important;
  }

  .sm\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169 !important;
  }

  .sm\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a !important;
  }

  .sm\:hover\:to-green-800:hover {
    --gradient-to-color: #276749 !important;
  }

  .sm\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d !important;
  }

  .sm\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa !important;
  }

  .sm\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea !important;
  }

  .sm\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9 !important;
  }

  .sm\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5 !important;
  }

  .sm\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac !important;
  }

  .sm\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795 !important;
  }

  .sm\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b !important;
  }

  .sm\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61 !important;
  }

  .sm\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52 !important;
  }

  .sm\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff !important;
  }

  .sm\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8 !important;
  }

  .sm\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4 !important;
  }

  .sm\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed !important;
  }

  .sm\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1 !important;
  }

  .sm\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce !important;
  }

  .sm\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0 !important;
  }

  .sm\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282 !important;
  }

  .sm\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365 !important;
  }

  .sm\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff !important;
  }

  .sm\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe !important;
  }

  .sm\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa !important;
  }

  .sm\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5 !important;
  }

  .sm\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea !important;
  }

  .sm\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8 !important;
  }

  .sm\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf !important;
  }

  .sm\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190 !important;
  }

  .sm\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b !important;
  }

  .sm\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff !important;
  }

  .sm\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd !important;
  }

  .sm\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa !important;
  }

  .sm\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4 !important;
  }

  .sm\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea !important;
  }

  .sm\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5 !important;
  }

  .sm\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1 !important;
  }

  .sm\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a !important;
  }

  .sm\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a !important;
  }

  .sm\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7 !important;
  }

  .sm\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2 !important;
  }

  .sm\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce !important;
  }

  .sm\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3 !important;
  }

  .sm\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6 !important;
  }

  .sm\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c !important;
  }

  .sm\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280 !important;
  }

  .sm\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d !important;
  }

  .sm\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459 !important;
  }

  .sm\:hover\:to-primary:hover {
    --gradient-to-color: var(--primary) !important;
  }

  .sm\:hover\:to-accent-1:hover {
    --gradient-to-color: #fafafa !important;
  }

  .sm\:hover\:to-accent-2:hover {
    --gradient-to-color: #eaeaea !important;
  }

  .sm\:focus\:from-transparent:focus {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:from-current:focus {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:from-black:focus {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:from-white:focus {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .sm\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .sm\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .sm\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .sm\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .sm\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .sm\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .sm\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .sm\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .sm\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .sm\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .sm\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .sm\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .sm\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .sm\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .sm\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .sm\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .sm\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .sm\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .sm\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .sm\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .sm\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .sm\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .sm\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .sm\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .sm\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .sm\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .sm\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .sm\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .sm\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .sm\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .sm\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .sm\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .sm\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .sm\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .sm\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .sm\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .sm\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .sm\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .sm\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .sm\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .sm\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .sm\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .sm\:focus\:from-green-800:focus {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .sm\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .sm\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .sm\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .sm\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .sm\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .sm\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .sm\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .sm\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .sm\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .sm\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .sm\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .sm\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .sm\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .sm\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .sm\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .sm\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .sm\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .sm\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .sm\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .sm\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .sm\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .sm\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .sm\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .sm\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .sm\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .sm\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .sm\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .sm\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .sm\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .sm\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .sm\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .sm\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .sm\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .sm\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .sm\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .sm\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .sm\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .sm\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .sm\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .sm\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .sm\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .sm\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .sm\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .sm\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .sm\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .sm\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .sm\:focus\:from-primary:focus {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:from-accent-1:focus {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .sm\:focus\:from-accent-2:focus {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .sm\:focus\:via-transparent:focus {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:via-current:focus {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:via-black:focus {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:via-white:focus {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .sm\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .sm\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .sm\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .sm\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .sm\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .sm\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .sm\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .sm\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .sm\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .sm\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .sm\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .sm\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .sm\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .sm\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .sm\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .sm\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .sm\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .sm\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .sm\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .sm\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .sm\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .sm\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .sm\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .sm\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .sm\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .sm\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .sm\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .sm\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .sm\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .sm\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .sm\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .sm\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .sm\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .sm\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .sm\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .sm\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .sm\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .sm\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .sm\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .sm\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .sm\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .sm\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .sm\:focus\:via-green-800:focus {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .sm\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .sm\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .sm\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .sm\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .sm\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .sm\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .sm\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .sm\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .sm\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .sm\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .sm\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .sm\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .sm\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .sm\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .sm\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .sm\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .sm\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .sm\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .sm\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .sm\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .sm\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .sm\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .sm\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .sm\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .sm\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .sm\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .sm\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .sm\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .sm\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .sm\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .sm\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .sm\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .sm\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .sm\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .sm\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .sm\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .sm\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .sm\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .sm\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .sm\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .sm\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .sm\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .sm\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .sm\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .sm\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .sm\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .sm\:focus\:via-primary:focus {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:via-accent-1:focus {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .sm\:focus\:via-accent-2:focus {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .sm\:focus\:to-transparent:focus {
    --gradient-to-color: transparent !important;
  }

  .sm\:focus\:to-current:focus {
    --gradient-to-color: currentColor !important;
  }

  .sm\:focus\:to-black:focus {
    --gradient-to-color: #000 !important;
  }

  .sm\:focus\:to-white:focus {
    --gradient-to-color: #fff !important;
  }

  .sm\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc !important;
  }

  .sm\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7 !important;
  }

  .sm\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0 !important;
  }

  .sm\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0 !important;
  }

  .sm\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0 !important;
  }

  .sm\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096 !important;
  }

  .sm\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568 !important;
  }

  .sm\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748 !important;
  }

  .sm\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c !important;
  }

  .sm\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5 !important;
  }

  .sm\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7 !important;
  }

  .sm\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2 !important;
  }

  .sm\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181 !important;
  }

  .sm\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565 !important;
  }

  .sm\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e !important;
  }

  .sm\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030 !important;
  }

  .sm\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c !important;
  }

  .sm\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a !important;
  }

  .sm\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0 !important;
  }

  .sm\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8 !important;
  }

  .sm\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d !important;
  }

  .sm\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55 !important;
  }

  .sm\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936 !important;
  }

  .sm\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20 !important;
  }

  .sm\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621 !important;
  }

  .sm\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221 !important;
  }

  .sm\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e !important;
  }

  .sm\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0 !important;
  }

  .sm\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf !important;
  }

  .sm\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089 !important;
  }

  .sm\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e !important;
  }

  .sm\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b !important;
  }

  .sm\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e !important;
  }

  .sm\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f !important;
  }

  .sm\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16 !important;
  }

  .sm\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210 !important;
  }

  .sm\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4 !important;
  }

  .sm\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5 !important;
  }

  .sm\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4 !important;
  }

  .sm\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391 !important;
  }

  .sm\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78 !important;
  }

  .sm\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169 !important;
  }

  .sm\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a !important;
  }

  .sm\:focus\:to-green-800:focus {
    --gradient-to-color: #276749 !important;
  }

  .sm\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d !important;
  }

  .sm\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa !important;
  }

  .sm\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea !important;
  }

  .sm\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9 !important;
  }

  .sm\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5 !important;
  }

  .sm\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac !important;
  }

  .sm\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795 !important;
  }

  .sm\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b !important;
  }

  .sm\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61 !important;
  }

  .sm\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52 !important;
  }

  .sm\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff !important;
  }

  .sm\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8 !important;
  }

  .sm\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4 !important;
  }

  .sm\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed !important;
  }

  .sm\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1 !important;
  }

  .sm\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce !important;
  }

  .sm\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0 !important;
  }

  .sm\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282 !important;
  }

  .sm\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365 !important;
  }

  .sm\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff !important;
  }

  .sm\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe !important;
  }

  .sm\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa !important;
  }

  .sm\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5 !important;
  }

  .sm\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea !important;
  }

  .sm\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8 !important;
  }

  .sm\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf !important;
  }

  .sm\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190 !important;
  }

  .sm\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b !important;
  }

  .sm\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff !important;
  }

  .sm\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd !important;
  }

  .sm\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa !important;
  }

  .sm\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4 !important;
  }

  .sm\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea !important;
  }

  .sm\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5 !important;
  }

  .sm\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1 !important;
  }

  .sm\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a !important;
  }

  .sm\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a !important;
  }

  .sm\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7 !important;
  }

  .sm\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2 !important;
  }

  .sm\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce !important;
  }

  .sm\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3 !important;
  }

  .sm\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6 !important;
  }

  .sm\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c !important;
  }

  .sm\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280 !important;
  }

  .sm\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d !important;
  }

  .sm\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459 !important;
  }

  .sm\:focus\:to-primary:focus {
    --gradient-to-color: var(--primary) !important;
  }

  .sm\:focus\:to-accent-1:focus {
    --gradient-to-color: #fafafa !important;
  }

  .sm\:focus\:to-accent-2:focus {
    --gradient-to-color: #eaeaea !important;
  }

  .sm\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .sm\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .sm\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .sm\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .sm\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .sm\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .sm\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .sm\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .sm\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .sm\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .sm\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .sm\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .sm\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .sm\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .sm\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .sm\:bg-bottom {
    background-position: bottom !important;
  }

  .sm\:bg-center {
    background-position: center !important;
  }

  .sm\:bg-left {
    background-position: left !important;
  }

  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .sm\:bg-left-top {
    background-position: left top !important;
  }

  .sm\:bg-right {
    background-position: right !important;
  }

  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .sm\:bg-right-top {
    background-position: right top !important;
  }

  .sm\:bg-top {
    background-position: top !important;
  }

  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }

  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }

  .sm\:bg-auto {
    background-size: auto !important;
  }

  .sm\:bg-cover {
    background-size: cover !important;
  }

  .sm\:bg-contain {
    background-size: contain !important;
  }

  .sm\:border-collapse {
    border-collapse: collapse !important;
  }

  .sm\:border-separate {
    border-collapse: separate !important;
  }

  .sm\:border-transparent {
    border-color: transparent !important;
  }

  .sm\:border-current {
    border-color: currentColor !important;
  }

  .sm\:border-black {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .sm\:border-white {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .sm\:border-gray-100 {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .sm\:border-gray-200 {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .sm\:border-gray-300 {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .sm\:border-gray-400 {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .sm\:border-gray-500 {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .sm\:border-gray-600 {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .sm\:border-gray-700 {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .sm\:border-gray-800 {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .sm\:border-gray-900 {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .sm\:border-red-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .sm\:border-red-200 {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .sm\:border-red-300 {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .sm\:border-red-400 {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .sm\:border-red-500 {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .sm\:border-red-600 {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .sm\:border-red-700 {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .sm\:border-red-800 {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .sm\:border-red-900 {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .sm\:border-orange-100 {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .sm\:border-orange-200 {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .sm\:border-orange-300 {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .sm\:border-orange-400 {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .sm\:border-orange-500 {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .sm\:border-orange-600 {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .sm\:border-orange-700 {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .sm\:border-orange-800 {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .sm\:border-orange-900 {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-100 {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-200 {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-300 {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-400 {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-500 {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-600 {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-700 {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-800 {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .sm\:border-yellow-900 {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .sm\:border-green-100 {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .sm\:border-green-200 {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .sm\:border-green-300 {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .sm\:border-green-400 {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .sm\:border-green-500 {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .sm\:border-green-600 {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .sm\:border-green-700 {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .sm\:border-green-800 {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .sm\:border-green-900 {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .sm\:border-teal-100 {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .sm\:border-teal-200 {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .sm\:border-teal-300 {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .sm\:border-teal-400 {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .sm\:border-teal-500 {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .sm\:border-teal-600 {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .sm\:border-teal-700 {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .sm\:border-teal-800 {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .sm\:border-teal-900 {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .sm\:border-blue-100 {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .sm\:border-blue-200 {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .sm\:border-blue-300 {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .sm\:border-blue-400 {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .sm\:border-blue-500 {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .sm\:border-blue-600 {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .sm\:border-blue-700 {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .sm\:border-blue-800 {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .sm\:border-blue-900 {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-100 {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-200 {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-300 {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-400 {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-500 {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-600 {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-700 {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-800 {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .sm\:border-indigo-900 {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .sm\:border-purple-100 {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .sm\:border-purple-200 {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .sm\:border-purple-300 {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .sm\:border-purple-400 {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .sm\:border-purple-500 {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .sm\:border-purple-600 {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .sm\:border-purple-700 {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .sm\:border-purple-800 {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .sm\:border-purple-900 {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .sm\:border-pink-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .sm\:border-pink-200 {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .sm\:border-pink-300 {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .sm\:border-pink-400 {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .sm\:border-pink-500 {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .sm\:border-pink-600 {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .sm\:border-pink-700 {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .sm\:border-pink-800 {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .sm\:border-pink-900 {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .sm\:border-primary {
    border-color: var(--primary) !important;
  }

  .sm\:border-accent-1 {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .sm\:border-accent-2 {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  .sm\:hover\:border-current:hover {
    border-color: currentColor !important;
  }

  .sm\:hover\:border-black:hover {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-white:hover {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-100:hover {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-200:hover {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-300:hover {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-400:hover {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-500:hover {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-600:hover {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-700:hover {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-800:hover {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-gray-900:hover {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-300:hover {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-400:hover {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-500:hover {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-600:hover {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-700:hover {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-800:hover {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-red-900:hover {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-200:hover {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-600:hover {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-700:hover {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-800:hover {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-orange-900:hover {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-200:hover {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-300:hover {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-500:hover {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-600:hover {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-700:hover {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-800:hover {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-900:hover {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-100:hover {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-200:hover {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-300:hover {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-400:hover {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-500:hover {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-600:hover {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-700:hover {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-800:hover {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-green-900:hover {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-100:hover {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-200:hover {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-300:hover {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-400:hover {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-500:hover {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-600:hover {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-700:hover {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-800:hover {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-teal-900:hover {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-200:hover {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-300:hover {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-400:hover {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-500:hover {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-600:hover {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-700:hover {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-800:hover {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-blue-900:hover {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-200:hover {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-300:hover {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-400:hover {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-500:hover {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-600:hover {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-700:hover {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-800:hover {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-900:hover {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-100:hover {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-200:hover {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-300:hover {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-400:hover {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-500:hover {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-600:hover {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-700:hover {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-800:hover {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-purple-900:hover {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-400:hover {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-600:hover {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-700:hover {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-800:hover {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-pink-900:hover {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-primary:hover {
    border-color: var(--primary) !important;
  }

  .sm\:hover\:border-accent-1:hover {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .sm\:hover\:border-accent-2:hover {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-transparent:focus {
    border-color: transparent !important;
  }

  .sm\:focus\:border-current:focus {
    border-color: currentColor !important;
  }

  .sm\:focus\:border-black:focus {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-white:focus {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-100:focus {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-200:focus {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-300:focus {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-400:focus {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-500:focus {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-600:focus {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-700:focus {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-800:focus {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-gray-900:focus {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-300:focus {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-400:focus {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-500:focus {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-600:focus {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-700:focus {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-800:focus {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-red-900:focus {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-200:focus {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-600:focus {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-700:focus {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-800:focus {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-orange-900:focus {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-200:focus {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-300:focus {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-500:focus {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-600:focus {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-700:focus {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-800:focus {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-900:focus {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-100:focus {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-200:focus {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-300:focus {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-400:focus {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-500:focus {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-600:focus {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-700:focus {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-800:focus {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-green-900:focus {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-100:focus {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-200:focus {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-300:focus {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-400:focus {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-500:focus {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-600:focus {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-700:focus {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-800:focus {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-teal-900:focus {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-200:focus {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-300:focus {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-400:focus {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-500:focus {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-600:focus {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-700:focus {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-800:focus {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-blue-900:focus {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-200:focus {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-300:focus {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-400:focus {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-500:focus {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-600:focus {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-700:focus {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-800:focus {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-900:focus {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-100:focus {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-200:focus {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-300:focus {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-400:focus {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-500:focus {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-600:focus {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-700:focus {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-800:focus {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-purple-900:focus {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-400:focus {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-600:focus {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-700:focus {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-800:focus {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-pink-900:focus {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-primary:focus {
    border-color: var(--primary) !important;
  }

  .sm\:focus\:border-accent-1:focus {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .sm\:focus\:border-accent-2:focus {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .sm\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .sm\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .sm\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .sm\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .sm\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .sm\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .sm\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .sm\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .sm\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .sm\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .sm\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .sm\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .sm\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .sm\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .sm\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .sm\:rounded-none {
    border-radius: 0 !important;
  }

  .sm\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .sm\:rounded {
    border-radius: 0.25rem !important;
  }

  .sm\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .sm\:rounded-xl {
    border-radius: 0.75rem !important;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem !important;
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem !important;
  }

  .sm\:rounded-full {
    border-radius: 9999px !important;
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .sm\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .sm\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: 0.75rem !important;
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: 0.75rem !important;
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem !important;
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:border-solid {
    border-style: solid !important;
  }

  .sm\:border-dashed {
    border-style: dashed !important;
  }

  .sm\:border-dotted {
    border-style: dotted !important;
  }

  .sm\:border-double {
    border-style: double !important;
  }

  .sm\:border-none {
    border-style: none !important;
  }

  .sm\:border-0 {
    border-width: 0 !important;
  }

  .sm\:border-2 {
    border-width: 2px !important;
  }

  .sm\:border-4 {
    border-width: 4px !important;
  }

  .sm\:border-8 {
    border-width: 8px !important;
  }

  .sm\:border {
    border-width: 1px !important;
  }

  .sm\:border-t-0 {
    border-top-width: 0 !important;
  }

  .sm\:border-r-0 {
    border-right-width: 0 !important;
  }

  .sm\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .sm\:border-l-0 {
    border-left-width: 0 !important;
  }

  .sm\:border-t-2 {
    border-top-width: 2px !important;
  }

  .sm\:border-r-2 {
    border-right-width: 2px !important;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .sm\:border-l-2 {
    border-left-width: 2px !important;
  }

  .sm\:border-t-4 {
    border-top-width: 4px !important;
  }

  .sm\:border-r-4 {
    border-right-width: 4px !important;
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .sm\:border-l-4 {
    border-left-width: 4px !important;
  }

  .sm\:border-t-8 {
    border-top-width: 8px !important;
  }

  .sm\:border-r-8 {
    border-right-width: 8px !important;
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .sm\:border-l-8 {
    border-left-width: 8px !important;
  }

  .sm\:border-t {
    border-top-width: 1px !important;
  }

  .sm\:border-r {
    border-right-width: 1px !important;
  }

  .sm\:border-b {
    border-bottom-width: 1px !important;
  }

  .sm\:border-l {
    border-left-width: 1px !important;
  }

  .sm\:box-border {
    box-sizing: border-box !important;
  }

  .sm\:box-content {
    box-sizing: content-box !important;
  }

  .sm\:cursor-auto {
    cursor: auto !important;
  }

  .sm\:cursor-default {
    cursor: default !important;
  }

  .sm\:cursor-pointer {
    cursor: pointer !important;
  }

  .sm\:cursor-wait {
    cursor: wait !important;
  }

  .sm\:cursor-text {
    cursor: text !important;
  }

  .sm\:cursor-move {
    cursor: move !important;
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .sm\:block {
    display: block !important;
  }

  .sm\:inline-block {
    display: inline-block !important;
  }

  .sm\:inline {
    display: inline !important;
  }

  .sm\:flex {
    display: flex !important;
  }

  .sm\:inline-flex {
    display: inline-flex !important;
  }

  .sm\:table {
    display: table !important;
  }

  .sm\:table-caption {
    display: table-caption !important;
  }

  .sm\:table-cell {
    display: table-cell !important;
  }

  .sm\:table-column {
    display: table-column !important;
  }

  .sm\:table-column-group {
    display: table-column-group !important;
  }

  .sm\:table-footer-group {
    display: table-footer-group !important;
  }

  .sm\:table-header-group {
    display: table-header-group !important;
  }

  .sm\:table-row-group {
    display: table-row-group !important;
  }

  .sm\:table-row {
    display: table-row !important;
  }

  .sm\:flow-root {
    display: flow-root !important;
  }

  .sm\:grid {
    display: grid !important;
  }

  .sm\:inline-grid {
    display: inline-grid !important;
  }

  .sm\:contents {
    display: contents !important;
  }

  .sm\:hidden {
    display: none !important;
  }

  .sm\:flex-row {
    flex-direction: row !important;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sm\:flex-col {
    flex-direction: column !important;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap !important;
  }

  .sm\:place-items-auto {
    place-items: auto !important;
  }

  .sm\:place-items-start {
    place-items: start !important;
  }

  .sm\:place-items-end {
    place-items: end !important;
  }

  .sm\:place-items-center {
    place-items: center !important;
  }

  .sm\:place-items-stretch {
    place-items: stretch !important;
  }

  .sm\:place-content-center {
    place-content: center !important;
  }

  .sm\:place-content-start {
    place-content: start !important;
  }

  .sm\:place-content-end {
    place-content: end !important;
  }

  .sm\:place-content-between {
    place-content: space-between !important;
  }

  .sm\:place-content-around {
    place-content: space-around !important;
  }

  .sm\:place-content-evenly {
    place-content: space-evenly !important;
  }

  .sm\:place-content-stretch {
    place-content: stretch !important;
  }

  .sm\:place-self-auto {
    place-self: auto !important;
  }

  .sm\:place-self-start {
    place-self: start !important;
  }

  .sm\:place-self-end {
    place-self: end !important;
  }

  .sm\:place-self-center {
    place-self: center !important;
  }

  .sm\:place-self-stretch {
    place-self: stretch !important;
  }

  .sm\:items-start {
    align-items: flex-start !important;
  }

  .sm\:items-end {
    align-items: flex-end !important;
  }

  .sm\:items-center {
    align-items: center !important;
  }

  .sm\:items-baseline {
    align-items: baseline !important;
  }

  .sm\:items-stretch {
    align-items: stretch !important;
  }

  .sm\:content-center {
    align-content: center !important;
  }

  .sm\:content-start {
    align-content: flex-start !important;
  }

  .sm\:content-end {
    align-content: flex-end !important;
  }

  .sm\:content-between {
    align-content: space-between !important;
  }

  .sm\:content-around {
    align-content: space-around !important;
  }

  .sm\:content-evenly {
    align-content: space-evenly !important;
  }

  .sm\:self-auto {
    align-self: auto !important;
  }

  .sm\:self-start {
    align-self: flex-start !important;
  }

  .sm\:self-end {
    align-self: flex-end !important;
  }

  .sm\:self-center {
    align-self: center !important;
  }

  .sm\:self-stretch {
    align-self: stretch !important;
  }

  .sm\:justify-items-auto {
    justify-items: auto !important;
  }

  .sm\:justify-items-start {
    justify-items: start !important;
  }

  .sm\:justify-items-end {
    justify-items: end !important;
  }

  .sm\:justify-items-center {
    justify-items: center !important;
  }

  .sm\:justify-items-stretch {
    justify-items: stretch !important;
  }

  .sm\:justify-start {
    justify-content: flex-start !important;
  }

  .sm\:justify-end {
    justify-content: flex-end !important;
  }

  .sm\:justify-center {
    justify-content: center !important;
  }

  .sm\:justify-between {
    justify-content: space-between !important;
  }

  .sm\:justify-around {
    justify-content: space-around !important;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .sm\:justify-self-auto {
    justify-self: auto !important;
  }

  .sm\:justify-self-start {
    justify-self: start !important;
  }

  .sm\:justify-self-end {
    justify-self: end !important;
  }

  .sm\:justify-self-center {
    justify-self: center !important;
  }

  .sm\:justify-self-stretch {
    justify-self: stretch !important;
  }

  .sm\:flex-1 {
    flex: 1 1 0% !important;
  }

  .sm\:flex-auto {
    flex: 1 1 auto !important;
  }

  .sm\:flex-initial {
    flex: 0 1 auto !important;
  }

  .sm\:flex-none {
    flex: none !important;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .sm\:flex-grow {
    flex-grow: 1 !important;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sm\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .sm\:order-1 {
    order: 1 !important;
  }

  .sm\:order-2 {
    order: 2 !important;
  }

  .sm\:order-3 {
    order: 3 !important;
  }

  .sm\:order-4 {
    order: 4 !important;
  }

  .sm\:order-5 {
    order: 5 !important;
  }

  .sm\:order-6 {
    order: 6 !important;
  }

  .sm\:order-7 {
    order: 7 !important;
  }

  .sm\:order-8 {
    order: 8 !important;
  }

  .sm\:order-9 {
    order: 9 !important;
  }

  .sm\:order-10 {
    order: 10 !important;
  }

  .sm\:order-11 {
    order: 11 !important;
  }

  .sm\:order-12 {
    order: 12 !important;
  }

  .sm\:order-first {
    order: -9999 !important;
  }

  .sm\:order-last {
    order: 9999 !important;
  }

  .sm\:order-none {
    order: 0 !important;
  }

  .sm\:float-right {
    float: right !important;
  }

  .sm\:float-left {
    float: left !important;
  }

  .sm\:float-none {
    float: none !important;
  }

  .sm\:clearfix:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }

  .sm\:clear-left {
    clear: left !important;
  }

  .sm\:clear-right {
    clear: right !important;
  }

  .sm\:clear-both {
    clear: both !important;
  }

  .sm\:clear-none {
    clear: none !important;
  }

  .sm\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .sm\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .sm\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .sm\:font-hairline {
    font-weight: 100 !important;
  }

  .sm\:font-thin {
    font-weight: 200 !important;
  }

  .sm\:font-light {
    font-weight: 300 !important;
  }

  .sm\:font-normal {
    font-weight: 400 !important;
  }

  .sm\:font-medium {
    font-weight: 500 !important;
  }

  .sm\:font-semibold {
    font-weight: 600 !important;
  }

  .sm\:font-bold {
    font-weight: 700 !important;
  }

  .sm\:font-extrabold {
    font-weight: 800 !important;
  }

  .sm\:font-black {
    font-weight: 900 !important;
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100 !important;
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200 !important;
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500 !important;
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600 !important;
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800 !important;
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900 !important;
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100 !important;
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200 !important;
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500 !important;
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600 !important;
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800 !important;
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900 !important;
  }

  .sm\:h-0 {
    height: 0 !important;
  }

  .sm\:h-1 {
    height: 0.25rem !important;
  }

  .sm\:h-2 {
    height: 0.5rem !important;
  }

  .sm\:h-3 {
    height: 0.75rem !important;
  }

  .sm\:h-4 {
    height: 1rem !important;
  }

  .sm\:h-5 {
    height: 1.25rem !important;
  }

  .sm\:h-6 {
    height: 1.5rem !important;
  }

  .sm\:h-8 {
    height: 2rem !important;
  }

  .sm\:h-10 {
    height: 2.5rem !important;
  }

  .sm\:h-12 {
    height: 3rem !important;
  }

  .sm\:h-16 {
    height: 4rem !important;
  }

  .sm\:h-20 {
    height: 5rem !important;
  }

  .sm\:h-24 {
    height: 6rem !important;
  }

  .sm\:h-32 {
    height: 8rem !important;
  }

  .sm\:h-40 {
    height: 10rem !important;
  }

  .sm\:h-48 {
    height: 12rem !important;
  }

  .sm\:h-56 {
    height: 14rem !important;
  }

  .sm\:h-64 {
    height: 16rem !important;
  }

  .sm\:h-auto {
    height: auto !important;
  }

  .sm\:h-px {
    height: 1px !important;
  }

  .sm\:h-full {
    height: 100% !important;
  }

  .sm\:h-screen {
    height: 100vh !important;
  }

  .sm\:text-xs {
    font-size: 0.80em !important;
  }

  .sm\:text-sm {
    font-size: 0.975em !important;
  }

  .sm\:text-base {
    font-size: 1em !important;
  }

  .sm\:text-lg {
    font-size: 1.125em !important;
  }

  .sm\:text-xl {
    font-size: 1.25em !important;
  }

  .sm\:text-2xl {
    font-size: 1.5em !important;
  }

  .sm\:text-3xl {
    font-size: 1.875em !important;
  }

  .sm\:text-4xl {
    font-size: 2.25em !important;
  }

  .sm\:text-5xl {
    font-size: 3em !important;
  }

  .sm\:text-6xl {
    font-size: 4em !important;
  }

  .sm\:text-md {
    font-size: 1em !important;
  }

  .sm\:text-sm-title {
    font-size:  18px !important;
  }

  .sm\:text-md-title {
    font-size: 22px !important;
  }

  .sm\:text-lg-title {
    font-size: 36px !important;
  }

  .sm\:text-size-body {
    font-size: 12px !important;
  }

  .sm\:text-size-label {
    font-size: 10px !important;
  }

  .sm\:leading-3 {
    line-height: .75rem !important;
  }

  .sm\:leading-4 {
    line-height: 1rem !important;
  }

  .sm\:leading-5 {
    line-height: 1.25rem !important;
  }

  .sm\:leading-6 {
    line-height: 1.5rem !important;
  }

  .sm\:leading-7 {
    line-height: 1.75rem !important;
  }

  .sm\:leading-8 {
    line-height: 2rem !important;
  }

  .sm\:leading-9 {
    line-height: 2.25rem !important;
  }

  .sm\:leading-10 {
    line-height: 2.5rem !important;
  }

  .sm\:leading-none {
    line-height: 1 !important;
  }

  .sm\:leading-tight {
    line-height: 1.25 !important;
  }

  .sm\:leading-snug {
    line-height: 1.375 !important;
  }

  .sm\:leading-normal {
    line-height: 1.5 !important;
  }

  .sm\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .sm\:leading-loose {
    line-height: 2 !important;
  }

  .sm\:list-inside {
    list-style-position: inside !important;
  }

  .sm\:list-outside {
    list-style-position: outside !important;
  }

  .sm\:list-none {
    list-style-type: none !important;
  }

  .sm\:list-disc {
    list-style-type: disc !important;
  }

  .sm\:list-decimal {
    list-style-type: decimal !important;
  }

  .sm\:m-0 {
    margin: 0 !important;
  }

  .sm\:m-1 {
    margin: 0.25rem !important;
  }

  .sm\:m-2 {
    margin: 0.5rem !important;
  }

  .sm\:m-3 {
    margin: 0.75rem !important;
  }

  .sm\:m-4 {
    margin: 1rem !important;
  }

  .sm\:m-5 {
    margin: 1.25rem !important;
  }

  .sm\:m-6 {
    margin: 1.5rem !important;
  }

  .sm\:m-8 {
    margin: 2rem !important;
  }

  .sm\:m-10 {
    margin: 2.5rem !important;
  }

  .sm\:m-12 {
    margin: 3rem !important;
  }

  .sm\:m-16 {
    margin: 4rem !important;
  }

  .sm\:m-20 {
    margin: 5rem !important;
  }

  .sm\:m-24 {
    margin: 6rem !important;
  }

  .sm\:m-32 {
    margin: 8rem !important;
  }

  .sm\:m-40 {
    margin: 10rem !important;
  }

  .sm\:m-48 {
    margin: 12rem !important;
  }

  .sm\:m-56 {
    margin: 14rem !important;
  }

  .sm\:m-64 {
    margin: 16rem !important;
  }

  .sm\:m-auto {
    margin: auto !important;
  }

  .sm\:m-px {
    margin: 1px !important;
  }

  .sm\:-m-1 {
    margin: -0.25rem !important;
  }

  .sm\:-m-2 {
    margin: -0.5rem !important;
  }

  .sm\:-m-3 {
    margin: -0.75rem !important;
  }

  .sm\:-m-4 {
    margin: -1rem !important;
  }

  .sm\:-m-5 {
    margin: -1.25rem !important;
  }

  .sm\:-m-6 {
    margin: -1.5rem !important;
  }

  .sm\:-m-8 {
    margin: -2rem !important;
  }

  .sm\:-m-10 {
    margin: -2.5rem !important;
  }

  .sm\:-m-12 {
    margin: -3rem !important;
  }

  .sm\:-m-16 {
    margin: -4rem !important;
  }

  .sm\:-m-20 {
    margin: -5rem !important;
  }

  .sm\:-m-24 {
    margin: -6rem !important;
  }

  .sm\:-m-32 {
    margin: -8rem !important;
  }

  .sm\:-m-40 {
    margin: -10rem !important;
  }

  .sm\:-m-48 {
    margin: -12rem !important;
  }

  .sm\:-m-56 {
    margin: -14rem !important;
  }

  .sm\:-m-64 {
    margin: -16rem !important;
  }

  .sm\:-m-px {
    margin: -1px !important;
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .sm\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .sm\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .sm\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .sm\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .sm\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .sm\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .sm\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .sm\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .sm\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .sm\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .sm\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .sm\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .sm\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .sm\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .sm\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .sm\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .sm\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .sm\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .sm\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .sm\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .sm\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .sm\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .sm\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .sm\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .sm\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .sm\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .sm\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .sm\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .sm\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .sm\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .sm\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .sm\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .sm\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .sm\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .sm\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .sm\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mr-0 {
    margin-right: 0 !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:ml-0 {
    margin-left: 0 !important;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .sm\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .sm\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .sm\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .sm\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .sm\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .sm\:mt-4 {
    margin-top: 1rem !important;
  }

  .sm\:mr-4 {
    margin-right: 1rem !important;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .sm\:ml-4 {
    margin-left: 1rem !important;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .sm\:mt-8 {
    margin-top: 2rem !important;
  }

  .sm\:mr-8 {
    margin-right: 2rem !important;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .sm\:ml-8 {
    margin-left: 2rem !important;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .sm\:mt-12 {
    margin-top: 3rem !important;
  }

  .sm\:mr-12 {
    margin-right: 3rem !important;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .sm\:ml-12 {
    margin-left: 3rem !important;
  }

  .sm\:mt-16 {
    margin-top: 4rem !important;
  }

  .sm\:mr-16 {
    margin-right: 4rem !important;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .sm\:ml-16 {
    margin-left: 4rem !important;
  }

  .sm\:mt-20 {
    margin-top: 5rem !important;
  }

  .sm\:mr-20 {
    margin-right: 5rem !important;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .sm\:ml-20 {
    margin-left: 5rem !important;
  }

  .sm\:mt-24 {
    margin-top: 6rem !important;
  }

  .sm\:mr-24 {
    margin-right: 6rem !important;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .sm\:ml-24 {
    margin-left: 6rem !important;
  }

  .sm\:mt-32 {
    margin-top: 8rem !important;
  }

  .sm\:mr-32 {
    margin-right: 8rem !important;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .sm\:ml-32 {
    margin-left: 8rem !important;
  }

  .sm\:mt-40 {
    margin-top: 10rem !important;
  }

  .sm\:mr-40 {
    margin-right: 10rem !important;
  }

  .sm\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .sm\:ml-40 {
    margin-left: 10rem !important;
  }

  .sm\:mt-48 {
    margin-top: 12rem !important;
  }

  .sm\:mr-48 {
    margin-right: 12rem !important;
  }

  .sm\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .sm\:ml-48 {
    margin-left: 12rem !important;
  }

  .sm\:mt-56 {
    margin-top: 14rem !important;
  }

  .sm\:mr-56 {
    margin-right: 14rem !important;
  }

  .sm\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .sm\:ml-56 {
    margin-left: 14rem !important;
  }

  .sm\:mt-64 {
    margin-top: 16rem !important;
  }

  .sm\:mr-64 {
    margin-right: 16rem !important;
  }

  .sm\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .sm\:ml-64 {
    margin-left: 16rem !important;
  }

  .sm\:mt-auto {
    margin-top: auto !important;
  }

  .sm\:mr-auto {
    margin-right: auto !important;
  }

  .sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .sm\:ml-auto {
    margin-left: auto !important;
  }

  .sm\:mt-px {
    margin-top: 1px !important;
  }

  .sm\:mr-px {
    margin-right: 1px !important;
  }

  .sm\:mb-px {
    margin-bottom: 1px !important;
  }

  .sm\:ml-px {
    margin-left: 1px !important;
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .sm\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .sm\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .sm\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .sm\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .sm\:-mt-4 {
    margin-top: -1rem !important;
  }

  .sm\:-mr-4 {
    margin-right: -1rem !important;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .sm\:-ml-4 {
    margin-left: -1rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .sm\:-mt-8 {
    margin-top: -2rem !important;
  }

  .sm\:-mr-8 {
    margin-right: -2rem !important;
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .sm\:-ml-8 {
    margin-left: -2rem !important;
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .sm\:-mt-12 {
    margin-top: -3rem !important;
  }

  .sm\:-mr-12 {
    margin-right: -3rem !important;
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .sm\:-ml-12 {
    margin-left: -3rem !important;
  }

  .sm\:-mt-16 {
    margin-top: -4rem !important;
  }

  .sm\:-mr-16 {
    margin-right: -4rem !important;
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .sm\:-ml-16 {
    margin-left: -4rem !important;
  }

  .sm\:-mt-20 {
    margin-top: -5rem !important;
  }

  .sm\:-mr-20 {
    margin-right: -5rem !important;
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .sm\:-ml-20 {
    margin-left: -5rem !important;
  }

  .sm\:-mt-24 {
    margin-top: -6rem !important;
  }

  .sm\:-mr-24 {
    margin-right: -6rem !important;
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .sm\:-ml-24 {
    margin-left: -6rem !important;
  }

  .sm\:-mt-32 {
    margin-top: -8rem !important;
  }

  .sm\:-mr-32 {
    margin-right: -8rem !important;
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .sm\:-ml-32 {
    margin-left: -8rem !important;
  }

  .sm\:-mt-40 {
    margin-top: -10rem !important;
  }

  .sm\:-mr-40 {
    margin-right: -10rem !important;
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .sm\:-ml-40 {
    margin-left: -10rem !important;
  }

  .sm\:-mt-48 {
    margin-top: -12rem !important;
  }

  .sm\:-mr-48 {
    margin-right: -12rem !important;
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .sm\:-ml-48 {
    margin-left: -12rem !important;
  }

  .sm\:-mt-56 {
    margin-top: -14rem !important;
  }

  .sm\:-mr-56 {
    margin-right: -14rem !important;
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .sm\:-ml-56 {
    margin-left: -14rem !important;
  }

  .sm\:-mt-64 {
    margin-top: -16rem !important;
  }

  .sm\:-mr-64 {
    margin-right: -16rem !important;
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .sm\:-ml-64 {
    margin-left: -16rem !important;
  }

  .sm\:-mt-px {
    margin-top: -1px !important;
  }

  .sm\:-mr-px {
    margin-right: -1px !important;
  }

  .sm\:-mb-px {
    margin-bottom: -1px !important;
  }

  .sm\:-ml-px {
    margin-left: -1px !important;
  }

  .sm\:max-h-full {
    max-height: 100% !important;
  }

  .sm\:max-h-screen {
    max-height: 100vh !important;
  }

  .sm\:max-w-0 {
    max-width: 0 !important;
  }

  .sm\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .sm\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .sm\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .sm\:max-w-4 {
    max-width: 1rem !important;
  }

  .sm\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .sm\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .sm\:max-w-8 {
    max-width: 2rem !important;
  }

  .sm\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .sm\:max-w-12 {
    max-width: 3rem !important;
  }

  .sm\:max-w-16 {
    max-width: 4rem !important;
  }

  .sm\:max-w-20 {
    max-width: 5rem !important;
  }

  .sm\:max-w-24 {
    max-width: 6rem !important;
  }

  .sm\:max-w-32 {
    max-width: 8rem !important;
  }

  .sm\:max-w-40 {
    max-width: 10rem !important;
  }

  .sm\:max-w-48 {
    max-width: 12rem !important;
  }

  .sm\:max-w-56 {
    max-width: 14rem !important;
  }

  .sm\:max-w-64 {
    max-width: 16rem !important;
  }

  .sm\:max-w-none {
    max-width: none !important;
  }

  .sm\:max-w-xs {
    max-width: 20rem !important;
  }

  .sm\:max-w-sm {
    max-width: 24rem !important;
  }

  .sm\:max-w-md {
    max-width: 28rem !important;
  }

  .sm\:max-w-lg {
    max-width: 32rem !important;
  }

  .sm\:max-w-xl {
    max-width: 36rem !important;
  }

  .sm\:max-w-2xl {
    max-width: 42rem !important;
  }

  .sm\:max-w-3xl {
    max-width: 48rem !important;
  }

  .sm\:max-w-4xl {
    max-width: 56rem !important;
  }

  .sm\:max-w-5xl {
    max-width: 64rem !important;
  }

  .sm\:max-w-6xl {
    max-width: 72rem !important;
  }

  .sm\:max-w-full {
    max-width: 100% !important;
  }

  .sm\:max-w-screen-xs {
    max-width: 480px !important;
  }

  .sm\:max-w-screen-sm {
    max-width: 576px !important;
  }

  .sm\:max-w-screen-md {
    max-width: 768px !important;
  }

  .sm\:max-w-screen-lg {
    max-width: 992px !important;
  }

  .sm\:max-w-screen-xl {
    max-width: 1200px !important;
  }

  .sm\:max-w-screen-xxl {
    max-width: 1600px !important;
  }

  .sm\:max-w-screen {
    max-width: 100vw !important;
  }

  .sm\:max-w-px {
    max-width: 1px !important;
  }

  .sm\:min-h-0 {
    min-height: 0 !important;
  }

  .sm\:min-h-full {
    min-height: 100% !important;
  }

  .sm\:min-h-screen {
    min-height: 100vh !important;
  }

  .sm\:min-w-0 {
    min-width: 0 !important;
  }

  .sm\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .sm\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .sm\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .sm\:min-w-4 {
    min-width: 1rem !important;
  }

  .sm\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .sm\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .sm\:min-w-8 {
    min-width: 2rem !important;
  }

  .sm\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .sm\:min-w-12 {
    min-width: 3rem !important;
  }

  .sm\:min-w-16 {
    min-width: 4rem !important;
  }

  .sm\:min-w-20 {
    min-width: 5rem !important;
  }

  .sm\:min-w-24 {
    min-width: 6rem !important;
  }

  .sm\:min-w-32 {
    min-width: 8rem !important;
  }

  .sm\:min-w-40 {
    min-width: 10rem !important;
  }

  .sm\:min-w-48 {
    min-width: 12rem !important;
  }

  .sm\:min-w-56 {
    min-width: 14rem !important;
  }

  .sm\:min-w-64 {
    min-width: 16rem !important;
  }

  .sm\:min-w-full {
    min-width: 100% !important;
  }

  .sm\:min-w-screen {
    min-width: 100vw !important;
  }

  .sm\:min-w-px {
    min-width: 1px !important;
  }

  .sm\:min-w-screen-xs {
    min-width: 480px !important;
  }

  .sm\:min-w-screen-sm {
    min-width: 576px !important;
  }

  .sm\:min-w-screen-md {
    min-width: 768px !important;
  }

  .sm\:min-w-screen-lg {
    min-width: 992px !important;
  }

  .sm\:min-w-screen-xl {
    min-width: 1200px !important;
  }

  .sm\:min-w-screen-xxl {
    min-width: 1600px !important;
  }

  .sm\:object-contain {
    object-fit: contain !important;
  }

  .sm\:object-cover {
    object-fit: cover !important;
  }

  .sm\:object-fill {
    object-fit: fill !important;
  }

  .sm\:object-none {
    object-fit: none !important;
  }

  .sm\:object-scale-down {
    object-fit: scale-down !important;
  }

  .sm\:object-bottom {
    object-position: bottom !important;
  }

  .sm\:object-center {
    object-position: center !important;
  }

  .sm\:object-left {
    object-position: left !important;
  }

  .sm\:object-left-bottom {
    object-position: left bottom !important;
  }

  .sm\:object-left-top {
    object-position: left top !important;
  }

  .sm\:object-right {
    object-position: right !important;
  }

  .sm\:object-right-bottom {
    object-position: right bottom !important;
  }

  .sm\:object-right-top {
    object-position: right top !important;
  }

  .sm\:object-top {
    object-position: top !important;
  }

  .sm\:opacity-0 {
    opacity: 0 !important;
  }

  .sm\:opacity-25 {
    opacity: 0.25 !important;
  }

  .sm\:opacity-50 {
    opacity: 0.5 !important;
  }

  .sm\:opacity-75 {
    opacity: 0.75 !important;
  }

  .sm\:opacity-100 {
    opacity: 1 !important;
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .sm\:overflow-auto {
    overflow: auto !important;
  }

  .sm\:overflow-hidden {
    overflow: hidden !important;
  }

  .sm\:overflow-visible {
    overflow: visible !important;
  }

  .sm\:overflow-scroll {
    overflow: scroll !important;
  }

  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto !important;
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain !important;
  }

  .sm\:overscroll-none {
    overscroll-behavior: none !important;
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto !important;
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain !important;
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none !important;
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto !important;
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain !important;
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none !important;
  }

  .sm\:p-0 {
    padding: 0 !important;
  }

  .sm\:p-1 {
    padding: 0.25rem !important;
  }

  .sm\:p-2 {
    padding: 0.5rem !important;
  }

  .sm\:p-3 {
    padding: 0.75rem !important;
  }

  .sm\:p-4 {
    padding: 1rem !important;
  }

  .sm\:p-5 {
    padding: 1.25rem !important;
  }

  .sm\:p-6 {
    padding: 1.5rem !important;
  }

  .sm\:p-8 {
    padding: 2rem !important;
  }

  .sm\:p-10 {
    padding: 2.5rem !important;
  }

  .sm\:p-12 {
    padding: 3rem !important;
  }

  .sm\:p-16 {
    padding: 4rem !important;
  }

  .sm\:p-20 {
    padding: 5rem !important;
  }

  .sm\:p-24 {
    padding: 6rem !important;
  }

  .sm\:p-32 {
    padding: 8rem !important;
  }

  .sm\:p-40 {
    padding: 10rem !important;
  }

  .sm\:p-48 {
    padding: 12rem !important;
  }

  .sm\:p-56 {
    padding: 14rem !important;
  }

  .sm\:p-64 {
    padding: 16rem !important;
  }

  .sm\:p-px {
    padding: 1px !important;
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .sm\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .sm\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .sm\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .sm\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .sm\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .sm\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .sm\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .sm\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .sm\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .sm\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .sm\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .sm\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .sm\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .sm\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .sm\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .sm\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .sm\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .sm\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .sm\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .sm\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .sm\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pr-0 {
    padding-right: 0 !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important;
  }

  .sm\:pl-0 {
    padding-left: 0 !important;
  }

  .sm\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .sm\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .sm\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .sm\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .sm\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .sm\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .sm\:pt-4 {
    padding-top: 1rem !important;
  }

  .sm\:pr-4 {
    padding-right: 1rem !important;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .sm\:pl-4 {
    padding-left: 1rem !important;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .sm\:pt-8 {
    padding-top: 2rem !important;
  }

  .sm\:pr-8 {
    padding-right: 2rem !important;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .sm\:pl-8 {
    padding-left: 2rem !important;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .sm\:pt-12 {
    padding-top: 3rem !important;
  }

  .sm\:pr-12 {
    padding-right: 3rem !important;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .sm\:pl-12 {
    padding-left: 3rem !important;
  }

  .sm\:pt-16 {
    padding-top: 4rem !important;
  }

  .sm\:pr-16 {
    padding-right: 4rem !important;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .sm\:pl-16 {
    padding-left: 4rem !important;
  }

  .sm\:pt-20 {
    padding-top: 5rem !important;
  }

  .sm\:pr-20 {
    padding-right: 5rem !important;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .sm\:pl-20 {
    padding-left: 5rem !important;
  }

  .sm\:pt-24 {
    padding-top: 6rem !important;
  }

  .sm\:pr-24 {
    padding-right: 6rem !important;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .sm\:pl-24 {
    padding-left: 6rem !important;
  }

  .sm\:pt-32 {
    padding-top: 8rem !important;
  }

  .sm\:pr-32 {
    padding-right: 8rem !important;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .sm\:pl-32 {
    padding-left: 8rem !important;
  }

  .sm\:pt-40 {
    padding-top: 10rem !important;
  }

  .sm\:pr-40 {
    padding-right: 10rem !important;
  }

  .sm\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .sm\:pl-40 {
    padding-left: 10rem !important;
  }

  .sm\:pt-48 {
    padding-top: 12rem !important;
  }

  .sm\:pr-48 {
    padding-right: 12rem !important;
  }

  .sm\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .sm\:pl-48 {
    padding-left: 12rem !important;
  }

  .sm\:pt-56 {
    padding-top: 14rem !important;
  }

  .sm\:pr-56 {
    padding-right: 14rem !important;
  }

  .sm\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .sm\:pl-56 {
    padding-left: 14rem !important;
  }

  .sm\:pt-64 {
    padding-top: 16rem !important;
  }

  .sm\:pr-64 {
    padding-right: 16rem !important;
  }

  .sm\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .sm\:pl-64 {
    padding-left: 16rem !important;
  }

  .sm\:pt-px {
    padding-top: 1px !important;
  }

  .sm\:pr-px {
    padding-right: 1px !important;
  }

  .sm\:pb-px {
    padding-bottom: 1px !important;
  }

  .sm\:pl-px {
    padding-left: 1px !important;
  }

  .sm\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent !important;
  }

  .sm\:placeholder-transparent::-ms-input-placeholder {
    color: transparent !important;
  }

  .sm\:placeholder-transparent::placeholder {
    color: transparent !important;
  }

  .sm\:placeholder-current::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .sm\:placeholder-current::-ms-input-placeholder {
    color: currentColor !important;
  }

  .sm\:placeholder-current::placeholder {
    color: currentColor !important;
  }

  .sm\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-black::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-white::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .sm\:placeholder-primary::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .sm\:placeholder-primary::placeholder {
    color: var(--primary) !important;
  }

  .sm\:placeholder-accent-1::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-accent-1::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-accent-1::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-accent-2::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-accent-2::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-accent-2::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  .sm\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent !important;
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent !important;
  }

  .sm\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .sm\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor !important;
  }

  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor !important;
  }

  .sm\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .sm\:focus\:placeholder-primary:focus::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .sm\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary) !important;
  }

  .sm\:focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-accent-1:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-accent-1:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-accent-2:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-accent-2:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .sm\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:pointer-events-none {
    pointer-events: none !important;
  }

  .sm\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .sm\:static {
    position: static !important;
  }

  .sm\:fixed {
    position: fixed !important;
  }

  .sm\:absolute {
    position: absolute !important;
  }

  .sm\:relative {
    position: relative !important;
  }

  .sm\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .sm\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .sm\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .sm\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .sm\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .sm\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .sm\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .sm\:top-0 {
    top: 0 !important;
  }

  .sm\:right-0 {
    right: 0 !important;
  }

  .sm\:bottom-0 {
    bottom: 0 !important;
  }

  .sm\:left-0 {
    left: 0 !important;
  }

  .sm\:top-auto {
    top: auto !important;
  }

  .sm\:right-auto {
    right: auto !important;
  }

  .sm\:bottom-auto {
    bottom: auto !important;
  }

  .sm\:left-auto {
    left: auto !important;
  }

  .sm\:resize-none {
    resize: none !important;
  }

  .sm\:resize-y {
    resize: vertical !important;
  }

  .sm\:resize-x {
    resize: horizontal !important;
  }

  .sm\:resize {
    resize: both !important;
  }

  .sm\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .sm\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .sm\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .sm\:shadow-none {
    box-shadow: none !important;
  }

  .sm\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .sm\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .sm\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .sm\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .sm\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .sm\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .sm\:fill-current {
    fill: currentColor !important;
  }

  .sm\:stroke-current {
    stroke: currentColor !important;
  }

  .sm\:stroke-0 {
    stroke-width: 0 !important;
  }

  .sm\:stroke-1 {
    stroke-width: 1 !important;
  }

  .sm\:stroke-2 {
    stroke-width: 2 !important;
  }

  .sm\:table-auto {
    table-layout: auto !important;
  }

  .sm\:table-fixed {
    table-layout: fixed !important;
  }

  .sm\:text-left {
    text-align: left !important;
  }

  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-right {
    text-align: right !important;
  }

  .sm\:text-justify {
    text-align: justify !important;
  }

  .sm\:text-transparent {
    color: transparent !important;
  }

  .sm\:text-current {
    color: currentColor !important;
  }

  .sm\:text-black {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .sm\:text-white {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .sm\:text-gray-100 {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .sm\:text-gray-200 {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .sm\:text-gray-300 {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .sm\:text-gray-400 {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .sm\:text-gray-500 {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .sm\:text-gray-600 {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .sm\:text-gray-700 {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .sm\:text-gray-800 {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .sm\:text-gray-900 {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .sm\:text-red-100 {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .sm\:text-red-200 {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .sm\:text-red-300 {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .sm\:text-red-400 {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .sm\:text-red-500 {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .sm\:text-red-600 {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .sm\:text-red-700 {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .sm\:text-red-800 {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .sm\:text-red-900 {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .sm\:text-orange-100 {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .sm\:text-orange-200 {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .sm\:text-orange-300 {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .sm\:text-orange-400 {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .sm\:text-orange-500 {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .sm\:text-orange-600 {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .sm\:text-orange-700 {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .sm\:text-orange-800 {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .sm\:text-orange-900 {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-100 {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-200 {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-300 {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-400 {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-500 {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-600 {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-700 {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-800 {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .sm\:text-yellow-900 {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .sm\:text-green-100 {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .sm\:text-green-200 {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .sm\:text-green-300 {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .sm\:text-green-400 {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .sm\:text-green-500 {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .sm\:text-green-600 {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .sm\:text-green-700 {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .sm\:text-green-800 {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .sm\:text-green-900 {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .sm\:text-teal-100 {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .sm\:text-teal-200 {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .sm\:text-teal-300 {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .sm\:text-teal-400 {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .sm\:text-teal-500 {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .sm\:text-teal-600 {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .sm\:text-teal-700 {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .sm\:text-teal-800 {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .sm\:text-teal-900 {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .sm\:text-blue-100 {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .sm\:text-blue-200 {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .sm\:text-blue-300 {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .sm\:text-blue-400 {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .sm\:text-blue-500 {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .sm\:text-blue-600 {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .sm\:text-blue-700 {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .sm\:text-blue-800 {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .sm\:text-blue-900 {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-100 {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-200 {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-300 {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-400 {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-500 {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-600 {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-700 {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-800 {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .sm\:text-indigo-900 {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .sm\:text-purple-100 {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .sm\:text-purple-200 {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .sm\:text-purple-300 {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .sm\:text-purple-400 {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .sm\:text-purple-500 {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .sm\:text-purple-600 {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .sm\:text-purple-700 {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .sm\:text-purple-800 {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .sm\:text-purple-900 {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .sm\:text-pink-100 {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .sm\:text-pink-200 {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .sm\:text-pink-300 {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .sm\:text-pink-400 {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .sm\:text-pink-500 {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .sm\:text-pink-600 {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .sm\:text-pink-700 {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .sm\:text-pink-800 {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .sm\:text-pink-900 {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .sm\:text-primary {
    color: var(--primary) !important;
  }

  .sm\:text-accent-1 {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .sm\:text-accent-2 {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-transparent:hover {
    color: transparent !important;
  }

  .sm\:hover\:text-current:hover {
    color: currentColor !important;
  }

  .sm\:hover\:text-black:hover {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-white:hover {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-100:hover {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-200:hover {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-300:hover {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-400:hover {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-500:hover {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-600:hover {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-700:hover {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-800:hover {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-gray-900:hover {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-200:hover {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-300:hover {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-400:hover {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-500:hover {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-600:hover {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-700:hover {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-800:hover {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-red-900:hover {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-100:hover {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-200:hover {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-300:hover {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-400:hover {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-500:hover {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-600:hover {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-700:hover {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-800:hover {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-orange-900:hover {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-100:hover {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-200:hover {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-300:hover {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-400:hover {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-500:hover {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-600:hover {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-700:hover {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-800:hover {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-900:hover {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-100:hover {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-200:hover {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-300:hover {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-400:hover {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-500:hover {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-600:hover {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-700:hover {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-800:hover {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-green-900:hover {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-100:hover {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-200:hover {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-300:hover {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-400:hover {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-500:hover {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-600:hover {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-700:hover {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-800:hover {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-teal-900:hover {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-100:hover {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-200:hover {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-300:hover {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-400:hover {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-500:hover {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-600:hover {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-700:hover {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-800:hover {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-blue-900:hover {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-100:hover {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-200:hover {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-300:hover {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-400:hover {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-500:hover {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-600:hover {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-700:hover {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-800:hover {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-900:hover {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-100:hover {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-200:hover {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-300:hover {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-400:hover {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-500:hover {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-600:hover {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-700:hover {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-800:hover {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-purple-900:hover {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-200:hover {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-300:hover {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-400:hover {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-500:hover {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-600:hover {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-700:hover {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-800:hover {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-pink-900:hover {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-primary:hover {
    color: var(--primary) !important;
  }

  .sm\:hover\:text-accent-1:hover {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .sm\:hover\:text-accent-2:hover {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-transparent:focus {
    color: transparent !important;
  }

  .sm\:focus\:text-current:focus {
    color: currentColor !important;
  }

  .sm\:focus\:text-black:focus {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-white:focus {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-100:focus {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-200:focus {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-300:focus {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-400:focus {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-500:focus {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-600:focus {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-700:focus {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-800:focus {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-gray-900:focus {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-200:focus {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-300:focus {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-400:focus {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-500:focus {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-600:focus {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-700:focus {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-800:focus {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-red-900:focus {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-100:focus {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-200:focus {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-300:focus {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-400:focus {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-500:focus {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-600:focus {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-700:focus {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-800:focus {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-orange-900:focus {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-100:focus {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-200:focus {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-300:focus {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-400:focus {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-500:focus {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-600:focus {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-700:focus {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-800:focus {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-900:focus {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-100:focus {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-200:focus {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-300:focus {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-400:focus {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-500:focus {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-600:focus {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-700:focus {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-800:focus {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-green-900:focus {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-100:focus {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-200:focus {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-300:focus {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-400:focus {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-500:focus {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-600:focus {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-700:focus {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-800:focus {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-teal-900:focus {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-100:focus {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-200:focus {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-300:focus {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-400:focus {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-500:focus {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-600:focus {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-700:focus {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-800:focus {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-blue-900:focus {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-100:focus {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-200:focus {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-300:focus {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-400:focus {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-500:focus {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-600:focus {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-700:focus {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-800:focus {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-900:focus {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-100:focus {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-200:focus {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-300:focus {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-400:focus {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-500:focus {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-600:focus {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-700:focus {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-800:focus {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-purple-900:focus {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-200:focus {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-300:focus {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-400:focus {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-500:focus {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-600:focus {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-700:focus {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-800:focus {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-pink-900:focus {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-primary:focus {
    color: var(--primary) !important;
  }

  .sm\:focus\:text-accent-1:focus {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .sm\:focus\:text-accent-2:focus {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .sm\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .sm\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .sm\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .sm\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .sm\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .sm\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .sm\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .sm\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .sm\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .sm\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .sm\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .sm\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .sm\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .sm\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .sm\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .sm\:italic {
    font-style: italic !important;
  }

  .sm\:not-italic {
    font-style: normal !important;
  }

  .sm\:uppercase {
    text-transform: uppercase !important;
  }

  .sm\:lowercase {
    text-transform: lowercase !important;
  }

  .sm\:capitalize {
    text-transform: capitalize !important;
  }

  .sm\:normal-case {
    text-transform: none !important;
  }

  .sm\:underline {
    text-decoration: underline !important;
  }

  .sm\:line-through {
    text-decoration: line-through !important;
  }

  .sm\:no-underline {
    text-decoration: none !important;
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
  }

  .sm\:normal-nums {
    font-variant-numeric: normal !important;
  }

  .sm\:ordinal {
    --font-variant-numeric-ordinal: ordinal !important;
  }

  .sm\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero !important;
  }

  .sm\:lining-nums {
    --font-variant-numeric-figure: lining-nums !important;
  }

  .sm\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums !important;
  }

  .sm\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums !important;
  }

  .sm\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums !important;
  }

  .sm\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions !important;
  }

  .sm\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions !important;
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .sm\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .sm\:select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .sm\:select-text {
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .sm\:select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .sm\:select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .sm\:align-baseline {
    vertical-align: baseline !important;
  }

  .sm\:align-top {
    vertical-align: top !important;
  }

  .sm\:align-middle {
    vertical-align: middle !important;
  }

  .sm\:align-bottom {
    vertical-align: bottom !important;
  }

  .sm\:align-text-top {
    vertical-align: text-top !important;
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .sm\:visible {
    visibility: visible !important;
  }

  .sm\:invisible {
    visibility: hidden !important;
  }

  .sm\:whitespace-normal {
    white-space: normal !important;
  }

  .sm\:whitespace-no-wrap {
    white-space: nowrap !important;
  }

  .sm\:whitespace-pre {
    white-space: pre !important;
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .sm\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .sm\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  .sm\:break-all {
    word-break: break-all !important;
  }

  .sm\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .sm\:w-0 {
    width: 0 !important;
  }

  .sm\:w-1 {
    width: 0.25rem !important;
  }

  .sm\:w-2 {
    width: 0.5rem !important;
  }

  .sm\:w-3 {
    width: 0.75rem !important;
  }

  .sm\:w-4 {
    width: 1rem !important;
  }

  .sm\:w-5 {
    width: 1.25rem !important;
  }

  .sm\:w-6 {
    width: 1.5rem !important;
  }

  .sm\:w-8 {
    width: 2rem !important;
  }

  .sm\:w-10 {
    width: 2.5rem !important;
  }

  .sm\:w-12 {
    width: 3rem !important;
  }

  .sm\:w-16 {
    width: 4rem !important;
  }

  .sm\:w-20 {
    width: 5rem !important;
  }

  .sm\:w-24 {
    width: 6rem !important;
  }

  .sm\:w-32 {
    width: 8rem !important;
  }

  .sm\:w-40 {
    width: 10rem !important;
  }

  .sm\:w-48 {
    width: 12rem !important;
  }

  .sm\:w-56 {
    width: 14rem !important;
  }

  .sm\:w-64 {
    width: 16rem !important;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-px {
    width: 1px !important;
  }

  .sm\:w-1\/2 {
    width: 50% !important;
  }

  .sm\:w-1\/3 {
    width: 33.333333% !important;
  }

  .sm\:w-2\/3 {
    width: 66.666667% !important;
  }

  .sm\:w-1\/4 {
    width: 25% !important;
  }

  .sm\:w-2\/4 {
    width: 50% !important;
  }

  .sm\:w-3\/4 {
    width: 75% !important;
  }

  .sm\:w-1\/5 {
    width: 20% !important;
  }

  .sm\:w-2\/5 {
    width: 40% !important;
  }

  .sm\:w-3\/5 {
    width: 60% !important;
  }

  .sm\:w-4\/5 {
    width: 80% !important;
  }

  .sm\:w-1\/6 {
    width: 16.666667% !important;
  }

  .sm\:w-2\/6 {
    width: 33.333333% !important;
  }

  .sm\:w-3\/6 {
    width: 50% !important;
  }

  .sm\:w-4\/6 {
    width: 66.666667% !important;
  }

  .sm\:w-5\/6 {
    width: 83.333333% !important;
  }

  .sm\:w-1\/12 {
    width: 8.333333% !important;
  }

  .sm\:w-2\/12 {
    width: 16.666667% !important;
  }

  .sm\:w-3\/12 {
    width: 25% !important;
  }

  .sm\:w-4\/12 {
    width: 33.333333% !important;
  }

  .sm\:w-5\/12 {
    width: 41.666667% !important;
  }

  .sm\:w-6\/12 {
    width: 50% !important;
  }

  .sm\:w-7\/12 {
    width: 58.333333% !important;
  }

  .sm\:w-8\/12 {
    width: 66.666667% !important;
  }

  .sm\:w-9\/12 {
    width: 75% !important;
  }

  .sm\:w-10\/12 {
    width: 83.333333% !important;
  }

  .sm\:w-11\/12 {
    width: 91.666667% !important;
  }

  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-screen {
    width: 100vw !important;
  }

  .sm\:w-none {
    width: none !important;
  }

  .sm\:w-xs {
    width: 20rem !important;
  }

  .sm\:w-sm {
    width: 24rem !important;
  }

  .sm\:w-md {
    width: 28rem !important;
  }

  .sm\:w-lg {
    width: 32rem !important;
  }

  .sm\:w-xl {
    width: 36rem !important;
  }

  .sm\:w-2xl {
    width: 42rem !important;
  }

  .sm\:w-3xl {
    width: 48rem !important;
  }

  .sm\:w-4xl {
    width: 56rem !important;
  }

  .sm\:w-5xl {
    width: 64rem !important;
  }

  .sm\:w-6xl {
    width: 72rem !important;
  }

  .sm\:w-screen-xs {
    width: 480px !important;
  }

  .sm\:w-screen-sm {
    width: 576px !important;
  }

  .sm\:w-screen-md {
    width: 768px !important;
  }

  .sm\:w-screen-lg {
    width: 992px !important;
  }

  .sm\:w-screen-xl {
    width: 1200px !important;
  }

  .sm\:w-screen-xxl {
    width: 1600px !important;
  }

  .sm\:w-sidebar {
    width: 15rem !important;
  }

  .sm\:z-0 {
    z-index: 0 !important;
  }

  .sm\:z-10 {
    z-index: 10 !important;
  }

  .sm\:z-20 {
    z-index: 20 !important;
  }

  .sm\:z-30 {
    z-index: 30 !important;
  }

  .sm\:z-40 {
    z-index: 40 !important;
  }

  .sm\:z-50 {
    z-index: 50 !important;
  }

  .sm\:z-auto {
    z-index: auto !important;
  }

  .sm\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .sm\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .sm\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .sm\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .sm\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .sm\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .sm\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .sm\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .sm\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .sm\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .sm\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .sm\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .sm\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .sm\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .sm\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .sm\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .sm\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .sm\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .sm\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .sm\:gap-x-0 {
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }

  .sm\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .sm\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .sm\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .sm\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .sm\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .sm\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .sm\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .sm\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .sm\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .sm\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .sm\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .sm\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .sm\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .sm\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .sm\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .sm\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .sm\:gap-x-px {
    grid-column-gap: 1px !important;
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .sm\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .sm\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .sm\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .sm\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .sm\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .sm\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .sm\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .sm\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .sm\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .sm\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .sm\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .sm\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .sm\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .sm\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .sm\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .sm\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .sm\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .sm\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .sm\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto !important;
  }

  .sm\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .sm\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .sm\:col-auto {
    grid-column: auto !important;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1 !important;
  }

  .sm\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .sm\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .sm\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .sm\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .sm\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .sm\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .sm\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .sm\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .sm\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .sm\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .sm\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .sm\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .sm\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .sm\:col-start-auto {
    grid-column-start: auto !important;
  }

  .sm\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .sm\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .sm\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .sm\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .sm\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .sm\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .sm\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .sm\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .sm\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .sm\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .sm\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .sm\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .sm\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .sm\:col-end-auto {
    grid-column-end: auto !important;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto !important;
  }

  .sm\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .sm\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .sm\:row-auto {
    grid-row: auto !important;
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .sm\:row-span-full {
    grid-row: 1 / -1 !important;
  }

  .sm\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .sm\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .sm\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .sm\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .sm\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .sm\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .sm\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .sm\:row-start-auto {
    grid-row-start: auto !important;
  }

  .sm\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .sm\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .sm\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .sm\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .sm\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .sm\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .sm\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .sm\:row-end-auto {
    grid-row-end: auto !important;
  }

  .sm\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .sm\:transform-none {
    transform: none !important;
  }

  .sm\:origin-center {
    transform-origin: center !important;
  }

  .sm\:origin-top {
    transform-origin: top !important;
  }

  .sm\:origin-top-right {
    transform-origin: top right !important;
  }

  .sm\:origin-right {
    transform-origin: right !important;
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .sm\:origin-left {
    transform-origin: left !important;
  }

  .sm\:origin-top-left {
    transform-origin: top left !important;
  }

  .sm\:scale-0 {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .sm\:scale-50 {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .sm\:scale-75 {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .sm\:scale-90 {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .sm\:scale-95 {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .sm\:scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .sm\:scale-105 {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .sm\:scale-110 {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .sm\:scale-125 {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .sm\:scale-150 {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .sm\:scale-x-0 {
    --transform-scale-x: 0 !important;
  }

  .sm\:scale-x-50 {
    --transform-scale-x: .5 !important;
  }

  .sm\:scale-x-75 {
    --transform-scale-x: .75 !important;
  }

  .sm\:scale-x-90 {
    --transform-scale-x: .9 !important;
  }

  .sm\:scale-x-95 {
    --transform-scale-x: .95 !important;
  }

  .sm\:scale-x-100 {
    --transform-scale-x: 1 !important;
  }

  .sm\:scale-x-105 {
    --transform-scale-x: 1.05 !important;
  }

  .sm\:scale-x-110 {
    --transform-scale-x: 1.1 !important;
  }

  .sm\:scale-x-125 {
    --transform-scale-x: 1.25 !important;
  }

  .sm\:scale-x-150 {
    --transform-scale-x: 1.5 !important;
  }

  .sm\:scale-y-0 {
    --transform-scale-y: 0 !important;
  }

  .sm\:scale-y-50 {
    --transform-scale-y: .5 !important;
  }

  .sm\:scale-y-75 {
    --transform-scale-y: .75 !important;
  }

  .sm\:scale-y-90 {
    --transform-scale-y: .9 !important;
  }

  .sm\:scale-y-95 {
    --transform-scale-y: .95 !important;
  }

  .sm\:scale-y-100 {
    --transform-scale-y: 1 !important;
  }

  .sm\:scale-y-105 {
    --transform-scale-y: 1.05 !important;
  }

  .sm\:scale-y-110 {
    --transform-scale-y: 1.1 !important;
  }

  .sm\:scale-y-125 {
    --transform-scale-y: 1.25 !important;
  }

  .sm\:scale-y-150 {
    --transform-scale-y: 1.5 !important;
  }

  .sm\:hover\:scale-0:hover {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .sm\:hover\:scale-50:hover {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .sm\:hover\:scale-75:hover {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .sm\:hover\:scale-90:hover {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .sm\:hover\:scale-95:hover {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .sm\:hover\:scale-100:hover {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .sm\:hover\:scale-105:hover {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .sm\:hover\:scale-110:hover {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .sm\:hover\:scale-125:hover {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .sm\:hover\:scale-150:hover {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .sm\:hover\:scale-x-0:hover {
    --transform-scale-x: 0 !important;
  }

  .sm\:hover\:scale-x-50:hover {
    --transform-scale-x: .5 !important;
  }

  .sm\:hover\:scale-x-75:hover {
    --transform-scale-x: .75 !important;
  }

  .sm\:hover\:scale-x-90:hover {
    --transform-scale-x: .9 !important;
  }

  .sm\:hover\:scale-x-95:hover {
    --transform-scale-x: .95 !important;
  }

  .sm\:hover\:scale-x-100:hover {
    --transform-scale-x: 1 !important;
  }

  .sm\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05 !important;
  }

  .sm\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1 !important;
  }

  .sm\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25 !important;
  }

  .sm\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5 !important;
  }

  .sm\:hover\:scale-y-0:hover {
    --transform-scale-y: 0 !important;
  }

  .sm\:hover\:scale-y-50:hover {
    --transform-scale-y: .5 !important;
  }

  .sm\:hover\:scale-y-75:hover {
    --transform-scale-y: .75 !important;
  }

  .sm\:hover\:scale-y-90:hover {
    --transform-scale-y: .9 !important;
  }

  .sm\:hover\:scale-y-95:hover {
    --transform-scale-y: .95 !important;
  }

  .sm\:hover\:scale-y-100:hover {
    --transform-scale-y: 1 !important;
  }

  .sm\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05 !important;
  }

  .sm\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1 !important;
  }

  .sm\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25 !important;
  }

  .sm\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5 !important;
  }

  .sm\:focus\:scale-0:focus {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .sm\:focus\:scale-50:focus {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .sm\:focus\:scale-75:focus {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .sm\:focus\:scale-90:focus {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .sm\:focus\:scale-95:focus {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .sm\:focus\:scale-100:focus {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .sm\:focus\:scale-105:focus {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .sm\:focus\:scale-110:focus {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .sm\:focus\:scale-125:focus {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .sm\:focus\:scale-150:focus {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .sm\:focus\:scale-x-0:focus {
    --transform-scale-x: 0 !important;
  }

  .sm\:focus\:scale-x-50:focus {
    --transform-scale-x: .5 !important;
  }

  .sm\:focus\:scale-x-75:focus {
    --transform-scale-x: .75 !important;
  }

  .sm\:focus\:scale-x-90:focus {
    --transform-scale-x: .9 !important;
  }

  .sm\:focus\:scale-x-95:focus {
    --transform-scale-x: .95 !important;
  }

  .sm\:focus\:scale-x-100:focus {
    --transform-scale-x: 1 !important;
  }

  .sm\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05 !important;
  }

  .sm\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1 !important;
  }

  .sm\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25 !important;
  }

  .sm\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5 !important;
  }

  .sm\:focus\:scale-y-0:focus {
    --transform-scale-y: 0 !important;
  }

  .sm\:focus\:scale-y-50:focus {
    --transform-scale-y: .5 !important;
  }

  .sm\:focus\:scale-y-75:focus {
    --transform-scale-y: .75 !important;
  }

  .sm\:focus\:scale-y-90:focus {
    --transform-scale-y: .9 !important;
  }

  .sm\:focus\:scale-y-95:focus {
    --transform-scale-y: .95 !important;
  }

  .sm\:focus\:scale-y-100:focus {
    --transform-scale-y: 1 !important;
  }

  .sm\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05 !important;
  }

  .sm\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1 !important;
  }

  .sm\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25 !important;
  }

  .sm\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5 !important;
  }

  .sm\:rotate-0 {
    --transform-rotate: 0 !important;
  }

  .sm\:rotate-1 {
    --transform-rotate: 1deg !important;
  }

  .sm\:rotate-2 {
    --transform-rotate: 2deg !important;
  }

  .sm\:rotate-3 {
    --transform-rotate: 3deg !important;
  }

  .sm\:rotate-6 {
    --transform-rotate: 6deg !important;
  }

  .sm\:rotate-12 {
    --transform-rotate: 12deg !important;
  }

  .sm\:rotate-45 {
    --transform-rotate: 45deg !important;
  }

  .sm\:rotate-90 {
    --transform-rotate: 90deg !important;
  }

  .sm\:rotate-180 {
    --transform-rotate: 180deg !important;
  }

  .sm\:-rotate-180 {
    --transform-rotate: -180deg !important;
  }

  .sm\:-rotate-90 {
    --transform-rotate: -90deg !important;
  }

  .sm\:-rotate-45 {
    --transform-rotate: -45deg !important;
  }

  .sm\:-rotate-12 {
    --transform-rotate: -12deg !important;
  }

  .sm\:-rotate-6 {
    --transform-rotate: -6deg !important;
  }

  .sm\:-rotate-3 {
    --transform-rotate: -3deg !important;
  }

  .sm\:-rotate-2 {
    --transform-rotate: -2deg !important;
  }

  .sm\:-rotate-1 {
    --transform-rotate: -1deg !important;
  }

  .sm\:hover\:rotate-0:hover {
    --transform-rotate: 0 !important;
  }

  .sm\:hover\:rotate-1:hover {
    --transform-rotate: 1deg !important;
  }

  .sm\:hover\:rotate-2:hover {
    --transform-rotate: 2deg !important;
  }

  .sm\:hover\:rotate-3:hover {
    --transform-rotate: 3deg !important;
  }

  .sm\:hover\:rotate-6:hover {
    --transform-rotate: 6deg !important;
  }

  .sm\:hover\:rotate-12:hover {
    --transform-rotate: 12deg !important;
  }

  .sm\:hover\:rotate-45:hover {
    --transform-rotate: 45deg !important;
  }

  .sm\:hover\:rotate-90:hover {
    --transform-rotate: 90deg !important;
  }

  .sm\:hover\:rotate-180:hover {
    --transform-rotate: 180deg !important;
  }

  .sm\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg !important;
  }

  .sm\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg !important;
  }

  .sm\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg !important;
  }

  .sm\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg !important;
  }

  .sm\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg !important;
  }

  .sm\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg !important;
  }

  .sm\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg !important;
  }

  .sm\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg !important;
  }

  .sm\:focus\:rotate-0:focus {
    --transform-rotate: 0 !important;
  }

  .sm\:focus\:rotate-1:focus {
    --transform-rotate: 1deg !important;
  }

  .sm\:focus\:rotate-2:focus {
    --transform-rotate: 2deg !important;
  }

  .sm\:focus\:rotate-3:focus {
    --transform-rotate: 3deg !important;
  }

  .sm\:focus\:rotate-6:focus {
    --transform-rotate: 6deg !important;
  }

  .sm\:focus\:rotate-12:focus {
    --transform-rotate: 12deg !important;
  }

  .sm\:focus\:rotate-45:focus {
    --transform-rotate: 45deg !important;
  }

  .sm\:focus\:rotate-90:focus {
    --transform-rotate: 90deg !important;
  }

  .sm\:focus\:rotate-180:focus {
    --transform-rotate: 180deg !important;
  }

  .sm\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg !important;
  }

  .sm\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg !important;
  }

  .sm\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg !important;
  }

  .sm\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg !important;
  }

  .sm\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg !important;
  }

  .sm\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg !important;
  }

  .sm\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg !important;
  }

  .sm\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg !important;
  }

  .sm\:translate-x-0 {
    --transform-translate-x: 0 !important;
  }

  .sm\:translate-x-1 {
    --transform-translate-x: 0.25rem !important;
  }

  .sm\:translate-x-2 {
    --transform-translate-x: 0.5rem !important;
  }

  .sm\:translate-x-3 {
    --transform-translate-x: 0.75rem !important;
  }

  .sm\:translate-x-4 {
    --transform-translate-x: 1rem !important;
  }

  .sm\:translate-x-5 {
    --transform-translate-x: 1.25rem !important;
  }

  .sm\:translate-x-6 {
    --transform-translate-x: 1.5rem !important;
  }

  .sm\:translate-x-8 {
    --transform-translate-x: 2rem !important;
  }

  .sm\:translate-x-10 {
    --transform-translate-x: 2.5rem !important;
  }

  .sm\:translate-x-12 {
    --transform-translate-x: 3rem !important;
  }

  .sm\:translate-x-16 {
    --transform-translate-x: 4rem !important;
  }

  .sm\:translate-x-20 {
    --transform-translate-x: 5rem !important;
  }

  .sm\:translate-x-24 {
    --transform-translate-x: 6rem !important;
  }

  .sm\:translate-x-32 {
    --transform-translate-x: 8rem !important;
  }

  .sm\:translate-x-40 {
    --transform-translate-x: 10rem !important;
  }

  .sm\:translate-x-48 {
    --transform-translate-x: 12rem !important;
  }

  .sm\:translate-x-56 {
    --transform-translate-x: 14rem !important;
  }

  .sm\:translate-x-64 {
    --transform-translate-x: 16rem !important;
  }

  .sm\:translate-x-px {
    --transform-translate-x: 1px !important;
  }

  .sm\:-translate-x-1 {
    --transform-translate-x: -0.25rem !important;
  }

  .sm\:-translate-x-2 {
    --transform-translate-x: -0.5rem !important;
  }

  .sm\:-translate-x-3 {
    --transform-translate-x: -0.75rem !important;
  }

  .sm\:-translate-x-4 {
    --transform-translate-x: -1rem !important;
  }

  .sm\:-translate-x-5 {
    --transform-translate-x: -1.25rem !important;
  }

  .sm\:-translate-x-6 {
    --transform-translate-x: -1.5rem !important;
  }

  .sm\:-translate-x-8 {
    --transform-translate-x: -2rem !important;
  }

  .sm\:-translate-x-10 {
    --transform-translate-x: -2.5rem !important;
  }

  .sm\:-translate-x-12 {
    --transform-translate-x: -3rem !important;
  }

  .sm\:-translate-x-16 {
    --transform-translate-x: -4rem !important;
  }

  .sm\:-translate-x-20 {
    --transform-translate-x: -5rem !important;
  }

  .sm\:-translate-x-24 {
    --transform-translate-x: -6rem !important;
  }

  .sm\:-translate-x-32 {
    --transform-translate-x: -8rem !important;
  }

  .sm\:-translate-x-40 {
    --transform-translate-x: -10rem !important;
  }

  .sm\:-translate-x-48 {
    --transform-translate-x: -12rem !important;
  }

  .sm\:-translate-x-56 {
    --transform-translate-x: -14rem !important;
  }

  .sm\:-translate-x-64 {
    --transform-translate-x: -16rem !important;
  }

  .sm\:-translate-x-px {
    --transform-translate-x: -1px !important;
  }

  .sm\:-translate-x-full {
    --transform-translate-x: -100% !important;
  }

  .sm\:-translate-x-1\/2 {
    --transform-translate-x: -50% !important;
  }

  .sm\:translate-x-1\/2 {
    --transform-translate-x: 50% !important;
  }

  .sm\:translate-x-full {
    --transform-translate-x: 100% !important;
  }

  .sm\:translate-y-0 {
    --transform-translate-y: 0 !important;
  }

  .sm\:translate-y-1 {
    --transform-translate-y: 0.25rem !important;
  }

  .sm\:translate-y-2 {
    --transform-translate-y: 0.5rem !important;
  }

  .sm\:translate-y-3 {
    --transform-translate-y: 0.75rem !important;
  }

  .sm\:translate-y-4 {
    --transform-translate-y: 1rem !important;
  }

  .sm\:translate-y-5 {
    --transform-translate-y: 1.25rem !important;
  }

  .sm\:translate-y-6 {
    --transform-translate-y: 1.5rem !important;
  }

  .sm\:translate-y-8 {
    --transform-translate-y: 2rem !important;
  }

  .sm\:translate-y-10 {
    --transform-translate-y: 2.5rem !important;
  }

  .sm\:translate-y-12 {
    --transform-translate-y: 3rem !important;
  }

  .sm\:translate-y-16 {
    --transform-translate-y: 4rem !important;
  }

  .sm\:translate-y-20 {
    --transform-translate-y: 5rem !important;
  }

  .sm\:translate-y-24 {
    --transform-translate-y: 6rem !important;
  }

  .sm\:translate-y-32 {
    --transform-translate-y: 8rem !important;
  }

  .sm\:translate-y-40 {
    --transform-translate-y: 10rem !important;
  }

  .sm\:translate-y-48 {
    --transform-translate-y: 12rem !important;
  }

  .sm\:translate-y-56 {
    --transform-translate-y: 14rem !important;
  }

  .sm\:translate-y-64 {
    --transform-translate-y: 16rem !important;
  }

  .sm\:translate-y-px {
    --transform-translate-y: 1px !important;
  }

  .sm\:-translate-y-1 {
    --transform-translate-y: -0.25rem !important;
  }

  .sm\:-translate-y-2 {
    --transform-translate-y: -0.5rem !important;
  }

  .sm\:-translate-y-3 {
    --transform-translate-y: -0.75rem !important;
  }

  .sm\:-translate-y-4 {
    --transform-translate-y: -1rem !important;
  }

  .sm\:-translate-y-5 {
    --transform-translate-y: -1.25rem !important;
  }

  .sm\:-translate-y-6 {
    --transform-translate-y: -1.5rem !important;
  }

  .sm\:-translate-y-8 {
    --transform-translate-y: -2rem !important;
  }

  .sm\:-translate-y-10 {
    --transform-translate-y: -2.5rem !important;
  }

  .sm\:-translate-y-12 {
    --transform-translate-y: -3rem !important;
  }

  .sm\:-translate-y-16 {
    --transform-translate-y: -4rem !important;
  }

  .sm\:-translate-y-20 {
    --transform-translate-y: -5rem !important;
  }

  .sm\:-translate-y-24 {
    --transform-translate-y: -6rem !important;
  }

  .sm\:-translate-y-32 {
    --transform-translate-y: -8rem !important;
  }

  .sm\:-translate-y-40 {
    --transform-translate-y: -10rem !important;
  }

  .sm\:-translate-y-48 {
    --transform-translate-y: -12rem !important;
  }

  .sm\:-translate-y-56 {
    --transform-translate-y: -14rem !important;
  }

  .sm\:-translate-y-64 {
    --transform-translate-y: -16rem !important;
  }

  .sm\:-translate-y-px {
    --transform-translate-y: -1px !important;
  }

  .sm\:-translate-y-full {
    --transform-translate-y: -100% !important;
  }

  .sm\:-translate-y-1\/2 {
    --transform-translate-y: -50% !important;
  }

  .sm\:translate-y-1\/2 {
    --transform-translate-y: 50% !important;
  }

  .sm\:translate-y-full {
    --transform-translate-y: 100% !important;
  }

  .sm\:hover\:translate-x-0:hover {
    --transform-translate-x: 0 !important;
  }

  .sm\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem !important;
  }

  .sm\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem !important;
  }

  .sm\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem !important;
  }

  .sm\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem !important;
  }

  .sm\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem !important;
  }

  .sm\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem !important;
  }

  .sm\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem !important;
  }

  .sm\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem !important;
  }

  .sm\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem !important;
  }

  .sm\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem !important;
  }

  .sm\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem !important;
  }

  .sm\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem !important;
  }

  .sm\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem !important;
  }

  .sm\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem !important;
  }

  .sm\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem !important;
  }

  .sm\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem !important;
  }

  .sm\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem !important;
  }

  .sm\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px !important;
  }

  .sm\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem !important;
  }

  .sm\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem !important;
  }

  .sm\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem !important;
  }

  .sm\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem !important;
  }

  .sm\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem !important;
  }

  .sm\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem !important;
  }

  .sm\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem !important;
  }

  .sm\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem !important;
  }

  .sm\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem !important;
  }

  .sm\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem !important;
  }

  .sm\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem !important;
  }

  .sm\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem !important;
  }

  .sm\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem !important;
  }

  .sm\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem !important;
  }

  .sm\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem !important;
  }

  .sm\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem !important;
  }

  .sm\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem !important;
  }

  .sm\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px !important;
  }

  .sm\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100% !important;
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50% !important;
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50% !important;
  }

  .sm\:hover\:translate-x-full:hover {
    --transform-translate-x: 100% !important;
  }

  .sm\:hover\:translate-y-0:hover {
    --transform-translate-y: 0 !important;
  }

  .sm\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem !important;
  }

  .sm\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem !important;
  }

  .sm\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem !important;
  }

  .sm\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem !important;
  }

  .sm\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem !important;
  }

  .sm\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem !important;
  }

  .sm\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem !important;
  }

  .sm\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem !important;
  }

  .sm\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem !important;
  }

  .sm\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem !important;
  }

  .sm\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem !important;
  }

  .sm\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem !important;
  }

  .sm\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem !important;
  }

  .sm\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem !important;
  }

  .sm\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem !important;
  }

  .sm\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem !important;
  }

  .sm\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem !important;
  }

  .sm\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px !important;
  }

  .sm\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem !important;
  }

  .sm\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem !important;
  }

  .sm\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem !important;
  }

  .sm\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem !important;
  }

  .sm\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem !important;
  }

  .sm\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem !important;
  }

  .sm\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem !important;
  }

  .sm\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem !important;
  }

  .sm\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem !important;
  }

  .sm\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem !important;
  }

  .sm\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem !important;
  }

  .sm\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem !important;
  }

  .sm\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem !important;
  }

  .sm\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem !important;
  }

  .sm\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem !important;
  }

  .sm\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem !important;
  }

  .sm\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem !important;
  }

  .sm\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px !important;
  }

  .sm\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100% !important;
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50% !important;
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50% !important;
  }

  .sm\:hover\:translate-y-full:hover {
    --transform-translate-y: 100% !important;
  }

  .sm\:focus\:translate-x-0:focus {
    --transform-translate-x: 0 !important;
  }

  .sm\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem !important;
  }

  .sm\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem !important;
  }

  .sm\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem !important;
  }

  .sm\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem !important;
  }

  .sm\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem !important;
  }

  .sm\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem !important;
  }

  .sm\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem !important;
  }

  .sm\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem !important;
  }

  .sm\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem !important;
  }

  .sm\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem !important;
  }

  .sm\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem !important;
  }

  .sm\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem !important;
  }

  .sm\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem !important;
  }

  .sm\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem !important;
  }

  .sm\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem !important;
  }

  .sm\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem !important;
  }

  .sm\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem !important;
  }

  .sm\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px !important;
  }

  .sm\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem !important;
  }

  .sm\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem !important;
  }

  .sm\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem !important;
  }

  .sm\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem !important;
  }

  .sm\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem !important;
  }

  .sm\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem !important;
  }

  .sm\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem !important;
  }

  .sm\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem !important;
  }

  .sm\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem !important;
  }

  .sm\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem !important;
  }

  .sm\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem !important;
  }

  .sm\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem !important;
  }

  .sm\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem !important;
  }

  .sm\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem !important;
  }

  .sm\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem !important;
  }

  .sm\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem !important;
  }

  .sm\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem !important;
  }

  .sm\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px !important;
  }

  .sm\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100% !important;
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50% !important;
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50% !important;
  }

  .sm\:focus\:translate-x-full:focus {
    --transform-translate-x: 100% !important;
  }

  .sm\:focus\:translate-y-0:focus {
    --transform-translate-y: 0 !important;
  }

  .sm\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem !important;
  }

  .sm\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem !important;
  }

  .sm\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem !important;
  }

  .sm\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem !important;
  }

  .sm\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem !important;
  }

  .sm\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem !important;
  }

  .sm\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem !important;
  }

  .sm\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem !important;
  }

  .sm\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem !important;
  }

  .sm\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem !important;
  }

  .sm\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem !important;
  }

  .sm\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem !important;
  }

  .sm\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem !important;
  }

  .sm\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem !important;
  }

  .sm\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem !important;
  }

  .sm\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem !important;
  }

  .sm\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem !important;
  }

  .sm\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px !important;
  }

  .sm\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem !important;
  }

  .sm\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem !important;
  }

  .sm\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem !important;
  }

  .sm\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem !important;
  }

  .sm\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem !important;
  }

  .sm\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem !important;
  }

  .sm\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem !important;
  }

  .sm\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem !important;
  }

  .sm\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem !important;
  }

  .sm\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem !important;
  }

  .sm\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem !important;
  }

  .sm\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem !important;
  }

  .sm\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem !important;
  }

  .sm\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem !important;
  }

  .sm\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem !important;
  }

  .sm\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem !important;
  }

  .sm\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem !important;
  }

  .sm\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px !important;
  }

  .sm\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100% !important;
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50% !important;
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50% !important;
  }

  .sm\:focus\:translate-y-full:focus {
    --transform-translate-y: 100% !important;
  }

  .sm\:skew-x-0 {
    --transform-skew-x: 0 !important;
  }

  .sm\:skew-x-1 {
    --transform-skew-x: 1deg !important;
  }

  .sm\:skew-x-2 {
    --transform-skew-x: 2deg !important;
  }

  .sm\:skew-x-3 {
    --transform-skew-x: 3deg !important;
  }

  .sm\:skew-x-6 {
    --transform-skew-x: 6deg !important;
  }

  .sm\:skew-x-12 {
    --transform-skew-x: 12deg !important;
  }

  .sm\:-skew-x-12 {
    --transform-skew-x: -12deg !important;
  }

  .sm\:-skew-x-6 {
    --transform-skew-x: -6deg !important;
  }

  .sm\:-skew-x-3 {
    --transform-skew-x: -3deg !important;
  }

  .sm\:-skew-x-2 {
    --transform-skew-x: -2deg !important;
  }

  .sm\:-skew-x-1 {
    --transform-skew-x: -1deg !important;
  }

  .sm\:skew-y-0 {
    --transform-skew-y: 0 !important;
  }

  .sm\:skew-y-1 {
    --transform-skew-y: 1deg !important;
  }

  .sm\:skew-y-2 {
    --transform-skew-y: 2deg !important;
  }

  .sm\:skew-y-3 {
    --transform-skew-y: 3deg !important;
  }

  .sm\:skew-y-6 {
    --transform-skew-y: 6deg !important;
  }

  .sm\:skew-y-12 {
    --transform-skew-y: 12deg !important;
  }

  .sm\:-skew-y-12 {
    --transform-skew-y: -12deg !important;
  }

  .sm\:-skew-y-6 {
    --transform-skew-y: -6deg !important;
  }

  .sm\:-skew-y-3 {
    --transform-skew-y: -3deg !important;
  }

  .sm\:-skew-y-2 {
    --transform-skew-y: -2deg !important;
  }

  .sm\:-skew-y-1 {
    --transform-skew-y: -1deg !important;
  }

  .sm\:hover\:skew-x-0:hover {
    --transform-skew-x: 0 !important;
  }

  .sm\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg !important;
  }

  .sm\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg !important;
  }

  .sm\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg !important;
  }

  .sm\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg !important;
  }

  .sm\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg !important;
  }

  .sm\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg !important;
  }

  .sm\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg !important;
  }

  .sm\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg !important;
  }

  .sm\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg !important;
  }

  .sm\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg !important;
  }

  .sm\:hover\:skew-y-0:hover {
    --transform-skew-y: 0 !important;
  }

  .sm\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg !important;
  }

  .sm\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg !important;
  }

  .sm\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg !important;
  }

  .sm\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg !important;
  }

  .sm\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg !important;
  }

  .sm\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg !important;
  }

  .sm\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg !important;
  }

  .sm\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg !important;
  }

  .sm\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg !important;
  }

  .sm\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg !important;
  }

  .sm\:focus\:skew-x-0:focus {
    --transform-skew-x: 0 !important;
  }

  .sm\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg !important;
  }

  .sm\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg !important;
  }

  .sm\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg !important;
  }

  .sm\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg !important;
  }

  .sm\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg !important;
  }

  .sm\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg !important;
  }

  .sm\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg !important;
  }

  .sm\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg !important;
  }

  .sm\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg !important;
  }

  .sm\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg !important;
  }

  .sm\:focus\:skew-y-0:focus {
    --transform-skew-y: 0 !important;
  }

  .sm\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg !important;
  }

  .sm\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg !important;
  }

  .sm\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg !important;
  }

  .sm\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg !important;
  }

  .sm\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg !important;
  }

  .sm\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg !important;
  }

  .sm\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg !important;
  }

  .sm\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg !important;
  }

  .sm\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg !important;
  }

  .sm\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg !important;
  }

  .sm\:transition-none {
    transition-property: none !important;
  }

  .sm\:transition-all {
    transition-property: all !important;
  }

  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .sm\:transition-opacity {
    transition-property: opacity !important;
  }

  .sm\:transition-shadow {
    transition-property: box-shadow !important;
  }

  .sm\:transition-transform {
    transition-property: transform !important;
  }

  .sm\:ease-linear {
    transition-timing-function: linear !important;
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .sm\:duration-75 {
    transition-duration: 75ms !important;
  }

  .sm\:duration-100 {
    transition-duration: 100ms !important;
  }

  .sm\:duration-150 {
    transition-duration: 150ms !important;
  }

  .sm\:duration-200 {
    transition-duration: 200ms !important;
  }

  .sm\:duration-300 {
    transition-duration: 300ms !important;
  }

  .sm\:duration-500 {
    transition-duration: 500ms !important;
  }

  .sm\:duration-700 {
    transition-duration: 700ms !important;
  }

  .sm\:duration-1000 {
    transition-duration: 1000ms !important;
  }

  .sm\:delay-75 {
    transition-delay: 75ms !important;
  }

  .sm\:delay-100 {
    transition-delay: 100ms !important;
  }

  .sm\:delay-150 {
    transition-delay: 150ms !important;
  }

  .sm\:delay-200 {
    transition-delay: 200ms !important;
  }

  .sm\:delay-300 {
    transition-delay: 300ms !important;
  }

  .sm\:delay-500 {
    transition-delay: 500ms !important;
  }

  .sm\:delay-700 {
    transition-delay: 700ms !important;
  }

  .sm\:delay-1000 {
    transition-delay: 1000ms !important;
  }

  .sm\:animate-none {
    animation: none !important;
  }

  .sm\:animate-spin {
    animation: spin 1s linear infinite !important;
  }

  .sm\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .sm\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .sm\:animate-bounce {
    animation: bounce 1s infinite !important;
  }

  .sm\:link {
    color: var(--primary);
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 480px) {
    .md\:container {
      max-width: 480px;
    }
  }

  @media (min-width: 576px) {
    .md\:container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .md\:container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .md\:container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .md\:container {
      max-width: 1600px;
    }
  }

  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .md\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .md\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .md\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .md\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
  }

  .md\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
  }

  .md\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
  }

  .md\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
  }

  .md\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
  }

  .md\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
  }

  .md\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
  }

  .md\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
  }

  .md\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
  }

  .md\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
  }

  .md\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
  }

  .md\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
  }

  .md\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
  }

  .md\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
  }

  .md\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
  }

  .md\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
  }

  .md\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
  }

  .md\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
  }

  .md\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
  }

  .md\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--primary) !important;
  }

  .md\:divide-accent-1 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-accent-2 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
  }

  .md\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid !important;
  }

  .md\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed !important;
  }

  .md\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted !important;
  }

  .md\:divide-double > :not(template) ~ :not(template) {
    border-style: double !important;
  }

  .md\:divide-none > :not(template) ~ :not(template) {
    border-style: none !important;
  }

  .md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .md\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .md\:bg-fixed {
    background-attachment: fixed !important;
  }

  .md\:bg-local {
    background-attachment: local !important;
  }

  .md\:bg-scroll {
    background-attachment: scroll !important;
  }

  .md\:bg-clip-border {
    background-clip: border-box !important;
  }

  .md\:bg-clip-padding {
    background-clip: padding-box !important;
  }

  .md\:bg-clip-content {
    background-clip: content-box !important;
  }

  .md\:bg-clip-text {
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .md\:bg-transparent {
    background-color: transparent !important;
  }

  .md\:bg-current {
    background-color: currentColor !important;
  }

  .md\:bg-black {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .md\:bg-white {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-100 {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-200 {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-300 {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-400 {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-500 {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-600 {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-700 {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-800 {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .md\:bg-gray-900 {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .md\:bg-red-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .md\:bg-red-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .md\:bg-red-300 {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .md\:bg-red-400 {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .md\:bg-red-500 {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .md\:bg-red-600 {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .md\:bg-red-700 {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .md\:bg-red-800 {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .md\:bg-red-900 {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-100 {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-200 {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-300 {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-400 {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-500 {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-600 {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-700 {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-800 {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .md\:bg-orange-900 {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-100 {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-200 {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-300 {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-400 {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-500 {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-600 {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-700 {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-800 {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .md\:bg-yellow-900 {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .md\:bg-green-100 {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .md\:bg-green-200 {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .md\:bg-green-300 {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .md\:bg-green-400 {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .md\:bg-green-500 {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .md\:bg-green-600 {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .md\:bg-green-700 {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .md\:bg-green-800 {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .md\:bg-green-900 {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-100 {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-200 {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-300 {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-400 {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-500 {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-600 {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-700 {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-800 {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .md\:bg-teal-900 {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-200 {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-300 {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-400 {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-500 {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-600 {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-700 {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-800 {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .md\:bg-blue-900 {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-200 {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-300 {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-400 {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-500 {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-600 {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-700 {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-800 {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .md\:bg-indigo-900 {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-100 {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-200 {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-300 {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-400 {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-500 {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-600 {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-700 {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-800 {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .md\:bg-purple-900 {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-300 {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-400 {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-500 {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-600 {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-700 {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-800 {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .md\:bg-pink-900 {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .md\:bg-primary {
    background-color: var(--primary) !important;
  }

  .md\:bg-accent-1 {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .md\:bg-accent-2 {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-transparent:hover {
    background-color: transparent !important;
  }

  .md\:hover\:bg-current:hover {
    background-color: currentColor !important;
  }

  .md\:hover\:bg-black:hover {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-white:hover {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-200:hover {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-300:hover {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-400:hover {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-500:hover {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-600:hover {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-900:hover {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-300:hover {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-400:hover {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-500:hover {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-600:hover {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-red-900:hover {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-200:hover {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-600:hover {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-orange-900:hover {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-300:hover {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-400:hover {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-500:hover {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-600:hover {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-800:hover {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-green-900:hover {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-100:hover {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-200:hover {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-300:hover {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-400:hover {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-500:hover {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-600:hover {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-800:hover {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-teal-900:hover {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-200:hover {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-300:hover {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-400:hover {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-500:hover {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-600:hover {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-900:hover {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-100:hover {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-200:hover {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-300:hover {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-400:hover {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-500:hover {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-600:hover {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-700:hover {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-800:hover {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-900:hover {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-800:hover {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-900:hover {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-primary:hover {
    background-color: var(--primary) !important;
  }

  .md\:hover\:bg-accent-1:hover {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .md\:hover\:bg-accent-2:hover {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-transparent:focus {
    background-color: transparent !important;
  }

  .md\:focus\:bg-current:focus {
    background-color: currentColor !important;
  }

  .md\:focus\:bg-black:focus {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-white:focus {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-200:focus {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-300:focus {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-400:focus {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-500:focus {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-600:focus {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-900:focus {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-300:focus {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-400:focus {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-500:focus {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-600:focus {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-red-900:focus {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-200:focus {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-600:focus {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-orange-900:focus {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-300:focus {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-400:focus {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-500:focus {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-600:focus {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-800:focus {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-green-900:focus {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-100:focus {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-200:focus {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-300:focus {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-400:focus {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-500:focus {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-600:focus {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-800:focus {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-teal-900:focus {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-200:focus {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-300:focus {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-400:focus {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-500:focus {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-600:focus {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-900:focus {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-100:focus {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-200:focus {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-300:focus {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-400:focus {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-500:focus {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-600:focus {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-700:focus {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-800:focus {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-900:focus {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-800:focus {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-900:focus {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-primary:focus {
    background-color: var(--primary) !important;
  }

  .md\:focus\:bg-accent-1:focus {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .md\:focus\:bg-accent-2:focus {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .md\:bg-none {
    background-image: none !important;
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
  }

  .md\:from-transparent {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:from-current {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:from-black {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:from-white {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:from-gray-100 {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .md\:from-gray-200 {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .md\:from-gray-300 {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .md\:from-gray-400 {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .md\:from-gray-500 {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .md\:from-gray-600 {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .md\:from-gray-700 {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .md\:from-gray-800 {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .md\:from-gray-900 {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .md\:from-red-100 {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .md\:from-red-200 {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .md\:from-red-300 {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .md\:from-red-400 {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .md\:from-red-500 {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .md\:from-red-600 {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .md\:from-red-700 {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .md\:from-red-800 {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .md\:from-red-900 {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .md\:from-orange-100 {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .md\:from-orange-200 {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .md\:from-orange-300 {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .md\:from-orange-400 {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .md\:from-orange-500 {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .md\:from-orange-600 {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .md\:from-orange-700 {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .md\:from-orange-800 {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .md\:from-orange-900 {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .md\:from-yellow-100 {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .md\:from-yellow-200 {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .md\:from-yellow-300 {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .md\:from-yellow-400 {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .md\:from-yellow-500 {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .md\:from-yellow-600 {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .md\:from-yellow-700 {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .md\:from-yellow-800 {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .md\:from-yellow-900 {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .md\:from-green-100 {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .md\:from-green-200 {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .md\:from-green-300 {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .md\:from-green-400 {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .md\:from-green-500 {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .md\:from-green-600 {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .md\:from-green-700 {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .md\:from-green-800 {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .md\:from-green-900 {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .md\:from-teal-100 {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .md\:from-teal-200 {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .md\:from-teal-300 {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .md\:from-teal-400 {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .md\:from-teal-500 {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .md\:from-teal-600 {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .md\:from-teal-700 {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .md\:from-teal-800 {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .md\:from-teal-900 {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .md\:from-blue-100 {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .md\:from-blue-200 {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .md\:from-blue-300 {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .md\:from-blue-400 {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .md\:from-blue-500 {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .md\:from-blue-600 {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .md\:from-blue-700 {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .md\:from-blue-800 {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .md\:from-blue-900 {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .md\:from-indigo-100 {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .md\:from-indigo-200 {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .md\:from-indigo-300 {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .md\:from-indigo-400 {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .md\:from-indigo-500 {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .md\:from-indigo-600 {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .md\:from-indigo-700 {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .md\:from-indigo-800 {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .md\:from-indigo-900 {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .md\:from-purple-100 {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .md\:from-purple-200 {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .md\:from-purple-300 {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .md\:from-purple-400 {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .md\:from-purple-500 {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .md\:from-purple-600 {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .md\:from-purple-700 {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .md\:from-purple-800 {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .md\:from-purple-900 {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .md\:from-pink-100 {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .md\:from-pink-200 {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .md\:from-pink-300 {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .md\:from-pink-400 {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .md\:from-pink-500 {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .md\:from-pink-600 {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .md\:from-pink-700 {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .md\:from-pink-800 {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .md\:from-pink-900 {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .md\:from-primary {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:from-accent-1 {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .md\:from-accent-2 {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .md\:via-transparent {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:via-current {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:via-black {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:via-white {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:via-gray-100 {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .md\:via-gray-200 {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .md\:via-gray-300 {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .md\:via-gray-400 {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .md\:via-gray-500 {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .md\:via-gray-600 {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .md\:via-gray-700 {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .md\:via-gray-800 {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .md\:via-gray-900 {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .md\:via-red-100 {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .md\:via-red-200 {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .md\:via-red-300 {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .md\:via-red-400 {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .md\:via-red-500 {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .md\:via-red-600 {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .md\:via-red-700 {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .md\:via-red-800 {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .md\:via-red-900 {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .md\:via-orange-100 {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .md\:via-orange-200 {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .md\:via-orange-300 {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .md\:via-orange-400 {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .md\:via-orange-500 {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .md\:via-orange-600 {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .md\:via-orange-700 {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .md\:via-orange-800 {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .md\:via-orange-900 {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .md\:via-yellow-100 {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .md\:via-yellow-200 {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .md\:via-yellow-300 {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .md\:via-yellow-400 {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .md\:via-yellow-500 {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .md\:via-yellow-600 {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .md\:via-yellow-700 {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .md\:via-yellow-800 {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .md\:via-yellow-900 {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .md\:via-green-100 {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .md\:via-green-200 {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .md\:via-green-300 {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .md\:via-green-400 {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .md\:via-green-500 {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .md\:via-green-600 {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .md\:via-green-700 {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .md\:via-green-800 {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .md\:via-green-900 {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .md\:via-teal-100 {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .md\:via-teal-200 {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .md\:via-teal-300 {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .md\:via-teal-400 {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .md\:via-teal-500 {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .md\:via-teal-600 {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .md\:via-teal-700 {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .md\:via-teal-800 {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .md\:via-teal-900 {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .md\:via-blue-100 {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .md\:via-blue-200 {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .md\:via-blue-300 {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .md\:via-blue-400 {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .md\:via-blue-500 {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .md\:via-blue-600 {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .md\:via-blue-700 {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .md\:via-blue-800 {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .md\:via-blue-900 {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .md\:via-indigo-100 {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .md\:via-indigo-200 {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .md\:via-indigo-300 {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .md\:via-indigo-400 {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .md\:via-indigo-500 {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .md\:via-indigo-600 {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .md\:via-indigo-700 {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .md\:via-indigo-800 {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .md\:via-indigo-900 {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .md\:via-purple-100 {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .md\:via-purple-200 {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .md\:via-purple-300 {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .md\:via-purple-400 {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .md\:via-purple-500 {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .md\:via-purple-600 {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .md\:via-purple-700 {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .md\:via-purple-800 {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .md\:via-purple-900 {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .md\:via-pink-100 {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .md\:via-pink-200 {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .md\:via-pink-300 {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .md\:via-pink-400 {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .md\:via-pink-500 {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .md\:via-pink-600 {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .md\:via-pink-700 {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .md\:via-pink-800 {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .md\:via-pink-900 {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .md\:via-primary {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:via-accent-1 {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .md\:via-accent-2 {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .md\:to-transparent {
    --gradient-to-color: transparent !important;
  }

  .md\:to-current {
    --gradient-to-color: currentColor !important;
  }

  .md\:to-black {
    --gradient-to-color: #000 !important;
  }

  .md\:to-white {
    --gradient-to-color: #fff !important;
  }

  .md\:to-gray-100 {
    --gradient-to-color: #f7fafc !important;
  }

  .md\:to-gray-200 {
    --gradient-to-color: #edf2f7 !important;
  }

  .md\:to-gray-300 {
    --gradient-to-color: #e2e8f0 !important;
  }

  .md\:to-gray-400 {
    --gradient-to-color: #cbd5e0 !important;
  }

  .md\:to-gray-500 {
    --gradient-to-color: #a0aec0 !important;
  }

  .md\:to-gray-600 {
    --gradient-to-color: #718096 !important;
  }

  .md\:to-gray-700 {
    --gradient-to-color: #4a5568 !important;
  }

  .md\:to-gray-800 {
    --gradient-to-color: #2d3748 !important;
  }

  .md\:to-gray-900 {
    --gradient-to-color: #1a202c !important;
  }

  .md\:to-red-100 {
    --gradient-to-color: #fff5f5 !important;
  }

  .md\:to-red-200 {
    --gradient-to-color: #fed7d7 !important;
  }

  .md\:to-red-300 {
    --gradient-to-color: #feb2b2 !important;
  }

  .md\:to-red-400 {
    --gradient-to-color: #fc8181 !important;
  }

  .md\:to-red-500 {
    --gradient-to-color: #f56565 !important;
  }

  .md\:to-red-600 {
    --gradient-to-color: #e53e3e !important;
  }

  .md\:to-red-700 {
    --gradient-to-color: #c53030 !important;
  }

  .md\:to-red-800 {
    --gradient-to-color: #9b2c2c !important;
  }

  .md\:to-red-900 {
    --gradient-to-color: #742a2a !important;
  }

  .md\:to-orange-100 {
    --gradient-to-color: #fffaf0 !important;
  }

  .md\:to-orange-200 {
    --gradient-to-color: #feebc8 !important;
  }

  .md\:to-orange-300 {
    --gradient-to-color: #fbd38d !important;
  }

  .md\:to-orange-400 {
    --gradient-to-color: #f6ad55 !important;
  }

  .md\:to-orange-500 {
    --gradient-to-color: #ed8936 !important;
  }

  .md\:to-orange-600 {
    --gradient-to-color: #dd6b20 !important;
  }

  .md\:to-orange-700 {
    --gradient-to-color: #c05621 !important;
  }

  .md\:to-orange-800 {
    --gradient-to-color: #9c4221 !important;
  }

  .md\:to-orange-900 {
    --gradient-to-color: #7b341e !important;
  }

  .md\:to-yellow-100 {
    --gradient-to-color: #fffff0 !important;
  }

  .md\:to-yellow-200 {
    --gradient-to-color: #fefcbf !important;
  }

  .md\:to-yellow-300 {
    --gradient-to-color: #faf089 !important;
  }

  .md\:to-yellow-400 {
    --gradient-to-color: #f6e05e !important;
  }

  .md\:to-yellow-500 {
    --gradient-to-color: #ecc94b !important;
  }

  .md\:to-yellow-600 {
    --gradient-to-color: #d69e2e !important;
  }

  .md\:to-yellow-700 {
    --gradient-to-color: #b7791f !important;
  }

  .md\:to-yellow-800 {
    --gradient-to-color: #975a16 !important;
  }

  .md\:to-yellow-900 {
    --gradient-to-color: #744210 !important;
  }

  .md\:to-green-100 {
    --gradient-to-color: #f0fff4 !important;
  }

  .md\:to-green-200 {
    --gradient-to-color: #c6f6d5 !important;
  }

  .md\:to-green-300 {
    --gradient-to-color: #9ae6b4 !important;
  }

  .md\:to-green-400 {
    --gradient-to-color: #68d391 !important;
  }

  .md\:to-green-500 {
    --gradient-to-color: #48bb78 !important;
  }

  .md\:to-green-600 {
    --gradient-to-color: #38a169 !important;
  }

  .md\:to-green-700 {
    --gradient-to-color: #2f855a !important;
  }

  .md\:to-green-800 {
    --gradient-to-color: #276749 !important;
  }

  .md\:to-green-900 {
    --gradient-to-color: #22543d !important;
  }

  .md\:to-teal-100 {
    --gradient-to-color: #e6fffa !important;
  }

  .md\:to-teal-200 {
    --gradient-to-color: #b2f5ea !important;
  }

  .md\:to-teal-300 {
    --gradient-to-color: #81e6d9 !important;
  }

  .md\:to-teal-400 {
    --gradient-to-color: #4fd1c5 !important;
  }

  .md\:to-teal-500 {
    --gradient-to-color: #38b2ac !important;
  }

  .md\:to-teal-600 {
    --gradient-to-color: #319795 !important;
  }

  .md\:to-teal-700 {
    --gradient-to-color: #2c7a7b !important;
  }

  .md\:to-teal-800 {
    --gradient-to-color: #285e61 !important;
  }

  .md\:to-teal-900 {
    --gradient-to-color: #234e52 !important;
  }

  .md\:to-blue-100 {
    --gradient-to-color: #ebf8ff !important;
  }

  .md\:to-blue-200 {
    --gradient-to-color: #bee3f8 !important;
  }

  .md\:to-blue-300 {
    --gradient-to-color: #90cdf4 !important;
  }

  .md\:to-blue-400 {
    --gradient-to-color: #63b3ed !important;
  }

  .md\:to-blue-500 {
    --gradient-to-color: #4299e1 !important;
  }

  .md\:to-blue-600 {
    --gradient-to-color: #3182ce !important;
  }

  .md\:to-blue-700 {
    --gradient-to-color: #2b6cb0 !important;
  }

  .md\:to-blue-800 {
    --gradient-to-color: #2c5282 !important;
  }

  .md\:to-blue-900 {
    --gradient-to-color: #2a4365 !important;
  }

  .md\:to-indigo-100 {
    --gradient-to-color: #ebf4ff !important;
  }

  .md\:to-indigo-200 {
    --gradient-to-color: #c3dafe !important;
  }

  .md\:to-indigo-300 {
    --gradient-to-color: #a3bffa !important;
  }

  .md\:to-indigo-400 {
    --gradient-to-color: #7f9cf5 !important;
  }

  .md\:to-indigo-500 {
    --gradient-to-color: #667eea !important;
  }

  .md\:to-indigo-600 {
    --gradient-to-color: #5a67d8 !important;
  }

  .md\:to-indigo-700 {
    --gradient-to-color: #4c51bf !important;
  }

  .md\:to-indigo-800 {
    --gradient-to-color: #434190 !important;
  }

  .md\:to-indigo-900 {
    --gradient-to-color: #3c366b !important;
  }

  .md\:to-purple-100 {
    --gradient-to-color: #faf5ff !important;
  }

  .md\:to-purple-200 {
    --gradient-to-color: #e9d8fd !important;
  }

  .md\:to-purple-300 {
    --gradient-to-color: #d6bcfa !important;
  }

  .md\:to-purple-400 {
    --gradient-to-color: #b794f4 !important;
  }

  .md\:to-purple-500 {
    --gradient-to-color: #9f7aea !important;
  }

  .md\:to-purple-600 {
    --gradient-to-color: #805ad5 !important;
  }

  .md\:to-purple-700 {
    --gradient-to-color: #6b46c1 !important;
  }

  .md\:to-purple-800 {
    --gradient-to-color: #553c9a !important;
  }

  .md\:to-purple-900 {
    --gradient-to-color: #44337a !important;
  }

  .md\:to-pink-100 {
    --gradient-to-color: #fff5f7 !important;
  }

  .md\:to-pink-200 {
    --gradient-to-color: #fed7e2 !important;
  }

  .md\:to-pink-300 {
    --gradient-to-color: #fbb6ce !important;
  }

  .md\:to-pink-400 {
    --gradient-to-color: #f687b3 !important;
  }

  .md\:to-pink-500 {
    --gradient-to-color: #ed64a6 !important;
  }

  .md\:to-pink-600 {
    --gradient-to-color: #d53f8c !important;
  }

  .md\:to-pink-700 {
    --gradient-to-color: #b83280 !important;
  }

  .md\:to-pink-800 {
    --gradient-to-color: #97266d !important;
  }

  .md\:to-pink-900 {
    --gradient-to-color: #702459 !important;
  }

  .md\:to-primary {
    --gradient-to-color: var(--primary) !important;
  }

  .md\:to-accent-1 {
    --gradient-to-color: #fafafa !important;
  }

  .md\:to-accent-2 {
    --gradient-to-color: #eaeaea !important;
  }

  .md\:hover\:from-transparent:hover {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:from-current:hover {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:from-black:hover {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:from-white:hover {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .md\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .md\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .md\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .md\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .md\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .md\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .md\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .md\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .md\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .md\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .md\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .md\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .md\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .md\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .md\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .md\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .md\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .md\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .md\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .md\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .md\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .md\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .md\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .md\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .md\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .md\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .md\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .md\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .md\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .md\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .md\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .md\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .md\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .md\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .md\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .md\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .md\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .md\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .md\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .md\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .md\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .md\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .md\:hover\:from-green-800:hover {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .md\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .md\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .md\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .md\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .md\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .md\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .md\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .md\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .md\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .md\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .md\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .md\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .md\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .md\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .md\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .md\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .md\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .md\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .md\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .md\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .md\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .md\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .md\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .md\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .md\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .md\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .md\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .md\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .md\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .md\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .md\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .md\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .md\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .md\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .md\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .md\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .md\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .md\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .md\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .md\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .md\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .md\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .md\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .md\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .md\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .md\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .md\:hover\:from-primary:hover {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:from-accent-1:hover {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .md\:hover\:from-accent-2:hover {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .md\:hover\:via-transparent:hover {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:via-current:hover {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:via-black:hover {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:via-white:hover {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .md\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .md\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .md\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .md\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .md\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .md\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .md\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .md\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .md\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .md\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .md\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .md\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .md\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .md\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .md\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .md\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .md\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .md\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .md\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .md\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .md\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .md\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .md\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .md\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .md\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .md\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .md\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .md\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .md\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .md\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .md\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .md\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .md\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .md\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .md\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .md\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .md\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .md\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .md\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .md\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .md\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .md\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .md\:hover\:via-green-800:hover {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .md\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .md\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .md\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .md\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .md\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .md\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .md\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .md\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .md\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .md\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .md\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .md\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .md\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .md\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .md\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .md\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .md\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .md\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .md\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .md\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .md\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .md\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .md\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .md\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .md\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .md\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .md\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .md\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .md\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .md\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .md\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .md\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .md\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .md\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .md\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .md\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .md\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .md\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .md\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .md\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .md\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .md\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .md\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .md\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .md\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .md\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .md\:hover\:via-primary:hover {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:via-accent-1:hover {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .md\:hover\:via-accent-2:hover {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .md\:hover\:to-transparent:hover {
    --gradient-to-color: transparent !important;
  }

  .md\:hover\:to-current:hover {
    --gradient-to-color: currentColor !important;
  }

  .md\:hover\:to-black:hover {
    --gradient-to-color: #000 !important;
  }

  .md\:hover\:to-white:hover {
    --gradient-to-color: #fff !important;
  }

  .md\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc !important;
  }

  .md\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7 !important;
  }

  .md\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0 !important;
  }

  .md\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0 !important;
  }

  .md\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0 !important;
  }

  .md\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096 !important;
  }

  .md\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568 !important;
  }

  .md\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748 !important;
  }

  .md\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c !important;
  }

  .md\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5 !important;
  }

  .md\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7 !important;
  }

  .md\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2 !important;
  }

  .md\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181 !important;
  }

  .md\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565 !important;
  }

  .md\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e !important;
  }

  .md\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030 !important;
  }

  .md\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c !important;
  }

  .md\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a !important;
  }

  .md\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0 !important;
  }

  .md\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8 !important;
  }

  .md\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d !important;
  }

  .md\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55 !important;
  }

  .md\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936 !important;
  }

  .md\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20 !important;
  }

  .md\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621 !important;
  }

  .md\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221 !important;
  }

  .md\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e !important;
  }

  .md\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0 !important;
  }

  .md\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf !important;
  }

  .md\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089 !important;
  }

  .md\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e !important;
  }

  .md\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b !important;
  }

  .md\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e !important;
  }

  .md\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f !important;
  }

  .md\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16 !important;
  }

  .md\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210 !important;
  }

  .md\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4 !important;
  }

  .md\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5 !important;
  }

  .md\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4 !important;
  }

  .md\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391 !important;
  }

  .md\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78 !important;
  }

  .md\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169 !important;
  }

  .md\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a !important;
  }

  .md\:hover\:to-green-800:hover {
    --gradient-to-color: #276749 !important;
  }

  .md\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d !important;
  }

  .md\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa !important;
  }

  .md\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea !important;
  }

  .md\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9 !important;
  }

  .md\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5 !important;
  }

  .md\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac !important;
  }

  .md\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795 !important;
  }

  .md\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b !important;
  }

  .md\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61 !important;
  }

  .md\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52 !important;
  }

  .md\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff !important;
  }

  .md\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8 !important;
  }

  .md\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4 !important;
  }

  .md\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed !important;
  }

  .md\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1 !important;
  }

  .md\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce !important;
  }

  .md\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0 !important;
  }

  .md\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282 !important;
  }

  .md\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365 !important;
  }

  .md\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff !important;
  }

  .md\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe !important;
  }

  .md\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa !important;
  }

  .md\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5 !important;
  }

  .md\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea !important;
  }

  .md\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8 !important;
  }

  .md\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf !important;
  }

  .md\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190 !important;
  }

  .md\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b !important;
  }

  .md\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff !important;
  }

  .md\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd !important;
  }

  .md\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa !important;
  }

  .md\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4 !important;
  }

  .md\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea !important;
  }

  .md\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5 !important;
  }

  .md\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1 !important;
  }

  .md\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a !important;
  }

  .md\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a !important;
  }

  .md\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7 !important;
  }

  .md\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2 !important;
  }

  .md\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce !important;
  }

  .md\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3 !important;
  }

  .md\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6 !important;
  }

  .md\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c !important;
  }

  .md\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280 !important;
  }

  .md\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d !important;
  }

  .md\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459 !important;
  }

  .md\:hover\:to-primary:hover {
    --gradient-to-color: var(--primary) !important;
  }

  .md\:hover\:to-accent-1:hover {
    --gradient-to-color: #fafafa !important;
  }

  .md\:hover\:to-accent-2:hover {
    --gradient-to-color: #eaeaea !important;
  }

  .md\:focus\:from-transparent:focus {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:from-current:focus {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:from-black:focus {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:from-white:focus {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .md\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .md\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .md\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .md\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .md\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .md\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .md\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .md\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .md\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .md\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .md\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .md\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .md\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .md\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .md\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .md\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .md\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .md\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .md\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .md\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .md\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .md\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .md\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .md\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .md\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .md\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .md\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .md\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .md\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .md\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .md\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .md\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .md\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .md\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .md\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .md\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .md\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .md\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .md\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .md\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .md\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .md\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .md\:focus\:from-green-800:focus {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .md\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .md\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .md\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .md\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .md\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .md\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .md\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .md\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .md\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .md\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .md\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .md\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .md\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .md\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .md\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .md\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .md\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .md\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .md\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .md\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .md\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .md\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .md\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .md\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .md\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .md\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .md\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .md\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .md\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .md\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .md\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .md\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .md\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .md\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .md\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .md\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .md\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .md\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .md\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .md\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .md\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .md\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .md\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .md\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .md\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .md\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .md\:focus\:from-primary:focus {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:from-accent-1:focus {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .md\:focus\:from-accent-2:focus {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .md\:focus\:via-transparent:focus {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:via-current:focus {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:via-black:focus {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:via-white:focus {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .md\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .md\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .md\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .md\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .md\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .md\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .md\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .md\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .md\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .md\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .md\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .md\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .md\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .md\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .md\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .md\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .md\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .md\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .md\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .md\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .md\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .md\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .md\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .md\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .md\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .md\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .md\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .md\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .md\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .md\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .md\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .md\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .md\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .md\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .md\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .md\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .md\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .md\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .md\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .md\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .md\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .md\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .md\:focus\:via-green-800:focus {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .md\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .md\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .md\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .md\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .md\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .md\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .md\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .md\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .md\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .md\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .md\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .md\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .md\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .md\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .md\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .md\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .md\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .md\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .md\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .md\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .md\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .md\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .md\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .md\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .md\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .md\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .md\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .md\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .md\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .md\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .md\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .md\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .md\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .md\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .md\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .md\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .md\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .md\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .md\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .md\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .md\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .md\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .md\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .md\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .md\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .md\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .md\:focus\:via-primary:focus {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:via-accent-1:focus {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .md\:focus\:via-accent-2:focus {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .md\:focus\:to-transparent:focus {
    --gradient-to-color: transparent !important;
  }

  .md\:focus\:to-current:focus {
    --gradient-to-color: currentColor !important;
  }

  .md\:focus\:to-black:focus {
    --gradient-to-color: #000 !important;
  }

  .md\:focus\:to-white:focus {
    --gradient-to-color: #fff !important;
  }

  .md\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc !important;
  }

  .md\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7 !important;
  }

  .md\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0 !important;
  }

  .md\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0 !important;
  }

  .md\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0 !important;
  }

  .md\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096 !important;
  }

  .md\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568 !important;
  }

  .md\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748 !important;
  }

  .md\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c !important;
  }

  .md\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5 !important;
  }

  .md\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7 !important;
  }

  .md\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2 !important;
  }

  .md\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181 !important;
  }

  .md\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565 !important;
  }

  .md\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e !important;
  }

  .md\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030 !important;
  }

  .md\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c !important;
  }

  .md\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a !important;
  }

  .md\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0 !important;
  }

  .md\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8 !important;
  }

  .md\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d !important;
  }

  .md\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55 !important;
  }

  .md\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936 !important;
  }

  .md\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20 !important;
  }

  .md\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621 !important;
  }

  .md\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221 !important;
  }

  .md\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e !important;
  }

  .md\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0 !important;
  }

  .md\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf !important;
  }

  .md\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089 !important;
  }

  .md\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e !important;
  }

  .md\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b !important;
  }

  .md\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e !important;
  }

  .md\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f !important;
  }

  .md\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16 !important;
  }

  .md\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210 !important;
  }

  .md\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4 !important;
  }

  .md\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5 !important;
  }

  .md\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4 !important;
  }

  .md\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391 !important;
  }

  .md\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78 !important;
  }

  .md\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169 !important;
  }

  .md\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a !important;
  }

  .md\:focus\:to-green-800:focus {
    --gradient-to-color: #276749 !important;
  }

  .md\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d !important;
  }

  .md\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa !important;
  }

  .md\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea !important;
  }

  .md\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9 !important;
  }

  .md\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5 !important;
  }

  .md\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac !important;
  }

  .md\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795 !important;
  }

  .md\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b !important;
  }

  .md\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61 !important;
  }

  .md\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52 !important;
  }

  .md\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff !important;
  }

  .md\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8 !important;
  }

  .md\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4 !important;
  }

  .md\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed !important;
  }

  .md\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1 !important;
  }

  .md\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce !important;
  }

  .md\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0 !important;
  }

  .md\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282 !important;
  }

  .md\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365 !important;
  }

  .md\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff !important;
  }

  .md\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe !important;
  }

  .md\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa !important;
  }

  .md\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5 !important;
  }

  .md\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea !important;
  }

  .md\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8 !important;
  }

  .md\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf !important;
  }

  .md\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190 !important;
  }

  .md\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b !important;
  }

  .md\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff !important;
  }

  .md\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd !important;
  }

  .md\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa !important;
  }

  .md\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4 !important;
  }

  .md\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea !important;
  }

  .md\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5 !important;
  }

  .md\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1 !important;
  }

  .md\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a !important;
  }

  .md\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a !important;
  }

  .md\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7 !important;
  }

  .md\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2 !important;
  }

  .md\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce !important;
  }

  .md\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3 !important;
  }

  .md\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6 !important;
  }

  .md\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c !important;
  }

  .md\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280 !important;
  }

  .md\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d !important;
  }

  .md\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459 !important;
  }

  .md\:focus\:to-primary:focus {
    --gradient-to-color: var(--primary) !important;
  }

  .md\:focus\:to-accent-1:focus {
    --gradient-to-color: #fafafa !important;
  }

  .md\:focus\:to-accent-2:focus {
    --gradient-to-color: #eaeaea !important;
  }

  .md\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .md\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .md\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .md\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .md\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .md\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .md\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .md\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .md\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .md\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .md\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .md\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .md\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .md\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .md\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .md\:bg-bottom {
    background-position: bottom !important;
  }

  .md\:bg-center {
    background-position: center !important;
  }

  .md\:bg-left {
    background-position: left !important;
  }

  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .md\:bg-left-top {
    background-position: left top !important;
  }

  .md\:bg-right {
    background-position: right !important;
  }

  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .md\:bg-right-top {
    background-position: right top !important;
  }

  .md\:bg-top {
    background-position: top !important;
  }

  .md\:bg-repeat {
    background-repeat: repeat !important;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .md\:bg-repeat-round {
    background-repeat: round !important;
  }

  .md\:bg-repeat-space {
    background-repeat: space !important;
  }

  .md\:bg-auto {
    background-size: auto !important;
  }

  .md\:bg-cover {
    background-size: cover !important;
  }

  .md\:bg-contain {
    background-size: contain !important;
  }

  .md\:border-collapse {
    border-collapse: collapse !important;
  }

  .md\:border-separate {
    border-collapse: separate !important;
  }

  .md\:border-transparent {
    border-color: transparent !important;
  }

  .md\:border-current {
    border-color: currentColor !important;
  }

  .md\:border-black {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .md\:border-white {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .md\:border-gray-100 {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .md\:border-gray-200 {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .md\:border-gray-300 {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .md\:border-gray-400 {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .md\:border-gray-500 {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .md\:border-gray-600 {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .md\:border-gray-700 {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .md\:border-gray-800 {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .md\:border-gray-900 {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .md\:border-red-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .md\:border-red-200 {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .md\:border-red-300 {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .md\:border-red-400 {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .md\:border-red-500 {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .md\:border-red-600 {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .md\:border-red-700 {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .md\:border-red-800 {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .md\:border-red-900 {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .md\:border-orange-100 {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .md\:border-orange-200 {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .md\:border-orange-300 {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .md\:border-orange-400 {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .md\:border-orange-500 {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .md\:border-orange-600 {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .md\:border-orange-700 {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .md\:border-orange-800 {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .md\:border-orange-900 {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .md\:border-yellow-100 {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .md\:border-yellow-200 {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .md\:border-yellow-300 {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .md\:border-yellow-400 {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .md\:border-yellow-500 {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .md\:border-yellow-600 {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .md\:border-yellow-700 {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .md\:border-yellow-800 {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .md\:border-yellow-900 {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .md\:border-green-100 {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .md\:border-green-200 {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .md\:border-green-300 {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .md\:border-green-400 {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .md\:border-green-500 {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .md\:border-green-600 {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .md\:border-green-700 {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .md\:border-green-800 {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .md\:border-green-900 {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .md\:border-teal-100 {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .md\:border-teal-200 {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .md\:border-teal-300 {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .md\:border-teal-400 {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .md\:border-teal-500 {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .md\:border-teal-600 {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .md\:border-teal-700 {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .md\:border-teal-800 {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .md\:border-teal-900 {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .md\:border-blue-100 {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .md\:border-blue-200 {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .md\:border-blue-300 {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .md\:border-blue-400 {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .md\:border-blue-500 {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .md\:border-blue-600 {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .md\:border-blue-700 {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .md\:border-blue-800 {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .md\:border-blue-900 {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .md\:border-indigo-100 {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .md\:border-indigo-200 {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .md\:border-indigo-300 {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .md\:border-indigo-400 {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .md\:border-indigo-500 {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .md\:border-indigo-600 {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .md\:border-indigo-700 {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .md\:border-indigo-800 {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .md\:border-indigo-900 {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .md\:border-purple-100 {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .md\:border-purple-200 {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .md\:border-purple-300 {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .md\:border-purple-400 {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .md\:border-purple-500 {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .md\:border-purple-600 {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .md\:border-purple-700 {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .md\:border-purple-800 {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .md\:border-purple-900 {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .md\:border-pink-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .md\:border-pink-200 {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .md\:border-pink-300 {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .md\:border-pink-400 {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .md\:border-pink-500 {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .md\:border-pink-600 {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .md\:border-pink-700 {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .md\:border-pink-800 {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .md\:border-pink-900 {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .md\:border-primary {
    border-color: var(--primary) !important;
  }

  .md\:border-accent-1 {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .md\:border-accent-2 {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .md\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  .md\:hover\:border-current:hover {
    border-color: currentColor !important;
  }

  .md\:hover\:border-black:hover {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .md\:hover\:border-white:hover {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-100:hover {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-200:hover {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-300:hover {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-400:hover {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-500:hover {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-600:hover {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-700:hover {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-800:hover {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .md\:hover\:border-gray-900:hover {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-300:hover {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-400:hover {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-500:hover {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-600:hover {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-700:hover {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-800:hover {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .md\:hover\:border-red-900:hover {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-200:hover {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-600:hover {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-700:hover {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-800:hover {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .md\:hover\:border-orange-900:hover {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-200:hover {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-300:hover {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-500:hover {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-600:hover {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-700:hover {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-800:hover {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .md\:hover\:border-yellow-900:hover {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-100:hover {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-200:hover {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-300:hover {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-400:hover {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-500:hover {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-600:hover {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-700:hover {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-800:hover {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .md\:hover\:border-green-900:hover {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-100:hover {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-200:hover {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-300:hover {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-400:hover {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-500:hover {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-600:hover {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-700:hover {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-800:hover {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .md\:hover\:border-teal-900:hover {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-200:hover {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-300:hover {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-400:hover {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-500:hover {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-600:hover {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-700:hover {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-800:hover {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .md\:hover\:border-blue-900:hover {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-200:hover {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-300:hover {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-400:hover {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-500:hover {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-600:hover {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-700:hover {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-800:hover {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .md\:hover\:border-indigo-900:hover {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-100:hover {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-200:hover {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-300:hover {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-400:hover {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-500:hover {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-600:hover {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-700:hover {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-800:hover {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .md\:hover\:border-purple-900:hover {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-400:hover {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-600:hover {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-700:hover {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-800:hover {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .md\:hover\:border-pink-900:hover {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .md\:hover\:border-primary:hover {
    border-color: var(--primary) !important;
  }

  .md\:hover\:border-accent-1:hover {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .md\:hover\:border-accent-2:hover {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .md\:focus\:border-transparent:focus {
    border-color: transparent !important;
  }

  .md\:focus\:border-current:focus {
    border-color: currentColor !important;
  }

  .md\:focus\:border-black:focus {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .md\:focus\:border-white:focus {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-100:focus {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-200:focus {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-300:focus {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-400:focus {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-500:focus {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-600:focus {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-700:focus {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-800:focus {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .md\:focus\:border-gray-900:focus {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-300:focus {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-400:focus {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-500:focus {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-600:focus {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-700:focus {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-800:focus {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .md\:focus\:border-red-900:focus {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-200:focus {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-600:focus {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-700:focus {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-800:focus {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .md\:focus\:border-orange-900:focus {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-200:focus {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-300:focus {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-500:focus {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-600:focus {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-700:focus {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-800:focus {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .md\:focus\:border-yellow-900:focus {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-100:focus {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-200:focus {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-300:focus {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-400:focus {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-500:focus {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-600:focus {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-700:focus {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-800:focus {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .md\:focus\:border-green-900:focus {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-100:focus {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-200:focus {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-300:focus {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-400:focus {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-500:focus {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-600:focus {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-700:focus {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-800:focus {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .md\:focus\:border-teal-900:focus {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-200:focus {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-300:focus {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-400:focus {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-500:focus {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-600:focus {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-700:focus {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-800:focus {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .md\:focus\:border-blue-900:focus {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-200:focus {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-300:focus {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-400:focus {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-500:focus {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-600:focus {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-700:focus {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-800:focus {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .md\:focus\:border-indigo-900:focus {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-100:focus {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-200:focus {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-300:focus {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-400:focus {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-500:focus {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-600:focus {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-700:focus {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-800:focus {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .md\:focus\:border-purple-900:focus {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-400:focus {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-600:focus {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-700:focus {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-800:focus {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .md\:focus\:border-pink-900:focus {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .md\:focus\:border-primary:focus {
    border-color: var(--primary) !important;
  }

  .md\:focus\:border-accent-1:focus {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .md\:focus\:border-accent-2:focus {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .md\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .md\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .md\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .md\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .md\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .md\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .md\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .md\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .md\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .md\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .md\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .md\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .md\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .md\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .md\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .md\:rounded-none {
    border-radius: 0 !important;
  }

  .md\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .md\:rounded {
    border-radius: 0.25rem !important;
  }

  .md\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .md\:rounded-xl {
    border-radius: 0.75rem !important;
  }

  .md\:rounded-2xl {
    border-radius: 1rem !important;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem !important;
  }

  .md\:rounded-full {
    border-radius: 9999px !important;
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .md\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-t-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .md\:rounded-r-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .md\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:rounded-l-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .md\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .md\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: 0.75rem !important;
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: 0.75rem !important;
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem !important;
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem !important;
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem !important;
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem !important;
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .md\:border-solid {
    border-style: solid !important;
  }

  .md\:border-dashed {
    border-style: dashed !important;
  }

  .md\:border-dotted {
    border-style: dotted !important;
  }

  .md\:border-double {
    border-style: double !important;
  }

  .md\:border-none {
    border-style: none !important;
  }

  .md\:border-0 {
    border-width: 0 !important;
  }

  .md\:border-2 {
    border-width: 2px !important;
  }

  .md\:border-4 {
    border-width: 4px !important;
  }

  .md\:border-8 {
    border-width: 8px !important;
  }

  .md\:border {
    border-width: 1px !important;
  }

  .md\:border-t-0 {
    border-top-width: 0 !important;
  }

  .md\:border-r-0 {
    border-right-width: 0 !important;
  }

  .md\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .md\:border-l-0 {
    border-left-width: 0 !important;
  }

  .md\:border-t-2 {
    border-top-width: 2px !important;
  }

  .md\:border-r-2 {
    border-right-width: 2px !important;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .md\:border-l-2 {
    border-left-width: 2px !important;
  }

  .md\:border-t-4 {
    border-top-width: 4px !important;
  }

  .md\:border-r-4 {
    border-right-width: 4px !important;
  }

  .md\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .md\:border-l-4 {
    border-left-width: 4px !important;
  }

  .md\:border-t-8 {
    border-top-width: 8px !important;
  }

  .md\:border-r-8 {
    border-right-width: 8px !important;
  }

  .md\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .md\:border-l-8 {
    border-left-width: 8px !important;
  }

  .md\:border-t {
    border-top-width: 1px !important;
  }

  .md\:border-r {
    border-right-width: 1px !important;
  }

  .md\:border-b {
    border-bottom-width: 1px !important;
  }

  .md\:border-l {
    border-left-width: 1px !important;
  }

  .md\:box-border {
    box-sizing: border-box !important;
  }

  .md\:box-content {
    box-sizing: content-box !important;
  }

  .md\:cursor-auto {
    cursor: auto !important;
  }

  .md\:cursor-default {
    cursor: default !important;
  }

  .md\:cursor-pointer {
    cursor: pointer !important;
  }

  .md\:cursor-wait {
    cursor: wait !important;
  }

  .md\:cursor-text {
    cursor: text !important;
  }

  .md\:cursor-move {
    cursor: move !important;
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:inline {
    display: inline !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:inline-flex {
    display: inline-flex !important;
  }

  .md\:table {
    display: table !important;
  }

  .md\:table-caption {
    display: table-caption !important;
  }

  .md\:table-cell {
    display: table-cell !important;
  }

  .md\:table-column {
    display: table-column !important;
  }

  .md\:table-column-group {
    display: table-column-group !important;
  }

  .md\:table-footer-group {
    display: table-footer-group !important;
  }

  .md\:table-header-group {
    display: table-header-group !important;
  }

  .md\:table-row-group {
    display: table-row-group !important;
  }

  .md\:table-row {
    display: table-row !important;
  }

  .md\:flow-root {
    display: flow-root !important;
  }

  .md\:grid {
    display: grid !important;
  }

  .md\:inline-grid {
    display: inline-grid !important;
  }

  .md\:contents {
    display: contents !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .md\:flex-col {
    flex-direction: column !important;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap !important;
  }

  .md\:place-items-auto {
    place-items: auto !important;
  }

  .md\:place-items-start {
    place-items: start !important;
  }

  .md\:place-items-end {
    place-items: end !important;
  }

  .md\:place-items-center {
    place-items: center !important;
  }

  .md\:place-items-stretch {
    place-items: stretch !important;
  }

  .md\:place-content-center {
    place-content: center !important;
  }

  .md\:place-content-start {
    place-content: start !important;
  }

  .md\:place-content-end {
    place-content: end !important;
  }

  .md\:place-content-between {
    place-content: space-between !important;
  }

  .md\:place-content-around {
    place-content: space-around !important;
  }

  .md\:place-content-evenly {
    place-content: space-evenly !important;
  }

  .md\:place-content-stretch {
    place-content: stretch !important;
  }

  .md\:place-self-auto {
    place-self: auto !important;
  }

  .md\:place-self-start {
    place-self: start !important;
  }

  .md\:place-self-end {
    place-self: end !important;
  }

  .md\:place-self-center {
    place-self: center !important;
  }

  .md\:place-self-stretch {
    place-self: stretch !important;
  }

  .md\:items-start {
    align-items: flex-start !important;
  }

  .md\:items-end {
    align-items: flex-end !important;
  }

  .md\:items-center {
    align-items: center !important;
  }

  .md\:items-baseline {
    align-items: baseline !important;
  }

  .md\:items-stretch {
    align-items: stretch !important;
  }

  .md\:content-center {
    align-content: center !important;
  }

  .md\:content-start {
    align-content: flex-start !important;
  }

  .md\:content-end {
    align-content: flex-end !important;
  }

  .md\:content-between {
    align-content: space-between !important;
  }

  .md\:content-around {
    align-content: space-around !important;
  }

  .md\:content-evenly {
    align-content: space-evenly !important;
  }

  .md\:self-auto {
    align-self: auto !important;
  }

  .md\:self-start {
    align-self: flex-start !important;
  }

  .md\:self-end {
    align-self: flex-end !important;
  }

  .md\:self-center {
    align-self: center !important;
  }

  .md\:self-stretch {
    align-self: stretch !important;
  }

  .md\:justify-items-auto {
    justify-items: auto !important;
  }

  .md\:justify-items-start {
    justify-items: start !important;
  }

  .md\:justify-items-end {
    justify-items: end !important;
  }

  .md\:justify-items-center {
    justify-items: center !important;
  }

  .md\:justify-items-stretch {
    justify-items: stretch !important;
  }

  .md\:justify-start {
    justify-content: flex-start !important;
  }

  .md\:justify-end {
    justify-content: flex-end !important;
  }

  .md\:justify-center {
    justify-content: center !important;
  }

  .md\:justify-between {
    justify-content: space-between !important;
  }

  .md\:justify-around {
    justify-content: space-around !important;
  }

  .md\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .md\:justify-self-auto {
    justify-self: auto !important;
  }

  .md\:justify-self-start {
    justify-self: start !important;
  }

  .md\:justify-self-end {
    justify-self: end !important;
  }

  .md\:justify-self-center {
    justify-self: center !important;
  }

  .md\:justify-self-stretch {
    justify-self: stretch !important;
  }

  .md\:flex-1 {
    flex: 1 1 0% !important;
  }

  .md\:flex-auto {
    flex: 1 1 auto !important;
  }

  .md\:flex-initial {
    flex: 0 1 auto !important;
  }

  .md\:flex-none {
    flex: none !important;
  }

  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .md\:flex-grow {
    flex-grow: 1 !important;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .md\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .md\:order-1 {
    order: 1 !important;
  }

  .md\:order-2 {
    order: 2 !important;
  }

  .md\:order-3 {
    order: 3 !important;
  }

  .md\:order-4 {
    order: 4 !important;
  }

  .md\:order-5 {
    order: 5 !important;
  }

  .md\:order-6 {
    order: 6 !important;
  }

  .md\:order-7 {
    order: 7 !important;
  }

  .md\:order-8 {
    order: 8 !important;
  }

  .md\:order-9 {
    order: 9 !important;
  }

  .md\:order-10 {
    order: 10 !important;
  }

  .md\:order-11 {
    order: 11 !important;
  }

  .md\:order-12 {
    order: 12 !important;
  }

  .md\:order-first {
    order: -9999 !important;
  }

  .md\:order-last {
    order: 9999 !important;
  }

  .md\:order-none {
    order: 0 !important;
  }

  .md\:float-right {
    float: right !important;
  }

  .md\:float-left {
    float: left !important;
  }

  .md\:float-none {
    float: none !important;
  }

  .md\:clearfix:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }

  .md\:clear-left {
    clear: left !important;
  }

  .md\:clear-right {
    clear: right !important;
  }

  .md\:clear-both {
    clear: both !important;
  }

  .md\:clear-none {
    clear: none !important;
  }

  .md\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .md\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .md\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .md\:font-hairline {
    font-weight: 100 !important;
  }

  .md\:font-thin {
    font-weight: 200 !important;
  }

  .md\:font-light {
    font-weight: 300 !important;
  }

  .md\:font-normal {
    font-weight: 400 !important;
  }

  .md\:font-medium {
    font-weight: 500 !important;
  }

  .md\:font-semibold {
    font-weight: 600 !important;
  }

  .md\:font-bold {
    font-weight: 700 !important;
  }

  .md\:font-extrabold {
    font-weight: 800 !important;
  }

  .md\:font-black {
    font-weight: 900 !important;
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100 !important;
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200 !important;
  }

  .md\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500 !important;
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600 !important;
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800 !important;
  }

  .md\:hover\:font-black:hover {
    font-weight: 900 !important;
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100 !important;
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200 !important;
  }

  .md\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500 !important;
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600 !important;
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800 !important;
  }

  .md\:focus\:font-black:focus {
    font-weight: 900 !important;
  }

  .md\:h-0 {
    height: 0 !important;
  }

  .md\:h-1 {
    height: 0.25rem !important;
  }

  .md\:h-2 {
    height: 0.5rem !important;
  }

  .md\:h-3 {
    height: 0.75rem !important;
  }

  .md\:h-4 {
    height: 1rem !important;
  }

  .md\:h-5 {
    height: 1.25rem !important;
  }

  .md\:h-6 {
    height: 1.5rem !important;
  }

  .md\:h-8 {
    height: 2rem !important;
  }

  .md\:h-10 {
    height: 2.5rem !important;
  }

  .md\:h-12 {
    height: 3rem !important;
  }

  .md\:h-16 {
    height: 4rem !important;
  }

  .md\:h-20 {
    height: 5rem !important;
  }

  .md\:h-24 {
    height: 6rem !important;
  }

  .md\:h-32 {
    height: 8rem !important;
  }

  .md\:h-40 {
    height: 10rem !important;
  }

  .md\:h-48 {
    height: 12rem !important;
  }

  .md\:h-56 {
    height: 14rem !important;
  }

  .md\:h-64 {
    height: 16rem !important;
  }

  .md\:h-auto {
    height: auto !important;
  }

  .md\:h-px {
    height: 1px !important;
  }

  .md\:h-full {
    height: 100% !important;
  }

  .md\:h-screen {
    height: 100vh !important;
  }

  .md\:text-xs {
    font-size: 0.80em !important;
  }

  .md\:text-sm {
    font-size: 0.975em !important;
  }

  .md\:text-base {
    font-size: 1em !important;
  }

  .md\:text-lg {
    font-size: 1.125em !important;
  }

  .md\:text-xl {
    font-size: 1.25em !important;
  }

  .md\:text-2xl {
    font-size: 1.5em !important;
  }

  .md\:text-3xl {
    font-size: 1.875em !important;
  }

  .md\:text-4xl {
    font-size: 2.25em !important;
  }

  .md\:text-5xl {
    font-size: 3em !important;
  }

  .md\:text-6xl {
    font-size: 4em !important;
  }

  .md\:text-md {
    font-size: 1em !important;
  }

  .md\:text-sm-title {
    font-size:  18px !important;
  }

  .md\:text-md-title {
    font-size: 22px !important;
  }

  .md\:text-lg-title {
    font-size: 36px !important;
  }

  .md\:text-size-body {
    font-size: 12px !important;
  }

  .md\:text-size-label {
    font-size: 10px !important;
  }

  .md\:leading-3 {
    line-height: .75rem !important;
  }

  .md\:leading-4 {
    line-height: 1rem !important;
  }

  .md\:leading-5 {
    line-height: 1.25rem !important;
  }

  .md\:leading-6 {
    line-height: 1.5rem !important;
  }

  .md\:leading-7 {
    line-height: 1.75rem !important;
  }

  .md\:leading-8 {
    line-height: 2rem !important;
  }

  .md\:leading-9 {
    line-height: 2.25rem !important;
  }

  .md\:leading-10 {
    line-height: 2.5rem !important;
  }

  .md\:leading-none {
    line-height: 1 !important;
  }

  .md\:leading-tight {
    line-height: 1.25 !important;
  }

  .md\:leading-snug {
    line-height: 1.375 !important;
  }

  .md\:leading-normal {
    line-height: 1.5 !important;
  }

  .md\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .md\:leading-loose {
    line-height: 2 !important;
  }

  .md\:list-inside {
    list-style-position: inside !important;
  }

  .md\:list-outside {
    list-style-position: outside !important;
  }

  .md\:list-none {
    list-style-type: none !important;
  }

  .md\:list-disc {
    list-style-type: disc !important;
  }

  .md\:list-decimal {
    list-style-type: decimal !important;
  }

  .md\:m-0 {
    margin: 0 !important;
  }

  .md\:m-1 {
    margin: 0.25rem !important;
  }

  .md\:m-2 {
    margin: 0.5rem !important;
  }

  .md\:m-3 {
    margin: 0.75rem !important;
  }

  .md\:m-4 {
    margin: 1rem !important;
  }

  .md\:m-5 {
    margin: 1.25rem !important;
  }

  .md\:m-6 {
    margin: 1.5rem !important;
  }

  .md\:m-8 {
    margin: 2rem !important;
  }

  .md\:m-10 {
    margin: 2.5rem !important;
  }

  .md\:m-12 {
    margin: 3rem !important;
  }

  .md\:m-16 {
    margin: 4rem !important;
  }

  .md\:m-20 {
    margin: 5rem !important;
  }

  .md\:m-24 {
    margin: 6rem !important;
  }

  .md\:m-32 {
    margin: 8rem !important;
  }

  .md\:m-40 {
    margin: 10rem !important;
  }

  .md\:m-48 {
    margin: 12rem !important;
  }

  .md\:m-56 {
    margin: 14rem !important;
  }

  .md\:m-64 {
    margin: 16rem !important;
  }

  .md\:m-auto {
    margin: auto !important;
  }

  .md\:m-px {
    margin: 1px !important;
  }

  .md\:-m-1 {
    margin: -0.25rem !important;
  }

  .md\:-m-2 {
    margin: -0.5rem !important;
  }

  .md\:-m-3 {
    margin: -0.75rem !important;
  }

  .md\:-m-4 {
    margin: -1rem !important;
  }

  .md\:-m-5 {
    margin: -1.25rem !important;
  }

  .md\:-m-6 {
    margin: -1.5rem !important;
  }

  .md\:-m-8 {
    margin: -2rem !important;
  }

  .md\:-m-10 {
    margin: -2.5rem !important;
  }

  .md\:-m-12 {
    margin: -3rem !important;
  }

  .md\:-m-16 {
    margin: -4rem !important;
  }

  .md\:-m-20 {
    margin: -5rem !important;
  }

  .md\:-m-24 {
    margin: -6rem !important;
  }

  .md\:-m-32 {
    margin: -8rem !important;
  }

  .md\:-m-40 {
    margin: -10rem !important;
  }

  .md\:-m-48 {
    margin: -12rem !important;
  }

  .md\:-m-56 {
    margin: -14rem !important;
  }

  .md\:-m-64 {
    margin: -16rem !important;
  }

  .md\:-m-px {
    margin: -1px !important;
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .md\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .md\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .md\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .md\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .md\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .md\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .md\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .md\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .md\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .md\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .md\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .md\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .md\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .md\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .md\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .md\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .md\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .md\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .md\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .md\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .md\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .md\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .md\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .md\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .md\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .md\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .md\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .md\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .md\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .md\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .md\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .md\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .md\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .md\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .md\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .md\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:mr-0 {
    margin-right: 0 !important;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:ml-0 {
    margin-left: 0 !important;
  }

  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .md\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .md\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .md\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .md\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .md\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .md\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .md\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .md\:mt-4 {
    margin-top: 1rem !important;
  }

  .md\:mr-4 {
    margin-right: 1rem !important;
  }

  .md\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .md\:ml-4 {
    margin-left: 1rem !important;
  }

  .md\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .md\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .md\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .md\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .md\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .md\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .md\:mt-8 {
    margin-top: 2rem !important;
  }

  .md\:mr-8 {
    margin-right: 2rem !important;
  }

  .md\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .md\:ml-8 {
    margin-left: 2rem !important;
  }

  .md\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .md\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .md\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .md\:mt-12 {
    margin-top: 3rem !important;
  }

  .md\:mr-12 {
    margin-right: 3rem !important;
  }

  .md\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .md\:ml-12 {
    margin-left: 3rem !important;
  }

  .md\:mt-16 {
    margin-top: 4rem !important;
  }

  .md\:mr-16 {
    margin-right: 4rem !important;
  }

  .md\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .md\:ml-16 {
    margin-left: 4rem !important;
  }

  .md\:mt-20 {
    margin-top: 5rem !important;
  }

  .md\:mr-20 {
    margin-right: 5rem !important;
  }

  .md\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .md\:ml-20 {
    margin-left: 5rem !important;
  }

  .md\:mt-24 {
    margin-top: 6rem !important;
  }

  .md\:mr-24 {
    margin-right: 6rem !important;
  }

  .md\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .md\:ml-24 {
    margin-left: 6rem !important;
  }

  .md\:mt-32 {
    margin-top: 8rem !important;
  }

  .md\:mr-32 {
    margin-right: 8rem !important;
  }

  .md\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .md\:ml-32 {
    margin-left: 8rem !important;
  }

  .md\:mt-40 {
    margin-top: 10rem !important;
  }

  .md\:mr-40 {
    margin-right: 10rem !important;
  }

  .md\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .md\:ml-40 {
    margin-left: 10rem !important;
  }

  .md\:mt-48 {
    margin-top: 12rem !important;
  }

  .md\:mr-48 {
    margin-right: 12rem !important;
  }

  .md\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .md\:ml-48 {
    margin-left: 12rem !important;
  }

  .md\:mt-56 {
    margin-top: 14rem !important;
  }

  .md\:mr-56 {
    margin-right: 14rem !important;
  }

  .md\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .md\:ml-56 {
    margin-left: 14rem !important;
  }

  .md\:mt-64 {
    margin-top: 16rem !important;
  }

  .md\:mr-64 {
    margin-right: 16rem !important;
  }

  .md\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .md\:ml-64 {
    margin-left: 16rem !important;
  }

  .md\:mt-auto {
    margin-top: auto !important;
  }

  .md\:mr-auto {
    margin-right: auto !important;
  }

  .md\:mb-auto {
    margin-bottom: auto !important;
  }

  .md\:ml-auto {
    margin-left: auto !important;
  }

  .md\:mt-px {
    margin-top: 1px !important;
  }

  .md\:mr-px {
    margin-right: 1px !important;
  }

  .md\:mb-px {
    margin-bottom: 1px !important;
  }

  .md\:ml-px {
    margin-left: 1px !important;
  }

  .md\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .md\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .md\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .md\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .md\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .md\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .md\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .md\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .md\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .md\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .md\:-mt-4 {
    margin-top: -1rem !important;
  }

  .md\:-mr-4 {
    margin-right: -1rem !important;
  }

  .md\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .md\:-ml-4 {
    margin-left: -1rem !important;
  }

  .md\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .md\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .md\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .md\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .md\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .md\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .md\:-mt-8 {
    margin-top: -2rem !important;
  }

  .md\:-mr-8 {
    margin-right: -2rem !important;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .md\:-ml-8 {
    margin-left: -2rem !important;
  }

  .md\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .md\:-mt-12 {
    margin-top: -3rem !important;
  }

  .md\:-mr-12 {
    margin-right: -3rem !important;
  }

  .md\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .md\:-ml-12 {
    margin-left: -3rem !important;
  }

  .md\:-mt-16 {
    margin-top: -4rem !important;
  }

  .md\:-mr-16 {
    margin-right: -4rem !important;
  }

  .md\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .md\:-ml-16 {
    margin-left: -4rem !important;
  }

  .md\:-mt-20 {
    margin-top: -5rem !important;
  }

  .md\:-mr-20 {
    margin-right: -5rem !important;
  }

  .md\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .md\:-ml-20 {
    margin-left: -5rem !important;
  }

  .md\:-mt-24 {
    margin-top: -6rem !important;
  }

  .md\:-mr-24 {
    margin-right: -6rem !important;
  }

  .md\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .md\:-ml-24 {
    margin-left: -6rem !important;
  }

  .md\:-mt-32 {
    margin-top: -8rem !important;
  }

  .md\:-mr-32 {
    margin-right: -8rem !important;
  }

  .md\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .md\:-ml-32 {
    margin-left: -8rem !important;
  }

  .md\:-mt-40 {
    margin-top: -10rem !important;
  }

  .md\:-mr-40 {
    margin-right: -10rem !important;
  }

  .md\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .md\:-ml-40 {
    margin-left: -10rem !important;
  }

  .md\:-mt-48 {
    margin-top: -12rem !important;
  }

  .md\:-mr-48 {
    margin-right: -12rem !important;
  }

  .md\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .md\:-ml-48 {
    margin-left: -12rem !important;
  }

  .md\:-mt-56 {
    margin-top: -14rem !important;
  }

  .md\:-mr-56 {
    margin-right: -14rem !important;
  }

  .md\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .md\:-ml-56 {
    margin-left: -14rem !important;
  }

  .md\:-mt-64 {
    margin-top: -16rem !important;
  }

  .md\:-mr-64 {
    margin-right: -16rem !important;
  }

  .md\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .md\:-ml-64 {
    margin-left: -16rem !important;
  }

  .md\:-mt-px {
    margin-top: -1px !important;
  }

  .md\:-mr-px {
    margin-right: -1px !important;
  }

  .md\:-mb-px {
    margin-bottom: -1px !important;
  }

  .md\:-ml-px {
    margin-left: -1px !important;
  }

  .md\:max-h-full {
    max-height: 100% !important;
  }

  .md\:max-h-screen {
    max-height: 100vh !important;
  }

  .md\:max-w-0 {
    max-width: 0 !important;
  }

  .md\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .md\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .md\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .md\:max-w-4 {
    max-width: 1rem !important;
  }

  .md\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .md\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .md\:max-w-8 {
    max-width: 2rem !important;
  }

  .md\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .md\:max-w-12 {
    max-width: 3rem !important;
  }

  .md\:max-w-16 {
    max-width: 4rem !important;
  }

  .md\:max-w-20 {
    max-width: 5rem !important;
  }

  .md\:max-w-24 {
    max-width: 6rem !important;
  }

  .md\:max-w-32 {
    max-width: 8rem !important;
  }

  .md\:max-w-40 {
    max-width: 10rem !important;
  }

  .md\:max-w-48 {
    max-width: 12rem !important;
  }

  .md\:max-w-56 {
    max-width: 14rem !important;
  }

  .md\:max-w-64 {
    max-width: 16rem !important;
  }

  .md\:max-w-none {
    max-width: none !important;
  }

  .md\:max-w-xs {
    max-width: 20rem !important;
  }

  .md\:max-w-sm {
    max-width: 24rem !important;
  }

  .md\:max-w-md {
    max-width: 28rem !important;
  }

  .md\:max-w-lg {
    max-width: 32rem !important;
  }

  .md\:max-w-xl {
    max-width: 36rem !important;
  }

  .md\:max-w-2xl {
    max-width: 42rem !important;
  }

  .md\:max-w-3xl {
    max-width: 48rem !important;
  }

  .md\:max-w-4xl {
    max-width: 56rem !important;
  }

  .md\:max-w-5xl {
    max-width: 64rem !important;
  }

  .md\:max-w-6xl {
    max-width: 72rem !important;
  }

  .md\:max-w-full {
    max-width: 100% !important;
  }

  .md\:max-w-screen-xs {
    max-width: 480px !important;
  }

  .md\:max-w-screen-sm {
    max-width: 576px !important;
  }

  .md\:max-w-screen-md {
    max-width: 768px !important;
  }

  .md\:max-w-screen-lg {
    max-width: 992px !important;
  }

  .md\:max-w-screen-xl {
    max-width: 1200px !important;
  }

  .md\:max-w-screen-xxl {
    max-width: 1600px !important;
  }

  .md\:max-w-screen {
    max-width: 100vw !important;
  }

  .md\:max-w-px {
    max-width: 1px !important;
  }

  .md\:min-h-0 {
    min-height: 0 !important;
  }

  .md\:min-h-full {
    min-height: 100% !important;
  }

  .md\:min-h-screen {
    min-height: 100vh !important;
  }

  .md\:min-w-0 {
    min-width: 0 !important;
  }

  .md\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .md\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .md\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .md\:min-w-4 {
    min-width: 1rem !important;
  }

  .md\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .md\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .md\:min-w-8 {
    min-width: 2rem !important;
  }

  .md\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .md\:min-w-12 {
    min-width: 3rem !important;
  }

  .md\:min-w-16 {
    min-width: 4rem !important;
  }

  .md\:min-w-20 {
    min-width: 5rem !important;
  }

  .md\:min-w-24 {
    min-width: 6rem !important;
  }

  .md\:min-w-32 {
    min-width: 8rem !important;
  }

  .md\:min-w-40 {
    min-width: 10rem !important;
  }

  .md\:min-w-48 {
    min-width: 12rem !important;
  }

  .md\:min-w-56 {
    min-width: 14rem !important;
  }

  .md\:min-w-64 {
    min-width: 16rem !important;
  }

  .md\:min-w-full {
    min-width: 100% !important;
  }

  .md\:min-w-screen {
    min-width: 100vw !important;
  }

  .md\:min-w-px {
    min-width: 1px !important;
  }

  .md\:min-w-screen-xs {
    min-width: 480px !important;
  }

  .md\:min-w-screen-sm {
    min-width: 576px !important;
  }

  .md\:min-w-screen-md {
    min-width: 768px !important;
  }

  .md\:min-w-screen-lg {
    min-width: 992px !important;
  }

  .md\:min-w-screen-xl {
    min-width: 1200px !important;
  }

  .md\:min-w-screen-xxl {
    min-width: 1600px !important;
  }

  .md\:object-contain {
    object-fit: contain !important;
  }

  .md\:object-cover {
    object-fit: cover !important;
  }

  .md\:object-fill {
    object-fit: fill !important;
  }

  .md\:object-none {
    object-fit: none !important;
  }

  .md\:object-scale-down {
    object-fit: scale-down !important;
  }

  .md\:object-bottom {
    object-position: bottom !important;
  }

  .md\:object-center {
    object-position: center !important;
  }

  .md\:object-left {
    object-position: left !important;
  }

  .md\:object-left-bottom {
    object-position: left bottom !important;
  }

  .md\:object-left-top {
    object-position: left top !important;
  }

  .md\:object-right {
    object-position: right !important;
  }

  .md\:object-right-bottom {
    object-position: right bottom !important;
  }

  .md\:object-right-top {
    object-position: right top !important;
  }

  .md\:object-top {
    object-position: top !important;
  }

  .md\:opacity-0 {
    opacity: 0 !important;
  }

  .md\:opacity-25 {
    opacity: 0.25 !important;
  }

  .md\:opacity-50 {
    opacity: 0.5 !important;
  }

  .md\:opacity-75 {
    opacity: 0.75 !important;
  }

  .md\:opacity-100 {
    opacity: 1 !important;
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .md\:overflow-auto {
    overflow: auto !important;
  }

  .md\:overflow-hidden {
    overflow: hidden !important;
  }

  .md\:overflow-visible {
    overflow: visible !important;
  }

  .md\:overflow-scroll {
    overflow: scroll !important;
  }

  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto !important;
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain !important;
  }

  .md\:overscroll-none {
    overscroll-behavior: none !important;
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto !important;
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain !important;
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none !important;
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto !important;
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain !important;
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none !important;
  }

  .md\:p-0 {
    padding: 0 !important;
  }

  .md\:p-1 {
    padding: 0.25rem !important;
  }

  .md\:p-2 {
    padding: 0.5rem !important;
  }

  .md\:p-3 {
    padding: 0.75rem !important;
  }

  .md\:p-4 {
    padding: 1rem !important;
  }

  .md\:p-5 {
    padding: 1.25rem !important;
  }

  .md\:p-6 {
    padding: 1.5rem !important;
  }

  .md\:p-8 {
    padding: 2rem !important;
  }

  .md\:p-10 {
    padding: 2.5rem !important;
  }

  .md\:p-12 {
    padding: 3rem !important;
  }

  .md\:p-16 {
    padding: 4rem !important;
  }

  .md\:p-20 {
    padding: 5rem !important;
  }

  .md\:p-24 {
    padding: 6rem !important;
  }

  .md\:p-32 {
    padding: 8rem !important;
  }

  .md\:p-40 {
    padding: 10rem !important;
  }

  .md\:p-48 {
    padding: 12rem !important;
  }

  .md\:p-56 {
    padding: 14rem !important;
  }

  .md\:p-64 {
    padding: 16rem !important;
  }

  .md\:p-px {
    padding: 1px !important;
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .md\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .md\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .md\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .md\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .md\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .md\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .md\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .md\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .md\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .md\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .md\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .md\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .md\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .md\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .md\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .md\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .md\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .md\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .md\:pt-0 {
    padding-top: 0 !important;
  }

  .md\:pr-0 {
    padding-right: 0 !important;
  }

  .md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:pl-0 {
    padding-left: 0 !important;
  }

  .md\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .md\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .md\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .md\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .md\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .md\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .md\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .md\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .md\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .md\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .md\:pt-4 {
    padding-top: 1rem !important;
  }

  .md\:pr-4 {
    padding-right: 1rem !important;
  }

  .md\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .md\:pl-4 {
    padding-left: 1rem !important;
  }

  .md\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .md\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .md\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .md\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .md\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .md\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .md\:pt-8 {
    padding-top: 2rem !important;
  }

  .md\:pr-8 {
    padding-right: 2rem !important;
  }

  .md\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .md\:pl-8 {
    padding-left: 2rem !important;
  }

  .md\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .md\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .md\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .md\:pt-12 {
    padding-top: 3rem !important;
  }

  .md\:pr-12 {
    padding-right: 3rem !important;
  }

  .md\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .md\:pl-12 {
    padding-left: 3rem !important;
  }

  .md\:pt-16 {
    padding-top: 4rem !important;
  }

  .md\:pr-16 {
    padding-right: 4rem !important;
  }

  .md\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .md\:pl-16 {
    padding-left: 4rem !important;
  }

  .md\:pt-20 {
    padding-top: 5rem !important;
  }

  .md\:pr-20 {
    padding-right: 5rem !important;
  }

  .md\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .md\:pl-20 {
    padding-left: 5rem !important;
  }

  .md\:pt-24 {
    padding-top: 6rem !important;
  }

  .md\:pr-24 {
    padding-right: 6rem !important;
  }

  .md\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .md\:pl-24 {
    padding-left: 6rem !important;
  }

  .md\:pt-32 {
    padding-top: 8rem !important;
  }

  .md\:pr-32 {
    padding-right: 8rem !important;
  }

  .md\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .md\:pl-32 {
    padding-left: 8rem !important;
  }

  .md\:pt-40 {
    padding-top: 10rem !important;
  }

  .md\:pr-40 {
    padding-right: 10rem !important;
  }

  .md\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .md\:pl-40 {
    padding-left: 10rem !important;
  }

  .md\:pt-48 {
    padding-top: 12rem !important;
  }

  .md\:pr-48 {
    padding-right: 12rem !important;
  }

  .md\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .md\:pl-48 {
    padding-left: 12rem !important;
  }

  .md\:pt-56 {
    padding-top: 14rem !important;
  }

  .md\:pr-56 {
    padding-right: 14rem !important;
  }

  .md\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .md\:pl-56 {
    padding-left: 14rem !important;
  }

  .md\:pt-64 {
    padding-top: 16rem !important;
  }

  .md\:pr-64 {
    padding-right: 16rem !important;
  }

  .md\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .md\:pl-64 {
    padding-left: 16rem !important;
  }

  .md\:pt-px {
    padding-top: 1px !important;
  }

  .md\:pr-px {
    padding-right: 1px !important;
  }

  .md\:pb-px {
    padding-bottom: 1px !important;
  }

  .md\:pl-px {
    padding-left: 1px !important;
  }

  .md\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent !important;
  }

  .md\:placeholder-transparent::-ms-input-placeholder {
    color: transparent !important;
  }

  .md\:placeholder-transparent::placeholder {
    color: transparent !important;
  }

  .md\:placeholder-current::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .md\:placeholder-current::-ms-input-placeholder {
    color: currentColor !important;
  }

  .md\:placeholder-current::placeholder {
    color: currentColor !important;
  }

  .md\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-black::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-white::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .md\:placeholder-primary::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .md\:placeholder-primary::placeholder {
    color: var(--primary) !important;
  }

  .md\:placeholder-accent-1::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-accent-1::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-accent-1::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-accent-2::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-accent-2::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-accent-2::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  .md\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent !important;
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent !important;
  }

  .md\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .md\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor !important;
  }

  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor !important;
  }

  .md\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .md\:focus\:placeholder-primary:focus::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .md\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary) !important;
  }

  .md\:focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-accent-1:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-accent-1:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-accent-2:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-accent-2:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .md\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:pointer-events-none {
    pointer-events: none !important;
  }

  .md\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .md\:static {
    position: static !important;
  }

  .md\:fixed {
    position: fixed !important;
  }

  .md\:absolute {
    position: absolute !important;
  }

  .md\:relative {
    position: relative !important;
  }

  .md\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .md\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .md\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .md\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .md\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .md\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .md\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .md\:top-0 {
    top: 0 !important;
  }

  .md\:right-0 {
    right: 0 !important;
  }

  .md\:bottom-0 {
    bottom: 0 !important;
  }

  .md\:left-0 {
    left: 0 !important;
  }

  .md\:top-auto {
    top: auto !important;
  }

  .md\:right-auto {
    right: auto !important;
  }

  .md\:bottom-auto {
    bottom: auto !important;
  }

  .md\:left-auto {
    left: auto !important;
  }

  .md\:resize-none {
    resize: none !important;
  }

  .md\:resize-y {
    resize: vertical !important;
  }

  .md\:resize-x {
    resize: horizontal !important;
  }

  .md\:resize {
    resize: both !important;
  }

  .md\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .md\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .md\:shadow-none {
    box-shadow: none !important;
  }

  .md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .md\:fill-current {
    fill: currentColor !important;
  }

  .md\:stroke-current {
    stroke: currentColor !important;
  }

  .md\:stroke-0 {
    stroke-width: 0 !important;
  }

  .md\:stroke-1 {
    stroke-width: 1 !important;
  }

  .md\:stroke-2 {
    stroke-width: 2 !important;
  }

  .md\:table-auto {
    table-layout: auto !important;
  }

  .md\:table-fixed {
    table-layout: fixed !important;
  }

  .md\:text-left {
    text-align: left !important;
  }

  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-right {
    text-align: right !important;
  }

  .md\:text-justify {
    text-align: justify !important;
  }

  .md\:text-transparent {
    color: transparent !important;
  }

  .md\:text-current {
    color: currentColor !important;
  }

  .md\:text-black {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .md\:text-white {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .md\:text-gray-100 {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .md\:text-gray-200 {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .md\:text-gray-300 {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .md\:text-gray-400 {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .md\:text-gray-500 {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .md\:text-gray-600 {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .md\:text-gray-700 {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .md\:text-gray-800 {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .md\:text-gray-900 {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .md\:text-red-100 {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .md\:text-red-200 {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .md\:text-red-300 {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .md\:text-red-400 {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .md\:text-red-500 {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .md\:text-red-600 {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .md\:text-red-700 {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .md\:text-red-800 {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .md\:text-red-900 {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .md\:text-orange-100 {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .md\:text-orange-200 {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .md\:text-orange-300 {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .md\:text-orange-400 {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .md\:text-orange-500 {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .md\:text-orange-600 {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .md\:text-orange-700 {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .md\:text-orange-800 {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .md\:text-orange-900 {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .md\:text-yellow-100 {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .md\:text-yellow-200 {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .md\:text-yellow-300 {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .md\:text-yellow-400 {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .md\:text-yellow-500 {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .md\:text-yellow-600 {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .md\:text-yellow-700 {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .md\:text-yellow-800 {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .md\:text-yellow-900 {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .md\:text-green-100 {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .md\:text-green-200 {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .md\:text-green-300 {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .md\:text-green-400 {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .md\:text-green-500 {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .md\:text-green-600 {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .md\:text-green-700 {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .md\:text-green-800 {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .md\:text-green-900 {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .md\:text-teal-100 {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .md\:text-teal-200 {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .md\:text-teal-300 {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .md\:text-teal-400 {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .md\:text-teal-500 {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .md\:text-teal-600 {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .md\:text-teal-700 {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .md\:text-teal-800 {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .md\:text-teal-900 {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .md\:text-blue-100 {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .md\:text-blue-200 {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .md\:text-blue-300 {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .md\:text-blue-400 {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .md\:text-blue-500 {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .md\:text-blue-600 {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .md\:text-blue-700 {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .md\:text-blue-800 {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .md\:text-blue-900 {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .md\:text-indigo-100 {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .md\:text-indigo-200 {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .md\:text-indigo-300 {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .md\:text-indigo-400 {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .md\:text-indigo-500 {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .md\:text-indigo-600 {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .md\:text-indigo-700 {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .md\:text-indigo-800 {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .md\:text-indigo-900 {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .md\:text-purple-100 {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .md\:text-purple-200 {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .md\:text-purple-300 {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .md\:text-purple-400 {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .md\:text-purple-500 {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .md\:text-purple-600 {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .md\:text-purple-700 {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .md\:text-purple-800 {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .md\:text-purple-900 {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .md\:text-pink-100 {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .md\:text-pink-200 {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .md\:text-pink-300 {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .md\:text-pink-400 {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .md\:text-pink-500 {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .md\:text-pink-600 {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .md\:text-pink-700 {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .md\:text-pink-800 {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .md\:text-pink-900 {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .md\:text-primary {
    color: var(--primary) !important;
  }

  .md\:text-accent-1 {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .md\:text-accent-2 {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .md\:hover\:text-transparent:hover {
    color: transparent !important;
  }

  .md\:hover\:text-current:hover {
    color: currentColor !important;
  }

  .md\:hover\:text-black:hover {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .md\:hover\:text-white:hover {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-100:hover {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-200:hover {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-300:hover {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-400:hover {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-500:hover {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-600:hover {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-700:hover {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-800:hover {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .md\:hover\:text-gray-900:hover {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-200:hover {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-300:hover {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-400:hover {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-500:hover {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-600:hover {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-700:hover {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-800:hover {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .md\:hover\:text-red-900:hover {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-100:hover {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-200:hover {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-300:hover {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-400:hover {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-500:hover {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-600:hover {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-700:hover {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-800:hover {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .md\:hover\:text-orange-900:hover {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-100:hover {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-200:hover {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-300:hover {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-400:hover {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-500:hover {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-600:hover {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-700:hover {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-800:hover {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .md\:hover\:text-yellow-900:hover {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-100:hover {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-200:hover {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-300:hover {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-400:hover {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-500:hover {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-600:hover {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-700:hover {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-800:hover {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .md\:hover\:text-green-900:hover {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-100:hover {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-200:hover {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-300:hover {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-400:hover {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-500:hover {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-600:hover {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-700:hover {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-800:hover {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .md\:hover\:text-teal-900:hover {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-100:hover {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-200:hover {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-300:hover {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-400:hover {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-500:hover {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-600:hover {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-700:hover {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-800:hover {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .md\:hover\:text-blue-900:hover {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-100:hover {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-200:hover {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-300:hover {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-400:hover {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-500:hover {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-600:hover {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-700:hover {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-800:hover {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .md\:hover\:text-indigo-900:hover {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-100:hover {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-200:hover {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-300:hover {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-400:hover {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-500:hover {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-600:hover {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-700:hover {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-800:hover {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .md\:hover\:text-purple-900:hover {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-200:hover {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-300:hover {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-400:hover {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-500:hover {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-600:hover {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-700:hover {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-800:hover {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .md\:hover\:text-pink-900:hover {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .md\:hover\:text-primary:hover {
    color: var(--primary) !important;
  }

  .md\:hover\:text-accent-1:hover {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .md\:hover\:text-accent-2:hover {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .md\:focus\:text-transparent:focus {
    color: transparent !important;
  }

  .md\:focus\:text-current:focus {
    color: currentColor !important;
  }

  .md\:focus\:text-black:focus {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .md\:focus\:text-white:focus {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-100:focus {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-200:focus {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-300:focus {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-400:focus {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-500:focus {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-600:focus {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-700:focus {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-800:focus {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .md\:focus\:text-gray-900:focus {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-200:focus {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-300:focus {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-400:focus {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-500:focus {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-600:focus {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-700:focus {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-800:focus {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .md\:focus\:text-red-900:focus {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-100:focus {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-200:focus {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-300:focus {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-400:focus {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-500:focus {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-600:focus {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-700:focus {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-800:focus {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .md\:focus\:text-orange-900:focus {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-100:focus {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-200:focus {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-300:focus {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-400:focus {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-500:focus {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-600:focus {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-700:focus {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-800:focus {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .md\:focus\:text-yellow-900:focus {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-100:focus {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-200:focus {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-300:focus {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-400:focus {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-500:focus {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-600:focus {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-700:focus {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-800:focus {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .md\:focus\:text-green-900:focus {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-100:focus {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-200:focus {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-300:focus {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-400:focus {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-500:focus {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-600:focus {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-700:focus {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-800:focus {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .md\:focus\:text-teal-900:focus {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-100:focus {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-200:focus {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-300:focus {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-400:focus {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-500:focus {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-600:focus {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-700:focus {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-800:focus {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .md\:focus\:text-blue-900:focus {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-100:focus {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-200:focus {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-300:focus {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-400:focus {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-500:focus {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-600:focus {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-700:focus {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-800:focus {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .md\:focus\:text-indigo-900:focus {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-100:focus {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-200:focus {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-300:focus {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-400:focus {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-500:focus {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-600:focus {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-700:focus {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-800:focus {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .md\:focus\:text-purple-900:focus {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-200:focus {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-300:focus {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-400:focus {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-500:focus {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-600:focus {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-700:focus {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-800:focus {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .md\:focus\:text-pink-900:focus {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .md\:focus\:text-primary:focus {
    color: var(--primary) !important;
  }

  .md\:focus\:text-accent-1:focus {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .md\:focus\:text-accent-2:focus {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .md\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .md\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .md\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .md\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .md\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .md\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .md\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .md\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .md\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .md\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .md\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .md\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .md\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .md\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .md\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .md\:italic {
    font-style: italic !important;
  }

  .md\:not-italic {
    font-style: normal !important;
  }

  .md\:uppercase {
    text-transform: uppercase !important;
  }

  .md\:lowercase {
    text-transform: lowercase !important;
  }

  .md\:capitalize {
    text-transform: capitalize !important;
  }

  .md\:normal-case {
    text-transform: none !important;
  }

  .md\:underline {
    text-decoration: underline !important;
  }

  .md\:line-through {
    text-decoration: line-through !important;
  }

  .md\:no-underline {
    text-decoration: none !important;
  }

  .md\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .md\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
  }

  .md\:normal-nums {
    font-variant-numeric: normal !important;
  }

  .md\:ordinal {
    --font-variant-numeric-ordinal: ordinal !important;
  }

  .md\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero !important;
  }

  .md\:lining-nums {
    --font-variant-numeric-figure: lining-nums !important;
  }

  .md\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums !important;
  }

  .md\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums !important;
  }

  .md\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums !important;
  }

  .md\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions !important;
  }

  .md\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions !important;
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .md\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .md\:select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .md\:select-text {
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .md\:select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .md\:select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .md\:align-baseline {
    vertical-align: baseline !important;
  }

  .md\:align-top {
    vertical-align: top !important;
  }

  .md\:align-middle {
    vertical-align: middle !important;
  }

  .md\:align-bottom {
    vertical-align: bottom !important;
  }

  .md\:align-text-top {
    vertical-align: text-top !important;
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .md\:visible {
    visibility: visible !important;
  }

  .md\:invisible {
    visibility: hidden !important;
  }

  .md\:whitespace-normal {
    white-space: normal !important;
  }

  .md\:whitespace-no-wrap {
    white-space: nowrap !important;
  }

  .md\:whitespace-pre {
    white-space: pre !important;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .md\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .md\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  .md\:break-all {
    word-break: break-all !important;
  }

  .md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .md\:w-0 {
    width: 0 !important;
  }

  .md\:w-1 {
    width: 0.25rem !important;
  }

  .md\:w-2 {
    width: 0.5rem !important;
  }

  .md\:w-3 {
    width: 0.75rem !important;
  }

  .md\:w-4 {
    width: 1rem !important;
  }

  .md\:w-5 {
    width: 1.25rem !important;
  }

  .md\:w-6 {
    width: 1.5rem !important;
  }

  .md\:w-8 {
    width: 2rem !important;
  }

  .md\:w-10 {
    width: 2.5rem !important;
  }

  .md\:w-12 {
    width: 3rem !important;
  }

  .md\:w-16 {
    width: 4rem !important;
  }

  .md\:w-20 {
    width: 5rem !important;
  }

  .md\:w-24 {
    width: 6rem !important;
  }

  .md\:w-32 {
    width: 8rem !important;
  }

  .md\:w-40 {
    width: 10rem !important;
  }

  .md\:w-48 {
    width: 12rem !important;
  }

  .md\:w-56 {
    width: 14rem !important;
  }

  .md\:w-64 {
    width: 16rem !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-px {
    width: 1px !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }

  .md\:w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:w-2\/3 {
    width: 66.666667% !important;
  }

  .md\:w-1\/4 {
    width: 25% !important;
  }

  .md\:w-2\/4 {
    width: 50% !important;
  }

  .md\:w-3\/4 {
    width: 75% !important;
  }

  .md\:w-1\/5 {
    width: 20% !important;
  }

  .md\:w-2\/5 {
    width: 40% !important;
  }

  .md\:w-3\/5 {
    width: 60% !important;
  }

  .md\:w-4\/5 {
    width: 80% !important;
  }

  .md\:w-1\/6 {
    width: 16.666667% !important;
  }

  .md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .md\:w-3\/6 {
    width: 50% !important;
  }

  .md\:w-4\/6 {
    width: 66.666667% !important;
  }

  .md\:w-5\/6 {
    width: 83.333333% !important;
  }

  .md\:w-1\/12 {
    width: 8.333333% !important;
  }

  .md\:w-2\/12 {
    width: 16.666667% !important;
  }

  .md\:w-3\/12 {
    width: 25% !important;
  }

  .md\:w-4\/12 {
    width: 33.333333% !important;
  }

  .md\:w-5\/12 {
    width: 41.666667% !important;
  }

  .md\:w-6\/12 {
    width: 50% !important;
  }

  .md\:w-7\/12 {
    width: 58.333333% !important;
  }

  .md\:w-8\/12 {
    width: 66.666667% !important;
  }

  .md\:w-9\/12 {
    width: 75% !important;
  }

  .md\:w-10\/12 {
    width: 83.333333% !important;
  }

  .md\:w-11\/12 {
    width: 91.666667% !important;
  }

  .md\:w-full {
    width: 100% !important;
  }

  .md\:w-screen {
    width: 100vw !important;
  }

  .md\:w-none {
    width: none !important;
  }

  .md\:w-xs {
    width: 20rem !important;
  }

  .md\:w-sm {
    width: 24rem !important;
  }

  .md\:w-md {
    width: 28rem !important;
  }

  .md\:w-lg {
    width: 32rem !important;
  }

  .md\:w-xl {
    width: 36rem !important;
  }

  .md\:w-2xl {
    width: 42rem !important;
  }

  .md\:w-3xl {
    width: 48rem !important;
  }

  .md\:w-4xl {
    width: 56rem !important;
  }

  .md\:w-5xl {
    width: 64rem !important;
  }

  .md\:w-6xl {
    width: 72rem !important;
  }

  .md\:w-screen-xs {
    width: 480px !important;
  }

  .md\:w-screen-sm {
    width: 576px !important;
  }

  .md\:w-screen-md {
    width: 768px !important;
  }

  .md\:w-screen-lg {
    width: 992px !important;
  }

  .md\:w-screen-xl {
    width: 1200px !important;
  }

  .md\:w-screen-xxl {
    width: 1600px !important;
  }

  .md\:w-sidebar {
    width: 15rem !important;
  }

  .md\:z-0 {
    z-index: 0 !important;
  }

  .md\:z-10 {
    z-index: 10 !important;
  }

  .md\:z-20 {
    z-index: 20 !important;
  }

  .md\:z-30 {
    z-index: 30 !important;
  }

  .md\:z-40 {
    z-index: 40 !important;
  }

  .md\:z-50 {
    z-index: 50 !important;
  }

  .md\:z-auto {
    z-index: auto !important;
  }

  .md\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .md\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .md\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .md\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .md\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .md\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .md\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .md\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .md\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .md\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .md\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .md\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .md\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .md\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .md\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .md\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .md\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .md\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .md\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .md\:gap-x-0 {
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }

  .md\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .md\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .md\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .md\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .md\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .md\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .md\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .md\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .md\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .md\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .md\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .md\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .md\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .md\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .md\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .md\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .md\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .md\:gap-x-px {
    grid-column-gap: 1px !important;
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .md\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .md\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .md\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .md\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .md\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .md\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .md\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .md\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .md\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .md\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .md\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .md\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .md\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .md\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .md\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .md\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .md\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .md\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .md\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .md\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto !important;
  }

  .md\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .md\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .md\:col-auto {
    grid-column: auto !important;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .md\:col-span-full {
    grid-column: 1 / -1 !important;
  }

  .md\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .md\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .md\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .md\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .md\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .md\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .md\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .md\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .md\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .md\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .md\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .md\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .md\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .md\:col-start-auto {
    grid-column-start: auto !important;
  }

  .md\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .md\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .md\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .md\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .md\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .md\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .md\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .md\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .md\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .md\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .md\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .md\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .md\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .md\:col-end-auto {
    grid-column-end: auto !important;
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto !important;
  }

  .md\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .md\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .md\:row-auto {
    grid-row: auto !important;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .md\:row-span-full {
    grid-row: 1 / -1 !important;
  }

  .md\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .md\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .md\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .md\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .md\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .md\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .md\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .md\:row-start-auto {
    grid-row-start: auto !important;
  }

  .md\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .md\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .md\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .md\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .md\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .md\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .md\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .md\:row-end-auto {
    grid-row-end: auto !important;
  }

  .md\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .md\:transform-none {
    transform: none !important;
  }

  .md\:origin-center {
    transform-origin: center !important;
  }

  .md\:origin-top {
    transform-origin: top !important;
  }

  .md\:origin-top-right {
    transform-origin: top right !important;
  }

  .md\:origin-right {
    transform-origin: right !important;
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .md\:origin-left {
    transform-origin: left !important;
  }

  .md\:origin-top-left {
    transform-origin: top left !important;
  }

  .md\:scale-0 {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .md\:scale-50 {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .md\:scale-75 {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .md\:scale-90 {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .md\:scale-95 {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .md\:scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .md\:scale-105 {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .md\:scale-110 {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .md\:scale-125 {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .md\:scale-150 {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .md\:scale-x-0 {
    --transform-scale-x: 0 !important;
  }

  .md\:scale-x-50 {
    --transform-scale-x: .5 !important;
  }

  .md\:scale-x-75 {
    --transform-scale-x: .75 !important;
  }

  .md\:scale-x-90 {
    --transform-scale-x: .9 !important;
  }

  .md\:scale-x-95 {
    --transform-scale-x: .95 !important;
  }

  .md\:scale-x-100 {
    --transform-scale-x: 1 !important;
  }

  .md\:scale-x-105 {
    --transform-scale-x: 1.05 !important;
  }

  .md\:scale-x-110 {
    --transform-scale-x: 1.1 !important;
  }

  .md\:scale-x-125 {
    --transform-scale-x: 1.25 !important;
  }

  .md\:scale-x-150 {
    --transform-scale-x: 1.5 !important;
  }

  .md\:scale-y-0 {
    --transform-scale-y: 0 !important;
  }

  .md\:scale-y-50 {
    --transform-scale-y: .5 !important;
  }

  .md\:scale-y-75 {
    --transform-scale-y: .75 !important;
  }

  .md\:scale-y-90 {
    --transform-scale-y: .9 !important;
  }

  .md\:scale-y-95 {
    --transform-scale-y: .95 !important;
  }

  .md\:scale-y-100 {
    --transform-scale-y: 1 !important;
  }

  .md\:scale-y-105 {
    --transform-scale-y: 1.05 !important;
  }

  .md\:scale-y-110 {
    --transform-scale-y: 1.1 !important;
  }

  .md\:scale-y-125 {
    --transform-scale-y: 1.25 !important;
  }

  .md\:scale-y-150 {
    --transform-scale-y: 1.5 !important;
  }

  .md\:hover\:scale-0:hover {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .md\:hover\:scale-50:hover {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .md\:hover\:scale-75:hover {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .md\:hover\:scale-90:hover {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .md\:hover\:scale-95:hover {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .md\:hover\:scale-100:hover {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .md\:hover\:scale-105:hover {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .md\:hover\:scale-110:hover {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .md\:hover\:scale-125:hover {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .md\:hover\:scale-150:hover {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .md\:hover\:scale-x-0:hover {
    --transform-scale-x: 0 !important;
  }

  .md\:hover\:scale-x-50:hover {
    --transform-scale-x: .5 !important;
  }

  .md\:hover\:scale-x-75:hover {
    --transform-scale-x: .75 !important;
  }

  .md\:hover\:scale-x-90:hover {
    --transform-scale-x: .9 !important;
  }

  .md\:hover\:scale-x-95:hover {
    --transform-scale-x: .95 !important;
  }

  .md\:hover\:scale-x-100:hover {
    --transform-scale-x: 1 !important;
  }

  .md\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05 !important;
  }

  .md\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1 !important;
  }

  .md\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25 !important;
  }

  .md\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5 !important;
  }

  .md\:hover\:scale-y-0:hover {
    --transform-scale-y: 0 !important;
  }

  .md\:hover\:scale-y-50:hover {
    --transform-scale-y: .5 !important;
  }

  .md\:hover\:scale-y-75:hover {
    --transform-scale-y: .75 !important;
  }

  .md\:hover\:scale-y-90:hover {
    --transform-scale-y: .9 !important;
  }

  .md\:hover\:scale-y-95:hover {
    --transform-scale-y: .95 !important;
  }

  .md\:hover\:scale-y-100:hover {
    --transform-scale-y: 1 !important;
  }

  .md\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05 !important;
  }

  .md\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1 !important;
  }

  .md\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25 !important;
  }

  .md\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5 !important;
  }

  .md\:focus\:scale-0:focus {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .md\:focus\:scale-50:focus {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .md\:focus\:scale-75:focus {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .md\:focus\:scale-90:focus {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .md\:focus\:scale-95:focus {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .md\:focus\:scale-100:focus {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .md\:focus\:scale-105:focus {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .md\:focus\:scale-110:focus {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .md\:focus\:scale-125:focus {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .md\:focus\:scale-150:focus {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .md\:focus\:scale-x-0:focus {
    --transform-scale-x: 0 !important;
  }

  .md\:focus\:scale-x-50:focus {
    --transform-scale-x: .5 !important;
  }

  .md\:focus\:scale-x-75:focus {
    --transform-scale-x: .75 !important;
  }

  .md\:focus\:scale-x-90:focus {
    --transform-scale-x: .9 !important;
  }

  .md\:focus\:scale-x-95:focus {
    --transform-scale-x: .95 !important;
  }

  .md\:focus\:scale-x-100:focus {
    --transform-scale-x: 1 !important;
  }

  .md\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05 !important;
  }

  .md\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1 !important;
  }

  .md\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25 !important;
  }

  .md\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5 !important;
  }

  .md\:focus\:scale-y-0:focus {
    --transform-scale-y: 0 !important;
  }

  .md\:focus\:scale-y-50:focus {
    --transform-scale-y: .5 !important;
  }

  .md\:focus\:scale-y-75:focus {
    --transform-scale-y: .75 !important;
  }

  .md\:focus\:scale-y-90:focus {
    --transform-scale-y: .9 !important;
  }

  .md\:focus\:scale-y-95:focus {
    --transform-scale-y: .95 !important;
  }

  .md\:focus\:scale-y-100:focus {
    --transform-scale-y: 1 !important;
  }

  .md\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05 !important;
  }

  .md\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1 !important;
  }

  .md\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25 !important;
  }

  .md\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5 !important;
  }

  .md\:rotate-0 {
    --transform-rotate: 0 !important;
  }

  .md\:rotate-1 {
    --transform-rotate: 1deg !important;
  }

  .md\:rotate-2 {
    --transform-rotate: 2deg !important;
  }

  .md\:rotate-3 {
    --transform-rotate: 3deg !important;
  }

  .md\:rotate-6 {
    --transform-rotate: 6deg !important;
  }

  .md\:rotate-12 {
    --transform-rotate: 12deg !important;
  }

  .md\:rotate-45 {
    --transform-rotate: 45deg !important;
  }

  .md\:rotate-90 {
    --transform-rotate: 90deg !important;
  }

  .md\:rotate-180 {
    --transform-rotate: 180deg !important;
  }

  .md\:-rotate-180 {
    --transform-rotate: -180deg !important;
  }

  .md\:-rotate-90 {
    --transform-rotate: -90deg !important;
  }

  .md\:-rotate-45 {
    --transform-rotate: -45deg !important;
  }

  .md\:-rotate-12 {
    --transform-rotate: -12deg !important;
  }

  .md\:-rotate-6 {
    --transform-rotate: -6deg !important;
  }

  .md\:-rotate-3 {
    --transform-rotate: -3deg !important;
  }

  .md\:-rotate-2 {
    --transform-rotate: -2deg !important;
  }

  .md\:-rotate-1 {
    --transform-rotate: -1deg !important;
  }

  .md\:hover\:rotate-0:hover {
    --transform-rotate: 0 !important;
  }

  .md\:hover\:rotate-1:hover {
    --transform-rotate: 1deg !important;
  }

  .md\:hover\:rotate-2:hover {
    --transform-rotate: 2deg !important;
  }

  .md\:hover\:rotate-3:hover {
    --transform-rotate: 3deg !important;
  }

  .md\:hover\:rotate-6:hover {
    --transform-rotate: 6deg !important;
  }

  .md\:hover\:rotate-12:hover {
    --transform-rotate: 12deg !important;
  }

  .md\:hover\:rotate-45:hover {
    --transform-rotate: 45deg !important;
  }

  .md\:hover\:rotate-90:hover {
    --transform-rotate: 90deg !important;
  }

  .md\:hover\:rotate-180:hover {
    --transform-rotate: 180deg !important;
  }

  .md\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg !important;
  }

  .md\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg !important;
  }

  .md\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg !important;
  }

  .md\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg !important;
  }

  .md\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg !important;
  }

  .md\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg !important;
  }

  .md\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg !important;
  }

  .md\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg !important;
  }

  .md\:focus\:rotate-0:focus {
    --transform-rotate: 0 !important;
  }

  .md\:focus\:rotate-1:focus {
    --transform-rotate: 1deg !important;
  }

  .md\:focus\:rotate-2:focus {
    --transform-rotate: 2deg !important;
  }

  .md\:focus\:rotate-3:focus {
    --transform-rotate: 3deg !important;
  }

  .md\:focus\:rotate-6:focus {
    --transform-rotate: 6deg !important;
  }

  .md\:focus\:rotate-12:focus {
    --transform-rotate: 12deg !important;
  }

  .md\:focus\:rotate-45:focus {
    --transform-rotate: 45deg !important;
  }

  .md\:focus\:rotate-90:focus {
    --transform-rotate: 90deg !important;
  }

  .md\:focus\:rotate-180:focus {
    --transform-rotate: 180deg !important;
  }

  .md\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg !important;
  }

  .md\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg !important;
  }

  .md\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg !important;
  }

  .md\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg !important;
  }

  .md\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg !important;
  }

  .md\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg !important;
  }

  .md\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg !important;
  }

  .md\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg !important;
  }

  .md\:translate-x-0 {
    --transform-translate-x: 0 !important;
  }

  .md\:translate-x-1 {
    --transform-translate-x: 0.25rem !important;
  }

  .md\:translate-x-2 {
    --transform-translate-x: 0.5rem !important;
  }

  .md\:translate-x-3 {
    --transform-translate-x: 0.75rem !important;
  }

  .md\:translate-x-4 {
    --transform-translate-x: 1rem !important;
  }

  .md\:translate-x-5 {
    --transform-translate-x: 1.25rem !important;
  }

  .md\:translate-x-6 {
    --transform-translate-x: 1.5rem !important;
  }

  .md\:translate-x-8 {
    --transform-translate-x: 2rem !important;
  }

  .md\:translate-x-10 {
    --transform-translate-x: 2.5rem !important;
  }

  .md\:translate-x-12 {
    --transform-translate-x: 3rem !important;
  }

  .md\:translate-x-16 {
    --transform-translate-x: 4rem !important;
  }

  .md\:translate-x-20 {
    --transform-translate-x: 5rem !important;
  }

  .md\:translate-x-24 {
    --transform-translate-x: 6rem !important;
  }

  .md\:translate-x-32 {
    --transform-translate-x: 8rem !important;
  }

  .md\:translate-x-40 {
    --transform-translate-x: 10rem !important;
  }

  .md\:translate-x-48 {
    --transform-translate-x: 12rem !important;
  }

  .md\:translate-x-56 {
    --transform-translate-x: 14rem !important;
  }

  .md\:translate-x-64 {
    --transform-translate-x: 16rem !important;
  }

  .md\:translate-x-px {
    --transform-translate-x: 1px !important;
  }

  .md\:-translate-x-1 {
    --transform-translate-x: -0.25rem !important;
  }

  .md\:-translate-x-2 {
    --transform-translate-x: -0.5rem !important;
  }

  .md\:-translate-x-3 {
    --transform-translate-x: -0.75rem !important;
  }

  .md\:-translate-x-4 {
    --transform-translate-x: -1rem !important;
  }

  .md\:-translate-x-5 {
    --transform-translate-x: -1.25rem !important;
  }

  .md\:-translate-x-6 {
    --transform-translate-x: -1.5rem !important;
  }

  .md\:-translate-x-8 {
    --transform-translate-x: -2rem !important;
  }

  .md\:-translate-x-10 {
    --transform-translate-x: -2.5rem !important;
  }

  .md\:-translate-x-12 {
    --transform-translate-x: -3rem !important;
  }

  .md\:-translate-x-16 {
    --transform-translate-x: -4rem !important;
  }

  .md\:-translate-x-20 {
    --transform-translate-x: -5rem !important;
  }

  .md\:-translate-x-24 {
    --transform-translate-x: -6rem !important;
  }

  .md\:-translate-x-32 {
    --transform-translate-x: -8rem !important;
  }

  .md\:-translate-x-40 {
    --transform-translate-x: -10rem !important;
  }

  .md\:-translate-x-48 {
    --transform-translate-x: -12rem !important;
  }

  .md\:-translate-x-56 {
    --transform-translate-x: -14rem !important;
  }

  .md\:-translate-x-64 {
    --transform-translate-x: -16rem !important;
  }

  .md\:-translate-x-px {
    --transform-translate-x: -1px !important;
  }

  .md\:-translate-x-full {
    --transform-translate-x: -100% !important;
  }

  .md\:-translate-x-1\/2 {
    --transform-translate-x: -50% !important;
  }

  .md\:translate-x-1\/2 {
    --transform-translate-x: 50% !important;
  }

  .md\:translate-x-full {
    --transform-translate-x: 100% !important;
  }

  .md\:translate-y-0 {
    --transform-translate-y: 0 !important;
  }

  .md\:translate-y-1 {
    --transform-translate-y: 0.25rem !important;
  }

  .md\:translate-y-2 {
    --transform-translate-y: 0.5rem !important;
  }

  .md\:translate-y-3 {
    --transform-translate-y: 0.75rem !important;
  }

  .md\:translate-y-4 {
    --transform-translate-y: 1rem !important;
  }

  .md\:translate-y-5 {
    --transform-translate-y: 1.25rem !important;
  }

  .md\:translate-y-6 {
    --transform-translate-y: 1.5rem !important;
  }

  .md\:translate-y-8 {
    --transform-translate-y: 2rem !important;
  }

  .md\:translate-y-10 {
    --transform-translate-y: 2.5rem !important;
  }

  .md\:translate-y-12 {
    --transform-translate-y: 3rem !important;
  }

  .md\:translate-y-16 {
    --transform-translate-y: 4rem !important;
  }

  .md\:translate-y-20 {
    --transform-translate-y: 5rem !important;
  }

  .md\:translate-y-24 {
    --transform-translate-y: 6rem !important;
  }

  .md\:translate-y-32 {
    --transform-translate-y: 8rem !important;
  }

  .md\:translate-y-40 {
    --transform-translate-y: 10rem !important;
  }

  .md\:translate-y-48 {
    --transform-translate-y: 12rem !important;
  }

  .md\:translate-y-56 {
    --transform-translate-y: 14rem !important;
  }

  .md\:translate-y-64 {
    --transform-translate-y: 16rem !important;
  }

  .md\:translate-y-px {
    --transform-translate-y: 1px !important;
  }

  .md\:-translate-y-1 {
    --transform-translate-y: -0.25rem !important;
  }

  .md\:-translate-y-2 {
    --transform-translate-y: -0.5rem !important;
  }

  .md\:-translate-y-3 {
    --transform-translate-y: -0.75rem !important;
  }

  .md\:-translate-y-4 {
    --transform-translate-y: -1rem !important;
  }

  .md\:-translate-y-5 {
    --transform-translate-y: -1.25rem !important;
  }

  .md\:-translate-y-6 {
    --transform-translate-y: -1.5rem !important;
  }

  .md\:-translate-y-8 {
    --transform-translate-y: -2rem !important;
  }

  .md\:-translate-y-10 {
    --transform-translate-y: -2.5rem !important;
  }

  .md\:-translate-y-12 {
    --transform-translate-y: -3rem !important;
  }

  .md\:-translate-y-16 {
    --transform-translate-y: -4rem !important;
  }

  .md\:-translate-y-20 {
    --transform-translate-y: -5rem !important;
  }

  .md\:-translate-y-24 {
    --transform-translate-y: -6rem !important;
  }

  .md\:-translate-y-32 {
    --transform-translate-y: -8rem !important;
  }

  .md\:-translate-y-40 {
    --transform-translate-y: -10rem !important;
  }

  .md\:-translate-y-48 {
    --transform-translate-y: -12rem !important;
  }

  .md\:-translate-y-56 {
    --transform-translate-y: -14rem !important;
  }

  .md\:-translate-y-64 {
    --transform-translate-y: -16rem !important;
  }

  .md\:-translate-y-px {
    --transform-translate-y: -1px !important;
  }

  .md\:-translate-y-full {
    --transform-translate-y: -100% !important;
  }

  .md\:-translate-y-1\/2 {
    --transform-translate-y: -50% !important;
  }

  .md\:translate-y-1\/2 {
    --transform-translate-y: 50% !important;
  }

  .md\:translate-y-full {
    --transform-translate-y: 100% !important;
  }

  .md\:hover\:translate-x-0:hover {
    --transform-translate-x: 0 !important;
  }

  .md\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem !important;
  }

  .md\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem !important;
  }

  .md\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem !important;
  }

  .md\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem !important;
  }

  .md\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem !important;
  }

  .md\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem !important;
  }

  .md\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem !important;
  }

  .md\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem !important;
  }

  .md\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem !important;
  }

  .md\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem !important;
  }

  .md\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem !important;
  }

  .md\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem !important;
  }

  .md\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem !important;
  }

  .md\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem !important;
  }

  .md\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem !important;
  }

  .md\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem !important;
  }

  .md\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem !important;
  }

  .md\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px !important;
  }

  .md\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem !important;
  }

  .md\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem !important;
  }

  .md\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem !important;
  }

  .md\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem !important;
  }

  .md\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem !important;
  }

  .md\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem !important;
  }

  .md\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem !important;
  }

  .md\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem !important;
  }

  .md\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem !important;
  }

  .md\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem !important;
  }

  .md\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem !important;
  }

  .md\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem !important;
  }

  .md\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem !important;
  }

  .md\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem !important;
  }

  .md\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem !important;
  }

  .md\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem !important;
  }

  .md\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem !important;
  }

  .md\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px !important;
  }

  .md\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100% !important;
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50% !important;
  }

  .md\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50% !important;
  }

  .md\:hover\:translate-x-full:hover {
    --transform-translate-x: 100% !important;
  }

  .md\:hover\:translate-y-0:hover {
    --transform-translate-y: 0 !important;
  }

  .md\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem !important;
  }

  .md\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem !important;
  }

  .md\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem !important;
  }

  .md\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem !important;
  }

  .md\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem !important;
  }

  .md\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem !important;
  }

  .md\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem !important;
  }

  .md\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem !important;
  }

  .md\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem !important;
  }

  .md\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem !important;
  }

  .md\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem !important;
  }

  .md\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem !important;
  }

  .md\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem !important;
  }

  .md\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem !important;
  }

  .md\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem !important;
  }

  .md\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem !important;
  }

  .md\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem !important;
  }

  .md\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px !important;
  }

  .md\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem !important;
  }

  .md\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem !important;
  }

  .md\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem !important;
  }

  .md\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem !important;
  }

  .md\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem !important;
  }

  .md\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem !important;
  }

  .md\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem !important;
  }

  .md\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem !important;
  }

  .md\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem !important;
  }

  .md\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem !important;
  }

  .md\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem !important;
  }

  .md\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem !important;
  }

  .md\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem !important;
  }

  .md\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem !important;
  }

  .md\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem !important;
  }

  .md\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem !important;
  }

  .md\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem !important;
  }

  .md\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px !important;
  }

  .md\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100% !important;
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50% !important;
  }

  .md\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50% !important;
  }

  .md\:hover\:translate-y-full:hover {
    --transform-translate-y: 100% !important;
  }

  .md\:focus\:translate-x-0:focus {
    --transform-translate-x: 0 !important;
  }

  .md\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem !important;
  }

  .md\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem !important;
  }

  .md\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem !important;
  }

  .md\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem !important;
  }

  .md\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem !important;
  }

  .md\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem !important;
  }

  .md\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem !important;
  }

  .md\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem !important;
  }

  .md\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem !important;
  }

  .md\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem !important;
  }

  .md\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem !important;
  }

  .md\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem !important;
  }

  .md\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem !important;
  }

  .md\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem !important;
  }

  .md\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem !important;
  }

  .md\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem !important;
  }

  .md\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem !important;
  }

  .md\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px !important;
  }

  .md\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem !important;
  }

  .md\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem !important;
  }

  .md\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem !important;
  }

  .md\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem !important;
  }

  .md\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem !important;
  }

  .md\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem !important;
  }

  .md\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem !important;
  }

  .md\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem !important;
  }

  .md\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem !important;
  }

  .md\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem !important;
  }

  .md\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem !important;
  }

  .md\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem !important;
  }

  .md\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem !important;
  }

  .md\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem !important;
  }

  .md\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem !important;
  }

  .md\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem !important;
  }

  .md\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem !important;
  }

  .md\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px !important;
  }

  .md\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100% !important;
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50% !important;
  }

  .md\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50% !important;
  }

  .md\:focus\:translate-x-full:focus {
    --transform-translate-x: 100% !important;
  }

  .md\:focus\:translate-y-0:focus {
    --transform-translate-y: 0 !important;
  }

  .md\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem !important;
  }

  .md\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem !important;
  }

  .md\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem !important;
  }

  .md\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem !important;
  }

  .md\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem !important;
  }

  .md\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem !important;
  }

  .md\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem !important;
  }

  .md\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem !important;
  }

  .md\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem !important;
  }

  .md\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem !important;
  }

  .md\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem !important;
  }

  .md\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem !important;
  }

  .md\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem !important;
  }

  .md\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem !important;
  }

  .md\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem !important;
  }

  .md\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem !important;
  }

  .md\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem !important;
  }

  .md\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px !important;
  }

  .md\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem !important;
  }

  .md\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem !important;
  }

  .md\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem !important;
  }

  .md\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem !important;
  }

  .md\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem !important;
  }

  .md\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem !important;
  }

  .md\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem !important;
  }

  .md\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem !important;
  }

  .md\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem !important;
  }

  .md\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem !important;
  }

  .md\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem !important;
  }

  .md\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem !important;
  }

  .md\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem !important;
  }

  .md\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem !important;
  }

  .md\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem !important;
  }

  .md\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem !important;
  }

  .md\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem !important;
  }

  .md\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px !important;
  }

  .md\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100% !important;
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50% !important;
  }

  .md\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50% !important;
  }

  .md\:focus\:translate-y-full:focus {
    --transform-translate-y: 100% !important;
  }

  .md\:skew-x-0 {
    --transform-skew-x: 0 !important;
  }

  .md\:skew-x-1 {
    --transform-skew-x: 1deg !important;
  }

  .md\:skew-x-2 {
    --transform-skew-x: 2deg !important;
  }

  .md\:skew-x-3 {
    --transform-skew-x: 3deg !important;
  }

  .md\:skew-x-6 {
    --transform-skew-x: 6deg !important;
  }

  .md\:skew-x-12 {
    --transform-skew-x: 12deg !important;
  }

  .md\:-skew-x-12 {
    --transform-skew-x: -12deg !important;
  }

  .md\:-skew-x-6 {
    --transform-skew-x: -6deg !important;
  }

  .md\:-skew-x-3 {
    --transform-skew-x: -3deg !important;
  }

  .md\:-skew-x-2 {
    --transform-skew-x: -2deg !important;
  }

  .md\:-skew-x-1 {
    --transform-skew-x: -1deg !important;
  }

  .md\:skew-y-0 {
    --transform-skew-y: 0 !important;
  }

  .md\:skew-y-1 {
    --transform-skew-y: 1deg !important;
  }

  .md\:skew-y-2 {
    --transform-skew-y: 2deg !important;
  }

  .md\:skew-y-3 {
    --transform-skew-y: 3deg !important;
  }

  .md\:skew-y-6 {
    --transform-skew-y: 6deg !important;
  }

  .md\:skew-y-12 {
    --transform-skew-y: 12deg !important;
  }

  .md\:-skew-y-12 {
    --transform-skew-y: -12deg !important;
  }

  .md\:-skew-y-6 {
    --transform-skew-y: -6deg !important;
  }

  .md\:-skew-y-3 {
    --transform-skew-y: -3deg !important;
  }

  .md\:-skew-y-2 {
    --transform-skew-y: -2deg !important;
  }

  .md\:-skew-y-1 {
    --transform-skew-y: -1deg !important;
  }

  .md\:hover\:skew-x-0:hover {
    --transform-skew-x: 0 !important;
  }

  .md\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg !important;
  }

  .md\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg !important;
  }

  .md\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg !important;
  }

  .md\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg !important;
  }

  .md\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg !important;
  }

  .md\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg !important;
  }

  .md\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg !important;
  }

  .md\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg !important;
  }

  .md\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg !important;
  }

  .md\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg !important;
  }

  .md\:hover\:skew-y-0:hover {
    --transform-skew-y: 0 !important;
  }

  .md\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg !important;
  }

  .md\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg !important;
  }

  .md\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg !important;
  }

  .md\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg !important;
  }

  .md\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg !important;
  }

  .md\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg !important;
  }

  .md\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg !important;
  }

  .md\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg !important;
  }

  .md\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg !important;
  }

  .md\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg !important;
  }

  .md\:focus\:skew-x-0:focus {
    --transform-skew-x: 0 !important;
  }

  .md\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg !important;
  }

  .md\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg !important;
  }

  .md\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg !important;
  }

  .md\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg !important;
  }

  .md\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg !important;
  }

  .md\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg !important;
  }

  .md\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg !important;
  }

  .md\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg !important;
  }

  .md\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg !important;
  }

  .md\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg !important;
  }

  .md\:focus\:skew-y-0:focus {
    --transform-skew-y: 0 !important;
  }

  .md\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg !important;
  }

  .md\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg !important;
  }

  .md\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg !important;
  }

  .md\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg !important;
  }

  .md\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg !important;
  }

  .md\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg !important;
  }

  .md\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg !important;
  }

  .md\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg !important;
  }

  .md\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg !important;
  }

  .md\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg !important;
  }

  .md\:transition-none {
    transition-property: none !important;
  }

  .md\:transition-all {
    transition-property: all !important;
  }

  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .md\:transition-opacity {
    transition-property: opacity !important;
  }

  .md\:transition-shadow {
    transition-property: box-shadow !important;
  }

  .md\:transition-transform {
    transition-property: transform !important;
  }

  .md\:ease-linear {
    transition-timing-function: linear !important;
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .md\:duration-75 {
    transition-duration: 75ms !important;
  }

  .md\:duration-100 {
    transition-duration: 100ms !important;
  }

  .md\:duration-150 {
    transition-duration: 150ms !important;
  }

  .md\:duration-200 {
    transition-duration: 200ms !important;
  }

  .md\:duration-300 {
    transition-duration: 300ms !important;
  }

  .md\:duration-500 {
    transition-duration: 500ms !important;
  }

  .md\:duration-700 {
    transition-duration: 700ms !important;
  }

  .md\:duration-1000 {
    transition-duration: 1000ms !important;
  }

  .md\:delay-75 {
    transition-delay: 75ms !important;
  }

  .md\:delay-100 {
    transition-delay: 100ms !important;
  }

  .md\:delay-150 {
    transition-delay: 150ms !important;
  }

  .md\:delay-200 {
    transition-delay: 200ms !important;
  }

  .md\:delay-300 {
    transition-delay: 300ms !important;
  }

  .md\:delay-500 {
    transition-delay: 500ms !important;
  }

  .md\:delay-700 {
    transition-delay: 700ms !important;
  }

  .md\:delay-1000 {
    transition-delay: 1000ms !important;
  }

  .md\:animate-none {
    animation: none !important;
  }

  .md\:animate-spin {
    animation: spin 1s linear infinite !important;
  }

  .md\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .md\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .md\:animate-bounce {
    animation: bounce 1s infinite !important;
  }

  .md\:link {
    color: var(--primary);
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

@media (min-width: 992px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 480px) {
    .lg\:container {
      max-width: 480px;
    }
  }

  @media (min-width: 576px) {
    .lg\:container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .lg\:container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .lg\:container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .lg\:container {
      max-width: 1600px;
    }
  }

  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .lg\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .lg\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .lg\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .lg\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
  }

  .lg\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--primary) !important;
  }

  .lg\:divide-accent-1 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-accent-2 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
  }

  .lg\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid !important;
  }

  .lg\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed !important;
  }

  .lg\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted !important;
  }

  .lg\:divide-double > :not(template) ~ :not(template) {
    border-style: double !important;
  }

  .lg\:divide-none > :not(template) ~ :not(template) {
    border-style: none !important;
  }

  .lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .lg\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .lg\:bg-fixed {
    background-attachment: fixed !important;
  }

  .lg\:bg-local {
    background-attachment: local !important;
  }

  .lg\:bg-scroll {
    background-attachment: scroll !important;
  }

  .lg\:bg-clip-border {
    background-clip: border-box !important;
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box !important;
  }

  .lg\:bg-clip-content {
    background-clip: content-box !important;
  }

  .lg\:bg-clip-text {
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .lg\:bg-transparent {
    background-color: transparent !important;
  }

  .lg\:bg-current {
    background-color: currentColor !important;
  }

  .lg\:bg-black {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .lg\:bg-white {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-100 {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-200 {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-300 {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-400 {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-500 {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-600 {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-700 {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-800 {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .lg\:bg-gray-900 {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-300 {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-400 {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-500 {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-600 {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-700 {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-800 {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .lg\:bg-red-900 {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-100 {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-200 {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-300 {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-400 {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-500 {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-600 {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-700 {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-800 {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .lg\:bg-orange-900 {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-100 {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-200 {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-300 {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-400 {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-500 {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-600 {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-700 {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-800 {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .lg\:bg-yellow-900 {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-100 {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-200 {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-300 {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-400 {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-500 {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-600 {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-700 {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-800 {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .lg\:bg-green-900 {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-100 {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-200 {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-300 {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-400 {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-500 {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-600 {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-700 {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-800 {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .lg\:bg-teal-900 {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-200 {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-300 {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-400 {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-500 {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-600 {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-700 {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-800 {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .lg\:bg-blue-900 {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-200 {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-300 {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-400 {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-500 {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-600 {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-700 {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-800 {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .lg\:bg-indigo-900 {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-100 {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-200 {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-300 {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-400 {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-500 {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-600 {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-700 {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-800 {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .lg\:bg-purple-900 {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-300 {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-400 {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-500 {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-600 {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-700 {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-800 {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .lg\:bg-pink-900 {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .lg\:bg-primary {
    background-color: var(--primary) !important;
  }

  .lg\:bg-accent-1 {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .lg\:bg-accent-2 {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: transparent !important;
  }

  .lg\:hover\:bg-current:hover {
    background-color: currentColor !important;
  }

  .lg\:hover\:bg-black:hover {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-white:hover {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-200:hover {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-300:hover {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-400:hover {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-500:hover {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-600:hover {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-900:hover {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-300:hover {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-400:hover {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-500:hover {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-600:hover {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-900:hover {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-200:hover {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-600:hover {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-orange-900:hover {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-300:hover {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-400:hover {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-500:hover {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-600:hover {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-800:hover {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-900:hover {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-100:hover {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-200:hover {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-300:hover {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-400:hover {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-500:hover {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-600:hover {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-800:hover {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-teal-900:hover {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-200:hover {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-300:hover {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-400:hover {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-500:hover {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-600:hover {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-900:hover {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-100:hover {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-200:hover {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-300:hover {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-400:hover {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-500:hover {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-600:hover {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-700:hover {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-800:hover {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-900:hover {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-800:hover {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-900:hover {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-primary:hover {
    background-color: var(--primary) !important;
  }

  .lg\:hover\:bg-accent-1:hover {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .lg\:hover\:bg-accent-2:hover {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: transparent !important;
  }

  .lg\:focus\:bg-current:focus {
    background-color: currentColor !important;
  }

  .lg\:focus\:bg-black:focus {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-white:focus {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-200:focus {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-300:focus {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-400:focus {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-500:focus {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-600:focus {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-900:focus {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-300:focus {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-400:focus {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-500:focus {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-600:focus {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-900:focus {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-200:focus {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-600:focus {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-orange-900:focus {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-300:focus {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-400:focus {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-500:focus {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-600:focus {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-800:focus {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-900:focus {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-100:focus {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-200:focus {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-300:focus {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-400:focus {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-500:focus {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-600:focus {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-800:focus {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-teal-900:focus {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-200:focus {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-300:focus {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-400:focus {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-500:focus {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-600:focus {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-900:focus {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-100:focus {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-200:focus {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-300:focus {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-400:focus {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-500:focus {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-600:focus {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-700:focus {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-800:focus {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-900:focus {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-800:focus {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-900:focus {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-primary:focus {
    background-color: var(--primary) !important;
  }

  .lg\:focus\:bg-accent-1:focus {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .lg\:focus\:bg-accent-2:focus {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .lg\:bg-none {
    background-image: none !important;
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
  }

  .lg\:from-transparent {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:from-current {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:from-black {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:from-white {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:from-gray-100 {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .lg\:from-gray-200 {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .lg\:from-gray-300 {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .lg\:from-gray-400 {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .lg\:from-gray-500 {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .lg\:from-gray-600 {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .lg\:from-gray-700 {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .lg\:from-gray-800 {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .lg\:from-gray-900 {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .lg\:from-red-100 {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .lg\:from-red-200 {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .lg\:from-red-300 {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .lg\:from-red-400 {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .lg\:from-red-500 {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .lg\:from-red-600 {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .lg\:from-red-700 {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .lg\:from-red-800 {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .lg\:from-red-900 {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .lg\:from-orange-100 {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .lg\:from-orange-200 {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .lg\:from-orange-300 {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .lg\:from-orange-400 {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .lg\:from-orange-500 {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .lg\:from-orange-600 {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .lg\:from-orange-700 {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .lg\:from-orange-800 {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .lg\:from-orange-900 {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .lg\:from-yellow-100 {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .lg\:from-yellow-200 {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .lg\:from-yellow-300 {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .lg\:from-yellow-400 {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .lg\:from-yellow-500 {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .lg\:from-yellow-600 {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .lg\:from-yellow-700 {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .lg\:from-yellow-800 {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .lg\:from-yellow-900 {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .lg\:from-green-100 {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .lg\:from-green-200 {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .lg\:from-green-300 {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .lg\:from-green-400 {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .lg\:from-green-500 {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .lg\:from-green-600 {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .lg\:from-green-700 {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .lg\:from-green-800 {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .lg\:from-green-900 {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .lg\:from-teal-100 {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .lg\:from-teal-200 {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .lg\:from-teal-300 {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .lg\:from-teal-400 {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .lg\:from-teal-500 {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .lg\:from-teal-600 {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .lg\:from-teal-700 {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .lg\:from-teal-800 {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .lg\:from-teal-900 {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .lg\:from-blue-100 {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .lg\:from-blue-200 {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .lg\:from-blue-300 {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .lg\:from-blue-400 {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .lg\:from-blue-500 {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .lg\:from-blue-600 {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .lg\:from-blue-700 {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .lg\:from-blue-800 {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .lg\:from-blue-900 {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .lg\:from-indigo-100 {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .lg\:from-indigo-200 {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .lg\:from-indigo-300 {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .lg\:from-indigo-400 {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .lg\:from-indigo-500 {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .lg\:from-indigo-600 {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .lg\:from-indigo-700 {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .lg\:from-indigo-800 {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .lg\:from-indigo-900 {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .lg\:from-purple-100 {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .lg\:from-purple-200 {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .lg\:from-purple-300 {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .lg\:from-purple-400 {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .lg\:from-purple-500 {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .lg\:from-purple-600 {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .lg\:from-purple-700 {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .lg\:from-purple-800 {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .lg\:from-purple-900 {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .lg\:from-pink-100 {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .lg\:from-pink-200 {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .lg\:from-pink-300 {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .lg\:from-pink-400 {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .lg\:from-pink-500 {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .lg\:from-pink-600 {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .lg\:from-pink-700 {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .lg\:from-pink-800 {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .lg\:from-pink-900 {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .lg\:from-primary {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:from-accent-1 {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .lg\:from-accent-2 {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .lg\:via-transparent {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:via-current {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:via-black {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:via-white {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:via-gray-100 {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .lg\:via-gray-200 {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .lg\:via-gray-300 {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .lg\:via-gray-400 {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .lg\:via-gray-500 {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .lg\:via-gray-600 {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .lg\:via-gray-700 {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .lg\:via-gray-800 {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .lg\:via-gray-900 {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .lg\:via-red-100 {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .lg\:via-red-200 {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .lg\:via-red-300 {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .lg\:via-red-400 {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .lg\:via-red-500 {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .lg\:via-red-600 {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .lg\:via-red-700 {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .lg\:via-red-800 {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .lg\:via-red-900 {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .lg\:via-orange-100 {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .lg\:via-orange-200 {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .lg\:via-orange-300 {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .lg\:via-orange-400 {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .lg\:via-orange-500 {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .lg\:via-orange-600 {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .lg\:via-orange-700 {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .lg\:via-orange-800 {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .lg\:via-orange-900 {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .lg\:via-yellow-100 {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .lg\:via-yellow-200 {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .lg\:via-yellow-300 {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .lg\:via-yellow-400 {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .lg\:via-yellow-500 {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .lg\:via-yellow-600 {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .lg\:via-yellow-700 {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .lg\:via-yellow-800 {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .lg\:via-yellow-900 {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .lg\:via-green-100 {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .lg\:via-green-200 {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .lg\:via-green-300 {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .lg\:via-green-400 {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .lg\:via-green-500 {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .lg\:via-green-600 {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .lg\:via-green-700 {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .lg\:via-green-800 {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .lg\:via-green-900 {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .lg\:via-teal-100 {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .lg\:via-teal-200 {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .lg\:via-teal-300 {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .lg\:via-teal-400 {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .lg\:via-teal-500 {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .lg\:via-teal-600 {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .lg\:via-teal-700 {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .lg\:via-teal-800 {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .lg\:via-teal-900 {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .lg\:via-blue-100 {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .lg\:via-blue-200 {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .lg\:via-blue-300 {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .lg\:via-blue-400 {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .lg\:via-blue-500 {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .lg\:via-blue-600 {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .lg\:via-blue-700 {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .lg\:via-blue-800 {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .lg\:via-blue-900 {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .lg\:via-indigo-100 {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .lg\:via-indigo-200 {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .lg\:via-indigo-300 {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .lg\:via-indigo-400 {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .lg\:via-indigo-500 {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .lg\:via-indigo-600 {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .lg\:via-indigo-700 {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .lg\:via-indigo-800 {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .lg\:via-indigo-900 {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .lg\:via-purple-100 {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .lg\:via-purple-200 {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .lg\:via-purple-300 {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .lg\:via-purple-400 {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .lg\:via-purple-500 {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .lg\:via-purple-600 {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .lg\:via-purple-700 {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .lg\:via-purple-800 {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .lg\:via-purple-900 {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .lg\:via-pink-100 {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .lg\:via-pink-200 {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .lg\:via-pink-300 {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .lg\:via-pink-400 {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .lg\:via-pink-500 {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .lg\:via-pink-600 {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .lg\:via-pink-700 {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .lg\:via-pink-800 {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .lg\:via-pink-900 {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .lg\:via-primary {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:via-accent-1 {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .lg\:via-accent-2 {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .lg\:to-transparent {
    --gradient-to-color: transparent !important;
  }

  .lg\:to-current {
    --gradient-to-color: currentColor !important;
  }

  .lg\:to-black {
    --gradient-to-color: #000 !important;
  }

  .lg\:to-white {
    --gradient-to-color: #fff !important;
  }

  .lg\:to-gray-100 {
    --gradient-to-color: #f7fafc !important;
  }

  .lg\:to-gray-200 {
    --gradient-to-color: #edf2f7 !important;
  }

  .lg\:to-gray-300 {
    --gradient-to-color: #e2e8f0 !important;
  }

  .lg\:to-gray-400 {
    --gradient-to-color: #cbd5e0 !important;
  }

  .lg\:to-gray-500 {
    --gradient-to-color: #a0aec0 !important;
  }

  .lg\:to-gray-600 {
    --gradient-to-color: #718096 !important;
  }

  .lg\:to-gray-700 {
    --gradient-to-color: #4a5568 !important;
  }

  .lg\:to-gray-800 {
    --gradient-to-color: #2d3748 !important;
  }

  .lg\:to-gray-900 {
    --gradient-to-color: #1a202c !important;
  }

  .lg\:to-red-100 {
    --gradient-to-color: #fff5f5 !important;
  }

  .lg\:to-red-200 {
    --gradient-to-color: #fed7d7 !important;
  }

  .lg\:to-red-300 {
    --gradient-to-color: #feb2b2 !important;
  }

  .lg\:to-red-400 {
    --gradient-to-color: #fc8181 !important;
  }

  .lg\:to-red-500 {
    --gradient-to-color: #f56565 !important;
  }

  .lg\:to-red-600 {
    --gradient-to-color: #e53e3e !important;
  }

  .lg\:to-red-700 {
    --gradient-to-color: #c53030 !important;
  }

  .lg\:to-red-800 {
    --gradient-to-color: #9b2c2c !important;
  }

  .lg\:to-red-900 {
    --gradient-to-color: #742a2a !important;
  }

  .lg\:to-orange-100 {
    --gradient-to-color: #fffaf0 !important;
  }

  .lg\:to-orange-200 {
    --gradient-to-color: #feebc8 !important;
  }

  .lg\:to-orange-300 {
    --gradient-to-color: #fbd38d !important;
  }

  .lg\:to-orange-400 {
    --gradient-to-color: #f6ad55 !important;
  }

  .lg\:to-orange-500 {
    --gradient-to-color: #ed8936 !important;
  }

  .lg\:to-orange-600 {
    --gradient-to-color: #dd6b20 !important;
  }

  .lg\:to-orange-700 {
    --gradient-to-color: #c05621 !important;
  }

  .lg\:to-orange-800 {
    --gradient-to-color: #9c4221 !important;
  }

  .lg\:to-orange-900 {
    --gradient-to-color: #7b341e !important;
  }

  .lg\:to-yellow-100 {
    --gradient-to-color: #fffff0 !important;
  }

  .lg\:to-yellow-200 {
    --gradient-to-color: #fefcbf !important;
  }

  .lg\:to-yellow-300 {
    --gradient-to-color: #faf089 !important;
  }

  .lg\:to-yellow-400 {
    --gradient-to-color: #f6e05e !important;
  }

  .lg\:to-yellow-500 {
    --gradient-to-color: #ecc94b !important;
  }

  .lg\:to-yellow-600 {
    --gradient-to-color: #d69e2e !important;
  }

  .lg\:to-yellow-700 {
    --gradient-to-color: #b7791f !important;
  }

  .lg\:to-yellow-800 {
    --gradient-to-color: #975a16 !important;
  }

  .lg\:to-yellow-900 {
    --gradient-to-color: #744210 !important;
  }

  .lg\:to-green-100 {
    --gradient-to-color: #f0fff4 !important;
  }

  .lg\:to-green-200 {
    --gradient-to-color: #c6f6d5 !important;
  }

  .lg\:to-green-300 {
    --gradient-to-color: #9ae6b4 !important;
  }

  .lg\:to-green-400 {
    --gradient-to-color: #68d391 !important;
  }

  .lg\:to-green-500 {
    --gradient-to-color: #48bb78 !important;
  }

  .lg\:to-green-600 {
    --gradient-to-color: #38a169 !important;
  }

  .lg\:to-green-700 {
    --gradient-to-color: #2f855a !important;
  }

  .lg\:to-green-800 {
    --gradient-to-color: #276749 !important;
  }

  .lg\:to-green-900 {
    --gradient-to-color: #22543d !important;
  }

  .lg\:to-teal-100 {
    --gradient-to-color: #e6fffa !important;
  }

  .lg\:to-teal-200 {
    --gradient-to-color: #b2f5ea !important;
  }

  .lg\:to-teal-300 {
    --gradient-to-color: #81e6d9 !important;
  }

  .lg\:to-teal-400 {
    --gradient-to-color: #4fd1c5 !important;
  }

  .lg\:to-teal-500 {
    --gradient-to-color: #38b2ac !important;
  }

  .lg\:to-teal-600 {
    --gradient-to-color: #319795 !important;
  }

  .lg\:to-teal-700 {
    --gradient-to-color: #2c7a7b !important;
  }

  .lg\:to-teal-800 {
    --gradient-to-color: #285e61 !important;
  }

  .lg\:to-teal-900 {
    --gradient-to-color: #234e52 !important;
  }

  .lg\:to-blue-100 {
    --gradient-to-color: #ebf8ff !important;
  }

  .lg\:to-blue-200 {
    --gradient-to-color: #bee3f8 !important;
  }

  .lg\:to-blue-300 {
    --gradient-to-color: #90cdf4 !important;
  }

  .lg\:to-blue-400 {
    --gradient-to-color: #63b3ed !important;
  }

  .lg\:to-blue-500 {
    --gradient-to-color: #4299e1 !important;
  }

  .lg\:to-blue-600 {
    --gradient-to-color: #3182ce !important;
  }

  .lg\:to-blue-700 {
    --gradient-to-color: #2b6cb0 !important;
  }

  .lg\:to-blue-800 {
    --gradient-to-color: #2c5282 !important;
  }

  .lg\:to-blue-900 {
    --gradient-to-color: #2a4365 !important;
  }

  .lg\:to-indigo-100 {
    --gradient-to-color: #ebf4ff !important;
  }

  .lg\:to-indigo-200 {
    --gradient-to-color: #c3dafe !important;
  }

  .lg\:to-indigo-300 {
    --gradient-to-color: #a3bffa !important;
  }

  .lg\:to-indigo-400 {
    --gradient-to-color: #7f9cf5 !important;
  }

  .lg\:to-indigo-500 {
    --gradient-to-color: #667eea !important;
  }

  .lg\:to-indigo-600 {
    --gradient-to-color: #5a67d8 !important;
  }

  .lg\:to-indigo-700 {
    --gradient-to-color: #4c51bf !important;
  }

  .lg\:to-indigo-800 {
    --gradient-to-color: #434190 !important;
  }

  .lg\:to-indigo-900 {
    --gradient-to-color: #3c366b !important;
  }

  .lg\:to-purple-100 {
    --gradient-to-color: #faf5ff !important;
  }

  .lg\:to-purple-200 {
    --gradient-to-color: #e9d8fd !important;
  }

  .lg\:to-purple-300 {
    --gradient-to-color: #d6bcfa !important;
  }

  .lg\:to-purple-400 {
    --gradient-to-color: #b794f4 !important;
  }

  .lg\:to-purple-500 {
    --gradient-to-color: #9f7aea !important;
  }

  .lg\:to-purple-600 {
    --gradient-to-color: #805ad5 !important;
  }

  .lg\:to-purple-700 {
    --gradient-to-color: #6b46c1 !important;
  }

  .lg\:to-purple-800 {
    --gradient-to-color: #553c9a !important;
  }

  .lg\:to-purple-900 {
    --gradient-to-color: #44337a !important;
  }

  .lg\:to-pink-100 {
    --gradient-to-color: #fff5f7 !important;
  }

  .lg\:to-pink-200 {
    --gradient-to-color: #fed7e2 !important;
  }

  .lg\:to-pink-300 {
    --gradient-to-color: #fbb6ce !important;
  }

  .lg\:to-pink-400 {
    --gradient-to-color: #f687b3 !important;
  }

  .lg\:to-pink-500 {
    --gradient-to-color: #ed64a6 !important;
  }

  .lg\:to-pink-600 {
    --gradient-to-color: #d53f8c !important;
  }

  .lg\:to-pink-700 {
    --gradient-to-color: #b83280 !important;
  }

  .lg\:to-pink-800 {
    --gradient-to-color: #97266d !important;
  }

  .lg\:to-pink-900 {
    --gradient-to-color: #702459 !important;
  }

  .lg\:to-primary {
    --gradient-to-color: var(--primary) !important;
  }

  .lg\:to-accent-1 {
    --gradient-to-color: #fafafa !important;
  }

  .lg\:to-accent-2 {
    --gradient-to-color: #eaeaea !important;
  }

  .lg\:hover\:from-transparent:hover {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:from-current:hover {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:from-black:hover {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:from-white:hover {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .lg\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .lg\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .lg\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .lg\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .lg\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .lg\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .lg\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .lg\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .lg\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .lg\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .lg\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .lg\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .lg\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .lg\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .lg\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .lg\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .lg\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .lg\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .lg\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .lg\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .lg\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .lg\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .lg\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .lg\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .lg\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .lg\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .lg\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .lg\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .lg\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .lg\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .lg\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .lg\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .lg\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .lg\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .lg\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .lg\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .lg\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .lg\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .lg\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .lg\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .lg\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .lg\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .lg\:hover\:from-green-800:hover {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .lg\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .lg\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .lg\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .lg\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .lg\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .lg\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .lg\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .lg\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .lg\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .lg\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .lg\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .lg\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .lg\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .lg\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .lg\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .lg\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .lg\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .lg\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .lg\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .lg\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .lg\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .lg\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .lg\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .lg\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .lg\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .lg\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .lg\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .lg\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .lg\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .lg\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .lg\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .lg\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .lg\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .lg\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .lg\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .lg\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .lg\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .lg\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .lg\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .lg\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .lg\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .lg\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .lg\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .lg\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .lg\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .lg\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .lg\:hover\:from-primary:hover {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:from-accent-1:hover {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .lg\:hover\:from-accent-2:hover {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .lg\:hover\:via-transparent:hover {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:via-current:hover {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:via-black:hover {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:via-white:hover {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .lg\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .lg\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .lg\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .lg\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .lg\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .lg\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .lg\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .lg\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .lg\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .lg\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .lg\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .lg\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .lg\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .lg\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .lg\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .lg\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .lg\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .lg\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .lg\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .lg\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .lg\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .lg\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .lg\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .lg\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .lg\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .lg\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .lg\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .lg\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .lg\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .lg\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .lg\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .lg\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .lg\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .lg\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .lg\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .lg\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .lg\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .lg\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .lg\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .lg\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .lg\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .lg\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .lg\:hover\:via-green-800:hover {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .lg\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .lg\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .lg\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .lg\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .lg\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .lg\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .lg\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .lg\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .lg\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .lg\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .lg\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .lg\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .lg\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .lg\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .lg\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .lg\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .lg\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .lg\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .lg\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .lg\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .lg\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .lg\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .lg\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .lg\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .lg\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .lg\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .lg\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .lg\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .lg\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .lg\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .lg\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .lg\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .lg\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .lg\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .lg\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .lg\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .lg\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .lg\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .lg\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .lg\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .lg\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .lg\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .lg\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .lg\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .lg\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .lg\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .lg\:hover\:via-primary:hover {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:via-accent-1:hover {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .lg\:hover\:via-accent-2:hover {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .lg\:hover\:to-transparent:hover {
    --gradient-to-color: transparent !important;
  }

  .lg\:hover\:to-current:hover {
    --gradient-to-color: currentColor !important;
  }

  .lg\:hover\:to-black:hover {
    --gradient-to-color: #000 !important;
  }

  .lg\:hover\:to-white:hover {
    --gradient-to-color: #fff !important;
  }

  .lg\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc !important;
  }

  .lg\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7 !important;
  }

  .lg\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0 !important;
  }

  .lg\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0 !important;
  }

  .lg\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0 !important;
  }

  .lg\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096 !important;
  }

  .lg\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568 !important;
  }

  .lg\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748 !important;
  }

  .lg\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c !important;
  }

  .lg\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5 !important;
  }

  .lg\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7 !important;
  }

  .lg\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2 !important;
  }

  .lg\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181 !important;
  }

  .lg\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565 !important;
  }

  .lg\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e !important;
  }

  .lg\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030 !important;
  }

  .lg\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c !important;
  }

  .lg\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a !important;
  }

  .lg\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0 !important;
  }

  .lg\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8 !important;
  }

  .lg\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d !important;
  }

  .lg\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55 !important;
  }

  .lg\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936 !important;
  }

  .lg\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20 !important;
  }

  .lg\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621 !important;
  }

  .lg\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221 !important;
  }

  .lg\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e !important;
  }

  .lg\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0 !important;
  }

  .lg\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf !important;
  }

  .lg\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089 !important;
  }

  .lg\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e !important;
  }

  .lg\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b !important;
  }

  .lg\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e !important;
  }

  .lg\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f !important;
  }

  .lg\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16 !important;
  }

  .lg\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210 !important;
  }

  .lg\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4 !important;
  }

  .lg\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5 !important;
  }

  .lg\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4 !important;
  }

  .lg\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391 !important;
  }

  .lg\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78 !important;
  }

  .lg\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169 !important;
  }

  .lg\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a !important;
  }

  .lg\:hover\:to-green-800:hover {
    --gradient-to-color: #276749 !important;
  }

  .lg\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d !important;
  }

  .lg\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa !important;
  }

  .lg\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea !important;
  }

  .lg\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9 !important;
  }

  .lg\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5 !important;
  }

  .lg\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac !important;
  }

  .lg\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795 !important;
  }

  .lg\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b !important;
  }

  .lg\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61 !important;
  }

  .lg\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52 !important;
  }

  .lg\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff !important;
  }

  .lg\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8 !important;
  }

  .lg\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4 !important;
  }

  .lg\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed !important;
  }

  .lg\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1 !important;
  }

  .lg\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce !important;
  }

  .lg\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0 !important;
  }

  .lg\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282 !important;
  }

  .lg\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365 !important;
  }

  .lg\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff !important;
  }

  .lg\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe !important;
  }

  .lg\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa !important;
  }

  .lg\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5 !important;
  }

  .lg\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea !important;
  }

  .lg\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8 !important;
  }

  .lg\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf !important;
  }

  .lg\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190 !important;
  }

  .lg\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b !important;
  }

  .lg\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff !important;
  }

  .lg\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd !important;
  }

  .lg\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa !important;
  }

  .lg\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4 !important;
  }

  .lg\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea !important;
  }

  .lg\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5 !important;
  }

  .lg\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1 !important;
  }

  .lg\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a !important;
  }

  .lg\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a !important;
  }

  .lg\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7 !important;
  }

  .lg\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2 !important;
  }

  .lg\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce !important;
  }

  .lg\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3 !important;
  }

  .lg\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6 !important;
  }

  .lg\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c !important;
  }

  .lg\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280 !important;
  }

  .lg\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d !important;
  }

  .lg\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459 !important;
  }

  .lg\:hover\:to-primary:hover {
    --gradient-to-color: var(--primary) !important;
  }

  .lg\:hover\:to-accent-1:hover {
    --gradient-to-color: #fafafa !important;
  }

  .lg\:hover\:to-accent-2:hover {
    --gradient-to-color: #eaeaea !important;
  }

  .lg\:focus\:from-transparent:focus {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:from-current:focus {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:from-black:focus {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:from-white:focus {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .lg\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .lg\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .lg\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .lg\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .lg\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .lg\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .lg\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .lg\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .lg\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .lg\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .lg\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .lg\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .lg\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .lg\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .lg\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .lg\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .lg\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .lg\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .lg\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .lg\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .lg\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .lg\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .lg\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .lg\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .lg\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .lg\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .lg\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .lg\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .lg\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .lg\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .lg\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .lg\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .lg\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .lg\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .lg\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .lg\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .lg\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .lg\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .lg\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .lg\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .lg\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .lg\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .lg\:focus\:from-green-800:focus {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .lg\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .lg\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .lg\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .lg\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .lg\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .lg\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .lg\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .lg\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .lg\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .lg\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .lg\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .lg\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .lg\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .lg\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .lg\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .lg\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .lg\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .lg\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .lg\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .lg\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .lg\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .lg\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .lg\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .lg\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .lg\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .lg\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .lg\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .lg\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .lg\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .lg\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .lg\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .lg\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .lg\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .lg\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .lg\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .lg\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .lg\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .lg\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .lg\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .lg\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .lg\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .lg\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .lg\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .lg\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .lg\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .lg\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .lg\:focus\:from-primary:focus {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:from-accent-1:focus {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .lg\:focus\:from-accent-2:focus {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .lg\:focus\:via-transparent:focus {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:via-current:focus {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:via-black:focus {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:via-white:focus {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .lg\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .lg\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .lg\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .lg\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .lg\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .lg\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .lg\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .lg\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .lg\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .lg\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .lg\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .lg\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .lg\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .lg\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .lg\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .lg\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .lg\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .lg\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .lg\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .lg\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .lg\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .lg\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .lg\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .lg\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .lg\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .lg\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .lg\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .lg\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .lg\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .lg\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .lg\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .lg\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .lg\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .lg\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .lg\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .lg\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .lg\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .lg\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .lg\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .lg\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .lg\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .lg\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .lg\:focus\:via-green-800:focus {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .lg\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .lg\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .lg\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .lg\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .lg\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .lg\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .lg\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .lg\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .lg\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .lg\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .lg\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .lg\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .lg\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .lg\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .lg\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .lg\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .lg\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .lg\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .lg\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .lg\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .lg\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .lg\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .lg\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .lg\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .lg\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .lg\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .lg\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .lg\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .lg\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .lg\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .lg\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .lg\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .lg\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .lg\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .lg\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .lg\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .lg\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .lg\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .lg\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .lg\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .lg\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .lg\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .lg\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .lg\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .lg\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .lg\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .lg\:focus\:via-primary:focus {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:via-accent-1:focus {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .lg\:focus\:via-accent-2:focus {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .lg\:focus\:to-transparent:focus {
    --gradient-to-color: transparent !important;
  }

  .lg\:focus\:to-current:focus {
    --gradient-to-color: currentColor !important;
  }

  .lg\:focus\:to-black:focus {
    --gradient-to-color: #000 !important;
  }

  .lg\:focus\:to-white:focus {
    --gradient-to-color: #fff !important;
  }

  .lg\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc !important;
  }

  .lg\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7 !important;
  }

  .lg\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0 !important;
  }

  .lg\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0 !important;
  }

  .lg\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0 !important;
  }

  .lg\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096 !important;
  }

  .lg\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568 !important;
  }

  .lg\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748 !important;
  }

  .lg\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c !important;
  }

  .lg\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5 !important;
  }

  .lg\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7 !important;
  }

  .lg\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2 !important;
  }

  .lg\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181 !important;
  }

  .lg\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565 !important;
  }

  .lg\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e !important;
  }

  .lg\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030 !important;
  }

  .lg\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c !important;
  }

  .lg\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a !important;
  }

  .lg\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0 !important;
  }

  .lg\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8 !important;
  }

  .lg\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d !important;
  }

  .lg\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55 !important;
  }

  .lg\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936 !important;
  }

  .lg\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20 !important;
  }

  .lg\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621 !important;
  }

  .lg\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221 !important;
  }

  .lg\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e !important;
  }

  .lg\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0 !important;
  }

  .lg\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf !important;
  }

  .lg\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089 !important;
  }

  .lg\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e !important;
  }

  .lg\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b !important;
  }

  .lg\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e !important;
  }

  .lg\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f !important;
  }

  .lg\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16 !important;
  }

  .lg\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210 !important;
  }

  .lg\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4 !important;
  }

  .lg\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5 !important;
  }

  .lg\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4 !important;
  }

  .lg\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391 !important;
  }

  .lg\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78 !important;
  }

  .lg\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169 !important;
  }

  .lg\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a !important;
  }

  .lg\:focus\:to-green-800:focus {
    --gradient-to-color: #276749 !important;
  }

  .lg\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d !important;
  }

  .lg\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa !important;
  }

  .lg\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea !important;
  }

  .lg\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9 !important;
  }

  .lg\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5 !important;
  }

  .lg\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac !important;
  }

  .lg\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795 !important;
  }

  .lg\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b !important;
  }

  .lg\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61 !important;
  }

  .lg\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52 !important;
  }

  .lg\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff !important;
  }

  .lg\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8 !important;
  }

  .lg\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4 !important;
  }

  .lg\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed !important;
  }

  .lg\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1 !important;
  }

  .lg\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce !important;
  }

  .lg\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0 !important;
  }

  .lg\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282 !important;
  }

  .lg\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365 !important;
  }

  .lg\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff !important;
  }

  .lg\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe !important;
  }

  .lg\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa !important;
  }

  .lg\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5 !important;
  }

  .lg\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea !important;
  }

  .lg\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8 !important;
  }

  .lg\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf !important;
  }

  .lg\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190 !important;
  }

  .lg\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b !important;
  }

  .lg\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff !important;
  }

  .lg\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd !important;
  }

  .lg\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa !important;
  }

  .lg\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4 !important;
  }

  .lg\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea !important;
  }

  .lg\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5 !important;
  }

  .lg\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1 !important;
  }

  .lg\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a !important;
  }

  .lg\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a !important;
  }

  .lg\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7 !important;
  }

  .lg\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2 !important;
  }

  .lg\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce !important;
  }

  .lg\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3 !important;
  }

  .lg\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6 !important;
  }

  .lg\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c !important;
  }

  .lg\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280 !important;
  }

  .lg\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d !important;
  }

  .lg\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459 !important;
  }

  .lg\:focus\:to-primary:focus {
    --gradient-to-color: var(--primary) !important;
  }

  .lg\:focus\:to-accent-1:focus {
    --gradient-to-color: #fafafa !important;
  }

  .lg\:focus\:to-accent-2:focus {
    --gradient-to-color: #eaeaea !important;
  }

  .lg\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .lg\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .lg\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .lg\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .lg\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .lg\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .lg\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .lg\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .lg\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .lg\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .lg\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .lg\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .lg\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .lg\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .lg\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .lg\:bg-bottom {
    background-position: bottom !important;
  }

  .lg\:bg-center {
    background-position: center !important;
  }

  .lg\:bg-left {
    background-position: left !important;
  }

  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .lg\:bg-left-top {
    background-position: left top !important;
  }

  .lg\:bg-right {
    background-position: right !important;
  }

  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .lg\:bg-right-top {
    background-position: right top !important;
  }

  .lg\:bg-top {
    background-position: top !important;
  }

  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }

  .lg\:bg-auto {
    background-size: auto !important;
  }

  .lg\:bg-cover {
    background-size: cover !important;
  }

  .lg\:bg-contain {
    background-size: contain !important;
  }

  .lg\:border-collapse {
    border-collapse: collapse !important;
  }

  .lg\:border-separate {
    border-collapse: separate !important;
  }

  .lg\:border-transparent {
    border-color: transparent !important;
  }

  .lg\:border-current {
    border-color: currentColor !important;
  }

  .lg\:border-black {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .lg\:border-white {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .lg\:border-gray-100 {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .lg\:border-gray-200 {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .lg\:border-gray-300 {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .lg\:border-gray-400 {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .lg\:border-gray-500 {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .lg\:border-gray-600 {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .lg\:border-gray-700 {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .lg\:border-gray-800 {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .lg\:border-gray-900 {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .lg\:border-red-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .lg\:border-red-200 {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .lg\:border-red-300 {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .lg\:border-red-400 {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .lg\:border-red-500 {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .lg\:border-red-600 {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .lg\:border-red-700 {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .lg\:border-red-800 {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .lg\:border-red-900 {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .lg\:border-orange-100 {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .lg\:border-orange-200 {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .lg\:border-orange-300 {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .lg\:border-orange-400 {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .lg\:border-orange-500 {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .lg\:border-orange-600 {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .lg\:border-orange-700 {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .lg\:border-orange-800 {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .lg\:border-orange-900 {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-100 {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-200 {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-300 {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-400 {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-500 {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-600 {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-700 {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-800 {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .lg\:border-yellow-900 {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .lg\:border-green-100 {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .lg\:border-green-200 {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .lg\:border-green-300 {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .lg\:border-green-400 {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .lg\:border-green-500 {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .lg\:border-green-600 {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .lg\:border-green-700 {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .lg\:border-green-800 {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .lg\:border-green-900 {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .lg\:border-teal-100 {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .lg\:border-teal-200 {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .lg\:border-teal-300 {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .lg\:border-teal-400 {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .lg\:border-teal-500 {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .lg\:border-teal-600 {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .lg\:border-teal-700 {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .lg\:border-teal-800 {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .lg\:border-teal-900 {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .lg\:border-blue-100 {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .lg\:border-blue-200 {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .lg\:border-blue-300 {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .lg\:border-blue-400 {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .lg\:border-blue-500 {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .lg\:border-blue-600 {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .lg\:border-blue-700 {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .lg\:border-blue-800 {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .lg\:border-blue-900 {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-100 {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-200 {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-300 {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-400 {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-500 {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-600 {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-700 {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-800 {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .lg\:border-indigo-900 {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .lg\:border-purple-100 {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .lg\:border-purple-200 {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .lg\:border-purple-300 {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .lg\:border-purple-400 {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .lg\:border-purple-500 {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .lg\:border-purple-600 {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .lg\:border-purple-700 {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .lg\:border-purple-800 {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .lg\:border-purple-900 {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .lg\:border-pink-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .lg\:border-pink-200 {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .lg\:border-pink-300 {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .lg\:border-pink-400 {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .lg\:border-pink-500 {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .lg\:border-pink-600 {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .lg\:border-pink-700 {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .lg\:border-pink-800 {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .lg\:border-pink-900 {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .lg\:border-primary {
    border-color: var(--primary) !important;
  }

  .lg\:border-accent-1 {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .lg\:border-accent-2 {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  .lg\:hover\:border-current:hover {
    border-color: currentColor !important;
  }

  .lg\:hover\:border-black:hover {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-white:hover {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-100:hover {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-200:hover {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-300:hover {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-400:hover {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-500:hover {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-600:hover {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-700:hover {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-800:hover {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-gray-900:hover {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-300:hover {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-400:hover {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-500:hover {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-600:hover {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-700:hover {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-800:hover {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-red-900:hover {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-200:hover {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-600:hover {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-700:hover {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-800:hover {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-orange-900:hover {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-200:hover {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-300:hover {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-500:hover {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-600:hover {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-700:hover {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-800:hover {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-900:hover {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-100:hover {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-200:hover {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-300:hover {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-400:hover {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-500:hover {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-600:hover {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-700:hover {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-800:hover {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-green-900:hover {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-100:hover {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-200:hover {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-300:hover {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-400:hover {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-500:hover {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-600:hover {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-700:hover {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-800:hover {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-teal-900:hover {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-200:hover {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-300:hover {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-400:hover {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-500:hover {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-600:hover {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-700:hover {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-800:hover {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-blue-900:hover {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-200:hover {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-300:hover {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-400:hover {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-500:hover {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-600:hover {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-700:hover {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-800:hover {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-900:hover {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-100:hover {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-200:hover {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-300:hover {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-400:hover {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-500:hover {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-600:hover {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-700:hover {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-800:hover {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-purple-900:hover {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-400:hover {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-600:hover {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-700:hover {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-800:hover {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-pink-900:hover {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-primary:hover {
    border-color: var(--primary) !important;
  }

  .lg\:hover\:border-accent-1:hover {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .lg\:hover\:border-accent-2:hover {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-transparent:focus {
    border-color: transparent !important;
  }

  .lg\:focus\:border-current:focus {
    border-color: currentColor !important;
  }

  .lg\:focus\:border-black:focus {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-white:focus {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-100:focus {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-200:focus {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-300:focus {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-400:focus {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-500:focus {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-600:focus {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-700:focus {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-800:focus {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-gray-900:focus {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-300:focus {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-400:focus {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-500:focus {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-600:focus {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-700:focus {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-800:focus {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-red-900:focus {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-200:focus {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-600:focus {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-700:focus {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-800:focus {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-orange-900:focus {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-200:focus {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-300:focus {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-500:focus {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-600:focus {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-700:focus {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-800:focus {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-900:focus {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-100:focus {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-200:focus {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-300:focus {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-400:focus {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-500:focus {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-600:focus {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-700:focus {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-800:focus {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-green-900:focus {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-100:focus {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-200:focus {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-300:focus {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-400:focus {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-500:focus {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-600:focus {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-700:focus {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-800:focus {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-teal-900:focus {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-200:focus {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-300:focus {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-400:focus {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-500:focus {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-600:focus {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-700:focus {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-800:focus {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-blue-900:focus {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-200:focus {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-300:focus {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-400:focus {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-500:focus {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-600:focus {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-700:focus {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-800:focus {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-900:focus {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-100:focus {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-200:focus {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-300:focus {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-400:focus {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-500:focus {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-600:focus {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-700:focus {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-800:focus {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-purple-900:focus {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-400:focus {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-600:focus {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-700:focus {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-800:focus {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-pink-900:focus {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-primary:focus {
    border-color: var(--primary) !important;
  }

  .lg\:focus\:border-accent-1:focus {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .lg\:focus\:border-accent-2:focus {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .lg\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .lg\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .lg\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .lg\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .lg\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .lg\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .lg\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .lg\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .lg\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .lg\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .lg\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .lg\:rounded-none {
    border-radius: 0 !important;
  }

  .lg\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .lg\:rounded {
    border-radius: 0.25rem !important;
  }

  .lg\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .lg\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .lg\:rounded-xl {
    border-radius: 0.75rem !important;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem !important;
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem !important;
  }

  .lg\:rounded-full {
    border-radius: 9999px !important;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .lg\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: 0.75rem !important;
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: 0.75rem !important;
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem !important;
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:border-solid {
    border-style: solid !important;
  }

  .lg\:border-dashed {
    border-style: dashed !important;
  }

  .lg\:border-dotted {
    border-style: dotted !important;
  }

  .lg\:border-double {
    border-style: double !important;
  }

  .lg\:border-none {
    border-style: none !important;
  }

  .lg\:border-0 {
    border-width: 0 !important;
  }

  .lg\:border-2 {
    border-width: 2px !important;
  }

  .lg\:border-4 {
    border-width: 4px !important;
  }

  .lg\:border-8 {
    border-width: 8px !important;
  }

  .lg\:border {
    border-width: 1px !important;
  }

  .lg\:border-t-0 {
    border-top-width: 0 !important;
  }

  .lg\:border-r-0 {
    border-right-width: 0 !important;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .lg\:border-l-0 {
    border-left-width: 0 !important;
  }

  .lg\:border-t-2 {
    border-top-width: 2px !important;
  }

  .lg\:border-r-2 {
    border-right-width: 2px !important;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .lg\:border-l-2 {
    border-left-width: 2px !important;
  }

  .lg\:border-t-4 {
    border-top-width: 4px !important;
  }

  .lg\:border-r-4 {
    border-right-width: 4px !important;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .lg\:border-l-4 {
    border-left-width: 4px !important;
  }

  .lg\:border-t-8 {
    border-top-width: 8px !important;
  }

  .lg\:border-r-8 {
    border-right-width: 8px !important;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .lg\:border-l-8 {
    border-left-width: 8px !important;
  }

  .lg\:border-t {
    border-top-width: 1px !important;
  }

  .lg\:border-r {
    border-right-width: 1px !important;
  }

  .lg\:border-b {
    border-bottom-width: 1px !important;
  }

  .lg\:border-l {
    border-left-width: 1px !important;
  }

  .lg\:box-border {
    box-sizing: border-box !important;
  }

  .lg\:box-content {
    box-sizing: content-box !important;
  }

  .lg\:cursor-auto {
    cursor: auto !important;
  }

  .lg\:cursor-default {
    cursor: default !important;
  }

  .lg\:cursor-pointer {
    cursor: pointer !important;
  }

  .lg\:cursor-wait {
    cursor: wait !important;
  }

  .lg\:cursor-text {
    cursor: text !important;
  }

  .lg\:cursor-move {
    cursor: move !important;
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:inline-block {
    display: inline-block !important;
  }

  .lg\:inline {
    display: inline !important;
  }

  .lg\:flex {
    display: flex !important;
  }

  .lg\:inline-flex {
    display: inline-flex !important;
  }

  .lg\:table {
    display: table !important;
  }

  .lg\:table-caption {
    display: table-caption !important;
  }

  .lg\:table-cell {
    display: table-cell !important;
  }

  .lg\:table-column {
    display: table-column !important;
  }

  .lg\:table-column-group {
    display: table-column-group !important;
  }

  .lg\:table-footer-group {
    display: table-footer-group !important;
  }

  .lg\:table-header-group {
    display: table-header-group !important;
  }

  .lg\:table-row-group {
    display: table-row-group !important;
  }

  .lg\:table-row {
    display: table-row !important;
  }

  .lg\:flow-root {
    display: flow-root !important;
  }

  .lg\:grid {
    display: grid !important;
  }

  .lg\:inline-grid {
    display: inline-grid !important;
  }

  .lg\:contents {
    display: contents !important;
  }

  .lg\:hidden {
    display: none !important;
  }

  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lg\:flex-col {
    flex-direction: column !important;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap !important;
  }

  .lg\:place-items-auto {
    place-items: auto !important;
  }

  .lg\:place-items-start {
    place-items: start !important;
  }

  .lg\:place-items-end {
    place-items: end !important;
  }

  .lg\:place-items-center {
    place-items: center !important;
  }

  .lg\:place-items-stretch {
    place-items: stretch !important;
  }

  .lg\:place-content-center {
    place-content: center !important;
  }

  .lg\:place-content-start {
    place-content: start !important;
  }

  .lg\:place-content-end {
    place-content: end !important;
  }

  .lg\:place-content-between {
    place-content: space-between !important;
  }

  .lg\:place-content-around {
    place-content: space-around !important;
  }

  .lg\:place-content-evenly {
    place-content: space-evenly !important;
  }

  .lg\:place-content-stretch {
    place-content: stretch !important;
  }

  .lg\:place-self-auto {
    place-self: auto !important;
  }

  .lg\:place-self-start {
    place-self: start !important;
  }

  .lg\:place-self-end {
    place-self: end !important;
  }

  .lg\:place-self-center {
    place-self: center !important;
  }

  .lg\:place-self-stretch {
    place-self: stretch !important;
  }

  .lg\:items-start {
    align-items: flex-start !important;
  }

  .lg\:items-end {
    align-items: flex-end !important;
  }

  .lg\:items-center {
    align-items: center !important;
  }

  .lg\:items-baseline {
    align-items: baseline !important;
  }

  .lg\:items-stretch {
    align-items: stretch !important;
  }

  .lg\:content-center {
    align-content: center !important;
  }

  .lg\:content-start {
    align-content: flex-start !important;
  }

  .lg\:content-end {
    align-content: flex-end !important;
  }

  .lg\:content-between {
    align-content: space-between !important;
  }

  .lg\:content-around {
    align-content: space-around !important;
  }

  .lg\:content-evenly {
    align-content: space-evenly !important;
  }

  .lg\:self-auto {
    align-self: auto !important;
  }

  .lg\:self-start {
    align-self: flex-start !important;
  }

  .lg\:self-end {
    align-self: flex-end !important;
  }

  .lg\:self-center {
    align-self: center !important;
  }

  .lg\:self-stretch {
    align-self: stretch !important;
  }

  .lg\:justify-items-auto {
    justify-items: auto !important;
  }

  .lg\:justify-items-start {
    justify-items: start !important;
  }

  .lg\:justify-items-end {
    justify-items: end !important;
  }

  .lg\:justify-items-center {
    justify-items: center !important;
  }

  .lg\:justify-items-stretch {
    justify-items: stretch !important;
  }

  .lg\:justify-start {
    justify-content: flex-start !important;
  }

  .lg\:justify-end {
    justify-content: flex-end !important;
  }

  .lg\:justify-center {
    justify-content: center !important;
  }

  .lg\:justify-between {
    justify-content: space-between !important;
  }

  .lg\:justify-around {
    justify-content: space-around !important;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .lg\:justify-self-auto {
    justify-self: auto !important;
  }

  .lg\:justify-self-start {
    justify-self: start !important;
  }

  .lg\:justify-self-end {
    justify-self: end !important;
  }

  .lg\:justify-self-center {
    justify-self: center !important;
  }

  .lg\:justify-self-stretch {
    justify-self: stretch !important;
  }

  .lg\:flex-1 {
    flex: 1 1 0% !important;
  }

  .lg\:flex-auto {
    flex: 1 1 auto !important;
  }

  .lg\:flex-initial {
    flex: 0 1 auto !important;
  }

  .lg\:flex-none {
    flex: none !important;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lg\:flex-grow {
    flex-grow: 1 !important;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lg\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .lg\:order-1 {
    order: 1 !important;
  }

  .lg\:order-2 {
    order: 2 !important;
  }

  .lg\:order-3 {
    order: 3 !important;
  }

  .lg\:order-4 {
    order: 4 !important;
  }

  .lg\:order-5 {
    order: 5 !important;
  }

  .lg\:order-6 {
    order: 6 !important;
  }

  .lg\:order-7 {
    order: 7 !important;
  }

  .lg\:order-8 {
    order: 8 !important;
  }

  .lg\:order-9 {
    order: 9 !important;
  }

  .lg\:order-10 {
    order: 10 !important;
  }

  .lg\:order-11 {
    order: 11 !important;
  }

  .lg\:order-12 {
    order: 12 !important;
  }

  .lg\:order-first {
    order: -9999 !important;
  }

  .lg\:order-last {
    order: 9999 !important;
  }

  .lg\:order-none {
    order: 0 !important;
  }

  .lg\:float-right {
    float: right !important;
  }

  .lg\:float-left {
    float: left !important;
  }

  .lg\:float-none {
    float: none !important;
  }

  .lg\:clearfix:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }

  .lg\:clear-left {
    clear: left !important;
  }

  .lg\:clear-right {
    clear: right !important;
  }

  .lg\:clear-both {
    clear: both !important;
  }

  .lg\:clear-none {
    clear: none !important;
  }

  .lg\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .lg\:font-hairline {
    font-weight: 100 !important;
  }

  .lg\:font-thin {
    font-weight: 200 !important;
  }

  .lg\:font-light {
    font-weight: 300 !important;
  }

  .lg\:font-normal {
    font-weight: 400 !important;
  }

  .lg\:font-medium {
    font-weight: 500 !important;
  }

  .lg\:font-semibold {
    font-weight: 600 !important;
  }

  .lg\:font-bold {
    font-weight: 700 !important;
  }

  .lg\:font-extrabold {
    font-weight: 800 !important;
  }

  .lg\:font-black {
    font-weight: 900 !important;
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100 !important;
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200 !important;
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500 !important;
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600 !important;
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800 !important;
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900 !important;
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100 !important;
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200 !important;
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500 !important;
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600 !important;
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800 !important;
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900 !important;
  }

  .lg\:h-0 {
    height: 0 !important;
  }

  .lg\:h-1 {
    height: 0.25rem !important;
  }

  .lg\:h-2 {
    height: 0.5rem !important;
  }

  .lg\:h-3 {
    height: 0.75rem !important;
  }

  .lg\:h-4 {
    height: 1rem !important;
  }

  .lg\:h-5 {
    height: 1.25rem !important;
  }

  .lg\:h-6 {
    height: 1.5rem !important;
  }

  .lg\:h-8 {
    height: 2rem !important;
  }

  .lg\:h-10 {
    height: 2.5rem !important;
  }

  .lg\:h-12 {
    height: 3rem !important;
  }

  .lg\:h-16 {
    height: 4rem !important;
  }

  .lg\:h-20 {
    height: 5rem !important;
  }

  .lg\:h-24 {
    height: 6rem !important;
  }

  .lg\:h-32 {
    height: 8rem !important;
  }

  .lg\:h-40 {
    height: 10rem !important;
  }

  .lg\:h-48 {
    height: 12rem !important;
  }

  .lg\:h-56 {
    height: 14rem !important;
  }

  .lg\:h-64 {
    height: 16rem !important;
  }

  .lg\:h-auto {
    height: auto !important;
  }

  .lg\:h-px {
    height: 1px !important;
  }

  .lg\:h-full {
    height: 100% !important;
  }

  .lg\:h-screen {
    height: 100vh !important;
  }

  .lg\:text-xs {
    font-size: 0.80em !important;
  }

  .lg\:text-sm {
    font-size: 0.975em !important;
  }

  .lg\:text-base {
    font-size: 1em !important;
  }

  .lg\:text-lg {
    font-size: 1.125em !important;
  }

  .lg\:text-xl {
    font-size: 1.25em !important;
  }

  .lg\:text-2xl {
    font-size: 1.5em !important;
  }

  .lg\:text-3xl {
    font-size: 1.875em !important;
  }

  .lg\:text-4xl {
    font-size: 2.25em !important;
  }

  .lg\:text-5xl {
    font-size: 3em !important;
  }

  .lg\:text-6xl {
    font-size: 4em !important;
  }

  .lg\:text-md {
    font-size: 1em !important;
  }

  .lg\:text-sm-title {
    font-size:  18px !important;
  }

  .lg\:text-md-title {
    font-size: 22px !important;
  }

  .lg\:text-lg-title {
    font-size: 36px !important;
  }

  .lg\:text-size-body {
    font-size: 12px !important;
  }

  .lg\:text-size-label {
    font-size: 10px !important;
  }

  .lg\:leading-3 {
    line-height: .75rem !important;
  }

  .lg\:leading-4 {
    line-height: 1rem !important;
  }

  .lg\:leading-5 {
    line-height: 1.25rem !important;
  }

  .lg\:leading-6 {
    line-height: 1.5rem !important;
  }

  .lg\:leading-7 {
    line-height: 1.75rem !important;
  }

  .lg\:leading-8 {
    line-height: 2rem !important;
  }

  .lg\:leading-9 {
    line-height: 2.25rem !important;
  }

  .lg\:leading-10 {
    line-height: 2.5rem !important;
  }

  .lg\:leading-none {
    line-height: 1 !important;
  }

  .lg\:leading-tight {
    line-height: 1.25 !important;
  }

  .lg\:leading-snug {
    line-height: 1.375 !important;
  }

  .lg\:leading-normal {
    line-height: 1.5 !important;
  }

  .lg\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .lg\:leading-loose {
    line-height: 2 !important;
  }

  .lg\:list-inside {
    list-style-position: inside !important;
  }

  .lg\:list-outside {
    list-style-position: outside !important;
  }

  .lg\:list-none {
    list-style-type: none !important;
  }

  .lg\:list-disc {
    list-style-type: disc !important;
  }

  .lg\:list-decimal {
    list-style-type: decimal !important;
  }

  .lg\:m-0 {
    margin: 0 !important;
  }

  .lg\:m-1 {
    margin: 0.25rem !important;
  }

  .lg\:m-2 {
    margin: 0.5rem !important;
  }

  .lg\:m-3 {
    margin: 0.75rem !important;
  }

  .lg\:m-4 {
    margin: 1rem !important;
  }

  .lg\:m-5 {
    margin: 1.25rem !important;
  }

  .lg\:m-6 {
    margin: 1.5rem !important;
  }

  .lg\:m-8 {
    margin: 2rem !important;
  }

  .lg\:m-10 {
    margin: 2.5rem !important;
  }

  .lg\:m-12 {
    margin: 3rem !important;
  }

  .lg\:m-16 {
    margin: 4rem !important;
  }

  .lg\:m-20 {
    margin: 5rem !important;
  }

  .lg\:m-24 {
    margin: 6rem !important;
  }

  .lg\:m-32 {
    margin: 8rem !important;
  }

  .lg\:m-40 {
    margin: 10rem !important;
  }

  .lg\:m-48 {
    margin: 12rem !important;
  }

  .lg\:m-56 {
    margin: 14rem !important;
  }

  .lg\:m-64 {
    margin: 16rem !important;
  }

  .lg\:m-auto {
    margin: auto !important;
  }

  .lg\:m-px {
    margin: 1px !important;
  }

  .lg\:-m-1 {
    margin: -0.25rem !important;
  }

  .lg\:-m-2 {
    margin: -0.5rem !important;
  }

  .lg\:-m-3 {
    margin: -0.75rem !important;
  }

  .lg\:-m-4 {
    margin: -1rem !important;
  }

  .lg\:-m-5 {
    margin: -1.25rem !important;
  }

  .lg\:-m-6 {
    margin: -1.5rem !important;
  }

  .lg\:-m-8 {
    margin: -2rem !important;
  }

  .lg\:-m-10 {
    margin: -2.5rem !important;
  }

  .lg\:-m-12 {
    margin: -3rem !important;
  }

  .lg\:-m-16 {
    margin: -4rem !important;
  }

  .lg\:-m-20 {
    margin: -5rem !important;
  }

  .lg\:-m-24 {
    margin: -6rem !important;
  }

  .lg\:-m-32 {
    margin: -8rem !important;
  }

  .lg\:-m-40 {
    margin: -10rem !important;
  }

  .lg\:-m-48 {
    margin: -12rem !important;
  }

  .lg\:-m-56 {
    margin: -14rem !important;
  }

  .lg\:-m-64 {
    margin: -16rem !important;
  }

  .lg\:-m-px {
    margin: -1px !important;
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lg\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lg\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lg\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lg\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lg\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lg\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lg\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lg\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lg\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lg\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lg\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lg\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lg\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lg\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lg\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lg\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lg\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lg\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lg\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lg\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lg\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lg\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lg\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lg\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lg\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lg\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lg\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lg\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lg\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lg\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lg\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lg\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lg\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lg\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lg\:mr-0 {
    margin-right: 0 !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lg\:ml-0 {
    margin-left: 0 !important;
  }

  .lg\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .lg\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .lg\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .lg\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .lg\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .lg\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .lg\:mt-4 {
    margin-top: 1rem !important;
  }

  .lg\:mr-4 {
    margin-right: 1rem !important;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .lg\:ml-4 {
    margin-left: 1rem !important;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .lg\:mt-8 {
    margin-top: 2rem !important;
  }

  .lg\:mr-8 {
    margin-right: 2rem !important;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .lg\:ml-8 {
    margin-left: 2rem !important;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .lg\:mt-12 {
    margin-top: 3rem !important;
  }

  .lg\:mr-12 {
    margin-right: 3rem !important;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .lg\:ml-12 {
    margin-left: 3rem !important;
  }

  .lg\:mt-16 {
    margin-top: 4rem !important;
  }

  .lg\:mr-16 {
    margin-right: 4rem !important;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .lg\:ml-16 {
    margin-left: 4rem !important;
  }

  .lg\:mt-20 {
    margin-top: 5rem !important;
  }

  .lg\:mr-20 {
    margin-right: 5rem !important;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .lg\:ml-20 {
    margin-left: 5rem !important;
  }

  .lg\:mt-24 {
    margin-top: 6rem !important;
  }

  .lg\:mr-24 {
    margin-right: 6rem !important;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .lg\:ml-24 {
    margin-left: 6rem !important;
  }

  .lg\:mt-32 {
    margin-top: 8rem !important;
  }

  .lg\:mr-32 {
    margin-right: 8rem !important;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .lg\:ml-32 {
    margin-left: 8rem !important;
  }

  .lg\:mt-40 {
    margin-top: 10rem !important;
  }

  .lg\:mr-40 {
    margin-right: 10rem !important;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .lg\:ml-40 {
    margin-left: 10rem !important;
  }

  .lg\:mt-48 {
    margin-top: 12rem !important;
  }

  .lg\:mr-48 {
    margin-right: 12rem !important;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .lg\:ml-48 {
    margin-left: 12rem !important;
  }

  .lg\:mt-56 {
    margin-top: 14rem !important;
  }

  .lg\:mr-56 {
    margin-right: 14rem !important;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .lg\:ml-56 {
    margin-left: 14rem !important;
  }

  .lg\:mt-64 {
    margin-top: 16rem !important;
  }

  .lg\:mr-64 {
    margin-right: 16rem !important;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .lg\:ml-64 {
    margin-left: 16rem !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:mr-auto {
    margin-right: auto !important;
  }

  .lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }

  .lg\:mt-px {
    margin-top: 1px !important;
  }

  .lg\:mr-px {
    margin-right: 1px !important;
  }

  .lg\:mb-px {
    margin-bottom: 1px !important;
  }

  .lg\:ml-px {
    margin-left: 1px !important;
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .lg\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .lg\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .lg\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .lg\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .lg\:-mt-4 {
    margin-top: -1rem !important;
  }

  .lg\:-mr-4 {
    margin-right: -1rem !important;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .lg\:-ml-4 {
    margin-left: -1rem !important;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .lg\:-mt-8 {
    margin-top: -2rem !important;
  }

  .lg\:-mr-8 {
    margin-right: -2rem !important;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .lg\:-ml-8 {
    margin-left: -2rem !important;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .lg\:-mt-12 {
    margin-top: -3rem !important;
  }

  .lg\:-mr-12 {
    margin-right: -3rem !important;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .lg\:-ml-12 {
    margin-left: -3rem !important;
  }

  .lg\:-mt-16 {
    margin-top: -4rem !important;
  }

  .lg\:-mr-16 {
    margin-right: -4rem !important;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .lg\:-ml-16 {
    margin-left: -4rem !important;
  }

  .lg\:-mt-20 {
    margin-top: -5rem !important;
  }

  .lg\:-mr-20 {
    margin-right: -5rem !important;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .lg\:-ml-20 {
    margin-left: -5rem !important;
  }

  .lg\:-mt-24 {
    margin-top: -6rem !important;
  }

  .lg\:-mr-24 {
    margin-right: -6rem !important;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .lg\:-ml-24 {
    margin-left: -6rem !important;
  }

  .lg\:-mt-32 {
    margin-top: -8rem !important;
  }

  .lg\:-mr-32 {
    margin-right: -8rem !important;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .lg\:-ml-32 {
    margin-left: -8rem !important;
  }

  .lg\:-mt-40 {
    margin-top: -10rem !important;
  }

  .lg\:-mr-40 {
    margin-right: -10rem !important;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .lg\:-ml-40 {
    margin-left: -10rem !important;
  }

  .lg\:-mt-48 {
    margin-top: -12rem !important;
  }

  .lg\:-mr-48 {
    margin-right: -12rem !important;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .lg\:-ml-48 {
    margin-left: -12rem !important;
  }

  .lg\:-mt-56 {
    margin-top: -14rem !important;
  }

  .lg\:-mr-56 {
    margin-right: -14rem !important;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .lg\:-ml-56 {
    margin-left: -14rem !important;
  }

  .lg\:-mt-64 {
    margin-top: -16rem !important;
  }

  .lg\:-mr-64 {
    margin-right: -16rem !important;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .lg\:-ml-64 {
    margin-left: -16rem !important;
  }

  .lg\:-mt-px {
    margin-top: -1px !important;
  }

  .lg\:-mr-px {
    margin-right: -1px !important;
  }

  .lg\:-mb-px {
    margin-bottom: -1px !important;
  }

  .lg\:-ml-px {
    margin-left: -1px !important;
  }

  .lg\:max-h-full {
    max-height: 100% !important;
  }

  .lg\:max-h-screen {
    max-height: 100vh !important;
  }

  .lg\:max-w-0 {
    max-width: 0 !important;
  }

  .lg\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .lg\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .lg\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .lg\:max-w-4 {
    max-width: 1rem !important;
  }

  .lg\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .lg\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .lg\:max-w-8 {
    max-width: 2rem !important;
  }

  .lg\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .lg\:max-w-12 {
    max-width: 3rem !important;
  }

  .lg\:max-w-16 {
    max-width: 4rem !important;
  }

  .lg\:max-w-20 {
    max-width: 5rem !important;
  }

  .lg\:max-w-24 {
    max-width: 6rem !important;
  }

  .lg\:max-w-32 {
    max-width: 8rem !important;
  }

  .lg\:max-w-40 {
    max-width: 10rem !important;
  }

  .lg\:max-w-48 {
    max-width: 12rem !important;
  }

  .lg\:max-w-56 {
    max-width: 14rem !important;
  }

  .lg\:max-w-64 {
    max-width: 16rem !important;
  }

  .lg\:max-w-none {
    max-width: none !important;
  }

  .lg\:max-w-xs {
    max-width: 20rem !important;
  }

  .lg\:max-w-sm {
    max-width: 24rem !important;
  }

  .lg\:max-w-md {
    max-width: 28rem !important;
  }

  .lg\:max-w-lg {
    max-width: 32rem !important;
  }

  .lg\:max-w-xl {
    max-width: 36rem !important;
  }

  .lg\:max-w-2xl {
    max-width: 42rem !important;
  }

  .lg\:max-w-3xl {
    max-width: 48rem !important;
  }

  .lg\:max-w-4xl {
    max-width: 56rem !important;
  }

  .lg\:max-w-5xl {
    max-width: 64rem !important;
  }

  .lg\:max-w-6xl {
    max-width: 72rem !important;
  }

  .lg\:max-w-full {
    max-width: 100% !important;
  }

  .lg\:max-w-screen-xs {
    max-width: 480px !important;
  }

  .lg\:max-w-screen-sm {
    max-width: 576px !important;
  }

  .lg\:max-w-screen-md {
    max-width: 768px !important;
  }

  .lg\:max-w-screen-lg {
    max-width: 992px !important;
  }

  .lg\:max-w-screen-xl {
    max-width: 1200px !important;
  }

  .lg\:max-w-screen-xxl {
    max-width: 1600px !important;
  }

  .lg\:max-w-screen {
    max-width: 100vw !important;
  }

  .lg\:max-w-px {
    max-width: 1px !important;
  }

  .lg\:min-h-0 {
    min-height: 0 !important;
  }

  .lg\:min-h-full {
    min-height: 100% !important;
  }

  .lg\:min-h-screen {
    min-height: 100vh !important;
  }

  .lg\:min-w-0 {
    min-width: 0 !important;
  }

  .lg\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .lg\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .lg\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .lg\:min-w-4 {
    min-width: 1rem !important;
  }

  .lg\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .lg\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .lg\:min-w-8 {
    min-width: 2rem !important;
  }

  .lg\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .lg\:min-w-12 {
    min-width: 3rem !important;
  }

  .lg\:min-w-16 {
    min-width: 4rem !important;
  }

  .lg\:min-w-20 {
    min-width: 5rem !important;
  }

  .lg\:min-w-24 {
    min-width: 6rem !important;
  }

  .lg\:min-w-32 {
    min-width: 8rem !important;
  }

  .lg\:min-w-40 {
    min-width: 10rem !important;
  }

  .lg\:min-w-48 {
    min-width: 12rem !important;
  }

  .lg\:min-w-56 {
    min-width: 14rem !important;
  }

  .lg\:min-w-64 {
    min-width: 16rem !important;
  }

  .lg\:min-w-full {
    min-width: 100% !important;
  }

  .lg\:min-w-screen {
    min-width: 100vw !important;
  }

  .lg\:min-w-px {
    min-width: 1px !important;
  }

  .lg\:min-w-screen-xs {
    min-width: 480px !important;
  }

  .lg\:min-w-screen-sm {
    min-width: 576px !important;
  }

  .lg\:min-w-screen-md {
    min-width: 768px !important;
  }

  .lg\:min-w-screen-lg {
    min-width: 992px !important;
  }

  .lg\:min-w-screen-xl {
    min-width: 1200px !important;
  }

  .lg\:min-w-screen-xxl {
    min-width: 1600px !important;
  }

  .lg\:object-contain {
    object-fit: contain !important;
  }

  .lg\:object-cover {
    object-fit: cover !important;
  }

  .lg\:object-fill {
    object-fit: fill !important;
  }

  .lg\:object-none {
    object-fit: none !important;
  }

  .lg\:object-scale-down {
    object-fit: scale-down !important;
  }

  .lg\:object-bottom {
    object-position: bottom !important;
  }

  .lg\:object-center {
    object-position: center !important;
  }

  .lg\:object-left {
    object-position: left !important;
  }

  .lg\:object-left-bottom {
    object-position: left bottom !important;
  }

  .lg\:object-left-top {
    object-position: left top !important;
  }

  .lg\:object-right {
    object-position: right !important;
  }

  .lg\:object-right-bottom {
    object-position: right bottom !important;
  }

  .lg\:object-right-top {
    object-position: right top !important;
  }

  .lg\:object-top {
    object-position: top !important;
  }

  .lg\:opacity-0 {
    opacity: 0 !important;
  }

  .lg\:opacity-25 {
    opacity: 0.25 !important;
  }

  .lg\:opacity-50 {
    opacity: 0.5 !important;
  }

  .lg\:opacity-75 {
    opacity: 0.75 !important;
  }

  .lg\:opacity-100 {
    opacity: 1 !important;
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .lg\:overflow-auto {
    overflow: auto !important;
  }

  .lg\:overflow-hidden {
    overflow: hidden !important;
  }

  .lg\:overflow-visible {
    overflow: visible !important;
  }

  .lg\:overflow-scroll {
    overflow: scroll !important;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto !important;
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain !important;
  }

  .lg\:overscroll-none {
    overscroll-behavior: none !important;
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto !important;
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain !important;
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none !important;
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto !important;
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain !important;
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none !important;
  }

  .lg\:p-0 {
    padding: 0 !important;
  }

  .lg\:p-1 {
    padding: 0.25rem !important;
  }

  .lg\:p-2 {
    padding: 0.5rem !important;
  }

  .lg\:p-3 {
    padding: 0.75rem !important;
  }

  .lg\:p-4 {
    padding: 1rem !important;
  }

  .lg\:p-5 {
    padding: 1.25rem !important;
  }

  .lg\:p-6 {
    padding: 1.5rem !important;
  }

  .lg\:p-8 {
    padding: 2rem !important;
  }

  .lg\:p-10 {
    padding: 2.5rem !important;
  }

  .lg\:p-12 {
    padding: 3rem !important;
  }

  .lg\:p-16 {
    padding: 4rem !important;
  }

  .lg\:p-20 {
    padding: 5rem !important;
  }

  .lg\:p-24 {
    padding: 6rem !important;
  }

  .lg\:p-32 {
    padding: 8rem !important;
  }

  .lg\:p-40 {
    padding: 10rem !important;
  }

  .lg\:p-48 {
    padding: 12rem !important;
  }

  .lg\:p-56 {
    padding: 14rem !important;
  }

  .lg\:p-64 {
    padding: 16rem !important;
  }

  .lg\:p-px {
    padding: 1px !important;
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lg\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lg\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lg\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lg\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lg\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lg\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lg\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lg\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lg\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lg\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lg\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lg\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lg\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lg\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lg\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lg\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lg\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lg\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lg\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lg\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lg\:pt-0 {
    padding-top: 0 !important;
  }

  .lg\:pr-0 {
    padding-right: 0 !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:pl-0 {
    padding-left: 0 !important;
  }

  .lg\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lg\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lg\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .lg\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .lg\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .lg\:pt-4 {
    padding-top: 1rem !important;
  }

  .lg\:pr-4 {
    padding-right: 1rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .lg\:pl-4 {
    padding-left: 1rem !important;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .lg\:pt-8 {
    padding-top: 2rem !important;
  }

  .lg\:pr-8 {
    padding-right: 2rem !important;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .lg\:pl-8 {
    padding-left: 2rem !important;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .lg\:pt-12 {
    padding-top: 3rem !important;
  }

  .lg\:pr-12 {
    padding-right: 3rem !important;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .lg\:pl-12 {
    padding-left: 3rem !important;
  }

  .lg\:pt-16 {
    padding-top: 4rem !important;
  }

  .lg\:pr-16 {
    padding-right: 4rem !important;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .lg\:pl-16 {
    padding-left: 4rem !important;
  }

  .lg\:pt-20 {
    padding-top: 5rem !important;
  }

  .lg\:pr-20 {
    padding-right: 5rem !important;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .lg\:pl-20 {
    padding-left: 5rem !important;
  }

  .lg\:pt-24 {
    padding-top: 6rem !important;
  }

  .lg\:pr-24 {
    padding-right: 6rem !important;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .lg\:pl-24 {
    padding-left: 6rem !important;
  }

  .lg\:pt-32 {
    padding-top: 8rem !important;
  }

  .lg\:pr-32 {
    padding-right: 8rem !important;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .lg\:pl-32 {
    padding-left: 8rem !important;
  }

  .lg\:pt-40 {
    padding-top: 10rem !important;
  }

  .lg\:pr-40 {
    padding-right: 10rem !important;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .lg\:pl-40 {
    padding-left: 10rem !important;
  }

  .lg\:pt-48 {
    padding-top: 12rem !important;
  }

  .lg\:pr-48 {
    padding-right: 12rem !important;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .lg\:pl-48 {
    padding-left: 12rem !important;
  }

  .lg\:pt-56 {
    padding-top: 14rem !important;
  }

  .lg\:pr-56 {
    padding-right: 14rem !important;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .lg\:pl-56 {
    padding-left: 14rem !important;
  }

  .lg\:pt-64 {
    padding-top: 16rem !important;
  }

  .lg\:pr-64 {
    padding-right: 16rem !important;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .lg\:pl-64 {
    padding-left: 16rem !important;
  }

  .lg\:pt-px {
    padding-top: 1px !important;
  }

  .lg\:pr-px {
    padding-right: 1px !important;
  }

  .lg\:pb-px {
    padding-bottom: 1px !important;
  }

  .lg\:pl-px {
    padding-left: 1px !important;
  }

  .lg\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent !important;
  }

  .lg\:placeholder-transparent::-ms-input-placeholder {
    color: transparent !important;
  }

  .lg\:placeholder-transparent::placeholder {
    color: transparent !important;
  }

  .lg\:placeholder-current::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .lg\:placeholder-current::-ms-input-placeholder {
    color: currentColor !important;
  }

  .lg\:placeholder-current::placeholder {
    color: currentColor !important;
  }

  .lg\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-black::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-white::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .lg\:placeholder-primary::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .lg\:placeholder-primary::placeholder {
    color: var(--primary) !important;
  }

  .lg\:placeholder-accent-1::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-accent-1::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-accent-1::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-accent-2::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-accent-2::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-accent-2::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  .lg\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent !important;
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent !important;
  }

  .lg\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .lg\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor !important;
  }

  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor !important;
  }

  .lg\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .lg\:focus\:placeholder-primary:focus::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .lg\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary) !important;
  }

  .lg\:focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-accent-1:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-accent-1:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-accent-2:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-accent-2:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .lg\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:pointer-events-none {
    pointer-events: none !important;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .lg\:static {
    position: static !important;
  }

  .lg\:fixed {
    position: fixed !important;
  }

  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:relative {
    position: relative !important;
  }

  .lg\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .lg\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .lg\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lg\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .lg\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .lg\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .lg\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .lg\:top-0 {
    top: 0 !important;
  }

  .lg\:right-0 {
    right: 0 !important;
  }

  .lg\:bottom-0 {
    bottom: 0 !important;
  }

  .lg\:left-0 {
    left: 0 !important;
  }

  .lg\:top-auto {
    top: auto !important;
  }

  .lg\:right-auto {
    right: auto !important;
  }

  .lg\:bottom-auto {
    bottom: auto !important;
  }

  .lg\:left-auto {
    left: auto !important;
  }

  .lg\:resize-none {
    resize: none !important;
  }

  .lg\:resize-y {
    resize: vertical !important;
  }

  .lg\:resize-x {
    resize: horizontal !important;
  }

  .lg\:resize {
    resize: both !important;
  }

  .lg\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lg\:shadow-none {
    box-shadow: none !important;
  }

  .lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .lg\:fill-current {
    fill: currentColor !important;
  }

  .lg\:stroke-current {
    stroke: currentColor !important;
  }

  .lg\:stroke-0 {
    stroke-width: 0 !important;
  }

  .lg\:stroke-1 {
    stroke-width: 1 !important;
  }

  .lg\:stroke-2 {
    stroke-width: 2 !important;
  }

  .lg\:table-auto {
    table-layout: auto !important;
  }

  .lg\:table-fixed {
    table-layout: fixed !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:text-right {
    text-align: right !important;
  }

  .lg\:text-justify {
    text-align: justify !important;
  }

  .lg\:text-transparent {
    color: transparent !important;
  }

  .lg\:text-current {
    color: currentColor !important;
  }

  .lg\:text-black {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .lg\:text-white {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .lg\:text-gray-100 {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .lg\:text-gray-200 {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .lg\:text-gray-300 {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .lg\:text-gray-400 {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .lg\:text-gray-500 {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .lg\:text-gray-600 {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .lg\:text-gray-700 {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .lg\:text-gray-800 {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .lg\:text-gray-900 {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .lg\:text-red-100 {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .lg\:text-red-200 {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .lg\:text-red-300 {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .lg\:text-red-400 {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .lg\:text-red-500 {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .lg\:text-red-600 {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .lg\:text-red-700 {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .lg\:text-red-800 {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .lg\:text-red-900 {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .lg\:text-orange-100 {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .lg\:text-orange-200 {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .lg\:text-orange-300 {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .lg\:text-orange-400 {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .lg\:text-orange-500 {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .lg\:text-orange-600 {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .lg\:text-orange-700 {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .lg\:text-orange-800 {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .lg\:text-orange-900 {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-100 {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-200 {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-300 {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-400 {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-500 {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-600 {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-700 {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-800 {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .lg\:text-yellow-900 {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .lg\:text-green-100 {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .lg\:text-green-200 {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .lg\:text-green-300 {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .lg\:text-green-400 {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .lg\:text-green-500 {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .lg\:text-green-600 {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .lg\:text-green-700 {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .lg\:text-green-800 {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .lg\:text-green-900 {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .lg\:text-teal-100 {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .lg\:text-teal-200 {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .lg\:text-teal-300 {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .lg\:text-teal-400 {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .lg\:text-teal-500 {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .lg\:text-teal-600 {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .lg\:text-teal-700 {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .lg\:text-teal-800 {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .lg\:text-teal-900 {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .lg\:text-blue-100 {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .lg\:text-blue-200 {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .lg\:text-blue-300 {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .lg\:text-blue-400 {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .lg\:text-blue-500 {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .lg\:text-blue-600 {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .lg\:text-blue-700 {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .lg\:text-blue-800 {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .lg\:text-blue-900 {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-100 {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-200 {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-300 {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-400 {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-500 {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-600 {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-700 {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-800 {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .lg\:text-indigo-900 {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .lg\:text-purple-100 {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .lg\:text-purple-200 {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .lg\:text-purple-300 {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .lg\:text-purple-400 {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .lg\:text-purple-500 {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .lg\:text-purple-600 {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .lg\:text-purple-700 {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .lg\:text-purple-800 {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .lg\:text-purple-900 {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .lg\:text-pink-100 {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .lg\:text-pink-200 {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .lg\:text-pink-300 {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .lg\:text-pink-400 {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .lg\:text-pink-500 {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .lg\:text-pink-600 {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .lg\:text-pink-700 {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .lg\:text-pink-800 {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .lg\:text-pink-900 {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .lg\:text-primary {
    color: var(--primary) !important;
  }

  .lg\:text-accent-1 {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .lg\:text-accent-2 {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-transparent:hover {
    color: transparent !important;
  }

  .lg\:hover\:text-current:hover {
    color: currentColor !important;
  }

  .lg\:hover\:text-black:hover {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-white:hover {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-100:hover {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-200:hover {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-300:hover {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-400:hover {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-500:hover {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-600:hover {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-700:hover {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-800:hover {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-gray-900:hover {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-200:hover {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-300:hover {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-400:hover {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-500:hover {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-600:hover {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-700:hover {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-800:hover {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-red-900:hover {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-100:hover {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-200:hover {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-300:hover {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-400:hover {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-500:hover {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-600:hover {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-700:hover {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-800:hover {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-orange-900:hover {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-100:hover {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-200:hover {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-300:hover {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-400:hover {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-500:hover {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-600:hover {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-700:hover {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-800:hover {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-900:hover {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-100:hover {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-200:hover {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-300:hover {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-400:hover {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-500:hover {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-600:hover {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-700:hover {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-800:hover {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-green-900:hover {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-100:hover {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-200:hover {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-300:hover {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-400:hover {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-500:hover {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-600:hover {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-700:hover {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-800:hover {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-teal-900:hover {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-100:hover {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-200:hover {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-300:hover {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-400:hover {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-500:hover {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-600:hover {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-700:hover {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-800:hover {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-blue-900:hover {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-100:hover {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-200:hover {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-300:hover {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-400:hover {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-500:hover {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-600:hover {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-700:hover {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-800:hover {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-900:hover {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-100:hover {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-200:hover {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-300:hover {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-400:hover {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-500:hover {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-600:hover {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-700:hover {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-800:hover {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-purple-900:hover {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-200:hover {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-300:hover {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-400:hover {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-500:hover {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-600:hover {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-700:hover {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-800:hover {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-pink-900:hover {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-primary:hover {
    color: var(--primary) !important;
  }

  .lg\:hover\:text-accent-1:hover {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .lg\:hover\:text-accent-2:hover {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-transparent:focus {
    color: transparent !important;
  }

  .lg\:focus\:text-current:focus {
    color: currentColor !important;
  }

  .lg\:focus\:text-black:focus {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-white:focus {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-100:focus {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-200:focus {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-300:focus {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-400:focus {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-500:focus {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-600:focus {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-700:focus {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-800:focus {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-gray-900:focus {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-200:focus {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-300:focus {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-400:focus {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-500:focus {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-600:focus {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-700:focus {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-800:focus {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-red-900:focus {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-100:focus {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-200:focus {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-300:focus {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-400:focus {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-500:focus {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-600:focus {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-700:focus {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-800:focus {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-orange-900:focus {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-100:focus {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-200:focus {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-300:focus {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-400:focus {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-500:focus {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-600:focus {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-700:focus {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-800:focus {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-900:focus {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-100:focus {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-200:focus {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-300:focus {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-400:focus {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-500:focus {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-600:focus {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-700:focus {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-800:focus {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-green-900:focus {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-100:focus {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-200:focus {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-300:focus {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-400:focus {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-500:focus {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-600:focus {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-700:focus {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-800:focus {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-teal-900:focus {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-100:focus {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-200:focus {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-300:focus {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-400:focus {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-500:focus {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-600:focus {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-700:focus {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-800:focus {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-blue-900:focus {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-100:focus {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-200:focus {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-300:focus {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-400:focus {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-500:focus {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-600:focus {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-700:focus {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-800:focus {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-900:focus {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-100:focus {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-200:focus {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-300:focus {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-400:focus {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-500:focus {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-600:focus {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-700:focus {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-800:focus {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-purple-900:focus {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-200:focus {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-300:focus {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-400:focus {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-500:focus {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-600:focus {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-700:focus {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-800:focus {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-pink-900:focus {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-primary:focus {
    color: var(--primary) !important;
  }

  .lg\:focus\:text-accent-1:focus {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .lg\:focus\:text-accent-2:focus {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .lg\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .lg\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .lg\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .lg\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .lg\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .lg\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .lg\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .lg\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .lg\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .lg\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .lg\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .lg\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .lg\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .lg\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .lg\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .lg\:italic {
    font-style: italic !important;
  }

  .lg\:not-italic {
    font-style: normal !important;
  }

  .lg\:uppercase {
    text-transform: uppercase !important;
  }

  .lg\:lowercase {
    text-transform: lowercase !important;
  }

  .lg\:capitalize {
    text-transform: capitalize !important;
  }

  .lg\:normal-case {
    text-transform: none !important;
  }

  .lg\:underline {
    text-decoration: underline !important;
  }

  .lg\:line-through {
    text-decoration: line-through !important;
  }

  .lg\:no-underline {
    text-decoration: none !important;
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
  }

  .lg\:normal-nums {
    font-variant-numeric: normal !important;
  }

  .lg\:ordinal {
    --font-variant-numeric-ordinal: ordinal !important;
  }

  .lg\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero !important;
  }

  .lg\:lining-nums {
    --font-variant-numeric-figure: lining-nums !important;
  }

  .lg\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums !important;
  }

  .lg\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums !important;
  }

  .lg\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums !important;
  }

  .lg\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions !important;
  }

  .lg\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions !important;
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .lg\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .lg\:select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .lg\:select-text {
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .lg\:select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .lg\:select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .lg\:align-baseline {
    vertical-align: baseline !important;
  }

  .lg\:align-top {
    vertical-align: top !important;
  }

  .lg\:align-middle {
    vertical-align: middle !important;
  }

  .lg\:align-bottom {
    vertical-align: bottom !important;
  }

  .lg\:align-text-top {
    vertical-align: text-top !important;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lg\:visible {
    visibility: visible !important;
  }

  .lg\:invisible {
    visibility: hidden !important;
  }

  .lg\:whitespace-normal {
    white-space: normal !important;
  }

  .lg\:whitespace-no-wrap {
    white-space: nowrap !important;
  }

  .lg\:whitespace-pre {
    white-space: pre !important;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .lg\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lg\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  .lg\:break-all {
    word-break: break-all !important;
  }

  .lg\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lg\:w-0 {
    width: 0 !important;
  }

  .lg\:w-1 {
    width: 0.25rem !important;
  }

  .lg\:w-2 {
    width: 0.5rem !important;
  }

  .lg\:w-3 {
    width: 0.75rem !important;
  }

  .lg\:w-4 {
    width: 1rem !important;
  }

  .lg\:w-5 {
    width: 1.25rem !important;
  }

  .lg\:w-6 {
    width: 1.5rem !important;
  }

  .lg\:w-8 {
    width: 2rem !important;
  }

  .lg\:w-10 {
    width: 2.5rem !important;
  }

  .lg\:w-12 {
    width: 3rem !important;
  }

  .lg\:w-16 {
    width: 4rem !important;
  }

  .lg\:w-20 {
    width: 5rem !important;
  }

  .lg\:w-24 {
    width: 6rem !important;
  }

  .lg\:w-32 {
    width: 8rem !important;
  }

  .lg\:w-40 {
    width: 10rem !important;
  }

  .lg\:w-48 {
    width: 12rem !important;
  }

  .lg\:w-56 {
    width: 14rem !important;
  }

  .lg\:w-64 {
    width: 16rem !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-px {
    width: 1px !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }

  .lg\:w-2\/3 {
    width: 66.666667% !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-2\/4 {
    width: 50% !important;
  }

  .lg\:w-3\/4 {
    width: 75% !important;
  }

  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-2\/5 {
    width: 40% !important;
  }

  .lg\:w-3\/5 {
    width: 60% !important;
  }

  .lg\:w-4\/5 {
    width: 80% !important;
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lg\:w-3\/6 {
    width: 50% !important;
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lg\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lg\:w-1\/12 {
    width: 8.333333% !important;
  }

  .lg\:w-2\/12 {
    width: 16.666667% !important;
  }

  .lg\:w-3\/12 {
    width: 25% !important;
  }

  .lg\:w-4\/12 {
    width: 33.333333% !important;
  }

  .lg\:w-5\/12 {
    width: 41.666667% !important;
  }

  .lg\:w-6\/12 {
    width: 50% !important;
  }

  .lg\:w-7\/12 {
    width: 58.333333% !important;
  }

  .lg\:w-8\/12 {
    width: 66.666667% !important;
  }

  .lg\:w-9\/12 {
    width: 75% !important;
  }

  .lg\:w-10\/12 {
    width: 83.333333% !important;
  }

  .lg\:w-11\/12 {
    width: 91.666667% !important;
  }

  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-screen {
    width: 100vw !important;
  }

  .lg\:w-none {
    width: none !important;
  }

  .lg\:w-xs {
    width: 20rem !important;
  }

  .lg\:w-sm {
    width: 24rem !important;
  }

  .lg\:w-md {
    width: 28rem !important;
  }

  .lg\:w-lg {
    width: 32rem !important;
  }

  .lg\:w-xl {
    width: 36rem !important;
  }

  .lg\:w-2xl {
    width: 42rem !important;
  }

  .lg\:w-3xl {
    width: 48rem !important;
  }

  .lg\:w-4xl {
    width: 56rem !important;
  }

  .lg\:w-5xl {
    width: 64rem !important;
  }

  .lg\:w-6xl {
    width: 72rem !important;
  }

  .lg\:w-screen-xs {
    width: 480px !important;
  }

  .lg\:w-screen-sm {
    width: 576px !important;
  }

  .lg\:w-screen-md {
    width: 768px !important;
  }

  .lg\:w-screen-lg {
    width: 992px !important;
  }

  .lg\:w-screen-xl {
    width: 1200px !important;
  }

  .lg\:w-screen-xxl {
    width: 1600px !important;
  }

  .lg\:w-sidebar {
    width: 15rem !important;
  }

  .lg\:z-0 {
    z-index: 0 !important;
  }

  .lg\:z-10 {
    z-index: 10 !important;
  }

  .lg\:z-20 {
    z-index: 20 !important;
  }

  .lg\:z-30 {
    z-index: 30 !important;
  }

  .lg\:z-40 {
    z-index: 40 !important;
  }

  .lg\:z-50 {
    z-index: 50 !important;
  }

  .lg\:z-auto {
    z-index: auto !important;
  }

  .lg\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .lg\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .lg\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .lg\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .lg\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .lg\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .lg\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .lg\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .lg\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .lg\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .lg\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .lg\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .lg\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .lg\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .lg\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .lg\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .lg\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .lg\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .lg\:gap-x-0 {
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }

  .lg\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .lg\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .lg\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .lg\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .lg\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .lg\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .lg\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .lg\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .lg\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .lg\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .lg\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .lg\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .lg\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .lg\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .lg\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .lg\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .lg\:gap-x-px {
    grid-column-gap: 1px !important;
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .lg\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .lg\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .lg\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .lg\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .lg\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .lg\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .lg\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .lg\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .lg\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .lg\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .lg\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .lg\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .lg\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .lg\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .lg\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .lg\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .lg\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto !important;
  }

  .lg\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .lg\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .lg\:col-auto {
    grid-column: auto !important;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1 !important;
  }

  .lg\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .lg\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .lg\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .lg\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .lg\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .lg\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .lg\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .lg\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .lg\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .lg\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .lg\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .lg\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .lg\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .lg\:col-start-auto {
    grid-column-start: auto !important;
  }

  .lg\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .lg\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .lg\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .lg\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .lg\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .lg\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .lg\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .lg\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .lg\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .lg\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .lg\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .lg\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .lg\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .lg\:col-end-auto {
    grid-column-end: auto !important;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto !important;
  }

  .lg\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .lg\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .lg\:row-auto {
    grid-row: auto !important;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .lg\:row-span-full {
    grid-row: 1 / -1 !important;
  }

  .lg\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .lg\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .lg\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .lg\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .lg\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .lg\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .lg\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .lg\:row-start-auto {
    grid-row-start: auto !important;
  }

  .lg\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .lg\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .lg\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .lg\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .lg\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .lg\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .lg\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .lg\:row-end-auto {
    grid-row-end: auto !important;
  }

  .lg\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .lg\:transform-none {
    transform: none !important;
  }

  .lg\:origin-center {
    transform-origin: center !important;
  }

  .lg\:origin-top {
    transform-origin: top !important;
  }

  .lg\:origin-top-right {
    transform-origin: top right !important;
  }

  .lg\:origin-right {
    transform-origin: right !important;
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .lg\:origin-left {
    transform-origin: left !important;
  }

  .lg\:origin-top-left {
    transform-origin: top left !important;
  }

  .lg\:scale-0 {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .lg\:scale-50 {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .lg\:scale-75 {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .lg\:scale-90 {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .lg\:scale-95 {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .lg\:scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .lg\:scale-105 {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .lg\:scale-110 {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .lg\:scale-125 {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .lg\:scale-150 {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .lg\:scale-x-0 {
    --transform-scale-x: 0 !important;
  }

  .lg\:scale-x-50 {
    --transform-scale-x: .5 !important;
  }

  .lg\:scale-x-75 {
    --transform-scale-x: .75 !important;
  }

  .lg\:scale-x-90 {
    --transform-scale-x: .9 !important;
  }

  .lg\:scale-x-95 {
    --transform-scale-x: .95 !important;
  }

  .lg\:scale-x-100 {
    --transform-scale-x: 1 !important;
  }

  .lg\:scale-x-105 {
    --transform-scale-x: 1.05 !important;
  }

  .lg\:scale-x-110 {
    --transform-scale-x: 1.1 !important;
  }

  .lg\:scale-x-125 {
    --transform-scale-x: 1.25 !important;
  }

  .lg\:scale-x-150 {
    --transform-scale-x: 1.5 !important;
  }

  .lg\:scale-y-0 {
    --transform-scale-y: 0 !important;
  }

  .lg\:scale-y-50 {
    --transform-scale-y: .5 !important;
  }

  .lg\:scale-y-75 {
    --transform-scale-y: .75 !important;
  }

  .lg\:scale-y-90 {
    --transform-scale-y: .9 !important;
  }

  .lg\:scale-y-95 {
    --transform-scale-y: .95 !important;
  }

  .lg\:scale-y-100 {
    --transform-scale-y: 1 !important;
  }

  .lg\:scale-y-105 {
    --transform-scale-y: 1.05 !important;
  }

  .lg\:scale-y-110 {
    --transform-scale-y: 1.1 !important;
  }

  .lg\:scale-y-125 {
    --transform-scale-y: 1.25 !important;
  }

  .lg\:scale-y-150 {
    --transform-scale-y: 1.5 !important;
  }

  .lg\:hover\:scale-0:hover {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .lg\:hover\:scale-50:hover {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .lg\:hover\:scale-75:hover {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .lg\:hover\:scale-90:hover {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .lg\:hover\:scale-95:hover {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .lg\:hover\:scale-100:hover {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .lg\:hover\:scale-105:hover {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .lg\:hover\:scale-110:hover {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .lg\:hover\:scale-125:hover {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .lg\:hover\:scale-150:hover {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .lg\:hover\:scale-x-0:hover {
    --transform-scale-x: 0 !important;
  }

  .lg\:hover\:scale-x-50:hover {
    --transform-scale-x: .5 !important;
  }

  .lg\:hover\:scale-x-75:hover {
    --transform-scale-x: .75 !important;
  }

  .lg\:hover\:scale-x-90:hover {
    --transform-scale-x: .9 !important;
  }

  .lg\:hover\:scale-x-95:hover {
    --transform-scale-x: .95 !important;
  }

  .lg\:hover\:scale-x-100:hover {
    --transform-scale-x: 1 !important;
  }

  .lg\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05 !important;
  }

  .lg\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1 !important;
  }

  .lg\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25 !important;
  }

  .lg\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5 !important;
  }

  .lg\:hover\:scale-y-0:hover {
    --transform-scale-y: 0 !important;
  }

  .lg\:hover\:scale-y-50:hover {
    --transform-scale-y: .5 !important;
  }

  .lg\:hover\:scale-y-75:hover {
    --transform-scale-y: .75 !important;
  }

  .lg\:hover\:scale-y-90:hover {
    --transform-scale-y: .9 !important;
  }

  .lg\:hover\:scale-y-95:hover {
    --transform-scale-y: .95 !important;
  }

  .lg\:hover\:scale-y-100:hover {
    --transform-scale-y: 1 !important;
  }

  .lg\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05 !important;
  }

  .lg\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1 !important;
  }

  .lg\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25 !important;
  }

  .lg\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5 !important;
  }

  .lg\:focus\:scale-0:focus {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .lg\:focus\:scale-50:focus {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .lg\:focus\:scale-75:focus {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .lg\:focus\:scale-90:focus {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .lg\:focus\:scale-95:focus {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .lg\:focus\:scale-100:focus {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .lg\:focus\:scale-105:focus {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .lg\:focus\:scale-110:focus {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .lg\:focus\:scale-125:focus {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .lg\:focus\:scale-150:focus {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .lg\:focus\:scale-x-0:focus {
    --transform-scale-x: 0 !important;
  }

  .lg\:focus\:scale-x-50:focus {
    --transform-scale-x: .5 !important;
  }

  .lg\:focus\:scale-x-75:focus {
    --transform-scale-x: .75 !important;
  }

  .lg\:focus\:scale-x-90:focus {
    --transform-scale-x: .9 !important;
  }

  .lg\:focus\:scale-x-95:focus {
    --transform-scale-x: .95 !important;
  }

  .lg\:focus\:scale-x-100:focus {
    --transform-scale-x: 1 !important;
  }

  .lg\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05 !important;
  }

  .lg\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1 !important;
  }

  .lg\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25 !important;
  }

  .lg\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5 !important;
  }

  .lg\:focus\:scale-y-0:focus {
    --transform-scale-y: 0 !important;
  }

  .lg\:focus\:scale-y-50:focus {
    --transform-scale-y: .5 !important;
  }

  .lg\:focus\:scale-y-75:focus {
    --transform-scale-y: .75 !important;
  }

  .lg\:focus\:scale-y-90:focus {
    --transform-scale-y: .9 !important;
  }

  .lg\:focus\:scale-y-95:focus {
    --transform-scale-y: .95 !important;
  }

  .lg\:focus\:scale-y-100:focus {
    --transform-scale-y: 1 !important;
  }

  .lg\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05 !important;
  }

  .lg\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1 !important;
  }

  .lg\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25 !important;
  }

  .lg\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5 !important;
  }

  .lg\:rotate-0 {
    --transform-rotate: 0 !important;
  }

  .lg\:rotate-1 {
    --transform-rotate: 1deg !important;
  }

  .lg\:rotate-2 {
    --transform-rotate: 2deg !important;
  }

  .lg\:rotate-3 {
    --transform-rotate: 3deg !important;
  }

  .lg\:rotate-6 {
    --transform-rotate: 6deg !important;
  }

  .lg\:rotate-12 {
    --transform-rotate: 12deg !important;
  }

  .lg\:rotate-45 {
    --transform-rotate: 45deg !important;
  }

  .lg\:rotate-90 {
    --transform-rotate: 90deg !important;
  }

  .lg\:rotate-180 {
    --transform-rotate: 180deg !important;
  }

  .lg\:-rotate-180 {
    --transform-rotate: -180deg !important;
  }

  .lg\:-rotate-90 {
    --transform-rotate: -90deg !important;
  }

  .lg\:-rotate-45 {
    --transform-rotate: -45deg !important;
  }

  .lg\:-rotate-12 {
    --transform-rotate: -12deg !important;
  }

  .lg\:-rotate-6 {
    --transform-rotate: -6deg !important;
  }

  .lg\:-rotate-3 {
    --transform-rotate: -3deg !important;
  }

  .lg\:-rotate-2 {
    --transform-rotate: -2deg !important;
  }

  .lg\:-rotate-1 {
    --transform-rotate: -1deg !important;
  }

  .lg\:hover\:rotate-0:hover {
    --transform-rotate: 0 !important;
  }

  .lg\:hover\:rotate-1:hover {
    --transform-rotate: 1deg !important;
  }

  .lg\:hover\:rotate-2:hover {
    --transform-rotate: 2deg !important;
  }

  .lg\:hover\:rotate-3:hover {
    --transform-rotate: 3deg !important;
  }

  .lg\:hover\:rotate-6:hover {
    --transform-rotate: 6deg !important;
  }

  .lg\:hover\:rotate-12:hover {
    --transform-rotate: 12deg !important;
  }

  .lg\:hover\:rotate-45:hover {
    --transform-rotate: 45deg !important;
  }

  .lg\:hover\:rotate-90:hover {
    --transform-rotate: 90deg !important;
  }

  .lg\:hover\:rotate-180:hover {
    --transform-rotate: 180deg !important;
  }

  .lg\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg !important;
  }

  .lg\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg !important;
  }

  .lg\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg !important;
  }

  .lg\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg !important;
  }

  .lg\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg !important;
  }

  .lg\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg !important;
  }

  .lg\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg !important;
  }

  .lg\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg !important;
  }

  .lg\:focus\:rotate-0:focus {
    --transform-rotate: 0 !important;
  }

  .lg\:focus\:rotate-1:focus {
    --transform-rotate: 1deg !important;
  }

  .lg\:focus\:rotate-2:focus {
    --transform-rotate: 2deg !important;
  }

  .lg\:focus\:rotate-3:focus {
    --transform-rotate: 3deg !important;
  }

  .lg\:focus\:rotate-6:focus {
    --transform-rotate: 6deg !important;
  }

  .lg\:focus\:rotate-12:focus {
    --transform-rotate: 12deg !important;
  }

  .lg\:focus\:rotate-45:focus {
    --transform-rotate: 45deg !important;
  }

  .lg\:focus\:rotate-90:focus {
    --transform-rotate: 90deg !important;
  }

  .lg\:focus\:rotate-180:focus {
    --transform-rotate: 180deg !important;
  }

  .lg\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg !important;
  }

  .lg\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg !important;
  }

  .lg\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg !important;
  }

  .lg\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg !important;
  }

  .lg\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg !important;
  }

  .lg\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg !important;
  }

  .lg\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg !important;
  }

  .lg\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg !important;
  }

  .lg\:translate-x-0 {
    --transform-translate-x: 0 !important;
  }

  .lg\:translate-x-1 {
    --transform-translate-x: 0.25rem !important;
  }

  .lg\:translate-x-2 {
    --transform-translate-x: 0.5rem !important;
  }

  .lg\:translate-x-3 {
    --transform-translate-x: 0.75rem !important;
  }

  .lg\:translate-x-4 {
    --transform-translate-x: 1rem !important;
  }

  .lg\:translate-x-5 {
    --transform-translate-x: 1.25rem !important;
  }

  .lg\:translate-x-6 {
    --transform-translate-x: 1.5rem !important;
  }

  .lg\:translate-x-8 {
    --transform-translate-x: 2rem !important;
  }

  .lg\:translate-x-10 {
    --transform-translate-x: 2.5rem !important;
  }

  .lg\:translate-x-12 {
    --transform-translate-x: 3rem !important;
  }

  .lg\:translate-x-16 {
    --transform-translate-x: 4rem !important;
  }

  .lg\:translate-x-20 {
    --transform-translate-x: 5rem !important;
  }

  .lg\:translate-x-24 {
    --transform-translate-x: 6rem !important;
  }

  .lg\:translate-x-32 {
    --transform-translate-x: 8rem !important;
  }

  .lg\:translate-x-40 {
    --transform-translate-x: 10rem !important;
  }

  .lg\:translate-x-48 {
    --transform-translate-x: 12rem !important;
  }

  .lg\:translate-x-56 {
    --transform-translate-x: 14rem !important;
  }

  .lg\:translate-x-64 {
    --transform-translate-x: 16rem !important;
  }

  .lg\:translate-x-px {
    --transform-translate-x: 1px !important;
  }

  .lg\:-translate-x-1 {
    --transform-translate-x: -0.25rem !important;
  }

  .lg\:-translate-x-2 {
    --transform-translate-x: -0.5rem !important;
  }

  .lg\:-translate-x-3 {
    --transform-translate-x: -0.75rem !important;
  }

  .lg\:-translate-x-4 {
    --transform-translate-x: -1rem !important;
  }

  .lg\:-translate-x-5 {
    --transform-translate-x: -1.25rem !important;
  }

  .lg\:-translate-x-6 {
    --transform-translate-x: -1.5rem !important;
  }

  .lg\:-translate-x-8 {
    --transform-translate-x: -2rem !important;
  }

  .lg\:-translate-x-10 {
    --transform-translate-x: -2.5rem !important;
  }

  .lg\:-translate-x-12 {
    --transform-translate-x: -3rem !important;
  }

  .lg\:-translate-x-16 {
    --transform-translate-x: -4rem !important;
  }

  .lg\:-translate-x-20 {
    --transform-translate-x: -5rem !important;
  }

  .lg\:-translate-x-24 {
    --transform-translate-x: -6rem !important;
  }

  .lg\:-translate-x-32 {
    --transform-translate-x: -8rem !important;
  }

  .lg\:-translate-x-40 {
    --transform-translate-x: -10rem !important;
  }

  .lg\:-translate-x-48 {
    --transform-translate-x: -12rem !important;
  }

  .lg\:-translate-x-56 {
    --transform-translate-x: -14rem !important;
  }

  .lg\:-translate-x-64 {
    --transform-translate-x: -16rem !important;
  }

  .lg\:-translate-x-px {
    --transform-translate-x: -1px !important;
  }

  .lg\:-translate-x-full {
    --transform-translate-x: -100% !important;
  }

  .lg\:-translate-x-1\/2 {
    --transform-translate-x: -50% !important;
  }

  .lg\:translate-x-1\/2 {
    --transform-translate-x: 50% !important;
  }

  .lg\:translate-x-full {
    --transform-translate-x: 100% !important;
  }

  .lg\:translate-y-0 {
    --transform-translate-y: 0 !important;
  }

  .lg\:translate-y-1 {
    --transform-translate-y: 0.25rem !important;
  }

  .lg\:translate-y-2 {
    --transform-translate-y: 0.5rem !important;
  }

  .lg\:translate-y-3 {
    --transform-translate-y: 0.75rem !important;
  }

  .lg\:translate-y-4 {
    --transform-translate-y: 1rem !important;
  }

  .lg\:translate-y-5 {
    --transform-translate-y: 1.25rem !important;
  }

  .lg\:translate-y-6 {
    --transform-translate-y: 1.5rem !important;
  }

  .lg\:translate-y-8 {
    --transform-translate-y: 2rem !important;
  }

  .lg\:translate-y-10 {
    --transform-translate-y: 2.5rem !important;
  }

  .lg\:translate-y-12 {
    --transform-translate-y: 3rem !important;
  }

  .lg\:translate-y-16 {
    --transform-translate-y: 4rem !important;
  }

  .lg\:translate-y-20 {
    --transform-translate-y: 5rem !important;
  }

  .lg\:translate-y-24 {
    --transform-translate-y: 6rem !important;
  }

  .lg\:translate-y-32 {
    --transform-translate-y: 8rem !important;
  }

  .lg\:translate-y-40 {
    --transform-translate-y: 10rem !important;
  }

  .lg\:translate-y-48 {
    --transform-translate-y: 12rem !important;
  }

  .lg\:translate-y-56 {
    --transform-translate-y: 14rem !important;
  }

  .lg\:translate-y-64 {
    --transform-translate-y: 16rem !important;
  }

  .lg\:translate-y-px {
    --transform-translate-y: 1px !important;
  }

  .lg\:-translate-y-1 {
    --transform-translate-y: -0.25rem !important;
  }

  .lg\:-translate-y-2 {
    --transform-translate-y: -0.5rem !important;
  }

  .lg\:-translate-y-3 {
    --transform-translate-y: -0.75rem !important;
  }

  .lg\:-translate-y-4 {
    --transform-translate-y: -1rem !important;
  }

  .lg\:-translate-y-5 {
    --transform-translate-y: -1.25rem !important;
  }

  .lg\:-translate-y-6 {
    --transform-translate-y: -1.5rem !important;
  }

  .lg\:-translate-y-8 {
    --transform-translate-y: -2rem !important;
  }

  .lg\:-translate-y-10 {
    --transform-translate-y: -2.5rem !important;
  }

  .lg\:-translate-y-12 {
    --transform-translate-y: -3rem !important;
  }

  .lg\:-translate-y-16 {
    --transform-translate-y: -4rem !important;
  }

  .lg\:-translate-y-20 {
    --transform-translate-y: -5rem !important;
  }

  .lg\:-translate-y-24 {
    --transform-translate-y: -6rem !important;
  }

  .lg\:-translate-y-32 {
    --transform-translate-y: -8rem !important;
  }

  .lg\:-translate-y-40 {
    --transform-translate-y: -10rem !important;
  }

  .lg\:-translate-y-48 {
    --transform-translate-y: -12rem !important;
  }

  .lg\:-translate-y-56 {
    --transform-translate-y: -14rem !important;
  }

  .lg\:-translate-y-64 {
    --transform-translate-y: -16rem !important;
  }

  .lg\:-translate-y-px {
    --transform-translate-y: -1px !important;
  }

  .lg\:-translate-y-full {
    --transform-translate-y: -100% !important;
  }

  .lg\:-translate-y-1\/2 {
    --transform-translate-y: -50% !important;
  }

  .lg\:translate-y-1\/2 {
    --transform-translate-y: 50% !important;
  }

  .lg\:translate-y-full {
    --transform-translate-y: 100% !important;
  }

  .lg\:hover\:translate-x-0:hover {
    --transform-translate-x: 0 !important;
  }

  .lg\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem !important;
  }

  .lg\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem !important;
  }

  .lg\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem !important;
  }

  .lg\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem !important;
  }

  .lg\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem !important;
  }

  .lg\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem !important;
  }

  .lg\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem !important;
  }

  .lg\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem !important;
  }

  .lg\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem !important;
  }

  .lg\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem !important;
  }

  .lg\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem !important;
  }

  .lg\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem !important;
  }

  .lg\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem !important;
  }

  .lg\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem !important;
  }

  .lg\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem !important;
  }

  .lg\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem !important;
  }

  .lg\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem !important;
  }

  .lg\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px !important;
  }

  .lg\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem !important;
  }

  .lg\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem !important;
  }

  .lg\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem !important;
  }

  .lg\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem !important;
  }

  .lg\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem !important;
  }

  .lg\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem !important;
  }

  .lg\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem !important;
  }

  .lg\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem !important;
  }

  .lg\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem !important;
  }

  .lg\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem !important;
  }

  .lg\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem !important;
  }

  .lg\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem !important;
  }

  .lg\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem !important;
  }

  .lg\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem !important;
  }

  .lg\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem !important;
  }

  .lg\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem !important;
  }

  .lg\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem !important;
  }

  .lg\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px !important;
  }

  .lg\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100% !important;
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50% !important;
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50% !important;
  }

  .lg\:hover\:translate-x-full:hover {
    --transform-translate-x: 100% !important;
  }

  .lg\:hover\:translate-y-0:hover {
    --transform-translate-y: 0 !important;
  }

  .lg\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem !important;
  }

  .lg\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem !important;
  }

  .lg\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem !important;
  }

  .lg\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem !important;
  }

  .lg\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem !important;
  }

  .lg\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem !important;
  }

  .lg\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem !important;
  }

  .lg\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem !important;
  }

  .lg\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem !important;
  }

  .lg\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem !important;
  }

  .lg\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem !important;
  }

  .lg\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem !important;
  }

  .lg\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem !important;
  }

  .lg\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem !important;
  }

  .lg\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem !important;
  }

  .lg\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem !important;
  }

  .lg\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem !important;
  }

  .lg\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px !important;
  }

  .lg\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem !important;
  }

  .lg\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem !important;
  }

  .lg\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem !important;
  }

  .lg\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem !important;
  }

  .lg\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem !important;
  }

  .lg\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem !important;
  }

  .lg\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem !important;
  }

  .lg\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem !important;
  }

  .lg\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem !important;
  }

  .lg\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem !important;
  }

  .lg\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem !important;
  }

  .lg\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem !important;
  }

  .lg\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem !important;
  }

  .lg\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem !important;
  }

  .lg\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem !important;
  }

  .lg\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem !important;
  }

  .lg\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem !important;
  }

  .lg\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px !important;
  }

  .lg\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100% !important;
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50% !important;
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50% !important;
  }

  .lg\:hover\:translate-y-full:hover {
    --transform-translate-y: 100% !important;
  }

  .lg\:focus\:translate-x-0:focus {
    --transform-translate-x: 0 !important;
  }

  .lg\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem !important;
  }

  .lg\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem !important;
  }

  .lg\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem !important;
  }

  .lg\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem !important;
  }

  .lg\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem !important;
  }

  .lg\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem !important;
  }

  .lg\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem !important;
  }

  .lg\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem !important;
  }

  .lg\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem !important;
  }

  .lg\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem !important;
  }

  .lg\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem !important;
  }

  .lg\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem !important;
  }

  .lg\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem !important;
  }

  .lg\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem !important;
  }

  .lg\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem !important;
  }

  .lg\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem !important;
  }

  .lg\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem !important;
  }

  .lg\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px !important;
  }

  .lg\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem !important;
  }

  .lg\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem !important;
  }

  .lg\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem !important;
  }

  .lg\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem !important;
  }

  .lg\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem !important;
  }

  .lg\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem !important;
  }

  .lg\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem !important;
  }

  .lg\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem !important;
  }

  .lg\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem !important;
  }

  .lg\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem !important;
  }

  .lg\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem !important;
  }

  .lg\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem !important;
  }

  .lg\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem !important;
  }

  .lg\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem !important;
  }

  .lg\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem !important;
  }

  .lg\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem !important;
  }

  .lg\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem !important;
  }

  .lg\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px !important;
  }

  .lg\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100% !important;
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50% !important;
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50% !important;
  }

  .lg\:focus\:translate-x-full:focus {
    --transform-translate-x: 100% !important;
  }

  .lg\:focus\:translate-y-0:focus {
    --transform-translate-y: 0 !important;
  }

  .lg\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem !important;
  }

  .lg\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem !important;
  }

  .lg\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem !important;
  }

  .lg\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem !important;
  }

  .lg\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem !important;
  }

  .lg\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem !important;
  }

  .lg\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem !important;
  }

  .lg\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem !important;
  }

  .lg\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem !important;
  }

  .lg\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem !important;
  }

  .lg\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem !important;
  }

  .lg\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem !important;
  }

  .lg\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem !important;
  }

  .lg\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem !important;
  }

  .lg\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem !important;
  }

  .lg\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem !important;
  }

  .lg\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem !important;
  }

  .lg\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px !important;
  }

  .lg\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem !important;
  }

  .lg\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem !important;
  }

  .lg\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem !important;
  }

  .lg\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem !important;
  }

  .lg\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem !important;
  }

  .lg\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem !important;
  }

  .lg\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem !important;
  }

  .lg\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem !important;
  }

  .lg\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem !important;
  }

  .lg\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem !important;
  }

  .lg\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem !important;
  }

  .lg\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem !important;
  }

  .lg\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem !important;
  }

  .lg\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem !important;
  }

  .lg\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem !important;
  }

  .lg\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem !important;
  }

  .lg\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem !important;
  }

  .lg\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px !important;
  }

  .lg\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100% !important;
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50% !important;
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50% !important;
  }

  .lg\:focus\:translate-y-full:focus {
    --transform-translate-y: 100% !important;
  }

  .lg\:skew-x-0 {
    --transform-skew-x: 0 !important;
  }

  .lg\:skew-x-1 {
    --transform-skew-x: 1deg !important;
  }

  .lg\:skew-x-2 {
    --transform-skew-x: 2deg !important;
  }

  .lg\:skew-x-3 {
    --transform-skew-x: 3deg !important;
  }

  .lg\:skew-x-6 {
    --transform-skew-x: 6deg !important;
  }

  .lg\:skew-x-12 {
    --transform-skew-x: 12deg !important;
  }

  .lg\:-skew-x-12 {
    --transform-skew-x: -12deg !important;
  }

  .lg\:-skew-x-6 {
    --transform-skew-x: -6deg !important;
  }

  .lg\:-skew-x-3 {
    --transform-skew-x: -3deg !important;
  }

  .lg\:-skew-x-2 {
    --transform-skew-x: -2deg !important;
  }

  .lg\:-skew-x-1 {
    --transform-skew-x: -1deg !important;
  }

  .lg\:skew-y-0 {
    --transform-skew-y: 0 !important;
  }

  .lg\:skew-y-1 {
    --transform-skew-y: 1deg !important;
  }

  .lg\:skew-y-2 {
    --transform-skew-y: 2deg !important;
  }

  .lg\:skew-y-3 {
    --transform-skew-y: 3deg !important;
  }

  .lg\:skew-y-6 {
    --transform-skew-y: 6deg !important;
  }

  .lg\:skew-y-12 {
    --transform-skew-y: 12deg !important;
  }

  .lg\:-skew-y-12 {
    --transform-skew-y: -12deg !important;
  }

  .lg\:-skew-y-6 {
    --transform-skew-y: -6deg !important;
  }

  .lg\:-skew-y-3 {
    --transform-skew-y: -3deg !important;
  }

  .lg\:-skew-y-2 {
    --transform-skew-y: -2deg !important;
  }

  .lg\:-skew-y-1 {
    --transform-skew-y: -1deg !important;
  }

  .lg\:hover\:skew-x-0:hover {
    --transform-skew-x: 0 !important;
  }

  .lg\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg !important;
  }

  .lg\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg !important;
  }

  .lg\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg !important;
  }

  .lg\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg !important;
  }

  .lg\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg !important;
  }

  .lg\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg !important;
  }

  .lg\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg !important;
  }

  .lg\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg !important;
  }

  .lg\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg !important;
  }

  .lg\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg !important;
  }

  .lg\:hover\:skew-y-0:hover {
    --transform-skew-y: 0 !important;
  }

  .lg\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg !important;
  }

  .lg\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg !important;
  }

  .lg\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg !important;
  }

  .lg\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg !important;
  }

  .lg\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg !important;
  }

  .lg\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg !important;
  }

  .lg\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg !important;
  }

  .lg\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg !important;
  }

  .lg\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg !important;
  }

  .lg\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg !important;
  }

  .lg\:focus\:skew-x-0:focus {
    --transform-skew-x: 0 !important;
  }

  .lg\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg !important;
  }

  .lg\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg !important;
  }

  .lg\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg !important;
  }

  .lg\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg !important;
  }

  .lg\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg !important;
  }

  .lg\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg !important;
  }

  .lg\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg !important;
  }

  .lg\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg !important;
  }

  .lg\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg !important;
  }

  .lg\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg !important;
  }

  .lg\:focus\:skew-y-0:focus {
    --transform-skew-y: 0 !important;
  }

  .lg\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg !important;
  }

  .lg\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg !important;
  }

  .lg\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg !important;
  }

  .lg\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg !important;
  }

  .lg\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg !important;
  }

  .lg\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg !important;
  }

  .lg\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg !important;
  }

  .lg\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg !important;
  }

  .lg\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg !important;
  }

  .lg\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg !important;
  }

  .lg\:transition-none {
    transition-property: none !important;
  }

  .lg\:transition-all {
    transition-property: all !important;
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .lg\:transition-opacity {
    transition-property: opacity !important;
  }

  .lg\:transition-shadow {
    transition-property: box-shadow !important;
  }

  .lg\:transition-transform {
    transition-property: transform !important;
  }

  .lg\:ease-linear {
    transition-timing-function: linear !important;
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .lg\:duration-75 {
    transition-duration: 75ms !important;
  }

  .lg\:duration-100 {
    transition-duration: 100ms !important;
  }

  .lg\:duration-150 {
    transition-duration: 150ms !important;
  }

  .lg\:duration-200 {
    transition-duration: 200ms !important;
  }

  .lg\:duration-300 {
    transition-duration: 300ms !important;
  }

  .lg\:duration-500 {
    transition-duration: 500ms !important;
  }

  .lg\:duration-700 {
    transition-duration: 700ms !important;
  }

  .lg\:duration-1000 {
    transition-duration: 1000ms !important;
  }

  .lg\:delay-75 {
    transition-delay: 75ms !important;
  }

  .lg\:delay-100 {
    transition-delay: 100ms !important;
  }

  .lg\:delay-150 {
    transition-delay: 150ms !important;
  }

  .lg\:delay-200 {
    transition-delay: 200ms !important;
  }

  .lg\:delay-300 {
    transition-delay: 300ms !important;
  }

  .lg\:delay-500 {
    transition-delay: 500ms !important;
  }

  .lg\:delay-700 {
    transition-delay: 700ms !important;
  }

  .lg\:delay-1000 {
    transition-delay: 1000ms !important;
  }

  .lg\:animate-none {
    animation: none !important;
  }

  .lg\:animate-spin {
    animation: spin 1s linear infinite !important;
  }

  .lg\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .lg\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .lg\:animate-bounce {
    animation: bounce 1s infinite !important;
  }

  .lg\:link {
    color: var(--primary);
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

@media (min-width: 1200px) {
  .xl\:container {
    width: 100%;
  }

  @media (min-width: 480px) {
    .xl\:container {
      max-width: 480px;
    }
  }

  @media (min-width: 576px) {
    .xl\:container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .xl\:container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .xl\:container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .xl\:container {
      max-width: 1600px;
    }
  }

  .xl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .xl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .xl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .xl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .xl\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .xl\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .xl\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .xl\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
  }

  .xl\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--primary) !important;
  }

  .xl\:divide-accent-1 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-accent-2 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
  }

  .xl\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid !important;
  }

  .xl\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed !important;
  }

  .xl\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted !important;
  }

  .xl\:divide-double > :not(template) ~ :not(template) {
    border-style: double !important;
  }

  .xl\:divide-none > :not(template) ~ :not(template) {
    border-style: none !important;
  }

  .xl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .xl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .xl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .xl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .xl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .xl\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .xl\:bg-fixed {
    background-attachment: fixed !important;
  }

  .xl\:bg-local {
    background-attachment: local !important;
  }

  .xl\:bg-scroll {
    background-attachment: scroll !important;
  }

  .xl\:bg-clip-border {
    background-clip: border-box !important;
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box !important;
  }

  .xl\:bg-clip-content {
    background-clip: content-box !important;
  }

  .xl\:bg-clip-text {
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .xl\:bg-transparent {
    background-color: transparent !important;
  }

  .xl\:bg-current {
    background-color: currentColor !important;
  }

  .xl\:bg-black {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xl\:bg-white {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-100 {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-200 {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-300 {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-400 {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-500 {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-600 {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-700 {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-800 {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xl\:bg-gray-900 {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-300 {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-400 {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-500 {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-600 {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-700 {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-800 {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xl\:bg-red-900 {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-100 {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-200 {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-300 {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-400 {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-500 {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-600 {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-700 {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-800 {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xl\:bg-orange-900 {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-100 {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-200 {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-300 {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-400 {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-500 {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-600 {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-700 {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-800 {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xl\:bg-yellow-900 {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-100 {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-200 {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-300 {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-400 {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-500 {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-600 {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-700 {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-800 {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xl\:bg-green-900 {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-100 {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-200 {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-300 {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-400 {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-500 {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-600 {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-700 {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-800 {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xl\:bg-teal-900 {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-200 {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-300 {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-400 {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-500 {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-600 {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-700 {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-800 {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xl\:bg-blue-900 {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-200 {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-300 {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-400 {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-500 {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-600 {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-700 {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-800 {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xl\:bg-indigo-900 {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-100 {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-200 {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-300 {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-400 {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-500 {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-600 {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-700 {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-800 {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xl\:bg-purple-900 {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-300 {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-400 {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-500 {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-600 {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-700 {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-800 {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xl\:bg-pink-900 {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xl\:bg-primary {
    background-color: var(--primary) !important;
  }

  .xl\:bg-accent-1 {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xl\:bg-accent-2 {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: transparent !important;
  }

  .xl\:hover\:bg-current:hover {
    background-color: currentColor !important;
  }

  .xl\:hover\:bg-black:hover {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-white:hover {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-200:hover {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-300:hover {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-400:hover {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-500:hover {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-600:hover {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-900:hover {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-300:hover {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-400:hover {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-500:hover {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-600:hover {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-900:hover {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-200:hover {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-600:hover {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-orange-900:hover {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-300:hover {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-400:hover {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-500:hover {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-600:hover {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-800:hover {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-900:hover {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-100:hover {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-200:hover {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-300:hover {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-400:hover {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-500:hover {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-600:hover {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-800:hover {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-teal-900:hover {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-200:hover {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-300:hover {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-400:hover {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-500:hover {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-600:hover {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-900:hover {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-100:hover {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-200:hover {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-300:hover {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-400:hover {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-500:hover {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-600:hover {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-700:hover {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-800:hover {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-900:hover {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-800:hover {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-900:hover {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-primary:hover {
    background-color: var(--primary) !important;
  }

  .xl\:hover\:bg-accent-1:hover {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xl\:hover\:bg-accent-2:hover {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: transparent !important;
  }

  .xl\:focus\:bg-current:focus {
    background-color: currentColor !important;
  }

  .xl\:focus\:bg-black:focus {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-white:focus {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-200:focus {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-300:focus {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-400:focus {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-500:focus {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-600:focus {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-900:focus {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-300:focus {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-400:focus {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-500:focus {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-600:focus {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-900:focus {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-200:focus {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-600:focus {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-orange-900:focus {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-300:focus {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-400:focus {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-500:focus {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-600:focus {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-800:focus {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-900:focus {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-100:focus {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-200:focus {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-300:focus {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-400:focus {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-500:focus {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-600:focus {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-800:focus {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-teal-900:focus {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-200:focus {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-300:focus {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-400:focus {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-500:focus {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-600:focus {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-900:focus {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-100:focus {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-200:focus {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-300:focus {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-400:focus {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-500:focus {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-600:focus {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-700:focus {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-800:focus {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-900:focus {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-800:focus {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-900:focus {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-primary:focus {
    background-color: var(--primary) !important;
  }

  .xl\:focus\:bg-accent-1:focus {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xl\:focus\:bg-accent-2:focus {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xl\:bg-none {
    background-image: none !important;
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
  }

  .xl\:from-transparent {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:from-current {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:from-black {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:from-white {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:from-gray-100 {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xl\:from-gray-200 {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xl\:from-gray-300 {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xl\:from-gray-400 {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xl\:from-gray-500 {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xl\:from-gray-600 {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xl\:from-gray-700 {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xl\:from-gray-800 {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xl\:from-gray-900 {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xl\:from-red-100 {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xl\:from-red-200 {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xl\:from-red-300 {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xl\:from-red-400 {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xl\:from-red-500 {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xl\:from-red-600 {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xl\:from-red-700 {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xl\:from-red-800 {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xl\:from-red-900 {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xl\:from-orange-100 {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xl\:from-orange-200 {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xl\:from-orange-300 {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xl\:from-orange-400 {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xl\:from-orange-500 {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xl\:from-orange-600 {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xl\:from-orange-700 {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xl\:from-orange-800 {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xl\:from-orange-900 {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xl\:from-yellow-100 {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xl\:from-yellow-200 {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xl\:from-yellow-300 {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xl\:from-yellow-400 {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xl\:from-yellow-500 {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xl\:from-yellow-600 {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xl\:from-yellow-700 {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xl\:from-yellow-800 {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xl\:from-yellow-900 {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xl\:from-green-100 {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xl\:from-green-200 {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xl\:from-green-300 {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xl\:from-green-400 {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xl\:from-green-500 {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xl\:from-green-600 {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xl\:from-green-700 {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xl\:from-green-800 {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xl\:from-green-900 {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xl\:from-teal-100 {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xl\:from-teal-200 {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xl\:from-teal-300 {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xl\:from-teal-400 {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xl\:from-teal-500 {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xl\:from-teal-600 {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xl\:from-teal-700 {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xl\:from-teal-800 {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xl\:from-teal-900 {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xl\:from-blue-100 {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xl\:from-blue-200 {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xl\:from-blue-300 {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xl\:from-blue-400 {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xl\:from-blue-500 {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xl\:from-blue-600 {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xl\:from-blue-700 {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xl\:from-blue-800 {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xl\:from-blue-900 {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xl\:from-indigo-100 {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xl\:from-indigo-200 {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xl\:from-indigo-300 {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xl\:from-indigo-400 {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xl\:from-indigo-500 {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xl\:from-indigo-600 {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xl\:from-indigo-700 {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xl\:from-indigo-800 {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xl\:from-indigo-900 {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xl\:from-purple-100 {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xl\:from-purple-200 {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xl\:from-purple-300 {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xl\:from-purple-400 {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xl\:from-purple-500 {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xl\:from-purple-600 {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xl\:from-purple-700 {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xl\:from-purple-800 {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xl\:from-purple-900 {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xl\:from-pink-100 {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xl\:from-pink-200 {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xl\:from-pink-300 {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xl\:from-pink-400 {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xl\:from-pink-500 {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xl\:from-pink-600 {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xl\:from-pink-700 {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xl\:from-pink-800 {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xl\:from-pink-900 {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xl\:from-primary {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:from-accent-1 {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xl\:from-accent-2 {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xl\:via-transparent {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:via-current {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:via-black {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:via-white {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:via-gray-100 {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xl\:via-gray-200 {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xl\:via-gray-300 {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xl\:via-gray-400 {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xl\:via-gray-500 {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xl\:via-gray-600 {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xl\:via-gray-700 {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xl\:via-gray-800 {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xl\:via-gray-900 {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xl\:via-red-100 {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xl\:via-red-200 {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xl\:via-red-300 {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xl\:via-red-400 {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xl\:via-red-500 {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xl\:via-red-600 {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xl\:via-red-700 {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xl\:via-red-800 {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xl\:via-red-900 {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xl\:via-orange-100 {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xl\:via-orange-200 {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xl\:via-orange-300 {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xl\:via-orange-400 {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xl\:via-orange-500 {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xl\:via-orange-600 {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xl\:via-orange-700 {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xl\:via-orange-800 {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xl\:via-orange-900 {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xl\:via-yellow-100 {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xl\:via-yellow-200 {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xl\:via-yellow-300 {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xl\:via-yellow-400 {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xl\:via-yellow-500 {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xl\:via-yellow-600 {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xl\:via-yellow-700 {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xl\:via-yellow-800 {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xl\:via-yellow-900 {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xl\:via-green-100 {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xl\:via-green-200 {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xl\:via-green-300 {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xl\:via-green-400 {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xl\:via-green-500 {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xl\:via-green-600 {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xl\:via-green-700 {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xl\:via-green-800 {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xl\:via-green-900 {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xl\:via-teal-100 {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xl\:via-teal-200 {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xl\:via-teal-300 {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xl\:via-teal-400 {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xl\:via-teal-500 {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xl\:via-teal-600 {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xl\:via-teal-700 {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xl\:via-teal-800 {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xl\:via-teal-900 {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xl\:via-blue-100 {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xl\:via-blue-200 {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xl\:via-blue-300 {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xl\:via-blue-400 {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xl\:via-blue-500 {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xl\:via-blue-600 {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xl\:via-blue-700 {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xl\:via-blue-800 {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xl\:via-blue-900 {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xl\:via-indigo-100 {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xl\:via-indigo-200 {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xl\:via-indigo-300 {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xl\:via-indigo-400 {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xl\:via-indigo-500 {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xl\:via-indigo-600 {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xl\:via-indigo-700 {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xl\:via-indigo-800 {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xl\:via-indigo-900 {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xl\:via-purple-100 {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xl\:via-purple-200 {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xl\:via-purple-300 {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xl\:via-purple-400 {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xl\:via-purple-500 {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xl\:via-purple-600 {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xl\:via-purple-700 {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xl\:via-purple-800 {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xl\:via-purple-900 {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xl\:via-pink-100 {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xl\:via-pink-200 {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xl\:via-pink-300 {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xl\:via-pink-400 {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xl\:via-pink-500 {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xl\:via-pink-600 {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xl\:via-pink-700 {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xl\:via-pink-800 {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xl\:via-pink-900 {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xl\:via-primary {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:via-accent-1 {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xl\:via-accent-2 {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xl\:to-transparent {
    --gradient-to-color: transparent !important;
  }

  .xl\:to-current {
    --gradient-to-color: currentColor !important;
  }

  .xl\:to-black {
    --gradient-to-color: #000 !important;
  }

  .xl\:to-white {
    --gradient-to-color: #fff !important;
  }

  .xl\:to-gray-100 {
    --gradient-to-color: #f7fafc !important;
  }

  .xl\:to-gray-200 {
    --gradient-to-color: #edf2f7 !important;
  }

  .xl\:to-gray-300 {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xl\:to-gray-400 {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xl\:to-gray-500 {
    --gradient-to-color: #a0aec0 !important;
  }

  .xl\:to-gray-600 {
    --gradient-to-color: #718096 !important;
  }

  .xl\:to-gray-700 {
    --gradient-to-color: #4a5568 !important;
  }

  .xl\:to-gray-800 {
    --gradient-to-color: #2d3748 !important;
  }

  .xl\:to-gray-900 {
    --gradient-to-color: #1a202c !important;
  }

  .xl\:to-red-100 {
    --gradient-to-color: #fff5f5 !important;
  }

  .xl\:to-red-200 {
    --gradient-to-color: #fed7d7 !important;
  }

  .xl\:to-red-300 {
    --gradient-to-color: #feb2b2 !important;
  }

  .xl\:to-red-400 {
    --gradient-to-color: #fc8181 !important;
  }

  .xl\:to-red-500 {
    --gradient-to-color: #f56565 !important;
  }

  .xl\:to-red-600 {
    --gradient-to-color: #e53e3e !important;
  }

  .xl\:to-red-700 {
    --gradient-to-color: #c53030 !important;
  }

  .xl\:to-red-800 {
    --gradient-to-color: #9b2c2c !important;
  }

  .xl\:to-red-900 {
    --gradient-to-color: #742a2a !important;
  }

  .xl\:to-orange-100 {
    --gradient-to-color: #fffaf0 !important;
  }

  .xl\:to-orange-200 {
    --gradient-to-color: #feebc8 !important;
  }

  .xl\:to-orange-300 {
    --gradient-to-color: #fbd38d !important;
  }

  .xl\:to-orange-400 {
    --gradient-to-color: #f6ad55 !important;
  }

  .xl\:to-orange-500 {
    --gradient-to-color: #ed8936 !important;
  }

  .xl\:to-orange-600 {
    --gradient-to-color: #dd6b20 !important;
  }

  .xl\:to-orange-700 {
    --gradient-to-color: #c05621 !important;
  }

  .xl\:to-orange-800 {
    --gradient-to-color: #9c4221 !important;
  }

  .xl\:to-orange-900 {
    --gradient-to-color: #7b341e !important;
  }

  .xl\:to-yellow-100 {
    --gradient-to-color: #fffff0 !important;
  }

  .xl\:to-yellow-200 {
    --gradient-to-color: #fefcbf !important;
  }

  .xl\:to-yellow-300 {
    --gradient-to-color: #faf089 !important;
  }

  .xl\:to-yellow-400 {
    --gradient-to-color: #f6e05e !important;
  }

  .xl\:to-yellow-500 {
    --gradient-to-color: #ecc94b !important;
  }

  .xl\:to-yellow-600 {
    --gradient-to-color: #d69e2e !important;
  }

  .xl\:to-yellow-700 {
    --gradient-to-color: #b7791f !important;
  }

  .xl\:to-yellow-800 {
    --gradient-to-color: #975a16 !important;
  }

  .xl\:to-yellow-900 {
    --gradient-to-color: #744210 !important;
  }

  .xl\:to-green-100 {
    --gradient-to-color: #f0fff4 !important;
  }

  .xl\:to-green-200 {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xl\:to-green-300 {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xl\:to-green-400 {
    --gradient-to-color: #68d391 !important;
  }

  .xl\:to-green-500 {
    --gradient-to-color: #48bb78 !important;
  }

  .xl\:to-green-600 {
    --gradient-to-color: #38a169 !important;
  }

  .xl\:to-green-700 {
    --gradient-to-color: #2f855a !important;
  }

  .xl\:to-green-800 {
    --gradient-to-color: #276749 !important;
  }

  .xl\:to-green-900 {
    --gradient-to-color: #22543d !important;
  }

  .xl\:to-teal-100 {
    --gradient-to-color: #e6fffa !important;
  }

  .xl\:to-teal-200 {
    --gradient-to-color: #b2f5ea !important;
  }

  .xl\:to-teal-300 {
    --gradient-to-color: #81e6d9 !important;
  }

  .xl\:to-teal-400 {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xl\:to-teal-500 {
    --gradient-to-color: #38b2ac !important;
  }

  .xl\:to-teal-600 {
    --gradient-to-color: #319795 !important;
  }

  .xl\:to-teal-700 {
    --gradient-to-color: #2c7a7b !important;
  }

  .xl\:to-teal-800 {
    --gradient-to-color: #285e61 !important;
  }

  .xl\:to-teal-900 {
    --gradient-to-color: #234e52 !important;
  }

  .xl\:to-blue-100 {
    --gradient-to-color: #ebf8ff !important;
  }

  .xl\:to-blue-200 {
    --gradient-to-color: #bee3f8 !important;
  }

  .xl\:to-blue-300 {
    --gradient-to-color: #90cdf4 !important;
  }

  .xl\:to-blue-400 {
    --gradient-to-color: #63b3ed !important;
  }

  .xl\:to-blue-500 {
    --gradient-to-color: #4299e1 !important;
  }

  .xl\:to-blue-600 {
    --gradient-to-color: #3182ce !important;
  }

  .xl\:to-blue-700 {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xl\:to-blue-800 {
    --gradient-to-color: #2c5282 !important;
  }

  .xl\:to-blue-900 {
    --gradient-to-color: #2a4365 !important;
  }

  .xl\:to-indigo-100 {
    --gradient-to-color: #ebf4ff !important;
  }

  .xl\:to-indigo-200 {
    --gradient-to-color: #c3dafe !important;
  }

  .xl\:to-indigo-300 {
    --gradient-to-color: #a3bffa !important;
  }

  .xl\:to-indigo-400 {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xl\:to-indigo-500 {
    --gradient-to-color: #667eea !important;
  }

  .xl\:to-indigo-600 {
    --gradient-to-color: #5a67d8 !important;
  }

  .xl\:to-indigo-700 {
    --gradient-to-color: #4c51bf !important;
  }

  .xl\:to-indigo-800 {
    --gradient-to-color: #434190 !important;
  }

  .xl\:to-indigo-900 {
    --gradient-to-color: #3c366b !important;
  }

  .xl\:to-purple-100 {
    --gradient-to-color: #faf5ff !important;
  }

  .xl\:to-purple-200 {
    --gradient-to-color: #e9d8fd !important;
  }

  .xl\:to-purple-300 {
    --gradient-to-color: #d6bcfa !important;
  }

  .xl\:to-purple-400 {
    --gradient-to-color: #b794f4 !important;
  }

  .xl\:to-purple-500 {
    --gradient-to-color: #9f7aea !important;
  }

  .xl\:to-purple-600 {
    --gradient-to-color: #805ad5 !important;
  }

  .xl\:to-purple-700 {
    --gradient-to-color: #6b46c1 !important;
  }

  .xl\:to-purple-800 {
    --gradient-to-color: #553c9a !important;
  }

  .xl\:to-purple-900 {
    --gradient-to-color: #44337a !important;
  }

  .xl\:to-pink-100 {
    --gradient-to-color: #fff5f7 !important;
  }

  .xl\:to-pink-200 {
    --gradient-to-color: #fed7e2 !important;
  }

  .xl\:to-pink-300 {
    --gradient-to-color: #fbb6ce !important;
  }

  .xl\:to-pink-400 {
    --gradient-to-color: #f687b3 !important;
  }

  .xl\:to-pink-500 {
    --gradient-to-color: #ed64a6 !important;
  }

  .xl\:to-pink-600 {
    --gradient-to-color: #d53f8c !important;
  }

  .xl\:to-pink-700 {
    --gradient-to-color: #b83280 !important;
  }

  .xl\:to-pink-800 {
    --gradient-to-color: #97266d !important;
  }

  .xl\:to-pink-900 {
    --gradient-to-color: #702459 !important;
  }

  .xl\:to-primary {
    --gradient-to-color: var(--primary) !important;
  }

  .xl\:to-accent-1 {
    --gradient-to-color: #fafafa !important;
  }

  .xl\:to-accent-2 {
    --gradient-to-color: #eaeaea !important;
  }

  .xl\:hover\:from-transparent:hover {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:from-current:hover {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:from-black:hover {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:from-white:hover {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xl\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xl\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xl\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xl\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xl\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xl\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xl\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xl\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xl\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xl\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xl\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xl\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xl\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xl\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xl\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xl\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xl\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xl\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xl\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xl\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xl\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xl\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xl\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xl\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xl\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xl\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xl\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xl\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xl\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xl\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xl\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xl\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xl\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xl\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xl\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xl\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xl\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xl\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xl\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xl\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xl\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xl\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xl\:hover\:from-green-800:hover {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xl\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xl\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xl\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xl\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xl\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xl\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xl\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xl\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xl\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xl\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xl\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xl\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xl\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xl\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xl\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xl\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xl\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xl\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xl\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xl\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xl\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xl\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xl\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xl\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xl\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xl\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xl\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xl\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xl\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xl\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xl\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xl\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xl\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xl\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xl\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xl\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xl\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xl\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xl\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xl\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xl\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xl\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xl\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xl\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xl\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xl\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xl\:hover\:from-primary:hover {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:from-accent-1:hover {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xl\:hover\:from-accent-2:hover {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xl\:hover\:via-transparent:hover {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:via-current:hover {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:via-black:hover {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:via-white:hover {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xl\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xl\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xl\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xl\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xl\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xl\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xl\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xl\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xl\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xl\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xl\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xl\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xl\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xl\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xl\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xl\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xl\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xl\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xl\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xl\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xl\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xl\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xl\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xl\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xl\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xl\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xl\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xl\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xl\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xl\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xl\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xl\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xl\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xl\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xl\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xl\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xl\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xl\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xl\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xl\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xl\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xl\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xl\:hover\:via-green-800:hover {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xl\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xl\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xl\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xl\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xl\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xl\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xl\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xl\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xl\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xl\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xl\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xl\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xl\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xl\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xl\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xl\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xl\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xl\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xl\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xl\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xl\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xl\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xl\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xl\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xl\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xl\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xl\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xl\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xl\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xl\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xl\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xl\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xl\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xl\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xl\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xl\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xl\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xl\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xl\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xl\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xl\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xl\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xl\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xl\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xl\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xl\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xl\:hover\:via-primary:hover {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:via-accent-1:hover {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xl\:hover\:via-accent-2:hover {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xl\:hover\:to-transparent:hover {
    --gradient-to-color: transparent !important;
  }

  .xl\:hover\:to-current:hover {
    --gradient-to-color: currentColor !important;
  }

  .xl\:hover\:to-black:hover {
    --gradient-to-color: #000 !important;
  }

  .xl\:hover\:to-white:hover {
    --gradient-to-color: #fff !important;
  }

  .xl\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc !important;
  }

  .xl\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7 !important;
  }

  .xl\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xl\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xl\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0 !important;
  }

  .xl\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096 !important;
  }

  .xl\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568 !important;
  }

  .xl\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748 !important;
  }

  .xl\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c !important;
  }

  .xl\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5 !important;
  }

  .xl\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7 !important;
  }

  .xl\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2 !important;
  }

  .xl\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181 !important;
  }

  .xl\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565 !important;
  }

  .xl\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e !important;
  }

  .xl\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030 !important;
  }

  .xl\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c !important;
  }

  .xl\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a !important;
  }

  .xl\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0 !important;
  }

  .xl\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8 !important;
  }

  .xl\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d !important;
  }

  .xl\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55 !important;
  }

  .xl\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936 !important;
  }

  .xl\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20 !important;
  }

  .xl\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621 !important;
  }

  .xl\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221 !important;
  }

  .xl\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e !important;
  }

  .xl\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0 !important;
  }

  .xl\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf !important;
  }

  .xl\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089 !important;
  }

  .xl\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e !important;
  }

  .xl\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b !important;
  }

  .xl\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e !important;
  }

  .xl\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f !important;
  }

  .xl\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16 !important;
  }

  .xl\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210 !important;
  }

  .xl\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4 !important;
  }

  .xl\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xl\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xl\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391 !important;
  }

  .xl\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78 !important;
  }

  .xl\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169 !important;
  }

  .xl\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a !important;
  }

  .xl\:hover\:to-green-800:hover {
    --gradient-to-color: #276749 !important;
  }

  .xl\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d !important;
  }

  .xl\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa !important;
  }

  .xl\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea !important;
  }

  .xl\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9 !important;
  }

  .xl\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xl\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac !important;
  }

  .xl\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795 !important;
  }

  .xl\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b !important;
  }

  .xl\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61 !important;
  }

  .xl\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52 !important;
  }

  .xl\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff !important;
  }

  .xl\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8 !important;
  }

  .xl\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4 !important;
  }

  .xl\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed !important;
  }

  .xl\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1 !important;
  }

  .xl\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce !important;
  }

  .xl\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xl\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282 !important;
  }

  .xl\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365 !important;
  }

  .xl\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff !important;
  }

  .xl\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe !important;
  }

  .xl\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa !important;
  }

  .xl\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xl\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea !important;
  }

  .xl\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8 !important;
  }

  .xl\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf !important;
  }

  .xl\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190 !important;
  }

  .xl\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b !important;
  }

  .xl\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff !important;
  }

  .xl\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd !important;
  }

  .xl\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa !important;
  }

  .xl\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4 !important;
  }

  .xl\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea !important;
  }

  .xl\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5 !important;
  }

  .xl\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1 !important;
  }

  .xl\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a !important;
  }

  .xl\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a !important;
  }

  .xl\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7 !important;
  }

  .xl\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2 !important;
  }

  .xl\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce !important;
  }

  .xl\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3 !important;
  }

  .xl\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6 !important;
  }

  .xl\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c !important;
  }

  .xl\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280 !important;
  }

  .xl\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d !important;
  }

  .xl\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459 !important;
  }

  .xl\:hover\:to-primary:hover {
    --gradient-to-color: var(--primary) !important;
  }

  .xl\:hover\:to-accent-1:hover {
    --gradient-to-color: #fafafa !important;
  }

  .xl\:hover\:to-accent-2:hover {
    --gradient-to-color: #eaeaea !important;
  }

  .xl\:focus\:from-transparent:focus {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:from-current:focus {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:from-black:focus {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:from-white:focus {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xl\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xl\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xl\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xl\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xl\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xl\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xl\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xl\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xl\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xl\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xl\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xl\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xl\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xl\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xl\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xl\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xl\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xl\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xl\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xl\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xl\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xl\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xl\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xl\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xl\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xl\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xl\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xl\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xl\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xl\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xl\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xl\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xl\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xl\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xl\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xl\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xl\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xl\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xl\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xl\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xl\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xl\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xl\:focus\:from-green-800:focus {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xl\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xl\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xl\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xl\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xl\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xl\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xl\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xl\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xl\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xl\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xl\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xl\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xl\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xl\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xl\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xl\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xl\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xl\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xl\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xl\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xl\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xl\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xl\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xl\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xl\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xl\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xl\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xl\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xl\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xl\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xl\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xl\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xl\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xl\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xl\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xl\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xl\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xl\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xl\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xl\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xl\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xl\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xl\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xl\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xl\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xl\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xl\:focus\:from-primary:focus {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:from-accent-1:focus {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xl\:focus\:from-accent-2:focus {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xl\:focus\:via-transparent:focus {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:via-current:focus {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:via-black:focus {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:via-white:focus {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xl\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xl\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xl\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xl\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xl\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xl\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xl\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xl\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xl\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xl\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xl\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xl\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xl\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xl\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xl\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xl\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xl\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xl\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xl\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xl\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xl\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xl\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xl\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xl\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xl\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xl\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xl\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xl\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xl\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xl\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xl\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xl\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xl\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xl\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xl\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xl\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xl\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xl\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xl\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xl\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xl\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xl\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xl\:focus\:via-green-800:focus {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xl\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xl\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xl\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xl\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xl\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xl\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xl\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xl\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xl\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xl\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xl\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xl\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xl\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xl\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xl\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xl\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xl\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xl\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xl\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xl\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xl\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xl\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xl\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xl\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xl\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xl\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xl\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xl\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xl\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xl\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xl\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xl\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xl\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xl\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xl\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xl\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xl\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xl\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xl\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xl\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xl\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xl\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xl\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xl\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xl\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xl\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xl\:focus\:via-primary:focus {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:via-accent-1:focus {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xl\:focus\:via-accent-2:focus {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xl\:focus\:to-transparent:focus {
    --gradient-to-color: transparent !important;
  }

  .xl\:focus\:to-current:focus {
    --gradient-to-color: currentColor !important;
  }

  .xl\:focus\:to-black:focus {
    --gradient-to-color: #000 !important;
  }

  .xl\:focus\:to-white:focus {
    --gradient-to-color: #fff !important;
  }

  .xl\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc !important;
  }

  .xl\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7 !important;
  }

  .xl\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xl\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xl\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0 !important;
  }

  .xl\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096 !important;
  }

  .xl\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568 !important;
  }

  .xl\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748 !important;
  }

  .xl\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c !important;
  }

  .xl\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5 !important;
  }

  .xl\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7 !important;
  }

  .xl\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2 !important;
  }

  .xl\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181 !important;
  }

  .xl\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565 !important;
  }

  .xl\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e !important;
  }

  .xl\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030 !important;
  }

  .xl\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c !important;
  }

  .xl\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a !important;
  }

  .xl\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0 !important;
  }

  .xl\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8 !important;
  }

  .xl\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d !important;
  }

  .xl\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55 !important;
  }

  .xl\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936 !important;
  }

  .xl\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20 !important;
  }

  .xl\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621 !important;
  }

  .xl\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221 !important;
  }

  .xl\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e !important;
  }

  .xl\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0 !important;
  }

  .xl\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf !important;
  }

  .xl\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089 !important;
  }

  .xl\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e !important;
  }

  .xl\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b !important;
  }

  .xl\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e !important;
  }

  .xl\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f !important;
  }

  .xl\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16 !important;
  }

  .xl\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210 !important;
  }

  .xl\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4 !important;
  }

  .xl\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xl\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xl\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391 !important;
  }

  .xl\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78 !important;
  }

  .xl\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169 !important;
  }

  .xl\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a !important;
  }

  .xl\:focus\:to-green-800:focus {
    --gradient-to-color: #276749 !important;
  }

  .xl\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d !important;
  }

  .xl\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa !important;
  }

  .xl\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea !important;
  }

  .xl\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9 !important;
  }

  .xl\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xl\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac !important;
  }

  .xl\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795 !important;
  }

  .xl\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b !important;
  }

  .xl\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61 !important;
  }

  .xl\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52 !important;
  }

  .xl\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff !important;
  }

  .xl\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8 !important;
  }

  .xl\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4 !important;
  }

  .xl\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed !important;
  }

  .xl\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1 !important;
  }

  .xl\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce !important;
  }

  .xl\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xl\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282 !important;
  }

  .xl\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365 !important;
  }

  .xl\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff !important;
  }

  .xl\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe !important;
  }

  .xl\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa !important;
  }

  .xl\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xl\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea !important;
  }

  .xl\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8 !important;
  }

  .xl\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf !important;
  }

  .xl\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190 !important;
  }

  .xl\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b !important;
  }

  .xl\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff !important;
  }

  .xl\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd !important;
  }

  .xl\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa !important;
  }

  .xl\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4 !important;
  }

  .xl\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea !important;
  }

  .xl\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5 !important;
  }

  .xl\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1 !important;
  }

  .xl\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a !important;
  }

  .xl\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a !important;
  }

  .xl\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7 !important;
  }

  .xl\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2 !important;
  }

  .xl\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce !important;
  }

  .xl\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3 !important;
  }

  .xl\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6 !important;
  }

  .xl\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c !important;
  }

  .xl\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280 !important;
  }

  .xl\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d !important;
  }

  .xl\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459 !important;
  }

  .xl\:focus\:to-primary:focus {
    --gradient-to-color: var(--primary) !important;
  }

  .xl\:focus\:to-accent-1:focus {
    --gradient-to-color: #fafafa !important;
  }

  .xl\:focus\:to-accent-2:focus {
    --gradient-to-color: #eaeaea !important;
  }

  .xl\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .xl\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .xl\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .xl\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .xl\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .xl\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .xl\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .xl\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .xl\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .xl\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .xl\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .xl\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .xl\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .xl\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .xl\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .xl\:bg-bottom {
    background-position: bottom !important;
  }

  .xl\:bg-center {
    background-position: center !important;
  }

  .xl\:bg-left {
    background-position: left !important;
  }

  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .xl\:bg-left-top {
    background-position: left top !important;
  }

  .xl\:bg-right {
    background-position: right !important;
  }

  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .xl\:bg-right-top {
    background-position: right top !important;
  }

  .xl\:bg-top {
    background-position: top !important;
  }

  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }

  .xl\:bg-auto {
    background-size: auto !important;
  }

  .xl\:bg-cover {
    background-size: cover !important;
  }

  .xl\:bg-contain {
    background-size: contain !important;
  }

  .xl\:border-collapse {
    border-collapse: collapse !important;
  }

  .xl\:border-separate {
    border-collapse: separate !important;
  }

  .xl\:border-transparent {
    border-color: transparent !important;
  }

  .xl\:border-current {
    border-color: currentColor !important;
  }

  .xl\:border-black {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xl\:border-white {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xl\:border-gray-100 {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xl\:border-gray-200 {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xl\:border-gray-300 {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xl\:border-gray-400 {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xl\:border-gray-500 {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xl\:border-gray-600 {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xl\:border-gray-700 {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xl\:border-gray-800 {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xl\:border-gray-900 {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xl\:border-red-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xl\:border-red-200 {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xl\:border-red-300 {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xl\:border-red-400 {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xl\:border-red-500 {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xl\:border-red-600 {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xl\:border-red-700 {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xl\:border-red-800 {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xl\:border-red-900 {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xl\:border-orange-100 {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xl\:border-orange-200 {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xl\:border-orange-300 {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xl\:border-orange-400 {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xl\:border-orange-500 {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xl\:border-orange-600 {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xl\:border-orange-700 {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xl\:border-orange-800 {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xl\:border-orange-900 {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-100 {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-200 {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-300 {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-400 {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-500 {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-600 {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-700 {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-800 {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xl\:border-yellow-900 {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xl\:border-green-100 {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xl\:border-green-200 {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xl\:border-green-300 {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xl\:border-green-400 {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xl\:border-green-500 {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xl\:border-green-600 {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xl\:border-green-700 {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xl\:border-green-800 {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xl\:border-green-900 {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xl\:border-teal-100 {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xl\:border-teal-200 {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xl\:border-teal-300 {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xl\:border-teal-400 {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xl\:border-teal-500 {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xl\:border-teal-600 {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xl\:border-teal-700 {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xl\:border-teal-800 {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xl\:border-teal-900 {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xl\:border-blue-100 {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xl\:border-blue-200 {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xl\:border-blue-300 {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xl\:border-blue-400 {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xl\:border-blue-500 {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xl\:border-blue-600 {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xl\:border-blue-700 {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xl\:border-blue-800 {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xl\:border-blue-900 {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-100 {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-200 {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-300 {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-400 {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-500 {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-600 {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-700 {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-800 {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xl\:border-indigo-900 {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xl\:border-purple-100 {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xl\:border-purple-200 {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xl\:border-purple-300 {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xl\:border-purple-400 {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xl\:border-purple-500 {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xl\:border-purple-600 {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xl\:border-purple-700 {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xl\:border-purple-800 {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xl\:border-purple-900 {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xl\:border-pink-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xl\:border-pink-200 {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xl\:border-pink-300 {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xl\:border-pink-400 {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xl\:border-pink-500 {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xl\:border-pink-600 {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xl\:border-pink-700 {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xl\:border-pink-800 {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xl\:border-pink-900 {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xl\:border-primary {
    border-color: var(--primary) !important;
  }

  .xl\:border-accent-1 {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xl\:border-accent-2 {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  .xl\:hover\:border-current:hover {
    border-color: currentColor !important;
  }

  .xl\:hover\:border-black:hover {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-white:hover {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-100:hover {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-200:hover {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-300:hover {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-400:hover {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-500:hover {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-600:hover {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-700:hover {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-800:hover {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-gray-900:hover {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-300:hover {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-400:hover {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-500:hover {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-600:hover {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-700:hover {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-800:hover {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-red-900:hover {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-200:hover {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-600:hover {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-700:hover {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-800:hover {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-orange-900:hover {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-200:hover {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-300:hover {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-500:hover {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-600:hover {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-700:hover {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-800:hover {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-900:hover {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-100:hover {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-200:hover {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-300:hover {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-400:hover {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-500:hover {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-600:hover {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-700:hover {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-800:hover {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-green-900:hover {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-100:hover {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-200:hover {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-300:hover {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-400:hover {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-500:hover {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-600:hover {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-700:hover {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-800:hover {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-teal-900:hover {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-200:hover {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-300:hover {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-400:hover {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-500:hover {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-600:hover {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-700:hover {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-800:hover {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-blue-900:hover {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-200:hover {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-300:hover {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-400:hover {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-500:hover {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-600:hover {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-700:hover {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-800:hover {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-900:hover {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-100:hover {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-200:hover {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-300:hover {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-400:hover {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-500:hover {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-600:hover {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-700:hover {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-800:hover {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-purple-900:hover {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-400:hover {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-600:hover {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-700:hover {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-800:hover {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-pink-900:hover {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-primary:hover {
    border-color: var(--primary) !important;
  }

  .xl\:hover\:border-accent-1:hover {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xl\:hover\:border-accent-2:hover {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-transparent:focus {
    border-color: transparent !important;
  }

  .xl\:focus\:border-current:focus {
    border-color: currentColor !important;
  }

  .xl\:focus\:border-black:focus {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-white:focus {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-100:focus {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-200:focus {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-300:focus {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-400:focus {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-500:focus {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-600:focus {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-700:focus {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-800:focus {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-gray-900:focus {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-300:focus {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-400:focus {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-500:focus {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-600:focus {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-700:focus {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-800:focus {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-red-900:focus {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-200:focus {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-600:focus {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-700:focus {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-800:focus {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-orange-900:focus {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-200:focus {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-300:focus {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-500:focus {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-600:focus {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-700:focus {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-800:focus {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-900:focus {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-100:focus {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-200:focus {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-300:focus {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-400:focus {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-500:focus {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-600:focus {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-700:focus {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-800:focus {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-green-900:focus {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-100:focus {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-200:focus {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-300:focus {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-400:focus {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-500:focus {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-600:focus {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-700:focus {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-800:focus {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-teal-900:focus {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-200:focus {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-300:focus {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-400:focus {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-500:focus {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-600:focus {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-700:focus {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-800:focus {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-blue-900:focus {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-200:focus {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-300:focus {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-400:focus {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-500:focus {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-600:focus {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-700:focus {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-800:focus {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-900:focus {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-100:focus {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-200:focus {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-300:focus {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-400:focus {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-500:focus {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-600:focus {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-700:focus {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-800:focus {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-purple-900:focus {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-400:focus {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-600:focus {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-700:focus {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-800:focus {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-pink-900:focus {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-primary:focus {
    border-color: var(--primary) !important;
  }

  .xl\:focus\:border-accent-1:focus {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xl\:focus\:border-accent-2:focus {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xl\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .xl\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .xl\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .xl\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .xl\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .xl\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .xl\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .xl\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .xl\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .xl\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .xl\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .xl\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .xl\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .xl\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .xl\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .xl\:rounded-none {
    border-radius: 0 !important;
  }

  .xl\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .xl\:rounded {
    border-radius: 0.25rem !important;
  }

  .xl\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .xl\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .xl\:rounded-xl {
    border-radius: 0.75rem !important;
  }

  .xl\:rounded-2xl {
    border-radius: 1rem !important;
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem !important;
  }

  .xl\:rounded-full {
    border-radius: 9999px !important;
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .xl\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem !important;
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem !important;
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem !important;
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:border-solid {
    border-style: solid !important;
  }

  .xl\:border-dashed {
    border-style: dashed !important;
  }

  .xl\:border-dotted {
    border-style: dotted !important;
  }

  .xl\:border-double {
    border-style: double !important;
  }

  .xl\:border-none {
    border-style: none !important;
  }

  .xl\:border-0 {
    border-width: 0 !important;
  }

  .xl\:border-2 {
    border-width: 2px !important;
  }

  .xl\:border-4 {
    border-width: 4px !important;
  }

  .xl\:border-8 {
    border-width: 8px !important;
  }

  .xl\:border {
    border-width: 1px !important;
  }

  .xl\:border-t-0 {
    border-top-width: 0 !important;
  }

  .xl\:border-r-0 {
    border-right-width: 0 !important;
  }

  .xl\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .xl\:border-l-0 {
    border-left-width: 0 !important;
  }

  .xl\:border-t-2 {
    border-top-width: 2px !important;
  }

  .xl\:border-r-2 {
    border-right-width: 2px !important;
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .xl\:border-l-2 {
    border-left-width: 2px !important;
  }

  .xl\:border-t-4 {
    border-top-width: 4px !important;
  }

  .xl\:border-r-4 {
    border-right-width: 4px !important;
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .xl\:border-l-4 {
    border-left-width: 4px !important;
  }

  .xl\:border-t-8 {
    border-top-width: 8px !important;
  }

  .xl\:border-r-8 {
    border-right-width: 8px !important;
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .xl\:border-l-8 {
    border-left-width: 8px !important;
  }

  .xl\:border-t {
    border-top-width: 1px !important;
  }

  .xl\:border-r {
    border-right-width: 1px !important;
  }

  .xl\:border-b {
    border-bottom-width: 1px !important;
  }

  .xl\:border-l {
    border-left-width: 1px !important;
  }

  .xl\:box-border {
    box-sizing: border-box !important;
  }

  .xl\:box-content {
    box-sizing: content-box !important;
  }

  .xl\:cursor-auto {
    cursor: auto !important;
  }

  .xl\:cursor-default {
    cursor: default !important;
  }

  .xl\:cursor-pointer {
    cursor: pointer !important;
  }

  .xl\:cursor-wait {
    cursor: wait !important;
  }

  .xl\:cursor-text {
    cursor: text !important;
  }

  .xl\:cursor-move {
    cursor: move !important;
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .xl\:block {
    display: block !important;
  }

  .xl\:inline-block {
    display: inline-block !important;
  }

  .xl\:inline {
    display: inline !important;
  }

  .xl\:flex {
    display: flex !important;
  }

  .xl\:inline-flex {
    display: inline-flex !important;
  }

  .xl\:table {
    display: table !important;
  }

  .xl\:table-caption {
    display: table-caption !important;
  }

  .xl\:table-cell {
    display: table-cell !important;
  }

  .xl\:table-column {
    display: table-column !important;
  }

  .xl\:table-column-group {
    display: table-column-group !important;
  }

  .xl\:table-footer-group {
    display: table-footer-group !important;
  }

  .xl\:table-header-group {
    display: table-header-group !important;
  }

  .xl\:table-row-group {
    display: table-row-group !important;
  }

  .xl\:table-row {
    display: table-row !important;
  }

  .xl\:flow-root {
    display: flow-root !important;
  }

  .xl\:grid {
    display: grid !important;
  }

  .xl\:inline-grid {
    display: inline-grid !important;
  }

  .xl\:contents {
    display: contents !important;
  }

  .xl\:hidden {
    display: none !important;
  }

  .xl\:flex-row {
    flex-direction: row !important;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xl\:flex-col {
    flex-direction: column !important;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap !important;
  }

  .xl\:place-items-auto {
    place-items: auto !important;
  }

  .xl\:place-items-start {
    place-items: start !important;
  }

  .xl\:place-items-end {
    place-items: end !important;
  }

  .xl\:place-items-center {
    place-items: center !important;
  }

  .xl\:place-items-stretch {
    place-items: stretch !important;
  }

  .xl\:place-content-center {
    place-content: center !important;
  }

  .xl\:place-content-start {
    place-content: start !important;
  }

  .xl\:place-content-end {
    place-content: end !important;
  }

  .xl\:place-content-between {
    place-content: space-between !important;
  }

  .xl\:place-content-around {
    place-content: space-around !important;
  }

  .xl\:place-content-evenly {
    place-content: space-evenly !important;
  }

  .xl\:place-content-stretch {
    place-content: stretch !important;
  }

  .xl\:place-self-auto {
    place-self: auto !important;
  }

  .xl\:place-self-start {
    place-self: start !important;
  }

  .xl\:place-self-end {
    place-self: end !important;
  }

  .xl\:place-self-center {
    place-self: center !important;
  }

  .xl\:place-self-stretch {
    place-self: stretch !important;
  }

  .xl\:items-start {
    align-items: flex-start !important;
  }

  .xl\:items-end {
    align-items: flex-end !important;
  }

  .xl\:items-center {
    align-items: center !important;
  }

  .xl\:items-baseline {
    align-items: baseline !important;
  }

  .xl\:items-stretch {
    align-items: stretch !important;
  }

  .xl\:content-center {
    align-content: center !important;
  }

  .xl\:content-start {
    align-content: flex-start !important;
  }

  .xl\:content-end {
    align-content: flex-end !important;
  }

  .xl\:content-between {
    align-content: space-between !important;
  }

  .xl\:content-around {
    align-content: space-around !important;
  }

  .xl\:content-evenly {
    align-content: space-evenly !important;
  }

  .xl\:self-auto {
    align-self: auto !important;
  }

  .xl\:self-start {
    align-self: flex-start !important;
  }

  .xl\:self-end {
    align-self: flex-end !important;
  }

  .xl\:self-center {
    align-self: center !important;
  }

  .xl\:self-stretch {
    align-self: stretch !important;
  }

  .xl\:justify-items-auto {
    justify-items: auto !important;
  }

  .xl\:justify-items-start {
    justify-items: start !important;
  }

  .xl\:justify-items-end {
    justify-items: end !important;
  }

  .xl\:justify-items-center {
    justify-items: center !important;
  }

  .xl\:justify-items-stretch {
    justify-items: stretch !important;
  }

  .xl\:justify-start {
    justify-content: flex-start !important;
  }

  .xl\:justify-end {
    justify-content: flex-end !important;
  }

  .xl\:justify-center {
    justify-content: center !important;
  }

  .xl\:justify-between {
    justify-content: space-between !important;
  }

  .xl\:justify-around {
    justify-content: space-around !important;
  }

  .xl\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .xl\:justify-self-auto {
    justify-self: auto !important;
  }

  .xl\:justify-self-start {
    justify-self: start !important;
  }

  .xl\:justify-self-end {
    justify-self: end !important;
  }

  .xl\:justify-self-center {
    justify-self: center !important;
  }

  .xl\:justify-self-stretch {
    justify-self: stretch !important;
  }

  .xl\:flex-1 {
    flex: 1 1 0% !important;
  }

  .xl\:flex-auto {
    flex: 1 1 auto !important;
  }

  .xl\:flex-initial {
    flex: 0 1 auto !important;
  }

  .xl\:flex-none {
    flex: none !important;
  }

  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xl\:flex-grow {
    flex-grow: 1 !important;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xl\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .xl\:order-1 {
    order: 1 !important;
  }

  .xl\:order-2 {
    order: 2 !important;
  }

  .xl\:order-3 {
    order: 3 !important;
  }

  .xl\:order-4 {
    order: 4 !important;
  }

  .xl\:order-5 {
    order: 5 !important;
  }

  .xl\:order-6 {
    order: 6 !important;
  }

  .xl\:order-7 {
    order: 7 !important;
  }

  .xl\:order-8 {
    order: 8 !important;
  }

  .xl\:order-9 {
    order: 9 !important;
  }

  .xl\:order-10 {
    order: 10 !important;
  }

  .xl\:order-11 {
    order: 11 !important;
  }

  .xl\:order-12 {
    order: 12 !important;
  }

  .xl\:order-first {
    order: -9999 !important;
  }

  .xl\:order-last {
    order: 9999 !important;
  }

  .xl\:order-none {
    order: 0 !important;
  }

  .xl\:float-right {
    float: right !important;
  }

  .xl\:float-left {
    float: left !important;
  }

  .xl\:float-none {
    float: none !important;
  }

  .xl\:clearfix:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }

  .xl\:clear-left {
    clear: left !important;
  }

  .xl\:clear-right {
    clear: right !important;
  }

  .xl\:clear-both {
    clear: both !important;
  }

  .xl\:clear-none {
    clear: none !important;
  }

  .xl\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .xl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .xl\:font-hairline {
    font-weight: 100 !important;
  }

  .xl\:font-thin {
    font-weight: 200 !important;
  }

  .xl\:font-light {
    font-weight: 300 !important;
  }

  .xl\:font-normal {
    font-weight: 400 !important;
  }

  .xl\:font-medium {
    font-weight: 500 !important;
  }

  .xl\:font-semibold {
    font-weight: 600 !important;
  }

  .xl\:font-bold {
    font-weight: 700 !important;
  }

  .xl\:font-extrabold {
    font-weight: 800 !important;
  }

  .xl\:font-black {
    font-weight: 900 !important;
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100 !important;
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200 !important;
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500 !important;
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600 !important;
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800 !important;
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900 !important;
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100 !important;
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200 !important;
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500 !important;
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600 !important;
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800 !important;
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900 !important;
  }

  .xl\:h-0 {
    height: 0 !important;
  }

  .xl\:h-1 {
    height: 0.25rem !important;
  }

  .xl\:h-2 {
    height: 0.5rem !important;
  }

  .xl\:h-3 {
    height: 0.75rem !important;
  }

  .xl\:h-4 {
    height: 1rem !important;
  }

  .xl\:h-5 {
    height: 1.25rem !important;
  }

  .xl\:h-6 {
    height: 1.5rem !important;
  }

  .xl\:h-8 {
    height: 2rem !important;
  }

  .xl\:h-10 {
    height: 2.5rem !important;
  }

  .xl\:h-12 {
    height: 3rem !important;
  }

  .xl\:h-16 {
    height: 4rem !important;
  }

  .xl\:h-20 {
    height: 5rem !important;
  }

  .xl\:h-24 {
    height: 6rem !important;
  }

  .xl\:h-32 {
    height: 8rem !important;
  }

  .xl\:h-40 {
    height: 10rem !important;
  }

  .xl\:h-48 {
    height: 12rem !important;
  }

  .xl\:h-56 {
    height: 14rem !important;
  }

  .xl\:h-64 {
    height: 16rem !important;
  }

  .xl\:h-auto {
    height: auto !important;
  }

  .xl\:h-px {
    height: 1px !important;
  }

  .xl\:h-full {
    height: 100% !important;
  }

  .xl\:h-screen {
    height: 100vh !important;
  }

  .xl\:text-xs {
    font-size: 0.80em !important;
  }

  .xl\:text-sm {
    font-size: 0.975em !important;
  }

  .xl\:text-base {
    font-size: 1em !important;
  }

  .xl\:text-lg {
    font-size: 1.125em !important;
  }

  .xl\:text-xl {
    font-size: 1.25em !important;
  }

  .xl\:text-2xl {
    font-size: 1.5em !important;
  }

  .xl\:text-3xl {
    font-size: 1.875em !important;
  }

  .xl\:text-4xl {
    font-size: 2.25em !important;
  }

  .xl\:text-5xl {
    font-size: 3em !important;
  }

  .xl\:text-6xl {
    font-size: 4em !important;
  }

  .xl\:text-md {
    font-size: 1em !important;
  }

  .xl\:text-sm-title {
    font-size:  18px !important;
  }

  .xl\:text-md-title {
    font-size: 22px !important;
  }

  .xl\:text-lg-title {
    font-size: 36px !important;
  }

  .xl\:text-size-body {
    font-size: 12px !important;
  }

  .xl\:text-size-label {
    font-size: 10px !important;
  }

  .xl\:leading-3 {
    line-height: .75rem !important;
  }

  .xl\:leading-4 {
    line-height: 1rem !important;
  }

  .xl\:leading-5 {
    line-height: 1.25rem !important;
  }

  .xl\:leading-6 {
    line-height: 1.5rem !important;
  }

  .xl\:leading-7 {
    line-height: 1.75rem !important;
  }

  .xl\:leading-8 {
    line-height: 2rem !important;
  }

  .xl\:leading-9 {
    line-height: 2.25rem !important;
  }

  .xl\:leading-10 {
    line-height: 2.5rem !important;
  }

  .xl\:leading-none {
    line-height: 1 !important;
  }

  .xl\:leading-tight {
    line-height: 1.25 !important;
  }

  .xl\:leading-snug {
    line-height: 1.375 !important;
  }

  .xl\:leading-normal {
    line-height: 1.5 !important;
  }

  .xl\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .xl\:leading-loose {
    line-height: 2 !important;
  }

  .xl\:list-inside {
    list-style-position: inside !important;
  }

  .xl\:list-outside {
    list-style-position: outside !important;
  }

  .xl\:list-none {
    list-style-type: none !important;
  }

  .xl\:list-disc {
    list-style-type: disc !important;
  }

  .xl\:list-decimal {
    list-style-type: decimal !important;
  }

  .xl\:m-0 {
    margin: 0 !important;
  }

  .xl\:m-1 {
    margin: 0.25rem !important;
  }

  .xl\:m-2 {
    margin: 0.5rem !important;
  }

  .xl\:m-3 {
    margin: 0.75rem !important;
  }

  .xl\:m-4 {
    margin: 1rem !important;
  }

  .xl\:m-5 {
    margin: 1.25rem !important;
  }

  .xl\:m-6 {
    margin: 1.5rem !important;
  }

  .xl\:m-8 {
    margin: 2rem !important;
  }

  .xl\:m-10 {
    margin: 2.5rem !important;
  }

  .xl\:m-12 {
    margin: 3rem !important;
  }

  .xl\:m-16 {
    margin: 4rem !important;
  }

  .xl\:m-20 {
    margin: 5rem !important;
  }

  .xl\:m-24 {
    margin: 6rem !important;
  }

  .xl\:m-32 {
    margin: 8rem !important;
  }

  .xl\:m-40 {
    margin: 10rem !important;
  }

  .xl\:m-48 {
    margin: 12rem !important;
  }

  .xl\:m-56 {
    margin: 14rem !important;
  }

  .xl\:m-64 {
    margin: 16rem !important;
  }

  .xl\:m-auto {
    margin: auto !important;
  }

  .xl\:m-px {
    margin: 1px !important;
  }

  .xl\:-m-1 {
    margin: -0.25rem !important;
  }

  .xl\:-m-2 {
    margin: -0.5rem !important;
  }

  .xl\:-m-3 {
    margin: -0.75rem !important;
  }

  .xl\:-m-4 {
    margin: -1rem !important;
  }

  .xl\:-m-5 {
    margin: -1.25rem !important;
  }

  .xl\:-m-6 {
    margin: -1.5rem !important;
  }

  .xl\:-m-8 {
    margin: -2rem !important;
  }

  .xl\:-m-10 {
    margin: -2.5rem !important;
  }

  .xl\:-m-12 {
    margin: -3rem !important;
  }

  .xl\:-m-16 {
    margin: -4rem !important;
  }

  .xl\:-m-20 {
    margin: -5rem !important;
  }

  .xl\:-m-24 {
    margin: -6rem !important;
  }

  .xl\:-m-32 {
    margin: -8rem !important;
  }

  .xl\:-m-40 {
    margin: -10rem !important;
  }

  .xl\:-m-48 {
    margin: -12rem !important;
  }

  .xl\:-m-56 {
    margin: -14rem !important;
  }

  .xl\:-m-64 {
    margin: -16rem !important;
  }

  .xl\:-m-px {
    margin: -1px !important;
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xl\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xl\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xl\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xl\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xl\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xl\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xl\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xl\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xl\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xl\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xl\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xl\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xl\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xl\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xl\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xl\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xl\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xl\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xl\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xl\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xl\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xl\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xl\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xl\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xl\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xl\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xl\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xl\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xl\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xl\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xl\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xl\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xl\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xl\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xl\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xl\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xl\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xl\:mt-0 {
    margin-top: 0 !important;
  }

  .xl\:mr-0 {
    margin-right: 0 !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xl\:ml-0 {
    margin-left: 0 !important;
  }

  .xl\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xl\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xl\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xl\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xl\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xl\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xl\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .xl\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .xl\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .xl\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .xl\:mt-4 {
    margin-top: 1rem !important;
  }

  .xl\:mr-4 {
    margin-right: 1rem !important;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .xl\:ml-4 {
    margin-left: 1rem !important;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .xl\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .xl\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .xl\:mt-8 {
    margin-top: 2rem !important;
  }

  .xl\:mr-8 {
    margin-right: 2rem !important;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .xl\:ml-8 {
    margin-left: 2rem !important;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .xl\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .xl\:mt-12 {
    margin-top: 3rem !important;
  }

  .xl\:mr-12 {
    margin-right: 3rem !important;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .xl\:ml-12 {
    margin-left: 3rem !important;
  }

  .xl\:mt-16 {
    margin-top: 4rem !important;
  }

  .xl\:mr-16 {
    margin-right: 4rem !important;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .xl\:ml-16 {
    margin-left: 4rem !important;
  }

  .xl\:mt-20 {
    margin-top: 5rem !important;
  }

  .xl\:mr-20 {
    margin-right: 5rem !important;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .xl\:ml-20 {
    margin-left: 5rem !important;
  }

  .xl\:mt-24 {
    margin-top: 6rem !important;
  }

  .xl\:mr-24 {
    margin-right: 6rem !important;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .xl\:ml-24 {
    margin-left: 6rem !important;
  }

  .xl\:mt-32 {
    margin-top: 8rem !important;
  }

  .xl\:mr-32 {
    margin-right: 8rem !important;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .xl\:ml-32 {
    margin-left: 8rem !important;
  }

  .xl\:mt-40 {
    margin-top: 10rem !important;
  }

  .xl\:mr-40 {
    margin-right: 10rem !important;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .xl\:ml-40 {
    margin-left: 10rem !important;
  }

  .xl\:mt-48 {
    margin-top: 12rem !important;
  }

  .xl\:mr-48 {
    margin-right: 12rem !important;
  }

  .xl\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .xl\:ml-48 {
    margin-left: 12rem !important;
  }

  .xl\:mt-56 {
    margin-top: 14rem !important;
  }

  .xl\:mr-56 {
    margin-right: 14rem !important;
  }

  .xl\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .xl\:ml-56 {
    margin-left: 14rem !important;
  }

  .xl\:mt-64 {
    margin-top: 16rem !important;
  }

  .xl\:mr-64 {
    margin-right: 16rem !important;
  }

  .xl\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .xl\:ml-64 {
    margin-left: 16rem !important;
  }

  .xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:mr-auto {
    margin-right: auto !important;
  }

  .xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:ml-auto {
    margin-left: auto !important;
  }

  .xl\:mt-px {
    margin-top: 1px !important;
  }

  .xl\:mr-px {
    margin-right: 1px !important;
  }

  .xl\:mb-px {
    margin-bottom: 1px !important;
  }

  .xl\:ml-px {
    margin-left: 1px !important;
  }

  .xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .xl\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .xl\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .xl\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .xl\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .xl\:-mt-4 {
    margin-top: -1rem !important;
  }

  .xl\:-mr-4 {
    margin-right: -1rem !important;
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .xl\:-ml-4 {
    margin-left: -1rem !important;
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .xl\:-mt-8 {
    margin-top: -2rem !important;
  }

  .xl\:-mr-8 {
    margin-right: -2rem !important;
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .xl\:-ml-8 {
    margin-left: -2rem !important;
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .xl\:-mt-12 {
    margin-top: -3rem !important;
  }

  .xl\:-mr-12 {
    margin-right: -3rem !important;
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .xl\:-ml-12 {
    margin-left: -3rem !important;
  }

  .xl\:-mt-16 {
    margin-top: -4rem !important;
  }

  .xl\:-mr-16 {
    margin-right: -4rem !important;
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .xl\:-ml-16 {
    margin-left: -4rem !important;
  }

  .xl\:-mt-20 {
    margin-top: -5rem !important;
  }

  .xl\:-mr-20 {
    margin-right: -5rem !important;
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .xl\:-ml-20 {
    margin-left: -5rem !important;
  }

  .xl\:-mt-24 {
    margin-top: -6rem !important;
  }

  .xl\:-mr-24 {
    margin-right: -6rem !important;
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .xl\:-ml-24 {
    margin-left: -6rem !important;
  }

  .xl\:-mt-32 {
    margin-top: -8rem !important;
  }

  .xl\:-mr-32 {
    margin-right: -8rem !important;
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .xl\:-ml-32 {
    margin-left: -8rem !important;
  }

  .xl\:-mt-40 {
    margin-top: -10rem !important;
  }

  .xl\:-mr-40 {
    margin-right: -10rem !important;
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .xl\:-ml-40 {
    margin-left: -10rem !important;
  }

  .xl\:-mt-48 {
    margin-top: -12rem !important;
  }

  .xl\:-mr-48 {
    margin-right: -12rem !important;
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .xl\:-ml-48 {
    margin-left: -12rem !important;
  }

  .xl\:-mt-56 {
    margin-top: -14rem !important;
  }

  .xl\:-mr-56 {
    margin-right: -14rem !important;
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .xl\:-ml-56 {
    margin-left: -14rem !important;
  }

  .xl\:-mt-64 {
    margin-top: -16rem !important;
  }

  .xl\:-mr-64 {
    margin-right: -16rem !important;
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .xl\:-ml-64 {
    margin-left: -16rem !important;
  }

  .xl\:-mt-px {
    margin-top: -1px !important;
  }

  .xl\:-mr-px {
    margin-right: -1px !important;
  }

  .xl\:-mb-px {
    margin-bottom: -1px !important;
  }

  .xl\:-ml-px {
    margin-left: -1px !important;
  }

  .xl\:max-h-full {
    max-height: 100% !important;
  }

  .xl\:max-h-screen {
    max-height: 100vh !important;
  }

  .xl\:max-w-0 {
    max-width: 0 !important;
  }

  .xl\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .xl\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .xl\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .xl\:max-w-4 {
    max-width: 1rem !important;
  }

  .xl\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .xl\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .xl\:max-w-8 {
    max-width: 2rem !important;
  }

  .xl\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .xl\:max-w-12 {
    max-width: 3rem !important;
  }

  .xl\:max-w-16 {
    max-width: 4rem !important;
  }

  .xl\:max-w-20 {
    max-width: 5rem !important;
  }

  .xl\:max-w-24 {
    max-width: 6rem !important;
  }

  .xl\:max-w-32 {
    max-width: 8rem !important;
  }

  .xl\:max-w-40 {
    max-width: 10rem !important;
  }

  .xl\:max-w-48 {
    max-width: 12rem !important;
  }

  .xl\:max-w-56 {
    max-width: 14rem !important;
  }

  .xl\:max-w-64 {
    max-width: 16rem !important;
  }

  .xl\:max-w-none {
    max-width: none !important;
  }

  .xl\:max-w-xs {
    max-width: 20rem !important;
  }

  .xl\:max-w-sm {
    max-width: 24rem !important;
  }

  .xl\:max-w-md {
    max-width: 28rem !important;
  }

  .xl\:max-w-lg {
    max-width: 32rem !important;
  }

  .xl\:max-w-xl {
    max-width: 36rem !important;
  }

  .xl\:max-w-2xl {
    max-width: 42rem !important;
  }

  .xl\:max-w-3xl {
    max-width: 48rem !important;
  }

  .xl\:max-w-4xl {
    max-width: 56rem !important;
  }

  .xl\:max-w-5xl {
    max-width: 64rem !important;
  }

  .xl\:max-w-6xl {
    max-width: 72rem !important;
  }

  .xl\:max-w-full {
    max-width: 100% !important;
  }

  .xl\:max-w-screen-xs {
    max-width: 480px !important;
  }

  .xl\:max-w-screen-sm {
    max-width: 576px !important;
  }

  .xl\:max-w-screen-md {
    max-width: 768px !important;
  }

  .xl\:max-w-screen-lg {
    max-width: 992px !important;
  }

  .xl\:max-w-screen-xl {
    max-width: 1200px !important;
  }

  .xl\:max-w-screen-xxl {
    max-width: 1600px !important;
  }

  .xl\:max-w-screen {
    max-width: 100vw !important;
  }

  .xl\:max-w-px {
    max-width: 1px !important;
  }

  .xl\:min-h-0 {
    min-height: 0 !important;
  }

  .xl\:min-h-full {
    min-height: 100% !important;
  }

  .xl\:min-h-screen {
    min-height: 100vh !important;
  }

  .xl\:min-w-0 {
    min-width: 0 !important;
  }

  .xl\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .xl\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .xl\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .xl\:min-w-4 {
    min-width: 1rem !important;
  }

  .xl\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .xl\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .xl\:min-w-8 {
    min-width: 2rem !important;
  }

  .xl\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .xl\:min-w-12 {
    min-width: 3rem !important;
  }

  .xl\:min-w-16 {
    min-width: 4rem !important;
  }

  .xl\:min-w-20 {
    min-width: 5rem !important;
  }

  .xl\:min-w-24 {
    min-width: 6rem !important;
  }

  .xl\:min-w-32 {
    min-width: 8rem !important;
  }

  .xl\:min-w-40 {
    min-width: 10rem !important;
  }

  .xl\:min-w-48 {
    min-width: 12rem !important;
  }

  .xl\:min-w-56 {
    min-width: 14rem !important;
  }

  .xl\:min-w-64 {
    min-width: 16rem !important;
  }

  .xl\:min-w-full {
    min-width: 100% !important;
  }

  .xl\:min-w-screen {
    min-width: 100vw !important;
  }

  .xl\:min-w-px {
    min-width: 1px !important;
  }

  .xl\:min-w-screen-xs {
    min-width: 480px !important;
  }

  .xl\:min-w-screen-sm {
    min-width: 576px !important;
  }

  .xl\:min-w-screen-md {
    min-width: 768px !important;
  }

  .xl\:min-w-screen-lg {
    min-width: 992px !important;
  }

  .xl\:min-w-screen-xl {
    min-width: 1200px !important;
  }

  .xl\:min-w-screen-xxl {
    min-width: 1600px !important;
  }

  .xl\:object-contain {
    object-fit: contain !important;
  }

  .xl\:object-cover {
    object-fit: cover !important;
  }

  .xl\:object-fill {
    object-fit: fill !important;
  }

  .xl\:object-none {
    object-fit: none !important;
  }

  .xl\:object-scale-down {
    object-fit: scale-down !important;
  }

  .xl\:object-bottom {
    object-position: bottom !important;
  }

  .xl\:object-center {
    object-position: center !important;
  }

  .xl\:object-left {
    object-position: left !important;
  }

  .xl\:object-left-bottom {
    object-position: left bottom !important;
  }

  .xl\:object-left-top {
    object-position: left top !important;
  }

  .xl\:object-right {
    object-position: right !important;
  }

  .xl\:object-right-bottom {
    object-position: right bottom !important;
  }

  .xl\:object-right-top {
    object-position: right top !important;
  }

  .xl\:object-top {
    object-position: top !important;
  }

  .xl\:opacity-0 {
    opacity: 0 !important;
  }

  .xl\:opacity-25 {
    opacity: 0.25 !important;
  }

  .xl\:opacity-50 {
    opacity: 0.5 !important;
  }

  .xl\:opacity-75 {
    opacity: 0.75 !important;
  }

  .xl\:opacity-100 {
    opacity: 1 !important;
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .xl\:overflow-auto {
    overflow: auto !important;
  }

  .xl\:overflow-hidden {
    overflow: hidden !important;
  }

  .xl\:overflow-visible {
    overflow: visible !important;
  }

  .xl\:overflow-scroll {
    overflow: scroll !important;
  }

  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto !important;
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain !important;
  }

  .xl\:overscroll-none {
    overscroll-behavior: none !important;
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto !important;
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain !important;
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none !important;
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto !important;
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain !important;
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none !important;
  }

  .xl\:p-0 {
    padding: 0 !important;
  }

  .xl\:p-1 {
    padding: 0.25rem !important;
  }

  .xl\:p-2 {
    padding: 0.5rem !important;
  }

  .xl\:p-3 {
    padding: 0.75rem !important;
  }

  .xl\:p-4 {
    padding: 1rem !important;
  }

  .xl\:p-5 {
    padding: 1.25rem !important;
  }

  .xl\:p-6 {
    padding: 1.5rem !important;
  }

  .xl\:p-8 {
    padding: 2rem !important;
  }

  .xl\:p-10 {
    padding: 2.5rem !important;
  }

  .xl\:p-12 {
    padding: 3rem !important;
  }

  .xl\:p-16 {
    padding: 4rem !important;
  }

  .xl\:p-20 {
    padding: 5rem !important;
  }

  .xl\:p-24 {
    padding: 6rem !important;
  }

  .xl\:p-32 {
    padding: 8rem !important;
  }

  .xl\:p-40 {
    padding: 10rem !important;
  }

  .xl\:p-48 {
    padding: 12rem !important;
  }

  .xl\:p-56 {
    padding: 14rem !important;
  }

  .xl\:p-64 {
    padding: 16rem !important;
  }

  .xl\:p-px {
    padding: 1px !important;
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xl\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xl\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xl\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xl\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xl\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xl\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xl\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xl\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xl\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xl\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xl\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xl\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xl\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xl\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xl\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xl\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xl\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xl\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xl\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xl\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xl\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xl\:pt-0 {
    padding-top: 0 !important;
  }

  .xl\:pr-0 {
    padding-right: 0 !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xl\:pl-0 {
    padding-left: 0 !important;
  }

  .xl\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xl\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xl\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xl\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xl\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xl\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xl\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .xl\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .xl\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .xl\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .xl\:pt-4 {
    padding-top: 1rem !important;
  }

  .xl\:pr-4 {
    padding-right: 1rem !important;
  }

  .xl\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .xl\:pl-4 {
    padding-left: 1rem !important;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .xl\:pt-8 {
    padding-top: 2rem !important;
  }

  .xl\:pr-8 {
    padding-right: 2rem !important;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .xl\:pl-8 {
    padding-left: 2rem !important;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .xl\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .xl\:pt-12 {
    padding-top: 3rem !important;
  }

  .xl\:pr-12 {
    padding-right: 3rem !important;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .xl\:pl-12 {
    padding-left: 3rem !important;
  }

  .xl\:pt-16 {
    padding-top: 4rem !important;
  }

  .xl\:pr-16 {
    padding-right: 4rem !important;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .xl\:pl-16 {
    padding-left: 4rem !important;
  }

  .xl\:pt-20 {
    padding-top: 5rem !important;
  }

  .xl\:pr-20 {
    padding-right: 5rem !important;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .xl\:pl-20 {
    padding-left: 5rem !important;
  }

  .xl\:pt-24 {
    padding-top: 6rem !important;
  }

  .xl\:pr-24 {
    padding-right: 6rem !important;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .xl\:pl-24 {
    padding-left: 6rem !important;
  }

  .xl\:pt-32 {
    padding-top: 8rem !important;
  }

  .xl\:pr-32 {
    padding-right: 8rem !important;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .xl\:pl-32 {
    padding-left: 8rem !important;
  }

  .xl\:pt-40 {
    padding-top: 10rem !important;
  }

  .xl\:pr-40 {
    padding-right: 10rem !important;
  }

  .xl\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .xl\:pl-40 {
    padding-left: 10rem !important;
  }

  .xl\:pt-48 {
    padding-top: 12rem !important;
  }

  .xl\:pr-48 {
    padding-right: 12rem !important;
  }

  .xl\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .xl\:pl-48 {
    padding-left: 12rem !important;
  }

  .xl\:pt-56 {
    padding-top: 14rem !important;
  }

  .xl\:pr-56 {
    padding-right: 14rem !important;
  }

  .xl\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .xl\:pl-56 {
    padding-left: 14rem !important;
  }

  .xl\:pt-64 {
    padding-top: 16rem !important;
  }

  .xl\:pr-64 {
    padding-right: 16rem !important;
  }

  .xl\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .xl\:pl-64 {
    padding-left: 16rem !important;
  }

  .xl\:pt-px {
    padding-top: 1px !important;
  }

  .xl\:pr-px {
    padding-right: 1px !important;
  }

  .xl\:pb-px {
    padding-bottom: 1px !important;
  }

  .xl\:pl-px {
    padding-left: 1px !important;
  }

  .xl\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent !important;
  }

  .xl\:placeholder-transparent::-ms-input-placeholder {
    color: transparent !important;
  }

  .xl\:placeholder-transparent::placeholder {
    color: transparent !important;
  }

  .xl\:placeholder-current::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .xl\:placeholder-current::-ms-input-placeholder {
    color: currentColor !important;
  }

  .xl\:placeholder-current::placeholder {
    color: currentColor !important;
  }

  .xl\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-black::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-white::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .xl\:placeholder-primary::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .xl\:placeholder-primary::placeholder {
    color: var(--primary) !important;
  }

  .xl\:placeholder-accent-1::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-accent-1::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-accent-1::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-accent-2::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-accent-2::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-accent-2::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  .xl\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent !important;
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent !important;
  }

  .xl\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .xl\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor !important;
  }

  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor !important;
  }

  .xl\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .xl\:focus\:placeholder-primary:focus::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .xl\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary) !important;
  }

  .xl\:focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-accent-1:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-accent-1:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-accent-2:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-accent-2:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xl\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:pointer-events-none {
    pointer-events: none !important;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .xl\:static {
    position: static !important;
  }

  .xl\:fixed {
    position: fixed !important;
  }

  .xl\:absolute {
    position: absolute !important;
  }

  .xl\:relative {
    position: relative !important;
  }

  .xl\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .xl\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xl\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xl\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .xl\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .xl\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .xl\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .xl\:top-0 {
    top: 0 !important;
  }

  .xl\:right-0 {
    right: 0 !important;
  }

  .xl\:bottom-0 {
    bottom: 0 !important;
  }

  .xl\:left-0 {
    left: 0 !important;
  }

  .xl\:top-auto {
    top: auto !important;
  }

  .xl\:right-auto {
    right: auto !important;
  }

  .xl\:bottom-auto {
    bottom: auto !important;
  }

  .xl\:left-auto {
    left: auto !important;
  }

  .xl\:resize-none {
    resize: none !important;
  }

  .xl\:resize-y {
    resize: vertical !important;
  }

  .xl\:resize-x {
    resize: horizontal !important;
  }

  .xl\:resize {
    resize: both !important;
  }

  .xl\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xl\:shadow-none {
    box-shadow: none !important;
  }

  .xl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .xl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .xl\:fill-current {
    fill: currentColor !important;
  }

  .xl\:stroke-current {
    stroke: currentColor !important;
  }

  .xl\:stroke-0 {
    stroke-width: 0 !important;
  }

  .xl\:stroke-1 {
    stroke-width: 1 !important;
  }

  .xl\:stroke-2 {
    stroke-width: 2 !important;
  }

  .xl\:table-auto {
    table-layout: auto !important;
  }

  .xl\:table-fixed {
    table-layout: fixed !important;
  }

  .xl\:text-left {
    text-align: left !important;
  }

  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }

  .xl\:text-justify {
    text-align: justify !important;
  }

  .xl\:text-transparent {
    color: transparent !important;
  }

  .xl\:text-current {
    color: currentColor !important;
  }

  .xl\:text-black {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xl\:text-white {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xl\:text-gray-100 {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xl\:text-gray-200 {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xl\:text-gray-300 {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xl\:text-gray-400 {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xl\:text-gray-500 {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xl\:text-gray-600 {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xl\:text-gray-700 {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xl\:text-gray-800 {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xl\:text-gray-900 {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xl\:text-red-100 {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xl\:text-red-200 {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xl\:text-red-300 {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xl\:text-red-400 {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xl\:text-red-500 {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xl\:text-red-600 {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xl\:text-red-700 {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xl\:text-red-800 {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xl\:text-red-900 {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xl\:text-orange-100 {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xl\:text-orange-200 {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xl\:text-orange-300 {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xl\:text-orange-400 {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xl\:text-orange-500 {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xl\:text-orange-600 {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xl\:text-orange-700 {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xl\:text-orange-800 {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xl\:text-orange-900 {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-100 {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-200 {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-300 {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-400 {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-500 {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-600 {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-700 {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-800 {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xl\:text-yellow-900 {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xl\:text-green-100 {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xl\:text-green-200 {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xl\:text-green-300 {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xl\:text-green-400 {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xl\:text-green-500 {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xl\:text-green-600 {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xl\:text-green-700 {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xl\:text-green-800 {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xl\:text-green-900 {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xl\:text-teal-100 {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xl\:text-teal-200 {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xl\:text-teal-300 {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xl\:text-teal-400 {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xl\:text-teal-500 {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xl\:text-teal-600 {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xl\:text-teal-700 {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xl\:text-teal-800 {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xl\:text-teal-900 {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xl\:text-blue-100 {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xl\:text-blue-200 {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xl\:text-blue-300 {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xl\:text-blue-400 {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xl\:text-blue-500 {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xl\:text-blue-600 {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xl\:text-blue-700 {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xl\:text-blue-800 {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xl\:text-blue-900 {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-100 {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-200 {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-300 {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-400 {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-500 {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-600 {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-700 {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-800 {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xl\:text-indigo-900 {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xl\:text-purple-100 {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xl\:text-purple-200 {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xl\:text-purple-300 {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xl\:text-purple-400 {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xl\:text-purple-500 {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xl\:text-purple-600 {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xl\:text-purple-700 {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xl\:text-purple-800 {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xl\:text-purple-900 {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xl\:text-pink-100 {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xl\:text-pink-200 {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xl\:text-pink-300 {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xl\:text-pink-400 {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xl\:text-pink-500 {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xl\:text-pink-600 {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xl\:text-pink-700 {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xl\:text-pink-800 {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xl\:text-pink-900 {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xl\:text-primary {
    color: var(--primary) !important;
  }

  .xl\:text-accent-1 {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xl\:text-accent-2 {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-transparent:hover {
    color: transparent !important;
  }

  .xl\:hover\:text-current:hover {
    color: currentColor !important;
  }

  .xl\:hover\:text-black:hover {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-white:hover {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-100:hover {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-200:hover {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-300:hover {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-400:hover {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-500:hover {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-600:hover {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-700:hover {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-800:hover {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-gray-900:hover {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-200:hover {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-300:hover {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-400:hover {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-500:hover {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-600:hover {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-700:hover {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-800:hover {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-red-900:hover {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-100:hover {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-200:hover {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-300:hover {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-400:hover {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-500:hover {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-600:hover {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-700:hover {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-800:hover {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-orange-900:hover {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-100:hover {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-200:hover {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-300:hover {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-400:hover {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-500:hover {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-600:hover {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-700:hover {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-800:hover {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-900:hover {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-100:hover {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-200:hover {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-300:hover {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-400:hover {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-500:hover {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-600:hover {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-700:hover {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-800:hover {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-green-900:hover {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-100:hover {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-200:hover {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-300:hover {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-400:hover {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-500:hover {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-600:hover {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-700:hover {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-800:hover {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-teal-900:hover {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-100:hover {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-200:hover {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-300:hover {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-400:hover {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-500:hover {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-600:hover {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-700:hover {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-800:hover {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-blue-900:hover {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-100:hover {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-200:hover {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-300:hover {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-400:hover {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-500:hover {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-600:hover {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-700:hover {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-800:hover {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-900:hover {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-100:hover {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-200:hover {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-300:hover {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-400:hover {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-500:hover {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-600:hover {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-700:hover {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-800:hover {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-purple-900:hover {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-200:hover {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-300:hover {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-400:hover {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-500:hover {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-600:hover {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-700:hover {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-800:hover {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-pink-900:hover {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-primary:hover {
    color: var(--primary) !important;
  }

  .xl\:hover\:text-accent-1:hover {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xl\:hover\:text-accent-2:hover {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-transparent:focus {
    color: transparent !important;
  }

  .xl\:focus\:text-current:focus {
    color: currentColor !important;
  }

  .xl\:focus\:text-black:focus {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-white:focus {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-100:focus {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-200:focus {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-300:focus {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-400:focus {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-500:focus {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-600:focus {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-700:focus {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-800:focus {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-gray-900:focus {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-200:focus {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-300:focus {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-400:focus {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-500:focus {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-600:focus {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-700:focus {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-800:focus {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-red-900:focus {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-100:focus {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-200:focus {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-300:focus {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-400:focus {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-500:focus {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-600:focus {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-700:focus {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-800:focus {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-orange-900:focus {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-100:focus {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-200:focus {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-300:focus {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-400:focus {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-500:focus {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-600:focus {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-700:focus {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-800:focus {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-900:focus {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-100:focus {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-200:focus {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-300:focus {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-400:focus {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-500:focus {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-600:focus {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-700:focus {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-800:focus {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-green-900:focus {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-100:focus {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-200:focus {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-300:focus {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-400:focus {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-500:focus {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-600:focus {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-700:focus {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-800:focus {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-teal-900:focus {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-100:focus {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-200:focus {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-300:focus {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-400:focus {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-500:focus {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-600:focus {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-700:focus {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-800:focus {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-blue-900:focus {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-100:focus {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-200:focus {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-300:focus {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-400:focus {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-500:focus {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-600:focus {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-700:focus {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-800:focus {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-900:focus {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-100:focus {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-200:focus {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-300:focus {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-400:focus {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-500:focus {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-600:focus {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-700:focus {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-800:focus {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-purple-900:focus {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-200:focus {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-300:focus {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-400:focus {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-500:focus {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-600:focus {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-700:focus {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-800:focus {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-pink-900:focus {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-primary:focus {
    color: var(--primary) !important;
  }

  .xl\:focus\:text-accent-1:focus {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xl\:focus\:text-accent-2:focus {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xl\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .xl\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .xl\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .xl\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .xl\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .xl\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .xl\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .xl\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .xl\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .xl\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .xl\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .xl\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .xl\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .xl\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .xl\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .xl\:italic {
    font-style: italic !important;
  }

  .xl\:not-italic {
    font-style: normal !important;
  }

  .xl\:uppercase {
    text-transform: uppercase !important;
  }

  .xl\:lowercase {
    text-transform: lowercase !important;
  }

  .xl\:capitalize {
    text-transform: capitalize !important;
  }

  .xl\:normal-case {
    text-transform: none !important;
  }

  .xl\:underline {
    text-decoration: underline !important;
  }

  .xl\:line-through {
    text-decoration: line-through !important;
  }

  .xl\:no-underline {
    text-decoration: none !important;
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
  }

  .xl\:normal-nums {
    font-variant-numeric: normal !important;
  }

  .xl\:ordinal {
    --font-variant-numeric-ordinal: ordinal !important;
  }

  .xl\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero !important;
  }

  .xl\:lining-nums {
    --font-variant-numeric-figure: lining-nums !important;
  }

  .xl\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums !important;
  }

  .xl\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums !important;
  }

  .xl\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums !important;
  }

  .xl\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions !important;
  }

  .xl\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions !important;
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .xl\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .xl\:select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .xl\:select-text {
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .xl\:select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .xl\:select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .xl\:align-baseline {
    vertical-align: baseline !important;
  }

  .xl\:align-top {
    vertical-align: top !important;
  }

  .xl\:align-middle {
    vertical-align: middle !important;
  }

  .xl\:align-bottom {
    vertical-align: bottom !important;
  }

  .xl\:align-text-top {
    vertical-align: text-top !important;
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xl\:visible {
    visibility: visible !important;
  }

  .xl\:invisible {
    visibility: hidden !important;
  }

  .xl\:whitespace-normal {
    white-space: normal !important;
  }

  .xl\:whitespace-no-wrap {
    white-space: nowrap !important;
  }

  .xl\:whitespace-pre {
    white-space: pre !important;
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .xl\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xl\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  .xl\:break-all {
    word-break: break-all !important;
  }

  .xl\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xl\:w-0 {
    width: 0 !important;
  }

  .xl\:w-1 {
    width: 0.25rem !important;
  }

  .xl\:w-2 {
    width: 0.5rem !important;
  }

  .xl\:w-3 {
    width: 0.75rem !important;
  }

  .xl\:w-4 {
    width: 1rem !important;
  }

  .xl\:w-5 {
    width: 1.25rem !important;
  }

  .xl\:w-6 {
    width: 1.5rem !important;
  }

  .xl\:w-8 {
    width: 2rem !important;
  }

  .xl\:w-10 {
    width: 2.5rem !important;
  }

  .xl\:w-12 {
    width: 3rem !important;
  }

  .xl\:w-16 {
    width: 4rem !important;
  }

  .xl\:w-20 {
    width: 5rem !important;
  }

  .xl\:w-24 {
    width: 6rem !important;
  }

  .xl\:w-32 {
    width: 8rem !important;
  }

  .xl\:w-40 {
    width: 10rem !important;
  }

  .xl\:w-48 {
    width: 12rem !important;
  }

  .xl\:w-56 {
    width: 14rem !important;
  }

  .xl\:w-64 {
    width: 16rem !important;
  }

  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-px {
    width: 1px !important;
  }

  .xl\:w-1\/2 {
    width: 50% !important;
  }

  .xl\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xl\:w-2\/3 {
    width: 66.666667% !important;
  }

  .xl\:w-1\/4 {
    width: 25% !important;
  }

  .xl\:w-2\/4 {
    width: 50% !important;
  }

  .xl\:w-3\/4 {
    width: 75% !important;
  }

  .xl\:w-1\/5 {
    width: 20% !important;
  }

  .xl\:w-2\/5 {
    width: 40% !important;
  }

  .xl\:w-3\/5 {
    width: 60% !important;
  }

  .xl\:w-4\/5 {
    width: 80% !important;
  }

  .xl\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xl\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xl\:w-3\/6 {
    width: 50% !important;
  }

  .xl\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xl\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xl\:w-1\/12 {
    width: 8.333333% !important;
  }

  .xl\:w-2\/12 {
    width: 16.666667% !important;
  }

  .xl\:w-3\/12 {
    width: 25% !important;
  }

  .xl\:w-4\/12 {
    width: 33.333333% !important;
  }

  .xl\:w-5\/12 {
    width: 41.666667% !important;
  }

  .xl\:w-6\/12 {
    width: 50% !important;
  }

  .xl\:w-7\/12 {
    width: 58.333333% !important;
  }

  .xl\:w-8\/12 {
    width: 66.666667% !important;
  }

  .xl\:w-9\/12 {
    width: 75% !important;
  }

  .xl\:w-10\/12 {
    width: 83.333333% !important;
  }

  .xl\:w-11\/12 {
    width: 91.666667% !important;
  }

  .xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-screen {
    width: 100vw !important;
  }

  .xl\:w-none {
    width: none !important;
  }

  .xl\:w-xs {
    width: 20rem !important;
  }

  .xl\:w-sm {
    width: 24rem !important;
  }

  .xl\:w-md {
    width: 28rem !important;
  }

  .xl\:w-lg {
    width: 32rem !important;
  }

  .xl\:w-xl {
    width: 36rem !important;
  }

  .xl\:w-2xl {
    width: 42rem !important;
  }

  .xl\:w-3xl {
    width: 48rem !important;
  }

  .xl\:w-4xl {
    width: 56rem !important;
  }

  .xl\:w-5xl {
    width: 64rem !important;
  }

  .xl\:w-6xl {
    width: 72rem !important;
  }

  .xl\:w-screen-xs {
    width: 480px !important;
  }

  .xl\:w-screen-sm {
    width: 576px !important;
  }

  .xl\:w-screen-md {
    width: 768px !important;
  }

  .xl\:w-screen-lg {
    width: 992px !important;
  }

  .xl\:w-screen-xl {
    width: 1200px !important;
  }

  .xl\:w-screen-xxl {
    width: 1600px !important;
  }

  .xl\:w-sidebar {
    width: 15rem !important;
  }

  .xl\:z-0 {
    z-index: 0 !important;
  }

  .xl\:z-10 {
    z-index: 10 !important;
  }

  .xl\:z-20 {
    z-index: 20 !important;
  }

  .xl\:z-30 {
    z-index: 30 !important;
  }

  .xl\:z-40 {
    z-index: 40 !important;
  }

  .xl\:z-50 {
    z-index: 50 !important;
  }

  .xl\:z-auto {
    z-index: auto !important;
  }

  .xl\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .xl\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .xl\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .xl\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .xl\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .xl\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .xl\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .xl\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .xl\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .xl\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .xl\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .xl\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .xl\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .xl\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .xl\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .xl\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .xl\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .xl\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .xl\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .xl\:gap-x-0 {
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }

  .xl\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .xl\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .xl\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .xl\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .xl\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .xl\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .xl\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .xl\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .xl\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .xl\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .xl\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .xl\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .xl\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .xl\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .xl\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .xl\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .xl\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .xl\:gap-x-px {
    grid-column-gap: 1px !important;
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .xl\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .xl\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .xl\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .xl\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .xl\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .xl\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .xl\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .xl\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .xl\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .xl\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .xl\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .xl\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .xl\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .xl\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .xl\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .xl\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .xl\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .xl\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .xl\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto !important;
  }

  .xl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .xl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .xl\:col-auto {
    grid-column: auto !important;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1 !important;
  }

  .xl\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .xl\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .xl\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .xl\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .xl\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .xl\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .xl\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .xl\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .xl\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .xl\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .xl\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .xl\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .xl\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .xl\:col-start-auto {
    grid-column-start: auto !important;
  }

  .xl\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .xl\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .xl\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .xl\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .xl\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .xl\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .xl\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .xl\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .xl\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .xl\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .xl\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .xl\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .xl\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .xl\:col-end-auto {
    grid-column-end: auto !important;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto !important;
  }

  .xl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .xl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .xl\:row-auto {
    grid-row: auto !important;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .xl\:row-span-full {
    grid-row: 1 / -1 !important;
  }

  .xl\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .xl\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .xl\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .xl\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .xl\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .xl\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .xl\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .xl\:row-start-auto {
    grid-row-start: auto !important;
  }

  .xl\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .xl\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .xl\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .xl\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .xl\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .xl\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .xl\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .xl\:row-end-auto {
    grid-row-end: auto !important;
  }

  .xl\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .xl\:transform-none {
    transform: none !important;
  }

  .xl\:origin-center {
    transform-origin: center !important;
  }

  .xl\:origin-top {
    transform-origin: top !important;
  }

  .xl\:origin-top-right {
    transform-origin: top right !important;
  }

  .xl\:origin-right {
    transform-origin: right !important;
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .xl\:origin-left {
    transform-origin: left !important;
  }

  .xl\:origin-top-left {
    transform-origin: top left !important;
  }

  .xl\:scale-0 {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xl\:scale-50 {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xl\:scale-75 {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xl\:scale-90 {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xl\:scale-95 {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xl\:scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xl\:scale-105 {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xl\:scale-110 {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xl\:scale-125 {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xl\:scale-150 {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xl\:scale-x-0 {
    --transform-scale-x: 0 !important;
  }

  .xl\:scale-x-50 {
    --transform-scale-x: .5 !important;
  }

  .xl\:scale-x-75 {
    --transform-scale-x: .75 !important;
  }

  .xl\:scale-x-90 {
    --transform-scale-x: .9 !important;
  }

  .xl\:scale-x-95 {
    --transform-scale-x: .95 !important;
  }

  .xl\:scale-x-100 {
    --transform-scale-x: 1 !important;
  }

  .xl\:scale-x-105 {
    --transform-scale-x: 1.05 !important;
  }

  .xl\:scale-x-110 {
    --transform-scale-x: 1.1 !important;
  }

  .xl\:scale-x-125 {
    --transform-scale-x: 1.25 !important;
  }

  .xl\:scale-x-150 {
    --transform-scale-x: 1.5 !important;
  }

  .xl\:scale-y-0 {
    --transform-scale-y: 0 !important;
  }

  .xl\:scale-y-50 {
    --transform-scale-y: .5 !important;
  }

  .xl\:scale-y-75 {
    --transform-scale-y: .75 !important;
  }

  .xl\:scale-y-90 {
    --transform-scale-y: .9 !important;
  }

  .xl\:scale-y-95 {
    --transform-scale-y: .95 !important;
  }

  .xl\:scale-y-100 {
    --transform-scale-y: 1 !important;
  }

  .xl\:scale-y-105 {
    --transform-scale-y: 1.05 !important;
  }

  .xl\:scale-y-110 {
    --transform-scale-y: 1.1 !important;
  }

  .xl\:scale-y-125 {
    --transform-scale-y: 1.25 !important;
  }

  .xl\:scale-y-150 {
    --transform-scale-y: 1.5 !important;
  }

  .xl\:hover\:scale-0:hover {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xl\:hover\:scale-50:hover {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xl\:hover\:scale-75:hover {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xl\:hover\:scale-90:hover {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xl\:hover\:scale-95:hover {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xl\:hover\:scale-100:hover {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xl\:hover\:scale-105:hover {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xl\:hover\:scale-110:hover {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xl\:hover\:scale-125:hover {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xl\:hover\:scale-150:hover {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xl\:hover\:scale-x-0:hover {
    --transform-scale-x: 0 !important;
  }

  .xl\:hover\:scale-x-50:hover {
    --transform-scale-x: .5 !important;
  }

  .xl\:hover\:scale-x-75:hover {
    --transform-scale-x: .75 !important;
  }

  .xl\:hover\:scale-x-90:hover {
    --transform-scale-x: .9 !important;
  }

  .xl\:hover\:scale-x-95:hover {
    --transform-scale-x: .95 !important;
  }

  .xl\:hover\:scale-x-100:hover {
    --transform-scale-x: 1 !important;
  }

  .xl\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05 !important;
  }

  .xl\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1 !important;
  }

  .xl\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25 !important;
  }

  .xl\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5 !important;
  }

  .xl\:hover\:scale-y-0:hover {
    --transform-scale-y: 0 !important;
  }

  .xl\:hover\:scale-y-50:hover {
    --transform-scale-y: .5 !important;
  }

  .xl\:hover\:scale-y-75:hover {
    --transform-scale-y: .75 !important;
  }

  .xl\:hover\:scale-y-90:hover {
    --transform-scale-y: .9 !important;
  }

  .xl\:hover\:scale-y-95:hover {
    --transform-scale-y: .95 !important;
  }

  .xl\:hover\:scale-y-100:hover {
    --transform-scale-y: 1 !important;
  }

  .xl\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05 !important;
  }

  .xl\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1 !important;
  }

  .xl\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25 !important;
  }

  .xl\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5 !important;
  }

  .xl\:focus\:scale-0:focus {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xl\:focus\:scale-50:focus {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xl\:focus\:scale-75:focus {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xl\:focus\:scale-90:focus {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xl\:focus\:scale-95:focus {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xl\:focus\:scale-100:focus {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xl\:focus\:scale-105:focus {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xl\:focus\:scale-110:focus {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xl\:focus\:scale-125:focus {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xl\:focus\:scale-150:focus {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xl\:focus\:scale-x-0:focus {
    --transform-scale-x: 0 !important;
  }

  .xl\:focus\:scale-x-50:focus {
    --transform-scale-x: .5 !important;
  }

  .xl\:focus\:scale-x-75:focus {
    --transform-scale-x: .75 !important;
  }

  .xl\:focus\:scale-x-90:focus {
    --transform-scale-x: .9 !important;
  }

  .xl\:focus\:scale-x-95:focus {
    --transform-scale-x: .95 !important;
  }

  .xl\:focus\:scale-x-100:focus {
    --transform-scale-x: 1 !important;
  }

  .xl\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05 !important;
  }

  .xl\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1 !important;
  }

  .xl\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25 !important;
  }

  .xl\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5 !important;
  }

  .xl\:focus\:scale-y-0:focus {
    --transform-scale-y: 0 !important;
  }

  .xl\:focus\:scale-y-50:focus {
    --transform-scale-y: .5 !important;
  }

  .xl\:focus\:scale-y-75:focus {
    --transform-scale-y: .75 !important;
  }

  .xl\:focus\:scale-y-90:focus {
    --transform-scale-y: .9 !important;
  }

  .xl\:focus\:scale-y-95:focus {
    --transform-scale-y: .95 !important;
  }

  .xl\:focus\:scale-y-100:focus {
    --transform-scale-y: 1 !important;
  }

  .xl\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05 !important;
  }

  .xl\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1 !important;
  }

  .xl\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25 !important;
  }

  .xl\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5 !important;
  }

  .xl\:rotate-0 {
    --transform-rotate: 0 !important;
  }

  .xl\:rotate-1 {
    --transform-rotate: 1deg !important;
  }

  .xl\:rotate-2 {
    --transform-rotate: 2deg !important;
  }

  .xl\:rotate-3 {
    --transform-rotate: 3deg !important;
  }

  .xl\:rotate-6 {
    --transform-rotate: 6deg !important;
  }

  .xl\:rotate-12 {
    --transform-rotate: 12deg !important;
  }

  .xl\:rotate-45 {
    --transform-rotate: 45deg !important;
  }

  .xl\:rotate-90 {
    --transform-rotate: 90deg !important;
  }

  .xl\:rotate-180 {
    --transform-rotate: 180deg !important;
  }

  .xl\:-rotate-180 {
    --transform-rotate: -180deg !important;
  }

  .xl\:-rotate-90 {
    --transform-rotate: -90deg !important;
  }

  .xl\:-rotate-45 {
    --transform-rotate: -45deg !important;
  }

  .xl\:-rotate-12 {
    --transform-rotate: -12deg !important;
  }

  .xl\:-rotate-6 {
    --transform-rotate: -6deg !important;
  }

  .xl\:-rotate-3 {
    --transform-rotate: -3deg !important;
  }

  .xl\:-rotate-2 {
    --transform-rotate: -2deg !important;
  }

  .xl\:-rotate-1 {
    --transform-rotate: -1deg !important;
  }

  .xl\:hover\:rotate-0:hover {
    --transform-rotate: 0 !important;
  }

  .xl\:hover\:rotate-1:hover {
    --transform-rotate: 1deg !important;
  }

  .xl\:hover\:rotate-2:hover {
    --transform-rotate: 2deg !important;
  }

  .xl\:hover\:rotate-3:hover {
    --transform-rotate: 3deg !important;
  }

  .xl\:hover\:rotate-6:hover {
    --transform-rotate: 6deg !important;
  }

  .xl\:hover\:rotate-12:hover {
    --transform-rotate: 12deg !important;
  }

  .xl\:hover\:rotate-45:hover {
    --transform-rotate: 45deg !important;
  }

  .xl\:hover\:rotate-90:hover {
    --transform-rotate: 90deg !important;
  }

  .xl\:hover\:rotate-180:hover {
    --transform-rotate: 180deg !important;
  }

  .xl\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg !important;
  }

  .xl\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg !important;
  }

  .xl\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg !important;
  }

  .xl\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg !important;
  }

  .xl\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg !important;
  }

  .xl\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg !important;
  }

  .xl\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg !important;
  }

  .xl\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg !important;
  }

  .xl\:focus\:rotate-0:focus {
    --transform-rotate: 0 !important;
  }

  .xl\:focus\:rotate-1:focus {
    --transform-rotate: 1deg !important;
  }

  .xl\:focus\:rotate-2:focus {
    --transform-rotate: 2deg !important;
  }

  .xl\:focus\:rotate-3:focus {
    --transform-rotate: 3deg !important;
  }

  .xl\:focus\:rotate-6:focus {
    --transform-rotate: 6deg !important;
  }

  .xl\:focus\:rotate-12:focus {
    --transform-rotate: 12deg !important;
  }

  .xl\:focus\:rotate-45:focus {
    --transform-rotate: 45deg !important;
  }

  .xl\:focus\:rotate-90:focus {
    --transform-rotate: 90deg !important;
  }

  .xl\:focus\:rotate-180:focus {
    --transform-rotate: 180deg !important;
  }

  .xl\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg !important;
  }

  .xl\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg !important;
  }

  .xl\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg !important;
  }

  .xl\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg !important;
  }

  .xl\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg !important;
  }

  .xl\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg !important;
  }

  .xl\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg !important;
  }

  .xl\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg !important;
  }

  .xl\:translate-x-0 {
    --transform-translate-x: 0 !important;
  }

  .xl\:translate-x-1 {
    --transform-translate-x: 0.25rem !important;
  }

  .xl\:translate-x-2 {
    --transform-translate-x: 0.5rem !important;
  }

  .xl\:translate-x-3 {
    --transform-translate-x: 0.75rem !important;
  }

  .xl\:translate-x-4 {
    --transform-translate-x: 1rem !important;
  }

  .xl\:translate-x-5 {
    --transform-translate-x: 1.25rem !important;
  }

  .xl\:translate-x-6 {
    --transform-translate-x: 1.5rem !important;
  }

  .xl\:translate-x-8 {
    --transform-translate-x: 2rem !important;
  }

  .xl\:translate-x-10 {
    --transform-translate-x: 2.5rem !important;
  }

  .xl\:translate-x-12 {
    --transform-translate-x: 3rem !important;
  }

  .xl\:translate-x-16 {
    --transform-translate-x: 4rem !important;
  }

  .xl\:translate-x-20 {
    --transform-translate-x: 5rem !important;
  }

  .xl\:translate-x-24 {
    --transform-translate-x: 6rem !important;
  }

  .xl\:translate-x-32 {
    --transform-translate-x: 8rem !important;
  }

  .xl\:translate-x-40 {
    --transform-translate-x: 10rem !important;
  }

  .xl\:translate-x-48 {
    --transform-translate-x: 12rem !important;
  }

  .xl\:translate-x-56 {
    --transform-translate-x: 14rem !important;
  }

  .xl\:translate-x-64 {
    --transform-translate-x: 16rem !important;
  }

  .xl\:translate-x-px {
    --transform-translate-x: 1px !important;
  }

  .xl\:-translate-x-1 {
    --transform-translate-x: -0.25rem !important;
  }

  .xl\:-translate-x-2 {
    --transform-translate-x: -0.5rem !important;
  }

  .xl\:-translate-x-3 {
    --transform-translate-x: -0.75rem !important;
  }

  .xl\:-translate-x-4 {
    --transform-translate-x: -1rem !important;
  }

  .xl\:-translate-x-5 {
    --transform-translate-x: -1.25rem !important;
  }

  .xl\:-translate-x-6 {
    --transform-translate-x: -1.5rem !important;
  }

  .xl\:-translate-x-8 {
    --transform-translate-x: -2rem !important;
  }

  .xl\:-translate-x-10 {
    --transform-translate-x: -2.5rem !important;
  }

  .xl\:-translate-x-12 {
    --transform-translate-x: -3rem !important;
  }

  .xl\:-translate-x-16 {
    --transform-translate-x: -4rem !important;
  }

  .xl\:-translate-x-20 {
    --transform-translate-x: -5rem !important;
  }

  .xl\:-translate-x-24 {
    --transform-translate-x: -6rem !important;
  }

  .xl\:-translate-x-32 {
    --transform-translate-x: -8rem !important;
  }

  .xl\:-translate-x-40 {
    --transform-translate-x: -10rem !important;
  }

  .xl\:-translate-x-48 {
    --transform-translate-x: -12rem !important;
  }

  .xl\:-translate-x-56 {
    --transform-translate-x: -14rem !important;
  }

  .xl\:-translate-x-64 {
    --transform-translate-x: -16rem !important;
  }

  .xl\:-translate-x-px {
    --transform-translate-x: -1px !important;
  }

  .xl\:-translate-x-full {
    --transform-translate-x: -100% !important;
  }

  .xl\:-translate-x-1\/2 {
    --transform-translate-x: -50% !important;
  }

  .xl\:translate-x-1\/2 {
    --transform-translate-x: 50% !important;
  }

  .xl\:translate-x-full {
    --transform-translate-x: 100% !important;
  }

  .xl\:translate-y-0 {
    --transform-translate-y: 0 !important;
  }

  .xl\:translate-y-1 {
    --transform-translate-y: 0.25rem !important;
  }

  .xl\:translate-y-2 {
    --transform-translate-y: 0.5rem !important;
  }

  .xl\:translate-y-3 {
    --transform-translate-y: 0.75rem !important;
  }

  .xl\:translate-y-4 {
    --transform-translate-y: 1rem !important;
  }

  .xl\:translate-y-5 {
    --transform-translate-y: 1.25rem !important;
  }

  .xl\:translate-y-6 {
    --transform-translate-y: 1.5rem !important;
  }

  .xl\:translate-y-8 {
    --transform-translate-y: 2rem !important;
  }

  .xl\:translate-y-10 {
    --transform-translate-y: 2.5rem !important;
  }

  .xl\:translate-y-12 {
    --transform-translate-y: 3rem !important;
  }

  .xl\:translate-y-16 {
    --transform-translate-y: 4rem !important;
  }

  .xl\:translate-y-20 {
    --transform-translate-y: 5rem !important;
  }

  .xl\:translate-y-24 {
    --transform-translate-y: 6rem !important;
  }

  .xl\:translate-y-32 {
    --transform-translate-y: 8rem !important;
  }

  .xl\:translate-y-40 {
    --transform-translate-y: 10rem !important;
  }

  .xl\:translate-y-48 {
    --transform-translate-y: 12rem !important;
  }

  .xl\:translate-y-56 {
    --transform-translate-y: 14rem !important;
  }

  .xl\:translate-y-64 {
    --transform-translate-y: 16rem !important;
  }

  .xl\:translate-y-px {
    --transform-translate-y: 1px !important;
  }

  .xl\:-translate-y-1 {
    --transform-translate-y: -0.25rem !important;
  }

  .xl\:-translate-y-2 {
    --transform-translate-y: -0.5rem !important;
  }

  .xl\:-translate-y-3 {
    --transform-translate-y: -0.75rem !important;
  }

  .xl\:-translate-y-4 {
    --transform-translate-y: -1rem !important;
  }

  .xl\:-translate-y-5 {
    --transform-translate-y: -1.25rem !important;
  }

  .xl\:-translate-y-6 {
    --transform-translate-y: -1.5rem !important;
  }

  .xl\:-translate-y-8 {
    --transform-translate-y: -2rem !important;
  }

  .xl\:-translate-y-10 {
    --transform-translate-y: -2.5rem !important;
  }

  .xl\:-translate-y-12 {
    --transform-translate-y: -3rem !important;
  }

  .xl\:-translate-y-16 {
    --transform-translate-y: -4rem !important;
  }

  .xl\:-translate-y-20 {
    --transform-translate-y: -5rem !important;
  }

  .xl\:-translate-y-24 {
    --transform-translate-y: -6rem !important;
  }

  .xl\:-translate-y-32 {
    --transform-translate-y: -8rem !important;
  }

  .xl\:-translate-y-40 {
    --transform-translate-y: -10rem !important;
  }

  .xl\:-translate-y-48 {
    --transform-translate-y: -12rem !important;
  }

  .xl\:-translate-y-56 {
    --transform-translate-y: -14rem !important;
  }

  .xl\:-translate-y-64 {
    --transform-translate-y: -16rem !important;
  }

  .xl\:-translate-y-px {
    --transform-translate-y: -1px !important;
  }

  .xl\:-translate-y-full {
    --transform-translate-y: -100% !important;
  }

  .xl\:-translate-y-1\/2 {
    --transform-translate-y: -50% !important;
  }

  .xl\:translate-y-1\/2 {
    --transform-translate-y: 50% !important;
  }

  .xl\:translate-y-full {
    --transform-translate-y: 100% !important;
  }

  .xl\:hover\:translate-x-0:hover {
    --transform-translate-x: 0 !important;
  }

  .xl\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem !important;
  }

  .xl\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem !important;
  }

  .xl\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem !important;
  }

  .xl\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem !important;
  }

  .xl\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem !important;
  }

  .xl\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem !important;
  }

  .xl\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem !important;
  }

  .xl\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem !important;
  }

  .xl\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem !important;
  }

  .xl\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem !important;
  }

  .xl\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem !important;
  }

  .xl\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem !important;
  }

  .xl\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem !important;
  }

  .xl\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem !important;
  }

  .xl\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem !important;
  }

  .xl\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem !important;
  }

  .xl\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem !important;
  }

  .xl\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px !important;
  }

  .xl\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem !important;
  }

  .xl\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem !important;
  }

  .xl\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem !important;
  }

  .xl\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem !important;
  }

  .xl\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem !important;
  }

  .xl\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem !important;
  }

  .xl\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem !important;
  }

  .xl\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem !important;
  }

  .xl\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem !important;
  }

  .xl\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem !important;
  }

  .xl\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem !important;
  }

  .xl\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem !important;
  }

  .xl\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem !important;
  }

  .xl\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem !important;
  }

  .xl\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem !important;
  }

  .xl\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem !important;
  }

  .xl\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem !important;
  }

  .xl\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px !important;
  }

  .xl\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100% !important;
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50% !important;
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50% !important;
  }

  .xl\:hover\:translate-x-full:hover {
    --transform-translate-x: 100% !important;
  }

  .xl\:hover\:translate-y-0:hover {
    --transform-translate-y: 0 !important;
  }

  .xl\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem !important;
  }

  .xl\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem !important;
  }

  .xl\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem !important;
  }

  .xl\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem !important;
  }

  .xl\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem !important;
  }

  .xl\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem !important;
  }

  .xl\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem !important;
  }

  .xl\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem !important;
  }

  .xl\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem !important;
  }

  .xl\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem !important;
  }

  .xl\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem !important;
  }

  .xl\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem !important;
  }

  .xl\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem !important;
  }

  .xl\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem !important;
  }

  .xl\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem !important;
  }

  .xl\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem !important;
  }

  .xl\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem !important;
  }

  .xl\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px !important;
  }

  .xl\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem !important;
  }

  .xl\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem !important;
  }

  .xl\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem !important;
  }

  .xl\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem !important;
  }

  .xl\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem !important;
  }

  .xl\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem !important;
  }

  .xl\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem !important;
  }

  .xl\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem !important;
  }

  .xl\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem !important;
  }

  .xl\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem !important;
  }

  .xl\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem !important;
  }

  .xl\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem !important;
  }

  .xl\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem !important;
  }

  .xl\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem !important;
  }

  .xl\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem !important;
  }

  .xl\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem !important;
  }

  .xl\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem !important;
  }

  .xl\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px !important;
  }

  .xl\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100% !important;
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50% !important;
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50% !important;
  }

  .xl\:hover\:translate-y-full:hover {
    --transform-translate-y: 100% !important;
  }

  .xl\:focus\:translate-x-0:focus {
    --transform-translate-x: 0 !important;
  }

  .xl\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem !important;
  }

  .xl\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem !important;
  }

  .xl\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem !important;
  }

  .xl\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem !important;
  }

  .xl\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem !important;
  }

  .xl\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem !important;
  }

  .xl\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem !important;
  }

  .xl\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem !important;
  }

  .xl\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem !important;
  }

  .xl\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem !important;
  }

  .xl\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem !important;
  }

  .xl\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem !important;
  }

  .xl\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem !important;
  }

  .xl\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem !important;
  }

  .xl\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem !important;
  }

  .xl\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem !important;
  }

  .xl\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem !important;
  }

  .xl\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px !important;
  }

  .xl\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem !important;
  }

  .xl\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem !important;
  }

  .xl\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem !important;
  }

  .xl\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem !important;
  }

  .xl\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem !important;
  }

  .xl\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem !important;
  }

  .xl\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem !important;
  }

  .xl\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem !important;
  }

  .xl\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem !important;
  }

  .xl\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem !important;
  }

  .xl\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem !important;
  }

  .xl\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem !important;
  }

  .xl\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem !important;
  }

  .xl\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem !important;
  }

  .xl\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem !important;
  }

  .xl\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem !important;
  }

  .xl\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem !important;
  }

  .xl\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px !important;
  }

  .xl\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100% !important;
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50% !important;
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50% !important;
  }

  .xl\:focus\:translate-x-full:focus {
    --transform-translate-x: 100% !important;
  }

  .xl\:focus\:translate-y-0:focus {
    --transform-translate-y: 0 !important;
  }

  .xl\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem !important;
  }

  .xl\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem !important;
  }

  .xl\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem !important;
  }

  .xl\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem !important;
  }

  .xl\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem !important;
  }

  .xl\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem !important;
  }

  .xl\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem !important;
  }

  .xl\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem !important;
  }

  .xl\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem !important;
  }

  .xl\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem !important;
  }

  .xl\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem !important;
  }

  .xl\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem !important;
  }

  .xl\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem !important;
  }

  .xl\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem !important;
  }

  .xl\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem !important;
  }

  .xl\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem !important;
  }

  .xl\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem !important;
  }

  .xl\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px !important;
  }

  .xl\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem !important;
  }

  .xl\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem !important;
  }

  .xl\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem !important;
  }

  .xl\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem !important;
  }

  .xl\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem !important;
  }

  .xl\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem !important;
  }

  .xl\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem !important;
  }

  .xl\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem !important;
  }

  .xl\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem !important;
  }

  .xl\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem !important;
  }

  .xl\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem !important;
  }

  .xl\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem !important;
  }

  .xl\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem !important;
  }

  .xl\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem !important;
  }

  .xl\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem !important;
  }

  .xl\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem !important;
  }

  .xl\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem !important;
  }

  .xl\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px !important;
  }

  .xl\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100% !important;
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50% !important;
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50% !important;
  }

  .xl\:focus\:translate-y-full:focus {
    --transform-translate-y: 100% !important;
  }

  .xl\:skew-x-0 {
    --transform-skew-x: 0 !important;
  }

  .xl\:skew-x-1 {
    --transform-skew-x: 1deg !important;
  }

  .xl\:skew-x-2 {
    --transform-skew-x: 2deg !important;
  }

  .xl\:skew-x-3 {
    --transform-skew-x: 3deg !important;
  }

  .xl\:skew-x-6 {
    --transform-skew-x: 6deg !important;
  }

  .xl\:skew-x-12 {
    --transform-skew-x: 12deg !important;
  }

  .xl\:-skew-x-12 {
    --transform-skew-x: -12deg !important;
  }

  .xl\:-skew-x-6 {
    --transform-skew-x: -6deg !important;
  }

  .xl\:-skew-x-3 {
    --transform-skew-x: -3deg !important;
  }

  .xl\:-skew-x-2 {
    --transform-skew-x: -2deg !important;
  }

  .xl\:-skew-x-1 {
    --transform-skew-x: -1deg !important;
  }

  .xl\:skew-y-0 {
    --transform-skew-y: 0 !important;
  }

  .xl\:skew-y-1 {
    --transform-skew-y: 1deg !important;
  }

  .xl\:skew-y-2 {
    --transform-skew-y: 2deg !important;
  }

  .xl\:skew-y-3 {
    --transform-skew-y: 3deg !important;
  }

  .xl\:skew-y-6 {
    --transform-skew-y: 6deg !important;
  }

  .xl\:skew-y-12 {
    --transform-skew-y: 12deg !important;
  }

  .xl\:-skew-y-12 {
    --transform-skew-y: -12deg !important;
  }

  .xl\:-skew-y-6 {
    --transform-skew-y: -6deg !important;
  }

  .xl\:-skew-y-3 {
    --transform-skew-y: -3deg !important;
  }

  .xl\:-skew-y-2 {
    --transform-skew-y: -2deg !important;
  }

  .xl\:-skew-y-1 {
    --transform-skew-y: -1deg !important;
  }

  .xl\:hover\:skew-x-0:hover {
    --transform-skew-x: 0 !important;
  }

  .xl\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg !important;
  }

  .xl\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg !important;
  }

  .xl\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg !important;
  }

  .xl\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg !important;
  }

  .xl\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg !important;
  }

  .xl\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg !important;
  }

  .xl\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg !important;
  }

  .xl\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg !important;
  }

  .xl\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg !important;
  }

  .xl\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg !important;
  }

  .xl\:hover\:skew-y-0:hover {
    --transform-skew-y: 0 !important;
  }

  .xl\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg !important;
  }

  .xl\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg !important;
  }

  .xl\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg !important;
  }

  .xl\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg !important;
  }

  .xl\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg !important;
  }

  .xl\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg !important;
  }

  .xl\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg !important;
  }

  .xl\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg !important;
  }

  .xl\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg !important;
  }

  .xl\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg !important;
  }

  .xl\:focus\:skew-x-0:focus {
    --transform-skew-x: 0 !important;
  }

  .xl\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg !important;
  }

  .xl\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg !important;
  }

  .xl\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg !important;
  }

  .xl\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg !important;
  }

  .xl\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg !important;
  }

  .xl\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg !important;
  }

  .xl\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg !important;
  }

  .xl\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg !important;
  }

  .xl\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg !important;
  }

  .xl\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg !important;
  }

  .xl\:focus\:skew-y-0:focus {
    --transform-skew-y: 0 !important;
  }

  .xl\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg !important;
  }

  .xl\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg !important;
  }

  .xl\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg !important;
  }

  .xl\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg !important;
  }

  .xl\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg !important;
  }

  .xl\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg !important;
  }

  .xl\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg !important;
  }

  .xl\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg !important;
  }

  .xl\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg !important;
  }

  .xl\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg !important;
  }

  .xl\:transition-none {
    transition-property: none !important;
  }

  .xl\:transition-all {
    transition-property: all !important;
  }

  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .xl\:transition-opacity {
    transition-property: opacity !important;
  }

  .xl\:transition-shadow {
    transition-property: box-shadow !important;
  }

  .xl\:transition-transform {
    transition-property: transform !important;
  }

  .xl\:ease-linear {
    transition-timing-function: linear !important;
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .xl\:duration-75 {
    transition-duration: 75ms !important;
  }

  .xl\:duration-100 {
    transition-duration: 100ms !important;
  }

  .xl\:duration-150 {
    transition-duration: 150ms !important;
  }

  .xl\:duration-200 {
    transition-duration: 200ms !important;
  }

  .xl\:duration-300 {
    transition-duration: 300ms !important;
  }

  .xl\:duration-500 {
    transition-duration: 500ms !important;
  }

  .xl\:duration-700 {
    transition-duration: 700ms !important;
  }

  .xl\:duration-1000 {
    transition-duration: 1000ms !important;
  }

  .xl\:delay-75 {
    transition-delay: 75ms !important;
  }

  .xl\:delay-100 {
    transition-delay: 100ms !important;
  }

  .xl\:delay-150 {
    transition-delay: 150ms !important;
  }

  .xl\:delay-200 {
    transition-delay: 200ms !important;
  }

  .xl\:delay-300 {
    transition-delay: 300ms !important;
  }

  .xl\:delay-500 {
    transition-delay: 500ms !important;
  }

  .xl\:delay-700 {
    transition-delay: 700ms !important;
  }

  .xl\:delay-1000 {
    transition-delay: 1000ms !important;
  }

  .xl\:animate-none {
    animation: none !important;
  }

  .xl\:animate-spin {
    animation: spin 1s linear infinite !important;
  }

  .xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .xl\:animate-bounce {
    animation: bounce 1s infinite !important;
  }

  .xl\:link {
    color: var(--primary);
    cursor: pointer;
    transition: color 0.3s ease;
  }
}

@media (min-width: 1600px) {
  .xxl\:container {
    width: 100%;
  }

  @media (min-width: 480px) {
    .xxl\:container {
      max-width: 480px;
    }
  }

  @media (min-width: 576px) {
    .xxl\:container {
      max-width: 576px;
    }
  }

  @media (min-width: 768px) {
    .xxl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 992px) {
    .xxl\:container {
      max-width: 992px;
    }
  }

  @media (min-width: 1200px) {
    .xxl\:container {
      max-width: 1200px;
    }
  }

  @media (min-width: 1600px) {
    .xxl\:container {
      max-width: 1600px;
    }
  }

  .xxl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .xxl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .xxl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xxl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .xxl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .xxl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .xxl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xxl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .xxl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xxl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .xxl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xxl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .xxl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xxl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .xxl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xxl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .xxl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .xxl\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .xxl\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .xxl\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .xxl\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--divide-opacity)) !important;
  }

  .xxl\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--divide-opacity)) !important;
  }

  .xxl\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--divide-opacity)) !important;
  }

  .xxl\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--divide-opacity)) !important;
  }

  .xxl\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--divide-opacity)) !important;
  }

  .xxl\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--divide-opacity)) !important;
  }

  .xxl\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--divide-opacity)) !important;
  }

  .xxl\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--divide-opacity)) !important;
  }

  .xxl\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--divide-opacity)) !important;
  }

  .xxl\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--divide-opacity)) !important;
  }

  .xxl\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--divide-opacity)) !important;
  }

  .xxl\:divide-primary > :not(template) ~ :not(template) {
    border-color: var(--primary) !important;
  }

  .xxl\:divide-accent-1 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--divide-opacity)) !important;
  }

  .xxl\:divide-accent-2 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--divide-opacity)) !important;
  }

  .xxl\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid !important;
  }

  .xxl\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed !important;
  }

  .xxl\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted !important;
  }

  .xxl\:divide-double > :not(template) ~ :not(template) {
    border-style: double !important;
  }

  .xxl\:divide-none > :not(template) ~ :not(template) {
    border-style: none !important;
  }

  .xxl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .xxl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .xxl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .xxl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .xxl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .xxl\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xxl\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xxl\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xxl\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xxl\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .xxl\:bg-fixed {
    background-attachment: fixed !important;
  }

  .xxl\:bg-local {
    background-attachment: local !important;
  }

  .xxl\:bg-scroll {
    background-attachment: scroll !important;
  }

  .xxl\:bg-clip-border {
    background-clip: border-box !important;
  }

  .xxl\:bg-clip-padding {
    background-clip: padding-box !important;
  }

  .xxl\:bg-clip-content {
    background-clip: content-box !important;
  }

  .xxl\:bg-clip-text {
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .xxl\:bg-transparent {
    background-color: transparent !important;
  }

  .xxl\:bg-current {
    background-color: currentColor !important;
  }

  .xxl\:bg-black {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xxl\:bg-white {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-100 {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-200 {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-300 {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-400 {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-500 {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-600 {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-700 {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-800 {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xxl\:bg-gray-900 {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-300 {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-400 {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-500 {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-600 {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-700 {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-800 {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xxl\:bg-red-900 {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-100 {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-200 {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-300 {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-400 {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-500 {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-600 {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-700 {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-800 {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xxl\:bg-orange-900 {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-100 {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-200 {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-300 {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-400 {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-500 {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-600 {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-700 {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-800 {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xxl\:bg-yellow-900 {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-100 {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-200 {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-300 {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-400 {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-500 {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-600 {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-700 {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-800 {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xxl\:bg-green-900 {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-100 {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-200 {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-300 {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-400 {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-500 {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-600 {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-700 {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-800 {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xxl\:bg-teal-900 {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-200 {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-300 {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-400 {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-500 {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-600 {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-700 {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-800 {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xxl\:bg-blue-900 {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-100 {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-200 {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-300 {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-400 {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-500 {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-600 {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-700 {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-800 {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xxl\:bg-indigo-900 {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-100 {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-200 {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-300 {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-400 {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-500 {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-600 {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-700 {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-800 {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xxl\:bg-purple-900 {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-100 {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-200 {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-300 {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-400 {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-500 {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-600 {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-700 {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-800 {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xxl\:bg-pink-900 {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xxl\:bg-primary {
    background-color: var(--primary) !important;
  }

  .xxl\:bg-accent-1 {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xxl\:bg-accent-2 {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-transparent:hover {
    background-color: transparent !important;
  }

  .xxl\:hover\:bg-current:hover {
    background-color: currentColor !important;
  }

  .xxl\:hover\:bg-black:hover {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-white:hover {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-200:hover {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-300:hover {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-400:hover {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-500:hover {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-600:hover {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-gray-900:hover {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-300:hover {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-400:hover {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-500:hover {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-600:hover {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-red-900:hover {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-200:hover {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-600:hover {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-700:hover {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-800:hover {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-orange-900:hover {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-100:hover {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-300:hover {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-400:hover {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-500:hover {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-600:hover {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-800:hover {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-green-900:hover {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-100:hover {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-200:hover {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-300:hover {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-400:hover {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-500:hover {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-600:hover {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-800:hover {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-teal-900:hover {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-200:hover {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-300:hover {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-400:hover {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-500:hover {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-600:hover {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-700:hover {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-800:hover {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-blue-900:hover {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-100:hover {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-200:hover {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-300:hover {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-400:hover {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-500:hover {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-600:hover {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-700:hover {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-800:hover {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-purple-900:hover {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-100:hover {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-200:hover {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-300:hover {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-400:hover {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-500:hover {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-600:hover {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-700:hover {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-800:hover {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-pink-900:hover {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-primary:hover {
    background-color: var(--primary) !important;
  }

  .xxl\:hover\:bg-accent-1:hover {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xxl\:hover\:bg-accent-2:hover {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-transparent:focus {
    background-color: transparent !important;
  }

  .xxl\:focus\:bg-current:focus {
    background-color: currentColor !important;
  }

  .xxl\:focus\:bg-black:focus {
    --bg-opacity: 1 !important;
    background-color: #000 !important;
    background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-white:focus {
    --bg-opacity: 1 !important;
    background-color: #fff !important;
    background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f7fafc !important;
    background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-200:focus {
    --bg-opacity: 1 !important;
    background-color: #edf2f7 !important;
    background-color: rgba(237, 242, 247, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-300:focus {
    --bg-opacity: 1 !important;
    background-color: #e2e8f0 !important;
    background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-400:focus {
    --bg-opacity: 1 !important;
    background-color: #cbd5e0 !important;
    background-color: rgba(203, 213, 224, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-500:focus {
    --bg-opacity: 1 !important;
    background-color: #a0aec0 !important;
    background-color: rgba(160, 174, 192, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-600:focus {
    --bg-opacity: 1 !important;
    background-color: #718096 !important;
    background-color: rgba(113, 128, 150, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4a5568 !important;
    background-color: rgba(74, 85, 104, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2d3748 !important;
    background-color: rgba(45, 55, 72, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-gray-900:focus {
    --bg-opacity: 1 !important;
    background-color: #1a202c !important;
    background-color: rgba(26, 32, 44, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f5 !important;
    background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7d7 !important;
    background-color: rgba(254, 215, 215, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-300:focus {
    --bg-opacity: 1 !important;
    background-color: #feb2b2 !important;
    background-color: rgba(254, 178, 178, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-400:focus {
    --bg-opacity: 1 !important;
    background-color: #fc8181 !important;
    background-color: rgba(252, 129, 129, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-500:focus {
    --bg-opacity: 1 !important;
    background-color: #f56565 !important;
    background-color: rgba(245, 101, 101, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-600:focus {
    --bg-opacity: 1 !important;
    background-color: #e53e3e !important;
    background-color: rgba(229, 62, 62, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c53030 !important;
    background-color: rgba(197, 48, 48, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9b2c2c !important;
    background-color: rgba(155, 44, 44, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-red-900:focus {
    --bg-opacity: 1 !important;
    background-color: #742a2a !important;
    background-color: rgba(116, 42, 42, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffaf0 !important;
    background-color: rgba(255, 250, 240, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-200:focus {
    --bg-opacity: 1 !important;
    background-color: #feebc8 !important;
    background-color: rgba(254, 235, 200, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbd38d !important;
    background-color: rgba(251, 211, 141, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6ad55 !important;
    background-color: rgba(246, 173, 85, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed8936 !important;
    background-color: rgba(237, 137, 54, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-600:focus {
    --bg-opacity: 1 !important;
    background-color: #dd6b20 !important;
    background-color: rgba(221, 107, 32, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-700:focus {
    --bg-opacity: 1 !important;
    background-color: #c05621 !important;
    background-color: rgba(192, 86, 33, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-800:focus {
    --bg-opacity: 1 !important;
    background-color: #9c4221 !important;
    background-color: rgba(156, 66, 33, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-orange-900:focus {
    --bg-opacity: 1 !important;
    background-color: #7b341e !important;
    background-color: rgba(123, 52, 30, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fffff0 !important;
    background-color: rgba(255, 255, 240, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fefcbf !important;
    background-color: rgba(254, 252, 191, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1 !important;
    background-color: #faf089 !important;
    background-color: rgba(250, 240, 137, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f6e05e !important;
    background-color: rgba(246, 224, 94, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ecc94b !important;
    background-color: rgba(236, 201, 75, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d69e2e !important;
    background-color: rgba(214, 158, 46, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b7791f !important;
    background-color: rgba(183, 121, 31, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1 !important;
    background-color: #975a16 !important;
    background-color: rgba(151, 90, 22, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1 !important;
    background-color: #744210 !important;
    background-color: rgba(116, 66, 16, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-100:focus {
    --bg-opacity: 1 !important;
    background-color: #f0fff4 !important;
    background-color: rgba(240, 255, 244, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c6f6d5 !important;
    background-color: rgba(198, 246, 213, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-300:focus {
    --bg-opacity: 1 !important;
    background-color: #9ae6b4 !important;
    background-color: rgba(154, 230, 180, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-400:focus {
    --bg-opacity: 1 !important;
    background-color: #68d391 !important;
    background-color: rgba(104, 211, 145, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-500:focus {
    --bg-opacity: 1 !important;
    background-color: #48bb78 !important;
    background-color: rgba(72, 187, 120, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-600:focus {
    --bg-opacity: 1 !important;
    background-color: #38a169 !important;
    background-color: rgba(56, 161, 105, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2f855a !important;
    background-color: rgba(47, 133, 90, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-800:focus {
    --bg-opacity: 1 !important;
    background-color: #276749 !important;
    background-color: rgba(39, 103, 73, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-green-900:focus {
    --bg-opacity: 1 !important;
    background-color: #22543d !important;
    background-color: rgba(34, 84, 61, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-100:focus {
    --bg-opacity: 1 !important;
    background-color: #e6fffa !important;
    background-color: rgba(230, 255, 250, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-200:focus {
    --bg-opacity: 1 !important;
    background-color: #b2f5ea !important;
    background-color: rgba(178, 245, 234, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-300:focus {
    --bg-opacity: 1 !important;
    background-color: #81e6d9 !important;
    background-color: rgba(129, 230, 217, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-400:focus {
    --bg-opacity: 1 !important;
    background-color: #4fd1c5 !important;
    background-color: rgba(79, 209, 197, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-500:focus {
    --bg-opacity: 1 !important;
    background-color: #38b2ac !important;
    background-color: rgba(56, 178, 172, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-600:focus {
    --bg-opacity: 1 !important;
    background-color: #319795 !important;
    background-color: rgba(49, 151, 149, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2c7a7b !important;
    background-color: rgba(44, 122, 123, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-800:focus {
    --bg-opacity: 1 !important;
    background-color: #285e61 !important;
    background-color: rgba(40, 94, 97, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-teal-900:focus {
    --bg-opacity: 1 !important;
    background-color: #234e52 !important;
    background-color: rgba(35, 78, 82, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf8ff !important;
    background-color: rgba(235, 248, 255, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-200:focus {
    --bg-opacity: 1 !important;
    background-color: #bee3f8 !important;
    background-color: rgba(190, 227, 248, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-300:focus {
    --bg-opacity: 1 !important;
    background-color: #90cdf4 !important;
    background-color: rgba(144, 205, 244, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-400:focus {
    --bg-opacity: 1 !important;
    background-color: #63b3ed !important;
    background-color: rgba(99, 179, 237, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-500:focus {
    --bg-opacity: 1 !important;
    background-color: #4299e1 !important;
    background-color: rgba(66, 153, 225, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-600:focus {
    --bg-opacity: 1 !important;
    background-color: #3182ce !important;
    background-color: rgba(49, 130, 206, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-700:focus {
    --bg-opacity: 1 !important;
    background-color: #2b6cb0 !important;
    background-color: rgba(43, 108, 176, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-800:focus {
    --bg-opacity: 1 !important;
    background-color: #2c5282 !important;
    background-color: rgba(44, 82, 130, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-blue-900:focus {
    --bg-opacity: 1 !important;
    background-color: #2a4365 !important;
    background-color: rgba(42, 67, 101, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1 !important;
    background-color: #ebf4ff !important;
    background-color: rgba(235, 244, 255, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1 !important;
    background-color: #c3dafe !important;
    background-color: rgba(195, 218, 254, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1 !important;
    background-color: #a3bffa !important;
    background-color: rgba(163, 191, 250, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1 !important;
    background-color: #7f9cf5 !important;
    background-color: rgba(127, 156, 245, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1 !important;
    background-color: #667eea !important;
    background-color: rgba(102, 126, 234, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1 !important;
    background-color: #5a67d8 !important;
    background-color: rgba(90, 103, 216, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1 !important;
    background-color: #4c51bf !important;
    background-color: rgba(76, 81, 191, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1 !important;
    background-color: #434190 !important;
    background-color: rgba(67, 65, 144, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1 !important;
    background-color: #3c366b !important;
    background-color: rgba(60, 54, 107, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-100:focus {
    --bg-opacity: 1 !important;
    background-color: #faf5ff !important;
    background-color: rgba(250, 245, 255, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-200:focus {
    --bg-opacity: 1 !important;
    background-color: #e9d8fd !important;
    background-color: rgba(233, 216, 253, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-300:focus {
    --bg-opacity: 1 !important;
    background-color: #d6bcfa !important;
    background-color: rgba(214, 188, 250, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-400:focus {
    --bg-opacity: 1 !important;
    background-color: #b794f4 !important;
    background-color: rgba(183, 148, 244, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-500:focus {
    --bg-opacity: 1 !important;
    background-color: #9f7aea !important;
    background-color: rgba(159, 122, 234, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-600:focus {
    --bg-opacity: 1 !important;
    background-color: #805ad5 !important;
    background-color: rgba(128, 90, 213, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-700:focus {
    --bg-opacity: 1 !important;
    background-color: #6b46c1 !important;
    background-color: rgba(107, 70, 193, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-800:focus {
    --bg-opacity: 1 !important;
    background-color: #553c9a !important;
    background-color: rgba(85, 60, 154, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-purple-900:focus {
    --bg-opacity: 1 !important;
    background-color: #44337a !important;
    background-color: rgba(68, 51, 122, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-100:focus {
    --bg-opacity: 1 !important;
    background-color: #fff5f7 !important;
    background-color: rgba(255, 245, 247, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-200:focus {
    --bg-opacity: 1 !important;
    background-color: #fed7e2 !important;
    background-color: rgba(254, 215, 226, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-300:focus {
    --bg-opacity: 1 !important;
    background-color: #fbb6ce !important;
    background-color: rgba(251, 182, 206, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-400:focus {
    --bg-opacity: 1 !important;
    background-color: #f687b3 !important;
    background-color: rgba(246, 135, 179, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-500:focus {
    --bg-opacity: 1 !important;
    background-color: #ed64a6 !important;
    background-color: rgba(237, 100, 166, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-600:focus {
    --bg-opacity: 1 !important;
    background-color: #d53f8c !important;
    background-color: rgba(213, 63, 140, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-700:focus {
    --bg-opacity: 1 !important;
    background-color: #b83280 !important;
    background-color: rgba(184, 50, 128, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-800:focus {
    --bg-opacity: 1 !important;
    background-color: #97266d !important;
    background-color: rgba(151, 38, 109, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-pink-900:focus {
    --bg-opacity: 1 !important;
    background-color: #702459 !important;
    background-color: rgba(112, 36, 89, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-primary:focus {
    background-color: var(--primary) !important;
  }

  .xxl\:focus\:bg-accent-1:focus {
    --bg-opacity: 1 !important;
    background-color: #fafafa !important;
    background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
  }

  .xxl\:focus\:bg-accent-2:focus {
    --bg-opacity: 1 !important;
    background-color: #eaeaea !important;
    background-color: rgba(234, 234, 234, var(--bg-opacity)) !important;
  }

  .xxl\:bg-none {
    background-image: none !important;
  }

  .xxl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops)) !important;
  }

  .xxl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops)) !important;
  }

  .xxl\:from-transparent {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:from-current {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:from-black {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:from-white {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:from-gray-100 {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xxl\:from-gray-200 {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xxl\:from-gray-300 {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xxl\:from-gray-400 {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xxl\:from-gray-500 {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xxl\:from-gray-600 {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xxl\:from-gray-700 {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xxl\:from-gray-800 {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xxl\:from-gray-900 {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xxl\:from-red-100 {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xxl\:from-red-200 {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xxl\:from-red-300 {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xxl\:from-red-400 {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xxl\:from-red-500 {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xxl\:from-red-600 {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xxl\:from-red-700 {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xxl\:from-red-800 {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xxl\:from-red-900 {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xxl\:from-orange-100 {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xxl\:from-orange-200 {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xxl\:from-orange-300 {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xxl\:from-orange-400 {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xxl\:from-orange-500 {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xxl\:from-orange-600 {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xxl\:from-orange-700 {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xxl\:from-orange-800 {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xxl\:from-orange-900 {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xxl\:from-yellow-100 {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xxl\:from-yellow-200 {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xxl\:from-yellow-300 {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xxl\:from-yellow-400 {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xxl\:from-yellow-500 {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xxl\:from-yellow-600 {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xxl\:from-yellow-700 {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xxl\:from-yellow-800 {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xxl\:from-yellow-900 {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xxl\:from-green-100 {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xxl\:from-green-200 {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xxl\:from-green-300 {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xxl\:from-green-400 {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xxl\:from-green-500 {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xxl\:from-green-600 {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xxl\:from-green-700 {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xxl\:from-green-800 {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xxl\:from-green-900 {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xxl\:from-teal-100 {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xxl\:from-teal-200 {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xxl\:from-teal-300 {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xxl\:from-teal-400 {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xxl\:from-teal-500 {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xxl\:from-teal-600 {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xxl\:from-teal-700 {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xxl\:from-teal-800 {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xxl\:from-teal-900 {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xxl\:from-blue-100 {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xxl\:from-blue-200 {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xxl\:from-blue-300 {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xxl\:from-blue-400 {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xxl\:from-blue-500 {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xxl\:from-blue-600 {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xxl\:from-blue-700 {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xxl\:from-blue-800 {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xxl\:from-blue-900 {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xxl\:from-indigo-100 {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xxl\:from-indigo-200 {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xxl\:from-indigo-300 {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xxl\:from-indigo-400 {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xxl\:from-indigo-500 {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xxl\:from-indigo-600 {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xxl\:from-indigo-700 {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xxl\:from-indigo-800 {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xxl\:from-indigo-900 {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xxl\:from-purple-100 {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xxl\:from-purple-200 {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xxl\:from-purple-300 {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xxl\:from-purple-400 {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xxl\:from-purple-500 {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xxl\:from-purple-600 {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xxl\:from-purple-700 {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xxl\:from-purple-800 {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xxl\:from-purple-900 {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xxl\:from-pink-100 {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xxl\:from-pink-200 {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xxl\:from-pink-300 {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xxl\:from-pink-400 {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xxl\:from-pink-500 {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xxl\:from-pink-600 {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xxl\:from-pink-700 {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xxl\:from-pink-800 {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xxl\:from-pink-900 {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xxl\:from-primary {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:from-accent-1 {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xxl\:from-accent-2 {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xxl\:via-transparent {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:via-current {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:via-black {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:via-white {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:via-gray-100 {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xxl\:via-gray-200 {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xxl\:via-gray-300 {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xxl\:via-gray-400 {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xxl\:via-gray-500 {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xxl\:via-gray-600 {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xxl\:via-gray-700 {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xxl\:via-gray-800 {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xxl\:via-gray-900 {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xxl\:via-red-100 {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xxl\:via-red-200 {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xxl\:via-red-300 {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xxl\:via-red-400 {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xxl\:via-red-500 {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xxl\:via-red-600 {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xxl\:via-red-700 {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xxl\:via-red-800 {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xxl\:via-red-900 {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xxl\:via-orange-100 {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xxl\:via-orange-200 {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xxl\:via-orange-300 {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xxl\:via-orange-400 {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xxl\:via-orange-500 {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xxl\:via-orange-600 {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xxl\:via-orange-700 {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xxl\:via-orange-800 {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xxl\:via-orange-900 {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xxl\:via-yellow-100 {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xxl\:via-yellow-200 {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xxl\:via-yellow-300 {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xxl\:via-yellow-400 {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xxl\:via-yellow-500 {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xxl\:via-yellow-600 {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xxl\:via-yellow-700 {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xxl\:via-yellow-800 {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xxl\:via-yellow-900 {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xxl\:via-green-100 {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xxl\:via-green-200 {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xxl\:via-green-300 {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xxl\:via-green-400 {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xxl\:via-green-500 {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xxl\:via-green-600 {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xxl\:via-green-700 {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xxl\:via-green-800 {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xxl\:via-green-900 {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xxl\:via-teal-100 {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xxl\:via-teal-200 {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xxl\:via-teal-300 {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xxl\:via-teal-400 {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xxl\:via-teal-500 {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xxl\:via-teal-600 {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xxl\:via-teal-700 {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xxl\:via-teal-800 {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xxl\:via-teal-900 {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xxl\:via-blue-100 {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xxl\:via-blue-200 {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xxl\:via-blue-300 {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xxl\:via-blue-400 {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xxl\:via-blue-500 {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xxl\:via-blue-600 {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xxl\:via-blue-700 {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xxl\:via-blue-800 {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xxl\:via-blue-900 {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xxl\:via-indigo-100 {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xxl\:via-indigo-200 {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xxl\:via-indigo-300 {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xxl\:via-indigo-400 {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xxl\:via-indigo-500 {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xxl\:via-indigo-600 {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xxl\:via-indigo-700 {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xxl\:via-indigo-800 {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xxl\:via-indigo-900 {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xxl\:via-purple-100 {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xxl\:via-purple-200 {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xxl\:via-purple-300 {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xxl\:via-purple-400 {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xxl\:via-purple-500 {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xxl\:via-purple-600 {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xxl\:via-purple-700 {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xxl\:via-purple-800 {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xxl\:via-purple-900 {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xxl\:via-pink-100 {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xxl\:via-pink-200 {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xxl\:via-pink-300 {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xxl\:via-pink-400 {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xxl\:via-pink-500 {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xxl\:via-pink-600 {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xxl\:via-pink-700 {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xxl\:via-pink-800 {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xxl\:via-pink-900 {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xxl\:via-primary {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:via-accent-1 {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xxl\:via-accent-2 {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xxl\:to-transparent {
    --gradient-to-color: transparent !important;
  }

  .xxl\:to-current {
    --gradient-to-color: currentColor !important;
  }

  .xxl\:to-black {
    --gradient-to-color: #000 !important;
  }

  .xxl\:to-white {
    --gradient-to-color: #fff !important;
  }

  .xxl\:to-gray-100 {
    --gradient-to-color: #f7fafc !important;
  }

  .xxl\:to-gray-200 {
    --gradient-to-color: #edf2f7 !important;
  }

  .xxl\:to-gray-300 {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xxl\:to-gray-400 {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xxl\:to-gray-500 {
    --gradient-to-color: #a0aec0 !important;
  }

  .xxl\:to-gray-600 {
    --gradient-to-color: #718096 !important;
  }

  .xxl\:to-gray-700 {
    --gradient-to-color: #4a5568 !important;
  }

  .xxl\:to-gray-800 {
    --gradient-to-color: #2d3748 !important;
  }

  .xxl\:to-gray-900 {
    --gradient-to-color: #1a202c !important;
  }

  .xxl\:to-red-100 {
    --gradient-to-color: #fff5f5 !important;
  }

  .xxl\:to-red-200 {
    --gradient-to-color: #fed7d7 !important;
  }

  .xxl\:to-red-300 {
    --gradient-to-color: #feb2b2 !important;
  }

  .xxl\:to-red-400 {
    --gradient-to-color: #fc8181 !important;
  }

  .xxl\:to-red-500 {
    --gradient-to-color: #f56565 !important;
  }

  .xxl\:to-red-600 {
    --gradient-to-color: #e53e3e !important;
  }

  .xxl\:to-red-700 {
    --gradient-to-color: #c53030 !important;
  }

  .xxl\:to-red-800 {
    --gradient-to-color: #9b2c2c !important;
  }

  .xxl\:to-red-900 {
    --gradient-to-color: #742a2a !important;
  }

  .xxl\:to-orange-100 {
    --gradient-to-color: #fffaf0 !important;
  }

  .xxl\:to-orange-200 {
    --gradient-to-color: #feebc8 !important;
  }

  .xxl\:to-orange-300 {
    --gradient-to-color: #fbd38d !important;
  }

  .xxl\:to-orange-400 {
    --gradient-to-color: #f6ad55 !important;
  }

  .xxl\:to-orange-500 {
    --gradient-to-color: #ed8936 !important;
  }

  .xxl\:to-orange-600 {
    --gradient-to-color: #dd6b20 !important;
  }

  .xxl\:to-orange-700 {
    --gradient-to-color: #c05621 !important;
  }

  .xxl\:to-orange-800 {
    --gradient-to-color: #9c4221 !important;
  }

  .xxl\:to-orange-900 {
    --gradient-to-color: #7b341e !important;
  }

  .xxl\:to-yellow-100 {
    --gradient-to-color: #fffff0 !important;
  }

  .xxl\:to-yellow-200 {
    --gradient-to-color: #fefcbf !important;
  }

  .xxl\:to-yellow-300 {
    --gradient-to-color: #faf089 !important;
  }

  .xxl\:to-yellow-400 {
    --gradient-to-color: #f6e05e !important;
  }

  .xxl\:to-yellow-500 {
    --gradient-to-color: #ecc94b !important;
  }

  .xxl\:to-yellow-600 {
    --gradient-to-color: #d69e2e !important;
  }

  .xxl\:to-yellow-700 {
    --gradient-to-color: #b7791f !important;
  }

  .xxl\:to-yellow-800 {
    --gradient-to-color: #975a16 !important;
  }

  .xxl\:to-yellow-900 {
    --gradient-to-color: #744210 !important;
  }

  .xxl\:to-green-100 {
    --gradient-to-color: #f0fff4 !important;
  }

  .xxl\:to-green-200 {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xxl\:to-green-300 {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xxl\:to-green-400 {
    --gradient-to-color: #68d391 !important;
  }

  .xxl\:to-green-500 {
    --gradient-to-color: #48bb78 !important;
  }

  .xxl\:to-green-600 {
    --gradient-to-color: #38a169 !important;
  }

  .xxl\:to-green-700 {
    --gradient-to-color: #2f855a !important;
  }

  .xxl\:to-green-800 {
    --gradient-to-color: #276749 !important;
  }

  .xxl\:to-green-900 {
    --gradient-to-color: #22543d !important;
  }

  .xxl\:to-teal-100 {
    --gradient-to-color: #e6fffa !important;
  }

  .xxl\:to-teal-200 {
    --gradient-to-color: #b2f5ea !important;
  }

  .xxl\:to-teal-300 {
    --gradient-to-color: #81e6d9 !important;
  }

  .xxl\:to-teal-400 {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xxl\:to-teal-500 {
    --gradient-to-color: #38b2ac !important;
  }

  .xxl\:to-teal-600 {
    --gradient-to-color: #319795 !important;
  }

  .xxl\:to-teal-700 {
    --gradient-to-color: #2c7a7b !important;
  }

  .xxl\:to-teal-800 {
    --gradient-to-color: #285e61 !important;
  }

  .xxl\:to-teal-900 {
    --gradient-to-color: #234e52 !important;
  }

  .xxl\:to-blue-100 {
    --gradient-to-color: #ebf8ff !important;
  }

  .xxl\:to-blue-200 {
    --gradient-to-color: #bee3f8 !important;
  }

  .xxl\:to-blue-300 {
    --gradient-to-color: #90cdf4 !important;
  }

  .xxl\:to-blue-400 {
    --gradient-to-color: #63b3ed !important;
  }

  .xxl\:to-blue-500 {
    --gradient-to-color: #4299e1 !important;
  }

  .xxl\:to-blue-600 {
    --gradient-to-color: #3182ce !important;
  }

  .xxl\:to-blue-700 {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xxl\:to-blue-800 {
    --gradient-to-color: #2c5282 !important;
  }

  .xxl\:to-blue-900 {
    --gradient-to-color: #2a4365 !important;
  }

  .xxl\:to-indigo-100 {
    --gradient-to-color: #ebf4ff !important;
  }

  .xxl\:to-indigo-200 {
    --gradient-to-color: #c3dafe !important;
  }

  .xxl\:to-indigo-300 {
    --gradient-to-color: #a3bffa !important;
  }

  .xxl\:to-indigo-400 {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xxl\:to-indigo-500 {
    --gradient-to-color: #667eea !important;
  }

  .xxl\:to-indigo-600 {
    --gradient-to-color: #5a67d8 !important;
  }

  .xxl\:to-indigo-700 {
    --gradient-to-color: #4c51bf !important;
  }

  .xxl\:to-indigo-800 {
    --gradient-to-color: #434190 !important;
  }

  .xxl\:to-indigo-900 {
    --gradient-to-color: #3c366b !important;
  }

  .xxl\:to-purple-100 {
    --gradient-to-color: #faf5ff !important;
  }

  .xxl\:to-purple-200 {
    --gradient-to-color: #e9d8fd !important;
  }

  .xxl\:to-purple-300 {
    --gradient-to-color: #d6bcfa !important;
  }

  .xxl\:to-purple-400 {
    --gradient-to-color: #b794f4 !important;
  }

  .xxl\:to-purple-500 {
    --gradient-to-color: #9f7aea !important;
  }

  .xxl\:to-purple-600 {
    --gradient-to-color: #805ad5 !important;
  }

  .xxl\:to-purple-700 {
    --gradient-to-color: #6b46c1 !important;
  }

  .xxl\:to-purple-800 {
    --gradient-to-color: #553c9a !important;
  }

  .xxl\:to-purple-900 {
    --gradient-to-color: #44337a !important;
  }

  .xxl\:to-pink-100 {
    --gradient-to-color: #fff5f7 !important;
  }

  .xxl\:to-pink-200 {
    --gradient-to-color: #fed7e2 !important;
  }

  .xxl\:to-pink-300 {
    --gradient-to-color: #fbb6ce !important;
  }

  .xxl\:to-pink-400 {
    --gradient-to-color: #f687b3 !important;
  }

  .xxl\:to-pink-500 {
    --gradient-to-color: #ed64a6 !important;
  }

  .xxl\:to-pink-600 {
    --gradient-to-color: #d53f8c !important;
  }

  .xxl\:to-pink-700 {
    --gradient-to-color: #b83280 !important;
  }

  .xxl\:to-pink-800 {
    --gradient-to-color: #97266d !important;
  }

  .xxl\:to-pink-900 {
    --gradient-to-color: #702459 !important;
  }

  .xxl\:to-primary {
    --gradient-to-color: var(--primary) !important;
  }

  .xxl\:to-accent-1 {
    --gradient-to-color: #fafafa !important;
  }

  .xxl\:to-accent-2 {
    --gradient-to-color: #eaeaea !important;
  }

  .xxl\:hover\:from-transparent:hover {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:hover\:from-current:hover {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:hover\:from-black:hover {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:hover\:from-white:hover {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:hover\:from-gray-100:hover {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xxl\:hover\:from-gray-200:hover {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xxl\:hover\:from-gray-300:hover {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xxl\:hover\:from-gray-400:hover {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xxl\:hover\:from-gray-500:hover {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xxl\:hover\:from-gray-600:hover {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xxl\:hover\:from-gray-700:hover {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xxl\:hover\:from-gray-800:hover {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xxl\:hover\:from-gray-900:hover {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xxl\:hover\:from-red-100:hover {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xxl\:hover\:from-red-200:hover {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xxl\:hover\:from-red-300:hover {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xxl\:hover\:from-red-400:hover {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xxl\:hover\:from-red-500:hover {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xxl\:hover\:from-red-600:hover {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xxl\:hover\:from-red-700:hover {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xxl\:hover\:from-red-800:hover {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xxl\:hover\:from-red-900:hover {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xxl\:hover\:from-orange-100:hover {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xxl\:hover\:from-orange-200:hover {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xxl\:hover\:from-orange-300:hover {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xxl\:hover\:from-orange-400:hover {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xxl\:hover\:from-orange-500:hover {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xxl\:hover\:from-orange-600:hover {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xxl\:hover\:from-orange-700:hover {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xxl\:hover\:from-orange-800:hover {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xxl\:hover\:from-orange-900:hover {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xxl\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xxl\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xxl\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xxl\:hover\:from-yellow-400:hover {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xxl\:hover\:from-yellow-500:hover {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xxl\:hover\:from-yellow-600:hover {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xxl\:hover\:from-yellow-700:hover {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xxl\:hover\:from-yellow-800:hover {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xxl\:hover\:from-yellow-900:hover {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xxl\:hover\:from-green-100:hover {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xxl\:hover\:from-green-200:hover {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xxl\:hover\:from-green-300:hover {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xxl\:hover\:from-green-400:hover {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xxl\:hover\:from-green-500:hover {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xxl\:hover\:from-green-600:hover {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xxl\:hover\:from-green-700:hover {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xxl\:hover\:from-green-800:hover {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xxl\:hover\:from-green-900:hover {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xxl\:hover\:from-teal-100:hover {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xxl\:hover\:from-teal-200:hover {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xxl\:hover\:from-teal-300:hover {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xxl\:hover\:from-teal-400:hover {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xxl\:hover\:from-teal-500:hover {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xxl\:hover\:from-teal-600:hover {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xxl\:hover\:from-teal-700:hover {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xxl\:hover\:from-teal-800:hover {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xxl\:hover\:from-teal-900:hover {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xxl\:hover\:from-blue-100:hover {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xxl\:hover\:from-blue-200:hover {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xxl\:hover\:from-blue-300:hover {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xxl\:hover\:from-blue-400:hover {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xxl\:hover\:from-blue-500:hover {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xxl\:hover\:from-blue-600:hover {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xxl\:hover\:from-blue-700:hover {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xxl\:hover\:from-blue-800:hover {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xxl\:hover\:from-blue-900:hover {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xxl\:hover\:from-indigo-100:hover {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xxl\:hover\:from-indigo-200:hover {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xxl\:hover\:from-indigo-300:hover {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xxl\:hover\:from-indigo-400:hover {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xxl\:hover\:from-indigo-500:hover {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xxl\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xxl\:hover\:from-indigo-700:hover {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xxl\:hover\:from-indigo-800:hover {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xxl\:hover\:from-indigo-900:hover {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xxl\:hover\:from-purple-100:hover {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xxl\:hover\:from-purple-200:hover {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xxl\:hover\:from-purple-300:hover {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xxl\:hover\:from-purple-400:hover {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xxl\:hover\:from-purple-500:hover {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xxl\:hover\:from-purple-600:hover {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xxl\:hover\:from-purple-700:hover {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xxl\:hover\:from-purple-800:hover {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xxl\:hover\:from-purple-900:hover {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xxl\:hover\:from-pink-100:hover {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xxl\:hover\:from-pink-200:hover {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xxl\:hover\:from-pink-300:hover {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xxl\:hover\:from-pink-400:hover {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xxl\:hover\:from-pink-500:hover {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xxl\:hover\:from-pink-600:hover {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xxl\:hover\:from-pink-700:hover {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xxl\:hover\:from-pink-800:hover {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xxl\:hover\:from-pink-900:hover {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xxl\:hover\:from-primary:hover {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:hover\:from-accent-1:hover {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xxl\:hover\:from-accent-2:hover {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xxl\:hover\:via-transparent:hover {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:hover\:via-current:hover {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:hover\:via-black:hover {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:hover\:via-white:hover {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:hover\:via-gray-100:hover {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xxl\:hover\:via-gray-200:hover {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xxl\:hover\:via-gray-300:hover {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xxl\:hover\:via-gray-400:hover {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xxl\:hover\:via-gray-500:hover {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xxl\:hover\:via-gray-600:hover {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xxl\:hover\:via-gray-700:hover {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xxl\:hover\:via-gray-800:hover {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xxl\:hover\:via-gray-900:hover {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xxl\:hover\:via-red-100:hover {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xxl\:hover\:via-red-200:hover {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xxl\:hover\:via-red-300:hover {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xxl\:hover\:via-red-400:hover {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xxl\:hover\:via-red-500:hover {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xxl\:hover\:via-red-600:hover {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xxl\:hover\:via-red-700:hover {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xxl\:hover\:via-red-800:hover {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xxl\:hover\:via-red-900:hover {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xxl\:hover\:via-orange-100:hover {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xxl\:hover\:via-orange-200:hover {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xxl\:hover\:via-orange-300:hover {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xxl\:hover\:via-orange-400:hover {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xxl\:hover\:via-orange-500:hover {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xxl\:hover\:via-orange-600:hover {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xxl\:hover\:via-orange-700:hover {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xxl\:hover\:via-orange-800:hover {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xxl\:hover\:via-orange-900:hover {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xxl\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xxl\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xxl\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xxl\:hover\:via-yellow-400:hover {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xxl\:hover\:via-yellow-500:hover {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xxl\:hover\:via-yellow-600:hover {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xxl\:hover\:via-yellow-700:hover {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xxl\:hover\:via-yellow-800:hover {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xxl\:hover\:via-yellow-900:hover {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xxl\:hover\:via-green-100:hover {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xxl\:hover\:via-green-200:hover {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xxl\:hover\:via-green-300:hover {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xxl\:hover\:via-green-400:hover {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xxl\:hover\:via-green-500:hover {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xxl\:hover\:via-green-600:hover {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xxl\:hover\:via-green-700:hover {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xxl\:hover\:via-green-800:hover {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xxl\:hover\:via-green-900:hover {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xxl\:hover\:via-teal-100:hover {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xxl\:hover\:via-teal-200:hover {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xxl\:hover\:via-teal-300:hover {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xxl\:hover\:via-teal-400:hover {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xxl\:hover\:via-teal-500:hover {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xxl\:hover\:via-teal-600:hover {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xxl\:hover\:via-teal-700:hover {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xxl\:hover\:via-teal-800:hover {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xxl\:hover\:via-teal-900:hover {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xxl\:hover\:via-blue-100:hover {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xxl\:hover\:via-blue-200:hover {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xxl\:hover\:via-blue-300:hover {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xxl\:hover\:via-blue-400:hover {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xxl\:hover\:via-blue-500:hover {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xxl\:hover\:via-blue-600:hover {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xxl\:hover\:via-blue-700:hover {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xxl\:hover\:via-blue-800:hover {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xxl\:hover\:via-blue-900:hover {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xxl\:hover\:via-indigo-100:hover {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xxl\:hover\:via-indigo-200:hover {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xxl\:hover\:via-indigo-300:hover {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xxl\:hover\:via-indigo-400:hover {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xxl\:hover\:via-indigo-500:hover {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xxl\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xxl\:hover\:via-indigo-700:hover {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xxl\:hover\:via-indigo-800:hover {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xxl\:hover\:via-indigo-900:hover {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xxl\:hover\:via-purple-100:hover {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xxl\:hover\:via-purple-200:hover {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xxl\:hover\:via-purple-300:hover {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xxl\:hover\:via-purple-400:hover {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xxl\:hover\:via-purple-500:hover {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xxl\:hover\:via-purple-600:hover {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xxl\:hover\:via-purple-700:hover {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xxl\:hover\:via-purple-800:hover {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xxl\:hover\:via-purple-900:hover {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xxl\:hover\:via-pink-100:hover {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xxl\:hover\:via-pink-200:hover {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xxl\:hover\:via-pink-300:hover {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xxl\:hover\:via-pink-400:hover {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xxl\:hover\:via-pink-500:hover {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xxl\:hover\:via-pink-600:hover {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xxl\:hover\:via-pink-700:hover {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xxl\:hover\:via-pink-800:hover {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xxl\:hover\:via-pink-900:hover {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xxl\:hover\:via-primary:hover {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:hover\:via-accent-1:hover {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xxl\:hover\:via-accent-2:hover {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xxl\:hover\:to-transparent:hover {
    --gradient-to-color: transparent !important;
  }

  .xxl\:hover\:to-current:hover {
    --gradient-to-color: currentColor !important;
  }

  .xxl\:hover\:to-black:hover {
    --gradient-to-color: #000 !important;
  }

  .xxl\:hover\:to-white:hover {
    --gradient-to-color: #fff !important;
  }

  .xxl\:hover\:to-gray-100:hover {
    --gradient-to-color: #f7fafc !important;
  }

  .xxl\:hover\:to-gray-200:hover {
    --gradient-to-color: #edf2f7 !important;
  }

  .xxl\:hover\:to-gray-300:hover {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xxl\:hover\:to-gray-400:hover {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xxl\:hover\:to-gray-500:hover {
    --gradient-to-color: #a0aec0 !important;
  }

  .xxl\:hover\:to-gray-600:hover {
    --gradient-to-color: #718096 !important;
  }

  .xxl\:hover\:to-gray-700:hover {
    --gradient-to-color: #4a5568 !important;
  }

  .xxl\:hover\:to-gray-800:hover {
    --gradient-to-color: #2d3748 !important;
  }

  .xxl\:hover\:to-gray-900:hover {
    --gradient-to-color: #1a202c !important;
  }

  .xxl\:hover\:to-red-100:hover {
    --gradient-to-color: #fff5f5 !important;
  }

  .xxl\:hover\:to-red-200:hover {
    --gradient-to-color: #fed7d7 !important;
  }

  .xxl\:hover\:to-red-300:hover {
    --gradient-to-color: #feb2b2 !important;
  }

  .xxl\:hover\:to-red-400:hover {
    --gradient-to-color: #fc8181 !important;
  }

  .xxl\:hover\:to-red-500:hover {
    --gradient-to-color: #f56565 !important;
  }

  .xxl\:hover\:to-red-600:hover {
    --gradient-to-color: #e53e3e !important;
  }

  .xxl\:hover\:to-red-700:hover {
    --gradient-to-color: #c53030 !important;
  }

  .xxl\:hover\:to-red-800:hover {
    --gradient-to-color: #9b2c2c !important;
  }

  .xxl\:hover\:to-red-900:hover {
    --gradient-to-color: #742a2a !important;
  }

  .xxl\:hover\:to-orange-100:hover {
    --gradient-to-color: #fffaf0 !important;
  }

  .xxl\:hover\:to-orange-200:hover {
    --gradient-to-color: #feebc8 !important;
  }

  .xxl\:hover\:to-orange-300:hover {
    --gradient-to-color: #fbd38d !important;
  }

  .xxl\:hover\:to-orange-400:hover {
    --gradient-to-color: #f6ad55 !important;
  }

  .xxl\:hover\:to-orange-500:hover {
    --gradient-to-color: #ed8936 !important;
  }

  .xxl\:hover\:to-orange-600:hover {
    --gradient-to-color: #dd6b20 !important;
  }

  .xxl\:hover\:to-orange-700:hover {
    --gradient-to-color: #c05621 !important;
  }

  .xxl\:hover\:to-orange-800:hover {
    --gradient-to-color: #9c4221 !important;
  }

  .xxl\:hover\:to-orange-900:hover {
    --gradient-to-color: #7b341e !important;
  }

  .xxl\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fffff0 !important;
  }

  .xxl\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fefcbf !important;
  }

  .xxl\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faf089 !important;
  }

  .xxl\:hover\:to-yellow-400:hover {
    --gradient-to-color: #f6e05e !important;
  }

  .xxl\:hover\:to-yellow-500:hover {
    --gradient-to-color: #ecc94b !important;
  }

  .xxl\:hover\:to-yellow-600:hover {
    --gradient-to-color: #d69e2e !important;
  }

  .xxl\:hover\:to-yellow-700:hover {
    --gradient-to-color: #b7791f !important;
  }

  .xxl\:hover\:to-yellow-800:hover {
    --gradient-to-color: #975a16 !important;
  }

  .xxl\:hover\:to-yellow-900:hover {
    --gradient-to-color: #744210 !important;
  }

  .xxl\:hover\:to-green-100:hover {
    --gradient-to-color: #f0fff4 !important;
  }

  .xxl\:hover\:to-green-200:hover {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xxl\:hover\:to-green-300:hover {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xxl\:hover\:to-green-400:hover {
    --gradient-to-color: #68d391 !important;
  }

  .xxl\:hover\:to-green-500:hover {
    --gradient-to-color: #48bb78 !important;
  }

  .xxl\:hover\:to-green-600:hover {
    --gradient-to-color: #38a169 !important;
  }

  .xxl\:hover\:to-green-700:hover {
    --gradient-to-color: #2f855a !important;
  }

  .xxl\:hover\:to-green-800:hover {
    --gradient-to-color: #276749 !important;
  }

  .xxl\:hover\:to-green-900:hover {
    --gradient-to-color: #22543d !important;
  }

  .xxl\:hover\:to-teal-100:hover {
    --gradient-to-color: #e6fffa !important;
  }

  .xxl\:hover\:to-teal-200:hover {
    --gradient-to-color: #b2f5ea !important;
  }

  .xxl\:hover\:to-teal-300:hover {
    --gradient-to-color: #81e6d9 !important;
  }

  .xxl\:hover\:to-teal-400:hover {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xxl\:hover\:to-teal-500:hover {
    --gradient-to-color: #38b2ac !important;
  }

  .xxl\:hover\:to-teal-600:hover {
    --gradient-to-color: #319795 !important;
  }

  .xxl\:hover\:to-teal-700:hover {
    --gradient-to-color: #2c7a7b !important;
  }

  .xxl\:hover\:to-teal-800:hover {
    --gradient-to-color: #285e61 !important;
  }

  .xxl\:hover\:to-teal-900:hover {
    --gradient-to-color: #234e52 !important;
  }

  .xxl\:hover\:to-blue-100:hover {
    --gradient-to-color: #ebf8ff !important;
  }

  .xxl\:hover\:to-blue-200:hover {
    --gradient-to-color: #bee3f8 !important;
  }

  .xxl\:hover\:to-blue-300:hover {
    --gradient-to-color: #90cdf4 !important;
  }

  .xxl\:hover\:to-blue-400:hover {
    --gradient-to-color: #63b3ed !important;
  }

  .xxl\:hover\:to-blue-500:hover {
    --gradient-to-color: #4299e1 !important;
  }

  .xxl\:hover\:to-blue-600:hover {
    --gradient-to-color: #3182ce !important;
  }

  .xxl\:hover\:to-blue-700:hover {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xxl\:hover\:to-blue-800:hover {
    --gradient-to-color: #2c5282 !important;
  }

  .xxl\:hover\:to-blue-900:hover {
    --gradient-to-color: #2a4365 !important;
  }

  .xxl\:hover\:to-indigo-100:hover {
    --gradient-to-color: #ebf4ff !important;
  }

  .xxl\:hover\:to-indigo-200:hover {
    --gradient-to-color: #c3dafe !important;
  }

  .xxl\:hover\:to-indigo-300:hover {
    --gradient-to-color: #a3bffa !important;
  }

  .xxl\:hover\:to-indigo-400:hover {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xxl\:hover\:to-indigo-500:hover {
    --gradient-to-color: #667eea !important;
  }

  .xxl\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5a67d8 !important;
  }

  .xxl\:hover\:to-indigo-700:hover {
    --gradient-to-color: #4c51bf !important;
  }

  .xxl\:hover\:to-indigo-800:hover {
    --gradient-to-color: #434190 !important;
  }

  .xxl\:hover\:to-indigo-900:hover {
    --gradient-to-color: #3c366b !important;
  }

  .xxl\:hover\:to-purple-100:hover {
    --gradient-to-color: #faf5ff !important;
  }

  .xxl\:hover\:to-purple-200:hover {
    --gradient-to-color: #e9d8fd !important;
  }

  .xxl\:hover\:to-purple-300:hover {
    --gradient-to-color: #d6bcfa !important;
  }

  .xxl\:hover\:to-purple-400:hover {
    --gradient-to-color: #b794f4 !important;
  }

  .xxl\:hover\:to-purple-500:hover {
    --gradient-to-color: #9f7aea !important;
  }

  .xxl\:hover\:to-purple-600:hover {
    --gradient-to-color: #805ad5 !important;
  }

  .xxl\:hover\:to-purple-700:hover {
    --gradient-to-color: #6b46c1 !important;
  }

  .xxl\:hover\:to-purple-800:hover {
    --gradient-to-color: #553c9a !important;
  }

  .xxl\:hover\:to-purple-900:hover {
    --gradient-to-color: #44337a !important;
  }

  .xxl\:hover\:to-pink-100:hover {
    --gradient-to-color: #fff5f7 !important;
  }

  .xxl\:hover\:to-pink-200:hover {
    --gradient-to-color: #fed7e2 !important;
  }

  .xxl\:hover\:to-pink-300:hover {
    --gradient-to-color: #fbb6ce !important;
  }

  .xxl\:hover\:to-pink-400:hover {
    --gradient-to-color: #f687b3 !important;
  }

  .xxl\:hover\:to-pink-500:hover {
    --gradient-to-color: #ed64a6 !important;
  }

  .xxl\:hover\:to-pink-600:hover {
    --gradient-to-color: #d53f8c !important;
  }

  .xxl\:hover\:to-pink-700:hover {
    --gradient-to-color: #b83280 !important;
  }

  .xxl\:hover\:to-pink-800:hover {
    --gradient-to-color: #97266d !important;
  }

  .xxl\:hover\:to-pink-900:hover {
    --gradient-to-color: #702459 !important;
  }

  .xxl\:hover\:to-primary:hover {
    --gradient-to-color: var(--primary) !important;
  }

  .xxl\:hover\:to-accent-1:hover {
    --gradient-to-color: #fafafa !important;
  }

  .xxl\:hover\:to-accent-2:hover {
    --gradient-to-color: #eaeaea !important;
  }

  .xxl\:focus\:from-transparent:focus {
    --gradient-from-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:focus\:from-current:focus {
    --gradient-from-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:focus\:from-black:focus {
    --gradient-from-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:focus\:from-white:focus {
    --gradient-from-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:focus\:from-gray-100:focus {
    --gradient-from-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xxl\:focus\:from-gray-200:focus {
    --gradient-from-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xxl\:focus\:from-gray-300:focus {
    --gradient-from-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xxl\:focus\:from-gray-400:focus {
    --gradient-from-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xxl\:focus\:from-gray-500:focus {
    --gradient-from-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xxl\:focus\:from-gray-600:focus {
    --gradient-from-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xxl\:focus\:from-gray-700:focus {
    --gradient-from-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xxl\:focus\:from-gray-800:focus {
    --gradient-from-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xxl\:focus\:from-gray-900:focus {
    --gradient-from-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xxl\:focus\:from-red-100:focus {
    --gradient-from-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xxl\:focus\:from-red-200:focus {
    --gradient-from-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xxl\:focus\:from-red-300:focus {
    --gradient-from-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xxl\:focus\:from-red-400:focus {
    --gradient-from-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xxl\:focus\:from-red-500:focus {
    --gradient-from-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xxl\:focus\:from-red-600:focus {
    --gradient-from-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xxl\:focus\:from-red-700:focus {
    --gradient-from-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xxl\:focus\:from-red-800:focus {
    --gradient-from-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xxl\:focus\:from-red-900:focus {
    --gradient-from-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xxl\:focus\:from-orange-100:focus {
    --gradient-from-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xxl\:focus\:from-orange-200:focus {
    --gradient-from-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xxl\:focus\:from-orange-300:focus {
    --gradient-from-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xxl\:focus\:from-orange-400:focus {
    --gradient-from-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xxl\:focus\:from-orange-500:focus {
    --gradient-from-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xxl\:focus\:from-orange-600:focus {
    --gradient-from-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xxl\:focus\:from-orange-700:focus {
    --gradient-from-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xxl\:focus\:from-orange-800:focus {
    --gradient-from-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xxl\:focus\:from-orange-900:focus {
    --gradient-from-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xxl\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xxl\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xxl\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xxl\:focus\:from-yellow-400:focus {
    --gradient-from-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xxl\:focus\:from-yellow-500:focus {
    --gradient-from-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xxl\:focus\:from-yellow-600:focus {
    --gradient-from-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xxl\:focus\:from-yellow-700:focus {
    --gradient-from-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xxl\:focus\:from-yellow-800:focus {
    --gradient-from-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xxl\:focus\:from-yellow-900:focus {
    --gradient-from-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xxl\:focus\:from-green-100:focus {
    --gradient-from-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xxl\:focus\:from-green-200:focus {
    --gradient-from-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xxl\:focus\:from-green-300:focus {
    --gradient-from-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xxl\:focus\:from-green-400:focus {
    --gradient-from-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xxl\:focus\:from-green-500:focus {
    --gradient-from-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xxl\:focus\:from-green-600:focus {
    --gradient-from-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xxl\:focus\:from-green-700:focus {
    --gradient-from-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xxl\:focus\:from-green-800:focus {
    --gradient-from-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xxl\:focus\:from-green-900:focus {
    --gradient-from-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xxl\:focus\:from-teal-100:focus {
    --gradient-from-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xxl\:focus\:from-teal-200:focus {
    --gradient-from-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xxl\:focus\:from-teal-300:focus {
    --gradient-from-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xxl\:focus\:from-teal-400:focus {
    --gradient-from-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xxl\:focus\:from-teal-500:focus {
    --gradient-from-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xxl\:focus\:from-teal-600:focus {
    --gradient-from-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xxl\:focus\:from-teal-700:focus {
    --gradient-from-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xxl\:focus\:from-teal-800:focus {
    --gradient-from-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xxl\:focus\:from-teal-900:focus {
    --gradient-from-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xxl\:focus\:from-blue-100:focus {
    --gradient-from-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xxl\:focus\:from-blue-200:focus {
    --gradient-from-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xxl\:focus\:from-blue-300:focus {
    --gradient-from-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xxl\:focus\:from-blue-400:focus {
    --gradient-from-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xxl\:focus\:from-blue-500:focus {
    --gradient-from-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xxl\:focus\:from-blue-600:focus {
    --gradient-from-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xxl\:focus\:from-blue-700:focus {
    --gradient-from-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xxl\:focus\:from-blue-800:focus {
    --gradient-from-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xxl\:focus\:from-blue-900:focus {
    --gradient-from-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xxl\:focus\:from-indigo-100:focus {
    --gradient-from-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xxl\:focus\:from-indigo-200:focus {
    --gradient-from-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xxl\:focus\:from-indigo-300:focus {
    --gradient-from-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xxl\:focus\:from-indigo-400:focus {
    --gradient-from-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xxl\:focus\:from-indigo-500:focus {
    --gradient-from-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xxl\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xxl\:focus\:from-indigo-700:focus {
    --gradient-from-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xxl\:focus\:from-indigo-800:focus {
    --gradient-from-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xxl\:focus\:from-indigo-900:focus {
    --gradient-from-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xxl\:focus\:from-purple-100:focus {
    --gradient-from-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xxl\:focus\:from-purple-200:focus {
    --gradient-from-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xxl\:focus\:from-purple-300:focus {
    --gradient-from-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xxl\:focus\:from-purple-400:focus {
    --gradient-from-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xxl\:focus\:from-purple-500:focus {
    --gradient-from-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xxl\:focus\:from-purple-600:focus {
    --gradient-from-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xxl\:focus\:from-purple-700:focus {
    --gradient-from-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xxl\:focus\:from-purple-800:focus {
    --gradient-from-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xxl\:focus\:from-purple-900:focus {
    --gradient-from-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xxl\:focus\:from-pink-100:focus {
    --gradient-from-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xxl\:focus\:from-pink-200:focus {
    --gradient-from-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xxl\:focus\:from-pink-300:focus {
    --gradient-from-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xxl\:focus\:from-pink-400:focus {
    --gradient-from-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xxl\:focus\:from-pink-500:focus {
    --gradient-from-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xxl\:focus\:from-pink-600:focus {
    --gradient-from-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xxl\:focus\:from-pink-700:focus {
    --gradient-from-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xxl\:focus\:from-pink-800:focus {
    --gradient-from-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xxl\:focus\:from-pink-900:focus {
    --gradient-from-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xxl\:focus\:from-primary:focus {
    --gradient-from-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:focus\:from-accent-1:focus {
    --gradient-from-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xxl\:focus\:from-accent-2:focus {
    --gradient-from-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xxl\:focus\:via-transparent:focus {
    --gradient-via-color: transparent !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:focus\:via-current:focus {
    --gradient-via-color: currentColor !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:focus\:via-black:focus {
    --gradient-via-color: #000 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(0, 0, 0, 0)) !important;
  }

  .xxl\:focus\:via-white:focus {
    --gradient-via-color: #fff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:focus\:via-gray-100:focus {
    --gradient-via-color: #f7fafc !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(247, 250, 252, 0)) !important;
  }

  .xxl\:focus\:via-gray-200:focus {
    --gradient-via-color: #edf2f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 242, 247, 0)) !important;
  }

  .xxl\:focus\:via-gray-300:focus {
    --gradient-via-color: #e2e8f0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(226, 232, 240, 0)) !important;
  }

  .xxl\:focus\:via-gray-400:focus {
    --gradient-via-color: #cbd5e0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(203, 213, 224, 0)) !important;
  }

  .xxl\:focus\:via-gray-500:focus {
    --gradient-via-color: #a0aec0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(160, 174, 192, 0)) !important;
  }

  .xxl\:focus\:via-gray-600:focus {
    --gradient-via-color: #718096 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(113, 128, 150, 0)) !important;
  }

  .xxl\:focus\:via-gray-700:focus {
    --gradient-via-color: #4a5568 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(74, 85, 104, 0)) !important;
  }

  .xxl\:focus\:via-gray-800:focus {
    --gradient-via-color: #2d3748 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(45, 55, 72, 0)) !important;
  }

  .xxl\:focus\:via-gray-900:focus {
    --gradient-via-color: #1a202c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(26, 32, 44, 0)) !important;
  }

  .xxl\:focus\:via-red-100:focus {
    --gradient-via-color: #fff5f5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 245, 0)) !important;
  }

  .xxl\:focus\:via-red-200:focus {
    --gradient-via-color: #fed7d7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 215, 0)) !important;
  }

  .xxl\:focus\:via-red-300:focus {
    --gradient-via-color: #feb2b2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 178, 178, 0)) !important;
  }

  .xxl\:focus\:via-red-400:focus {
    --gradient-via-color: #fc8181 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(252, 129, 129, 0)) !important;
  }

  .xxl\:focus\:via-red-500:focus {
    --gradient-via-color: #f56565 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(245, 101, 101, 0)) !important;
  }

  .xxl\:focus\:via-red-600:focus {
    --gradient-via-color: #e53e3e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(229, 62, 62, 0)) !important;
  }

  .xxl\:focus\:via-red-700:focus {
    --gradient-via-color: #c53030 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(197, 48, 48, 0)) !important;
  }

  .xxl\:focus\:via-red-800:focus {
    --gradient-via-color: #9b2c2c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(155, 44, 44, 0)) !important;
  }

  .xxl\:focus\:via-red-900:focus {
    --gradient-via-color: #742a2a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 42, 42, 0)) !important;
  }

  .xxl\:focus\:via-orange-100:focus {
    --gradient-via-color: #fffaf0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 250, 240, 0)) !important;
  }

  .xxl\:focus\:via-orange-200:focus {
    --gradient-via-color: #feebc8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 235, 200, 0)) !important;
  }

  .xxl\:focus\:via-orange-300:focus {
    --gradient-via-color: #fbd38d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 211, 141, 0)) !important;
  }

  .xxl\:focus\:via-orange-400:focus {
    --gradient-via-color: #f6ad55 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 173, 85, 0)) !important;
  }

  .xxl\:focus\:via-orange-500:focus {
    --gradient-via-color: #ed8936 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 137, 54, 0)) !important;
  }

  .xxl\:focus\:via-orange-600:focus {
    --gradient-via-color: #dd6b20 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(221, 107, 32, 0)) !important;
  }

  .xxl\:focus\:via-orange-700:focus {
    --gradient-via-color: #c05621 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(192, 86, 33, 0)) !important;
  }

  .xxl\:focus\:via-orange-800:focus {
    --gradient-via-color: #9c4221 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(156, 66, 33, 0)) !important;
  }

  .xxl\:focus\:via-orange-900:focus {
    --gradient-via-color: #7b341e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(123, 52, 30, 0)) !important;
  }

  .xxl\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fffff0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 240, 0)) !important;
  }

  .xxl\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fefcbf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 252, 191, 0)) !important;
  }

  .xxl\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faf089 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 240, 137, 0)) !important;
  }

  .xxl\:focus\:via-yellow-400:focus {
    --gradient-via-color: #f6e05e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 224, 94, 0)) !important;
  }

  .xxl\:focus\:via-yellow-500:focus {
    --gradient-via-color: #ecc94b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(236, 201, 75, 0)) !important;
  }

  .xxl\:focus\:via-yellow-600:focus {
    --gradient-via-color: #d69e2e !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 158, 46, 0)) !important;
  }

  .xxl\:focus\:via-yellow-700:focus {
    --gradient-via-color: #b7791f !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 121, 31, 0)) !important;
  }

  .xxl\:focus\:via-yellow-800:focus {
    --gradient-via-color: #975a16 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 90, 22, 0)) !important;
  }

  .xxl\:focus\:via-yellow-900:focus {
    --gradient-via-color: #744210 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(116, 66, 16, 0)) !important;
  }

  .xxl\:focus\:via-green-100:focus {
    --gradient-via-color: #f0fff4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(240, 255, 244, 0)) !important;
  }

  .xxl\:focus\:via-green-200:focus {
    --gradient-via-color: #c6f6d5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(198, 246, 213, 0)) !important;
  }

  .xxl\:focus\:via-green-300:focus {
    --gradient-via-color: #9ae6b4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(154, 230, 180, 0)) !important;
  }

  .xxl\:focus\:via-green-400:focus {
    --gradient-via-color: #68d391 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(104, 211, 145, 0)) !important;
  }

  .xxl\:focus\:via-green-500:focus {
    --gradient-via-color: #48bb78 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(72, 187, 120, 0)) !important;
  }

  .xxl\:focus\:via-green-600:focus {
    --gradient-via-color: #38a169 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 161, 105, 0)) !important;
  }

  .xxl\:focus\:via-green-700:focus {
    --gradient-via-color: #2f855a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(47, 133, 90, 0)) !important;
  }

  .xxl\:focus\:via-green-800:focus {
    --gradient-via-color: #276749 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(39, 103, 73, 0)) !important;
  }

  .xxl\:focus\:via-green-900:focus {
    --gradient-via-color: #22543d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(34, 84, 61, 0)) !important;
  }

  .xxl\:focus\:via-teal-100:focus {
    --gradient-via-color: #e6fffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(230, 255, 250, 0)) !important;
  }

  .xxl\:focus\:via-teal-200:focus {
    --gradient-via-color: #b2f5ea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(178, 245, 234, 0)) !important;
  }

  .xxl\:focus\:via-teal-300:focus {
    --gradient-via-color: #81e6d9 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(129, 230, 217, 0)) !important;
  }

  .xxl\:focus\:via-teal-400:focus {
    --gradient-via-color: #4fd1c5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(79, 209, 197, 0)) !important;
  }

  .xxl\:focus\:via-teal-500:focus {
    --gradient-via-color: #38b2ac !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(56, 178, 172, 0)) !important;
  }

  .xxl\:focus\:via-teal-600:focus {
    --gradient-via-color: #319795 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 151, 149, 0)) !important;
  }

  .xxl\:focus\:via-teal-700:focus {
    --gradient-via-color: #2c7a7b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 122, 123, 0)) !important;
  }

  .xxl\:focus\:via-teal-800:focus {
    --gradient-via-color: #285e61 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(40, 94, 97, 0)) !important;
  }

  .xxl\:focus\:via-teal-900:focus {
    --gradient-via-color: #234e52 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(35, 78, 82, 0)) !important;
  }

  .xxl\:focus\:via-blue-100:focus {
    --gradient-via-color: #ebf8ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 248, 255, 0)) !important;
  }

  .xxl\:focus\:via-blue-200:focus {
    --gradient-via-color: #bee3f8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(190, 227, 248, 0)) !important;
  }

  .xxl\:focus\:via-blue-300:focus {
    --gradient-via-color: #90cdf4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(144, 205, 244, 0)) !important;
  }

  .xxl\:focus\:via-blue-400:focus {
    --gradient-via-color: #63b3ed !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(99, 179, 237, 0)) !important;
  }

  .xxl\:focus\:via-blue-500:focus {
    --gradient-via-color: #4299e1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(66, 153, 225, 0)) !important;
  }

  .xxl\:focus\:via-blue-600:focus {
    --gradient-via-color: #3182ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(49, 130, 206, 0)) !important;
  }

  .xxl\:focus\:via-blue-700:focus {
    --gradient-via-color: #2b6cb0 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(43, 108, 176, 0)) !important;
  }

  .xxl\:focus\:via-blue-800:focus {
    --gradient-via-color: #2c5282 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(44, 82, 130, 0)) !important;
  }

  .xxl\:focus\:via-blue-900:focus {
    --gradient-via-color: #2a4365 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(42, 67, 101, 0)) !important;
  }

  .xxl\:focus\:via-indigo-100:focus {
    --gradient-via-color: #ebf4ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(235, 244, 255, 0)) !important;
  }

  .xxl\:focus\:via-indigo-200:focus {
    --gradient-via-color: #c3dafe !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(195, 218, 254, 0)) !important;
  }

  .xxl\:focus\:via-indigo-300:focus {
    --gradient-via-color: #a3bffa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(163, 191, 250, 0)) !important;
  }

  .xxl\:focus\:via-indigo-400:focus {
    --gradient-via-color: #7f9cf5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(127, 156, 245, 0)) !important;
  }

  .xxl\:focus\:via-indigo-500:focus {
    --gradient-via-color: #667eea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(102, 126, 234, 0)) !important;
  }

  .xxl\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5a67d8 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(90, 103, 216, 0)) !important;
  }

  .xxl\:focus\:via-indigo-700:focus {
    --gradient-via-color: #4c51bf !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(76, 81, 191, 0)) !important;
  }

  .xxl\:focus\:via-indigo-800:focus {
    --gradient-via-color: #434190 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(67, 65, 144, 0)) !important;
  }

  .xxl\:focus\:via-indigo-900:focus {
    --gradient-via-color: #3c366b !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(60, 54, 107, 0)) !important;
  }

  .xxl\:focus\:via-purple-100:focus {
    --gradient-via-color: #faf5ff !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 245, 255, 0)) !important;
  }

  .xxl\:focus\:via-purple-200:focus {
    --gradient-via-color: #e9d8fd !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(233, 216, 253, 0)) !important;
  }

  .xxl\:focus\:via-purple-300:focus {
    --gradient-via-color: #d6bcfa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(214, 188, 250, 0)) !important;
  }

  .xxl\:focus\:via-purple-400:focus {
    --gradient-via-color: #b794f4 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(183, 148, 244, 0)) !important;
  }

  .xxl\:focus\:via-purple-500:focus {
    --gradient-via-color: #9f7aea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(159, 122, 234, 0)) !important;
  }

  .xxl\:focus\:via-purple-600:focus {
    --gradient-via-color: #805ad5 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(128, 90, 213, 0)) !important;
  }

  .xxl\:focus\:via-purple-700:focus {
    --gradient-via-color: #6b46c1 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(107, 70, 193, 0)) !important;
  }

  .xxl\:focus\:via-purple-800:focus {
    --gradient-via-color: #553c9a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(85, 60, 154, 0)) !important;
  }

  .xxl\:focus\:via-purple-900:focus {
    --gradient-via-color: #44337a !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(68, 51, 122, 0)) !important;
  }

  .xxl\:focus\:via-pink-100:focus {
    --gradient-via-color: #fff5f7 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 245, 247, 0)) !important;
  }

  .xxl\:focus\:via-pink-200:focus {
    --gradient-via-color: #fed7e2 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(254, 215, 226, 0)) !important;
  }

  .xxl\:focus\:via-pink-300:focus {
    --gradient-via-color: #fbb6ce !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(251, 182, 206, 0)) !important;
  }

  .xxl\:focus\:via-pink-400:focus {
    --gradient-via-color: #f687b3 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(246, 135, 179, 0)) !important;
  }

  .xxl\:focus\:via-pink-500:focus {
    --gradient-via-color: #ed64a6 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(237, 100, 166, 0)) !important;
  }

  .xxl\:focus\:via-pink-600:focus {
    --gradient-via-color: #d53f8c !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(213, 63, 140, 0)) !important;
  }

  .xxl\:focus\:via-pink-700:focus {
    --gradient-via-color: #b83280 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(184, 50, 128, 0)) !important;
  }

  .xxl\:focus\:via-pink-800:focus {
    --gradient-via-color: #97266d !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(151, 38, 109, 0)) !important;
  }

  .xxl\:focus\:via-pink-900:focus {
    --gradient-via-color: #702459 !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(112, 36, 89, 0)) !important;
  }

  .xxl\:focus\:via-primary:focus {
    --gradient-via-color: var(--primary) !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(255, 255, 255, 0)) !important;
  }

  .xxl\:focus\:via-accent-1:focus {
    --gradient-via-color: #fafafa !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(250, 250, 250, 0)) !important;
  }

  .xxl\:focus\:via-accent-2:focus {
    --gradient-via-color: #eaeaea !important;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, rgba(234, 234, 234, 0)) !important;
  }

  .xxl\:focus\:to-transparent:focus {
    --gradient-to-color: transparent !important;
  }

  .xxl\:focus\:to-current:focus {
    --gradient-to-color: currentColor !important;
  }

  .xxl\:focus\:to-black:focus {
    --gradient-to-color: #000 !important;
  }

  .xxl\:focus\:to-white:focus {
    --gradient-to-color: #fff !important;
  }

  .xxl\:focus\:to-gray-100:focus {
    --gradient-to-color: #f7fafc !important;
  }

  .xxl\:focus\:to-gray-200:focus {
    --gradient-to-color: #edf2f7 !important;
  }

  .xxl\:focus\:to-gray-300:focus {
    --gradient-to-color: #e2e8f0 !important;
  }

  .xxl\:focus\:to-gray-400:focus {
    --gradient-to-color: #cbd5e0 !important;
  }

  .xxl\:focus\:to-gray-500:focus {
    --gradient-to-color: #a0aec0 !important;
  }

  .xxl\:focus\:to-gray-600:focus {
    --gradient-to-color: #718096 !important;
  }

  .xxl\:focus\:to-gray-700:focus {
    --gradient-to-color: #4a5568 !important;
  }

  .xxl\:focus\:to-gray-800:focus {
    --gradient-to-color: #2d3748 !important;
  }

  .xxl\:focus\:to-gray-900:focus {
    --gradient-to-color: #1a202c !important;
  }

  .xxl\:focus\:to-red-100:focus {
    --gradient-to-color: #fff5f5 !important;
  }

  .xxl\:focus\:to-red-200:focus {
    --gradient-to-color: #fed7d7 !important;
  }

  .xxl\:focus\:to-red-300:focus {
    --gradient-to-color: #feb2b2 !important;
  }

  .xxl\:focus\:to-red-400:focus {
    --gradient-to-color: #fc8181 !important;
  }

  .xxl\:focus\:to-red-500:focus {
    --gradient-to-color: #f56565 !important;
  }

  .xxl\:focus\:to-red-600:focus {
    --gradient-to-color: #e53e3e !important;
  }

  .xxl\:focus\:to-red-700:focus {
    --gradient-to-color: #c53030 !important;
  }

  .xxl\:focus\:to-red-800:focus {
    --gradient-to-color: #9b2c2c !important;
  }

  .xxl\:focus\:to-red-900:focus {
    --gradient-to-color: #742a2a !important;
  }

  .xxl\:focus\:to-orange-100:focus {
    --gradient-to-color: #fffaf0 !important;
  }

  .xxl\:focus\:to-orange-200:focus {
    --gradient-to-color: #feebc8 !important;
  }

  .xxl\:focus\:to-orange-300:focus {
    --gradient-to-color: #fbd38d !important;
  }

  .xxl\:focus\:to-orange-400:focus {
    --gradient-to-color: #f6ad55 !important;
  }

  .xxl\:focus\:to-orange-500:focus {
    --gradient-to-color: #ed8936 !important;
  }

  .xxl\:focus\:to-orange-600:focus {
    --gradient-to-color: #dd6b20 !important;
  }

  .xxl\:focus\:to-orange-700:focus {
    --gradient-to-color: #c05621 !important;
  }

  .xxl\:focus\:to-orange-800:focus {
    --gradient-to-color: #9c4221 !important;
  }

  .xxl\:focus\:to-orange-900:focus {
    --gradient-to-color: #7b341e !important;
  }

  .xxl\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fffff0 !important;
  }

  .xxl\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fefcbf !important;
  }

  .xxl\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faf089 !important;
  }

  .xxl\:focus\:to-yellow-400:focus {
    --gradient-to-color: #f6e05e !important;
  }

  .xxl\:focus\:to-yellow-500:focus {
    --gradient-to-color: #ecc94b !important;
  }

  .xxl\:focus\:to-yellow-600:focus {
    --gradient-to-color: #d69e2e !important;
  }

  .xxl\:focus\:to-yellow-700:focus {
    --gradient-to-color: #b7791f !important;
  }

  .xxl\:focus\:to-yellow-800:focus {
    --gradient-to-color: #975a16 !important;
  }

  .xxl\:focus\:to-yellow-900:focus {
    --gradient-to-color: #744210 !important;
  }

  .xxl\:focus\:to-green-100:focus {
    --gradient-to-color: #f0fff4 !important;
  }

  .xxl\:focus\:to-green-200:focus {
    --gradient-to-color: #c6f6d5 !important;
  }

  .xxl\:focus\:to-green-300:focus {
    --gradient-to-color: #9ae6b4 !important;
  }

  .xxl\:focus\:to-green-400:focus {
    --gradient-to-color: #68d391 !important;
  }

  .xxl\:focus\:to-green-500:focus {
    --gradient-to-color: #48bb78 !important;
  }

  .xxl\:focus\:to-green-600:focus {
    --gradient-to-color: #38a169 !important;
  }

  .xxl\:focus\:to-green-700:focus {
    --gradient-to-color: #2f855a !important;
  }

  .xxl\:focus\:to-green-800:focus {
    --gradient-to-color: #276749 !important;
  }

  .xxl\:focus\:to-green-900:focus {
    --gradient-to-color: #22543d !important;
  }

  .xxl\:focus\:to-teal-100:focus {
    --gradient-to-color: #e6fffa !important;
  }

  .xxl\:focus\:to-teal-200:focus {
    --gradient-to-color: #b2f5ea !important;
  }

  .xxl\:focus\:to-teal-300:focus {
    --gradient-to-color: #81e6d9 !important;
  }

  .xxl\:focus\:to-teal-400:focus {
    --gradient-to-color: #4fd1c5 !important;
  }

  .xxl\:focus\:to-teal-500:focus {
    --gradient-to-color: #38b2ac !important;
  }

  .xxl\:focus\:to-teal-600:focus {
    --gradient-to-color: #319795 !important;
  }

  .xxl\:focus\:to-teal-700:focus {
    --gradient-to-color: #2c7a7b !important;
  }

  .xxl\:focus\:to-teal-800:focus {
    --gradient-to-color: #285e61 !important;
  }

  .xxl\:focus\:to-teal-900:focus {
    --gradient-to-color: #234e52 !important;
  }

  .xxl\:focus\:to-blue-100:focus {
    --gradient-to-color: #ebf8ff !important;
  }

  .xxl\:focus\:to-blue-200:focus {
    --gradient-to-color: #bee3f8 !important;
  }

  .xxl\:focus\:to-blue-300:focus {
    --gradient-to-color: #90cdf4 !important;
  }

  .xxl\:focus\:to-blue-400:focus {
    --gradient-to-color: #63b3ed !important;
  }

  .xxl\:focus\:to-blue-500:focus {
    --gradient-to-color: #4299e1 !important;
  }

  .xxl\:focus\:to-blue-600:focus {
    --gradient-to-color: #3182ce !important;
  }

  .xxl\:focus\:to-blue-700:focus {
    --gradient-to-color: #2b6cb0 !important;
  }

  .xxl\:focus\:to-blue-800:focus {
    --gradient-to-color: #2c5282 !important;
  }

  .xxl\:focus\:to-blue-900:focus {
    --gradient-to-color: #2a4365 !important;
  }

  .xxl\:focus\:to-indigo-100:focus {
    --gradient-to-color: #ebf4ff !important;
  }

  .xxl\:focus\:to-indigo-200:focus {
    --gradient-to-color: #c3dafe !important;
  }

  .xxl\:focus\:to-indigo-300:focus {
    --gradient-to-color: #a3bffa !important;
  }

  .xxl\:focus\:to-indigo-400:focus {
    --gradient-to-color: #7f9cf5 !important;
  }

  .xxl\:focus\:to-indigo-500:focus {
    --gradient-to-color: #667eea !important;
  }

  .xxl\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5a67d8 !important;
  }

  .xxl\:focus\:to-indigo-700:focus {
    --gradient-to-color: #4c51bf !important;
  }

  .xxl\:focus\:to-indigo-800:focus {
    --gradient-to-color: #434190 !important;
  }

  .xxl\:focus\:to-indigo-900:focus {
    --gradient-to-color: #3c366b !important;
  }

  .xxl\:focus\:to-purple-100:focus {
    --gradient-to-color: #faf5ff !important;
  }

  .xxl\:focus\:to-purple-200:focus {
    --gradient-to-color: #e9d8fd !important;
  }

  .xxl\:focus\:to-purple-300:focus {
    --gradient-to-color: #d6bcfa !important;
  }

  .xxl\:focus\:to-purple-400:focus {
    --gradient-to-color: #b794f4 !important;
  }

  .xxl\:focus\:to-purple-500:focus {
    --gradient-to-color: #9f7aea !important;
  }

  .xxl\:focus\:to-purple-600:focus {
    --gradient-to-color: #805ad5 !important;
  }

  .xxl\:focus\:to-purple-700:focus {
    --gradient-to-color: #6b46c1 !important;
  }

  .xxl\:focus\:to-purple-800:focus {
    --gradient-to-color: #553c9a !important;
  }

  .xxl\:focus\:to-purple-900:focus {
    --gradient-to-color: #44337a !important;
  }

  .xxl\:focus\:to-pink-100:focus {
    --gradient-to-color: #fff5f7 !important;
  }

  .xxl\:focus\:to-pink-200:focus {
    --gradient-to-color: #fed7e2 !important;
  }

  .xxl\:focus\:to-pink-300:focus {
    --gradient-to-color: #fbb6ce !important;
  }

  .xxl\:focus\:to-pink-400:focus {
    --gradient-to-color: #f687b3 !important;
  }

  .xxl\:focus\:to-pink-500:focus {
    --gradient-to-color: #ed64a6 !important;
  }

  .xxl\:focus\:to-pink-600:focus {
    --gradient-to-color: #d53f8c !important;
  }

  .xxl\:focus\:to-pink-700:focus {
    --gradient-to-color: #b83280 !important;
  }

  .xxl\:focus\:to-pink-800:focus {
    --gradient-to-color: #97266d !important;
  }

  .xxl\:focus\:to-pink-900:focus {
    --gradient-to-color: #702459 !important;
  }

  .xxl\:focus\:to-primary:focus {
    --gradient-to-color: var(--primary) !important;
  }

  .xxl\:focus\:to-accent-1:focus {
    --gradient-to-color: #fafafa !important;
  }

  .xxl\:focus\:to-accent-2:focus {
    --gradient-to-color: #eaeaea !important;
  }

  .xxl\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .xxl\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .xxl\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .xxl\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .xxl\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .xxl\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .xxl\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .xxl\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .xxl\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .xxl\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .xxl\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .xxl\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .xxl\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .xxl\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .xxl\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .xxl\:bg-bottom {
    background-position: bottom !important;
  }

  .xxl\:bg-center {
    background-position: center !important;
  }

  .xxl\:bg-left {
    background-position: left !important;
  }

  .xxl\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .xxl\:bg-left-top {
    background-position: left top !important;
  }

  .xxl\:bg-right {
    background-position: right !important;
  }

  .xxl\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .xxl\:bg-right-top {
    background-position: right top !important;
  }

  .xxl\:bg-top {
    background-position: top !important;
  }

  .xxl\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xxl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xxl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xxl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xxl\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xxl\:bg-repeat-space {
    background-repeat: space !important;
  }

  .xxl\:bg-auto {
    background-size: auto !important;
  }

  .xxl\:bg-cover {
    background-size: cover !important;
  }

  .xxl\:bg-contain {
    background-size: contain !important;
  }

  .xxl\:border-collapse {
    border-collapse: collapse !important;
  }

  .xxl\:border-separate {
    border-collapse: separate !important;
  }

  .xxl\:border-transparent {
    border-color: transparent !important;
  }

  .xxl\:border-current {
    border-color: currentColor !important;
  }

  .xxl\:border-black {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xxl\:border-white {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-100 {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-200 {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-300 {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-400 {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-500 {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-600 {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-700 {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-800 {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xxl\:border-gray-900 {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xxl\:border-red-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xxl\:border-red-200 {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xxl\:border-red-300 {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xxl\:border-red-400 {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xxl\:border-red-500 {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xxl\:border-red-600 {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xxl\:border-red-700 {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xxl\:border-red-800 {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xxl\:border-red-900 {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-100 {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-200 {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-300 {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-400 {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-500 {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-600 {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-700 {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-800 {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xxl\:border-orange-900 {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-100 {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-200 {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-300 {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-400 {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-500 {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-600 {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-700 {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-800 {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xxl\:border-yellow-900 {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xxl\:border-green-100 {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xxl\:border-green-200 {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xxl\:border-green-300 {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xxl\:border-green-400 {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xxl\:border-green-500 {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xxl\:border-green-600 {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xxl\:border-green-700 {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xxl\:border-green-800 {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xxl\:border-green-900 {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-100 {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-200 {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-300 {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-400 {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-500 {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-600 {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-700 {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-800 {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xxl\:border-teal-900 {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-100 {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-200 {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-300 {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-400 {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-500 {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-600 {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-700 {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-800 {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xxl\:border-blue-900 {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-100 {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-200 {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-300 {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-400 {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-500 {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-600 {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-700 {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-800 {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xxl\:border-indigo-900 {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-100 {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-200 {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-300 {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-400 {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-500 {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-600 {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-700 {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-800 {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xxl\:border-purple-900 {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-100 {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-200 {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-300 {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-400 {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-500 {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-600 {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-700 {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-800 {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xxl\:border-pink-900 {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xxl\:border-primary {
    border-color: var(--primary) !important;
  }

  .xxl\:border-accent-1 {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xxl\:border-accent-2 {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-transparent:hover {
    border-color: transparent !important;
  }

  .xxl\:hover\:border-current:hover {
    border-color: currentColor !important;
  }

  .xxl\:hover\:border-black:hover {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-white:hover {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-100:hover {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-200:hover {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-300:hover {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-400:hover {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-500:hover {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-600:hover {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-700:hover {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-800:hover {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-gray-900:hover {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-300:hover {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-400:hover {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-500:hover {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-600:hover {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-700:hover {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-800:hover {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-red-900:hover {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-200:hover {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-600:hover {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-700:hover {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-800:hover {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-orange-900:hover {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-100:hover {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-200:hover {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-300:hover {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-400:hover {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-500:hover {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-600:hover {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-700:hover {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-800:hover {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-yellow-900:hover {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-100:hover {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-200:hover {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-300:hover {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-400:hover {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-500:hover {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-600:hover {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-700:hover {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-800:hover {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-green-900:hover {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-100:hover {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-200:hover {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-300:hover {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-400:hover {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-500:hover {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-600:hover {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-700:hover {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-800:hover {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-teal-900:hover {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-200:hover {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-300:hover {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-400:hover {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-500:hover {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-600:hover {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-700:hover {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-800:hover {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-blue-900:hover {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-100:hover {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-200:hover {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-300:hover {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-400:hover {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-500:hover {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-600:hover {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-700:hover {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-800:hover {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-indigo-900:hover {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-100:hover {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-200:hover {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-300:hover {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-400:hover {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-500:hover {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-600:hover {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-700:hover {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-800:hover {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-purple-900:hover {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-100:hover {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-200:hover {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-300:hover {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-400:hover {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-500:hover {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-600:hover {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-700:hover {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-800:hover {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-pink-900:hover {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-primary:hover {
    border-color: var(--primary) !important;
  }

  .xxl\:hover\:border-accent-1:hover {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xxl\:hover\:border-accent-2:hover {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-transparent:focus {
    border-color: transparent !important;
  }

  .xxl\:focus\:border-current:focus {
    border-color: currentColor !important;
  }

  .xxl\:focus\:border-black:focus {
    --border-opacity: 1 !important;
    border-color: #000 !important;
    border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-white:focus {
    --border-opacity: 1 !important;
    border-color: #fff !important;
    border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-100:focus {
    --border-opacity: 1 !important;
    border-color: #f7fafc !important;
    border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-200:focus {
    --border-opacity: 1 !important;
    border-color: #edf2f7 !important;
    border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-300:focus {
    --border-opacity: 1 !important;
    border-color: #e2e8f0 !important;
    border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-400:focus {
    --border-opacity: 1 !important;
    border-color: #cbd5e0 !important;
    border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-500:focus {
    --border-opacity: 1 !important;
    border-color: #a0aec0 !important;
    border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-600:focus {
    --border-opacity: 1 !important;
    border-color: #718096 !important;
    border-color: rgba(113, 128, 150, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-700:focus {
    --border-opacity: 1 !important;
    border-color: #4a5568 !important;
    border-color: rgba(74, 85, 104, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-800:focus {
    --border-opacity: 1 !important;
    border-color: #2d3748 !important;
    border-color: rgba(45, 55, 72, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-gray-900:focus {
    --border-opacity: 1 !important;
    border-color: #1a202c !important;
    border-color: rgba(26, 32, 44, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f5 !important;
    border-color: rgba(255, 245, 245, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7d7 !important;
    border-color: rgba(254, 215, 215, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-300:focus {
    --border-opacity: 1 !important;
    border-color: #feb2b2 !important;
    border-color: rgba(254, 178, 178, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-400:focus {
    --border-opacity: 1 !important;
    border-color: #fc8181 !important;
    border-color: rgba(252, 129, 129, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-500:focus {
    --border-opacity: 1 !important;
    border-color: #f56565 !important;
    border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-600:focus {
    --border-opacity: 1 !important;
    border-color: #e53e3e !important;
    border-color: rgba(229, 62, 62, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-700:focus {
    --border-opacity: 1 !important;
    border-color: #c53030 !important;
    border-color: rgba(197, 48, 48, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-800:focus {
    --border-opacity: 1 !important;
    border-color: #9b2c2c !important;
    border-color: rgba(155, 44, 44, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-red-900:focus {
    --border-opacity: 1 !important;
    border-color: #742a2a !important;
    border-color: rgba(116, 42, 42, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffaf0 !important;
    border-color: rgba(255, 250, 240, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-200:focus {
    --border-opacity: 1 !important;
    border-color: #feebc8 !important;
    border-color: rgba(254, 235, 200, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbd38d !important;
    border-color: rgba(251, 211, 141, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6ad55 !important;
    border-color: rgba(246, 173, 85, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed8936 !important;
    border-color: rgba(237, 137, 54, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-600:focus {
    --border-opacity: 1 !important;
    border-color: #dd6b20 !important;
    border-color: rgba(221, 107, 32, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-700:focus {
    --border-opacity: 1 !important;
    border-color: #c05621 !important;
    border-color: rgba(192, 86, 33, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-800:focus {
    --border-opacity: 1 !important;
    border-color: #9c4221 !important;
    border-color: rgba(156, 66, 33, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-orange-900:focus {
    --border-opacity: 1 !important;
    border-color: #7b341e !important;
    border-color: rgba(123, 52, 30, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-100:focus {
    --border-opacity: 1 !important;
    border-color: #fffff0 !important;
    border-color: rgba(255, 255, 240, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-200:focus {
    --border-opacity: 1 !important;
    border-color: #fefcbf !important;
    border-color: rgba(254, 252, 191, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-300:focus {
    --border-opacity: 1 !important;
    border-color: #faf089 !important;
    border-color: rgba(250, 240, 137, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-400:focus {
    --border-opacity: 1 !important;
    border-color: #f6e05e !important;
    border-color: rgba(246, 224, 94, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-500:focus {
    --border-opacity: 1 !important;
    border-color: #ecc94b !important;
    border-color: rgba(236, 201, 75, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-600:focus {
    --border-opacity: 1 !important;
    border-color: #d69e2e !important;
    border-color: rgba(214, 158, 46, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-700:focus {
    --border-opacity: 1 !important;
    border-color: #b7791f !important;
    border-color: rgba(183, 121, 31, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-800:focus {
    --border-opacity: 1 !important;
    border-color: #975a16 !important;
    border-color: rgba(151, 90, 22, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-yellow-900:focus {
    --border-opacity: 1 !important;
    border-color: #744210 !important;
    border-color: rgba(116, 66, 16, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-100:focus {
    --border-opacity: 1 !important;
    border-color: #f0fff4 !important;
    border-color: rgba(240, 255, 244, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-200:focus {
    --border-opacity: 1 !important;
    border-color: #c6f6d5 !important;
    border-color: rgba(198, 246, 213, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-300:focus {
    --border-opacity: 1 !important;
    border-color: #9ae6b4 !important;
    border-color: rgba(154, 230, 180, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-400:focus {
    --border-opacity: 1 !important;
    border-color: #68d391 !important;
    border-color: rgba(104, 211, 145, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-500:focus {
    --border-opacity: 1 !important;
    border-color: #48bb78 !important;
    border-color: rgba(72, 187, 120, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-600:focus {
    --border-opacity: 1 !important;
    border-color: #38a169 !important;
    border-color: rgba(56, 161, 105, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-700:focus {
    --border-opacity: 1 !important;
    border-color: #2f855a !important;
    border-color: rgba(47, 133, 90, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-800:focus {
    --border-opacity: 1 !important;
    border-color: #276749 !important;
    border-color: rgba(39, 103, 73, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-green-900:focus {
    --border-opacity: 1 !important;
    border-color: #22543d !important;
    border-color: rgba(34, 84, 61, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-100:focus {
    --border-opacity: 1 !important;
    border-color: #e6fffa !important;
    border-color: rgba(230, 255, 250, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-200:focus {
    --border-opacity: 1 !important;
    border-color: #b2f5ea !important;
    border-color: rgba(178, 245, 234, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-300:focus {
    --border-opacity: 1 !important;
    border-color: #81e6d9 !important;
    border-color: rgba(129, 230, 217, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-400:focus {
    --border-opacity: 1 !important;
    border-color: #4fd1c5 !important;
    border-color: rgba(79, 209, 197, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-500:focus {
    --border-opacity: 1 !important;
    border-color: #38b2ac !important;
    border-color: rgba(56, 178, 172, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-600:focus {
    --border-opacity: 1 !important;
    border-color: #319795 !important;
    border-color: rgba(49, 151, 149, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-700:focus {
    --border-opacity: 1 !important;
    border-color: #2c7a7b !important;
    border-color: rgba(44, 122, 123, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-800:focus {
    --border-opacity: 1 !important;
    border-color: #285e61 !important;
    border-color: rgba(40, 94, 97, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-teal-900:focus {
    --border-opacity: 1 !important;
    border-color: #234e52 !important;
    border-color: rgba(35, 78, 82, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf8ff !important;
    border-color: rgba(235, 248, 255, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-200:focus {
    --border-opacity: 1 !important;
    border-color: #bee3f8 !important;
    border-color: rgba(190, 227, 248, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-300:focus {
    --border-opacity: 1 !important;
    border-color: #90cdf4 !important;
    border-color: rgba(144, 205, 244, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-400:focus {
    --border-opacity: 1 !important;
    border-color: #63b3ed !important;
    border-color: rgba(99, 179, 237, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-500:focus {
    --border-opacity: 1 !important;
    border-color: #4299e1 !important;
    border-color: rgba(66, 153, 225, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-600:focus {
    --border-opacity: 1 !important;
    border-color: #3182ce !important;
    border-color: rgba(49, 130, 206, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-700:focus {
    --border-opacity: 1 !important;
    border-color: #2b6cb0 !important;
    border-color: rgba(43, 108, 176, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-800:focus {
    --border-opacity: 1 !important;
    border-color: #2c5282 !important;
    border-color: rgba(44, 82, 130, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-blue-900:focus {
    --border-opacity: 1 !important;
    border-color: #2a4365 !important;
    border-color: rgba(42, 67, 101, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-100:focus {
    --border-opacity: 1 !important;
    border-color: #ebf4ff !important;
    border-color: rgba(235, 244, 255, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-200:focus {
    --border-opacity: 1 !important;
    border-color: #c3dafe !important;
    border-color: rgba(195, 218, 254, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-300:focus {
    --border-opacity: 1 !important;
    border-color: #a3bffa !important;
    border-color: rgba(163, 191, 250, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-400:focus {
    --border-opacity: 1 !important;
    border-color: #7f9cf5 !important;
    border-color: rgba(127, 156, 245, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-500:focus {
    --border-opacity: 1 !important;
    border-color: #667eea !important;
    border-color: rgba(102, 126, 234, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-600:focus {
    --border-opacity: 1 !important;
    border-color: #5a67d8 !important;
    border-color: rgba(90, 103, 216, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-700:focus {
    --border-opacity: 1 !important;
    border-color: #4c51bf !important;
    border-color: rgba(76, 81, 191, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-800:focus {
    --border-opacity: 1 !important;
    border-color: #434190 !important;
    border-color: rgba(67, 65, 144, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-indigo-900:focus {
    --border-opacity: 1 !important;
    border-color: #3c366b !important;
    border-color: rgba(60, 54, 107, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-100:focus {
    --border-opacity: 1 !important;
    border-color: #faf5ff !important;
    border-color: rgba(250, 245, 255, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-200:focus {
    --border-opacity: 1 !important;
    border-color: #e9d8fd !important;
    border-color: rgba(233, 216, 253, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-300:focus {
    --border-opacity: 1 !important;
    border-color: #d6bcfa !important;
    border-color: rgba(214, 188, 250, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-400:focus {
    --border-opacity: 1 !important;
    border-color: #b794f4 !important;
    border-color: rgba(183, 148, 244, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-500:focus {
    --border-opacity: 1 !important;
    border-color: #9f7aea !important;
    border-color: rgba(159, 122, 234, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-600:focus {
    --border-opacity: 1 !important;
    border-color: #805ad5 !important;
    border-color: rgba(128, 90, 213, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-700:focus {
    --border-opacity: 1 !important;
    border-color: #6b46c1 !important;
    border-color: rgba(107, 70, 193, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-800:focus {
    --border-opacity: 1 !important;
    border-color: #553c9a !important;
    border-color: rgba(85, 60, 154, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-purple-900:focus {
    --border-opacity: 1 !important;
    border-color: #44337a !important;
    border-color: rgba(68, 51, 122, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-100:focus {
    --border-opacity: 1 !important;
    border-color: #fff5f7 !important;
    border-color: rgba(255, 245, 247, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-200:focus {
    --border-opacity: 1 !important;
    border-color: #fed7e2 !important;
    border-color: rgba(254, 215, 226, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-300:focus {
    --border-opacity: 1 !important;
    border-color: #fbb6ce !important;
    border-color: rgba(251, 182, 206, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-400:focus {
    --border-opacity: 1 !important;
    border-color: #f687b3 !important;
    border-color: rgba(246, 135, 179, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-500:focus {
    --border-opacity: 1 !important;
    border-color: #ed64a6 !important;
    border-color: rgba(237, 100, 166, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-600:focus {
    --border-opacity: 1 !important;
    border-color: #d53f8c !important;
    border-color: rgba(213, 63, 140, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-700:focus {
    --border-opacity: 1 !important;
    border-color: #b83280 !important;
    border-color: rgba(184, 50, 128, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-800:focus {
    --border-opacity: 1 !important;
    border-color: #97266d !important;
    border-color: rgba(151, 38, 109, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-pink-900:focus {
    --border-opacity: 1 !important;
    border-color: #702459 !important;
    border-color: rgba(112, 36, 89, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-primary:focus {
    border-color: var(--primary) !important;
  }

  .xxl\:focus\:border-accent-1:focus {
    --border-opacity: 1 !important;
    border-color: #fafafa !important;
    border-color: rgba(250, 250, 250, var(--border-opacity)) !important;
  }

  .xxl\:focus\:border-accent-2:focus {
    --border-opacity: 1 !important;
    border-color: #eaeaea !important;
    border-color: rgba(234, 234, 234, var(--border-opacity)) !important;
  }

  .xxl\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .xxl\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .xxl\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .xxl\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .xxl\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .xxl\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .xxl\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .xxl\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .xxl\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .xxl\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .xxl\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .xxl\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .xxl\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .xxl\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .xxl\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .xxl\:rounded-none {
    border-radius: 0 !important;
  }

  .xxl\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .xxl\:rounded {
    border-radius: 0.25rem !important;
  }

  .xxl\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .xxl\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .xxl\:rounded-xl {
    border-radius: 0.75rem !important;
  }

  .xxl\:rounded-2xl {
    border-radius: 1rem !important;
  }

  .xxl\:rounded-3xl {
    border-radius: 1.5rem !important;
  }

  .xxl\:rounded-full {
    border-radius: 9999px !important;
  }

  .xxl\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xxl\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xxl\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xxl\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xxl\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xxl\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xxl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xxl\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xxl\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xxl\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xxl\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xxl\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xxl\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xxl\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xxl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xxl\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xxl\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xxl\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xxl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xxl\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xxl\:rounded-t-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .xxl\:rounded-r-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .xxl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xxl\:rounded-l-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xxl\:rounded-t-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xxl\:rounded-r-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xxl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xxl\:rounded-l-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xxl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xxl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xxl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xxl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xxl\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xxl\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xxl\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xxl\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xxl\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .xxl\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .xxl\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .xxl\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .xxl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .xxl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .xxl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .xxl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .xxl\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .xxl\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .xxl\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .xxl\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .xxl\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .xxl\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .xxl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .xxl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .xxl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .xxl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .xxl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .xxl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .xxl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem !important;
  }

  .xxl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem !important;
  }

  .xxl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem !important;
  }

  .xxl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem !important;
  }

  .xxl\:rounded-tl-2xl {
    border-top-left-radius: 1rem !important;
  }

  .xxl\:rounded-tr-2xl {
    border-top-right-radius: 1rem !important;
  }

  .xxl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem !important;
  }

  .xxl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem !important;
  }

  .xxl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem !important;
  }

  .xxl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem !important;
  }

  .xxl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem !important;
  }

  .xxl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem !important;
  }

  .xxl\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .xxl\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .xxl\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .xxl\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .xxl\:border-solid {
    border-style: solid !important;
  }

  .xxl\:border-dashed {
    border-style: dashed !important;
  }

  .xxl\:border-dotted {
    border-style: dotted !important;
  }

  .xxl\:border-double {
    border-style: double !important;
  }

  .xxl\:border-none {
    border-style: none !important;
  }

  .xxl\:border-0 {
    border-width: 0 !important;
  }

  .xxl\:border-2 {
    border-width: 2px !important;
  }

  .xxl\:border-4 {
    border-width: 4px !important;
  }

  .xxl\:border-8 {
    border-width: 8px !important;
  }

  .xxl\:border {
    border-width: 1px !important;
  }

  .xxl\:border-t-0 {
    border-top-width: 0 !important;
  }

  .xxl\:border-r-0 {
    border-right-width: 0 !important;
  }

  .xxl\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .xxl\:border-l-0 {
    border-left-width: 0 !important;
  }

  .xxl\:border-t-2 {
    border-top-width: 2px !important;
  }

  .xxl\:border-r-2 {
    border-right-width: 2px !important;
  }

  .xxl\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .xxl\:border-l-2 {
    border-left-width: 2px !important;
  }

  .xxl\:border-t-4 {
    border-top-width: 4px !important;
  }

  .xxl\:border-r-4 {
    border-right-width: 4px !important;
  }

  .xxl\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .xxl\:border-l-4 {
    border-left-width: 4px !important;
  }

  .xxl\:border-t-8 {
    border-top-width: 8px !important;
  }

  .xxl\:border-r-8 {
    border-right-width: 8px !important;
  }

  .xxl\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .xxl\:border-l-8 {
    border-left-width: 8px !important;
  }

  .xxl\:border-t {
    border-top-width: 1px !important;
  }

  .xxl\:border-r {
    border-right-width: 1px !important;
  }

  .xxl\:border-b {
    border-bottom-width: 1px !important;
  }

  .xxl\:border-l {
    border-left-width: 1px !important;
  }

  .xxl\:box-border {
    box-sizing: border-box !important;
  }

  .xxl\:box-content {
    box-sizing: content-box !important;
  }

  .xxl\:cursor-auto {
    cursor: auto !important;
  }

  .xxl\:cursor-default {
    cursor: default !important;
  }

  .xxl\:cursor-pointer {
    cursor: pointer !important;
  }

  .xxl\:cursor-wait {
    cursor: wait !important;
  }

  .xxl\:cursor-text {
    cursor: text !important;
  }

  .xxl\:cursor-move {
    cursor: move !important;
  }

  .xxl\:cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .xxl\:block {
    display: block !important;
  }

  .xxl\:inline-block {
    display: inline-block !important;
  }

  .xxl\:inline {
    display: inline !important;
  }

  .xxl\:flex {
    display: flex !important;
  }

  .xxl\:inline-flex {
    display: inline-flex !important;
  }

  .xxl\:table {
    display: table !important;
  }

  .xxl\:table-caption {
    display: table-caption !important;
  }

  .xxl\:table-cell {
    display: table-cell !important;
  }

  .xxl\:table-column {
    display: table-column !important;
  }

  .xxl\:table-column-group {
    display: table-column-group !important;
  }

  .xxl\:table-footer-group {
    display: table-footer-group !important;
  }

  .xxl\:table-header-group {
    display: table-header-group !important;
  }

  .xxl\:table-row-group {
    display: table-row-group !important;
  }

  .xxl\:table-row {
    display: table-row !important;
  }

  .xxl\:flow-root {
    display: flow-root !important;
  }

  .xxl\:grid {
    display: grid !important;
  }

  .xxl\:inline-grid {
    display: inline-grid !important;
  }

  .xxl\:contents {
    display: contents !important;
  }

  .xxl\:hidden {
    display: none !important;
  }

  .xxl\:flex-row {
    flex-direction: row !important;
  }

  .xxl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xxl\:flex-col {
    flex-direction: column !important;
  }

  .xxl\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .xxl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xxl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xxl\:flex-no-wrap {
    flex-wrap: nowrap !important;
  }

  .xxl\:place-items-auto {
    place-items: auto !important;
  }

  .xxl\:place-items-start {
    place-items: start !important;
  }

  .xxl\:place-items-end {
    place-items: end !important;
  }

  .xxl\:place-items-center {
    place-items: center !important;
  }

  .xxl\:place-items-stretch {
    place-items: stretch !important;
  }

  .xxl\:place-content-center {
    place-content: center !important;
  }

  .xxl\:place-content-start {
    place-content: start !important;
  }

  .xxl\:place-content-end {
    place-content: end !important;
  }

  .xxl\:place-content-between {
    place-content: space-between !important;
  }

  .xxl\:place-content-around {
    place-content: space-around !important;
  }

  .xxl\:place-content-evenly {
    place-content: space-evenly !important;
  }

  .xxl\:place-content-stretch {
    place-content: stretch !important;
  }

  .xxl\:place-self-auto {
    place-self: auto !important;
  }

  .xxl\:place-self-start {
    place-self: start !important;
  }

  .xxl\:place-self-end {
    place-self: end !important;
  }

  .xxl\:place-self-center {
    place-self: center !important;
  }

  .xxl\:place-self-stretch {
    place-self: stretch !important;
  }

  .xxl\:items-start {
    align-items: flex-start !important;
  }

  .xxl\:items-end {
    align-items: flex-end !important;
  }

  .xxl\:items-center {
    align-items: center !important;
  }

  .xxl\:items-baseline {
    align-items: baseline !important;
  }

  .xxl\:items-stretch {
    align-items: stretch !important;
  }

  .xxl\:content-center {
    align-content: center !important;
  }

  .xxl\:content-start {
    align-content: flex-start !important;
  }

  .xxl\:content-end {
    align-content: flex-end !important;
  }

  .xxl\:content-between {
    align-content: space-between !important;
  }

  .xxl\:content-around {
    align-content: space-around !important;
  }

  .xxl\:content-evenly {
    align-content: space-evenly !important;
  }

  .xxl\:self-auto {
    align-self: auto !important;
  }

  .xxl\:self-start {
    align-self: flex-start !important;
  }

  .xxl\:self-end {
    align-self: flex-end !important;
  }

  .xxl\:self-center {
    align-self: center !important;
  }

  .xxl\:self-stretch {
    align-self: stretch !important;
  }

  .xxl\:justify-items-auto {
    justify-items: auto !important;
  }

  .xxl\:justify-items-start {
    justify-items: start !important;
  }

  .xxl\:justify-items-end {
    justify-items: end !important;
  }

  .xxl\:justify-items-center {
    justify-items: center !important;
  }

  .xxl\:justify-items-stretch {
    justify-items: stretch !important;
  }

  .xxl\:justify-start {
    justify-content: flex-start !important;
  }

  .xxl\:justify-end {
    justify-content: flex-end !important;
  }

  .xxl\:justify-center {
    justify-content: center !important;
  }

  .xxl\:justify-between {
    justify-content: space-between !important;
  }

  .xxl\:justify-around {
    justify-content: space-around !important;
  }

  .xxl\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .xxl\:justify-self-auto {
    justify-self: auto !important;
  }

  .xxl\:justify-self-start {
    justify-self: start !important;
  }

  .xxl\:justify-self-end {
    justify-self: end !important;
  }

  .xxl\:justify-self-center {
    justify-self: center !important;
  }

  .xxl\:justify-self-stretch {
    justify-self: stretch !important;
  }

  .xxl\:flex-1 {
    flex: 1 1 0% !important;
  }

  .xxl\:flex-auto {
    flex: 1 1 auto !important;
  }

  .xxl\:flex-initial {
    flex: 0 1 auto !important;
  }

  .xxl\:flex-none {
    flex: none !important;
  }

  .xxl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xxl\:flex-grow {
    flex-grow: 1 !important;
  }

  .xxl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xxl\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .xxl\:order-1 {
    order: 1 !important;
  }

  .xxl\:order-2 {
    order: 2 !important;
  }

  .xxl\:order-3 {
    order: 3 !important;
  }

  .xxl\:order-4 {
    order: 4 !important;
  }

  .xxl\:order-5 {
    order: 5 !important;
  }

  .xxl\:order-6 {
    order: 6 !important;
  }

  .xxl\:order-7 {
    order: 7 !important;
  }

  .xxl\:order-8 {
    order: 8 !important;
  }

  .xxl\:order-9 {
    order: 9 !important;
  }

  .xxl\:order-10 {
    order: 10 !important;
  }

  .xxl\:order-11 {
    order: 11 !important;
  }

  .xxl\:order-12 {
    order: 12 !important;
  }

  .xxl\:order-first {
    order: -9999 !important;
  }

  .xxl\:order-last {
    order: 9999 !important;
  }

  .xxl\:order-none {
    order: 0 !important;
  }

  .xxl\:float-right {
    float: right !important;
  }

  .xxl\:float-left {
    float: left !important;
  }

  .xxl\:float-none {
    float: none !important;
  }

  .xxl\:clearfix:after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }

  .xxl\:clear-left {
    clear: left !important;
  }

  .xxl\:clear-right {
    clear: right !important;
  }

  .xxl\:clear-both {
    clear: both !important;
  }

  .xxl\:clear-none {
    clear: none !important;
  }

  .xxl\:font-sans {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .xxl\:font-serif {
    font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .xxl\:font-mono {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .xxl\:font-hairline {
    font-weight: 100 !important;
  }

  .xxl\:font-thin {
    font-weight: 200 !important;
  }

  .xxl\:font-light {
    font-weight: 300 !important;
  }

  .xxl\:font-normal {
    font-weight: 400 !important;
  }

  .xxl\:font-medium {
    font-weight: 500 !important;
  }

  .xxl\:font-semibold {
    font-weight: 600 !important;
  }

  .xxl\:font-bold {
    font-weight: 700 !important;
  }

  .xxl\:font-extrabold {
    font-weight: 800 !important;
  }

  .xxl\:font-black {
    font-weight: 900 !important;
  }

  .xxl\:hover\:font-hairline:hover {
    font-weight: 100 !important;
  }

  .xxl\:hover\:font-thin:hover {
    font-weight: 200 !important;
  }

  .xxl\:hover\:font-light:hover {
    font-weight: 300 !important;
  }

  .xxl\:hover\:font-normal:hover {
    font-weight: 400 !important;
  }

  .xxl\:hover\:font-medium:hover {
    font-weight: 500 !important;
  }

  .xxl\:hover\:font-semibold:hover {
    font-weight: 600 !important;
  }

  .xxl\:hover\:font-bold:hover {
    font-weight: 700 !important;
  }

  .xxl\:hover\:font-extrabold:hover {
    font-weight: 800 !important;
  }

  .xxl\:hover\:font-black:hover {
    font-weight: 900 !important;
  }

  .xxl\:focus\:font-hairline:focus {
    font-weight: 100 !important;
  }

  .xxl\:focus\:font-thin:focus {
    font-weight: 200 !important;
  }

  .xxl\:focus\:font-light:focus {
    font-weight: 300 !important;
  }

  .xxl\:focus\:font-normal:focus {
    font-weight: 400 !important;
  }

  .xxl\:focus\:font-medium:focus {
    font-weight: 500 !important;
  }

  .xxl\:focus\:font-semibold:focus {
    font-weight: 600 !important;
  }

  .xxl\:focus\:font-bold:focus {
    font-weight: 700 !important;
  }

  .xxl\:focus\:font-extrabold:focus {
    font-weight: 800 !important;
  }

  .xxl\:focus\:font-black:focus {
    font-weight: 900 !important;
  }

  .xxl\:h-0 {
    height: 0 !important;
  }

  .xxl\:h-1 {
    height: 0.25rem !important;
  }

  .xxl\:h-2 {
    height: 0.5rem !important;
  }

  .xxl\:h-3 {
    height: 0.75rem !important;
  }

  .xxl\:h-4 {
    height: 1rem !important;
  }

  .xxl\:h-5 {
    height: 1.25rem !important;
  }

  .xxl\:h-6 {
    height: 1.5rem !important;
  }

  .xxl\:h-8 {
    height: 2rem !important;
  }

  .xxl\:h-10 {
    height: 2.5rem !important;
  }

  .xxl\:h-12 {
    height: 3rem !important;
  }

  .xxl\:h-16 {
    height: 4rem !important;
  }

  .xxl\:h-20 {
    height: 5rem !important;
  }

  .xxl\:h-24 {
    height: 6rem !important;
  }

  .xxl\:h-32 {
    height: 8rem !important;
  }

  .xxl\:h-40 {
    height: 10rem !important;
  }

  .xxl\:h-48 {
    height: 12rem !important;
  }

  .xxl\:h-56 {
    height: 14rem !important;
  }

  .xxl\:h-64 {
    height: 16rem !important;
  }

  .xxl\:h-auto {
    height: auto !important;
  }

  .xxl\:h-px {
    height: 1px !important;
  }

  .xxl\:h-full {
    height: 100% !important;
  }

  .xxl\:h-screen {
    height: 100vh !important;
  }

  .xxl\:text-xs {
    font-size: 0.80em !important;
  }

  .xxl\:text-sm {
    font-size: 0.975em !important;
  }

  .xxl\:text-base {
    font-size: 1em !important;
  }

  .xxl\:text-lg {
    font-size: 1.125em !important;
  }

  .xxl\:text-xl {
    font-size: 1.25em !important;
  }

  .xxl\:text-2xl {
    font-size: 1.5em !important;
  }

  .xxl\:text-3xl {
    font-size: 1.875em !important;
  }

  .xxl\:text-4xl {
    font-size: 2.25em !important;
  }

  .xxl\:text-5xl {
    font-size: 3em !important;
  }

  .xxl\:text-6xl {
    font-size: 4em !important;
  }

  .xxl\:text-md {
    font-size: 1em !important;
  }

  .xxl\:text-sm-title {
    font-size:  18px !important;
  }

  .xxl\:text-md-title {
    font-size: 22px !important;
  }

  .xxl\:text-lg-title {
    font-size: 36px !important;
  }

  .xxl\:text-size-body {
    font-size: 12px !important;
  }

  .xxl\:text-size-label {
    font-size: 10px !important;
  }

  .xxl\:leading-3 {
    line-height: .75rem !important;
  }

  .xxl\:leading-4 {
    line-height: 1rem !important;
  }

  .xxl\:leading-5 {
    line-height: 1.25rem !important;
  }

  .xxl\:leading-6 {
    line-height: 1.5rem !important;
  }

  .xxl\:leading-7 {
    line-height: 1.75rem !important;
  }

  .xxl\:leading-8 {
    line-height: 2rem !important;
  }

  .xxl\:leading-9 {
    line-height: 2.25rem !important;
  }

  .xxl\:leading-10 {
    line-height: 2.5rem !important;
  }

  .xxl\:leading-none {
    line-height: 1 !important;
  }

  .xxl\:leading-tight {
    line-height: 1.25 !important;
  }

  .xxl\:leading-snug {
    line-height: 1.375 !important;
  }

  .xxl\:leading-normal {
    line-height: 1.5 !important;
  }

  .xxl\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .xxl\:leading-loose {
    line-height: 2 !important;
  }

  .xxl\:list-inside {
    list-style-position: inside !important;
  }

  .xxl\:list-outside {
    list-style-position: outside !important;
  }

  .xxl\:list-none {
    list-style-type: none !important;
  }

  .xxl\:list-disc {
    list-style-type: disc !important;
  }

  .xxl\:list-decimal {
    list-style-type: decimal !important;
  }

  .xxl\:m-0 {
    margin: 0 !important;
  }

  .xxl\:m-1 {
    margin: 0.25rem !important;
  }

  .xxl\:m-2 {
    margin: 0.5rem !important;
  }

  .xxl\:m-3 {
    margin: 0.75rem !important;
  }

  .xxl\:m-4 {
    margin: 1rem !important;
  }

  .xxl\:m-5 {
    margin: 1.25rem !important;
  }

  .xxl\:m-6 {
    margin: 1.5rem !important;
  }

  .xxl\:m-8 {
    margin: 2rem !important;
  }

  .xxl\:m-10 {
    margin: 2.5rem !important;
  }

  .xxl\:m-12 {
    margin: 3rem !important;
  }

  .xxl\:m-16 {
    margin: 4rem !important;
  }

  .xxl\:m-20 {
    margin: 5rem !important;
  }

  .xxl\:m-24 {
    margin: 6rem !important;
  }

  .xxl\:m-32 {
    margin: 8rem !important;
  }

  .xxl\:m-40 {
    margin: 10rem !important;
  }

  .xxl\:m-48 {
    margin: 12rem !important;
  }

  .xxl\:m-56 {
    margin: 14rem !important;
  }

  .xxl\:m-64 {
    margin: 16rem !important;
  }

  .xxl\:m-auto {
    margin: auto !important;
  }

  .xxl\:m-px {
    margin: 1px !important;
  }

  .xxl\:-m-1 {
    margin: -0.25rem !important;
  }

  .xxl\:-m-2 {
    margin: -0.5rem !important;
  }

  .xxl\:-m-3 {
    margin: -0.75rem !important;
  }

  .xxl\:-m-4 {
    margin: -1rem !important;
  }

  .xxl\:-m-5 {
    margin: -1.25rem !important;
  }

  .xxl\:-m-6 {
    margin: -1.5rem !important;
  }

  .xxl\:-m-8 {
    margin: -2rem !important;
  }

  .xxl\:-m-10 {
    margin: -2.5rem !important;
  }

  .xxl\:-m-12 {
    margin: -3rem !important;
  }

  .xxl\:-m-16 {
    margin: -4rem !important;
  }

  .xxl\:-m-20 {
    margin: -5rem !important;
  }

  .xxl\:-m-24 {
    margin: -6rem !important;
  }

  .xxl\:-m-32 {
    margin: -8rem !important;
  }

  .xxl\:-m-40 {
    margin: -10rem !important;
  }

  .xxl\:-m-48 {
    margin: -12rem !important;
  }

  .xxl\:-m-56 {
    margin: -14rem !important;
  }

  .xxl\:-m-64 {
    margin: -16rem !important;
  }

  .xxl\:-m-px {
    margin: -1px !important;
  }

  .xxl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xxl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xxl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xxl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xxl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xxl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xxl\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xxl\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xxl\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xxl\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xxl\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xxl\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xxl\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xxl\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xxl\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xxl\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xxl\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xxl\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xxl\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xxl\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xxl\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xxl\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xxl\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xxl\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xxl\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xxl\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xxl\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xxl\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xxl\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xxl\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xxl\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xxl\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xxl\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xxl\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xxl\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xxl\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xxl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xxl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xxl\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xxl\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xxl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xxl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xxl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xxl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xxl\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xxl\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xxl\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xxl\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xxl\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xxl\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xxl\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xxl\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xxl\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xxl\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xxl\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xxl\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xxl\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xxl\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xxl\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xxl\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xxl\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xxl\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xxl\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xxl\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xxl\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xxl\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xxl\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xxl\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xxl\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xxl\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xxl\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xxl\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xxl\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xxl\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xxl\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xxl\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xxl\:mt-0 {
    margin-top: 0 !important;
  }

  .xxl\:mr-0 {
    margin-right: 0 !important;
  }

  .xxl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xxl\:ml-0 {
    margin-left: 0 !important;
  }

  .xxl\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xxl\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xxl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xxl\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xxl\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xxl\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xxl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xxl\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xxl\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .xxl\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .xxl\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .xxl\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .xxl\:mt-4 {
    margin-top: 1rem !important;
  }

  .xxl\:mr-4 {
    margin-right: 1rem !important;
  }

  .xxl\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .xxl\:ml-4 {
    margin-left: 1rem !important;
  }

  .xxl\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .xxl\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .xxl\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .xxl\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .xxl\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .xxl\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .xxl\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .xxl\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .xxl\:mt-8 {
    margin-top: 2rem !important;
  }

  .xxl\:mr-8 {
    margin-right: 2rem !important;
  }

  .xxl\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .xxl\:ml-8 {
    margin-left: 2rem !important;
  }

  .xxl\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .xxl\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .xxl\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .xxl\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .xxl\:mt-12 {
    margin-top: 3rem !important;
  }

  .xxl\:mr-12 {
    margin-right: 3rem !important;
  }

  .xxl\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .xxl\:ml-12 {
    margin-left: 3rem !important;
  }

  .xxl\:mt-16 {
    margin-top: 4rem !important;
  }

  .xxl\:mr-16 {
    margin-right: 4rem !important;
  }

  .xxl\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .xxl\:ml-16 {
    margin-left: 4rem !important;
  }

  .xxl\:mt-20 {
    margin-top: 5rem !important;
  }

  .xxl\:mr-20 {
    margin-right: 5rem !important;
  }

  .xxl\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .xxl\:ml-20 {
    margin-left: 5rem !important;
  }

  .xxl\:mt-24 {
    margin-top: 6rem !important;
  }

  .xxl\:mr-24 {
    margin-right: 6rem !important;
  }

  .xxl\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .xxl\:ml-24 {
    margin-left: 6rem !important;
  }

  .xxl\:mt-32 {
    margin-top: 8rem !important;
  }

  .xxl\:mr-32 {
    margin-right: 8rem !important;
  }

  .xxl\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .xxl\:ml-32 {
    margin-left: 8rem !important;
  }

  .xxl\:mt-40 {
    margin-top: 10rem !important;
  }

  .xxl\:mr-40 {
    margin-right: 10rem !important;
  }

  .xxl\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .xxl\:ml-40 {
    margin-left: 10rem !important;
  }

  .xxl\:mt-48 {
    margin-top: 12rem !important;
  }

  .xxl\:mr-48 {
    margin-right: 12rem !important;
  }

  .xxl\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .xxl\:ml-48 {
    margin-left: 12rem !important;
  }

  .xxl\:mt-56 {
    margin-top: 14rem !important;
  }

  .xxl\:mr-56 {
    margin-right: 14rem !important;
  }

  .xxl\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .xxl\:ml-56 {
    margin-left: 14rem !important;
  }

  .xxl\:mt-64 {
    margin-top: 16rem !important;
  }

  .xxl\:mr-64 {
    margin-right: 16rem !important;
  }

  .xxl\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .xxl\:ml-64 {
    margin-left: 16rem !important;
  }

  .xxl\:mt-auto {
    margin-top: auto !important;
  }

  .xxl\:mr-auto {
    margin-right: auto !important;
  }

  .xxl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xxl\:ml-auto {
    margin-left: auto !important;
  }

  .xxl\:mt-px {
    margin-top: 1px !important;
  }

  .xxl\:mr-px {
    margin-right: 1px !important;
  }

  .xxl\:mb-px {
    margin-bottom: 1px !important;
  }

  .xxl\:ml-px {
    margin-left: 1px !important;
  }

  .xxl\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .xxl\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .xxl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .xxl\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .xxl\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .xxl\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .xxl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .xxl\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .xxl\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .xxl\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .xxl\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .xxl\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .xxl\:-mt-4 {
    margin-top: -1rem !important;
  }

  .xxl\:-mr-4 {
    margin-right: -1rem !important;
  }

  .xxl\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .xxl\:-ml-4 {
    margin-left: -1rem !important;
  }

  .xxl\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .xxl\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .xxl\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .xxl\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .xxl\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .xxl\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .xxl\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .xxl\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .xxl\:-mt-8 {
    margin-top: -2rem !important;
  }

  .xxl\:-mr-8 {
    margin-right: -2rem !important;
  }

  .xxl\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .xxl\:-ml-8 {
    margin-left: -2rem !important;
  }

  .xxl\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .xxl\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .xxl\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .xxl\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .xxl\:-mt-12 {
    margin-top: -3rem !important;
  }

  .xxl\:-mr-12 {
    margin-right: -3rem !important;
  }

  .xxl\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .xxl\:-ml-12 {
    margin-left: -3rem !important;
  }

  .xxl\:-mt-16 {
    margin-top: -4rem !important;
  }

  .xxl\:-mr-16 {
    margin-right: -4rem !important;
  }

  .xxl\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .xxl\:-ml-16 {
    margin-left: -4rem !important;
  }

  .xxl\:-mt-20 {
    margin-top: -5rem !important;
  }

  .xxl\:-mr-20 {
    margin-right: -5rem !important;
  }

  .xxl\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .xxl\:-ml-20 {
    margin-left: -5rem !important;
  }

  .xxl\:-mt-24 {
    margin-top: -6rem !important;
  }

  .xxl\:-mr-24 {
    margin-right: -6rem !important;
  }

  .xxl\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .xxl\:-ml-24 {
    margin-left: -6rem !important;
  }

  .xxl\:-mt-32 {
    margin-top: -8rem !important;
  }

  .xxl\:-mr-32 {
    margin-right: -8rem !important;
  }

  .xxl\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .xxl\:-ml-32 {
    margin-left: -8rem !important;
  }

  .xxl\:-mt-40 {
    margin-top: -10rem !important;
  }

  .xxl\:-mr-40 {
    margin-right: -10rem !important;
  }

  .xxl\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .xxl\:-ml-40 {
    margin-left: -10rem !important;
  }

  .xxl\:-mt-48 {
    margin-top: -12rem !important;
  }

  .xxl\:-mr-48 {
    margin-right: -12rem !important;
  }

  .xxl\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .xxl\:-ml-48 {
    margin-left: -12rem !important;
  }

  .xxl\:-mt-56 {
    margin-top: -14rem !important;
  }

  .xxl\:-mr-56 {
    margin-right: -14rem !important;
  }

  .xxl\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .xxl\:-ml-56 {
    margin-left: -14rem !important;
  }

  .xxl\:-mt-64 {
    margin-top: -16rem !important;
  }

  .xxl\:-mr-64 {
    margin-right: -16rem !important;
  }

  .xxl\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .xxl\:-ml-64 {
    margin-left: -16rem !important;
  }

  .xxl\:-mt-px {
    margin-top: -1px !important;
  }

  .xxl\:-mr-px {
    margin-right: -1px !important;
  }

  .xxl\:-mb-px {
    margin-bottom: -1px !important;
  }

  .xxl\:-ml-px {
    margin-left: -1px !important;
  }

  .xxl\:max-h-full {
    max-height: 100% !important;
  }

  .xxl\:max-h-screen {
    max-height: 100vh !important;
  }

  .xxl\:max-w-0 {
    max-width: 0 !important;
  }

  .xxl\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .xxl\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .xxl\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .xxl\:max-w-4 {
    max-width: 1rem !important;
  }

  .xxl\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .xxl\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .xxl\:max-w-8 {
    max-width: 2rem !important;
  }

  .xxl\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .xxl\:max-w-12 {
    max-width: 3rem !important;
  }

  .xxl\:max-w-16 {
    max-width: 4rem !important;
  }

  .xxl\:max-w-20 {
    max-width: 5rem !important;
  }

  .xxl\:max-w-24 {
    max-width: 6rem !important;
  }

  .xxl\:max-w-32 {
    max-width: 8rem !important;
  }

  .xxl\:max-w-40 {
    max-width: 10rem !important;
  }

  .xxl\:max-w-48 {
    max-width: 12rem !important;
  }

  .xxl\:max-w-56 {
    max-width: 14rem !important;
  }

  .xxl\:max-w-64 {
    max-width: 16rem !important;
  }

  .xxl\:max-w-none {
    max-width: none !important;
  }

  .xxl\:max-w-xs {
    max-width: 20rem !important;
  }

  .xxl\:max-w-sm {
    max-width: 24rem !important;
  }

  .xxl\:max-w-md {
    max-width: 28rem !important;
  }

  .xxl\:max-w-lg {
    max-width: 32rem !important;
  }

  .xxl\:max-w-xl {
    max-width: 36rem !important;
  }

  .xxl\:max-w-2xl {
    max-width: 42rem !important;
  }

  .xxl\:max-w-3xl {
    max-width: 48rem !important;
  }

  .xxl\:max-w-4xl {
    max-width: 56rem !important;
  }

  .xxl\:max-w-5xl {
    max-width: 64rem !important;
  }

  .xxl\:max-w-6xl {
    max-width: 72rem !important;
  }

  .xxl\:max-w-full {
    max-width: 100% !important;
  }

  .xxl\:max-w-screen-xs {
    max-width: 480px !important;
  }

  .xxl\:max-w-screen-sm {
    max-width: 576px !important;
  }

  .xxl\:max-w-screen-md {
    max-width: 768px !important;
  }

  .xxl\:max-w-screen-lg {
    max-width: 992px !important;
  }

  .xxl\:max-w-screen-xl {
    max-width: 1200px !important;
  }

  .xxl\:max-w-screen-xxl {
    max-width: 1600px !important;
  }

  .xxl\:max-w-screen {
    max-width: 100vw !important;
  }

  .xxl\:max-w-px {
    max-width: 1px !important;
  }

  .xxl\:min-h-0 {
    min-height: 0 !important;
  }

  .xxl\:min-h-full {
    min-height: 100% !important;
  }

  .xxl\:min-h-screen {
    min-height: 100vh !important;
  }

  .xxl\:min-w-0 {
    min-width: 0 !important;
  }

  .xxl\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .xxl\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .xxl\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .xxl\:min-w-4 {
    min-width: 1rem !important;
  }

  .xxl\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .xxl\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .xxl\:min-w-8 {
    min-width: 2rem !important;
  }

  .xxl\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .xxl\:min-w-12 {
    min-width: 3rem !important;
  }

  .xxl\:min-w-16 {
    min-width: 4rem !important;
  }

  .xxl\:min-w-20 {
    min-width: 5rem !important;
  }

  .xxl\:min-w-24 {
    min-width: 6rem !important;
  }

  .xxl\:min-w-32 {
    min-width: 8rem !important;
  }

  .xxl\:min-w-40 {
    min-width: 10rem !important;
  }

  .xxl\:min-w-48 {
    min-width: 12rem !important;
  }

  .xxl\:min-w-56 {
    min-width: 14rem !important;
  }

  .xxl\:min-w-64 {
    min-width: 16rem !important;
  }

  .xxl\:min-w-full {
    min-width: 100% !important;
  }

  .xxl\:min-w-screen {
    min-width: 100vw !important;
  }

  .xxl\:min-w-px {
    min-width: 1px !important;
  }

  .xxl\:min-w-screen-xs {
    min-width: 480px !important;
  }

  .xxl\:min-w-screen-sm {
    min-width: 576px !important;
  }

  .xxl\:min-w-screen-md {
    min-width: 768px !important;
  }

  .xxl\:min-w-screen-lg {
    min-width: 992px !important;
  }

  .xxl\:min-w-screen-xl {
    min-width: 1200px !important;
  }

  .xxl\:min-w-screen-xxl {
    min-width: 1600px !important;
  }

  .xxl\:object-contain {
    object-fit: contain !important;
  }

  .xxl\:object-cover {
    object-fit: cover !important;
  }

  .xxl\:object-fill {
    object-fit: fill !important;
  }

  .xxl\:object-none {
    object-fit: none !important;
  }

  .xxl\:object-scale-down {
    object-fit: scale-down !important;
  }

  .xxl\:object-bottom {
    object-position: bottom !important;
  }

  .xxl\:object-center {
    object-position: center !important;
  }

  .xxl\:object-left {
    object-position: left !important;
  }

  .xxl\:object-left-bottom {
    object-position: left bottom !important;
  }

  .xxl\:object-left-top {
    object-position: left top !important;
  }

  .xxl\:object-right {
    object-position: right !important;
  }

  .xxl\:object-right-bottom {
    object-position: right bottom !important;
  }

  .xxl\:object-right-top {
    object-position: right top !important;
  }

  .xxl\:object-top {
    object-position: top !important;
  }

  .xxl\:opacity-0 {
    opacity: 0 !important;
  }

  .xxl\:opacity-25 {
    opacity: 0.25 !important;
  }

  .xxl\:opacity-50 {
    opacity: 0.5 !important;
  }

  .xxl\:opacity-75 {
    opacity: 0.75 !important;
  }

  .xxl\:opacity-100 {
    opacity: 1 !important;
  }

  .xxl\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .xxl\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .xxl\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .xxl\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .xxl\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .xxl\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .xxl\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .xxl\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .xxl\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .xxl\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .xxl\:overflow-auto {
    overflow: auto !important;
  }

  .xxl\:overflow-hidden {
    overflow: hidden !important;
  }

  .xxl\:overflow-visible {
    overflow: visible !important;
  }

  .xxl\:overflow-scroll {
    overflow: scroll !important;
  }

  .xxl\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xxl\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xxl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xxl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xxl\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xxl\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xxl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .xxl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .xxl\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .xxl\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .xxl\:overscroll-auto {
    overscroll-behavior: auto !important;
  }

  .xxl\:overscroll-contain {
    overscroll-behavior: contain !important;
  }

  .xxl\:overscroll-none {
    overscroll-behavior: none !important;
  }

  .xxl\:overscroll-y-auto {
    overscroll-behavior-y: auto !important;
  }

  .xxl\:overscroll-y-contain {
    overscroll-behavior-y: contain !important;
  }

  .xxl\:overscroll-y-none {
    overscroll-behavior-y: none !important;
  }

  .xxl\:overscroll-x-auto {
    overscroll-behavior-x: auto !important;
  }

  .xxl\:overscroll-x-contain {
    overscroll-behavior-x: contain !important;
  }

  .xxl\:overscroll-x-none {
    overscroll-behavior-x: none !important;
  }

  .xxl\:p-0 {
    padding: 0 !important;
  }

  .xxl\:p-1 {
    padding: 0.25rem !important;
  }

  .xxl\:p-2 {
    padding: 0.5rem !important;
  }

  .xxl\:p-3 {
    padding: 0.75rem !important;
  }

  .xxl\:p-4 {
    padding: 1rem !important;
  }

  .xxl\:p-5 {
    padding: 1.25rem !important;
  }

  .xxl\:p-6 {
    padding: 1.5rem !important;
  }

  .xxl\:p-8 {
    padding: 2rem !important;
  }

  .xxl\:p-10 {
    padding: 2.5rem !important;
  }

  .xxl\:p-12 {
    padding: 3rem !important;
  }

  .xxl\:p-16 {
    padding: 4rem !important;
  }

  .xxl\:p-20 {
    padding: 5rem !important;
  }

  .xxl\:p-24 {
    padding: 6rem !important;
  }

  .xxl\:p-32 {
    padding: 8rem !important;
  }

  .xxl\:p-40 {
    padding: 10rem !important;
  }

  .xxl\:p-48 {
    padding: 12rem !important;
  }

  .xxl\:p-56 {
    padding: 14rem !important;
  }

  .xxl\:p-64 {
    padding: 16rem !important;
  }

  .xxl\:p-px {
    padding: 1px !important;
  }

  .xxl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xxl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xxl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xxl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xxl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xxl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xxl\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xxl\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xxl\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xxl\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xxl\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xxl\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xxl\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xxl\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xxl\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xxl\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xxl\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xxl\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xxl\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xxl\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xxl\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xxl\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xxl\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xxl\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xxl\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xxl\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xxl\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xxl\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xxl\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xxl\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xxl\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xxl\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xxl\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xxl\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xxl\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xxl\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xxl\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xxl\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xxl\:pt-0 {
    padding-top: 0 !important;
  }

  .xxl\:pr-0 {
    padding-right: 0 !important;
  }

  .xxl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xxl\:pl-0 {
    padding-left: 0 !important;
  }

  .xxl\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xxl\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xxl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xxl\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xxl\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xxl\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xxl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xxl\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xxl\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .xxl\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .xxl\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .xxl\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .xxl\:pt-4 {
    padding-top: 1rem !important;
  }

  .xxl\:pr-4 {
    padding-right: 1rem !important;
  }

  .xxl\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .xxl\:pl-4 {
    padding-left: 1rem !important;
  }

  .xxl\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .xxl\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .xxl\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .xxl\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .xxl\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .xxl\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .xxl\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .xxl\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .xxl\:pt-8 {
    padding-top: 2rem !important;
  }

  .xxl\:pr-8 {
    padding-right: 2rem !important;
  }

  .xxl\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .xxl\:pl-8 {
    padding-left: 2rem !important;
  }

  .xxl\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .xxl\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .xxl\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .xxl\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .xxl\:pt-12 {
    padding-top: 3rem !important;
  }

  .xxl\:pr-12 {
    padding-right: 3rem !important;
  }

  .xxl\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .xxl\:pl-12 {
    padding-left: 3rem !important;
  }

  .xxl\:pt-16 {
    padding-top: 4rem !important;
  }

  .xxl\:pr-16 {
    padding-right: 4rem !important;
  }

  .xxl\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .xxl\:pl-16 {
    padding-left: 4rem !important;
  }

  .xxl\:pt-20 {
    padding-top: 5rem !important;
  }

  .xxl\:pr-20 {
    padding-right: 5rem !important;
  }

  .xxl\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .xxl\:pl-20 {
    padding-left: 5rem !important;
  }

  .xxl\:pt-24 {
    padding-top: 6rem !important;
  }

  .xxl\:pr-24 {
    padding-right: 6rem !important;
  }

  .xxl\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .xxl\:pl-24 {
    padding-left: 6rem !important;
  }

  .xxl\:pt-32 {
    padding-top: 8rem !important;
  }

  .xxl\:pr-32 {
    padding-right: 8rem !important;
  }

  .xxl\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .xxl\:pl-32 {
    padding-left: 8rem !important;
  }

  .xxl\:pt-40 {
    padding-top: 10rem !important;
  }

  .xxl\:pr-40 {
    padding-right: 10rem !important;
  }

  .xxl\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .xxl\:pl-40 {
    padding-left: 10rem !important;
  }

  .xxl\:pt-48 {
    padding-top: 12rem !important;
  }

  .xxl\:pr-48 {
    padding-right: 12rem !important;
  }

  .xxl\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .xxl\:pl-48 {
    padding-left: 12rem !important;
  }

  .xxl\:pt-56 {
    padding-top: 14rem !important;
  }

  .xxl\:pr-56 {
    padding-right: 14rem !important;
  }

  .xxl\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .xxl\:pl-56 {
    padding-left: 14rem !important;
  }

  .xxl\:pt-64 {
    padding-top: 16rem !important;
  }

  .xxl\:pr-64 {
    padding-right: 16rem !important;
  }

  .xxl\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .xxl\:pl-64 {
    padding-left: 16rem !important;
  }

  .xxl\:pt-px {
    padding-top: 1px !important;
  }

  .xxl\:pr-px {
    padding-right: 1px !important;
  }

  .xxl\:pb-px {
    padding-bottom: 1px !important;
  }

  .xxl\:pl-px {
    padding-left: 1px !important;
  }

  .xxl\:placeholder-transparent::-webkit-input-placeholder {
    color: transparent !important;
  }

  .xxl\:placeholder-transparent::-ms-input-placeholder {
    color: transparent !important;
  }

  .xxl\:placeholder-transparent::placeholder {
    color: transparent !important;
  }

  .xxl\:placeholder-current::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .xxl\:placeholder-current::-ms-input-placeholder {
    color: currentColor !important;
  }

  .xxl\:placeholder-current::placeholder {
    color: currentColor !important;
  }

  .xxl\:placeholder-black::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-black::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-black::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-white::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-white::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-white::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-200::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-200::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-300::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-300::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-400::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-400::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-500::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-500::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-600::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-600::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-700::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-700::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-800::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-800::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-900::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-900::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-primary::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .xxl\:placeholder-primary::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .xxl\:placeholder-primary::placeholder {
    color: var(--primary) !important;
  }

  .xxl\:placeholder-accent-1::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-accent-1::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-accent-1::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-accent-2::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-accent-2::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-accent-2::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder {
    color: transparent !important;
  }

  .xxl\:focus\:placeholder-transparent:focus::-ms-input-placeholder {
    color: transparent !important;
  }

  .xxl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent !important;
  }

  .xxl\:focus\:placeholder-current:focus::-webkit-input-placeholder {
    color: currentColor !important;
  }

  .xxl\:focus\:placeholder-current:focus::-ms-input-placeholder {
    color: currentColor !important;
  }

  .xxl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor !important;
  }

  .xxl\:focus\:placeholder-black:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-black:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-white:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-white:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-200:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-300:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-400:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-500:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-600:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-700:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-800:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-900:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-primary:focus::-webkit-input-placeholder {
    color: var(--primary) !important;
  }

  .xxl\:focus\:placeholder-primary:focus::-ms-input-placeholder {
    color: var(--primary) !important;
  }

  .xxl\:focus\:placeholder-primary:focus::placeholder {
    color: var(--primary) !important;
  }

  .xxl\:focus\:placeholder-accent-1:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-accent-1:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-accent-1:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-accent-2:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-accent-2:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:focus\:placeholder-accent-2:focus::placeholder {
    --placeholder-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--placeholder-opacity)) !important;
  }

  .xxl\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xxl\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xxl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xxl\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xxl\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xxl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xxl\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xxl\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xxl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xxl\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xxl\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xxl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xxl\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xxl\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xxl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xxl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xxl\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xxl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xxl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xxl\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xxl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xxl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xxl\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xxl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xxl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xxl\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xxl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xxl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xxl\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xxl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xxl\:pointer-events-none {
    pointer-events: none !important;
  }

  .xxl\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .xxl\:static {
    position: static !important;
  }

  .xxl\:fixed {
    position: fixed !important;
  }

  .xxl\:absolute {
    position: absolute !important;
  }

  .xxl\:relative {
    position: relative !important;
  }

  .xxl\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .xxl\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xxl\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xxl\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .xxl\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .xxl\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .xxl\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .xxl\:top-0 {
    top: 0 !important;
  }

  .xxl\:right-0 {
    right: 0 !important;
  }

  .xxl\:bottom-0 {
    bottom: 0 !important;
  }

  .xxl\:left-0 {
    left: 0 !important;
  }

  .xxl\:top-auto {
    top: auto !important;
  }

  .xxl\:right-auto {
    right: auto !important;
  }

  .xxl\:bottom-auto {
    bottom: auto !important;
  }

  .xxl\:left-auto {
    left: auto !important;
  }

  .xxl\:resize-none {
    resize: none !important;
  }

  .xxl\:resize-y {
    resize: vertical !important;
  }

  .xxl\:resize-x {
    resize: horizontal !important;
  }

  .xxl\:resize {
    resize: both !important;
  }

  .xxl\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xxl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xxl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xxl\:shadow-none {
    box-shadow: none !important;
  }

  .xxl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xxl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xxl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xxl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .xxl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xxl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xxl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xxl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xxl\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xxl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .xxl\:fill-current {
    fill: currentColor !important;
  }

  .xxl\:stroke-current {
    stroke: currentColor !important;
  }

  .xxl\:stroke-0 {
    stroke-width: 0 !important;
  }

  .xxl\:stroke-1 {
    stroke-width: 1 !important;
  }

  .xxl\:stroke-2 {
    stroke-width: 2 !important;
  }

  .xxl\:table-auto {
    table-layout: auto !important;
  }

  .xxl\:table-fixed {
    table-layout: fixed !important;
  }

  .xxl\:text-left {
    text-align: left !important;
  }

  .xxl\:text-center {
    text-align: center !important;
  }

  .xxl\:text-right {
    text-align: right !important;
  }

  .xxl\:text-justify {
    text-align: justify !important;
  }

  .xxl\:text-transparent {
    color: transparent !important;
  }

  .xxl\:text-current {
    color: currentColor !important;
  }

  .xxl\:text-black {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xxl\:text-white {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-100 {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-200 {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-300 {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-400 {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-500 {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-600 {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-700 {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-800 {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xxl\:text-gray-900 {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xxl\:text-red-100 {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xxl\:text-red-200 {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xxl\:text-red-300 {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xxl\:text-red-400 {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xxl\:text-red-500 {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xxl\:text-red-600 {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xxl\:text-red-700 {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xxl\:text-red-800 {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xxl\:text-red-900 {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-100 {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-200 {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-300 {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-400 {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-500 {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-600 {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-700 {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-800 {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xxl\:text-orange-900 {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-100 {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-200 {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-300 {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-400 {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-500 {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-600 {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-700 {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-800 {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xxl\:text-yellow-900 {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xxl\:text-green-100 {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xxl\:text-green-200 {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xxl\:text-green-300 {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xxl\:text-green-400 {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xxl\:text-green-500 {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xxl\:text-green-600 {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xxl\:text-green-700 {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xxl\:text-green-800 {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xxl\:text-green-900 {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-100 {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-200 {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-300 {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-400 {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-500 {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-600 {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-700 {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-800 {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xxl\:text-teal-900 {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-100 {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-200 {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-300 {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-400 {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-500 {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-600 {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-700 {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-800 {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xxl\:text-blue-900 {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-100 {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-200 {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-300 {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-400 {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-500 {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-600 {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-700 {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-800 {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xxl\:text-indigo-900 {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-100 {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-200 {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-300 {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-400 {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-500 {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-600 {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-700 {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-800 {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xxl\:text-purple-900 {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-100 {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-200 {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-300 {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-400 {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-500 {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-600 {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-700 {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-800 {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xxl\:text-pink-900 {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xxl\:text-primary {
    color: var(--primary) !important;
  }

  .xxl\:text-accent-1 {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xxl\:text-accent-2 {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-transparent:hover {
    color: transparent !important;
  }

  .xxl\:hover\:text-current:hover {
    color: currentColor !important;
  }

  .xxl\:hover\:text-black:hover {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-white:hover {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-100:hover {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-200:hover {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-300:hover {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-400:hover {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-500:hover {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-600:hover {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-700:hover {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-800:hover {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-gray-900:hover {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-200:hover {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-300:hover {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-400:hover {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-500:hover {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-600:hover {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-700:hover {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-800:hover {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-red-900:hover {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-100:hover {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-200:hover {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-300:hover {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-400:hover {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-500:hover {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-600:hover {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-700:hover {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-800:hover {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-orange-900:hover {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-100:hover {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-200:hover {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-300:hover {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-400:hover {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-500:hover {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-600:hover {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-700:hover {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-800:hover {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-yellow-900:hover {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-100:hover {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-200:hover {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-300:hover {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-400:hover {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-500:hover {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-600:hover {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-700:hover {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-800:hover {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-green-900:hover {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-100:hover {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-200:hover {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-300:hover {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-400:hover {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-500:hover {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-600:hover {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-700:hover {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-800:hover {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-teal-900:hover {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-100:hover {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-200:hover {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-300:hover {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-400:hover {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-500:hover {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-600:hover {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-700:hover {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-800:hover {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-blue-900:hover {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-100:hover {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-200:hover {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-300:hover {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-400:hover {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-500:hover {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-600:hover {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-700:hover {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-800:hover {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-indigo-900:hover {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-100:hover {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-200:hover {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-300:hover {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-400:hover {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-500:hover {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-600:hover {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-700:hover {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-800:hover {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-purple-900:hover {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-100:hover {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-200:hover {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-300:hover {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-400:hover {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-500:hover {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-600:hover {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-700:hover {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-800:hover {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-pink-900:hover {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-primary:hover {
    color: var(--primary) !important;
  }

  .xxl\:hover\:text-accent-1:hover {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xxl\:hover\:text-accent-2:hover {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-transparent:focus {
    color: transparent !important;
  }

  .xxl\:focus\:text-current:focus {
    color: currentColor !important;
  }

  .xxl\:focus\:text-black:focus {
    --text-opacity: 1 !important;
    color: #000 !important;
    color: rgba(0, 0, 0, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-white:focus {
    --text-opacity: 1 !important;
    color: #fff !important;
    color: rgba(255, 255, 255, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-100:focus {
    --text-opacity: 1 !important;
    color: #f7fafc !important;
    color: rgba(247, 250, 252, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-200:focus {
    --text-opacity: 1 !important;
    color: #edf2f7 !important;
    color: rgba(237, 242, 247, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-300:focus {
    --text-opacity: 1 !important;
    color: #e2e8f0 !important;
    color: rgba(226, 232, 240, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-400:focus {
    --text-opacity: 1 !important;
    color: #cbd5e0 !important;
    color: rgba(203, 213, 224, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-500:focus {
    --text-opacity: 1 !important;
    color: #a0aec0 !important;
    color: rgba(160, 174, 192, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-600:focus {
    --text-opacity: 1 !important;
    color: #718096 !important;
    color: rgba(113, 128, 150, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-700:focus {
    --text-opacity: 1 !important;
    color: #4a5568 !important;
    color: rgba(74, 85, 104, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-800:focus {
    --text-opacity: 1 !important;
    color: #2d3748 !important;
    color: rgba(45, 55, 72, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-gray-900:focus {
    --text-opacity: 1 !important;
    color: #1a202c !important;
    color: rgba(26, 32, 44, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f5 !important;
    color: rgba(255, 245, 245, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-200:focus {
    --text-opacity: 1 !important;
    color: #fed7d7 !important;
    color: rgba(254, 215, 215, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-300:focus {
    --text-opacity: 1 !important;
    color: #feb2b2 !important;
    color: rgba(254, 178, 178, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-400:focus {
    --text-opacity: 1 !important;
    color: #fc8181 !important;
    color: rgba(252, 129, 129, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-500:focus {
    --text-opacity: 1 !important;
    color: #f56565 !important;
    color: rgba(245, 101, 101, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-600:focus {
    --text-opacity: 1 !important;
    color: #e53e3e !important;
    color: rgba(229, 62, 62, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-700:focus {
    --text-opacity: 1 !important;
    color: #c53030 !important;
    color: rgba(197, 48, 48, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-800:focus {
    --text-opacity: 1 !important;
    color: #9b2c2c !important;
    color: rgba(155, 44, 44, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-red-900:focus {
    --text-opacity: 1 !important;
    color: #742a2a !important;
    color: rgba(116, 42, 42, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-100:focus {
    --text-opacity: 1 !important;
    color: #fffaf0 !important;
    color: rgba(255, 250, 240, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-200:focus {
    --text-opacity: 1 !important;
    color: #feebc8 !important;
    color: rgba(254, 235, 200, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-300:focus {
    --text-opacity: 1 !important;
    color: #fbd38d !important;
    color: rgba(251, 211, 141, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-400:focus {
    --text-opacity: 1 !important;
    color: #f6ad55 !important;
    color: rgba(246, 173, 85, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-500:focus {
    --text-opacity: 1 !important;
    color: #ed8936 !important;
    color: rgba(237, 137, 54, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-600:focus {
    --text-opacity: 1 !important;
    color: #dd6b20 !important;
    color: rgba(221, 107, 32, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-700:focus {
    --text-opacity: 1 !important;
    color: #c05621 !important;
    color: rgba(192, 86, 33, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-800:focus {
    --text-opacity: 1 !important;
    color: #9c4221 !important;
    color: rgba(156, 66, 33, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-orange-900:focus {
    --text-opacity: 1 !important;
    color: #7b341e !important;
    color: rgba(123, 52, 30, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-100:focus {
    --text-opacity: 1 !important;
    color: #fffff0 !important;
    color: rgba(255, 255, 240, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-200:focus {
    --text-opacity: 1 !important;
    color: #fefcbf !important;
    color: rgba(254, 252, 191, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-300:focus {
    --text-opacity: 1 !important;
    color: #faf089 !important;
    color: rgba(250, 240, 137, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-400:focus {
    --text-opacity: 1 !important;
    color: #f6e05e !important;
    color: rgba(246, 224, 94, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-500:focus {
    --text-opacity: 1 !important;
    color: #ecc94b !important;
    color: rgba(236, 201, 75, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-600:focus {
    --text-opacity: 1 !important;
    color: #d69e2e !important;
    color: rgba(214, 158, 46, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-700:focus {
    --text-opacity: 1 !important;
    color: #b7791f !important;
    color: rgba(183, 121, 31, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-800:focus {
    --text-opacity: 1 !important;
    color: #975a16 !important;
    color: rgba(151, 90, 22, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-yellow-900:focus {
    --text-opacity: 1 !important;
    color: #744210 !important;
    color: rgba(116, 66, 16, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-100:focus {
    --text-opacity: 1 !important;
    color: #f0fff4 !important;
    color: rgba(240, 255, 244, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-200:focus {
    --text-opacity: 1 !important;
    color: #c6f6d5 !important;
    color: rgba(198, 246, 213, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-300:focus {
    --text-opacity: 1 !important;
    color: #9ae6b4 !important;
    color: rgba(154, 230, 180, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-400:focus {
    --text-opacity: 1 !important;
    color: #68d391 !important;
    color: rgba(104, 211, 145, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-500:focus {
    --text-opacity: 1 !important;
    color: #48bb78 !important;
    color: rgba(72, 187, 120, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-600:focus {
    --text-opacity: 1 !important;
    color: #38a169 !important;
    color: rgba(56, 161, 105, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-700:focus {
    --text-opacity: 1 !important;
    color: #2f855a !important;
    color: rgba(47, 133, 90, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-800:focus {
    --text-opacity: 1 !important;
    color: #276749 !important;
    color: rgba(39, 103, 73, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-green-900:focus {
    --text-opacity: 1 !important;
    color: #22543d !important;
    color: rgba(34, 84, 61, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-100:focus {
    --text-opacity: 1 !important;
    color: #e6fffa !important;
    color: rgba(230, 255, 250, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-200:focus {
    --text-opacity: 1 !important;
    color: #b2f5ea !important;
    color: rgba(178, 245, 234, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-300:focus {
    --text-opacity: 1 !important;
    color: #81e6d9 !important;
    color: rgba(129, 230, 217, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-400:focus {
    --text-opacity: 1 !important;
    color: #4fd1c5 !important;
    color: rgba(79, 209, 197, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-500:focus {
    --text-opacity: 1 !important;
    color: #38b2ac !important;
    color: rgba(56, 178, 172, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-600:focus {
    --text-opacity: 1 !important;
    color: #319795 !important;
    color: rgba(49, 151, 149, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-700:focus {
    --text-opacity: 1 !important;
    color: #2c7a7b !important;
    color: rgba(44, 122, 123, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-800:focus {
    --text-opacity: 1 !important;
    color: #285e61 !important;
    color: rgba(40, 94, 97, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-teal-900:focus {
    --text-opacity: 1 !important;
    color: #234e52 !important;
    color: rgba(35, 78, 82, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-100:focus {
    --text-opacity: 1 !important;
    color: #ebf8ff !important;
    color: rgba(235, 248, 255, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-200:focus {
    --text-opacity: 1 !important;
    color: #bee3f8 !important;
    color: rgba(190, 227, 248, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-300:focus {
    --text-opacity: 1 !important;
    color: #90cdf4 !important;
    color: rgba(144, 205, 244, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-400:focus {
    --text-opacity: 1 !important;
    color: #63b3ed !important;
    color: rgba(99, 179, 237, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-500:focus {
    --text-opacity: 1 !important;
    color: #4299e1 !important;
    color: rgba(66, 153, 225, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-600:focus {
    --text-opacity: 1 !important;
    color: #3182ce !important;
    color: rgba(49, 130, 206, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-700:focus {
    --text-opacity: 1 !important;
    color: #2b6cb0 !important;
    color: rgba(43, 108, 176, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-800:focus {
    --text-opacity: 1 !important;
    color: #2c5282 !important;
    color: rgba(44, 82, 130, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-blue-900:focus {
    --text-opacity: 1 !important;
    color: #2a4365 !important;
    color: rgba(42, 67, 101, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-100:focus {
    --text-opacity: 1 !important;
    color: #ebf4ff !important;
    color: rgba(235, 244, 255, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-200:focus {
    --text-opacity: 1 !important;
    color: #c3dafe !important;
    color: rgba(195, 218, 254, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-300:focus {
    --text-opacity: 1 !important;
    color: #a3bffa !important;
    color: rgba(163, 191, 250, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-400:focus {
    --text-opacity: 1 !important;
    color: #7f9cf5 !important;
    color: rgba(127, 156, 245, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-500:focus {
    --text-opacity: 1 !important;
    color: #667eea !important;
    color: rgba(102, 126, 234, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-600:focus {
    --text-opacity: 1 !important;
    color: #5a67d8 !important;
    color: rgba(90, 103, 216, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-700:focus {
    --text-opacity: 1 !important;
    color: #4c51bf !important;
    color: rgba(76, 81, 191, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-800:focus {
    --text-opacity: 1 !important;
    color: #434190 !important;
    color: rgba(67, 65, 144, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-indigo-900:focus {
    --text-opacity: 1 !important;
    color: #3c366b !important;
    color: rgba(60, 54, 107, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-100:focus {
    --text-opacity: 1 !important;
    color: #faf5ff !important;
    color: rgba(250, 245, 255, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-200:focus {
    --text-opacity: 1 !important;
    color: #e9d8fd !important;
    color: rgba(233, 216, 253, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-300:focus {
    --text-opacity: 1 !important;
    color: #d6bcfa !important;
    color: rgba(214, 188, 250, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-400:focus {
    --text-opacity: 1 !important;
    color: #b794f4 !important;
    color: rgba(183, 148, 244, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-500:focus {
    --text-opacity: 1 !important;
    color: #9f7aea !important;
    color: rgba(159, 122, 234, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-600:focus {
    --text-opacity: 1 !important;
    color: #805ad5 !important;
    color: rgba(128, 90, 213, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-700:focus {
    --text-opacity: 1 !important;
    color: #6b46c1 !important;
    color: rgba(107, 70, 193, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-800:focus {
    --text-opacity: 1 !important;
    color: #553c9a !important;
    color: rgba(85, 60, 154, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-purple-900:focus {
    --text-opacity: 1 !important;
    color: #44337a !important;
    color: rgba(68, 51, 122, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-100:focus {
    --text-opacity: 1 !important;
    color: #fff5f7 !important;
    color: rgba(255, 245, 247, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-200:focus {
    --text-opacity: 1 !important;
    color: #fed7e2 !important;
    color: rgba(254, 215, 226, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-300:focus {
    --text-opacity: 1 !important;
    color: #fbb6ce !important;
    color: rgba(251, 182, 206, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-400:focus {
    --text-opacity: 1 !important;
    color: #f687b3 !important;
    color: rgba(246, 135, 179, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-500:focus {
    --text-opacity: 1 !important;
    color: #ed64a6 !important;
    color: rgba(237, 100, 166, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-600:focus {
    --text-opacity: 1 !important;
    color: #d53f8c !important;
    color: rgba(213, 63, 140, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-700:focus {
    --text-opacity: 1 !important;
    color: #b83280 !important;
    color: rgba(184, 50, 128, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-800:focus {
    --text-opacity: 1 !important;
    color: #97266d !important;
    color: rgba(151, 38, 109, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-pink-900:focus {
    --text-opacity: 1 !important;
    color: #702459 !important;
    color: rgba(112, 36, 89, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-primary:focus {
    color: var(--primary) !important;
  }

  .xxl\:focus\:text-accent-1:focus {
    --text-opacity: 1 !important;
    color: #fafafa !important;
    color: rgba(250, 250, 250, var(--text-opacity)) !important;
  }

  .xxl\:focus\:text-accent-2:focus {
    --text-opacity: 1 !important;
    color: #eaeaea !important;
    color: rgba(234, 234, 234, var(--text-opacity)) !important;
  }

  .xxl\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .xxl\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .xxl\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .xxl\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .xxl\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .xxl\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .xxl\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .xxl\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .xxl\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .xxl\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .xxl\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .xxl\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .xxl\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .xxl\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .xxl\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .xxl\:italic {
    font-style: italic !important;
  }

  .xxl\:not-italic {
    font-style: normal !important;
  }

  .xxl\:uppercase {
    text-transform: uppercase !important;
  }

  .xxl\:lowercase {
    text-transform: lowercase !important;
  }

  .xxl\:capitalize {
    text-transform: capitalize !important;
  }

  .xxl\:normal-case {
    text-transform: none !important;
  }

  .xxl\:underline {
    text-decoration: underline !important;
  }

  .xxl\:line-through {
    text-decoration: line-through !important;
  }

  .xxl\:no-underline {
    text-decoration: none !important;
  }

  .xxl\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .xxl\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .xxl\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .xxl\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .xxl\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .xxl\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .xxl\:antialiased {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .xxl\:subpixel-antialiased {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .xxl\:ordinal, .xxl\:slashed-zero, .xxl\:lining-nums, .xxl\:oldstyle-nums, .xxl\:proportional-nums, .xxl\:tabular-nums, .xxl\:diagonal-fractions, .xxl\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-slashed-zero: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-figure: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-spacing: var(--tailwind-empty,/*!*/ /*!*/) !important;
    --font-variant-numeric-fraction: var(--tailwind-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction) !important;
  }

  .xxl\:normal-nums {
    font-variant-numeric: normal !important;
  }

  .xxl\:ordinal {
    --font-variant-numeric-ordinal: ordinal !important;
  }

  .xxl\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero !important;
  }

  .xxl\:lining-nums {
    --font-variant-numeric-figure: lining-nums !important;
  }

  .xxl\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums !important;
  }

  .xxl\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums !important;
  }

  .xxl\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums !important;
  }

  .xxl\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions !important;
  }

  .xxl\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions !important;
  }

  .xxl\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .xxl\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .xxl\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xxl\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .xxl\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .xxl\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .xxl\:select-none {
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .xxl\:select-text {
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .xxl\:select-all {
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .xxl\:select-auto {
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .xxl\:align-baseline {
    vertical-align: baseline !important;
  }

  .xxl\:align-top {
    vertical-align: top !important;
  }

  .xxl\:align-middle {
    vertical-align: middle !important;
  }

  .xxl\:align-bottom {
    vertical-align: bottom !important;
  }

  .xxl\:align-text-top {
    vertical-align: text-top !important;
  }

  .xxl\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xxl\:visible {
    visibility: visible !important;
  }

  .xxl\:invisible {
    visibility: hidden !important;
  }

  .xxl\:whitespace-normal {
    white-space: normal !important;
  }

  .xxl\:whitespace-no-wrap {
    white-space: nowrap !important;
  }

  .xxl\:whitespace-pre {
    white-space: pre !important;
  }

  .xxl\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .xxl\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .xxl\:break-normal {
    word-wrap: normal !important;
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xxl\:break-words {
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
  }

  .xxl\:break-all {
    word-break: break-all !important;
  }

  .xxl\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xxl\:w-0 {
    width: 0 !important;
  }

  .xxl\:w-1 {
    width: 0.25rem !important;
  }

  .xxl\:w-2 {
    width: 0.5rem !important;
  }

  .xxl\:w-3 {
    width: 0.75rem !important;
  }

  .xxl\:w-4 {
    width: 1rem !important;
  }

  .xxl\:w-5 {
    width: 1.25rem !important;
  }

  .xxl\:w-6 {
    width: 1.5rem !important;
  }

  .xxl\:w-8 {
    width: 2rem !important;
  }

  .xxl\:w-10 {
    width: 2.5rem !important;
  }

  .xxl\:w-12 {
    width: 3rem !important;
  }

  .xxl\:w-16 {
    width: 4rem !important;
  }

  .xxl\:w-20 {
    width: 5rem !important;
  }

  .xxl\:w-24 {
    width: 6rem !important;
  }

  .xxl\:w-32 {
    width: 8rem !important;
  }

  .xxl\:w-40 {
    width: 10rem !important;
  }

  .xxl\:w-48 {
    width: 12rem !important;
  }

  .xxl\:w-56 {
    width: 14rem !important;
  }

  .xxl\:w-64 {
    width: 16rem !important;
  }

  .xxl\:w-auto {
    width: auto !important;
  }

  .xxl\:w-px {
    width: 1px !important;
  }

  .xxl\:w-1\/2 {
    width: 50% !important;
  }

  .xxl\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xxl\:w-2\/3 {
    width: 66.666667% !important;
  }

  .xxl\:w-1\/4 {
    width: 25% !important;
  }

  .xxl\:w-2\/4 {
    width: 50% !important;
  }

  .xxl\:w-3\/4 {
    width: 75% !important;
  }

  .xxl\:w-1\/5 {
    width: 20% !important;
  }

  .xxl\:w-2\/5 {
    width: 40% !important;
  }

  .xxl\:w-3\/5 {
    width: 60% !important;
  }

  .xxl\:w-4\/5 {
    width: 80% !important;
  }

  .xxl\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xxl\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xxl\:w-3\/6 {
    width: 50% !important;
  }

  .xxl\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xxl\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xxl\:w-1\/12 {
    width: 8.333333% !important;
  }

  .xxl\:w-2\/12 {
    width: 16.666667% !important;
  }

  .xxl\:w-3\/12 {
    width: 25% !important;
  }

  .xxl\:w-4\/12 {
    width: 33.333333% !important;
  }

  .xxl\:w-5\/12 {
    width: 41.666667% !important;
  }

  .xxl\:w-6\/12 {
    width: 50% !important;
  }

  .xxl\:w-7\/12 {
    width: 58.333333% !important;
  }

  .xxl\:w-8\/12 {
    width: 66.666667% !important;
  }

  .xxl\:w-9\/12 {
    width: 75% !important;
  }

  .xxl\:w-10\/12 {
    width: 83.333333% !important;
  }

  .xxl\:w-11\/12 {
    width: 91.666667% !important;
  }

  .xxl\:w-full {
    width: 100% !important;
  }

  .xxl\:w-screen {
    width: 100vw !important;
  }

  .xxl\:w-none {
    width: none !important;
  }

  .xxl\:w-xs {
    width: 20rem !important;
  }

  .xxl\:w-sm {
    width: 24rem !important;
  }

  .xxl\:w-md {
    width: 28rem !important;
  }

  .xxl\:w-lg {
    width: 32rem !important;
  }

  .xxl\:w-xl {
    width: 36rem !important;
  }

  .xxl\:w-2xl {
    width: 42rem !important;
  }

  .xxl\:w-3xl {
    width: 48rem !important;
  }

  .xxl\:w-4xl {
    width: 56rem !important;
  }

  .xxl\:w-5xl {
    width: 64rem !important;
  }

  .xxl\:w-6xl {
    width: 72rem !important;
  }

  .xxl\:w-screen-xs {
    width: 480px !important;
  }

  .xxl\:w-screen-sm {
    width: 576px !important;
  }

  .xxl\:w-screen-md {
    width: 768px !important;
  }

  .xxl\:w-screen-lg {
    width: 992px !important;
  }

  .xxl\:w-screen-xl {
    width: 1200px !important;
  }

  .xxl\:w-screen-xxl {
    width: 1600px !important;
  }

  .xxl\:w-sidebar {
    width: 15rem !important;
  }

  .xxl\:z-0 {
    z-index: 0 !important;
  }

  .xxl\:z-10 {
    z-index: 10 !important;
  }

  .xxl\:z-20 {
    z-index: 20 !important;
  }

  .xxl\:z-30 {
    z-index: 30 !important;
  }

  .xxl\:z-40 {
    z-index: 40 !important;
  }

  .xxl\:z-50 {
    z-index: 50 !important;
  }

  .xxl\:z-auto {
    z-index: auto !important;
  }

  .xxl\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .xxl\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .xxl\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .xxl\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .xxl\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .xxl\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .xxl\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .xxl\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .xxl\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .xxl\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .xxl\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .xxl\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .xxl\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .xxl\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .xxl\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .xxl\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .xxl\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .xxl\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .xxl\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .xxl\:gap-x-0 {
    grid-column-gap: 0 !important;
    -webkit-column-gap: 0 !important;
            column-gap: 0 !important;
  }

  .xxl\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .xxl\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .xxl\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .xxl\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .xxl\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .xxl\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .xxl\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .xxl\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .xxl\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .xxl\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .xxl\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .xxl\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .xxl\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .xxl\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .xxl\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .xxl\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .xxl\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .xxl\:gap-x-px {
    grid-column-gap: 1px !important;
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .xxl\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .xxl\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .xxl\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .xxl\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .xxl\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .xxl\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .xxl\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .xxl\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .xxl\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .xxl\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .xxl\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .xxl\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .xxl\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .xxl\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .xxl\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .xxl\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .xxl\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .xxl\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .xxl\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .xxl\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .xxl\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .xxl\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .xxl\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .xxl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .xxl\:auto-cols-auto {
    grid-auto-columns: auto !important;
  }

  .xxl\:auto-cols-min {
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .xxl\:auto-cols-max {
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .xxl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .xxl\:col-auto {
    grid-column: auto !important;
  }

  .xxl\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .xxl\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .xxl\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .xxl\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .xxl\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .xxl\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .xxl\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .xxl\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .xxl\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .xxl\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .xxl\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .xxl\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .xxl\:col-span-full {
    grid-column: 1 / -1 !important;
  }

  .xxl\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .xxl\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .xxl\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .xxl\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .xxl\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .xxl\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .xxl\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .xxl\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .xxl\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .xxl\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .xxl\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .xxl\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .xxl\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .xxl\:col-start-auto {
    grid-column-start: auto !important;
  }

  .xxl\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .xxl\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .xxl\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .xxl\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .xxl\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .xxl\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .xxl\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .xxl\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .xxl\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .xxl\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .xxl\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .xxl\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .xxl\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .xxl\:col-end-auto {
    grid-column-end: auto !important;
  }

  .xxl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xxl\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .xxl\:auto-rows-auto {
    grid-auto-rows: auto !important;
  }

  .xxl\:auto-rows-min {
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .xxl\:auto-rows-max {
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .xxl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .xxl\:row-auto {
    grid-row: auto !important;
  }

  .xxl\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .xxl\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .xxl\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .xxl\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .xxl\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .xxl\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .xxl\:row-span-full {
    grid-row: 1 / -1 !important;
  }

  .xxl\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .xxl\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .xxl\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .xxl\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .xxl\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .xxl\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .xxl\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .xxl\:row-start-auto {
    grid-row-start: auto !important;
  }

  .xxl\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .xxl\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .xxl\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .xxl\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .xxl\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .xxl\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .xxl\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .xxl\:row-end-auto {
    grid-row-end: auto !important;
  }

  .xxl\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .xxl\:transform-none {
    transform: none !important;
  }

  .xxl\:origin-center {
    transform-origin: center !important;
  }

  .xxl\:origin-top {
    transform-origin: top !important;
  }

  .xxl\:origin-top-right {
    transform-origin: top right !important;
  }

  .xxl\:origin-right {
    transform-origin: right !important;
  }

  .xxl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .xxl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xxl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .xxl\:origin-left {
    transform-origin: left !important;
  }

  .xxl\:origin-top-left {
    transform-origin: top left !important;
  }

  .xxl\:scale-0 {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xxl\:scale-50 {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xxl\:scale-75 {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xxl\:scale-90 {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xxl\:scale-95 {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xxl\:scale-100 {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xxl\:scale-105 {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xxl\:scale-110 {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xxl\:scale-125 {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xxl\:scale-150 {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xxl\:scale-x-0 {
    --transform-scale-x: 0 !important;
  }

  .xxl\:scale-x-50 {
    --transform-scale-x: .5 !important;
  }

  .xxl\:scale-x-75 {
    --transform-scale-x: .75 !important;
  }

  .xxl\:scale-x-90 {
    --transform-scale-x: .9 !important;
  }

  .xxl\:scale-x-95 {
    --transform-scale-x: .95 !important;
  }

  .xxl\:scale-x-100 {
    --transform-scale-x: 1 !important;
  }

  .xxl\:scale-x-105 {
    --transform-scale-x: 1.05 !important;
  }

  .xxl\:scale-x-110 {
    --transform-scale-x: 1.1 !important;
  }

  .xxl\:scale-x-125 {
    --transform-scale-x: 1.25 !important;
  }

  .xxl\:scale-x-150 {
    --transform-scale-x: 1.5 !important;
  }

  .xxl\:scale-y-0 {
    --transform-scale-y: 0 !important;
  }

  .xxl\:scale-y-50 {
    --transform-scale-y: .5 !important;
  }

  .xxl\:scale-y-75 {
    --transform-scale-y: .75 !important;
  }

  .xxl\:scale-y-90 {
    --transform-scale-y: .9 !important;
  }

  .xxl\:scale-y-95 {
    --transform-scale-y: .95 !important;
  }

  .xxl\:scale-y-100 {
    --transform-scale-y: 1 !important;
  }

  .xxl\:scale-y-105 {
    --transform-scale-y: 1.05 !important;
  }

  .xxl\:scale-y-110 {
    --transform-scale-y: 1.1 !important;
  }

  .xxl\:scale-y-125 {
    --transform-scale-y: 1.25 !important;
  }

  .xxl\:scale-y-150 {
    --transform-scale-y: 1.5 !important;
  }

  .xxl\:hover\:scale-0:hover {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xxl\:hover\:scale-50:hover {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xxl\:hover\:scale-75:hover {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xxl\:hover\:scale-90:hover {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xxl\:hover\:scale-95:hover {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xxl\:hover\:scale-100:hover {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xxl\:hover\:scale-105:hover {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xxl\:hover\:scale-110:hover {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xxl\:hover\:scale-125:hover {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xxl\:hover\:scale-150:hover {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xxl\:hover\:scale-x-0:hover {
    --transform-scale-x: 0 !important;
  }

  .xxl\:hover\:scale-x-50:hover {
    --transform-scale-x: .5 !important;
  }

  .xxl\:hover\:scale-x-75:hover {
    --transform-scale-x: .75 !important;
  }

  .xxl\:hover\:scale-x-90:hover {
    --transform-scale-x: .9 !important;
  }

  .xxl\:hover\:scale-x-95:hover {
    --transform-scale-x: .95 !important;
  }

  .xxl\:hover\:scale-x-100:hover {
    --transform-scale-x: 1 !important;
  }

  .xxl\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05 !important;
  }

  .xxl\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1 !important;
  }

  .xxl\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25 !important;
  }

  .xxl\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5 !important;
  }

  .xxl\:hover\:scale-y-0:hover {
    --transform-scale-y: 0 !important;
  }

  .xxl\:hover\:scale-y-50:hover {
    --transform-scale-y: .5 !important;
  }

  .xxl\:hover\:scale-y-75:hover {
    --transform-scale-y: .75 !important;
  }

  .xxl\:hover\:scale-y-90:hover {
    --transform-scale-y: .9 !important;
  }

  .xxl\:hover\:scale-y-95:hover {
    --transform-scale-y: .95 !important;
  }

  .xxl\:hover\:scale-y-100:hover {
    --transform-scale-y: 1 !important;
  }

  .xxl\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05 !important;
  }

  .xxl\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1 !important;
  }

  .xxl\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25 !important;
  }

  .xxl\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5 !important;
  }

  .xxl\:focus\:scale-0:focus {
    --transform-scale-x: 0 !important;
    --transform-scale-y: 0 !important;
  }

  .xxl\:focus\:scale-50:focus {
    --transform-scale-x: .5 !important;
    --transform-scale-y: .5 !important;
  }

  .xxl\:focus\:scale-75:focus {
    --transform-scale-x: .75 !important;
    --transform-scale-y: .75 !important;
  }

  .xxl\:focus\:scale-90:focus {
    --transform-scale-x: .9 !important;
    --transform-scale-y: .9 !important;
  }

  .xxl\:focus\:scale-95:focus {
    --transform-scale-x: .95 !important;
    --transform-scale-y: .95 !important;
  }

  .xxl\:focus\:scale-100:focus {
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
  }

  .xxl\:focus\:scale-105:focus {
    --transform-scale-x: 1.05 !important;
    --transform-scale-y: 1.05 !important;
  }

  .xxl\:focus\:scale-110:focus {
    --transform-scale-x: 1.1 !important;
    --transform-scale-y: 1.1 !important;
  }

  .xxl\:focus\:scale-125:focus {
    --transform-scale-x: 1.25 !important;
    --transform-scale-y: 1.25 !important;
  }

  .xxl\:focus\:scale-150:focus {
    --transform-scale-x: 1.5 !important;
    --transform-scale-y: 1.5 !important;
  }

  .xxl\:focus\:scale-x-0:focus {
    --transform-scale-x: 0 !important;
  }

  .xxl\:focus\:scale-x-50:focus {
    --transform-scale-x: .5 !important;
  }

  .xxl\:focus\:scale-x-75:focus {
    --transform-scale-x: .75 !important;
  }

  .xxl\:focus\:scale-x-90:focus {
    --transform-scale-x: .9 !important;
  }

  .xxl\:focus\:scale-x-95:focus {
    --transform-scale-x: .95 !important;
  }

  .xxl\:focus\:scale-x-100:focus {
    --transform-scale-x: 1 !important;
  }

  .xxl\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05 !important;
  }

  .xxl\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1 !important;
  }

  .xxl\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25 !important;
  }

  .xxl\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5 !important;
  }

  .xxl\:focus\:scale-y-0:focus {
    --transform-scale-y: 0 !important;
  }

  .xxl\:focus\:scale-y-50:focus {
    --transform-scale-y: .5 !important;
  }

  .xxl\:focus\:scale-y-75:focus {
    --transform-scale-y: .75 !important;
  }

  .xxl\:focus\:scale-y-90:focus {
    --transform-scale-y: .9 !important;
  }

  .xxl\:focus\:scale-y-95:focus {
    --transform-scale-y: .95 !important;
  }

  .xxl\:focus\:scale-y-100:focus {
    --transform-scale-y: 1 !important;
  }

  .xxl\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05 !important;
  }

  .xxl\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1 !important;
  }

  .xxl\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25 !important;
  }

  .xxl\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5 !important;
  }

  .xxl\:rotate-0 {
    --transform-rotate: 0 !important;
  }

  .xxl\:rotate-1 {
    --transform-rotate: 1deg !important;
  }

  .xxl\:rotate-2 {
    --transform-rotate: 2deg !important;
  }

  .xxl\:rotate-3 {
    --transform-rotate: 3deg !important;
  }

  .xxl\:rotate-6 {
    --transform-rotate: 6deg !important;
  }

  .xxl\:rotate-12 {
    --transform-rotate: 12deg !important;
  }

  .xxl\:rotate-45 {
    --transform-rotate: 45deg !important;
  }

  .xxl\:rotate-90 {
    --transform-rotate: 90deg !important;
  }

  .xxl\:rotate-180 {
    --transform-rotate: 180deg !important;
  }

  .xxl\:-rotate-180 {
    --transform-rotate: -180deg !important;
  }

  .xxl\:-rotate-90 {
    --transform-rotate: -90deg !important;
  }

  .xxl\:-rotate-45 {
    --transform-rotate: -45deg !important;
  }

  .xxl\:-rotate-12 {
    --transform-rotate: -12deg !important;
  }

  .xxl\:-rotate-6 {
    --transform-rotate: -6deg !important;
  }

  .xxl\:-rotate-3 {
    --transform-rotate: -3deg !important;
  }

  .xxl\:-rotate-2 {
    --transform-rotate: -2deg !important;
  }

  .xxl\:-rotate-1 {
    --transform-rotate: -1deg !important;
  }

  .xxl\:hover\:rotate-0:hover {
    --transform-rotate: 0 !important;
  }

  .xxl\:hover\:rotate-1:hover {
    --transform-rotate: 1deg !important;
  }

  .xxl\:hover\:rotate-2:hover {
    --transform-rotate: 2deg !important;
  }

  .xxl\:hover\:rotate-3:hover {
    --transform-rotate: 3deg !important;
  }

  .xxl\:hover\:rotate-6:hover {
    --transform-rotate: 6deg !important;
  }

  .xxl\:hover\:rotate-12:hover {
    --transform-rotate: 12deg !important;
  }

  .xxl\:hover\:rotate-45:hover {
    --transform-rotate: 45deg !important;
  }

  .xxl\:hover\:rotate-90:hover {
    --transform-rotate: 90deg !important;
  }

  .xxl\:hover\:rotate-180:hover {
    --transform-rotate: 180deg !important;
  }

  .xxl\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg !important;
  }

  .xxl\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg !important;
  }

  .xxl\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg !important;
  }

  .xxl\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg !important;
  }

  .xxl\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg !important;
  }

  .xxl\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg !important;
  }

  .xxl\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg !important;
  }

  .xxl\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg !important;
  }

  .xxl\:focus\:rotate-0:focus {
    --transform-rotate: 0 !important;
  }

  .xxl\:focus\:rotate-1:focus {
    --transform-rotate: 1deg !important;
  }

  .xxl\:focus\:rotate-2:focus {
    --transform-rotate: 2deg !important;
  }

  .xxl\:focus\:rotate-3:focus {
    --transform-rotate: 3deg !important;
  }

  .xxl\:focus\:rotate-6:focus {
    --transform-rotate: 6deg !important;
  }

  .xxl\:focus\:rotate-12:focus {
    --transform-rotate: 12deg !important;
  }

  .xxl\:focus\:rotate-45:focus {
    --transform-rotate: 45deg !important;
  }

  .xxl\:focus\:rotate-90:focus {
    --transform-rotate: 90deg !important;
  }

  .xxl\:focus\:rotate-180:focus {
    --transform-rotate: 180deg !important;
  }

  .xxl\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg !important;
  }

  .xxl\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg !important;
  }

  .xxl\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg !important;
  }

  .xxl\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg !important;
  }

  .xxl\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg !important;
  }

  .xxl\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg !important;
  }

  .xxl\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg !important;
  }

  .xxl\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg !important;
  }

  .xxl\:translate-x-0 {
    --transform-translate-x: 0 !important;
  }

  .xxl\:translate-x-1 {
    --transform-translate-x: 0.25rem !important;
  }

  .xxl\:translate-x-2 {
    --transform-translate-x: 0.5rem !important;
  }

  .xxl\:translate-x-3 {
    --transform-translate-x: 0.75rem !important;
  }

  .xxl\:translate-x-4 {
    --transform-translate-x: 1rem !important;
  }

  .xxl\:translate-x-5 {
    --transform-translate-x: 1.25rem !important;
  }

  .xxl\:translate-x-6 {
    --transform-translate-x: 1.5rem !important;
  }

  .xxl\:translate-x-8 {
    --transform-translate-x: 2rem !important;
  }

  .xxl\:translate-x-10 {
    --transform-translate-x: 2.5rem !important;
  }

  .xxl\:translate-x-12 {
    --transform-translate-x: 3rem !important;
  }

  .xxl\:translate-x-16 {
    --transform-translate-x: 4rem !important;
  }

  .xxl\:translate-x-20 {
    --transform-translate-x: 5rem !important;
  }

  .xxl\:translate-x-24 {
    --transform-translate-x: 6rem !important;
  }

  .xxl\:translate-x-32 {
    --transform-translate-x: 8rem !important;
  }

  .xxl\:translate-x-40 {
    --transform-translate-x: 10rem !important;
  }

  .xxl\:translate-x-48 {
    --transform-translate-x: 12rem !important;
  }

  .xxl\:translate-x-56 {
    --transform-translate-x: 14rem !important;
  }

  .xxl\:translate-x-64 {
    --transform-translate-x: 16rem !important;
  }

  .xxl\:translate-x-px {
    --transform-translate-x: 1px !important;
  }

  .xxl\:-translate-x-1 {
    --transform-translate-x: -0.25rem !important;
  }

  .xxl\:-translate-x-2 {
    --transform-translate-x: -0.5rem !important;
  }

  .xxl\:-translate-x-3 {
    --transform-translate-x: -0.75rem !important;
  }

  .xxl\:-translate-x-4 {
    --transform-translate-x: -1rem !important;
  }

  .xxl\:-translate-x-5 {
    --transform-translate-x: -1.25rem !important;
  }

  .xxl\:-translate-x-6 {
    --transform-translate-x: -1.5rem !important;
  }

  .xxl\:-translate-x-8 {
    --transform-translate-x: -2rem !important;
  }

  .xxl\:-translate-x-10 {
    --transform-translate-x: -2.5rem !important;
  }

  .xxl\:-translate-x-12 {
    --transform-translate-x: -3rem !important;
  }

  .xxl\:-translate-x-16 {
    --transform-translate-x: -4rem !important;
  }

  .xxl\:-translate-x-20 {
    --transform-translate-x: -5rem !important;
  }

  .xxl\:-translate-x-24 {
    --transform-translate-x: -6rem !important;
  }

  .xxl\:-translate-x-32 {
    --transform-translate-x: -8rem !important;
  }

  .xxl\:-translate-x-40 {
    --transform-translate-x: -10rem !important;
  }

  .xxl\:-translate-x-48 {
    --transform-translate-x: -12rem !important;
  }

  .xxl\:-translate-x-56 {
    --transform-translate-x: -14rem !important;
  }

  .xxl\:-translate-x-64 {
    --transform-translate-x: -16rem !important;
  }

  .xxl\:-translate-x-px {
    --transform-translate-x: -1px !important;
  }

  .xxl\:-translate-x-full {
    --transform-translate-x: -100% !important;
  }

  .xxl\:-translate-x-1\/2 {
    --transform-translate-x: -50% !important;
  }

  .xxl\:translate-x-1\/2 {
    --transform-translate-x: 50% !important;
  }

  .xxl\:translate-x-full {
    --transform-translate-x: 100% !important;
  }

  .xxl\:translate-y-0 {
    --transform-translate-y: 0 !important;
  }

  .xxl\:translate-y-1 {
    --transform-translate-y: 0.25rem !important;
  }

  .xxl\:translate-y-2 {
    --transform-translate-y: 0.5rem !important;
  }

  .xxl\:translate-y-3 {
    --transform-translate-y: 0.75rem !important;
  }

  .xxl\:translate-y-4 {
    --transform-translate-y: 1rem !important;
  }

  .xxl\:translate-y-5 {
    --transform-translate-y: 1.25rem !important;
  }

  .xxl\:translate-y-6 {
    --transform-translate-y: 1.5rem !important;
  }

  .xxl\:translate-y-8 {
    --transform-translate-y: 2rem !important;
  }

  .xxl\:translate-y-10 {
    --transform-translate-y: 2.5rem !important;
  }

  .xxl\:translate-y-12 {
    --transform-translate-y: 3rem !important;
  }

  .xxl\:translate-y-16 {
    --transform-translate-y: 4rem !important;
  }

  .xxl\:translate-y-20 {
    --transform-translate-y: 5rem !important;
  }

  .xxl\:translate-y-24 {
    --transform-translate-y: 6rem !important;
  }

  .xxl\:translate-y-32 {
    --transform-translate-y: 8rem !important;
  }

  .xxl\:translate-y-40 {
    --transform-translate-y: 10rem !important;
  }

  .xxl\:translate-y-48 {
    --transform-translate-y: 12rem !important;
  }

  .xxl\:translate-y-56 {
    --transform-translate-y: 14rem !important;
  }

  .xxl\:translate-y-64 {
    --transform-translate-y: 16rem !important;
  }

  .xxl\:translate-y-px {
    --transform-translate-y: 1px !important;
  }

  .xxl\:-translate-y-1 {
    --transform-translate-y: -0.25rem !important;
  }

  .xxl\:-translate-y-2 {
    --transform-translate-y: -0.5rem !important;
  }

  .xxl\:-translate-y-3 {
    --transform-translate-y: -0.75rem !important;
  }

  .xxl\:-translate-y-4 {
    --transform-translate-y: -1rem !important;
  }

  .xxl\:-translate-y-5 {
    --transform-translate-y: -1.25rem !important;
  }

  .xxl\:-translate-y-6 {
    --transform-translate-y: -1.5rem !important;
  }

  .xxl\:-translate-y-8 {
    --transform-translate-y: -2rem !important;
  }

  .xxl\:-translate-y-10 {
    --transform-translate-y: -2.5rem !important;
  }

  .xxl\:-translate-y-12 {
    --transform-translate-y: -3rem !important;
  }

  .xxl\:-translate-y-16 {
    --transform-translate-y: -4rem !important;
  }

  .xxl\:-translate-y-20 {
    --transform-translate-y: -5rem !important;
  }

  .xxl\:-translate-y-24 {
    --transform-translate-y: -6rem !important;
  }

  .xxl\:-translate-y-32 {
    --transform-translate-y: -8rem !important;
  }

  .xxl\:-translate-y-40 {
    --transform-translate-y: -10rem !important;
  }

  .xxl\:-translate-y-48 {
    --transform-translate-y: -12rem !important;
  }

  .xxl\:-translate-y-56 {
    --transform-translate-y: -14rem !important;
  }

  .xxl\:-translate-y-64 {
    --transform-translate-y: -16rem !important;
  }

  .xxl\:-translate-y-px {
    --transform-translate-y: -1px !important;
  }

  .xxl\:-translate-y-full {
    --transform-translate-y: -100% !important;
  }

  .xxl\:-translate-y-1\/2 {
    --transform-translate-y: -50% !important;
  }

  .xxl\:translate-y-1\/2 {
    --transform-translate-y: 50% !important;
  }

  .xxl\:translate-y-full {
    --transform-translate-y: 100% !important;
  }

  .xxl\:hover\:translate-x-0:hover {
    --transform-translate-x: 0 !important;
  }

  .xxl\:hover\:translate-x-1:hover {
    --transform-translate-x: 0.25rem !important;
  }

  .xxl\:hover\:translate-x-2:hover {
    --transform-translate-x: 0.5rem !important;
  }

  .xxl\:hover\:translate-x-3:hover {
    --transform-translate-x: 0.75rem !important;
  }

  .xxl\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem !important;
  }

  .xxl\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem !important;
  }

  .xxl\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem !important;
  }

  .xxl\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem !important;
  }

  .xxl\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem !important;
  }

  .xxl\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem !important;
  }

  .xxl\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem !important;
  }

  .xxl\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem !important;
  }

  .xxl\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem !important;
  }

  .xxl\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem !important;
  }

  .xxl\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem !important;
  }

  .xxl\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem !important;
  }

  .xxl\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem !important;
  }

  .xxl\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem !important;
  }

  .xxl\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px !important;
  }

  .xxl\:hover\:-translate-x-1:hover {
    --transform-translate-x: -0.25rem !important;
  }

  .xxl\:hover\:-translate-x-2:hover {
    --transform-translate-x: -0.5rem !important;
  }

  .xxl\:hover\:-translate-x-3:hover {
    --transform-translate-x: -0.75rem !important;
  }

  .xxl\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem !important;
  }

  .xxl\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem !important;
  }

  .xxl\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem !important;
  }

  .xxl\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem !important;
  }

  .xxl\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem !important;
  }

  .xxl\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem !important;
  }

  .xxl\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem !important;
  }

  .xxl\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem !important;
  }

  .xxl\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem !important;
  }

  .xxl\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem !important;
  }

  .xxl\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem !important;
  }

  .xxl\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem !important;
  }

  .xxl\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem !important;
  }

  .xxl\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem !important;
  }

  .xxl\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px !important;
  }

  .xxl\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100% !important;
  }

  .xxl\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50% !important;
  }

  .xxl\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50% !important;
  }

  .xxl\:hover\:translate-x-full:hover {
    --transform-translate-x: 100% !important;
  }

  .xxl\:hover\:translate-y-0:hover {
    --transform-translate-y: 0 !important;
  }

  .xxl\:hover\:translate-y-1:hover {
    --transform-translate-y: 0.25rem !important;
  }

  .xxl\:hover\:translate-y-2:hover {
    --transform-translate-y: 0.5rem !important;
  }

  .xxl\:hover\:translate-y-3:hover {
    --transform-translate-y: 0.75rem !important;
  }

  .xxl\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem !important;
  }

  .xxl\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem !important;
  }

  .xxl\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem !important;
  }

  .xxl\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem !important;
  }

  .xxl\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem !important;
  }

  .xxl\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem !important;
  }

  .xxl\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem !important;
  }

  .xxl\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem !important;
  }

  .xxl\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem !important;
  }

  .xxl\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem !important;
  }

  .xxl\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem !important;
  }

  .xxl\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem !important;
  }

  .xxl\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem !important;
  }

  .xxl\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem !important;
  }

  .xxl\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px !important;
  }

  .xxl\:hover\:-translate-y-1:hover {
    --transform-translate-y: -0.25rem !important;
  }

  .xxl\:hover\:-translate-y-2:hover {
    --transform-translate-y: -0.5rem !important;
  }

  .xxl\:hover\:-translate-y-3:hover {
    --transform-translate-y: -0.75rem !important;
  }

  .xxl\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem !important;
  }

  .xxl\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem !important;
  }

  .xxl\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem !important;
  }

  .xxl\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem !important;
  }

  .xxl\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem !important;
  }

  .xxl\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem !important;
  }

  .xxl\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem !important;
  }

  .xxl\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem !important;
  }

  .xxl\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem !important;
  }

  .xxl\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem !important;
  }

  .xxl\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem !important;
  }

  .xxl\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem !important;
  }

  .xxl\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem !important;
  }

  .xxl\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem !important;
  }

  .xxl\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px !important;
  }

  .xxl\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100% !important;
  }

  .xxl\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50% !important;
  }

  .xxl\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50% !important;
  }

  .xxl\:hover\:translate-y-full:hover {
    --transform-translate-y: 100% !important;
  }

  .xxl\:focus\:translate-x-0:focus {
    --transform-translate-x: 0 !important;
  }

  .xxl\:focus\:translate-x-1:focus {
    --transform-translate-x: 0.25rem !important;
  }

  .xxl\:focus\:translate-x-2:focus {
    --transform-translate-x: 0.5rem !important;
  }

  .xxl\:focus\:translate-x-3:focus {
    --transform-translate-x: 0.75rem !important;
  }

  .xxl\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem !important;
  }

  .xxl\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem !important;
  }

  .xxl\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem !important;
  }

  .xxl\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem !important;
  }

  .xxl\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem !important;
  }

  .xxl\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem !important;
  }

  .xxl\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem !important;
  }

  .xxl\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem !important;
  }

  .xxl\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem !important;
  }

  .xxl\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem !important;
  }

  .xxl\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem !important;
  }

  .xxl\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem !important;
  }

  .xxl\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem !important;
  }

  .xxl\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem !important;
  }

  .xxl\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px !important;
  }

  .xxl\:focus\:-translate-x-1:focus {
    --transform-translate-x: -0.25rem !important;
  }

  .xxl\:focus\:-translate-x-2:focus {
    --transform-translate-x: -0.5rem !important;
  }

  .xxl\:focus\:-translate-x-3:focus {
    --transform-translate-x: -0.75rem !important;
  }

  .xxl\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem !important;
  }

  .xxl\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem !important;
  }

  .xxl\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem !important;
  }

  .xxl\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem !important;
  }

  .xxl\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem !important;
  }

  .xxl\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem !important;
  }

  .xxl\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem !important;
  }

  .xxl\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem !important;
  }

  .xxl\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem !important;
  }

  .xxl\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem !important;
  }

  .xxl\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem !important;
  }

  .xxl\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem !important;
  }

  .xxl\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem !important;
  }

  .xxl\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem !important;
  }

  .xxl\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px !important;
  }

  .xxl\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100% !important;
  }

  .xxl\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50% !important;
  }

  .xxl\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50% !important;
  }

  .xxl\:focus\:translate-x-full:focus {
    --transform-translate-x: 100% !important;
  }

  .xxl\:focus\:translate-y-0:focus {
    --transform-translate-y: 0 !important;
  }

  .xxl\:focus\:translate-y-1:focus {
    --transform-translate-y: 0.25rem !important;
  }

  .xxl\:focus\:translate-y-2:focus {
    --transform-translate-y: 0.5rem !important;
  }

  .xxl\:focus\:translate-y-3:focus {
    --transform-translate-y: 0.75rem !important;
  }

  .xxl\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem !important;
  }

  .xxl\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem !important;
  }

  .xxl\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem !important;
  }

  .xxl\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem !important;
  }

  .xxl\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem !important;
  }

  .xxl\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem !important;
  }

  .xxl\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem !important;
  }

  .xxl\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem !important;
  }

  .xxl\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem !important;
  }

  .xxl\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem !important;
  }

  .xxl\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem !important;
  }

  .xxl\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem !important;
  }

  .xxl\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem !important;
  }

  .xxl\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem !important;
  }

  .xxl\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px !important;
  }

  .xxl\:focus\:-translate-y-1:focus {
    --transform-translate-y: -0.25rem !important;
  }

  .xxl\:focus\:-translate-y-2:focus {
    --transform-translate-y: -0.5rem !important;
  }

  .xxl\:focus\:-translate-y-3:focus {
    --transform-translate-y: -0.75rem !important;
  }

  .xxl\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem !important;
  }

  .xxl\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem !important;
  }

  .xxl\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem !important;
  }

  .xxl\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem !important;
  }

  .xxl\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem !important;
  }

  .xxl\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem !important;
  }

  .xxl\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem !important;
  }

  .xxl\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem !important;
  }

  .xxl\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem !important;
  }

  .xxl\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem !important;
  }

  .xxl\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem !important;
  }

  .xxl\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem !important;
  }

  .xxl\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem !important;
  }

  .xxl\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem !important;
  }

  .xxl\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px !important;
  }

  .xxl\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100% !important;
  }

  .xxl\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50% !important;
  }

  .xxl\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50% !important;
  }

  .xxl\:focus\:translate-y-full:focus {
    --transform-translate-y: 100% !important;
  }

  .xxl\:skew-x-0 {
    --transform-skew-x: 0 !important;
  }

  .xxl\:skew-x-1 {
    --transform-skew-x: 1deg !important;
  }

  .xxl\:skew-x-2 {
    --transform-skew-x: 2deg !important;
  }

  .xxl\:skew-x-3 {
    --transform-skew-x: 3deg !important;
  }

  .xxl\:skew-x-6 {
    --transform-skew-x: 6deg !important;
  }

  .xxl\:skew-x-12 {
    --transform-skew-x: 12deg !important;
  }

  .xxl\:-skew-x-12 {
    --transform-skew-x: -12deg !important;
  }

  .xxl\:-skew-x-6 {
    --transform-skew-x: -6deg !important;
  }

  .xxl\:-skew-x-3 {
    --transform-skew-x: -3deg !important;
  }

  .xxl\:-skew-x-2 {
    --transform-skew-x: -2deg !important;
  }

  .xxl\:-skew-x-1 {
    --transform-skew-x: -1deg !important;
  }

  .xxl\:skew-y-0 {
    --transform-skew-y: 0 !important;
  }

  .xxl\:skew-y-1 {
    --transform-skew-y: 1deg !important;
  }

  .xxl\:skew-y-2 {
    --transform-skew-y: 2deg !important;
  }

  .xxl\:skew-y-3 {
    --transform-skew-y: 3deg !important;
  }

  .xxl\:skew-y-6 {
    --transform-skew-y: 6deg !important;
  }

  .xxl\:skew-y-12 {
    --transform-skew-y: 12deg !important;
  }

  .xxl\:-skew-y-12 {
    --transform-skew-y: -12deg !important;
  }

  .xxl\:-skew-y-6 {
    --transform-skew-y: -6deg !important;
  }

  .xxl\:-skew-y-3 {
    --transform-skew-y: -3deg !important;
  }

  .xxl\:-skew-y-2 {
    --transform-skew-y: -2deg !important;
  }

  .xxl\:-skew-y-1 {
    --transform-skew-y: -1deg !important;
  }

  .xxl\:hover\:skew-x-0:hover {
    --transform-skew-x: 0 !important;
  }

  .xxl\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg !important;
  }

  .xxl\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg !important;
  }

  .xxl\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg !important;
  }

  .xxl\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg !important;
  }

  .xxl\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg !important;
  }

  .xxl\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg !important;
  }

  .xxl\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg !important;
  }

  .xxl\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg !important;
  }

  .xxl\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg !important;
  }

  .xxl\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg !important;
  }

  .xxl\:hover\:skew-y-0:hover {
    --transform-skew-y: 0 !important;
  }

  .xxl\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg !important;
  }

  .xxl\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg !important;
  }

  .xxl\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg !important;
  }

  .xxl\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg !important;
  }

  .xxl\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg !important;
  }

  .xxl\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg !important;
  }

  .xxl\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg !important;
  }

  .xxl\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg !important;
  }

  .xxl\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg !important;
  }

  .xxl\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg !important;
  }

  .xxl\:focus\:skew-x-0:focus {
    --transform-skew-x: 0 !important;
  }

  .xxl\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg !important;
  }

  .xxl\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg !important;
  }

  .xxl\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg !important;
  }

  .xxl\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg !important;
  }

  .xxl\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg !important;
  }

  .xxl\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg !important;
  }

  .xxl\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg !important;
  }

  .xxl\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg !important;
  }

  .xxl\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg !important;
  }

  .xxl\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg !important;
  }

  .xxl\:focus\:skew-y-0:focus {
    --transform-skew-y: 0 !important;
  }

  .xxl\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg !important;
  }

  .xxl\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg !important;
  }

  .xxl\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg !important;
  }

  .xxl\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg !important;
  }

  .xxl\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg !important;
  }

  .xxl\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg !important;
  }

  .xxl\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg !important;
  }

  .xxl\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg !important;
  }

  .xxl\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg !important;
  }

  .xxl\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg !important;
  }

  .xxl\:transition-none {
    transition-property: none !important;
  }

  .xxl\:transition-all {
    transition-property: all !important;
  }

  .xxl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  }

  .xxl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke !important;
  }

  .xxl\:transition-opacity {
    transition-property: opacity !important;
  }

  .xxl\:transition-shadow {
    transition-property: box-shadow !important;
  }

  .xxl\:transition-transform {
    transition-property: transform !important;
  }

  .xxl\:ease-linear {
    transition-timing-function: linear !important;
  }

  .xxl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .xxl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .xxl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .xxl\:duration-75 {
    transition-duration: 75ms !important;
  }

  .xxl\:duration-100 {
    transition-duration: 100ms !important;
  }

  .xxl\:duration-150 {
    transition-duration: 150ms !important;
  }

  .xxl\:duration-200 {
    transition-duration: 200ms !important;
  }

  .xxl\:duration-300 {
    transition-duration: 300ms !important;
  }

  .xxl\:duration-500 {
    transition-duration: 500ms !important;
  }

  .xxl\:duration-700 {
    transition-duration: 700ms !important;
  }

  .xxl\:duration-1000 {
    transition-duration: 1000ms !important;
  }

  .xxl\:delay-75 {
    transition-delay: 75ms !important;
  }

  .xxl\:delay-100 {
    transition-delay: 100ms !important;
  }

  .xxl\:delay-150 {
    transition-delay: 150ms !important;
  }

  .xxl\:delay-200 {
    transition-delay: 200ms !important;
  }

  .xxl\:delay-300 {
    transition-delay: 300ms !important;
  }

  .xxl\:delay-500 {
    transition-delay: 500ms !important;
  }

  .xxl\:delay-700 {
    transition-delay: 700ms !important;
  }

  .xxl\:delay-1000 {
    transition-delay: 1000ms !important;
  }

  .xxl\:animate-none {
    animation: none !important;
  }

  .xxl\:animate-spin {
    animation: spin 1s linear infinite !important;
  }

  .xxl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .xxl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .xxl\:animate-bounce {
    animation: bounce 1s infinite !important;
  }

  .xxl\:link {
    color: var(--primary);
    cursor: pointer;
    transition: color 0.3s ease;
  }
}
