.react-phone-number-input .rrui__options-list {
  position: fixed;
  max-width: 95vw;
  left: 50%;
  transform: translateX(-50%);
}

.mobile-device .react-phone-number-input .rrui__options-list {
  left: 50%;
  top: 51.1%;
  transform: translate(-50%, -50%);
  height: 80vh;
  z-index: 99;
}
.mobile-device .react-phone-number-input .rrui__options-list ul {
  max-height: 100% !important;
  font-size: 16px;
}
#fp-nav.fp-right {
  z-index: -1;
}

.mobile-device #fp-nav,
.mobile-device #_hj_feedback_container {
  display: none;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  margin-right: 10px;
}

.flex-container > *:last-child {
  margin-right: auto;
}

.no-padding .ant-modal-body {
  padding: 0;
}

.ant-popover.dark-popover .ant-popover-inner,
.ant-popover.dark-popover .ant-popover-arrow {
  background-color: #292929;
}
.ant-popover.dark-popover .ant-popover-inner-content {
  color: #fff;
}

.ant-modal.no-padding .ant-modal-content {
  padding: 0px;
}

.ant-modal.fab-close .ant-modal-close-x {
  border: 1px rgb(50, 54, 57) solid;
  border-radius: 50%;
  background: rgb(50, 54, 57);
  margin: 6px 5px;
  opacity: 0.7;
  color: white;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

@media screen and (max-width: 600px) {
  .ant-modal.responsive-fullscreen {
    max-width: 100vw !important;
    width: 700px !important;
    top: 0 !important;
    margin: 0px !important;
    height: 100%;
  }
  .ant-modal.responsive-fullscreen .ant-modal-content {
    border-radius: 0px;
    min-height: 100vh !important;
  }
}

.has-error .ant-input:focus {
  /* border: none !important; */
  box-shadow: none !important;
  outline: none !important;
}

.locale-button {
  border: none !important;
  font-size: 14px !important;
  opacity: 0.8;
  color: black !important;
}
